import { BaseIconProps } from "../icon";

export function LinkIcon(props: BaseIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 19 18"
      fill="none"
      {...props}
    >
      <path
        d="M1.83356 1.5V16.5H17.1664V9H15.4627V14.8333H3.5372V3.16667H9.49996V1.5H1.83356ZM11.2036 1.5V3.16667H14.2582L6.34223 10.9108L7.54676 12.0892L15.4627 4.34505V7.33333H17.1664V1.5H11.2036Z"
        fill="currentColor"
      />
    </svg>
  );
}
