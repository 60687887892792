import { cn, getSymbolWithDefault } from "@/utils";
import React, { forwardRef } from "react";
import { Icon } from "../icon";
import { TokenInfo } from "@/api";

interface CurrencyInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  value: string;
  currency?: string | null;
  preventFocusOnClick?: boolean;
  onChangeIsUsd?: () => void;
  swapValue?: string;
  token?: TokenInfo | null;
  usdSwitchDisabled?: boolean;
}

function CurrencyInputComponent(
  {
    className,
    value,
    currency,
    preventFocusOnClick,
    onChangeIsUsd,
    swapValue,
    token,
    usdSwitchDisabled = false,
    ...props
  }: CurrencyInputProps,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  return (
    <div>
      <div className="h-14">
        <div
          className={cn("flex items-center relative", {
            "text-[3rem] h-[3rem]": value.length <= 4,
            "text-[2.8rem] h-[2.8rem]": value.length > 4 && value.length <= 5,
            "text-[2.6rem] h-[2.6rem]": value.length > 5 && value.length <= 6,
            "text-[2.5rem] h-[2.5rem]": value.length > 6 && value.length <= 7,
            "text-[2.4rem] h-[2.4rem]": value.length > 7 && value.length <= 8,
            "text-[2.3rem] h-[2.3rem]": value.length > 8 && value.length <= 9,
            "text-[2.2rem] h-[2.2rem]": value.length > 9 && value.length <= 10,
            "text-[2.1rem] h-[2.1rem]": value.length > 10 && value.length <= 11,
            "text-[2rem] h-[2rem]": value.length > 11 && value.length <= 12,
            "text-[1.9rem] h-[1.9rem]": value.length > 12 && value.length <= 13,
            "text-[1.75rem] h-[1.75rem]":
              value.length > 13 && value.length <= 14,
            "text-[1.65rem] h-[1.65rem]":
              value.length > 14 && value.length <= 15,
            "text-[1.5rem] h-[1.5rem]": value.length > 15,
          })}
        >
          <input
            ref={ref}
            inputMode="none"
            autoCorrect="off"
            spellCheck="false"
            autoComplete="off"
            value={value}
            className={cn(
              "bg-transparent text-gray-white font-semibold w-full focus:text-right focus:outline-none",
              value.length > 20 ? "text-left" : "text-right",
              className,
            )}
            {...props}
            onMouseDown={
              preventFocusOnClick
                ? (e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    props.onMouseDown?.(e);
                  }
                : props.onMouseDown
            }
          />

          {currency ? (
            <div className="text-gray-200 pl-2 shrink-0">{currency}</div>
          ) : null}
        </div>
      </div>
      {onChangeIsUsd ? (
        <button
          type="button"
          className="flex items-center gap-1.5 text-sm font-bold text-gray-200 ml-auto group"
          onClick={onChangeIsUsd}
          disabled={usdSwitchDisabled}
        >
          <Icon
            name="switch"
            className="w-4 text-primary group-disabled:text-gray-400"
          />
          {swapValue}
          <span className="sr-only">
            Switch to {currency === "USD" ? getSymbolWithDefault(token) : "USD"}
          </span>
        </button>
      ) : null}
    </div>
  );
}

export const CurrencyInput = forwardRef(CurrencyInputComponent);
