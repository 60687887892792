import { Drawer } from "vaul";
import type { DialogProps } from "@radix-ui/react-dialog";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { Dialog } from "@/components";

export function Root(props: DialogProps) {
  const isDesktopAndUp = useMediaQuery("(min-width: 1024px)");
  return isDesktopAndUp ? (
    <Dialog.Root {...props} />
  ) : (
    <Drawer.Root {...props} />
  );
}
