import { BaseIconProps } from "../icon";

export function VerifiedIcon(props: BaseIconProps) {
  return (
    <svg
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_14081_2187)">
        <path
          d="M7.49691 0C3.36495 0 0 3.36495 0 7.49691C0 11.6289 3.36495 14.9938 7.49691 14.9938C11.6289 14.9938 14.9938 11.6289 14.9938 7.49691C14.9938 3.36495 11.6351 0 7.49691 0Z"
          fill="currentColor"
        />
        <path
          d="M6.69281 8.78352L4.6825 6.77321L3.71136 7.75053L6.64332 10.6763L6.69281 10.732L11.4804 5.94435L11.5361 5.89486L10.5588 4.91754L6.69281 8.78352Z"
          fill="#1D1E20"
        />
      </g>
      <defs>
        <clipPath id="clip0_14081_2187">
          <rect width="15" height="15" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
