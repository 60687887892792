import { REHYDRATE, rehydrateReducer } from "@/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface RewardsState {
  inviteCode: string;
}
const initialState: RewardsState = {
  inviteCode: "",
};

const rewardsSlice = createSlice({
  name: "rewards",
  initialState,
  reducers: {
    [REHYDRATE]: rehydrateReducer,
    updateInviteCode: (state, action: PayloadAction<string>) => {
      state.inviteCode = action.payload;
    },
  },
});

export const { updateInviteCode } = rewardsSlice.actions;

export const rewardsReducer = rewardsSlice.reducer;
export const rewardsActions = rewardsSlice.actions;
