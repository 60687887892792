import { orderHistoryApi, walletApi } from "@/api";

export const backgroundTransactionApiActions = {
  pendingOrderSaved:
    orderHistoryApi.endpoints.saveOrderQuoteDetails.matchFulfilled,
  transactionPending: walletApi.endpoints.sendTransaction.matchPending,
  transactionFulfilled: walletApi.endpoints.sendTransaction.matchFulfilled,
  transactionRejected: walletApi.endpoints.sendTransaction.matchRejected,
  completedOrderSaved:
    orderHistoryApi.endpoints.saveCompletedUserOrder.matchFulfilled,
  completedOrderRejected:
    orderHistoryApi.endpoints.saveCompletedUserOrder.matchRejected,
};
