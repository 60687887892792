import { api, getAuthHeaders } from "./api";

const XP_API_BASE_URL = import.meta.env.VITE_USER_API_BASE_URL;

export const xpApi = api.injectEndpoints({
  endpoints: (builder) => ({
    userXp: builder.query<UserXp, void>({
      query: () => ({
        url: `${XP_API_BASE_URL}/api/xp/user`,
        headers: getAuthHeaders(),
      }),
      providesTags: ["UserXp"],
    }),
    leaderboard: builder.query<LeaderboardEntry[], void>({
      query: () => ({
        url: `${XP_API_BASE_URL}/api/xp/leaderboard`,
        headers: getAuthHeaders(),
      }),
      providesTags: ["Leaderboard"],
    }),
  }),
});

export const { useUserXpQuery, useLeaderboardQuery } = xpApi;

export interface UserXpApiResponse {
  username: string;
  profile_image: string | null;
  wallet_address: string;
  change_rank: number;
  change_total_tradingVolume: number;
  change_total_xp: number;
  change_trailing_7_day_trading_volume: number;
  current_streak: number;
  max_streak: number;
  rank: number;
  total_trading_volume: number;
  total_xp: number;
  trailing_7_day_trading_volume: number;
  percentile_rank: number;
  timestamp: number;
}

export interface UserXp {
  timestamp: number | null;
  username: string;
  profileImage: string | null;
  walletAddress: string;
  percentileRank: number | null;
  rank: number | null;
  totalXp: number;
  totalTradingVolume: number;
  trailing7DayTradingVolume: number;
  currentStreak: number;
  maxStreak: number;
  changeRank: number | null;
  changeTotalXp: number;
  changeTotalTradingVolume: number;
  changeTrailing7DayTradingVolume: number;
}

export interface LeaderboardEntry {
  username: string;
  profileImage: string | null;
  rank: number | null;
  currentStreak: number;
  changeRank: number | null;
}
