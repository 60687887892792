import { SOL_TRANSACTION_FEE, cn } from "@/utils";
import { Dispatch, SetStateAction } from "react";

const DEFAULT_PRESETS = [
  { label: "10%", divideBy: 10n },
  { label: "50%", divideBy: 2n },
  { label: "MAX", divideBy: 1n },
];

interface TotalPercentagePresetsProps {
  className?: string;
  maxAmount: bigint;
  isNativeSol: boolean;
  priorityFee: bigint;
  minimumWalletSolBalance: bigint;
  options?: typeof DEFAULT_PRESETS;
  disabled?: boolean;
  selected: string | null;
  setSelected: Dispatch<SetStateAction<string | null>>;
  setOverdraft: Dispatch<
    SetStateAction<{ overdrafted: boolean; amount: bigint }>
  >;
  onSelect: (dividedAmount: bigint) => void;
}

export function TotalPercentagePresets({
  className,
  maxAmount,
  isNativeSol,
  priorityFee,
  minimumWalletSolBalance,
  options = DEFAULT_PRESETS,
  disabled,
  selected,
  setSelected,
  setOverdraft,
  onSelect,
}: TotalPercentagePresetsProps) {
  return (
    <div className={cn("flex gap-3.5 py-3.5", className)}>
      {options.map((preset) => (
        <button
          key={preset.label}
          disabled={disabled}
          className={cn(
            "h-[34px] border border-cloud rounded-full px-2 w-full text-sm font-bold disabled:text-gray-300",
            {
              "border-accent": selected === preset.label,
            },
          )}
          onClick={() => {
            setSelected(preset.label);
            // If the token is native SOL and the preset is MAX, need to subtract fees and minimum amount
            const dividedAmount = maxAmount / preset.divideBy;
            setOverdraft({ overdrafted: false, amount: 0n });
            if (isNativeSol) {
              const maximumSend =
                maxAmount -
                priorityFee -
                SOL_TRANSACTION_FEE -
                minimumWalletSolBalance;

              if (maximumSend <= 0n) {
                // If the token is native SOL and wallet will be left with less than minimum,
                // set overdraft to show error
                onSelect(0n);
                setOverdraft({
                  amount: -maximumSend,
                  overdrafted: true,
                });
                return;
              }

              if (maximumSend >= dividedAmount) {
                onSelect(dividedAmount);
                return;
              } else {
                onSelect(maximumSend);
                return;
              }
            }

            onSelect(dividedAmount);
          }}
        >
          {preset.label}
        </button>
      ))}
      <button
        className="uppercase py-2 w-full text-sm font-bold"
        onClick={() => {
          setSelected(null);
          setOverdraft({ amount: 0n, overdrafted: false });
          onSelect(0n);
        }}
      >
        Clear
      </button>
    </div>
  );
}
