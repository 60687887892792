import { BaseIconProps } from "../icon";

export function ShareIcon(props: BaseIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 19 18"
      fill="none"
      {...props}
    >
      <path
        d="M8.5803 4.67338V12.6225H10.4196V4.67338L13.4452 7.72147L14.751 6.41515L9.49993 1.125L4.24883 6.41515L5.55471 7.72147L8.5803 4.67338Z"
        fill="currentColor"
      />
      <path
        d="M16.0937 12.2426V15.0221H2.90616V12.2426H1.06689V16.875H17.933V12.2426H16.0937Z"
        fill="currentColor"
      />
    </svg>
  );
}
