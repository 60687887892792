import {
  isUnwrapSolError,
  isWrapSolError,
  useTokenAccountByMintQuery,
  useTokenInfoQuery,
  useUnwrapSolMutation,
  useUpdateSettingsMutation,
  useUserQuery,
  useWalletInfoQuery,
  useWrapSolMutation,
} from "@/api";
import {
  Button,
  ErrorMessage,
  Icon,
  OneTimeCodePrompt,
  Page,
  Shimmer,
  Switch,
  TokenIcon,
  Tooltip,
} from "@/components";
import { useValidation } from "@/routes/profile/wsol/-validation";
import { useAppDispatch, useTypedSelector } from "@/store";
import {
  NATIVE_SOL_ACCOUNT_RENT,
  SOL_ADDRESS,
  SOL_DECIMALS,
  getIsSlippageLimitReached,
  normalizeScaled,
  transactionStatusMessage,
} from "@/utils";
import { useIsLoggedIn } from "@dynamic-labs/sdk-react-core";
import * as RadixTabs from "@radix-ui/react-tabs";
import { skipToken } from "@reduxjs/toolkit/query";
import { createFileRoute } from "@tanstack/react-router";
import clsx from "clsx";
import {
  getMaxWrapAmount,
  getReducedPriorityFee,
  resetWrapUnwrap,
  setMaxWrapAmount,
  setWrapUnwrapToggle,
  updateUseWrappedSol,
  updateWrapAmount,
} from "../-reducer";
import { useDebouncedFocus } from "@/hooks";
import { useCallback } from "react";

export type TabEnum = "wrap" | "unwrap";

export const Route = createFileRoute("/profile/wsol/")({
  component: ProfileWSOL,
});

function ProfileWSOL() {
  const isLoggedIn = useIsLoggedIn();
  const userQuery = useUserQuery(isLoggedIn ? undefined : skipToken);
  const [settingsMutate, settingsMutation] = useUpdateSettingsMutation();
  const {
    useWrappedSol,
    wrapUnwrapToggle,
    wrapInput,
    priorityFee,
    hasInsufficientSolForWrap,
  } = useTypedSelector((state) => state.profile);
  const queryOptions = { refetchOnMountOrArgChange: 5 };
  const walletInfoQuery = useWalletInfoQuery(undefined, queryOptions);
  const wsolAccountQuery = useTokenAccountByMintQuery(
    SOL_ADDRESS,
    queryOptions,
  );
  const [wrapSolMutate, wrapSolMutation] = useWrapSolMutation();
  const [unwrapSolMutate, unwrapSolMutation] = useUnwrapSolMutation();
  const solBalance = walletInfoQuery.data?.rawBalance ?? "0";
  const wsolBalance = wsolAccountQuery.data?.tokenRawBalance ?? "0";
  const wrapMaxAmount = getMaxWrapAmount(
    BigInt(solBalance),
    BigInt(wsolBalance),
    priorityFee,
  );
  const solInfoQuery = useTokenInfoQuery({
    address: SOL_ADDRESS,
    solType: "sol",
  });
  useDebouncedFocus(
    useCallback(() => {
      void walletInfoQuery.refetch();
      void wsolAccountQuery.refetch();
    }, [walletInfoQuery, wsolAccountQuery]),
    5_000,
  );

  let signature =
    wrapSolMutation.data?.signature ?? unwrapSolMutation.data?.signature;
  let wrapButtonText = wrapSolMutation.isLoading ? "WRAPPING" : "WRAP SOL";
  let wrapErrorTitle = "";
  let wrapErrorMsg = "";
  if (isWrapSolError(wrapSolMutation.error)) {
    switch (wrapSolMutation.error.kind) {
      case "cancel_signature":
        wrapSolMutation.reset();
        break;
      case "failed_to_sign":
        wrapErrorTitle = "FAILED TO SIGN TRANSACTION";
        wrapErrorMsg = transactionStatusMessage.failedToSignTransaction;
        break;
      case "failed_to_fetch_latest_blockhash":
        wrapErrorTitle = "TRANSACTION NOT SENT";
        wrapErrorMsg = transactionStatusMessage.failedToFetchLatestBlockhash;
        break;
      case "not_simulated":
        wrapErrorTitle = "TRANSACTION NOT SENT";
        wrapErrorMsg = transactionStatusMessage.notSimulated;
        break;
      case "simulation_failed":
        wrapErrorTitle = "TRANSACTION NOT SENT";
        wrapErrorMsg = transactionStatusMessage.simulationFailed(
          wrapSolMutation.error.data.value,
        );
        break;
      case "unhandled_error":
        wrapErrorTitle = "AN ERROR OCCURRED";
        wrapErrorMsg = transactionStatusMessage.unhandledError(
          wrapSolMutation.error.data,
        );
        break;
      default: {
        const _exhaustiveCheck: never = wrapSolMutation.error;
      }
    }
  }
  if (wrapSolMutation.data) {
    switch (wrapSolMutation.data.status) {
      case "success":
        wrapButtonText = "WRAPPED";
        break;
      case "failed":
        if (getIsSlippageLimitReached(wrapSolMutation.data.transactionError)) {
          wrapErrorTitle = "SLIPPAGE LIMIT REACHED";
          wrapErrorMsg = transactionStatusMessage.slippageLimitReached;
        } else {
          wrapErrorTitle = "TRANSACTION FAILED";
          wrapErrorMsg = transactionStatusMessage.transactionFailed;
        }
        break;
      case "expired":
        signature = undefined;
        wrapErrorTitle = "TRANSACTION EXPIRED";
        wrapErrorMsg = transactionStatusMessage.transactionExpired;
        break;
      case "unknown":
        wrapErrorTitle = "TRANSACTION STATUS UNKNOWN";
        wrapErrorMsg = transactionStatusMessage.transactionStatusUnknown;
        break;
      default: {
        const _exhaustiveCheck: never = wrapSolMutation.data;
      }
    }
  }

  let unwrapButtonText = unwrapSolMutation.isLoading
    ? "UNWRAPPING"
    : "UNWRAP WSOL TO SOL";
  let unwrapErrorTitle = "";
  let unwrapErrorMsg = "";
  if (isUnwrapSolError(unwrapSolMutation.error)) {
    switch (unwrapSolMutation.error.kind) {
      case "cancel_signature":
        unwrapSolMutation.reset();
        break;
      case "failed_to_sign":
        unwrapErrorMsg = transactionStatusMessage.failedToSignTransaction;
        break;
      case "failed_to_fetch_latest_blockhash":
        unwrapErrorTitle = "TRANSACTION NOT SENT";
        unwrapErrorMsg = transactionStatusMessage.failedToFetchLatestBlockhash;
        break;
      case "not_simulated":
        unwrapErrorTitle = "TRANSACTION NOT SENT";
        unwrapErrorMsg = transactionStatusMessage.notSimulated;
        break;
      case "simulation_failed":
        unwrapErrorTitle = "TRANSACTION NOT SENT";
        unwrapErrorMsg = transactionStatusMessage.simulationFailed(
          unwrapSolMutation.error.data.value,
        );
        break;
      case "unhandled_error":
        unwrapErrorTitle = "AN ERROR OCCURRED";
        unwrapErrorMsg = transactionStatusMessage.unhandledError(
          unwrapSolMutation.error.data,
        );
        break;
      default: {
        const _exhaustiveCheck: never = unwrapSolMutation.error;
      }
    }
  }
  if (unwrapSolMutation.data) {
    switch (unwrapSolMutation.data.status) {
      case "success":
        unwrapButtonText = "UNWRAPPED";
        break;
      case "failed":
        unwrapErrorTitle = "TRANSACTION FAILED";
        unwrapErrorMsg = transactionStatusMessage.transactionFailed;
        break;
      case "expired":
        unwrapErrorTitle = "TRANSACTION EXPIRED";
        unwrapErrorMsg = transactionStatusMessage.transactionExpired;
        break;
      case "unknown":
        unwrapErrorTitle = "TRANSACTION STATUS UNKNOWN";
        unwrapErrorMsg = transactionStatusMessage.transactionStatusUnknown;
        break;
      default: {
        const _exhaustiveCheck: never = unwrapSolMutation.data;
      }
    }
  }

  const showMaxSolWarning = wrapInput.scaledAmount === wrapMaxAmount.toString();

  const dispatch = useAppDispatch();

  const handleSwitchChange = (value: boolean) => {
    if (settingsMutation.isLoading) return;
    dispatch(updateUseWrappedSol(value));
    if (isLoggedIn) {
      void settingsMutate({ useWrappedSolSetting: value });
    }
  };

  const validation = useValidation();

  if (hasInsufficientSolForWrap) {
    unwrapErrorTitle = "Insufficient SOL";
    unwrapErrorMsg =
      "You need enough SOL in your account to cover the base fee and priority fee to wrap SOL. You can reduce your priority fee to lower the required SOL amount.";
  }

  if (validation.kind === "insufficient_sol") {
    unwrapErrorTitle = "Insufficient SOL";
    unwrapErrorMsg =
      "You need enough SOL in your account to cover the base fee and priority fee to unwrap WSOL. You can reduce your priority fee to lower the required SOL amount.";
  }

  if (validation.kind === "min_sol_required") {
    wrapButtonText = `Max ${normalizeScaled(BigInt(wrapMaxAmount) - NATIVE_SOL_ACCOUNT_RENT, SOL_DECIMALS)} or ${normalizeScaled(wrapMaxAmount, SOL_DECIMALS)}`;
  }

  const onSubmit = async () => {
    if (!walletInfoQuery.data?.walletAddress) return;
    if (wrapSolMutation.isLoading) return;
    if (wrapUnwrapToggle === "wrap" && !validation.valid) {
      return;
    }
    console.log("Wrapping");
    wrapSolMutation.reset();
    unwrapSolMutation.reset();
    if (wrapUnwrapToggle === "wrap") {
      const result = await wrapSolMutate({
        amountLamports: wrapInput.scaledAmount,
        walletAddress: walletInfoQuery.data.walletAddress,
        prioritizationFeeLamports:
          getReducedPriorityFee(priorityFee).toString(),
      });
      if ("error" in result) {
        console.error(result.error);
        return;
      }
      dispatch(resetWrapUnwrap());
      // Refetch the wsol account
      // in case it was just created
      // and has no subscription
      void wsolAccountQuery.refetch();
    } else {
      const result = await unwrapSolMutate({
        walletAddress: walletInfoQuery.data.walletAddress,
        prioritizationFeeLamports:
          getReducedPriorityFee(priorityFee).toString(),
      });
      if ("error" in result) {
        console.error(result.error);
        return;
      }
      // This closes the wsol account
      // and will break the subscription
      // so we need to refetch it
      void wsolAccountQuery.refetch();
      void walletInfoQuery.refetch();
      dispatch(resetWrapUnwrap());
    }
  };

  const onClickMax = () => {
    dispatch(setMaxWrapAmount({ solBalance, wsolBalance }));
  };

  const tabClass =
    "uppercase font-bold text-sm py-2 mr-6 border-b border-transparent transition-colors text-gray-200 hover:text-white hover:border-white aria-selected:text-primary aria-selected:border-primary";

  return (
    <Page
      title="Wrapped SOL"
      backButton={{
        to: "/profile",
        search: (prev) => prev,
        "aria-label": "Back to profile",
      }}
      hideTabs
    >
      <div className="px-5 pb-24">
        {settingsMutation.error && (
          <ErrorMessage variant="banner" className="mb-6">
            Something happened. Please try again.
          </ErrorMessage>
        )}
        <p className="text-sm text-gray-200 mb-5">
          Specify whether to use native or wrapped SOL when buying or selling
          SOL.
        </p>
        <div className="divide-y divide-cloud">
          <div className="flex gap-4 items-center justify-between">
            <p className="flex items-center gap-2">
              <span className="text-primary font-bold text-sm uppercase">
                USE WSOL
              </span>
              <Tooltip title="USE WSOL">
                Enabling WSOL means you will send or receive WSOL, the wrapped
                variant, when conducting trades containing SOL.
              </Tooltip>
            </p>
            <Switch
              checked={useWrappedSol}
              disabled={settingsMutation.isLoading || userQuery.isLoading}
              onCheckedChange={handleSwitchChange}
              aria-label="Use WSOL"
            />
          </div>
          <p className="pt-5 my-5 text-primary font-bold text-sm uppercase">
            WRAP / UNWRAP SOL
          </p>
        </div>
        <Shimmer.Text
          isLoading={
            userQuery.isLoading ||
            walletInfoQuery.isLoading ||
            wsolAccountQuery.isLoading
          }
        >
          <div className="border border-cloud rounded-md mt-4">
            <RadixTabs.Root
              defaultValue={wrapUnwrapToggle}
              onValueChange={(value) => {
                dispatch(setWrapUnwrapToggle(value));
              }}
            >
              <RadixTabs.List
                aria-label="wrap tab"
                className="flex justify-between pl-4"
              >
                <div className="flex min-h-12">
                  <RadixTabs.Trigger value="wrap" className={tabClass}>
                    {solBalance ? normalizeScaled(solBalance, SOL_DECIMALS) : 0}{" "}
                    SOL
                  </RadixTabs.Trigger>
                  <RadixTabs.Trigger value="unwrap" className={tabClass}>
                    {wsolBalance
                      ? normalizeScaled(wsolBalance, SOL_DECIMALS)
                      : 0}{" "}
                    WSOL
                  </RadixTabs.Trigger>
                </div>
                {wrapUnwrapToggle === "wrap" && (
                  <Button variant="flat" onClick={onClickMax}>
                    MAX
                  </Button>
                )}
              </RadixTabs.List>
              <div className="w-full h-px bg-cloud -mt-px" />

              <RadixTabs.Content value="wrap">
                <div className="pt-5 divide-y flex flex-col divide-cloud font-bold">
                  <div className="flex gap-4 justify-between items-center pb-5 px-3.5">
                    <span className="text-base flex items-center gap-1">
                      <TokenIcon
                        logoURI={solInfoQuery.data?.logoURI}
                        className="w-6 h-6"
                      />
                      SOL
                    </span>

                    <input
                      className="text-xl w-full text-gray-200 placeholder:text-gray-200 focus:text-white bg-transparent border-none text-right outline-none"
                      value={wrapInput.value}
                      onChange={(e) => {
                        wrapSolMutation.reset();
                        dispatch(updateWrapAmount(e.target.value));
                      }}
                      inputMode="decimal"
                      pattern="[0-9.,]*"
                      autoCorrect="off"
                      spellCheck="false"
                      autoComplete="off"
                      aria-label="Token amount"
                      placeholder="0.00"
                    />
                  </div>
                  <div>
                    <OneTimeCodePrompt>
                      {({ isCodeRequired }) => (
                        <div
                          className={clsx(
                            "flex flex-col items-center justify-center px-5 py-4",
                          )}
                        >
                          <Button
                            color="primary"
                            className="flex w-full"
                            isLoading={wrapSolMutation.isLoading}
                            disabled={!validation.valid}
                            onClick={() => {
                              if (isCodeRequired) return;
                              void onSubmit();
                            }}
                          >
                            {wrapButtonText}
                          </Button>
                        </div>
                      )}
                    </OneTimeCodePrompt>
                    {signature && (
                      <Button
                        variant="flat"
                        icon={<Icon name="external" className="w-4" />}
                        className="w-full"
                        onClick={() => {
                          window.open(
                            `https://solscan.io/tx/${signature}`,
                            "_blank",
                          );
                        }}
                      >
                        View on explorer
                      </Button>
                    )}
                  </div>
                </div>
              </RadixTabs.Content>
              <RadixTabs.Content value="unwrap">
                <OneTimeCodePrompt>
                  {({ isCodeRequired }) => (
                    <div
                      className={clsx(
                        "flex flex-col items-center justify-center px-5",
                        {
                          "pt-4": unwrapSolMutation.data,
                          "py-4": !unwrapSolMutation.data,
                        },
                      )}
                    >
                      <Button
                        color="primary"
                        className="flex w-full"
                        isLoading={unwrapSolMutation.isLoading}
                        onClick={() => {
                          if (isCodeRequired) return;
                          void onSubmit();
                        }}
                        disabled={
                          (!wsolAccountQuery.isLoading &&
                            !unwrapSolMutation.isLoading &&
                            (!wsolBalance || BigInt(wsolBalance) === 0n)) ||
                          !validation.valid
                        }
                      >
                        {unwrapButtonText}
                      </Button>
                    </div>
                  )}
                </OneTimeCodePrompt>
                {signature && (
                  <Button
                    variant="flat"
                    icon={<Icon name="external" className="w-4" />}
                    className="w-full"
                    onClick={() => {
                      window.open(
                        `https://solscan.io/tx/${signature}`,
                        "_blank",
                      );
                    }}
                  >
                    View on explorer
                  </Button>
                )}
              </RadixTabs.Content>
            </RadixTabs.Root>
          </div>
        </Shimmer.Text>
        <span className="flex justify-center pt-4">
          {wrapUnwrapToggle === "wrap" && (
            <>
              {wrapErrorTitle ? (
                <ErrorMessage
                  variant="rounded"
                  className="mt-1"
                  tooltip={wrapErrorMsg}
                >
                  {wrapErrorTitle}
                </ErrorMessage>
              ) : showMaxSolWarning ? (
                <ErrorMessage
                  variant="rounded"
                  color="warning"
                  className="mt-1"
                  tooltip="You are wrapping all of your SOL. You will no longer have SOL to pay for future transactions."
                >
                  No Remaining SOL
                </ErrorMessage>
              ) : null}
            </>
          )}
          {unwrapErrorTitle && wrapUnwrapToggle === "unwrap" && (
            <ErrorMessage
              variant="rounded"
              className="mt-1"
              tooltip={unwrapErrorMsg}
            >
              {unwrapErrorTitle}
            </ErrorMessage>
          )}
        </span>
      </div>
    </Page>
  );
}
