import { TokenBalanceEntry, tokenApi } from "@/api";
import {
  formatNumberInput,
  formatTokenNumber,
  getTokenBalanceNominalValue,
  normalizeScaled,
  parseAsScaled,
} from "@/utils";
import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { setIsUsd as tradeSetIsUsd } from "../trade/-reducer";
import { REHYDRATE, rehydrateReducer } from "@/store";

export interface WithdrawFlowState {
  token: TokenBalanceEntry | undefined;
  withdrawInput: string | undefined;
  tokenQuantity: string | undefined;
  isUsd: boolean;
  address: string | undefined;
}

const initialState: WithdrawFlowState = {
  token: undefined,
  tokenQuantity: undefined,
  withdrawInput: undefined,
  address: undefined,
  isUsd: true,
};

const withdrawFlowSlice = createSlice({
  name: "withdrawFlow",
  initialState,
  reducers: {
    [REHYDRATE]: rehydrateReducer,
    resetWithdrawFlow: (state) => {
      const newState = { ...initialState };
      // Retain isUsd across reset
      newState.isUsd = state.isUsd;
      // Retain address across reset
      newState.address = state.address;
      return newState;
    },
    setToken: (state, action: PayloadAction<TokenBalanceEntry>) => {
      state.token = action.payload;
    },
    setAmount: (
      state,
      action: PayloadAction<{
        amount: string;
        priorityFee: number;
      }>,
    ) => {
      if (state.isUsd && action.payload.amount.split(".")[1]?.length > 2) {
        return;
      }
      const formatOptions = state.isUsd
        ? { currency: "fiat" as const, showFractionTruncatedMark: true }
        : {
            currency: "token" as const,
            tokenDecimals: state.token?.decimals ?? 2,
          };

      const formatted = formatNumberInput(action.payload.amount, formatOptions);
      state.withdrawInput = formatted;
      if (!state.isUsd) {
        state.tokenQuantity = formatted;
      } else if (state.token) {
        state.tokenQuantity = formatTokenNumber(
          state.token.price ? Number(formatted) / state.token.price : 0,
          state.token.decimals,
          { decimalsMode: "fixed", noGroups: true },
        );
      }
    },
    setIsUsd: innerSetIsUsd,
    setAddress: (state, action: PayloadAction<string>) => {
      state.address = action.payload;
    },
    setPresetAmount: (
      state,
      action: PayloadAction<{
        tokenQuantity: string;
        priorityFee: number;
      }>,
    ) => {
      if (!state.token) return;

      const { tokenQuantity } = action.payload;
      const newTokenBalance = normalizeScaled(
        tokenQuantity,
        state.token.decimals,
      ).toString();
      state.tokenQuantity = newTokenBalance;

      if (state.isUsd) {
        state.withdrawInput = formatNumberInput(
          getTokenBalanceNominalValue(
            parseAsScaled(newTokenBalance, state.token.decimals) ?? 0n,
            state.token.decimals,
            state.token.price ?? 0,
          ),
          { currency: "fiat", showFractionTruncatedMark: true },
        );
      } else {
        state.withdrawInput = newTokenBalance;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(tradeSetIsUsd, innerSetIsUsd);
    // Refresh token price when tokens are refetched
    builder.addMatcher(
      tokenApi.endpoints.tokenPrice.matchFulfilled,
      (state, action) => {
        if (!state.token) return;
        if (!action.payload) return;

        if (action.meta.arg.originalArgs === state.token.address) {
          state.token.price = action.payload.value;
        }
      },
    );
  },
});

function innerSetIsUsd(
  state: WithdrawFlowState,
  action: PayloadAction<boolean>,
) {
  state.isUsd = action.payload;
  if (state.isUsd) {
    state.withdrawInput = formatNumberInput(
      getTokenBalanceNominalValue(
        parseAsScaled(state.tokenQuantity ?? "0", state.token?.decimals ?? 2) ??
          0n,
        state.token?.decimals ?? 2,
        state.token?.price ?? 0,
      ),
      { currency: "fiat", showFractionTruncatedMark: true },
    );
  } else {
    state.withdrawInput = state.tokenQuantity;
  }
}

export const {
  setAddress,
  setAmount,
  setPresetAmount,
  setToken,
  resetWithdrawFlow,
  setIsUsd,
} = withdrawFlowSlice.actions;

export const withdrawFlowReducer = withdrawFlowSlice.reducer;
export const withdrawFlowActions = withdrawFlowSlice.actions;
