import { BaseIconProps } from "../icon";

export function CheckboxOnIcon(props: BaseIconProps) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.6167 7.23268L16.1083 8.74102V8.79935C16.2083 9.22435 16.25 9.66602 16.25 10.1077C16.25 13.7243 13.3083 16.6743 9.68333 16.6743C6.05833 16.6743 3.11667 13.7327 3.11667 10.1077C3.11667 6.48268 6.05833 3.54102 9.68333 3.54102C10.975 3.54102 12.2333 3.92435 13.3083 4.64102L13.375 4.69102L14.7167 3.34935L14.6167 3.27435C13.1667 2.22435 11.4583 1.66602 9.68333 1.66602C5.03333 1.66602 1.25 5.44935 1.25 10.0993C1.25 14.7493 5.03333 18.5327 9.68333 18.5327C14.3333 18.5327 18.1167 14.7493 18.1167 10.0993C18.1167 9.18268 17.9667 8.27435 17.6583 7.38268L17.6083 7.22435L17.6167 7.23268Z"
        fill="currentColor"
      />
      <path
        d="M17.4333 2.69935L8.85 11.2743L6.10833 8.53268L4.78333 9.85768L8.78333 13.8577L8.85 13.9327L18.7583 4.02435L17.4333 2.69935Z"
        fill="currentColor"
      />
    </svg>
  );
}
