import * as RadixAccordion from "@radix-ui/react-accordion";
import { Row } from "./row";
import { Content } from "./content";

export const Accordion = {
  Root: RadixAccordion.Root,
  Item: RadixAccordion.Item,
  Row,
  Content,
};
