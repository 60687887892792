import { BaseIconProps } from "../icon";

export function CirclePartialIcon({
  size = "default",
  ...props
}: BaseIconProps) {
  return size === "small" ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6183 3.05964C11.3047 2.50571 9.8429 2.38343 8.45226 2.71115C7.06162 3.03887 5.8171 3.79893 4.90545 4.87727C3.9938 5.95562 3.46418 7.2941 3.39604 8.69188C3.32791 10.0897 3.72494 11.4714 4.52756 12.6297C5.33019 13.788 6.49513 14.6605 7.84758 15.1162C9.20003 15.5719 10.6671 15.5862 12.0286 15.1571L12.6751 17.1202C10.8795 17.6862 8.94471 17.6672 7.16104 17.0662C5.37737 16.4653 3.84101 15.3146 2.78247 13.787L3.65502 13.2083L2.78247 13.787C1.72394 12.2593 1.20032 10.4371 1.29018 8.59364C1.38003 6.75018 2.07853 4.98494 3.28085 3.56278C4.48317 2.14062 6.12448 1.13822 7.95852 0.706009C9.79255 0.273793 11.7204 0.43506 13.4528 1.16561C15.1852 1.89617 16.6287 3.15661 17.5668 4.75782C18.5049 6.35903 18.8869 8.21466 18.6555 10.0463L16.5632 9.79333C16.7386 8.40452 16.4489 6.99751 15.7377 5.78341C15.0264 4.5693 13.9318 3.61358 12.6183 3.05964Z"
        fill="currentColor"
      />
    </svg>
  ) : (
    <svg
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M33.7367 47.3533C28.9603 49.2201 23.7116 49.5079 18.7597 48.1745C13.8079 46.8411 9.41336 43.9567 6.22022 39.9439C3.02708 35.9311 1.20323 31.001 1.016 25.8762C0.82877 20.7514 2.288 15.7014 5.17981 11.4663C8.07163 7.23124 12.244 4.03379 17.0853 2.34261C21.9266 0.651429 27.1824 0.555453 32.0822 2.06875C36.9821 3.58205 41.2684 6.62503 44.3129 10.7517C47.3574 14.8784 49 19.8718 49 25"
        stroke="currentColor"
        strokeWidth={props.strokeWidth ?? 2}
      />
    </svg>
  );
}
