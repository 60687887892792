import { Button, Drawer } from "@/components";
import { useSession } from "@/hooks";
import { useIsFastSigninEnabled } from "@/hooks/useIsFastSigninEnabled";
import { getRedirectPath } from "@/utils";
import React from "react";

interface OneTimeCodePromptProps {
  altDescription?: string;
  altButtonText?: string;
  children: (props: { isCodeRequired: boolean }) => React.ReactNode;
}

export function OneTimeCodePrompt({
  children,
  altDescription,
  altButtonText,
}: OneTimeCodePromptProps) {
  const isFastSigninEnabled = useIsFastSigninEnabled();
  const { isSessionActive, hasPasskeys } = useSession();
  const isCodeRequired = isFastSigninEnabled
    ? false
    : !isSessionActive && !hasPasskeys;

  if (!isCodeRequired) {
    return <>{children({ isCodeRequired })}</>;
  }

  return (
    <Drawer.Root>
      <Drawer.Trigger asChild>{children({ isCodeRequired })}</Drawer.Trigger>
      <Drawer.Content title="Set up signing method">
        <p className="text-gray-200 text-sm">
          {altDescription ??
            "A one-time code is required to sign transactions."}
        </p>
        <Button
          variant="big"
          className="my-10 w-full"
          to="/profile/wallet-settings/create-session"
          search={{
            redirect: getRedirectPath(),
          }}
        >
          {altButtonText ?? "Authorize transaction"}
        </Button>
      </Drawer.Content>
    </Drawer.Root>
  );
}
