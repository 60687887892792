import {
  Button,
  ChooseTokenModal,
  CurrencyInput,
  TransactionErrorMessage,
  TransactionErrorMessageProps,
  Icon,
  Link,
  NumericKeyboard,
  Page,
  TokenIcon,
  handleNumericKeyboardKey,
} from "@/components";
import { TotalPercentagePresets } from "@/components/preset-selectors";
import { useAccountBalance, useTotalPercentagePresets } from "@/hooks";
import { calculatePriorityFee, getIsNativeSol } from "@/routes/withdraw/-utils";
import { useAppDispatch, useTypedSelector } from "@/store";
import {
  NATIVE_SOL_ACCOUNT_RENT,
  SOL_DECIMALS,
  TOKEN_ACCOUNT_RENT,
  formatAddressShort,
  formatTokenNumber,
  formatUsdTokenAmountValue,
  getSymbolWithDefault,
  getTokenBalanceNominalValue,
  normalizeScaled,
  parseAsScaled,
} from "@/utils";
import { createFileRoute } from "@tanstack/react-router";
import { useEffect, useRef } from "react";
import { setAmount, setIsUsd, setPresetAmount, setToken } from "../-reducer";
import { useValidation } from "../-validation";

export const Route = createFileRoute("/withdraw/amount/")({
  component: AmountView,
});

function AmountView() {
  const dispatch = useAppDispatch();
  const { address, token, withdrawInput, tokenQuantity, isUsd } =
    useTypedSelector((state) => state.withdrawFlow);
  const solTypeOverride = token && getIsNativeSol(token) ? "sol" : "wsol";
  const accountBalanceQuery = useAccountBalance(
    token?.address,
    solTypeOverride,
  );
  const accountBalance = accountBalanceQuery.data?.rawBalance;
  // Update token quantity in store when account balance changes
  useEffect(() => {
    if (!token) return;
    if (!accountBalance) return;
    if (token.quantity === accountBalance) return;
    dispatch(setToken({ ...token, quantity: accountBalance }));
  });
  const priorityFee = useTypedSelector((state) => state.profile.priorityFee);
  // Set isUsd to false if we don't have a price
  useEffect(() => {
    if (isUsd && token && !token.price) {
      dispatch(setAmount({ amount: "", priorityFee }));
      dispatch(setIsUsd(false));
    }
  });
  const withdrawPriorityFee = calculatePriorityFee(priorityFee);
  const {
    reset: resetPresets,
    overdraft: presetOverdraft,
    ...totalPercentagePresetProps
  } = useTotalPercentagePresets(token?.address, solTypeOverride);
  const tokenPrice = token?.price ?? 0;
  const inputValue = withdrawInput?.length ? withdrawInput : "0";
  const scaledValue = parseAsScaled(inputValue, token?.decimals ?? 0);

  const { tokenValidation, addressValidation, amountValidation } =
    useValidation();
  const inputRef = useRef<HTMLInputElement>(null);

  let error: TransactionErrorMessageProps | undefined;
  switch (true) {
    case amountValidation.kind === "insufficient_sol":
      error = {
        title: "Insufficient SOL",
        message: (
          <p>
            You need enough SOL in your account to cover the base fee, priority
            fee and minimum SOL required. You can reduce your priority fee to
            lower the required SOL amount.
          </p>
        ),
        color: "negative",
      };
      break;
    case amountValidation.kind === "insufficient_rent_for_source" ||
      amountValidation.kind === "insufficient_rent_for_destination":
      error = {
        title: "Min sol required",
        message:
          solTypeOverride === "sol" ? (
            <>
              <p>
                The smallest amount of SOL that can be held in a Solana account
                is {normalizeScaled(NATIVE_SOL_ACCOUNT_RENT, SOL_DECIMALS)} SOL.
                If the destination account doesn’t hold this minimum, the input
                amount must cover this.
              </p>
              <p className="mt-4">
                Click <b>MAX</b> to withdraw the maximum amount of SOL.
              </p>
            </>
          ) : (
            <>
              <p>
                The smallest amount of SOL that can be held in an SPL account is{" "}
                {normalizeScaled(TOKEN_ACCOUNT_RENT, SOL_DECIMALS)} SOL. If the
                destination account does not hold any tokens, the source account
                must cover this amount.
              </p>
              <p className="mt-4">
                Ensure you have enough SOL in your account to cover fees and
                rent
              </p>
            </>
          ),
        color: "negative",
      };
      break;
    case amountValidation.kind === "max_amount" || presetOverdraft.overdrafted:
      error = {
        title: "Insufficient funds",
        message: (
          <p>
            You do not have enough {getSymbolWithDefault(token)} in your
            account.
          </p>
        ),
        color: "negative",
      };
      break;
  }

  return (
    <Page
      flex
      fullScreenHeight
      hideTabs
      title="Withdraw"
      backButton={{
        icon: "close",
        to: "/portfolio",
        "aria-label": "Back to portfolio",
      }}
    >
      <div className="w-full">
        <div className="flex flex-col h-[calc(var(--h-page-tabless)-276px-var(--safe-bottom))] justify-between px-5 pt-4 w-full overflow-y-auto">
          <div className="mb-6">
            <CurrencyInput
              ref={inputRef}
              value={inputValue}
              aria-label={
                isUsd
                  ? "USD amount"
                  : token
                    ? `${getSymbolWithDefault(token)} amount`
                    : "Token amount"
              }
              currency={isUsd ? "USD" : token?.symbol}
              onChange={(e) => {
                resetPresets();
                dispatch(
                  setAmount({
                    amount: e.target.value,
                    priorityFee,
                  }),
                );
              }}
              preventFocusOnClick
              onChangeIsUsd={
                token
                  ? () => {
                      resetPresets();
                      dispatch(setIsUsd(!isUsd));
                    }
                  : undefined
              }
              swapValue={
                isUsd && token
                  ? `${formatTokenNumber(tokenQuantity, token.decimals, {
                      decimalsMode: "fixed",
                    })} ${getSymbolWithDefault(token)}`
                  : token
                    ? formatUsdTokenAmountValue(
                        getTokenBalanceNominalValue(
                          scaledValue ?? 0n,
                          token.decimals,
                          tokenPrice,
                        ),
                        { showFractionTruncatedMark: true },
                      )
                    : ""
              }
              token={token}
              usdSwitchDisabled={token && !token.price}
            />
          </div>

          <div className="flex flex-col">
            {token ? (
              <>
                {error ? <TransactionErrorMessage {...error} /> : null}
                <div className="mb-3.5 mt-4">
                  <p className="text-sm font-bold text-gray-200">
                    {isUsd
                      ? formatUsdTokenAmountValue(
                          Number(
                            normalizeScaled(
                              accountBalance ?? token.quantity,
                              token.decimals,
                            ),
                          ) * tokenPrice,
                          { moneyMaxDecimals: 4 },
                        )
                      : formatTokenNumber(
                          normalizeScaled(
                            accountBalance ?? token.quantity,
                            token.decimals,
                          ),
                          token.decimals,
                          { decimalsMode: "fixed" },
                        )}{" "}
                    {isUsd ? "USD" : getSymbolWithDefault(token, true)}{" "}
                    <span className="uppercase">available to withdraw</span>
                  </p>
                </div>
              </>
            ) : null}
            <div className="divide-y divide-cloud border-y border-cloud">
              <ChooseTokenModal
                title="Select token to withdraw"
                selectedToken={token}
                onlyWalletTokens
                onSelectToken={(newToken) => {
                  resetPresets();
                  dispatch(setToken(newToken));
                }}
                solType="any"
                triggerClassName="w-full h-info-row flex items-center justify-between py-3"
              >
                <p className="uppercase text-xs font-bold">TOKEN</p>
                <div className="flex items-center justify-between">
                  {!token ? (
                    <div className="w-5 h-5 rounded-full bg-white/10 flex items-center justify-center">
                      <Icon name="coin" className="w-3.5 h-3.5 text-white/10" />
                    </div>
                  ) : (
                    <TokenIcon
                      logoURI={token.logoURI}
                      className="w-[25px] h-[25px]"
                    />
                  )}
                  <span className="text-sm font-bold ml-1.5">
                    {token ? getSymbolWithDefault(token) : "Select"}
                  </span>
                  <Icon name="chevron" className="w-3 h-3 -rotate-90 ml-1.5" />
                </div>
              </ChooseTokenModal>
              <Link
                to="/withdraw/address"
                className="w-full h-info-row flex items-center justify-between py-3"
              >
                <div className="w-full h-info-row flex items-center justify-between py-3">
                  <p className="uppercase text-xs font-bold">TO</p>
                  <div className="flex items-center">
                    <span className="text-sm font-bold ml-1.5">
                      {address
                        ? formatAddressShort(address, 5)
                        : "Enter address"}
                    </span>
                    <Icon
                      name="chevron"
                      className="w-3 h-3 -rotate-90 ml-1.5"
                    />
                  </div>
                </div>
              </Link>
            </div>
            <TotalPercentagePresets
              {...totalPercentagePresetProps}
              minimumWalletSolBalance={0n}
              priorityFee={withdrawPriorityFee}
              onSelect={(dividedAmount) => {
                dispatch(
                  setPresetAmount({
                    tokenQuantity: dividedAmount.toString(),
                    priorityFee,
                  }),
                );
              }}
            />
            <div className="fixed bottom-0 left-0 right-0">
              <Button
                variant="big"
                color={
                  !tokenValidation.valid ||
                  !amountValidation.valid ||
                  !addressValidation.valid ||
                  !!presetOverdraft.amount
                    ? "gray"
                    : "primary"
                }
                disabled={
                  !tokenValidation.valid ||
                  !amountValidation.valid ||
                  !addressValidation.valid ||
                  !!presetOverdraft.amount
                }
                className="w-full"
                to="/withdraw/confirm"
              >
                {(!!presetOverdraft.amount &&
                  `Deposit ${normalizeScaled(presetOverdraft.amount.toString(), SOL_DECIMALS)} SOL to proceed`) ||
                  tokenValidation.message ||
                  addressValidation.message ||
                  amountValidation.message ||
                  "Review"}
              </Button>
              <NumericKeyboard
                onKeyClick={(key) => {
                  const newAmount = handleNumericKeyboardKey(
                    key,
                    inputValue,
                    inputRef.current,
                  );

                  resetPresets();
                  dispatch(
                    setAmount({
                      amount: newAmount,
                      priorityFee,
                    }),
                  );
                }}
                onBackspaceLongPress={() => {
                  dispatch(
                    setAmount({
                      amount: "",
                      priorityFee,
                    }),
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}
