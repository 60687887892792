interface ListIllustrationProps {
  className?: string;
}

export function ListIllustration({ className }: ListIllustrationProps) {
  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M50.8857 62.9999L55.7354 60.1999M50.8857 62.9999L23.0005 46.8999M50.8857 62.9999L50.8859 68.6002M55.7354 60.1999L27.8501 44.0999L23.0005 46.8999M55.7354 60.1999L55.7355 65.8002L50.8859 68.6002M23.0005 46.8999V52.4999L50.8859 68.6002M50.8858 46.1996L55.7354 43.3996M50.8858 46.1996L23.0006 30.0996M50.8858 46.1996L50.8857 51.7999M55.7354 43.3996L27.8502 27.2996L23.0006 30.0996M55.7354 43.3996L55.7353 48.9999L50.8857 51.7999M23.0006 30.0996L23.0005 35.6999L50.8857 51.7999M50.8857 29.3999L55.7354 26.5999M50.8857 29.3999L23.0005 13.2999M50.8857 29.3999L50.8857 34.9999M55.7354 26.5999L27.8501 10.4999L23.0005 13.2999M55.7354 26.5999L55.7353 32.1999L50.8857 34.9999M23.0005 13.2999L23.0006 18.8996L50.8857 34.9999M16.9384 9.8002L21.788 7.0002M16.9384 9.8002L12.0874 7.0002M16.9384 9.8002L16.9385 15.3999M21.788 7.0002L16.9384 4.2002L12.0874 7.0002M21.788 7.0002L21.7881 12.5999L16.9385 15.3999M12.0874 7.0002L12.0875 12.5999L16.9385 15.3999M16.9385 26.5999L21.7881 23.7999M16.9385 26.5999L12.0875 23.7999M16.9385 26.5999L16.9386 32.1996M21.7881 23.7999L16.9385 20.9999L12.0875 23.7999M21.7881 23.7999L21.7882 29.3996L16.9386 32.1996M12.0875 23.7999L12.0874 29.3996L16.9386 32.1996M16.9384 43.3996L21.788 40.5996M16.9384 43.3996L12.0874 40.5996M16.9384 43.3996L16.9385 48.9999M21.788 40.5996L16.9384 37.7996L12.0874 40.5996M21.788 40.5996L21.7881 46.1999L16.9385 48.9999M12.0874 40.5996L12.0875 46.1999L16.9385 48.9999"
        stroke="#FFF6D5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
