import { isTransactionPending } from "@/app/background-transactions/utils";
import { getRedirectPath } from "@/utils";
import { useNavigate } from "@tanstack/react-router";
import { TransactionToast, TransactionToastProps } from "@/components";
import { useEffect } from "react";
import { useResumePendingTransactions } from "./use-resume-pending-transactions";

export function BackgroundTransactionToast() {
  const { showToast, setShowToast, popTransactionUpdate, latestUpdate } =
    useResumePendingTransactions();

  const tStatus = latestUpdate?.status;
  const transactionIsPending =
    latestUpdate && isTransactionPending(latestUpdate);
  const status: TransactionToastProps["status"] = transactionIsPending
    ? "pending"
    : tStatus === "succeeded"
      ? "success"
      : tStatus === "failed" || tStatus === "expired" || tStatus === "unknown"
        ? "failed"
        : "neutral";
  const pair = latestUpdate?.swapPair;
  const navigate = useNavigate();

  const onOpenChangeHandler = (value: boolean) => {
    setShowToast(value);
    if (!value) {
      popTransactionUpdate();
    }
  };

  useEffect(() => {
    if (!showToast) return;

    const duration = 5000;
    const timeout = setTimeout(() => {
      onOpenChangeHandler(false);
    }, duration);

    return () => clearTimeout(timeout);
  });

  return (
    <TransactionToast
      open={showToast}
      onOpenChange={onOpenChangeHandler}
      // We manually close the toast instead of using this duration prop
      duration={60 * 60 * 1000}
      status={status}
      animationStyle="slideDown"
      onClick={() => {
        const search = { redirect: getRedirectPath() };
        const id = latestUpdate?.pendingOrderId;
        if (!id) return;
        void navigate({
          to: `/orders/pending/${id}`,
          search,
        });
      }}
      title={
        tStatus === "sending"
          ? "Sending order..."
          : tStatus === "confirming"
            ? "Confirming order..."
            : tStatus === "succeeded"
              ? "Trade succeeded"
              : tStatus === "failed"
                ? "Transaction failed"
                : tStatus === "expired"
                  ? "Transaction expired"
                  : tStatus === "unknown"
                    ? "Transaction status unknown"
                    : undefined
      }
      baseToken={pair?.base}
      quoteToken={pair?.quote}
    />
  );
}
