import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { type Timeframe } from "./token-price";
import { REHYDRATE, rehydrateReducer } from "@/store";

export type BasePricingMode = "sendToken" | "receiveToken";
export type ComparePricingMode = "sendToken" | "receiveToken" | "USD";

export const isBasePricingMode = (value: string): value is BasePricingMode =>
  ["sendToken", "receiveToken"].includes(value);

export const isComparePricingMode = (
  value: string,
): value is ComparePricingMode => isBasePricingMode(value) || value === "USD";

interface TradeChartState {
  token?: string;
  basePricingMode: BasePricingMode;
  comparePricingMode: ComparePricingMode;
  mode: Timeframe["label"];
}

const initialState: TradeChartState = {
  token: undefined,
  basePricingMode: "receiveToken",
  comparePricingMode: "USD",
  mode: "24H",
};

const tradeChartSlice = createSlice({
  name: "tradeChart",
  initialState,
  reducers: {
    [REHYDRATE]: rehydrateReducer,
    updatePricingMode: (state, action: PayloadAction<[string, string]>) => {
      const [base, compare] = action.payload;
      if (isBasePricingMode(base) && isComparePricingMode(compare)) {
        state.basePricingMode = base;
        state.comparePricingMode = compare;
      }
    },
    updateChartMode: (state, action: PayloadAction<Timeframe>) => {
      state.mode = action.payload.label;
    },
    reset: (_, action: PayloadAction<string>) => ({
      ...initialState,
      token: action.payload,
    }),
  },
});

export const { updatePricingMode, updateChartMode, reset } =
  tradeChartSlice.actions;

export const tradeChartReducer = tradeChartSlice.reducer;
export const tradeChartActions = tradeChartSlice.actions;
