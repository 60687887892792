import { useSetMigrationStatusMutation } from "@/api";
import { useRouter } from "@tanstack/react-router";
import { useCallback } from "react";

export function useMigrationStatus() {
  const router = useRouter();
  const search = router.parseLocation().search;
  const [setMigrationStatus, completeMigrationMutation] =
    useSetMigrationStatusMutation();

  const handleSkip = useCallback(() => {
    void setMigrationStatus("SKIPPED");
    void router.navigate({ to: search.redirect });
  }, [router, search.redirect, setMigrationStatus]);

  const handleComplete = useCallback(() => {
    void setMigrationStatus("COMPLETED");
    void router.navigate({ to: search.redirect });
  }, [router, search.redirect, setMigrationStatus]);

  return { completeMigrationMutation, handleSkip, handleComplete };
}
