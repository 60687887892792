import { BaseIconProps } from "../icon";

export function FailureIcon(props: BaseIconProps) {
  return (
    <svg
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.5 1.125C3.71111 1.125 0.625 4.21111 0.625 8C0.625 11.7889 3.71111 14.875 7.5 14.875C11.2889 14.875 14.375 11.7889 14.375 8C14.375 4.21111 11.2889 1.125 7.5 1.125ZM7.5 2.65278C8.64074 2.65278 9.76111 3.01944 10.6676 3.68148L3.25278 11.244C2.54491 10.3171 2.15278 9.1662 2.15278 8C2.15278 5.05139 4.55139 2.65278 7.5 2.65278ZM7.5 13.3472C6.35926 13.3472 5.23889 12.9806 4.33241 12.3185L11.7472 4.75602C12.4551 5.68287 12.8472 6.8338 12.8472 8C12.8472 10.9486 10.4486 13.3472 7.5 13.3472Z"
        fill="currentColor"
      />
    </svg>
  );
}
