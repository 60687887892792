import { TokenInfo } from "@/api";
import { Icon } from "@/components";
import { cn } from "@/utils";
import { useState } from "react";

interface TokenProps extends Pick<TokenInfo, "symbol" | "logoURI"> {
  alt?: string;
  className?: string;
  showUnverified?: boolean;
}

export const TokenIcon = ({
  showUnverified,
  logoURI,
  alt,
  className,
}: TokenProps) => {
  const [hasError, setHasError] = useState(false);
  return (
    <div className={cn("relative w-fit", className)}>
      {logoURI && !hasError ? (
        <img
          src={logoURI}
          alt={alt || ""}
          className={cn("rounded-full bg-cloud")}
          onLoad={() => setHasError(false)}
          onError={() => setHasError(true)}
        />
      ) : (
        <Icon name="unknown" />
      )}
      {showUnverified ? (
        <Icon
          name="warning"
          className="w-5 h-5 text-accent absolute right-0 bottom-0"
        />
      ) : null}
    </div>
  );
};
