import { useEffect, useRef } from "react";

export function useIntervalWhenFocused(callback: () => void, interval: number) {
  const savedCallback = useRef<() => void>();
  savedCallback.current = callback;
  const timeCalled = useRef(0);

  useEffect(() => {
    const id = setInterval(() => {
      if (document.hasFocus() && Date.now() - timeCalled.current > interval) {
        timeCalled.current = Date.now();
        savedCallback.current?.();
      }
    }, 1000);

    return () => clearInterval(id);
  }, [interval]);
}
