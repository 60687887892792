import { cn } from "@/utils";
import { VariantProps, cva } from "class-variance-authority";

const variants = cva("absolute w-3 h-3 text-positive", {
  variants: {
    flashing: { true: "animate-flash" },
  },
});

interface NotificationDotProps extends VariantProps<typeof variants> {
  className?: string;
}

export const NotificationDot = ({
  className,
  flashing,
}: NotificationDotProps) => (
  <svg
    className={cn(variants({ flashing }), className)}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 12 12"
    fill="none"
  >
    <rect width="12" height="12" rx="6" fill="currentColor" fillOpacity="0.5" />
    <circle cx="6" cy="6" r="4" fill="currentColor" />
  </svg>
);
