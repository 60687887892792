import { BaseIconProps } from "../icon";

export function PieChartIcon(props: BaseIconProps) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.3919 0V1.04762V11.0859L20.2776 3.24721L19.5323 2.50651C17.9756 0.95946 15.818 0 13.4461 0H12.3919ZM10.2836 1.10082C4.96323 1.62672 0.791992 6.09924 0.791992 11.5238C0.791992 17.3004 5.52098 22 11.3337 22C17.1463 22 21.8753 17.3004 21.8753 11.5238C21.8753 9.55114 21.3141 7.70997 20.3558 6.13225L18.8137 7.66685C19.4199 8.82238 19.767 10.1326 19.767 11.5238C19.767 13.4571 19.0968 15.2339 17.986 16.6535L13.8785 12.5714L12.3878 14.0528L16.4954 18.1349C15.0669 19.2387 13.279 19.9048 11.3337 19.9048C7.0416 19.9048 3.49398 16.6986 2.97239 12.5714H10.2795V10.4762H2.97445C3.45325 6.69293 6.4756 3.68788 10.2836 3.21447V1.10082ZM14.5003 2.30599C15.4882 2.47228 16.3711 2.83474 17.1522 3.39044L14.5003 6.02381V2.30599Z"
        fill="currentColor"
      />
    </svg>
  );
}
