import { BaseIconProps } from "@/components";

export function BackArrowBoxed(props: BaseIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M11.7771 17.5408L13.3247 15.9588L10.5465 13.1189L17.6506 13.1189V10.881H10.5465L13.3247 8.0411L11.7771 6.4591L6.34893 11.9999L11.7771 17.5408Z"
        fill="currentColor"
      />
      <path
        d="M16.8959 0V2.23794H21.8106V7.26174H24V0H16.8959Z"
        fill="currentColor"
      />
      <path
        d="M2.18937 2.23794H7.10412V0H0V7.26174H2.18937V2.23794Z"
        fill="currentColor"
      />
      <path
        d="M21.8106 21.7621H16.8959V24H24V16.7383H21.8106V21.7621Z"
        fill="currentColor"
      />
      <path
        d="M2.18937 16.7383H0V24H7.10412V21.7621H2.18937V16.7383Z"
        fill="currentColor"
      />
    </svg>
  );
}
