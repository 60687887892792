import { Page, Accordion, KeyboardAwareButton } from "@/components";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { usePriorityFeeForm } from "../priority-fee/-use-priority-fee-hook";
import { PriorityFeeForm } from "../priority-fee/-priority-fee-form";
import { SlippageForm } from "../slippage/-slippage-form";
import { useSlippageForm } from "../slippage/-use-slippage-hook";

interface SearchParams {
  redirect: string;
}

export const Route = createFileRoute("/profile/trade-settings/")({
  component: TradeSettings,
  validateSearch: (searchParams?: Record<string, unknown>): SearchParams => {
    return {
      redirect:
        typeof searchParams?.redirect === "string"
          ? searchParams.redirect
          : "/profile",
    };
  },
});

function TradeSettings() {
  const search = Route.useSearch();
  const priorityFormProps = usePriorityFeeForm();
  const slippageFormProps = useSlippageForm();
  const navigate = useNavigate();

  return (
    <Page
      title="Trade Settings"
      hideTabs
      backButton={{
        to: decodeURI(search.redirect),
        "aria-label": "Back to trade",
      }}
    >
      <div className="space-y-6 pb-button-row mt-5">
        <Accordion.Root type="multiple" className="border-b border-cloud">
          <Accordion.Item value="priority-fee" className="group">
            <Accordion.Row
              value={priorityFormProps.unsavedPriorityFee.formattedValue}
            >
              Priority Fee
            </Accordion.Row>
            <Accordion.Content>
              <PriorityFeeForm {...priorityFormProps} />
            </Accordion.Content>
          </Accordion.Item>
          <Accordion.Item value="slippage" className="group">
            <Accordion.Row value={`${slippageFormProps.slippageState.value}%`}>
              Slippage
            </Accordion.Row>
            <Accordion.Content>
              <SlippageForm {...slippageFormProps} />
            </Accordion.Content>
          </Accordion.Item>
        </Accordion.Root>

        <KeyboardAwareButton
          placement="without-tabs"
          disabled={
            slippageFormProps.saveDisabled && priorityFormProps.saveDisabled
          }
          onClick={async () => {
            if (priorityFormProps.isLoading || slippageFormProps.isSaving) {
              return;
            }

            const promises = [];
            if (!priorityFormProps.saveDisabled) {
              promises.push(priorityFormProps.onSavePriorityFee());
            }
            if (!slippageFormProps.saveDisabled) {
              promises.push(slippageFormProps.onSaveSlippage());
            }

            const results = await Promise.all(promises);

            if (results.every((result) => !(result && "error" in result))) {
              void navigate({ to: decodeURI(search.redirect) });
            }
          }}
          className="shrink-0 w-full"
          isLoading={priorityFormProps.isLoading || slippageFormProps.isSaving}
        >
          Save Changes
        </KeyboardAwareButton>
      </div>
    </Page>
  );
}
