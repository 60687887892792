import { aggregatorApi, userApi, tokenApi, walletApi, xpApi } from "@/api";
import { getAuthToken } from "@dynamic-labs/sdk-react-core";
import {
  FetchArgs,
  createApi,
  fetchBaseQuery,
  retry,
} from "@reduxjs/toolkit/query/react";

export function getAuthHeaders() {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const bearerToken = getAuthToken();
  return bearerToken ? { Authorization: `Bearer ${bearerToken}` } : {};
}

const staggeredBaseQueryWithBailOut = retry(
  async (args: string | FetchArgs, api, extraOptions) => {
    if (typeof args !== "string" && args.body) {
      // automatically add Content-Type header if body is present
      args.headers = {
        "Content-Type": "application/json",
        ...args.headers,
      };
    }
    const result = await fetchBaseQuery()(args, api, extraOptions);

    // bail out of re-tries immediately for following statuses
    // because we know successive re-retries would be redundant
    if (
      result.error?.status === 401 ||
      result.error?.status === 404 ||
      result.error?.status === 403 ||
      result.error?.status === 400 ||
      result.error?.status === 429
    ) {
      retry.fail(result.error);
    }

    if (
      typeof result.data === "object" &&
      result.data !== null &&
      Object.keys(result.data).length === 1
    ) {
      // unwrap data object if it's the only key
      return { ...result, data: (result.data as { data: unknown }).data };
    }

    return result;
  },
  {
    maxRetries: 3,
  },
);

export const api = createApi({
  baseQuery: staggeredBaseQueryWithBailOut,
  tagTypes: [
    "WalletBalance",
    "TokenAccounts",
    "TokenAccountByMint",
    "Web3authWalletBalance",
    "User",
    "PreviousWithdrawalAddresses",
    "Order",
    "DestinationSolBalance",
    "DestinationTokenBalance",
    "Leaderboard",
    "UserXp",
    "FavoriteTokens",
    "Quote",
    "QuickTradeSettings",
  ],
  endpoints: () => ({}),
  keepUnusedDataFor: 60 * 2,
});

export type Endpoints = typeof userApi.endpoints &
  typeof xpApi.endpoints &
  typeof walletApi.endpoints &
  typeof aggregatorApi.endpoints &
  typeof tokenApi.endpoints;

export class UnauthorizedError extends Error {
  constructor(msg: string) {
    super(msg);
    this.name = "UnauthorizedError";
  }
}
