import { BaseIconProps } from "../icon";

export function SpeakerphoneIcon(props: BaseIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.1842 2L18.0789 4.22222L8.13158 7.55556H3.71053C2.49474 7.55556 1.5 8.55556 1.5 9.77778V14.2222C1.5 15.4444 2.49474 16.4444 3.71053 16.4444H5.92105V22H8.13158V16.4444L18.0789 19.7778L19.1842 22H21.3947V13.9141C22.0531 13.5293 22.5 12.8211 22.5 12C22.5 11.1789 22.0531 10.4707 21.3947 10.0859V2H19.1842ZM19.1842 6.19488V17.8051L18.7784 17.6684L10.3421 14.8429V9.15712L18.7784 6.3316L19.1842 6.19488ZM3.71053 9.77778H8.13158V14.2222H5.92105H3.71053V9.77778Z"
        fill="currentColor"
      />
    </svg>
  );
}
