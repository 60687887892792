import { DynamicProvider } from "@/providers/dynamic-provider.tsx";
import { RouterProvider } from "@tanstack/react-router";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { App } from "./app.tsx";
import { store } from "./app/store/store.ts";
import "./index.css";

// DON'T MOVE THIS IMPORT
import { router } from "@/router.ts";
import "./embedded-wallet";
import { ToastProvider } from "@radix-ui/react-toast";

//! Non-component named exports will break hmr!
//? Issue: https://github.com/vitejs/vite/discussions/4583#discussioncomment-4744751

async function enableMocking() {
  if (
    process.env.NODE_ENV !== "development" ||
    process.env.DEV_SW === "true" ||
    import.meta.env.VITE_USE_MOCKS === "false"
  ) {
    return;
  }

  const { worker } = await import("./mocks/browser");

  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return worker.start({ onUnhandledRequest: "bypass" });
}

enableMocking()
  .then(() => {
    const rootElement = document.getElementById("root");
    if (!rootElement) {
      throw new Error("No root element found");
    }

    ReactDOM.createRoot(rootElement).render(
      <React.StrictMode>
        <Provider store={store}>
          <DynamicProvider>
            <ToastProvider swipeDirection="up">
              <App>
                <RouterProvider router={router} />
              </App>
            </ToastProvider>
          </DynamicProvider>
        </Provider>
      </React.StrictMode>,
    );
  })
  .catch((error: unknown) => {
    console.error(error);
    throw error;
  });
