import {
  useQuickTradeSettingsQuery,
  useUpdateQuickTradeSettingsMutation,
  useUpdateSettingsMutation,
  useUserQuery,
} from "@/api";
import { useAppDispatch, useTypedSelector } from "@/store";
import {
  SOL_DECIMALS,
  formatNumberInput,
  formatTokenNumber,
  normalizeScaled,
  parseAsScaled,
} from "@/utils";
import { useIsLoggedIn } from "@dynamic-labs/sdk-react-core";
import { skipToken } from "@reduxjs/toolkit/query";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import { Reducer, useEffect, useReducer, useRef } from "react";
import {
  DEFAULT_PRIORITY_FEE,
  isValidNumberInput,
  updatePriorityFee,
} from "../-reducer";
import type { ToggleGroupData } from "../-toggle-group";

export const priorityFeeOptions: ToggleGroupData[] = [
  {
    label: "FAST",
    value: "1500000",
  },
  {
    label: "TURBO",
    value: "3500000",
  },
  {
    label: "EXTREME",
    value: "7500000",
  },
];

interface UnsavedPriorityFee {
  value: string;
  input: string;
  scaledValue: string;
  showSuffix: boolean;
  isPresetSelected: boolean;
}

type UpdateAction =
  | {
      type: "input";
      payload: string;
    }
  | {
      type: "preset";
      payload: string;
    }
  | {
      type: "focus";
    }
  | {
      type: "reset";
      payload: bigint;
    };

const getPriorityFeeInitialState = (priorityFee: bigint) => {
  const uiPriorityFee = getUiPriorityFee(priorityFee);
  return {
    value: uiPriorityFee,
    input: priorityFeeOptions.some((o) => o.value === priorityFee.toString())
      ? ""
      : uiPriorityFee,
    scaledValue: priorityFee.toString(),
    isPresetSelected: priorityFeeOptions.some(
      (o) => o.value === priorityFee.toString(),
    ),
    showSuffix: priorityFeeOptions.every(
      (o) => o.value !== priorityFee.toString(),
    ),
  };
};

const updateUnsavedPriorityFee: Reducer<UnsavedPriorityFee, UpdateAction> = (
  state,
  action,
) => {
  switch (action.type) {
    case "input": {
      if (!isValidNumberInput(action.payload)) return state;
      const scaledValue =
        parseAsScaled(action.payload, SOL_DECIMALS)?.toString() ?? "";
      return {
        ...state,
        input: formatNumberInput(action.payload, {
          currency: "token",
          tokenDecimals: 9,
        }),
        value: action.payload,
        scaledValue,
        isPresetSelected: false,
      };
    }
    case "preset": {
      const value = normalizeScaled(action.payload, SOL_DECIMALS);
      const scaledValue = action.payload;

      return {
        ...state,
        value,
        scaledValue,
        isPresetSelected: true,
      };
    }

    case "focus":
      return {
        ...state,
        showSuffix: true,
      };

    case "reset":
      return getPriorityFeeInitialState(action.payload);
  }
};

const getUiPriorityFee = (priorityFee: bigint) =>
  formatTokenNumber(normalizeScaled(priorityFee, SOL_DECIMALS), SOL_DECIMALS, {
    decimalsMode: "fixed",
    noGroups: true,
  });

export type PriorityFeeFormProps = Omit<
  ReturnType<typeof usePriorityFeeForm>,
  "onSavePriorityFee"
> & {
  className?: string;
  showValue?: boolean;
};

function useBasePriorityFeeForm(priorityFee: bigint, isLoading: boolean) {
  const uiPriorityFee = getUiPriorityFee(priorityFee);

  const [unsavedPriorityFee, dispatchUnsavedPriorityFee] = useReducer(
    updateUnsavedPriorityFee,
    getPriorityFeeInitialState(priorityFee),
  );

  const prevIsLoading = useRef(isLoading);
  useEffect(() => {
    if (prevIsLoading.current && !isLoading) {
      if (unsavedPriorityFee.value !== uiPriorityFee) {
        dispatchUnsavedPriorityFee({ type: "reset", payload: priorityFee });
      }
    }

    prevIsLoading.current = isLoading;
  }, [isLoading, unsavedPriorityFee.value, uiPriorityFee, priorityFee]);

  const formattedValue = `${formatTokenNumber(
    unsavedPriorityFee.value,
    SOL_DECIMALS,
    {
      decimalsMode: "fixed",
    },
  )} SOL`;

  const error =
    BigInt(unsavedPriorityFee.scaledValue) > BigInt(LAMPORTS_PER_SOL)
      ? "Priority fee must be 1 SOL or lower"
      : Number(unsavedPriorityFee.scaledValue) < 0
        ? "Priority fee must be 0 SOL or higher"
        : undefined;
  const saveDisabled =
    priorityFee.toString() === unsavedPriorityFee.scaledValue || Boolean(error);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatchUnsavedPriorityFee({ type: "input", payload: event.target.value });
  };

  const handleToggleGroup = (value: string) => {
    dispatchUnsavedPriorityFee({ type: "preset", payload: value });
  };

  const handleInputFocus = () => {
    dispatchUnsavedPriorityFee({ type: "focus" });
  };

  return {
    error,
    priorityFee,
    unsavedPriorityFee: { ...unsavedPriorityFee, formattedValue },
    saveDisabled,
    handleInputChange,
    handleToggleGroup,
    handleInputFocus,
  };
}

export function usePriorityFeeForm() {
  const isLoggedIn = useIsLoggedIn();
  const userQuery = useUserQuery(isLoggedIn ? undefined : skipToken);
  const [settingsMutate, settingsMutation] = useUpdateSettingsMutation();
  const priorityFee = useTypedSelector((state) =>
    BigInt(state.profile.priorityFee),
  );

  const formProps = useBasePriorityFeeForm(priorityFee, userQuery.isLoading);

  const dispatch = useAppDispatch();

  const onSavePriorityFee = () => {
    if (settingsMutation.isLoading || formProps.saveDisabled) return;

    const lamports = Math.round(
      Number(formProps.unsavedPriorityFee.scaledValue),
    );
    dispatch(updatePriorityFee(lamports));
    if (isLoggedIn) {
      return settingsMutate({ priorityFeeSetting: lamports });
    }
  };

  return {
    ...formProps,
    isLoading: userQuery.isLoading,
    priorityFee,
    onSavePriorityFee,
    isSaving: settingsMutation.isLoading,
  };
}

export function useQuickTradePriorityFeeForm() {
  const isLoggedIn = useIsLoggedIn();
  const quickTradeSettingsQuery = useQuickTradeSettingsQuery(
    isLoggedIn ? undefined : skipToken,
  );
  const [, quickTradeSettingsMutation] = useUpdateQuickTradeSettingsMutation();
  const priorityFee = BigInt(
    quickTradeSettingsQuery.data?.settings?.priorityFee ?? DEFAULT_PRIORITY_FEE,
  );

  const formProps = useBasePriorityFeeForm(
    priorityFee,
    quickTradeSettingsQuery.isLoading,
  );

  return {
    ...formProps,
    isLoading: quickTradeSettingsQuery.isLoading,
    priorityFee,
    isSaving: quickTradeSettingsMutation.isLoading,
  };
}
