import { useOneUserOrderQuery } from "@/api";
import {
  isTransactionPending,
  removeUpdatesForSignature,
  useBackgroundTransactions,
} from "@/app/background-transactions";
import {
  Button,
  ButtonRow,
  TransactionErrorMessage,
  Icon,
  LogoSpinner,
  Page,
} from "@/components";
import { withDynamicProtected } from "@/hocs/withDynamicProtected";
import { TradeDetails } from "@/routes/trade/confirm/-trade-details";
import { useAppDispatch, useTypedSelector } from "@/store";
import { SearchParamsWithRedirect } from "@/utils";
import { skipToken } from "@reduxjs/toolkit/query";
import { Navigate, createFileRoute } from "@tanstack/react-router";
import { useEffect } from "react";

export const Route = createFileRoute("/orders/pending/$pendingOrderId")({
  component: withDynamicProtected(PendingOrder),
  validateSearch: (
    searchParams?: Record<string, unknown>,
  ): SearchParamsWithRedirect => {
    return {
      redirect:
        typeof searchParams?.redirect === "string"
          ? searchParams.redirect
          : "/orders",
    };
  },
});

function PendingOrder() {
  const { pendingOrderId } = Route.useParams();
  const { redirect } = Route.useSearch();
  const backgroundTransactions = useBackgroundTransactions();
  const pendingTransaction = backgroundTransactions.find(
    (tx) => tx.pendingOrderId === Number(pendingOrderId),
  );
  const completedOrderQuery = useOneUserOrderQuery(
    pendingTransaction?.orderId ?? skipToken,
  );
  const quote = pendingTransaction?.quote;
  const status = pendingTransaction?.status;
  const error = pendingTransaction?.error;
  let title = "";
  switch (status) {
    case "sending":
      title = "Sending order...";
      break;
    case "confirming":
      title = "Confirming order...";
      break;
    case "succeeded":
      title = "Order completed!";
      break;
    case "failed":
      title = "Order failed";
      break;
    case "expired":
      title = "Order expired";
      break;
    case "unknown":
      title = "Order status unknown";
      break;
  }

  const isPageLoading =
    (pendingTransaction && isTransactionPending(pendingTransaction)) ||
    pendingTransaction?.isSaving;

  const dispatch = useAppDispatch();
  const updatesForPendingTransaction = useTypedSelector(
    (state) => state.backgroundTransactions.transactionUpdates,
  ).filter((tx) => tx.signature === pendingTransaction?.signature);

  // Remove transaction updates for viewed transaction
  useEffect(() => {
    if (
      pendingTransaction?.signature &&
      updatesForPendingTransaction.length > 0
    ) {
      dispatch(removeUpdatesForSignature(pendingTransaction.signature));
    }
  });

  if (!pendingTransaction) {
    return <Navigate to="/orders" />;
  }

  const completedSignature = completedOrderQuery.data?.signature;

  return (
    <Page
      flex
      fullScreenHeight
      hideTabs
      backButton={{
        to: redirect,
        isRedirect: true,
      }}
      title={title}
    >
      <div className="w-full flex flex-col p-5 pb-button-row">
        <div>
          {isPageLoading ? (
            <div className="mb-5 flex justify-center items-center">
              <LogoSpinner size="large" />
            </div>
          ) : status === "failed" ||
            status === "expired" ||
            status === "unknown" ? (
            <div className="flex flex-col justify-center items-center gap-2 mb-5">
              <div className="w-12 h-12 rounded-full border-negative border-2 flex justify-center items-center">
                <Icon name="warning" className="w-5 h-5 text-primary" />
              </div>
              {error && <TransactionErrorMessage {...error} />}
            </div>
          ) : status === "succeeded" ? (
            <div className="w-12 h-12 rounded-full border-positive border-2 flex justify-center items-center mx-auto mb-5">
              <Icon name="check" className="w-5 h-5 text-positive" />
            </div>
          ) : null}
        </div>
        {quote && (
          <>
            {isPageLoading ? (
              <TradeDetails
                type="quote"
                isFetching={completedOrderQuery.isLoading}
                isError={false}
                inAmount={quote.inAmount}
                outAmount={quote.outAmount}
                platformFeeBps={quote.platformFee?.feeBps ?? 0}
                inTokenMint={quote.inputMint}
                outTokenMint={quote.outputMint}
                priceImpactPct={Number(quote.priceImpactPct)}
              />
            ) : error || status !== "succeeded" ? (
              <TradeDetails
                type="error_order"
                isFetching={completedOrderQuery.isLoading}
                isError={true}
                inAmount={quote.inAmount}
                outAmount={quote.outAmount}
                platformFeeBps={quote.platformFee?.feeBps ?? 0}
                inTokenMint={quote.inputMint}
                outTokenMint={quote.outputMint}
              />
            ) : completedOrderQuery.data ? (
              <TradeDetails
                type="completed_order"
                isFetching={completedOrderQuery.isLoading}
                isError={completedOrderQuery.isError}
                inAmount={completedOrderQuery.data.inAmount}
                outAmount={completedOrderQuery.data.outAmount}
                platformFeeBps={completedOrderQuery.data.platformFeeBps ?? 0}
                inTokenMint={completedOrderQuery.data.inMint}
                outTokenMint={completedOrderQuery.data.outMint}
              />
            ) : null}
          </>
        )}

        {completedSignature && (
          <ButtonRow noTabs>
            <Button
              to={`https://solscan.io/tx/${completedSignature}`}
              className="w-full"
              icon={<Icon className="w-5" name="link" />}
            >
              View on explorer
            </Button>
          </ButtonRow>
        )}
      </div>
    </Page>
  );
}
