import { getAuthToken } from "@dynamic-labs/sdk-react-core";
import * as jose from "jose";

console.log(import.meta.env.VITE_DYNAMIC_CLIENT_ID);

const jwksExternal = jose.createRemoteJWKSet(
  new URL(
    `https://app.dynamicauth.com/api/v0/sdk/${import.meta.env.VITE_DYNAMIC_CLIENT_ID}/.well-known/jwks`,
  ),
);

interface JWTPayload extends jose.JWTPayload {
  email: string;
}

export async function decodeIdToken(idToken: string): Promise<JWTPayload> {
  const jwtDecoded = await jose.jwtVerify(idToken, jwksExternal, {
    algorithms: ["RS256"],
  });
  const payload = jwtDecoded.payload as JWTPayload;
  return payload;
}

export async function isAuthenticated() {
  const dynamicAuthToken = getAuthToken() as string | undefined;
  if (!dynamicAuthToken) return false;

  try {
    // this will throw an error if the token is invalid
    await decodeIdToken(dynamicAuthToken);
    return true;
  } catch {
    return false;
  }
}

export async function getJwtPayload() {
  const dynamicAuthToken = getAuthToken() as string | undefined;
  if (!dynamicAuthToken) return;

  try {
    return await decodeIdToken(dynamicAuthToken);
  } catch {
    return;
  }
}
