import { useEffect, useState } from "react";
import { useNavigate, useRouter } from "@tanstack/react-router";
import { LogoSpinner } from "@/components";
import { getRedirectPath } from "@/utils";
import { useWeb3AuthClient } from "@/hooks/useWeb3AuthClient";

export const withWeb3authProtected =
  (options?: { requireTkeyPassword: boolean }) =>
  (Component: React.ComponentType) => {
    const WithWeb3authProtectedWrapper = (props: {}) => {
      const [isLoading, setIsLoading] = useState(true);
      const [client, error] = useWeb3AuthClient();
      const navigate = useNavigate();
      const isLoggedIn = Boolean(client?.userInfo);
      const missingPassword =
        client !== undefined && client.tkey.getKeyDetails().requiredShares > 0;
      const { pathname } = useRouter().parseLocation();

      useEffect(() => {
        if (error) {
          if (pathname === "/migrate/login") {
            setIsLoading(false);
          } else {
            void navigate({
              to: "/migrate/login",
              replace: true,
              search: (prev) => ({
                ...prev,
                redirect:
                  ("redirect" in prev && prev.redirect
                    ? decodeURI(prev.redirect)
                    : undefined) ?? getRedirectPath(),
              }),
            });
          }
        } else if (missingPassword && options?.requireTkeyPassword) {
          if (pathname === "/migrate/password") {
            setIsLoading(false);
          } else {
            void navigate({
              to: "/migrate/password",
              replace: true,
              search: (prev) => prev,
            });
          }
        } else if (isLoggedIn) {
          if (!missingPassword && pathname === "/migrate/password") {
            void navigate({
              to: "/migrate/tokens",
              replace: true,
              search: (prev) => prev,
            });
          } else {
            setIsLoading(false);
          }
        }
      }, [error, missingPassword, isLoggedIn, navigate, pathname]);

      if (isLoading) {
        return (
          <div className="flex items-center justify-center h-full pb-tab-bar">
            <LogoSpinner size="large" />
          </div>
        );
      }

      return <Component {...props} />;
    };

    WithWeb3authProtectedWrapper.displayName = `withWeb3authProtected(${Component.displayName})`;

    return WithWeb3authProtectedWrapper;
  };
