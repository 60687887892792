import { Drawer } from "vaul";
import type { DialogCloseProps } from "@radix-ui/react-dialog";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { Dialog } from "@/components";

export function Close(props: DialogCloseProps) {
  const isDesktopAndUp = useMediaQuery("(min-width: 1024px)");
  return isDesktopAndUp ? (
    <Dialog.Close {...props} />
  ) : (
    <Drawer.Close {...props} />
  );
}
