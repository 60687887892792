import { KeyboardAwareButton, Page } from "@/components";
import { createFileRoute } from "@tanstack/react-router";
import { PriorityFeeForm } from "./-priority-fee-form";
import { usePriorityFeeForm } from "./-use-priority-fee-hook";
import { useNavigate } from "@tanstack/react-router";

export const Route = createFileRoute("/profile/priority-fee/")({
  component: ProfilePriorityFee,
});

function ProfilePriorityFee() {
  const formProps = usePriorityFeeForm();
  const navigate = useNavigate();

  return (
    <Page
      title="Priority Fee"
      backButton={{
        to: "/profile",
        search: (prev) => prev,
        "aria-label": "Back to profile",
      }}
      hideTabs
    >
      <PriorityFeeForm
        {...formProps}
        showValue
        className="px-5 pb-button-row"
      />
      <KeyboardAwareButton
        disabled={formProps.saveDisabled}
        onClick={async () => {
          if (!formProps.isSaving) {
            const result = await formProps.onSavePriorityFee();

            if (!(result && "error" in result)) {
              void navigate({ to: "/profile", search: (prev) => prev });
            }
          }
        }}
        className="shrink-0 w-full"
        placement="without-tabs"
        isLoading={formProps.isSaving}
      >
        Save
      </KeyboardAwareButton>
    </Page>
  );
}
