import { BaseIconProps } from "../icon";

export function FireIcon(props: BaseIconProps) {
  return (
    <svg
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.2199 0.166687L4.39811 1.49183C4.40834 1.50363 5.3908 2.65912 5.23111 4.0119C5.12944 4.87579 4.57578 5.67391 3.59175 6.376C0.699057 8.37044 -0.178223 11.0533 1.02076 13.6568C1.92289 15.6165 3.90044 16.8334 6.0268 16.8334H7.26499L6.48929 15.7144C5.86967 14.8991 5.88139 13.9674 6.02947 13.273C7.08378 14.0848 8.31518 13.9811 9.17091 13.4262C9.95636 12.9165 10.512 11.9986 10.5304 10.9034C10.7064 11.1868 10.8518 11.5168 10.9556 11.8827C11.2572 12.9459 11.2204 14.5676 10.1265 15.6384L8.98698 16.8334H10.0612C10.6693 16.8334 11.2793 16.7177 11.8232 16.4427C13.5217 15.5851 14.6679 14.0185 15.2725 11.7539C15.7645 9.91012 15.4502 7.47497 14.4089 5.57983C11.9939 1.16941 5.26644 0.373599 4.98188 0.341654L3.2199 0.166687ZM6.25871 1.98282C8.24245 2.43699 11.7597 3.59275 13.2187 6.25664C14.0805 7.824 14.3612 9.7969 13.9517 11.408C13.6126 12.7559 13.0793 13.7629 12.3164 14.472C12.5859 13.4317 12.5113 12.36 12.267 11.4975C11.8201 9.92253 10.7605 8.78022 9.50012 8.51494L7.9967 8.19484L8.7764 9.54983C9.50043 10.7887 9.10798 11.8196 8.43786 12.2544C7.90354 12.6009 7.03027 12.6344 6.32535 11.5816L5.67494 10.6091L5.15248 11.6589C4.97164 12.0221 4.27108 13.591 4.7433 15.2397C3.747 14.9105 2.74034 14.1744 2.21762 12.98C1.11145 10.4536 2.77135 8.61939 4.36612 7.51939C5.69544 6.57147 6.4439 5.44162 6.58925 4.16246C6.68342 3.33537 6.5037 2.58282 6.25871 1.98282Z"
        fill="currentColor"
      />
    </svg>
  );
}
