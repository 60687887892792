import { getUnixTime } from "date-fns";
import {
  useBaseQuotePriceQuery,
  usePriceChartQuery,
  useTokenInfoQuery,
  useTokenPriceQuery,
} from "@/api";
import {
  calculateYTD,
  formatAbsolutePercent,
  getSymbolWithDefault,
} from "@/utils";
import { skipToken } from "@reduxjs/toolkit/query";
import { useState } from "react";
import { BasePricingMode, ComparePricingMode } from "./reducer";
import { useTypedSelector } from "@/store";

export const pricingModes = [
  ["receiveToken", "USD"],
  ["receiveToken", "sendToken"],
  ["sendToken", "USD"],
  ["sendToken", "receiveToken"],
] as const;

export const timeframes = [
  {
    label: "24H",
    value: "1",
    title: "Past 24 Hours",
  },
  {
    label: "1W",
    value: "7",
    title: "Past Week",
  },
  {
    label: "1M",
    value: "30",
    title: "Past Month",
  },
  {
    label: "YTD",
    value: calculateYTD(),
    title: "Year to Date",
  },
  {
    label: "MAX",
    value: "max",
    title: "Max Time",
  },
] as const;

export type Timeframe = (typeof timeframes)[number];

export interface PricePoint {
  value: number;
  unixTime: number;
}

interface UseTokenPriceArgs {
  basePricingMode: BasePricingMode;
  comparePricingMode: ComparePricingMode;
  sendToken?: string;
  receiveToken?: string;
  mode: Timeframe["label"];
  refetchOnMountOrArgChange?: boolean;
}

export const useTokenPriceGraphData = ({
  basePricingMode,
  comparePricingMode,
  sendToken,
  receiveToken,
  mode,
  refetchOnMountOrArgChange,
}: UseTokenPriceArgs) => {
  const { useWrappedSol } = useTypedSelector((state) => state.profile);
  const sendTokenInfo = useTokenInfoQuery(
    sendToken
      ? { address: sendToken, solType: useWrappedSol ? "wsol" : "sol" }
      : skipToken,
  ).currentData;

  const receiveTokenInfo = useTokenInfoQuery(
    receiveToken
      ? { address: receiveToken, solType: useWrappedSol ? "wsol" : "sol" }
      : skipToken,
  ).currentData;

  const sendTokenPriceResults = useTokenPriceQuery(sendToken ?? skipToken, {
    refetchOnMountOrArgChange,
  });

  const receiveTokenPriceResults = useTokenPriceQuery(
    receiveToken ?? skipToken,
    { refetchOnMountOrArgChange },
  );

  const sendToReceiveTokenPriceResults = useBaseQuotePriceQuery(
    sendToken && receiveToken
      ? {
          baseAddress: sendToken,
          quoteAddress: receiveToken,
        }
      : skipToken,
    { refetchOnMountOrArgChange },
  );

  const receiveToSendTokenPriceResults = useBaseQuotePriceQuery(
    sendToken && receiveToken
      ? {
          baseAddress: receiveToken,
          quoteAddress: sendToken,
        }
      : skipToken,
    { refetchOnMountOrArgChange },
  );

  const pricingModeToAddress = {
    sendToken,
    receiveToken,
    USD: "USD",
  } as const;

  const pricingModeToSymbol = {
    sendToken: getSymbolWithDefault(sendTokenInfo),
    receiveToken: getSymbolWithDefault(receiveTokenInfo),
    USD: "USD",
  } as const;

  const baseCompareToken =
    pricingModeToAddress[basePricingMode] ?? receiveToken;
  const secondaryCompareToken = pricingModeToAddress[comparePricingMode];

  const displayPricingMode = `${basePricingMode}/${comparePricingMode}`;

  const priceChartParams =
    comparePricingMode === "USD" && baseCompareToken
      ? { address: baseCompareToken, mode }
      : comparePricingMode !== "USD" &&
          baseCompareToken &&
          secondaryCompareToken
        ? {
            address: baseCompareToken,
            compare: secondaryCompareToken,
            mode,
          }
        : skipToken;

  const priceChartResults = usePriceChartQuery(priceChartParams, {
    refetchOnMountOrArgChange,
  });

  const { data: priceChartData, isError: isPriceChartError } =
    priceChartResults;

  const [hoveredPricePoint, setHoveredPricePoint] = useState<PricePoint>();

  const tokenPriceResults =
    baseCompareToken === sendToken && secondaryCompareToken === "USD"
      ? sendTokenPriceResults
      : baseCompareToken === receiveToken && secondaryCompareToken === "USD"
        ? receiveTokenPriceResults
        : baseCompareToken === sendToken &&
            secondaryCompareToken === receiveToken
          ? sendToReceiveTokenPriceResults
          : receiveToSendTokenPriceResults;

  const { data: tokenPriceData, currentData: tokenPriceCurrentData } =
    tokenPriceResults;

  const earliestPrice = priceChartData?.[0]?.value;
  const currentPrice = tokenPriceData?.value;
  const price = hoveredPricePoint?.value ?? currentPrice;
  const priceChange =
    hoveredPricePoint && earliestPrice !== undefined
      ? hoveredPricePoint.value - earliestPrice
      : earliestPrice !== undefined && currentPrice !== undefined
        ? currentPrice - earliestPrice
        : undefined;
  const percent =
    earliestPrice !== undefined && priceChange !== undefined
      ? (priceChange / earliestPrice) * 100
      : 0;

  return {
    baseCompareToken,
    secondaryCompareToken,
    price,
    priceChange,
    displayPricingMode,
    setHoveredPricePoint,
    tokenPriceResults,
    hasPriceChartData: !isPriceChartError && priceChartData?.length,
    pricingModeItems: pricingModes.map((mode) => ({
      value: mode.join("/"),
      label: mode.map((value) => pricingModeToSymbol[value]).join("/"),
    })),
    togglePricingModeItems: pricingModes
      .filter((mode) => mode[0] === "receiveToken")
      .map((mode) => ({
        value: mode.join("/"),
        label: mode.map((value) => pricingModeToSymbol[value]).join("/"),
      })),
    displayPercent: formatAbsolutePercent(percent),
    priceChartResults: {
      ...priceChartResults,
      currentData:
        tokenPriceCurrentData && tokenPriceResults.fulfilledTimeStamp
          ? priceChartResults.currentData?.concat([
              {
                unixTime: getUnixTime(tokenPriceResults.fulfilledTimeStamp),
                value: tokenPriceCurrentData.value,
              },
            ])
          : priceChartResults.currentData,
      data:
        tokenPriceData && tokenPriceResults.fulfilledTimeStamp
          ? priceChartResults.data?.concat([
              {
                unixTime: getUnixTime(tokenPriceResults.fulfilledTimeStamp),
                value: tokenPriceData.value,
              },
            ])
          : priceChartResults.data,
    } as typeof priceChartResults,
  };
};
