import { KeyboardAwareButton, Page } from "@/components";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { SlippageForm } from "./-slippage-form";
import { useSlippageForm } from "./-use-slippage-hook";

export const Route = createFileRoute("/profile/slippage/")({
  component: ProfileSlippage,
});

export function ProfileSlippage() {
  const formProps = useSlippageForm();
  const navigate = useNavigate();

  return (
    <Page
      title="Slippage"
      backButton={{
        to: "/profile",
        search: (prev) => prev,
        "aria-label": "Back to profile",
      }}
      hideTabs
    >
      <SlippageForm {...formProps} className="px-5 pb-button-row" />
      <KeyboardAwareButton
        disabled={formProps.saveDisabled}
        onClick={async () => {
          if (!formProps.isSaving) {
            const result = await formProps.onSaveSlippage();
            if (!(result && "error" in result)) {
              void navigate({ to: "/profile", search: (prev) => prev });
            }
          }
        }}
        className="shrink-0 w-full"
        placement="without-tabs"
        isLoading={formProps.isSaving}
      >
        Save
      </KeyboardAwareButton>
    </Page>
  );
}
