import { TokenInfoWithPrice, useTrendingTokensQuery } from "@/api";
import { TokenList } from "../token-list";
import { TabListBaseProps } from "./shared";

const EMPTY_LIST: TokenInfoWithPrice[] = [];

interface TrendingListProps extends TabListBaseProps {
  search: string;
}

export function TrendingList({
  search,
  onSelectToken,
  scrollRef,
  extraHeight,
  onQuickBuyLongPress,
}: TrendingListProps) {
  const trendingTokensQuery = useTrendingTokensQuery(undefined, {
    refetchOnMountOrArgChange: 60,
  });
  const rows = trendingTokensQuery.data ?? EMPTY_LIST;

  return (
    <TokenList
      ref={scrollRef}
      tokens={rows}
      id="trending-token-list"
      searchKey={search}
      isFetching={trendingTokensQuery.isFetching}
      hasNextPage={false}
      onSelectToken={onSelectToken}
      extraHeight={extraHeight}
      onQuickBuyLongPress={onQuickBuyLongPress}
    />
  );
}
