import { cn } from "@/utils";
import * as SwitchPrimitive from "@radix-ui/react-switch";
import { cva } from "class-variance-authority";

const rootVariants = cva(
  "w-[35px] h-[19px] rounded-full relative border border-cloud outline-none cursor-default",
  {
    variants: {
      disabled: {
        true: "disabled:border-gray-400",
        false: "data-[state=checked]:border-primary",
      },
    },
  },
);

const thumbVariants = cva(
  "block w-[15px] h-[15px] rounded-full transition-transform duration-100 translate-x-0.5 will-change-transform data-[state=checked]:translate-x-[17px]",
  {
    variants: {
      disabled: {
        true: "bg-gray-400",
        false: "bg-white data-[state=checked]:bg-primary",
      },
    },
  },
);

export const Switch = ({
  className,
  disabled = false,
  ...props
}: SwitchPrimitive.SwitchProps) => (
  <SwitchPrimitive.Root
    className={cn(rootVariants({ disabled }), className)}
    {...props}
  >
    <SwitchPrimitive.Thumb className={thumbVariants({ disabled })} />
  </SwitchPrimitive.Root>
);
