import {
  useAddFavoriteTokenMutation,
  useFavoriteTokensQuery,
  useQuickTradeSettingsQuery,
  useRemoveFavoriteTokenMutation,
  useTokenAccountByMintQuery,
  useTokenInfoQuery,
} from "@/api";
import { Button, ButtonRow, Icon, Link, Page, Shimmer } from "@/components";
import { useDebouncedFocus } from "@/hooks/useDebouncedFocus";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import {
  clearAmount,
  updateReceiveToken,
  updateSendToken,
} from "@/routes/trade/-reducer";
import { updateSearchParams } from "@/routes/trade/-swap/util";
import { store, useAppDispatch, useTypedSelector } from "@/store";
import {
  SOL_ADDRESS,
  SOL_DECIMALS,
  formatTokenNumber,
  getRedirectPath,
  normalizeScaled,
} from "@/utils";
import { skipToken } from "@reduxjs/toolkit/query";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { useCallback, useEffect, useRef } from "react";
import { MobileSwap } from "./-swap/mobile-swap";
import { TokenInfo } from "./-token/token-info";
import { TokenSearch } from "@/routes/trade/-search/token-search";
import { ResetScrollHandle } from "@/routes/trade/-search/categories";
import { useQuickBuy } from "@/hooks/useQuickTrade";
import { updateCategory } from "./-search/token-search-reducer";

export type Step = "trade";
export type { SearchParams as TradeSearchParams };

interface SearchParams {
  step?: Step;
  sendToken?: string;
  receiveToken?: string;
  search?: string;
  redirect?: string;
}

export const Route = createFileRoute("/trade/")({
  component: Trade,
  validateSearch: (searchParams?: Record<string, unknown>): SearchParams => {
    const sendToken =
      typeof searchParams?.sendToken === "string"
        ? searchParams.sendToken
        : undefined;
    const receiveToken =
      typeof searchParams?.receiveToken === "string"
        ? searchParams.receiveToken
        : undefined;

    return {
      step: searchParams?.step !== "trade" ? undefined : searchParams.step,
      sendToken,
      receiveToken: receiveToken === sendToken ? undefined : receiveToken,
      redirect:
        typeof searchParams?.redirect === "string"
          ? searchParams.redirect
          : undefined,
    };
  },
  beforeLoad: ({ search }) => {
    const state = store.getState().tradeSwap;
    if (state.receiveToken?.address !== search.receiveToken) {
      store.dispatch(clearAmount());
    }

    if (search.search && !search.sendToken && !search.receiveToken) {
      store.dispatch(updateCategory("all"));
    }
  },
});

function Trade() {
  const search = Route.useSearch();
  const {
    sendToken: sendTokenAddress,
    receiveToken: receiveTokenAddress,
    step,
    redirect,
  } = search;
  const dispatch = useAppDispatch();
  const tradeState = useTypedSelector((state) => state.tradeSwap);
  const navigate = useNavigate();

  const isDesktopAndUp = useMediaQuery("(min-width: 1024px)");

  const { useWrappedSol } = useTypedSelector((state) => state.profile);

  const sendTokenAccountQuery = useTokenAccountByMintQuery(
    sendTokenAddress && !(sendTokenAddress === SOL_ADDRESS && useWrappedSol)
      ? sendTokenAddress
      : skipToken,
  );

  const receiveTokenAccountQuery = useTokenAccountByMintQuery(
    receiveTokenAddress &&
      !(receiveTokenAddress === SOL_ADDRESS && useWrappedSol)
      ? receiveTokenAddress
      : skipToken,
  );

  const { basePricingMode } = useTypedSelector((state) => state.tradeChart);
  const tokenAddress =
    basePricingMode === "sendToken" ? sendTokenAddress : receiveTokenAddress;
  const favoriteTokenQuery = useFavoriteTokensQuery();
  const isTokenLiked = favoriteTokenQuery.currentData?.find(
    (token) => token.address === tokenAddress,
  );
  const [addFavoriteTokenMutate] = useAddFavoriteTokenMutation();
  const [removeFavoriteTokenMutate] = useRemoveFavoriteTokenMutation();

  useDebouncedFocus(
    useCallback(() => {
      if (sendTokenAccountQuery.isUninitialized) return;
      if (receiveTokenAccountQuery.isUninitialized) return;

      void sendTokenAccountQuery.refetch();
      void receiveTokenAccountQuery.refetch();
    }, [sendTokenAccountQuery, receiveTokenAccountQuery]),
    5_000,
  );

  const sendTokenQuery = useTokenInfoQuery(
    sendTokenAddress
      ? {
          address: sendTokenAddress,
          solType: useWrappedSol ? "wsol" : "sol",
          withPrice: true,
        }
      : skipToken,
    {
      pollingInterval: 2 * 60 * 1000,
      skipPollingIfUnfocused: true,
    },
  );
  const receiveTokenQuery = useTokenInfoQuery(
    receiveTokenAddress
      ? {
          address: receiveTokenAddress,
          solType: useWrappedSol ? "wsol" : "sol",
          withPrice: true,
        }
      : skipToken,
    {
      pollingInterval: 2 * 60 * 1000,
      skipPollingIfUnfocused: true,
    },
  );

  // This is used to update the swap tokens when the user receives a shared link
  // It only needs to update when the url search params change, the token info is loaded
  // and the search params are different from the current token info
  useEffect(() => {
    if (
      sendTokenAddress &&
      tradeState.sendToken?.address !== sendTokenAddress &&
      sendTokenQuery.currentData?.address === sendTokenAddress
    ) {
      dispatch(updateSendToken(sendTokenQuery.currentData));
    }
    if (
      receiveTokenAddress &&
      tradeState.receiveToken?.address !== receiveTokenAddress &&
      receiveTokenQuery.currentData?.address === receiveTokenAddress
    ) {
      dispatch(updateReceiveToken(receiveTokenQuery.currentData));
    }

    if (!sendTokenAddress) {
      dispatch(updateSendToken(null));
    }

    if (!receiveTokenAddress) {
      dispatch(updateReceiveToken(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    sendTokenAddress,
    receiveTokenAddress,
    sendTokenQuery.currentData,
    receiveTokenQuery.currentData,
  ]);

  // Ensure the swap token prices are reasonably up to date
  useEffect(() => {
    if (
      tradeState.sendToken &&
      sendTokenQuery.currentData?.updateUnixTime &&
      tradeState.sendToken.address === sendTokenQuery.currentData.address &&
      (tradeState.sendToken.updateUnixTime ?? 0) <
        sendTokenQuery.currentData.updateUnixTime
    ) {
      dispatch(updateSendToken(sendTokenQuery.currentData));
    }
    if (
      tradeState.receiveToken &&
      receiveTokenQuery.currentData?.updateUnixTime &&
      tradeState.receiveToken.address ===
        receiveTokenQuery.currentData.address &&
      (tradeState.receiveToken.updateUnixTime ?? 0) <
        receiveTokenQuery.currentData.updateUnixTime
    ) {
      dispatch(updateReceiveToken(receiveTokenQuery.currentData));
    }
  });

  const tokenListScrollContainerRef = useRef<ResetScrollHandle | null>(null);

  const quickBuyProps = useQuickBuy();
  const quickTradeSettingsQuery = useQuickTradeSettingsQuery();

  if (!isDesktopAndUp && !receiveTokenAddress) {
    const quickTradeBuyAmount =
      quickTradeSettingsQuery.data?.settings?.buyTokenSymbol === "SOL"
        ? quickTradeSettingsQuery.data.settings.solBuyAmount
        : quickTradeSettingsQuery.data?.settings?.buyTokenSymbol === "USDC"
          ? quickTradeSettingsQuery.data.settings.usdcBuyAmount
          : quickTradeSettingsQuery.data?.settings?.buyTokenSymbol === "USDT"
            ? quickTradeSettingsQuery.data.settings.usdtBuyAmount
            : null;
    const quickTradeBuyAmountDecimals =
      quickTradeSettingsQuery.data?.settings?.buyTokenSymbol === "SOL"
        ? SOL_DECIMALS
        : 6;
    const normalizedQuickTradeBuyAmount = quickTradeBuyAmount
      ? normalizeScaled(quickTradeBuyAmount, quickTradeBuyAmountDecimals)
      : null;

    return (
      <Page
        title="Trade"
        profileButton
        onCurrentTabClick={() => {
          if (tokenListScrollContainerRef.current) {
            tokenListScrollContainerRef.current.resetScroll();
          }
        }}
        headerContent={
          <Shimmer.Text
            isLoading={quickTradeSettingsQuery.isLoading}
            className="w-8 h-5 mr-2"
          >
            <Link
              to="/profile/quick-trade-settings"
              search={{ redirect: getRedirectPath() }}
              aria-label="Quick trade settings"
              className="shrink-0 p-2 flex items-center gap-x-1"
            >
              <Icon name="bolt" className="text-primary w-5 h-5" />
              {quickTradeSettingsQuery.data?.enabled &&
              quickTradeSettingsQuery.data.settings?.buyTokenSymbol &&
              normalizedQuickTradeBuyAmount ? (
                <p className="text-sm text-primary font-bold">
                  {formatTokenNumber(
                    normalizedQuickTradeBuyAmount,
                    quickTradeBuyAmountDecimals,
                  )}{" "}
                  {quickTradeSettingsQuery.data.settings.buyTokenSymbol}
                </p>
              ) : null}
            </Link>
          </Shimmer.Text>
        }
      >
        <TokenSearch
          tokenListScrollContainerRef={tokenListScrollContainerRef}
        />
      </Page>
    );
  }

  if (!isDesktopAndUp && step === "trade" && receiveTokenAddress) {
    return (
      <Page
        flex
        fullScreenHeight
        hideTabs
        backButton={
          redirect
            ? { to: redirect, isRedirect: true }
            : {
                to: "/trade",
                search: {
                  sendToken: sendTokenAddress,
                  receiveToken: receiveTokenAddress,
                },
              }
        }
        title="Trade"
        headerContent={
          <Link
            to="/profile/trade-settings"
            search={{ redirect: getRedirectPath() }}
            aria-label="Trade settings"
            className="shrink-0 p-2"
          >
            <Icon name="cog" className="w-5" color="primary" />
          </Link>
        }
      >
        <MobileSwap
          sendTokenIsLoading={sendTokenQuery.isLoading}
          receiveTokenIsLoading={receiveTokenQuery.isLoading}
        />
      </Page>
    );
  }

  if (!isDesktopAndUp && receiveTokenAddress) {
    return (
      <Page
        backButton={
          redirect
            ? { to: redirect, isRedirect: true }
            : {
                "to": "/trade",
                "aria-label": "Back to all tokens",
              }
        }
        title="Trade"
        profileButton
        headerContent={
          tokenAddress ? (
            <button
              type="button"
              aria-label={isTokenLiked ? "Liked" : "Like"}
              className="shrink-0 p-2"
              onClick={() => {
                if (isTokenLiked) {
                  void removeFavoriteTokenMutate(tokenAddress);
                } else {
                  void addFavoriteTokenMutate(tokenAddress);
                }
              }}
            >
              <Icon
                name={isTokenLiked ? "heart-filled" : "heart"}
                className="w-5"
                color="primary"
              />
            </button>
          ) : undefined
        }
      >
        <div className="pt-4 pb-button-row">
          <TokenInfo
            isLoading={sendTokenQuery.isLoading || receiveTokenQuery.isLoading}
            sendToken={tradeState.sendToken ?? { address: sendTokenAddress }}
            receiveToken={
              tradeState.receiveToken ?? { address: receiveTokenAddress }
            }
            onCompareTokenChange={(token) => {
              updateSearchParams({
                sendToken: token,
              });
            }}
          />
          <ButtonRow border>
            <Button
              className="w-full"
              onClick={() => {
                void navigate({
                  to: "/trade",
                  search: {
                    receiveToken: receiveTokenAddress,
                    step: "trade",
                  },
                });
              }}
            >
              Trade
            </Button>
            <Button
              {...quickBuyProps.getActionProps(
                tradeState.receiveToken || undefined,
              )}
              disabled={
                receiveTokenAccountQuery.isLoading || quickBuyProps.isLoading
              }
              className="w-full"
              variant="outline"
              icon={<Icon name="bolt" className="w-5 h-5" />}
            >
              Quick buy
            </Button>
          </ButtonRow>
        </div>
      </Page>
    );
  }

  return (
    <Page>
      <div className="w-full mx-auto max-w-md">
        {/* TODO: Bring these props down inside component */}
        {/* <DesktopSwap
          isUsd={isUsd}
          sendToken={sendTokenInfo}
          receiveToken={receiveTokenInfo}
          onInputChange={onInputChange}
          value={value}
          swapValue={swapValueDisplay}
          sendBalance={sendBalance}
          receiveBalance={receiveBalance}
          receiveValue={
            isUsd
              ? receiveTokenMoneyValue
              : receiveTokenValueDisplay
          }
          receiveSwapValue={
            isUsd
              ? receiveTokenValueDisplay
              : receiveTokenMoneyValue
          }
          exchangeRate={
            sendToReceiveTokenPriceData?.value && receiveTokenInfo
              ? formatTokenNumber(
                  sendToReceiveTokenPriceData.value,
                  receiveTokenInfo.decimals,
                )
              : undefined
          }
          isExchangeRateFetching={
            isSendToReceiveTokenPriceFetching &&
            sendToReceiveTokenPriceCurrentData?.value === undefined
          }
          isExchangeRateLoading={isSendToReceiveTokenPriceLoading}
          buttonErrorProps={buttonErrorProps}
          onSellTokenChange={onSellTokenChange}
          onBuyTokenChange={onBuyTokenChange}
          onSwitchToken={onSwitchToken}
        /> */}
      </div>
    </Page>
  );
}
