import { useMediaQuery } from "@/hooks/useMediaQuery";
import * as HoverCard from "@radix-ui/react-hover-card";
import * as Popover from "@radix-ui/react-popover";
import { type HoverCardProps } from "@radix-ui/react-hover-card";
import { createContext } from "react";

export const GraphHoverCardContext = createContext<GraphHoverCardContentProps>(
  {},
);

interface GraphHoverCardContentProps {
  sendToken?: string;
  receiveToken?: string;
  hidePopover?: boolean;
}

export function GraphHoverCardRoot({
  sendToken,
  receiveToken,
  hidePopover,
  ...props
}: GraphHoverCardContentProps & HoverCardProps) {
  const isDesktopAndUp = useMediaQuery("(min-width: 1024px)");
  const content = (
    <HoverCard.Root
      openDelay={200}
      closeDelay={200}
      {...props}
      open={isDesktopAndUp ? props.open : false}
    >
      {props.children}
    </HoverCard.Root>
  );

  return (
    <GraphHoverCardContext.Provider
      value={{ sendToken, receiveToken, hidePopover }}
    >
      {hidePopover ? content : <Popover.Root>{content}</Popover.Root>}
    </GraphHoverCardContext.Provider>
  );
}
