import { BaseIconProps } from "../icon";

export function CopyIcon(props: BaseIconProps) {
  return (
    <svg
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.28662 1.75V11.75H2.53662V3H11.2866V1.75H1.28662ZM3.78662 4.25V14.25H13.7866V4.25H3.78662ZM5.03662 5.5H12.5366V13H5.03662V5.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
