import { cn } from "@/utils";
import { Drawer } from "vaul";
import type { DialogContentProps as DrawerProps } from "@radix-ui/react-dialog";
import { cva, type VariantProps } from "class-variance-authority";
import { Dialog, overlayClassName, Icon } from "@/components";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { useVisualViewportOffset } from "@/hooks";

const drawerVariants = cva(
  "select-none fixed z-50 max-w-2xl top-auto bottom-0 mx-auto inset-x-0 bg-gray-600 flex h-auto flex-col",
  {
    variants: {
      variant: {
        default:
          "h-auto rounded-t-lg md:rounded-lg border-t border-cloud lg:max-w-[380px] max-h-[80dvh]",
        full: "h-viewport",
        layer: "h-viewport bg-transparent",
      },
    },
  },
);

interface DrawerContentProps
  extends DrawerProps,
    VariantProps<typeof drawerVariants> {
  title?: string;
  titleId?: string;
  noPadding?: boolean;
}

export const DrawerContent = ({
  children,
  title,
  titleId,
  variant = "default",
  noPadding,
}: DrawerContentProps) => {
  const isDesktopAndUp = useMediaQuery("(min-width: 1024px)");
  const showCloseBtn = variant === "default";
  const showBackBtn = variant === "full";
  const isLayer = variant === "layer";
  const visualViewportOffset = useVisualViewportOffset();
  const hasVisualViewportOffset =
    visualViewportOffset && visualViewportOffset > 0;

  return isDesktopAndUp ? (
    <Dialog.Content
      title={title ?? ""}
      titleId={titleId}
      variant="default"
      noPadding={noPadding}
    >
      {children}
    </Dialog.Content>
  ) : (
    <Drawer.Portal>
      <Drawer.Overlay
        className={cn(overlayClassName, {
          "bg-drawer-layer": isLayer,
        })}
      />
      <Drawer.Content
        className={cn(drawerVariants({ variant }), {
          "max-h-[100dvh]": variant === "default" && hasVisualViewportOffset,
        })}
      >
        <div className="flex flex-col overflow-auto">
          {title && (
            <div className="flex justify-between items-center pt-5 pb-3 px-3">
              {showBackBtn && (
                <Drawer.Close aria-label="Close" className="p-2">
                  <Icon name="back-arrow-boxed" className="w-5 text-primary" />
                </Drawer.Close>
              )}
              <Drawer.Title
                className={cn(
                  "text-primary font-heading font-bold text-xl px-2",
                  {
                    "flex-1 text-center -ml-5 px-7 -z-10": showBackBtn,
                  },
                )}
                id={titleId}
              >
                {title}
              </Drawer.Title>
              {showCloseBtn && (
                <Drawer.Close aria-label="Close" className="p-2">
                  <Icon name="close" className="w-5 text-primary" />
                </Drawer.Close>
              )}
            </div>
          )}
          <div
            className={cn("px-5 pb-[calc(env(safe-area-inset-bottom)+20px)]", {
              "pt-5": isLayer,
              "p-0": noPadding,
            })}
          >
            {isLayer && (
              <Drawer.Close
                aria-label="Close"
                className="absolute top-5 left-5"
              >
                <Icon name="back-arrow-boxed" className="w-5 text-primary" />
              </Drawer.Close>
            )}
            {children}
          </div>
        </div>
      </Drawer.Content>
    </Drawer.Portal>
  );
};
