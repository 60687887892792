export function handleNumericKeyboardKey(
  key: string,
  currentInput: string,
  inputEl: HTMLInputElement | null,
) {
  if (key === "backspace" && currentInput.includes("<")) {
    return "0";
  }

  const selectionStart = inputEl?.selectionStart ?? undefined;
  const selectionEnd = inputEl?.selectionEnd ?? undefined;

  if (selectionStart !== selectionEnd) {
    return (
      currentInput.slice(0, selectionStart) +
      (key === "backspace" ? "" : key) +
      currentInput.slice(selectionEnd)
    );
  } else if (key === "backspace") {
    return currentInput.slice(0, -1);
  } else {
    return currentInput.concat(key);
  }
}
