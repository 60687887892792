import { BaseIconProps } from "../icon";

export function RunIcon(props: BaseIconProps) {
  return (
    <svg
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.139 1.04163C18.9132 1.04163 17.9184 2.03216 17.9184 3.25264C17.9184 4.47312 18.9132 5.46365 20.139 5.46365C21.3647 5.46365 22.3596 4.47312 22.3596 3.25264C22.3596 2.03216 21.3647 1.04163 20.139 1.04163ZM13.7525 2.4213C13.6903 2.4213 13.6282 2.4213 13.5571 2.44783L8.67176 2.96963C8.04998 3.14651 7.33051 3.42952 7.41933 4.0486C7.50816 4.49081 8.22763 4.57925 8.67176 4.49081L13.4771 4.41121L15.529 6.03852L11.3365 8.91283C10.2706 9.7088 9.6577 10.3986 10.3683 11.8137L11.2565 13.1403C11.3365 13.2553 11.4342 13.3083 11.5319 13.3349C12.358 14.5907 13.5571 16.3507 13.5571 16.3507C13.5571 16.3507 9.56 16.5099 8.67176 16.5983C7.87234 16.6868 7.41933 17.2351 7.41933 17.6773C7.41933 18.1195 7.95228 18.6413 8.7517 18.6413C9.55112 18.6413 16.0531 18.7209 16.0531 18.7209C16.586 18.7209 17.3677 18.2698 17.19 17.5623C16.9235 16.8548 13.9124 12.0083 13.9124 12.0083L17.6341 9.69111L17.8295 11.8225C18.0072 12.1763 18.0782 12.4593 18.5224 12.4593C18.5224 12.4593 21.8089 12.2647 22.5195 12.2647C23.3189 12.2647 23.8696 12.0259 23.9584 11.4865C23.9584 10.7789 23.4166 10.7701 22.706 10.7701L19.5172 10.6905V7.8427C19.5527 7.66582 19.5705 7.4801 19.5172 7.4005L18.1848 5.29561C18.1848 5.29561 18.1404 5.26024 18.1315 5.24255C18.1138 5.21602 18.096 5.18064 18.0782 5.16295C18.0516 5.14527 18.025 5.12758 17.9983 5.10989L14.3654 2.65124C14.1611 2.51858 13.9568 2.43014 13.7525 2.43014V2.4213ZM1.04175 6.34806V8.11687H8.76058L11.4253 6.34806H1.04175ZM1.04175 9.88568V11.6545H7.78351C7.33939 10.8585 7.52592 10.151 7.70357 9.88568H1.04175ZM1.04175 13.4233V15.1921H9.75541L8.59181 13.4233H1.04175ZM10.8924 19.5345V19.6141C10.8924 19.6141 9.38235 21.2149 8.67176 22.0993C8.13881 22.8068 7.97893 23.4082 8.42305 23.762C8.86717 24.1158 9.39123 24.0362 10.3683 23.1518C11.1677 22.4442 14.09 19.5345 14.09 19.5345H10.9012H10.8924Z"
        fill="currentColor"
      />
    </svg>
  );
}
