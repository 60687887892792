/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { get as _get, clear, entries, set, values } from "idb-keyval";

export const version = COMMIT_REF ?? "unknown_version";
console.log("Cache version:", version);

export async function init() {
  if ((await values()).some((value) => value.version !== version)) {
    console.log("Clearing cache due to version mismatch");
    await clear();
  }
}

export async function get(key: string) {
  const value = await _get(key);
  if (!value) return;

  if (value.expiresAt < Date.now()) {
    return;
  }
  if (value.version !== version) {
    return;
  }
  return value;
}

export async function getAll() {
  const now = Date.now();
  return Object.fromEntries(
    (await entries()).filter(([, value]) => {
      if (value.version !== version) {
        return false;
      }
      if (value.expiresAt < now) {
        return false;
      }
      return true;
    }),
  ) as Record<string, { version: string; timestamp: number; data: unknown }>;
}

export { clear, set };
