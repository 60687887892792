import {
  isQuoteError,
  QuickTradeSettings,
  TokenInfo,
  useLazyQuoteQuery,
  useQuickTradeSettingsQuery,
  useTokenAccountByMintQuery,
  useTokenAccountsQuery,
  useTokenInfoQuery,
  useWalletInfoQuery,
} from "@/api";
import { showQuickTradeValidationToast } from "@/app/quick-trade-validation-toast";
import { useSubmitQuote } from "@/hooks/useSubmitQuote";
import { useValidate } from "@/routes/trade/-swap/validation";
import { useAppDispatch, useTypedSelector } from "@/store";
import {
  getPlatformFeeBps,
  getRedirectPath,
  SOL_ADDRESS,
  USDC_ADDRESS,
  USDT_ADDRESS,
} from "@/utils";
import { skipToken } from "@reduxjs/toolkit/query";
import { useState } from "react";

const useQuickTrade = () => {
  const { submitQuote } = useSubmitQuote();
  const dispatch = useAppDispatch();
  const validate = useValidate();
  const [getQuote] = useLazyQuoteQuery();

  return async ({
    sendToken,
    receiveToken,
    amount,
    quickTradeSettings,
    sendTokenBalance,
    action,
  }: {
    sendToken?: TokenInfo;
    receiveToken?: TokenInfo;
    amount?: string | null;
    quickTradeSettings?: QuickTradeSettings | null;
    sendTokenBalance?: string;
    action: "buy" | "sell";
  }) => {
    if (
      !sendToken ||
      !receiveToken ||
      !amount ||
      quickTradeSettings?.priorityFee === undefined ||
      quickTradeSettings.priorityFee === null ||
      !quickTradeSettings.buyTokenSymbol
    ) {
      dispatch(
        showQuickTradeValidationToast({
          title: "Invalid quick trade settings",
          status: "failed",
          baseToken: sendToken,
          quoteToken: receiveToken,
        }),
      );
      return;
    }

    if (
      sendToken.address === receiveToken.address &&
      sendToken.symbol === receiveToken.symbol
    ) {
      dispatch(
        showQuickTradeValidationToast({
          title: `Cannot ${action === "buy" ? "buy with" : "sell to"} same token`,
          status: "failed",
          baseToken: sendToken,
          quoteToken: receiveToken,
        }),
      );
      return;
    }

    const validation = validate({
      sendToken,
      receiveToken,
      scaledValue: amount,
      sendTokenBalance,
    });

    if (!validation.valid) {
      if (
        validation.kind === "insufficient_sol" ||
        validation.kind === "zero_sol_balance"
      ) {
        dispatch(
          showQuickTradeValidationToast({
            title: "Insufficient SOL",
            status: "failed",
            baseToken: sendToken,
            quoteToken: receiveToken,
          }),
        );
      } else if (
        validation.kind === "insufficient_funds" ||
        validation.kind === "zero_balance"
      ) {
        dispatch(
          showQuickTradeValidationToast({
            title: "Insufficient funds",
            status: "failed",
            baseToken: sendToken,
            quoteToken: receiveToken,
          }),
        );
      } else {
        dispatch(
          showQuickTradeValidationToast({
            title: "Something went wrong",
            status: "failed",
            baseToken: sendToken,
            quoteToken: receiveToken,
          }),
        );
      }
      return;
    }

    const quoteParams = {
      amount,
      inputMint: sendToken.address,
      outputMint: receiveToken.address,
      ...(quickTradeSettings.slippageBps !== undefined &&
      quickTradeSettings.slippageBps !== null
        ? {
            slippageBps: quickTradeSettings.slippageBps,
          }
        : {}),
      platformFeeBps: getPlatformFeeBps(
        sendToken.address,
        receiveToken.address,
      ),
    };

    const quote = await getQuote(quoteParams);

    if (quote.isError) {
      if (
        isQuoteError(quote.error) &&
        quote.error.kind === "failed_to_compute_route"
      ) {
        dispatch(
          showQuickTradeValidationToast({
            title: "No route found",
            status: "failed",
            baseToken: sendToken,
            quoteToken: receiveToken,
          }),
        );
      } else {
        dispatch(
          showQuickTradeValidationToast({
            title: "Failed to fetch quote",
            status: "failed",
            baseToken: sendToken,
            quoteToken: receiveToken,
          }),
        );
      }
      return;
    }

    await submitQuote({
      sendToken,
      receiveToken,
      quote: quote.data,
      quoteStartedTimeStamp: quote.startedTimeStamp,
      quoteFulfilledTimeStamp: quote.fulfilledTimeStamp,
      priorityFee: quickTradeSettings.priorityFee,
    });
  };
};

export const useQuickBuy = () => {
  const quickTradeSettingsQuery = useQuickTradeSettingsQuery();
  const [submittingTokens, setSubmittingTokens] = useState<
    Record<string, boolean>
  >({});

  const { useWrappedSol } = useTypedSelector((state) => state.profile);

  const sendTokenAddress =
    quickTradeSettingsQuery.data?.settings?.buyTokenSymbol === "SOL"
      ? SOL_ADDRESS
      : quickTradeSettingsQuery.data?.settings?.buyTokenSymbol === "USDC"
        ? USDC_ADDRESS
        : quickTradeSettingsQuery.data?.settings?.buyTokenSymbol === "USDT"
          ? USDT_ADDRESS
          : undefined;

  const sendTokenQuery = useTokenInfoQuery(
    sendTokenAddress
      ? {
          address: sendTokenAddress,
          solType: useWrappedSol ? "wsol" : "sol",
          withPrice: false,
        }
      : skipToken,
  );

  const sendTokenBalance = useTokenAccountByMintQuery(
    sendTokenAddress ?? skipToken,
    { refetchOnMountOrArgChange: true },
  ).currentData?.tokenRawBalance;

  const executeQuickTrade = useQuickTrade();

  const onQuickBuy = async (
    receiveToken?: TokenInfo,
    customAmount?: string,
  ) => {
    if (quickTradeSettingsQuery.isLoading || sendTokenQuery.isLoading) {
      return;
    }

    if (receiveToken?.address) {
      setSubmittingTokens((prev) => ({
        ...prev,
        [receiveToken.address]: true,
      }));
    }

    const quickTradeSettings = quickTradeSettingsQuery.data?.settings;
    const settingsAmount =
      quickTradeSettings?.buyTokenSymbol === "SOL"
        ? quickTradeSettings.solBuyAmount
        : quickTradeSettings?.buyTokenSymbol === "USDC"
          ? quickTradeSettings.usdcBuyAmount
          : quickTradeSettings?.buyTokenSymbol === "USDT"
            ? quickTradeSettings.usdtBuyAmount
            : undefined;

    await executeQuickTrade({
      sendToken: sendTokenQuery.data,
      receiveToken,
      amount: customAmount || settingsAmount,
      quickTradeSettings,
      sendTokenBalance,
      action: "buy",
    });

    if (receiveToken?.address) {
      setSubmittingTokens((prev) => ({
        ...prev,
        [receiveToken.address]: false,
      }));
    }
  };

  return {
    isLoading: quickTradeSettingsQuery.isLoading || sendTokenQuery.isLoading,
    submittingTokens: Object.keys(submittingTokens).filter(
      (address) => submittingTokens[address],
    ),
    onQuickBuy,
    getActionProps: (receiveToken?: TokenInfo) =>
      quickTradeSettingsQuery.data?.enabled &&
      quickTradeSettingsQuery.data.settings
        ? {
            onClick: () => onQuickBuy(receiveToken),
          }
        : {
            to: "/profile/quick-trade-settings",
            search: { redirect: getRedirectPath() },
          },
  };
};

export const useQuickSell = () => {
  const quickTradeSettingsQuery = useQuickTradeSettingsQuery();
  const dispatch = useAppDispatch();
  const [submittingTokens, setSubmittingTokens] = useState<
    Record<string, boolean>
  >({});

  const { useWrappedSol } = useTypedSelector((state) => state.profile);

  const walletQuery = useWalletInfoQuery();
  const tokenAccountsQuery = useTokenAccountsQuery();

  const receiveTokenAddress =
    quickTradeSettingsQuery.data?.settings?.sellTokenSymbol === "SOL"
      ? SOL_ADDRESS
      : quickTradeSettingsQuery.data?.settings?.sellTokenSymbol === "USDC"
        ? USDC_ADDRESS
        : quickTradeSettingsQuery.data?.settings?.sellTokenSymbol === "USDT"
          ? USDT_ADDRESS
          : undefined;

  const receiveTokenQuery = useTokenInfoQuery(
    receiveTokenAddress
      ? {
          address: receiveTokenAddress,
          solType: useWrappedSol ? "wsol" : "sol",
          withPrice: false,
        }
      : skipToken,
  );

  const executeQuickTrade = useQuickTrade();

  const onQuickSell = async (sendToken?: TokenInfo, customAmount?: string) => {
    if (quickTradeSettingsQuery.isLoading || receiveTokenQuery.isLoading) {
      return;
    }

    if (sendToken?.address) {
      setSubmittingTokens((prev) => ({
        ...prev,
        [sendToken.address]: true,
      }));
    }

    const sendTokenBalance =
      sendToken?.address === SOL_ADDRESS && sendToken.symbol === "SOL"
        ? walletQuery.data?.rawBalance
        : tokenAccountsQuery.data?.find(
            (token) => token.mintAddress === sendToken?.address,
          )?.tokenRawBalance;

    if (!sendTokenBalance || sendTokenBalance === "0") {
      dispatch(
        showQuickTradeValidationToast({
          title: "Insufficient funds",
          status: "failed",
          baseToken: sendToken,
          quoteToken: receiveTokenQuery.data,
        }),
      );

      if (sendToken?.address) {
        setSubmittingTokens((prev) => ({
          ...prev,
          [sendToken.address]: false,
        }));
      }

      return;
    }

    const quickTradeSettings = quickTradeSettingsQuery.data?.settings;
    const sellBps =
      quickTradeSettings?.sellTokenSymbol === "SOL"
        ? quickTradeSettings.solSellBps
        : quickTradeSettings?.sellTokenSymbol === "USDC"
          ? quickTradeSettings.usdcSellBps
          : quickTradeSettings?.sellTokenSymbol === "USDT"
            ? quickTradeSettings.usdtSellBps
            : undefined;
    const settingsAmount = sellBps
      ? (BigInt(sellBps) * BigInt(sendTokenBalance)) / 10000n
      : undefined;

    await executeQuickTrade({
      sendToken,
      receiveToken: receiveTokenQuery.data,
      amount: customAmount || settingsAmount?.toString(),
      quickTradeSettings,
      sendTokenBalance,
      action: "sell",
    });

    if (sendToken?.address) {
      setSubmittingTokens((prev) => ({
        ...prev,
        [sendToken.address]: false,
      }));
    }
  };

  return {
    isLoading: quickTradeSettingsQuery.isLoading || receiveTokenQuery.isLoading,
    submittingTokens: Object.keys(submittingTokens).filter(
      (address) => submittingTokens[address],
    ),
    onQuickSell,
    getActionProps: (receiveToken?: TokenInfo) =>
      quickTradeSettingsQuery.data?.enabled &&
      quickTradeSettingsQuery.data.settings
        ? {
            onClick: () => onQuickSell(receiveToken),
          }
        : {
            to: "/profile/quick-trade-settings",
            search: { redirect: getRedirectPath(), tab: "quick-sell" },
          },
  };
};
