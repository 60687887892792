// create connection with custom RPC
// custom fetch ensures 429 error responses include a field for retryAfterMs from the response headers
// this is necessary because errors returned by connection methods do not include headers
// this is to allows the caller to handle the retry otherwise operations will be queued without the caller knowing
export async function fetchWithRateLimit(
  info: URL | RequestInfo,
  init?: RequestInit | undefined,
) {
  const originalResponse = await fetch(info, init);

  if (originalResponse.status === 429) {
    const retryAfterMs =
      Number(originalResponse.headers.get("retry-after") ?? "0") * 1_000;
    const data = (await originalResponse.json().catch(() => ({}))) as Record<
      string,
      unknown
    >;
    const newResponse = new Response(
      JSON.stringify({ ...data, retryAfterMs }),
      originalResponse,
    );
    return newResponse;
  }

  return originalResponse;
}
