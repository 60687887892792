import {
  useDestinationSolBalanceQuery,
  useDestinationTokenBalanceQuery,
  useWalletInfoQuery,
} from "@/api";
import {
  validateAddress,
  validateAmount,
  validateToken,
} from "@/app/validations";
import { calculatePriorityFee, getIsNativeSol } from "@/routes/withdraw/-utils";
import { useTypedSelector } from "@/store";
import { skipToken } from "@reduxjs/toolkit/query";
import { PublicKey } from "@solana/web3.js";
import { useEffect, useState } from "react";

export const useValidation = () => {
  const walletData = useWalletInfoQuery().data;
  const solBalance = walletData?.rawBalance ?? "0";
  const ownAddress = walletData?.walletAddress ?? undefined;
  const { address, token, withdrawInput, tokenQuantity, isUsd } =
    useTypedSelector((state) => state.withdrawFlow);
  const priorityFee = useTypedSelector((state) => state.profile.priorityFee);

  const withdrawPriorityFee = calculatePriorityFee(priorityFee);

  const [debouncedDestAddress, setDebouncedDestAddress] = useState<
    string | undefined
  >(address);
  const isNativeSolWithdrawal = token ? getIsNativeSol(token) : false;

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!address) {
        setDebouncedDestAddress(undefined);
        return;
      }
      try {
        const validated = new PublicKey(address).toBase58();
        setDebouncedDestAddress(validated);
      } catch {
        setDebouncedDestAddress(undefined);
      }
    }, 500);
    return () => clearTimeout(timeout);
  }, [address]);

  const _destinationSolBalance =
    useDestinationSolBalanceQuery(debouncedDestAddress ?? skipToken, {
      skip: !isNativeSolWithdrawal,
      refetchOnMountOrArgChange: true,
    }).data?.rawBalance ?? "0";
  const destinationSolBalance = BigInt(_destinationSolBalance);

  const destinationTokenBalance = useDestinationTokenBalanceQuery(
    token?.address && debouncedDestAddress
      ? {
          destinationAddress: debouncedDestAddress,
          mintAddress: token.address,
        }
      : skipToken,
    {
      skip: isNativeSolWithdrawal,
      refetchOnMountOrArgChange: true,
    },
  ).data?.rawBalance;

  const recipientAccountExists = isNativeSolWithdrawal
    ? destinationSolBalance > 0n
    : Boolean(destinationTokenBalance);

  const tokenValidation = validateToken(token);
  const addressValidation = validateAddress(address, ownAddress);
  const amountValidation = validateAmount(
    withdrawInput ?? "0",
    tokenQuantity ?? "0",
    withdrawPriorityFee,
    token ?? null,
    BigInt(solBalance),
    isNativeSolWithdrawal,
    isUsd,
    recipientAccountExists,
    destinationSolBalance,
  );

  return {
    tokenValidation,
    addressValidation,
    amountValidation,
  };
};
