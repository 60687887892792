import type { TokenInfoParams } from "@/api";
import { useUserSolType } from "@/hooks/useUserSolType";
import { skipToken } from "@reduxjs/toolkit/query";

export function useTokenInfoParams<P extends boolean>(
  address: string | null | undefined,
  withPrice?: P,
): TokenInfoParams<P> | typeof skipToken {
  const solType = useUserSolType();
  return address ? { address, solType, withPrice } : skipToken;
}
