import { useTokenOverviewQuery } from "@/api";
import { Drawer, Icon } from "@/components";
import { useTypedSelector } from "@/store";
import { cn, formatNumber } from "@/utils";
import { skipToken } from "@reduxjs/toolkit/query";

interface StatProps {
  label: string;
  labelShort?: string;
  value?: string;
  isLoading?: boolean;
  isFetching?: boolean;
  tooltip?: string;
}

function Stat({
  label,
  labelShort,
  value,
  isLoading,
  isFetching,
  tooltip,
}: StatProps) {
  const className = "p-5 border-b even:border-l border-cloud";
  const content = (
    <>
      <p className="text-gray-200 mb-px text-sm leading-tight text-left flex items-center flex-wrap gap-x-1">
        <span>
          <span className="hidden lg:inline">{labelShort}</span>
          <span className={cn(labelShort && "lg:hidden")}>{label}</span>
        </span>
        {tooltip ? (
          <Icon name="info" className="text-gray-200 w-3.5 h-3.5" />
        ) : null}
      </p>
      {isLoading ? (
        <div className="h-5 w-1/2 loading-skeleton" />
      ) : (
        <div
          className={cn(
            "flex items-center transition-opacity",
            isFetching && "opacity-40",
          )}
        >
          <p className="text-sm font-bold">{value ?? "–"}</p>
        </div>
      )}
    </>
  );

  return tooltip ? (
    <Drawer.Root>
      <Drawer.Trigger className={className}>{content}</Drawer.Trigger>
      <Drawer.Content title={label}>
        <p className="text-sm">{tooltip}</p>
      </Drawer.Content>
    </Drawer.Root>
  ) : (
    <div className={className}>{content}</div>
  );
}

const usdFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

interface TokenStatsProps {
  tokenAddress: string | undefined | null;
}

export const TokenStats = ({ tokenAddress }: TokenStatsProps) => {
  const { useWrappedSol } = useTypedSelector((state) => state.profile);
  const overviewQuery = useTokenOverviewQuery(
    tokenAddress
      ? { address: tokenAddress, solType: useWrappedSol ? "wsol" : "sol" }
      : skipToken,
  );
  const overviewData = overviewQuery.data ?? undefined;
  return (
    <div className="grid grid-cols-2 lg:grid-cols-3">
      <Stat
        label="FDV"
        value={
          typeof overviewData?.mc === "number" && !isNaN(overviewData.mc)
            ? usdFormatter.format(overviewData.mc)
            : undefined
        }
        isLoading={overviewQuery.isLoading}
        isFetching={overviewQuery.isFetching}
      />
      <Stat
        label="Liquidity"
        value={
          typeof overviewData?.liquidity === "number" &&
          !isNaN(overviewData.liquidity)
            ? usdFormatter.format(overviewData.liquidity)
            : undefined
        }
        isLoading={overviewQuery.isLoading}
        isFetching={overviewQuery.isFetching}
      />
      <Stat
        label="24H Volume"
        value={
          typeof overviewData?.v24hUSD === "number" &&
          !isNaN(overviewData.v24hUSD)
            ? usdFormatter.format(overviewData.v24hUSD)
            : undefined
        }
        isLoading={overviewQuery.isLoading}
        isFetching={overviewQuery.isFetching}
      />
      <Stat
        label="Holders"
        value={
          typeof overviewData?.holder === "number" &&
          !isNaN(overviewData.holder)
            ? formatNumber(overviewData.holder)
            : undefined
        }
        isLoading={overviewQuery.isLoading}
        isFetching={overviewQuery.isFetching}
      />
      <Stat
        label="Token Supply"
        value={
          typeof overviewData?.supply === "number" &&
          !isNaN(overviewData.supply)
            ? formatNumber(overviewData.supply)
            : undefined
        }
        isLoading={overviewQuery.isLoading}
        isFetching={overviewQuery.isFetching}
      />
      <Stat
        label="24H Unique Wallets"
        value={
          typeof overviewData?.uniqueWallet24h === "number" &&
          !isNaN(overviewData.uniqueWallet24h)
            ? formatNumber(overviewData.uniqueWallet24h)
            : undefined
        }
        isLoading={overviewQuery.isLoading}
        isFetching={overviewQuery.isFetching}
        tooltip="The number of unique wallets that traded the token in the past 24 hours."
      />
    </div>
  );
};
