import { useOneUserOrderQuery } from "@/app/services/order-history-api";
import { Page } from "@/components";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { useEffect } from "react";
import { OrderDetails } from "./-order-details";
import { withDynamicProtected } from "@/hocs/withDynamicProtected";

export const Route = createFileRoute("/orders/$orderId/")({
  component: withDynamicProtected(OrderId),
});

function OrderId() {
  const { orderId } = Route.useParams();
  const orderQuery = useOneUserOrderQuery(orderId);
  const order = orderQuery.data ?? null;
  const navigate = useNavigate();

  // Redirect to orders page after 1 second  if order is not found
  useEffect(() => {
    if (Boolean(order) || orderQuery.isLoading) return;

    const to = setTimeout(() => void navigate({ to: "/orders" }), 1000);

    return () => clearTimeout(to);
  }, [navigate, order, orderQuery.isLoading]);

  return (
    <Page
      backButton={{
        "to": "/orders",
        "aria-label": "Back to all orders",
      }}
      title={
        order
          ? order.status === "SUCCESS"
            ? "COMPLETED ORDER"
            : "FAILED ORDER"
          : undefined
      }
      profileButton
    >
      {orderQuery.isLoading ? (
        <OrderDetails.LoadingSkeleton />
      ) : order ? (
        <OrderDetails order={order} />
      ) : (
        <div className="flex h-[50vh] items-center justify-center font-heading animate-pulse">
          Not found, redirecting...
        </div>
      )}
    </Page>
  );
}
