import {
  PriorityFeeFormProps,
  priorityFeeOptions,
} from "./-use-priority-fee-hook";
import { ErrorMessage, Shimmer } from "@/components";
import { ToggleGroup } from "../-toggle-group";
import { CustomInput } from "../-custom-input";

export function PriorityFeeForm({
  unsavedPriorityFee,
  isLoading,
  error,
  handleInputChange,
  handleInputFocus,
  handleToggleGroup,
  className,
  showValue,
}: PriorityFeeFormProps) {
  return (
    <div className={className}>
      <p className="text-sm text-gray-200 mb-5 leading-4">
        Setting a higher priority fee generally results in faster orders and
        fewer failures.
      </p>
      {showValue ? (
        <p className="text-sm uppercase text-primary mb-5 font-bold">
          Priority Fee: {unsavedPriorityFee.formattedValue}
        </p>
      ) : null}
      <div className="flex flex-col gap-2.5">
        <Shimmer.Text className="h-12" isLoading={isLoading}>
          <ToggleGroup
            options={priorityFeeOptions}
            setActiveValue={handleToggleGroup}
            activeValue={
              unsavedPriorityFee.isPresetSelected
                ? unsavedPriorityFee.scaledValue
                : ""
            }
          />
        </Shimmer.Text>
        <Shimmer.Text className="h-12" isLoading={isLoading}>
          <CustomInput
            customValue={
              unsavedPriorityFee.isPresetSelected
                ? ""
                : unsavedPriorityFee.input
            }
            label="Custom"
            suffix={unsavedPriorityFee.showSuffix ? "SOL" : ""}
            onChange={handleInputChange}
            focused={!unsavedPriorityFee.isPresetSelected}
            onFocus={handleInputFocus}
          />
        </Shimmer.Text>
        {error ? (
          <ErrorMessage variant="rounded" className="mt-2">
            {error}
          </ErrorMessage>
        ) : null}
      </div>
    </div>
  );
}
