import { BaseIconProps } from "../icon";

export function CogIcon(props: BaseIconProps) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.98977 6.41534C8.05897 6.41534 6.49659 8.02414 6.49659 9.99666C6.49659 11.9692 8.06579 13.578 9.98977 13.578C11.9137 13.578 13.4829 11.9692 13.4829 9.99666C13.4829 8.02414 11.9137 6.41534 9.98977 6.41534ZM9.98977 12.0112C8.89133 12.0112 8.02485 11.1298 8.02485 9.99666C8.02485 8.86351 8.8845 7.98217 9.98977 7.98217C11.095 7.98217 11.9547 8.86351 11.9547 9.99666C11.9547 11.1298 11.095 12.0112 9.98977 12.0112Z"
        fill="currentColor"
      />
      <path
        d="M16.7987 10.92C16.8465 10.6262 16.8943 10.3184 16.8943 10.0037C16.8943 9.68889 16.8465 9.38112 16.7987 9.08734L16.7783 8.94745L18.2792 7.60445L18.7364 7.18476L17.058 4.21897L16.7442 3.65239L16.1438 3.84825L14.2061 4.53374C13.6603 4.09307 13.0872 3.74333 12.4937 3.49851L12.098 1.47002L11.9751 0.833496H7.99756L7.47905 3.5055C6.88548 3.75032 6.31238 4.10006 5.76657 4.54073L3.82895 3.85524L3.22856 3.65939L1.56384 6.61119L1.25 7.17777L3.20809 8.94045L3.18762 9.08035C3.13986 9.37413 3.09211 9.6819 3.09211 9.99666C3.09211 10.3114 3.13986 10.6192 3.18762 10.913L3.20809 11.0529L1.70712 12.3959L1.25 12.8156L2.92836 15.7814L3.2422 16.3479L3.84259 16.1521L5.78021 15.4666C6.32602 15.9073 6.89912 16.257 7.49269 16.5018L7.8884 18.5303L8.01121 19.1668H11.9888L12.5073 16.4948C13.1009 16.25 13.674 15.9003 14.2198 15.4596L16.1574 16.1451L16.7578 16.3409L18.4362 13.3682L18.75 12.8016L16.7919 11.0389L16.8124 10.899L16.7987 10.92ZM11.1564 15.3966L10.7334 17.607H9.25292L8.92544 15.8653L8.82992 15.4036L8.4001 15.2567C7.63596 15.0119 6.9537 14.6062 6.36696 14.0536L6.03265 13.7319L3.93811 14.4663L3.20127 13.1723L4.51121 11.9762L4.87281 11.6754L4.75 11.2068C4.66813 10.822 4.62719 10.4303 4.62719 10.0037C4.62719 9.57698 4.66813 9.18527 4.75 8.80056L4.87281 8.33191L4.51121 8.03812L3.20127 6.84202L3.93811 5.54798L5.60283 6.13554L6.03265 6.28243L6.36696 5.96067C6.9537 5.40809 7.64279 5.00239 8.40692 4.75757L8.83674 4.61068L9.25975 2.40033H10.7403L11.0677 4.14203L11.1633 4.60369L11.5931 4.75058C12.3572 4.99539 13.0395 5.40109 13.6262 5.95368L13.9605 6.27544L16.0551 5.54099L16.7919 6.83502L15.482 8.03113L15.1204 8.33191L15.2432 8.80056C15.325 9.18527 15.366 9.57698 15.366 10.0037C15.366 10.4303 15.325 10.822 15.2432 11.2068L15.1477 11.6754L16.7987 13.1653L16.0619 14.4593L14.3972 13.8718L13.9673 13.7249L13.633 14.0466C13.0463 14.5992 12.3572 15.0049 11.5931 15.2497L11.1633 15.3966H11.1564Z"
        fill="currentColor"
      />
    </svg>
  );
}
