export function getRedirectPath() {
  const params = new URLSearchParams(location.search);
  // Remove any current redirect parameter from the search string
  // when getting a new redirect path
  const paramsWithoutRedirect = Array.from(params.entries()).filter(
    ([key]) => key !== "redirect",
  );
  const search = paramsWithoutRedirect.length
    ? `?${new URLSearchParams(paramsWithoutRedirect).toString()}`
    : "";
  return encodeURI(`${location.pathname}${search}`);
}

export interface SearchParamsWithRedirect {
  redirect?: string;
}
