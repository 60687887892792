import { BaseIconProps } from "../icon";

export function ExternalIcon(props: BaseIconProps) {
  return (
    <svg
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.83362 1.5V16.5H17.1664V9H15.4628V14.8333H3.53726V3.16667H9.50002V1.5H1.83362ZM11.2037 1.5V3.16667H14.2582L6.34229 10.9108L7.54682 12.0892L15.4628 4.34505V7.33333H17.1664V1.5H11.2037Z"
        fill="currentColor"
      />
    </svg>
  );
}
