import { TokenBalanceEntry, useTokensByAddressQuery } from "@/api";
import { useCallback, useMemo } from "react";
import { useWeb3authTokensQuery } from "@/app/web3auth";
import { SOL_ADDRESS, getTokenBalanceNominalValue } from "@/utils";
import { useDebouncedFocus } from "./useDebouncedFocus";

export function useWeb3authPortfolio() {
  const web3authTokensQuery = useWeb3authTokensQuery(undefined, {
    refetchOnMountOrArgChange: 5,
  });
  const tokenAddresses =
    web3authTokensQuery.data?.map((x) => x.tokenAddress) ?? [];
  const tokensQuery = useTokensByAddressQuery(
    { listAddresses: [SOL_ADDRESS, ...tokenAddresses], withPrices: true },
    { skip: web3authTokensQuery.isFetching },
  );
  useDebouncedFocus(
    useCallback(() => {
      void web3authTokensQuery.refetch();
    }, [web3authTokensQuery]),
    5_000,
  );
  const tokens = useMemo(() => {
    if (!web3authTokensQuery.data || !tokensQuery.data) {
      return undefined;
    }

    const newTokens: TokenBalanceEntry[] = web3authTokensQuery.data
      .filter((token) => BigInt(token.amount) > 0)
      .map((token) => {
        if (token.tokenAddress === "" && tokensQuery.data?.[SOL_ADDRESS]) {
          const details = tokensQuery.data[SOL_ADDRESS];

          return {
            ...details,
            name: "Solana",
            symbol: "SOL",
            verified: false,
            quantity: token.amount,
            usdTotal: getTokenBalanceNominalValue(
              token.amount,
              token.decimals,
              details.price ?? 0,
            ),
          };
        }

        const details = tokensQuery.data?.[token.tokenAddress];
        if (!details) {
          return {
            address: token.tokenAddress,
            symbol: "",
            name: "",
            logoURI: undefined,
            quantity: token.amount,
            decimals: token.decimals,
            price: 0,
            priceChange24h: 0,
            updateUnixTime: 0,
            usdTotal: 0,
            verified: false,
          };
        }

        return {
          ...details,
          quantity: token.amount,
          usdTotal: getTokenBalanceNominalValue(
            token.amount,
            token.decimals,
            details.price ?? 0,
          ),
        };
      });

    return newTokens;
  }, [web3authTokensQuery.data, tokensQuery.data]);

  return { tokens, web3authTokensQuery, tokensQuery };
}
