import {
  TokenInfoWithPrice,
  useQuickTradeSettingsQuery,
  useTokenAccountsQuery,
  useWalletInfoQuery,
} from "@/api";
import { Drawer, Button, Slider, Shimmer } from "@/components";
import { useAccountBalance } from "@/hooks";
import { useQuickBuy } from "@/hooks/useQuickTrade";
import {
  formatPercent,
  formatTokenNumber,
  MINIMUM_SOL_BALANCE_LAMPORTS,
  normalizeScaled,
  SOL_ADDRESS,
  SOL_DECIMALS,
  SOL_TRANSACTION_FEE,
  USDC_ADDRESS,
  USDT_ADDRESS,
} from "@/utils";
import { useState } from "react";

interface CustomQuickBuyDrawerProps {
  token?: TokenInfoWithPrice;
  onClose: () => void;
}

export function CustomQuickBuyDrawer({
  token,
  onClose,
}: CustomQuickBuyDrawerProps) {
  const { data: quickTradeSettings, isLoading: isQuickTradeSettingsLoading } =
    useQuickTradeSettingsQuery();
  const walletQuery = useWalletInfoQuery();

  const sendTokenAddress =
    quickTradeSettings?.settings?.buyTokenSymbol === "SOL"
      ? SOL_ADDRESS
      : quickTradeSettings?.settings?.buyTokenSymbol === "USDC"
        ? USDC_ADDRESS
        : quickTradeSettings?.settings?.buyTokenSymbol === "USDT"
          ? USDT_ADDRESS
          : undefined;

  const tokenAccountsQuery = useTokenAccountsQuery();

  let sendTokenBalance =
    sendTokenAddress === SOL_ADDRESS
      ? walletQuery.data?.rawBalance
      : tokenAccountsQuery.data?.find((t) => t.mintAddress === sendTokenAddress)
          ?.tokenRawBalance;

  if (sendTokenAddress === SOL_ADDRESS && sendTokenBalance) {
    sendTokenBalance =
      typeof quickTradeSettings?.settings?.priorityFee === "number"
        ? (
            BigInt(sendTokenBalance) -
            BigInt(quickTradeSettings.settings.priorityFee) -
            SOL_TRANSACTION_FEE -
            MINIMUM_SOL_BALANCE_LAMPORTS
          ).toString()
        : undefined;
  }

  const { onQuickBuy } = useQuickBuy();

  const [percent, setPercent] = useState(0);
  if (!token && percent !== 0) {
    setPercent(0);
  }

  const amount = sendTokenBalance
    ? (BigInt(percent) * BigInt(sendTokenBalance)) / 100n
    : undefined;

  const decimals =
    quickTradeSettings?.settings?.buyTokenSymbol === "SOL" ? SOL_DECIMALS : 6;

  const isLoading =
    isQuickTradeSettingsLoading ||
    walletQuery.isLoading ||
    tokenAccountsQuery.isLoading;

  const isSameToken = Boolean(
    token?.address && token.address === sendTokenAddress,
  );
  const hasNoBalance =
    !isLoading && (!sendTokenBalance || sendTokenBalance === "0");

  const isSliderDisabled =
    isQuickTradeSettingsLoading || isSameToken || hasNoBalance;

  return (
    <Drawer.Root
      open={Boolean(token)}
      onOpenChange={(open) => {
        if (!open) {
          onClose();
        }
      }}
    >
      <Drawer.Content title="Buy with">
        <div>
          <Shimmer.Text isLoading={isLoading} className="w-40 h-8 mb-3">
            <p className="font-bold text-xl">
              {amount
                ? formatTokenNumber(
                    normalizeScaled(amount, decimals),
                    decimals,
                    { decimalsMode: "fixed" },
                  )
                : "0"}{" "}
              {quickTradeSettings?.settings?.buyTokenSymbol}
            </p>
          </Shimmer.Text>
          <Shimmer.Text isLoading={isLoading} className="w-16 h-5">
            <p className="text-lime">{formatPercent(percent)}</p>
          </Shimmer.Text>
        </div>
        <Slider
          min={0}
          max={100}
          value={[percent]}
          onValueChange={(v) => {
            setPercent(v[0]);
          }}
          disabled={isSliderDisabled}
          className="mt-5"
        />
        <div className="mt-7">
          {isSameToken ? (
            <Button className="w-full" color="negative">
              Cannot buy with same token
            </Button>
          ) : hasNoBalance ? (
            <Button className="w-full" color="negative">
              Insufficient funds
            </Button>
          ) : (
            <Drawer.Close asChild>
              <Button
                className="w-full"
                disabled={isSliderDisabled || !amount}
                onClick={() => {
                  if (amount) {
                    void onQuickBuy(token, amount.toString());
                  }
                }}
              >
                Buy
              </Button>
            </Drawer.Close>
          )}
        </div>
      </Drawer.Content>
    </Drawer.Root>
  );
}
