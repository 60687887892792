import { BaseIconProps } from "../icon";

export function CoinIllustrationIcon(props: BaseIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.9531 18.72L13.7179 22.32L7.89792 18.96L4.1568 12.48V5.76L10.3925 2.16L16.212 5.52L19.9531 12.48V18.72Z"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.0434 13.2V18.96L13.7179 20.88L8.72979 18L5.40387 12.24V6.48L8.72979 4.56L14.1336 7.68L17.0434 13.2Z"
        stroke="currentColor"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8082 7.68V8.64L9.14545 7.68V11.52L12.8866 13.68V15.6L9.14545 13.44V14.4L10.8082 15.36V16.32L11.6395 16.8V15.84L13.7179 17.04V13.2L9.97681 11.04V9.12L13.7179 11.28V10.32L11.6395 9.12V8.16L10.8082 7.68Z"
        fill="currentColor"
      />
      <path
        d="M14.1336 20.64L9.14542 17.76L8.72974 18L13.7179 20.88L14.1336 20.64Z"
        stroke="currentColor"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.81926 12.0003L5.40356 12.2403L8.72924 18.0005L9.14494 17.7605L5.81926 12.0003Z"
        stroke="currentColor"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.81955 12V6.24L5.40387 6.48V12.24L5.81955 12Z"
        stroke="currentColor"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.2904 19.68L19.9531 18.72V12.48L18.2904 13.44V19.68Z"
        stroke="currentColor"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.212 5.52L10.4045 2.1672L8.72974 3.12L14.5493 6.48L16.212 5.52Z"
        stroke="currentColor"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5493 6.48L16.212 5.52L19.9531 12.48L18.2904 13.44L14.5493 6.48Z"
        stroke="currentColor"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
