import * as ToggleGroup from "@radix-ui/react-toggle-group";
import { useId } from "react";

interface TokenToggleGroupProps {
  label: string;
  value: string;
  onValueChange: (value: string) => void;
}

const options = ["SOL", "USDC", "USDT"];

export function TokenToggleGroup({
  label,
  value,
  onValueChange,
}: TokenToggleGroupProps) {
  const labelId = useId();

  const valueIndex = options.indexOf(value);

  return (
    <div className="flex items-center justify-between gap-x-6 mb-5">
      <p className="uppercase font-bold text-sm shrink-0 min-w-20" id={labelId}>
        {label}
      </p>
      <div className="border border-cloud rounded-full p-px flex-1 max-w-72">
        <div className="relative">
          <div
            className="absolute top-0 bottom-0 left-0 bg-cloud rounded-full w-1/3 transition-transform"
            style={{
              transform: `translateX(${valueIndex * 100}%)`,
            }}
          />
          <ToggleGroup.Root
            value={value}
            onValueChange={(value) => {
              if (value) {
                onValueChange(value);
              }
            }}
            type="single"
            aria-labelledby={labelId}
            className="relative flex items-center"
          >
            {options.map((option) => (
              <ToggleGroup.Item
                key={option}
                value={option}
                className="block text-sm text-gray-200 font-bold py-1.5 px-3 flex-1 aria-checked:text-primary rounded-full transition-colors"
              >
                {option}
              </ToggleGroup.Item>
            ))}
          </ToggleGroup.Root>
        </div>
      </div>
    </div>
  );
}
