import { BaseIconProps } from "../icon";

export function QRCodeAppIcon(props: BaseIconProps) {
  return (
    <svg
      viewBox="0 0 904 904"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_11636_108656)">
        <path
          d="M327.31 24.9379C332.475 24.9379 336.662 20.751 336.662 15.5862C336.662 10.4214 332.475 6.2345 327.31 6.2345C322.146 6.2345 317.959 10.4214 317.959 15.5862C317.959 20.751 322.146 24.9379 327.31 24.9379Z"
          fill="#FFD02B"
        />
        <path
          d="M358.483 24.9379C363.648 24.9379 367.835 20.751 367.835 15.5862C367.835 10.4214 363.648 6.2345 358.483 6.2345C353.318 6.2345 349.131 10.4214 349.131 15.5862C349.131 20.751 353.318 24.9379 358.483 24.9379Z"
          fill="#FFD02B"
        />
        <path
          d="M514.345 24.9379C519.51 24.9379 523.697 20.751 523.697 15.5862C523.697 10.4214 519.51 6.2345 514.345 6.2345C509.18 6.2345 504.993 10.4214 504.993 15.5862C504.993 20.751 509.18 24.9379 514.345 24.9379Z"
          fill="#FFD02B"
        />
        <path
          d="M607.862 24.9379C613.027 24.9379 617.214 20.751 617.214 15.5862C617.214 10.4214 613.027 6.2345 607.862 6.2345C602.697 6.2345 598.51 10.4214 598.51 15.5862C598.51 20.751 602.697 24.9379 607.862 24.9379Z"
          fill="#FFD02B"
        />
        <path
          d="M639.035 24.9379C644.199 24.9379 648.386 20.751 648.386 15.5862C648.386 10.4214 644.199 6.2345 639.035 6.2345C633.87 6.2345 629.683 10.4214 629.683 15.5862C629.683 20.751 633.87 24.9379 639.035 24.9379Z"
          fill="#FFD02B"
        />
        <path
          d="M264.966 56.1104C270.13 56.1104 274.317 51.9235 274.317 46.7586C274.317 41.5938 270.13 37.4069 264.966 37.4069C259.801 37.4069 255.614 41.5938 255.614 46.7586C255.614 51.9235 259.801 56.1104 264.966 56.1104Z"
          fill="#FFD02B"
        />
        <path
          d="M296.138 56.1104C301.303 56.1104 305.49 51.9235 305.49 46.7586C305.49 41.5938 301.303 37.4069 296.138 37.4069C290.973 37.4069 286.786 41.5938 286.786 46.7586C286.786 51.9235 290.973 56.1104 296.138 56.1104Z"
          fill="#FFD02B"
        />
        <path
          d="M327.31 56.1104C332.475 56.1104 336.662 51.9235 336.662 46.7586C336.662 41.5938 332.475 37.4069 327.31 37.4069C322.146 37.4069 317.959 41.5938 317.959 46.7586C317.959 51.9235 322.146 56.1104 327.31 56.1104Z"
          fill="#FFD02B"
        />
        <path
          d="M420.828 56.1104C425.992 56.1104 430.179 51.9235 430.179 46.7586C430.179 41.5938 425.992 37.4069 420.828 37.4069C415.663 37.4069 411.476 41.5938 411.476 46.7586C411.476 51.9235 415.663 56.1104 420.828 56.1104Z"
          fill="#FFD02B"
        />
        <path
          d="M576.69 56.1104C581.855 56.1104 586.041 51.9235 586.041 46.7586C586.041 41.5938 581.855 37.4069 576.69 37.4069C571.525 37.4069 567.338 41.5938 567.338 46.7586C567.338 51.9235 571.525 56.1104 576.69 56.1104Z"
          fill="#FFD02B"
        />
        <path
          d="M607.862 56.1104C613.027 56.1104 617.214 51.9235 617.214 46.7586C617.214 41.5938 613.027 37.4069 607.862 37.4069C602.697 37.4069 598.51 41.5938 598.51 46.7586C598.51 51.9235 602.697 56.1104 607.862 56.1104Z"
          fill="#FFD02B"
        />
        <path
          d="M327.31 87.2828C332.475 87.2828 336.662 83.0959 336.662 77.931C336.662 72.7662 332.475 68.5793 327.31 68.5793C322.146 68.5793 317.959 72.7662 317.959 77.931C317.959 83.0959 322.146 87.2828 327.31 87.2828Z"
          fill="#FFD02B"
        />
        <path
          d="M389.655 87.2828C394.82 87.2828 399.007 83.0959 399.007 77.931C399.007 72.7662 394.82 68.5793 389.655 68.5793C384.49 68.5793 380.303 72.7662 380.303 77.931C380.303 83.0959 384.49 87.2828 389.655 87.2828Z"
          fill="#FFD02B"
        />
        <path
          d="M452 87.2828C457.165 87.2828 461.352 83.0959 461.352 77.931C461.352 72.7662 457.165 68.5793 452 68.5793C446.835 68.5793 442.648 72.7662 442.648 77.931C442.648 83.0959 446.835 87.2828 452 87.2828Z"
          fill="#FFD02B"
        />
        <path
          d="M483.172 87.2828C488.337 87.2828 492.524 83.0959 492.524 77.931C492.524 72.7662 488.337 68.5793 483.172 68.5793C478.008 68.5793 473.821 72.7662 473.821 77.931C473.821 83.0959 478.008 87.2828 483.172 87.2828Z"
          fill="#FFD02B"
        />
        <path
          d="M545.517 87.2828C550.682 87.2828 554.869 83.0959 554.869 77.931C554.869 72.7662 550.682 68.5793 545.517 68.5793C540.352 68.5793 536.166 72.7662 536.166 77.931C536.166 83.0959 540.352 87.2828 545.517 87.2828Z"
          fill="#FFD02B"
        />
        <path
          d="M296.138 118.455C301.303 118.455 305.49 114.268 305.49 109.103C305.49 103.939 301.303 99.7517 296.138 99.7517C290.973 99.7517 286.786 103.939 286.786 109.103C286.786 114.268 290.973 118.455 296.138 118.455Z"
          fill="#FFD02B"
        />
        <path
          d="M327.31 118.455C332.475 118.455 336.662 114.268 336.662 109.103C336.662 103.939 332.475 99.7517 327.31 99.7517C322.146 99.7517 317.959 103.939 317.959 109.103C317.959 114.268 322.146 118.455 327.31 118.455Z"
          fill="#FFD02B"
        />
        <path
          d="M389.655 118.455C394.82 118.455 399.007 114.268 399.007 109.103C399.007 103.939 394.82 99.7517 389.655 99.7517C384.49 99.7517 380.303 103.939 380.303 109.103C380.303 114.268 384.49 118.455 389.655 118.455Z"
          fill="#FFD02B"
        />
        <path
          d="M420.828 118.455C425.992 118.455 430.179 114.268 430.179 109.103C430.179 103.939 425.992 99.7517 420.828 99.7517C415.663 99.7517 411.476 103.939 411.476 109.103C411.476 114.268 415.663 118.455 420.828 118.455Z"
          fill="#FFD02B"
        />
        <path
          d="M483.172 118.455C488.337 118.455 492.524 114.268 492.524 109.103C492.524 103.939 488.337 99.7517 483.172 99.7517C478.008 99.7517 473.821 103.939 473.821 109.103C473.821 114.268 478.008 118.455 483.172 118.455Z"
          fill="#FFD02B"
        />
        <path
          d="M514.345 118.455C519.51 118.455 523.697 114.268 523.697 109.103C523.697 103.939 519.51 99.7517 514.345 99.7517C509.18 99.7517 504.993 103.939 504.993 109.103C504.993 114.268 509.18 118.455 514.345 118.455Z"
          fill="#FFD02B"
        />
        <path
          d="M639.035 118.455C644.199 118.455 648.386 114.268 648.386 109.103C648.386 103.939 644.199 99.7517 639.035 99.7517C633.87 99.7517 629.683 103.939 629.683 109.103C629.683 114.268 633.87 118.455 639.035 118.455Z"
          fill="#FFD02B"
        />
        <path
          d="M327.31 149.628C332.475 149.628 336.662 145.441 336.662 140.276C336.662 135.111 332.475 130.924 327.31 130.924C322.146 130.924 317.959 135.111 317.959 140.276C317.959 145.441 322.146 149.628 327.31 149.628Z"
          fill="#FFD02B"
        />
        <path
          d="M358.483 149.628C363.648 149.628 367.835 145.441 367.835 140.276C367.835 135.111 363.648 130.924 358.483 130.924C353.318 130.924 349.131 135.111 349.131 140.276C349.131 145.441 353.318 149.628 358.483 149.628Z"
          fill="#FFD02B"
        />
        <path
          d="M420.828 149.628C425.992 149.628 430.179 145.441 430.179 140.276C430.179 135.111 425.992 130.924 420.828 130.924C415.663 130.924 411.476 135.111 411.476 140.276C411.476 145.441 415.663 149.628 420.828 149.628Z"
          fill="#FFD02B"
        />
        <path
          d="M514.345 149.628C519.51 149.628 523.697 145.441 523.697 140.276C523.697 135.111 519.51 130.924 514.345 130.924C509.18 130.924 504.993 135.111 504.993 140.276C504.993 145.441 509.18 149.628 514.345 149.628Z"
          fill="#FFD02B"
        />
        <path
          d="M545.517 149.628C550.682 149.628 554.869 145.441 554.869 140.276C554.869 135.111 550.682 130.924 545.517 130.924C540.352 130.924 536.166 135.111 536.166 140.276C536.166 145.441 540.352 149.628 545.517 149.628Z"
          fill="#FFD02B"
        />
        <path
          d="M576.69 149.628C581.855 149.628 586.041 145.441 586.041 140.276C586.041 135.111 581.855 130.924 576.69 130.924C571.525 130.924 567.338 135.111 567.338 140.276C567.338 145.441 571.525 149.628 576.69 149.628Z"
          fill="#FFD02B"
        />
        <path
          d="M264.966 180.8C270.13 180.8 274.317 176.613 274.317 171.448C274.317 166.283 270.13 162.097 264.966 162.097C259.801 162.097 255.614 166.283 255.614 171.448C255.614 176.613 259.801 180.8 264.966 180.8Z"
          fill="#FFD02B"
        />
        <path
          d="M296.138 180.8C301.303 180.8 305.49 176.613 305.49 171.448C305.49 166.283 301.303 162.097 296.138 162.097C290.973 162.097 286.786 166.283 286.786 171.448C286.786 176.613 290.973 180.8 296.138 180.8Z"
          fill="#FFD02B"
        />
        <path
          d="M389.655 180.8C394.82 180.8 399.007 176.613 399.007 171.448C399.007 166.283 394.82 162.097 389.655 162.097C384.49 162.097 380.303 166.283 380.303 171.448C380.303 176.613 384.49 180.8 389.655 180.8Z"
          fill="#FFD02B"
        />
        <path
          d="M420.828 180.8C425.992 180.8 430.179 176.613 430.179 171.448C430.179 166.283 425.992 162.097 420.828 162.097C415.663 162.097 411.476 166.283 411.476 171.448C411.476 176.613 415.663 180.8 420.828 180.8Z"
          fill="#FFD02B"
        />
        <path
          d="M452 180.8C457.165 180.8 461.352 176.613 461.352 171.448C461.352 166.283 457.165 162.097 452 162.097C446.835 162.097 442.648 166.283 442.648 171.448C442.648 176.613 446.835 180.8 452 180.8Z"
          fill="#FFD02B"
        />
        <path
          d="M483.172 180.8C488.337 180.8 492.524 176.613 492.524 171.448C492.524 166.283 488.337 162.097 483.172 162.097C478.008 162.097 473.821 166.283 473.821 171.448C473.821 176.613 478.008 180.8 483.172 180.8Z"
          fill="#FFD02B"
        />
        <path
          d="M545.517 180.8C550.682 180.8 554.869 176.613 554.869 171.448C554.869 166.283 550.682 162.097 545.517 162.097C540.352 162.097 536.166 166.283 536.166 171.448C536.166 176.613 540.352 180.8 545.517 180.8Z"
          fill="#FFD02B"
        />
        <path
          d="M607.862 180.8C613.027 180.8 617.214 176.613 617.214 171.448C617.214 166.283 613.027 162.097 607.862 162.097C602.697 162.097 598.51 166.283 598.51 171.448C598.51 176.613 602.697 180.8 607.862 180.8Z"
          fill="#FFD02B"
        />
        <path
          d="M639.035 180.8C644.199 180.8 648.386 176.613 648.386 171.448C648.386 166.283 644.199 162.097 639.035 162.097C633.87 162.097 629.683 166.283 629.683 171.448C629.683 176.613 633.87 180.8 639.035 180.8Z"
          fill="#FFD02B"
        />
        <path
          d="M264.966 211.972C270.13 211.972 274.317 207.786 274.317 202.621C274.317 197.456 270.13 193.269 264.966 193.269C259.801 193.269 255.614 197.456 255.614 202.621C255.614 207.786 259.801 211.972 264.966 211.972Z"
          fill="#FFD02B"
        />
        <path
          d="M327.31 211.972C332.475 211.972 336.662 207.786 336.662 202.621C336.662 197.456 332.475 193.269 327.31 193.269C322.146 193.269 317.959 197.456 317.959 202.621C317.959 207.786 322.146 211.972 327.31 211.972Z"
          fill="#FFD02B"
        />
        <path
          d="M389.655 211.972C394.82 211.972 399.007 207.786 399.007 202.621C399.007 197.456 394.82 193.269 389.655 193.269C384.49 193.269 380.303 197.456 380.303 202.621C380.303 207.786 384.49 211.972 389.655 211.972Z"
          fill="#FFD02B"
        />
        <path
          d="M452 211.972C457.165 211.972 461.352 207.786 461.352 202.621C461.352 197.456 457.165 193.269 452 193.269C446.835 193.269 442.648 197.456 442.648 202.621C442.648 207.786 446.835 211.972 452 211.972Z"
          fill="#FFD02B"
        />
        <path
          d="M514.345 211.972C519.51 211.972 523.697 207.786 523.697 202.621C523.697 197.456 519.51 193.269 514.345 193.269C509.18 193.269 504.993 197.456 504.993 202.621C504.993 207.786 509.18 211.972 514.345 211.972Z"
          fill="#FFD02B"
        />
        <path
          d="M576.69 211.972C581.855 211.972 586.041 207.786 586.041 202.621C586.041 197.456 581.855 193.269 576.69 193.269C571.525 193.269 567.338 197.456 567.338 202.621C567.338 207.786 571.525 211.972 576.69 211.972Z"
          fill="#FFD02B"
        />
        <path
          d="M639.035 211.972C644.199 211.972 648.386 207.786 648.386 202.621C648.386 197.456 644.199 193.269 639.035 193.269C633.87 193.269 629.683 197.456 629.683 202.621C629.683 207.786 633.87 211.972 639.035 211.972Z"
          fill="#FFD02B"
        />
        <path
          d="M264.966 243.145C270.13 243.145 274.317 238.958 274.317 233.793C274.317 228.628 270.13 224.441 264.966 224.441C259.801 224.441 255.614 228.628 255.614 233.793C255.614 238.958 259.801 243.145 264.966 243.145Z"
          fill="#FFD02B"
        />
        <path
          d="M327.31 243.145C332.475 243.145 336.662 238.958 336.662 233.793C336.662 228.628 332.475 224.441 327.31 224.441C322.146 224.441 317.959 228.628 317.959 233.793C317.959 238.958 322.146 243.145 327.31 243.145Z"
          fill="#FFD02B"
        />
        <path
          d="M358.483 243.145C363.648 243.145 367.835 238.958 367.835 233.793C367.835 228.628 363.648 224.441 358.483 224.441C353.318 224.441 349.131 228.628 349.131 233.793C349.131 238.958 353.318 243.145 358.483 243.145Z"
          fill="#FFD02B"
        />
        <path
          d="M389.655 243.145C394.82 243.145 399.007 238.958 399.007 233.793C399.007 228.628 394.82 224.441 389.655 224.441C384.49 224.441 380.303 228.628 380.303 233.793C380.303 238.958 384.49 243.145 389.655 243.145Z"
          fill="#FFD02B"
        />
        <path
          d="M420.828 243.145C425.992 243.145 430.179 238.958 430.179 233.793C430.179 228.628 425.992 224.441 420.828 224.441C415.663 224.441 411.476 228.628 411.476 233.793C411.476 238.958 415.663 243.145 420.828 243.145Z"
          fill="#FFD02B"
        />
        <path
          d="M452 243.145C457.165 243.145 461.352 238.958 461.352 233.793C461.352 228.628 457.165 224.441 452 224.441C446.835 224.441 442.648 228.628 442.648 233.793C442.648 238.958 446.835 243.145 452 243.145Z"
          fill="#FFD02B"
        />
        <path
          d="M514.345 243.145C519.51 243.145 523.697 238.958 523.697 233.793C523.697 228.628 519.51 224.441 514.345 224.441C509.18 224.441 504.993 228.628 504.993 233.793C504.993 238.958 509.18 243.145 514.345 243.145Z"
          fill="#FFD02B"
        />
        <path
          d="M140.276 274.317C145.441 274.317 149.628 270.13 149.628 264.966C149.628 259.801 145.441 255.614 140.276 255.614C135.111 255.614 130.924 259.801 130.924 264.966C130.924 270.13 135.111 274.317 140.276 274.317Z"
          fill="#FFD02B"
        />
        <path
          d="M171.448 274.317C176.613 274.317 180.8 270.13 180.8 264.966C180.8 259.801 176.613 255.614 171.448 255.614C166.283 255.614 162.097 259.801 162.097 264.966C162.097 270.13 166.283 274.317 171.448 274.317Z"
          fill="#FFD02B"
        />
        <path
          d="M202.621 274.317C207.786 274.317 211.972 270.13 211.972 264.966C211.972 259.801 207.786 255.614 202.621 255.614C197.456 255.614 193.269 259.801 193.269 264.966C193.269 270.13 197.456 274.317 202.621 274.317Z"
          fill="#FFD02B"
        />
        <path
          d="M233.793 274.317C238.958 274.317 243.145 270.13 243.145 264.966C243.145 259.801 238.958 255.614 233.793 255.614C228.628 255.614 224.441 259.801 224.441 264.966C224.441 270.13 228.628 274.317 233.793 274.317Z"
          fill="#FFD02B"
        />
        <path
          d="M296.138 274.317C301.303 274.317 305.49 270.13 305.49 264.966C305.49 259.801 301.303 255.614 296.138 255.614C290.973 255.614 286.786 259.801 286.786 264.966C286.786 270.13 290.973 274.317 296.138 274.317Z"
          fill="#FFD02B"
        />
        <path
          d="M327.31 274.317C332.475 274.317 336.662 270.13 336.662 264.966C336.662 259.801 332.475 255.614 327.31 255.614C322.146 255.614 317.959 259.801 317.959 264.966C317.959 270.13 322.146 274.317 327.31 274.317Z"
          fill="#FFD02B"
        />
        <path
          d="M389.655 274.317C394.82 274.317 399.007 270.13 399.007 264.966C399.007 259.801 394.82 255.614 389.655 255.614C384.49 255.614 380.303 259.801 380.303 264.966C380.303 270.13 384.49 274.317 389.655 274.317Z"
          fill="#FFD02B"
        />
        <path
          d="M483.172 274.317C488.337 274.317 492.524 270.13 492.524 264.966C492.524 259.801 488.337 255.614 483.172 255.614C478.008 255.614 473.821 259.801 473.821 264.966C473.821 270.13 478.008 274.317 483.172 274.317Z"
          fill="#FFD02B"
        />
        <path
          d="M607.862 274.317C613.027 274.317 617.214 270.13 617.214 264.966C617.214 259.801 613.027 255.614 607.862 255.614C602.697 255.614 598.51 259.801 598.51 264.966C598.51 270.13 602.697 274.317 607.862 274.317Z"
          fill="#FFD02B"
        />
        <path
          d="M639.035 274.317C644.199 274.317 648.386 270.13 648.386 264.966C648.386 259.801 644.199 255.614 639.035 255.614C633.87 255.614 629.683 259.801 629.683 264.966C629.683 270.13 633.87 274.317 639.035 274.317Z"
          fill="#FFD02B"
        />
        <path
          d="M701.379 274.317C706.544 274.317 710.731 270.13 710.731 264.966C710.731 259.801 706.544 255.614 701.379 255.614C696.215 255.614 692.028 259.801 692.028 264.966C692.028 270.13 696.215 274.317 701.379 274.317Z"
          fill="#FFD02B"
        />
        <path
          d="M732.552 274.317C737.717 274.317 741.903 270.13 741.903 264.966C741.903 259.801 737.717 255.614 732.552 255.614C727.387 255.614 723.2 259.801 723.2 264.966C723.2 270.13 727.387 274.317 732.552 274.317Z"
          fill="#FFD02B"
        />
        <path
          d="M857.241 274.317C862.406 274.317 866.593 270.13 866.593 264.966C866.593 259.801 862.406 255.614 857.241 255.614C852.077 255.614 847.89 259.801 847.89 264.966C847.89 270.13 852.077 274.317 857.241 274.317Z"
          fill="#FFD02B"
        />
        <path
          d="M46.7586 305.49C51.9235 305.49 56.1104 301.303 56.1104 296.138C56.1104 290.973 51.9235 286.786 46.7586 286.786C41.5938 286.786 37.4069 290.973 37.4069 296.138C37.4069 301.303 41.5938 305.49 46.7586 305.49Z"
          fill="#FFD02B"
        />
        <path
          d="M171.448 305.49C176.613 305.49 180.8 301.303 180.8 296.138C180.8 290.973 176.613 286.786 171.448 286.786C166.283 286.786 162.097 290.973 162.097 296.138C162.097 301.303 166.283 305.49 171.448 305.49Z"
          fill="#FFD02B"
        />
        <path
          d="M233.793 305.49C238.958 305.49 243.145 301.303 243.145 296.138C243.145 290.973 238.958 286.786 233.793 286.786C228.628 286.786 224.441 290.973 224.441 296.138C224.441 301.303 228.628 305.49 233.793 305.49Z"
          fill="#FFD02B"
        />
        <path
          d="M264.966 305.49C270.13 305.49 274.317 301.303 274.317 296.138C274.317 290.973 270.13 286.786 264.966 286.786C259.801 286.786 255.614 290.973 255.614 296.138C255.614 301.303 259.801 305.49 264.966 305.49Z"
          fill="#FFD02B"
        />
        <path
          d="M296.138 305.49C301.303 305.49 305.49 301.303 305.49 296.138C305.49 290.973 301.303 286.786 296.138 286.786C290.973 286.786 286.786 290.973 286.786 296.138C286.786 301.303 290.973 305.49 296.138 305.49Z"
          fill="#FFD02B"
        />
        <path
          d="M327.31 305.49C332.475 305.49 336.662 301.303 336.662 296.138C336.662 290.973 332.475 286.786 327.31 286.786C322.146 286.786 317.959 290.973 317.959 296.138C317.959 301.303 322.146 305.49 327.31 305.49Z"
          fill="#FFD02B"
        />
        <path
          d="M358.483 305.49C363.648 305.49 367.835 301.303 367.835 296.138C367.835 290.973 363.648 286.786 358.483 286.786C353.318 286.786 349.131 290.973 349.131 296.138C349.131 301.303 353.318 305.49 358.483 305.49Z"
          fill="#FFD02B"
        />
        <path
          d="M420.828 305.49C425.992 305.49 430.179 301.303 430.179 296.138C430.179 290.973 425.992 286.786 420.828 286.786C415.663 286.786 411.476 290.973 411.476 296.138C411.476 301.303 415.663 305.49 420.828 305.49Z"
          fill="#FFD02B"
        />
        <path
          d="M452 305.49C457.165 305.49 461.352 301.303 461.352 296.138C461.352 290.973 457.165 286.786 452 286.786C446.835 286.786 442.648 290.973 442.648 296.138C442.648 301.303 446.835 305.49 452 305.49Z"
          fill="#FFD02B"
        />
        <path
          d="M483.172 305.49C488.337 305.49 492.524 301.303 492.524 296.138C492.524 290.973 488.337 286.786 483.172 286.786C478.008 286.786 473.821 290.973 473.821 296.138C473.821 301.303 478.008 305.49 483.172 305.49Z"
          fill="#FFD02B"
        />
        <path
          d="M545.517 305.49C550.682 305.49 554.869 301.303 554.869 296.138C554.869 290.973 550.682 286.786 545.517 286.786C540.352 286.786 536.166 290.973 536.166 296.138C536.166 301.303 540.352 305.49 545.517 305.49Z"
          fill="#FFD02B"
        />
        <path
          d="M576.69 305.49C581.855 305.49 586.041 301.303 586.041 296.138C586.041 290.973 581.855 286.786 576.69 286.786C571.525 286.786 567.338 290.973 567.338 296.138C567.338 301.303 571.525 305.49 576.69 305.49Z"
          fill="#FFD02B"
        />
        <path
          d="M607.862 305.49C613.027 305.49 617.214 301.303 617.214 296.138C617.214 290.973 613.027 286.786 607.862 286.786C602.697 286.786 598.51 290.973 598.51 296.138C598.51 301.303 602.697 305.49 607.862 305.49Z"
          fill="#FFD02B"
        />
        <path
          d="M701.379 305.49C706.544 305.49 710.731 301.303 710.731 296.138C710.731 290.973 706.544 286.786 701.379 286.786C696.215 286.786 692.028 290.973 692.028 296.138C692.028 301.303 696.215 305.49 701.379 305.49Z"
          fill="#FFD02B"
        />
        <path
          d="M732.552 305.49C737.717 305.49 741.903 301.303 741.903 296.138C741.903 290.973 737.717 286.786 732.552 286.786C727.387 286.786 723.2 290.973 723.2 296.138C723.2 301.303 727.387 305.49 732.552 305.49Z"
          fill="#FFD02B"
        />
        <path
          d="M763.724 305.49C768.889 305.49 773.076 301.303 773.076 296.138C773.076 290.973 768.889 286.786 763.724 286.786C758.559 286.786 754.372 290.973 754.372 296.138C754.372 301.303 758.559 305.49 763.724 305.49Z"
          fill="#FFD02B"
        />
        <path
          d="M826.069 305.49C831.234 305.49 835.421 301.303 835.421 296.138C835.421 290.973 831.234 286.786 826.069 286.786C820.904 286.786 816.717 290.973 816.717 296.138C816.717 301.303 820.904 305.49 826.069 305.49Z"
          fill="#FFD02B"
        />
        <path
          d="M857.241 305.49C862.406 305.49 866.593 301.303 866.593 296.138C866.593 290.973 862.406 286.786 857.241 286.786C852.077 286.786 847.89 290.973 847.89 296.138C847.89 301.303 852.077 305.49 857.241 305.49Z"
          fill="#FFD02B"
        />
        <path
          d="M888.414 305.49C893.579 305.49 897.766 301.303 897.766 296.138C897.766 290.973 893.579 286.786 888.414 286.786C883.249 286.786 879.062 290.973 879.062 296.138C879.062 301.303 883.249 305.49 888.414 305.49Z"
          fill="#FFD02B"
        />
        <path
          d="M15.5862 336.662C20.751 336.662 24.9379 332.475 24.9379 327.31C24.9379 322.146 20.751 317.959 15.5862 317.959C10.4214 317.959 6.2345 322.146 6.2345 327.31C6.2345 332.475 10.4214 336.662 15.5862 336.662Z"
          fill="#FFD02B"
        />
        <path
          d="M46.7586 336.662C51.9235 336.662 56.1104 332.475 56.1104 327.31C56.1104 322.146 51.9235 317.959 46.7586 317.959C41.5938 317.959 37.4069 322.146 37.4069 327.31C37.4069 332.475 41.5938 336.662 46.7586 336.662Z"
          fill="#FFD02B"
        />
        <path
          d="M77.931 336.662C83.0959 336.662 87.2828 332.475 87.2828 327.31C87.2828 322.146 83.0959 317.959 77.931 317.959C72.7662 317.959 68.5793 322.146 68.5793 327.31C68.5793 332.475 72.7662 336.662 77.931 336.662Z"
          fill="#FFD02B"
        />
        <path
          d="M109.103 336.662C114.268 336.662 118.455 332.475 118.455 327.31C118.455 322.146 114.268 317.959 109.103 317.959C103.939 317.959 99.7517 322.146 99.7517 327.31C99.7517 332.475 103.939 336.662 109.103 336.662Z"
          fill="#FFD02B"
        />
        <path
          d="M140.276 336.662C145.441 336.662 149.628 332.475 149.628 327.31C149.628 322.146 145.441 317.959 140.276 317.959C135.111 317.959 130.924 322.146 130.924 327.31C130.924 332.475 135.111 336.662 140.276 336.662Z"
          fill="#FFD02B"
        />
        <path
          d="M171.448 336.662C176.613 336.662 180.8 332.475 180.8 327.31C180.8 322.146 176.613 317.959 171.448 317.959C166.283 317.959 162.097 322.146 162.097 327.31C162.097 332.475 166.283 336.662 171.448 336.662Z"
          fill="#FFD02B"
        />
        <path
          d="M202.621 336.662C207.786 336.662 211.972 332.475 211.972 327.31C211.972 322.146 207.786 317.959 202.621 317.959C197.456 317.959 193.269 322.146 193.269 327.31C193.269 332.475 197.456 336.662 202.621 336.662Z"
          fill="#FFD02B"
        />
        <path
          d="M233.793 336.662C238.958 336.662 243.145 332.475 243.145 327.31C243.145 322.146 238.958 317.959 233.793 317.959C228.628 317.959 224.441 322.146 224.441 327.31C224.441 332.475 228.628 336.662 233.793 336.662Z"
          fill="#FFD02B"
        />
        <path
          d="M264.966 336.662C270.13 336.662 274.317 332.475 274.317 327.31C274.317 322.146 270.13 317.959 264.966 317.959C259.801 317.959 255.614 322.146 255.614 327.31C255.614 332.475 259.801 336.662 264.966 336.662Z"
          fill="#FFD02B"
        />
        <path
          d="M523.697 327.31C523.697 322.145 519.511 317.959 514.345 317.959C510.898 317.959 507.888 319.829 506.267 322.608C512.038 325.03 517.605 327.827 522.948 330.971C523.429 329.849 523.697 328.611 523.697 327.31Z"
          fill="#FFD02B"
        />
        <path
          d="M607.862 336.662C613.027 336.662 617.214 332.475 617.214 327.31C617.214 322.146 613.027 317.959 607.862 317.959C602.697 317.959 598.51 322.146 598.51 327.31C598.51 332.475 602.697 336.662 607.862 336.662Z"
          fill="#FFD02B"
        />
        <path
          d="M670.207 336.662C675.372 336.662 679.559 332.475 679.559 327.31C679.559 322.146 675.372 317.959 670.207 317.959C665.042 317.959 660.855 322.146 660.855 327.31C660.855 332.475 665.042 336.662 670.207 336.662Z"
          fill="#FFD02B"
        />
        <path
          d="M732.552 336.662C737.717 336.662 741.903 332.475 741.903 327.31C741.903 322.146 737.717 317.959 732.552 317.959C727.387 317.959 723.2 322.146 723.2 327.31C723.2 332.475 727.387 336.662 732.552 336.662Z"
          fill="#FFD02B"
        />
        <path
          d="M763.724 336.662C768.889 336.662 773.076 332.475 773.076 327.31C773.076 322.146 768.889 317.959 763.724 317.959C758.559 317.959 754.372 322.146 754.372 327.31C754.372 332.475 758.559 336.662 763.724 336.662Z"
          fill="#FFD02B"
        />
        <path
          d="M826.069 336.662C831.234 336.662 835.421 332.475 835.421 327.31C835.421 322.146 831.234 317.959 826.069 317.959C820.904 317.959 816.717 322.146 816.717 327.31C816.717 332.475 820.904 336.662 826.069 336.662Z"
          fill="#FFD02B"
        />
        <path
          d="M888.414 336.662C893.579 336.662 897.766 332.475 897.766 327.31C897.766 322.146 893.579 317.959 888.414 317.959C883.249 317.959 879.062 322.146 879.062 327.31C879.062 332.475 883.249 336.662 888.414 336.662Z"
          fill="#FFD02B"
        />
        <path
          d="M46.7586 367.835C51.9235 367.835 56.1104 363.648 56.1104 358.483C56.1104 353.318 51.9235 349.131 46.7586 349.131C41.5938 349.131 37.4069 353.318 37.4069 358.483C37.4069 363.648 41.5938 367.835 46.7586 367.835Z"
          fill="#FFD02B"
        />
        <path
          d="M233.793 367.835C238.958 367.835 243.145 363.648 243.145 358.483C243.145 353.318 238.958 349.131 233.793 349.131C228.628 349.131 224.441 353.318 224.441 358.483C224.441 363.648 228.628 367.835 233.793 367.835Z"
          fill="#FFD02B"
        />
        <path
          d="M296.138 367.835C301.303 367.835 305.49 363.648 305.49 358.483C305.49 353.318 301.303 349.131 296.138 349.131C290.973 349.131 286.786 353.318 286.786 358.483C286.786 363.648 290.973 367.835 296.138 367.835Z"
          fill="#FFD02B"
        />
        <path
          d="M607.862 367.835C613.027 367.835 617.214 363.648 617.214 358.483C617.214 353.318 613.027 349.131 607.862 349.131C602.697 349.131 598.51 353.318 598.51 358.483C598.51 363.648 602.697 367.835 607.862 367.835Z"
          fill="#FFD02B"
        />
        <path
          d="M639.035 367.835C644.199 367.835 648.386 363.648 648.386 358.483C648.386 353.318 644.199 349.131 639.035 349.131C633.87 349.131 629.683 353.318 629.683 358.483C629.683 363.648 633.87 367.835 639.035 367.835Z"
          fill="#FFD02B"
        />
        <path
          d="M670.207 367.835C675.372 367.835 679.559 363.648 679.559 358.483C679.559 353.318 675.372 349.131 670.207 349.131C665.042 349.131 660.855 353.318 660.855 358.483C660.855 363.648 665.042 367.835 670.207 367.835Z"
          fill="#FFD02B"
        />
        <path
          d="M763.724 367.835C768.889 367.835 773.076 363.648 773.076 358.483C773.076 353.318 768.889 349.131 763.724 349.131C758.559 349.131 754.372 353.318 754.372 358.483C754.372 363.648 758.559 367.835 763.724 367.835Z"
          fill="#FFD02B"
        />
        <path
          d="M794.897 367.835C800.061 367.835 804.248 363.648 804.248 358.483C804.248 353.318 800.061 349.131 794.897 349.131C789.732 349.131 785.545 353.318 785.545 358.483C785.545 363.648 789.732 367.835 794.897 367.835Z"
          fill="#FFD02B"
        />
        <path
          d="M857.241 367.835C862.406 367.835 866.593 363.648 866.593 358.483C866.593 353.318 862.406 349.131 857.241 349.131C852.077 349.131 847.89 353.318 847.89 358.483C847.89 363.648 852.077 367.835 857.241 367.835Z"
          fill="#FFD02B"
        />
        <path
          d="M888.414 367.835C893.579 367.835 897.766 363.648 897.766 358.483C897.766 353.318 893.579 349.131 888.414 349.131C883.249 349.131 879.062 353.318 879.062 358.483C879.062 363.648 883.249 367.835 888.414 367.835Z"
          fill="#FFD02B"
        />
        <path
          d="M46.7586 399.007C51.9235 399.007 56.1104 394.82 56.1104 389.655C56.1104 384.49 51.9235 380.303 46.7586 380.303C41.5938 380.303 37.4069 384.49 37.4069 389.655C37.4069 394.82 41.5938 399.007 46.7586 399.007Z"
          fill="#FFD02B"
        />
        <path
          d="M202.621 399.007C207.786 399.007 211.972 394.82 211.972 389.655C211.972 384.49 207.786 380.303 202.621 380.303C197.456 380.303 193.269 384.49 193.269 389.655C193.269 394.82 197.456 399.007 202.621 399.007Z"
          fill="#FFD02B"
        />
        <path
          d="M233.793 399.007C238.958 399.007 243.145 394.82 243.145 389.655C243.145 384.49 238.958 380.303 233.793 380.303C228.628 380.303 224.441 384.49 224.441 389.655C224.441 394.82 228.628 399.007 233.793 399.007Z"
          fill="#FFD02B"
        />
        <path
          d="M264.966 399.007C270.13 399.007 274.317 394.82 274.317 389.655C274.317 384.49 270.13 380.303 264.966 380.303C259.801 380.303 255.614 384.49 255.614 389.655C255.614 394.82 259.801 399.007 264.966 399.007Z"
          fill="#FFD02B"
        />
        <path
          d="M296.138 399.007C301.303 399.007 305.49 394.82 305.49 389.655C305.49 384.49 301.303 380.303 296.138 380.303C290.973 380.303 286.786 384.49 286.786 389.655C286.786 394.82 290.973 399.007 296.138 399.007Z"
          fill="#FFD02B"
        />
        <path
          d="M327.31 380.303C322.145 380.303 317.959 384.489 317.959 389.655C317.959 393.102 319.829 396.112 322.608 397.733C325.03 391.962 327.827 386.395 330.971 381.052C329.849 380.571 328.611 380.303 327.31 380.303Z"
          fill="#FFD02B"
        />
        <path
          d="M586.041 389.655C586.041 384.489 581.855 380.303 576.69 380.303C575.389 380.303 574.151 380.571 573.029 381.052C576.173 386.395 578.97 391.962 581.392 397.733C584.171 396.112 586.041 393.111 586.041 389.655Z"
          fill="#FFD02B"
        />
        <path
          d="M732.552 399.007C737.717 399.007 741.903 394.82 741.903 389.655C741.903 384.49 737.717 380.303 732.552 380.303C727.387 380.303 723.2 384.49 723.2 389.655C723.2 394.82 727.387 399.007 732.552 399.007Z"
          fill="#FFD02B"
        />
        <path
          d="M794.897 399.007C800.061 399.007 804.248 394.82 804.248 389.655C804.248 384.49 800.061 380.303 794.897 380.303C789.732 380.303 785.545 384.49 785.545 389.655C785.545 394.82 789.732 399.007 794.897 399.007Z"
          fill="#FFD02B"
        />
        <path
          d="M888.414 399.007C893.579 399.007 897.766 394.82 897.766 389.655C897.766 384.49 893.579 380.303 888.414 380.303C883.249 380.303 879.062 384.49 879.062 389.655C879.062 394.82 883.249 399.007 888.414 399.007Z"
          fill="#FFD02B"
        />
        <path
          d="M15.5862 430.179C20.751 430.179 24.9379 425.992 24.9379 420.828C24.9379 415.663 20.751 411.476 15.5862 411.476C10.4214 411.476 6.2345 415.663 6.2345 420.828C6.2345 425.992 10.4214 430.179 15.5862 430.179Z"
          fill="#FFD02B"
        />
        <path
          d="M46.7586 430.179C51.9235 430.179 56.1104 425.992 56.1104 420.828C56.1104 415.663 51.9235 411.476 46.7586 411.476C41.5938 411.476 37.4069 415.663 37.4069 420.828C37.4069 425.992 41.5938 430.179 46.7586 430.179Z"
          fill="#FFD02B"
        />
        <path
          d="M109.103 430.179C114.268 430.179 118.455 425.992 118.455 420.828C118.455 415.663 114.268 411.476 109.103 411.476C103.939 411.476 99.7517 415.663 99.7517 420.828C99.7517 425.992 103.939 430.179 109.103 430.179Z"
          fill="#FFD02B"
        />
        <path
          d="M140.276 430.179C145.441 430.179 149.628 425.992 149.628 420.828C149.628 415.663 145.441 411.476 140.276 411.476C135.111 411.476 130.924 415.663 130.924 420.828C130.924 425.992 135.111 430.179 140.276 430.179Z"
          fill="#FFD02B"
        />
        <path
          d="M264.966 430.179C270.13 430.179 274.317 425.992 274.317 420.828C274.317 415.663 270.13 411.476 264.966 411.476C259.801 411.476 255.614 415.663 255.614 420.828C255.614 425.992 259.801 430.179 264.966 430.179Z"
          fill="#FFD02B"
        />
        <path
          d="M296.138 430.179C301.303 430.179 305.49 425.992 305.49 420.828C305.49 415.663 301.303 411.476 296.138 411.476C290.973 411.476 286.786 415.663 286.786 420.828C286.786 425.992 290.973 430.179 296.138 430.179Z"
          fill="#FFD02B"
        />
        <path
          d="M701.379 430.179C706.544 430.179 710.731 425.992 710.731 420.828C710.731 415.663 706.544 411.476 701.379 411.476C696.215 411.476 692.028 415.663 692.028 420.828C692.028 425.992 696.215 430.179 701.379 430.179Z"
          fill="#FFD02B"
        />
        <path
          d="M763.724 430.179C768.889 430.179 773.076 425.992 773.076 420.828C773.076 415.663 768.889 411.476 763.724 411.476C758.559 411.476 754.372 415.663 754.372 420.828C754.372 425.992 758.559 430.179 763.724 430.179Z"
          fill="#FFD02B"
        />
        <path
          d="M826.069 430.179C831.234 430.179 835.421 425.992 835.421 420.828C835.421 415.663 831.234 411.476 826.069 411.476C820.904 411.476 816.717 415.663 816.717 420.828C816.717 425.992 820.904 430.179 826.069 430.179Z"
          fill="#FFD02B"
        />
        <path
          d="M888.414 430.179C893.579 430.179 897.766 425.992 897.766 420.828C897.766 415.663 893.579 411.476 888.414 411.476C883.249 411.476 879.062 415.663 879.062 420.828C879.062 425.992 883.249 430.179 888.414 430.179Z"
          fill="#FFD02B"
        />
        <path
          d="M77.931 461.352C83.0959 461.352 87.2828 457.165 87.2828 452C87.2828 446.835 83.0959 442.648 77.931 442.648C72.7662 442.648 68.5793 446.835 68.5793 452C68.5793 457.165 72.7662 461.352 77.931 461.352Z"
          fill="#FFD02B"
        />
        <path
          d="M109.103 461.352C114.268 461.352 118.455 457.165 118.455 452C118.455 446.835 114.268 442.648 109.103 442.648C103.939 442.648 99.7517 446.835 99.7517 452C99.7517 457.165 103.939 461.352 109.103 461.352Z"
          fill="#FFD02B"
        />
        <path
          d="M171.448 461.352C176.613 461.352 180.8 457.165 180.8 452C180.8 446.835 176.613 442.648 171.448 442.648C166.283 442.648 162.097 446.835 162.097 452C162.097 457.165 166.283 461.352 171.448 461.352Z"
          fill="#FFD02B"
        />
        <path
          d="M202.621 461.352C207.786 461.352 211.972 457.165 211.972 452C211.972 446.835 207.786 442.648 202.621 442.648C197.456 442.648 193.269 446.835 193.269 452C193.269 457.165 197.456 461.352 202.621 461.352Z"
          fill="#FFD02B"
        />
        <path
          d="M639.035 461.352C644.199 461.352 648.386 457.165 648.386 452C648.386 446.835 644.199 442.648 639.035 442.648C633.87 442.648 629.683 446.835 629.683 452C629.683 457.165 633.87 461.352 639.035 461.352Z"
          fill="#FFD02B"
        />
        <path
          d="M763.724 461.352C768.889 461.352 773.076 457.165 773.076 452C773.076 446.835 768.889 442.648 763.724 442.648C758.559 442.648 754.372 446.835 754.372 452C754.372 457.165 758.559 461.352 763.724 461.352Z"
          fill="#FFD02B"
        />
        <path
          d="M888.414 461.352C893.579 461.352 897.766 457.165 897.766 452C897.766 446.835 893.579 442.648 888.414 442.648C883.249 442.648 879.062 446.835 879.062 452C879.062 457.165 883.249 461.352 888.414 461.352Z"
          fill="#FFD02B"
        />
        <path
          d="M77.931 492.524C83.0959 492.524 87.2828 488.337 87.2828 483.172C87.2828 478.008 83.0959 473.821 77.931 473.821C72.7662 473.821 68.5793 478.008 68.5793 483.172C68.5793 488.337 72.7662 492.524 77.931 492.524Z"
          fill="#FFD02B"
        />
        <path
          d="M109.103 492.524C114.268 492.524 118.455 488.337 118.455 483.172C118.455 478.008 114.268 473.821 109.103 473.821C103.939 473.821 99.7517 478.008 99.7517 483.172C99.7517 488.337 103.939 492.524 109.103 492.524Z"
          fill="#FFD02B"
        />
        <path
          d="M264.966 492.524C270.13 492.524 274.317 488.337 274.317 483.172C274.317 478.008 270.13 473.821 264.966 473.821C259.801 473.821 255.614 478.008 255.614 483.172C255.614 488.337 259.801 492.524 264.966 492.524Z"
          fill="#FFD02B"
        />
        <path
          d="M296.138 492.524C301.303 492.524 305.49 488.337 305.49 483.172C305.49 478.008 301.303 473.821 296.138 473.821C290.973 473.821 286.786 478.008 286.786 483.172C286.786 488.337 290.973 492.524 296.138 492.524Z"
          fill="#FFD02B"
        />
        <path
          d="M670.207 492.524C675.372 492.524 679.559 488.337 679.559 483.172C679.559 478.008 675.372 473.821 670.207 473.821C665.042 473.821 660.855 478.008 660.855 483.172C660.855 488.337 665.042 492.524 670.207 492.524Z"
          fill="#FFD02B"
        />
        <path
          d="M732.552 492.524C737.717 492.524 741.903 488.337 741.903 483.172C741.903 478.008 737.717 473.821 732.552 473.821C727.387 473.821 723.2 478.008 723.2 483.172C723.2 488.337 727.387 492.524 732.552 492.524Z"
          fill="#FFD02B"
        />
        <path
          d="M794.897 492.524C800.061 492.524 804.248 488.337 804.248 483.172C804.248 478.008 800.061 473.821 794.897 473.821C789.732 473.821 785.545 478.008 785.545 483.172C785.545 488.337 789.732 492.524 794.897 492.524Z"
          fill="#FFD02B"
        />
        <path
          d="M857.241 492.524C862.406 492.524 866.593 488.337 866.593 483.172C866.593 478.008 862.406 473.821 857.241 473.821C852.077 473.821 847.89 478.008 847.89 483.172C847.89 488.337 852.077 492.524 857.241 492.524Z"
          fill="#FFD02B"
        />
        <path
          d="M888.414 492.524C893.579 492.524 897.766 488.337 897.766 483.172C897.766 478.008 893.579 473.821 888.414 473.821C883.249 473.821 879.062 478.008 879.062 483.172C879.062 488.337 883.249 492.524 888.414 492.524Z"
          fill="#FFD02B"
        />
        <path
          d="M171.448 523.697C176.613 523.697 180.8 519.51 180.8 514.345C180.8 509.18 176.613 504.993 171.448 504.993C166.283 504.993 162.097 509.18 162.097 514.345C162.097 519.51 166.283 523.697 171.448 523.697Z"
          fill="#FFD02B"
        />
        <path
          d="M202.621 523.697C207.786 523.697 211.972 519.51 211.972 514.345C211.972 509.18 207.786 504.993 202.621 504.993C197.456 504.993 193.269 509.18 193.269 514.345C193.269 519.51 197.456 523.697 202.621 523.697Z"
          fill="#FFD02B"
        />
        <path
          d="M264.966 523.697C270.13 523.697 274.317 519.51 274.317 514.345C274.317 509.18 270.13 504.993 264.966 504.993C259.801 504.993 255.614 509.18 255.614 514.345C255.614 519.51 259.801 523.697 264.966 523.697Z"
          fill="#FFD02B"
        />
        <path
          d="M317.959 514.345C317.959 519.511 322.145 523.697 327.31 523.697C328.611 523.697 329.849 523.429 330.971 522.948C327.827 517.605 325.03 512.038 322.608 506.267C319.829 507.888 317.959 510.889 317.959 514.345Z"
          fill="#FFD02B"
        />
        <path
          d="M607.862 523.697C613.027 523.697 617.214 519.51 617.214 514.345C617.214 509.18 613.027 504.993 607.862 504.993C602.697 504.993 598.51 509.18 598.51 514.345C598.51 519.51 602.697 523.697 607.862 523.697Z"
          fill="#FFD02B"
        />
        <path
          d="M639.035 523.697C644.199 523.697 648.386 519.51 648.386 514.345C648.386 509.18 644.199 504.993 639.035 504.993C633.87 504.993 629.683 509.18 629.683 514.345C629.683 519.51 633.87 523.697 639.035 523.697Z"
          fill="#FFD02B"
        />
        <path
          d="M670.207 523.697C675.372 523.697 679.559 519.51 679.559 514.345C679.559 509.18 675.372 504.993 670.207 504.993C665.042 504.993 660.855 509.18 660.855 514.345C660.855 519.51 665.042 523.697 670.207 523.697Z"
          fill="#FFD02B"
        />
        <path
          d="M857.241 523.697C862.406 523.697 866.593 519.51 866.593 514.345C866.593 509.18 862.406 504.993 857.241 504.993C852.077 504.993 847.89 509.18 847.89 514.345C847.89 519.51 852.077 523.697 857.241 523.697Z"
          fill="#FFD02B"
        />
        <path
          d="M15.5862 554.869C20.751 554.869 24.9379 550.682 24.9379 545.517C24.9379 540.352 20.751 536.166 15.5862 536.166C10.4214 536.166 6.2345 540.352 6.2345 545.517C6.2345 550.682 10.4214 554.869 15.5862 554.869Z"
          fill="#FFD02B"
        />
        <path
          d="M109.103 554.869C114.268 554.869 118.455 550.682 118.455 545.517C118.455 540.352 114.268 536.166 109.103 536.166C103.939 536.166 99.7517 540.352 99.7517 545.517C99.7517 550.682 103.939 554.869 109.103 554.869Z"
          fill="#FFD02B"
        />
        <path
          d="M233.793 554.869C238.958 554.869 243.145 550.682 243.145 545.517C243.145 540.352 238.958 536.166 233.793 536.166C228.628 536.166 224.441 540.352 224.441 545.517C224.441 550.682 228.628 554.869 233.793 554.869Z"
          fill="#FFD02B"
        />
        <path
          d="M264.966 554.869C270.13 554.869 274.317 550.682 274.317 545.517C274.317 540.352 270.13 536.166 264.966 536.166C259.801 536.166 255.614 540.352 255.614 545.517C255.614 550.682 259.801 554.869 264.966 554.869Z"
          fill="#FFD02B"
        />
        <path
          d="M296.138 554.869C301.303 554.869 305.49 550.682 305.49 545.517C305.49 540.352 301.303 536.166 296.138 536.166C290.973 536.166 286.786 540.352 286.786 545.517C286.786 550.682 290.973 554.869 296.138 554.869Z"
          fill="#FFD02B"
        />
        <path
          d="M327.31 554.869C332.475 554.869 336.662 550.682 336.662 545.517C336.662 540.352 332.475 536.166 327.31 536.166C322.146 536.166 317.959 540.352 317.959 545.517C317.959 550.682 322.146 554.869 327.31 554.869Z"
          fill="#FFD02B"
        />
        <path
          d="M349.38 547.619C350.182 551.093 352.907 553.818 356.381 554.62C353.967 552.366 351.634 550.033 349.38 547.619Z"
          fill="#FFD02B"
        />
        <path
          d="M607.862 554.869C613.027 554.869 617.214 550.682 617.214 545.517C617.214 540.352 613.027 536.166 607.862 536.166C602.697 536.166 598.51 540.352 598.51 545.517C598.51 550.682 602.697 554.869 607.862 554.869Z"
          fill="#FFD02B"
        />
        <path
          d="M639.035 554.869C644.199 554.869 648.386 550.682 648.386 545.517C648.386 540.352 644.199 536.166 639.035 536.166C633.87 536.166 629.683 540.352 629.683 545.517C629.683 550.682 633.87 554.869 639.035 554.869Z"
          fill="#FFD02B"
        />
        <path
          d="M701.379 554.869C706.544 554.869 710.731 550.682 710.731 545.517C710.731 540.352 706.544 536.166 701.379 536.166C696.215 536.166 692.028 540.352 692.028 545.517C692.028 550.682 696.215 554.869 701.379 554.869Z"
          fill="#FFD02B"
        />
        <path
          d="M763.724 554.869C768.889 554.869 773.076 550.682 773.076 545.517C773.076 540.352 768.889 536.166 763.724 536.166C758.559 536.166 754.372 540.352 754.372 545.517C754.372 550.682 758.559 554.869 763.724 554.869Z"
          fill="#FFD02B"
        />
        <path
          d="M888.414 554.869C893.579 554.869 897.766 550.682 897.766 545.517C897.766 540.352 893.579 536.166 888.414 536.166C883.249 536.166 879.062 540.352 879.062 545.517C879.062 550.682 883.249 554.869 888.414 554.869Z"
          fill="#FFD02B"
        />
        <path
          d="M109.103 586.041C114.268 586.041 118.455 581.855 118.455 576.69C118.455 571.525 114.268 567.338 109.103 567.338C103.939 567.338 99.7517 571.525 99.7517 576.69C99.7517 581.855 103.939 586.041 109.103 586.041Z"
          fill="#FFD02B"
        />
        <path
          d="M202.621 586.041C207.786 586.041 211.972 581.855 211.972 576.69C211.972 571.525 207.786 567.338 202.621 567.338C197.456 567.338 193.269 571.525 193.269 576.69C193.269 581.855 197.456 586.041 202.621 586.041Z"
          fill="#FFD02B"
        />
        <path
          d="M327.31 586.041C332.475 586.041 336.662 581.855 336.662 576.69C336.662 571.525 332.475 567.338 327.31 567.338C322.146 567.338 317.959 571.525 317.959 576.69C317.959 581.855 322.146 586.041 327.31 586.041Z"
          fill="#FFD02B"
        />
        <path
          d="M380.303 576.69C380.303 581.855 384.489 586.041 389.655 586.041C393.102 586.041 396.112 584.171 397.733 581.392C391.962 578.97 386.395 576.173 381.052 573.029C380.571 574.151 380.303 575.389 380.303 576.69Z"
          fill="#FFD02B"
        />
        <path
          d="M545.517 586.041C550.682 586.041 554.869 581.855 554.869 576.69C554.869 571.525 550.682 567.338 545.517 567.338C540.352 567.338 536.166 571.525 536.166 576.69C536.166 581.855 540.352 586.041 545.517 586.041Z"
          fill="#FFD02B"
        />
        <path
          d="M763.724 586.041C768.889 586.041 773.076 581.855 773.076 576.69C773.076 571.525 768.889 567.338 763.724 567.338C758.559 567.338 754.372 571.525 754.372 576.69C754.372 581.855 758.559 586.041 763.724 586.041Z"
          fill="#FFD02B"
        />
        <path
          d="M888.414 586.041C893.579 586.041 897.766 581.855 897.766 576.69C897.766 571.525 893.579 567.338 888.414 567.338C883.249 567.338 879.062 571.525 879.062 576.69C879.062 581.855 883.249 586.041 888.414 586.041Z"
          fill="#FFD02B"
        />
        <path
          d="M109.103 617.214C114.268 617.214 118.455 613.027 118.455 607.862C118.455 602.697 114.268 598.51 109.103 598.51C103.939 598.51 99.7517 602.697 99.7517 607.862C99.7517 613.027 103.939 617.214 109.103 617.214Z"
          fill="#FFD02B"
        />
        <path
          d="M140.276 617.214C145.441 617.214 149.628 613.027 149.628 607.862C149.628 602.697 145.441 598.51 140.276 598.51C135.111 598.51 130.924 602.697 130.924 607.862C130.924 613.027 135.111 617.214 140.276 617.214Z"
          fill="#FFD02B"
        />
        <path
          d="M327.31 617.214C332.475 617.214 336.662 613.027 336.662 607.862C336.662 602.697 332.475 598.51 327.31 598.51C322.146 598.51 317.959 602.697 317.959 607.862C317.959 613.027 322.146 617.214 327.31 617.214Z"
          fill="#FFD02B"
        />
        <path
          d="M389.655 617.214C394.82 617.214 399.007 613.027 399.007 607.862C399.007 602.697 394.82 598.51 389.655 598.51C384.49 598.51 380.303 602.697 380.303 607.862C380.303 613.027 384.49 617.214 389.655 617.214Z"
          fill="#FFD02B"
        />
        <path
          d="M420.828 617.214C425.992 617.214 430.179 613.027 430.179 607.862C430.179 602.697 425.992 598.51 420.828 598.51C415.663 598.51 411.476 602.697 411.476 607.862C411.476 613.027 415.663 617.214 420.828 617.214Z"
          fill="#FFD02B"
        />
        <path
          d="M639.035 617.214C644.199 617.214 648.386 613.027 648.386 607.862C648.386 602.697 644.199 598.51 639.035 598.51C633.87 598.51 629.683 602.697 629.683 607.862C629.683 613.027 633.87 617.214 639.035 617.214Z"
          fill="#FFD02B"
        />
        <path
          d="M794.897 617.214C800.061 617.214 804.248 613.027 804.248 607.862C804.248 602.697 800.061 598.51 794.897 598.51C789.732 598.51 785.545 602.697 785.545 607.862C785.545 613.027 789.732 617.214 794.897 617.214Z"
          fill="#FFD02B"
        />
        <path
          d="M888.414 617.214C893.579 617.214 897.766 613.027 897.766 607.862C897.766 602.697 893.579 598.51 888.414 598.51C883.249 598.51 879.062 602.697 879.062 607.862C879.062 613.027 883.249 617.214 888.414 617.214Z"
          fill="#FFD02B"
        />
        <path
          d="M15.5862 648.386C20.751 648.386 24.9379 644.199 24.9379 639.035C24.9379 633.87 20.751 629.683 15.5862 629.683C10.4214 629.683 6.2345 633.87 6.2345 639.035C6.2345 644.199 10.4214 648.386 15.5862 648.386Z"
          fill="#FFD02B"
        />
        <path
          d="M46.7586 648.386C51.9235 648.386 56.1104 644.199 56.1104 639.035C56.1104 633.87 51.9235 629.683 46.7586 629.683C41.5938 629.683 37.4069 633.87 37.4069 639.035C37.4069 644.199 41.5938 648.386 46.7586 648.386Z"
          fill="#FFD02B"
        />
        <path
          d="M77.931 648.386C83.0959 648.386 87.2828 644.199 87.2828 639.035C87.2828 633.87 83.0959 629.683 77.931 629.683C72.7662 629.683 68.5793 633.87 68.5793 639.035C68.5793 644.199 72.7662 648.386 77.931 648.386Z"
          fill="#FFD02B"
        />
        <path
          d="M140.276 648.386C145.441 648.386 149.628 644.199 149.628 639.035C149.628 633.87 145.441 629.683 140.276 629.683C135.111 629.683 130.924 633.87 130.924 639.035C130.924 644.199 135.111 648.386 140.276 648.386Z"
          fill="#FFD02B"
        />
        <path
          d="M171.448 648.386C176.613 648.386 180.8 644.199 180.8 639.035C180.8 633.87 176.613 629.683 171.448 629.683C166.283 629.683 162.097 633.87 162.097 639.035C162.097 644.199 166.283 648.386 171.448 648.386Z"
          fill="#FFD02B"
        />
        <path
          d="M202.621 648.386C207.786 648.386 211.972 644.199 211.972 639.035C211.972 633.87 207.786 629.683 202.621 629.683C197.456 629.683 193.269 633.87 193.269 639.035C193.269 644.199 197.456 648.386 202.621 648.386Z"
          fill="#FFD02B"
        />
        <path
          d="M264.966 648.386C270.13 648.386 274.317 644.199 274.317 639.035C274.317 633.87 270.13 629.683 264.966 629.683C259.801 629.683 255.614 633.87 255.614 639.035C255.614 644.199 259.801 648.386 264.966 648.386Z"
          fill="#FFD02B"
        />
        <path
          d="M296.138 648.386C301.303 648.386 305.49 644.199 305.49 639.035C305.49 633.87 301.303 629.683 296.138 629.683C290.973 629.683 286.786 633.87 286.786 639.035C286.786 644.199 290.973 648.386 296.138 648.386Z"
          fill="#FFD02B"
        />
        <path
          d="M420.828 648.386C425.992 648.386 430.179 644.199 430.179 639.035C430.179 633.87 425.992 629.683 420.828 629.683C415.663 629.683 411.476 633.87 411.476 639.035C411.476 644.199 415.663 648.386 420.828 648.386Z"
          fill="#FFD02B"
        />
        <path
          d="M452 648.386C457.165 648.386 461.352 644.199 461.352 639.035C461.352 633.87 457.165 629.683 452 629.683C446.835 629.683 442.648 633.87 442.648 639.035C442.648 644.199 446.835 648.386 452 648.386Z"
          fill="#FFD02B"
        />
        <path
          d="M483.172 648.386C488.337 648.386 492.524 644.199 492.524 639.035C492.524 633.87 488.337 629.683 483.172 629.683C478.008 629.683 473.821 633.87 473.821 639.035C473.821 644.199 478.008 648.386 483.172 648.386Z"
          fill="#FFD02B"
        />
        <path
          d="M545.517 648.386C550.682 648.386 554.869 644.199 554.869 639.035C554.869 633.87 550.682 629.683 545.517 629.683C540.352 629.683 536.166 633.87 536.166 639.035C536.166 644.199 540.352 648.386 545.517 648.386Z"
          fill="#FFD02B"
        />
        <path
          d="M607.862 648.386C613.027 648.386 617.214 644.199 617.214 639.035C617.214 633.87 613.027 629.683 607.862 629.683C602.697 629.683 598.51 633.87 598.51 639.035C598.51 644.199 602.697 648.386 607.862 648.386Z"
          fill="#FFD02B"
        />
        <path
          d="M639.035 648.386C644.199 648.386 648.386 644.199 648.386 639.035C648.386 633.87 644.199 629.683 639.035 629.683C633.87 629.683 629.683 633.87 629.683 639.035C629.683 644.199 633.87 648.386 639.035 648.386Z"
          fill="#FFD02B"
        />
        <path
          d="M670.207 648.386C675.372 648.386 679.559 644.199 679.559 639.035C679.559 633.87 675.372 629.683 670.207 629.683C665.042 629.683 660.855 633.87 660.855 639.035C660.855 644.199 665.042 648.386 670.207 648.386Z"
          fill="#FFD02B"
        />
        <path
          d="M701.379 648.386C706.544 648.386 710.731 644.199 710.731 639.035C710.731 633.87 706.544 629.683 701.379 629.683C696.215 629.683 692.028 633.87 692.028 639.035C692.028 644.199 696.215 648.386 701.379 648.386Z"
          fill="#FFD02B"
        />
        <path
          d="M732.552 648.386C737.717 648.386 741.903 644.199 741.903 639.035C741.903 633.87 737.717 629.683 732.552 629.683C727.387 629.683 723.2 633.87 723.2 639.035C723.2 644.199 727.387 648.386 732.552 648.386Z"
          fill="#FFD02B"
        />
        <path
          d="M763.724 648.386C768.889 648.386 773.076 644.199 773.076 639.035C773.076 633.87 768.889 629.683 763.724 629.683C758.559 629.683 754.372 633.87 754.372 639.035C754.372 644.199 758.559 648.386 763.724 648.386Z"
          fill="#FFD02B"
        />
        <path
          d="M794.897 648.386C800.061 648.386 804.248 644.199 804.248 639.035C804.248 633.87 800.061 629.683 794.897 629.683C789.732 629.683 785.545 633.87 785.545 639.035C785.545 644.199 789.732 648.386 794.897 648.386Z"
          fill="#FFD02B"
        />
        <path
          d="M857.241 648.386C862.406 648.386 866.593 644.199 866.593 639.035C866.593 633.87 862.406 629.683 857.241 629.683C852.077 629.683 847.89 633.87 847.89 639.035C847.89 644.199 852.077 648.386 857.241 648.386Z"
          fill="#FFD02B"
        />
        <path
          d="M264.966 679.559C270.13 679.559 274.317 675.372 274.317 670.207C274.317 665.042 270.13 660.855 264.966 660.855C259.801 660.855 255.614 665.042 255.614 670.207C255.614 675.372 259.801 679.559 264.966 679.559Z"
          fill="#FFD02B"
        />
        <path
          d="M389.655 679.559C394.82 679.559 399.007 675.372 399.007 670.207C399.007 665.042 394.82 660.855 389.655 660.855C384.49 660.855 380.303 665.042 380.303 670.207C380.303 675.372 384.49 679.559 389.655 679.559Z"
          fill="#FFD02B"
        />
        <path
          d="M514.345 679.559C519.51 679.559 523.697 675.372 523.697 670.207C523.697 665.042 519.51 660.855 514.345 660.855C509.18 660.855 504.993 665.042 504.993 670.207C504.993 675.372 509.18 679.559 514.345 679.559Z"
          fill="#FFD02B"
        />
        <path
          d="M545.517 679.559C550.682 679.559 554.869 675.372 554.869 670.207C554.869 665.042 550.682 660.855 545.517 660.855C540.352 660.855 536.166 665.042 536.166 670.207C536.166 675.372 540.352 679.559 545.517 679.559Z"
          fill="#FFD02B"
        />
        <path
          d="M576.69 679.559C581.855 679.559 586.041 675.372 586.041 670.207C586.041 665.042 581.855 660.855 576.69 660.855C571.525 660.855 567.338 665.042 567.338 670.207C567.338 675.372 571.525 679.559 576.69 679.559Z"
          fill="#FFD02B"
        />
        <path
          d="M607.862 679.559C613.027 679.559 617.214 675.372 617.214 670.207C617.214 665.042 613.027 660.855 607.862 660.855C602.697 660.855 598.51 665.042 598.51 670.207C598.51 675.372 602.697 679.559 607.862 679.559Z"
          fill="#FFD02B"
        />
        <path
          d="M639.035 679.559C644.199 679.559 648.386 675.372 648.386 670.207C648.386 665.042 644.199 660.855 639.035 660.855C633.87 660.855 629.683 665.042 629.683 670.207C629.683 675.372 633.87 679.559 639.035 679.559Z"
          fill="#FFD02B"
        />
        <path
          d="M763.724 679.559C768.889 679.559 773.076 675.372 773.076 670.207C773.076 665.042 768.889 660.855 763.724 660.855C758.559 660.855 754.372 665.042 754.372 670.207C754.372 675.372 758.559 679.559 763.724 679.559Z"
          fill="#FFD02B"
        />
        <path
          d="M826.069 679.559C831.234 679.559 835.421 675.372 835.421 670.207C835.421 665.042 831.234 660.855 826.069 660.855C820.904 660.855 816.717 665.042 816.717 670.207C816.717 675.372 820.904 679.559 826.069 679.559Z"
          fill="#FFD02B"
        />
        <path
          d="M888.414 679.559C893.579 679.559 897.766 675.372 897.766 670.207C897.766 665.042 893.579 660.855 888.414 660.855C883.249 660.855 879.062 665.042 879.062 670.207C879.062 675.372 883.249 679.559 888.414 679.559Z"
          fill="#FFD02B"
        />
        <path
          d="M264.966 710.731C270.13 710.731 274.317 706.544 274.317 701.379C274.317 696.215 270.13 692.028 264.966 692.028C259.801 692.028 255.614 696.215 255.614 701.379C255.614 706.544 259.801 710.731 264.966 710.731Z"
          fill="#FFD02B"
        />
        <path
          d="M296.138 710.731C301.303 710.731 305.49 706.544 305.49 701.379C305.49 696.215 301.303 692.028 296.138 692.028C290.973 692.028 286.786 696.215 286.786 701.379C286.786 706.544 290.973 710.731 296.138 710.731Z"
          fill="#FFD02B"
        />
        <path
          d="M327.31 710.731C332.475 710.731 336.662 706.544 336.662 701.379C336.662 696.215 332.475 692.028 327.31 692.028C322.146 692.028 317.959 696.215 317.959 701.379C317.959 706.544 322.146 710.731 327.31 710.731Z"
          fill="#FFD02B"
        />
        <path
          d="M420.828 710.731C425.992 710.731 430.179 706.544 430.179 701.379C430.179 696.215 425.992 692.028 420.828 692.028C415.663 692.028 411.476 696.215 411.476 701.379C411.476 706.544 415.663 710.731 420.828 710.731Z"
          fill="#FFD02B"
        />
        <path
          d="M452 710.731C457.165 710.731 461.352 706.544 461.352 701.379C461.352 696.215 457.165 692.028 452 692.028C446.835 692.028 442.648 696.215 442.648 701.379C442.648 706.544 446.835 710.731 452 710.731Z"
          fill="#FFD02B"
        />
        <path
          d="M514.345 710.731C519.51 710.731 523.697 706.544 523.697 701.379C523.697 696.215 519.51 692.028 514.345 692.028C509.18 692.028 504.993 696.215 504.993 701.379C504.993 706.544 509.18 710.731 514.345 710.731Z"
          fill="#FFD02B"
        />
        <path
          d="M545.517 710.731C550.682 710.731 554.869 706.544 554.869 701.379C554.869 696.215 550.682 692.028 545.517 692.028C540.352 692.028 536.166 696.215 536.166 701.379C536.166 706.544 540.352 710.731 545.517 710.731Z"
          fill="#FFD02B"
        />
        <path
          d="M576.69 710.731C581.855 710.731 586.041 706.544 586.041 701.379C586.041 696.215 581.855 692.028 576.69 692.028C571.525 692.028 567.338 696.215 567.338 701.379C567.338 706.544 571.525 710.731 576.69 710.731Z"
          fill="#FFD02B"
        />
        <path
          d="M607.862 710.731C613.027 710.731 617.214 706.544 617.214 701.379C617.214 696.215 613.027 692.028 607.862 692.028C602.697 692.028 598.51 696.215 598.51 701.379C598.51 706.544 602.697 710.731 607.862 710.731Z"
          fill="#FFD02B"
        />
        <path
          d="M639.035 710.731C644.199 710.731 648.386 706.544 648.386 701.379C648.386 696.215 644.199 692.028 639.035 692.028C633.87 692.028 629.683 696.215 629.683 701.379C629.683 706.544 633.87 710.731 639.035 710.731Z"
          fill="#FFD02B"
        />
        <path
          d="M701.379 710.731C706.544 710.731 710.731 706.544 710.731 701.379C710.731 696.215 706.544 692.028 701.379 692.028C696.215 692.028 692.028 696.215 692.028 701.379C692.028 706.544 696.215 710.731 701.379 710.731Z"
          fill="#FFD02B"
        />
        <path
          d="M763.724 710.731C768.889 710.731 773.076 706.544 773.076 701.379C773.076 696.215 768.889 692.028 763.724 692.028C758.559 692.028 754.372 696.215 754.372 701.379C754.372 706.544 758.559 710.731 763.724 710.731Z"
          fill="#FFD02B"
        />
        <path
          d="M888.414 710.731C893.579 710.731 897.766 706.544 897.766 701.379C897.766 696.215 893.579 692.028 888.414 692.028C883.249 692.028 879.062 696.215 879.062 701.379C879.062 706.544 883.249 710.731 888.414 710.731Z"
          fill="#FFD02B"
        />
        <path
          d="M264.966 741.903C270.13 741.903 274.317 737.717 274.317 732.552C274.317 727.387 270.13 723.2 264.966 723.2C259.801 723.2 255.614 727.387 255.614 732.552C255.614 737.717 259.801 741.903 264.966 741.903Z"
          fill="#FFD02B"
        />
        <path
          d="M296.138 741.903C301.303 741.903 305.49 737.717 305.49 732.552C305.49 727.387 301.303 723.2 296.138 723.2C290.973 723.2 286.786 727.387 286.786 732.552C286.786 737.717 290.973 741.903 296.138 741.903Z"
          fill="#FFD02B"
        />
        <path
          d="M327.31 741.903C332.475 741.903 336.662 737.717 336.662 732.552C336.662 727.387 332.475 723.2 327.31 723.2C322.146 723.2 317.959 727.387 317.959 732.552C317.959 737.717 322.146 741.903 327.31 741.903Z"
          fill="#FFD02B"
        />
        <path
          d="M358.483 741.903C363.648 741.903 367.835 737.717 367.835 732.552C367.835 727.387 363.648 723.2 358.483 723.2C353.318 723.2 349.131 727.387 349.131 732.552C349.131 737.717 353.318 741.903 358.483 741.903Z"
          fill="#FFD02B"
        />
        <path
          d="M420.828 741.903C425.992 741.903 430.179 737.717 430.179 732.552C430.179 727.387 425.992 723.2 420.828 723.2C415.663 723.2 411.476 727.387 411.476 732.552C411.476 737.717 415.663 741.903 420.828 741.903Z"
          fill="#FFD02B"
        />
        <path
          d="M452 741.903C457.165 741.903 461.352 737.717 461.352 732.552C461.352 727.387 457.165 723.2 452 723.2C446.835 723.2 442.648 727.387 442.648 732.552C442.648 737.717 446.835 741.903 452 741.903Z"
          fill="#FFD02B"
        />
        <path
          d="M483.172 741.903C488.337 741.903 492.524 737.717 492.524 732.552C492.524 727.387 488.337 723.2 483.172 723.2C478.008 723.2 473.821 727.387 473.821 732.552C473.821 737.717 478.008 741.903 483.172 741.903Z"
          fill="#FFD02B"
        />
        <path
          d="M514.345 741.903C519.51 741.903 523.697 737.717 523.697 732.552C523.697 727.387 519.51 723.2 514.345 723.2C509.18 723.2 504.993 727.387 504.993 732.552C504.993 737.717 509.18 741.903 514.345 741.903Z"
          fill="#FFD02B"
        />
        <path
          d="M607.862 741.903C613.027 741.903 617.214 737.717 617.214 732.552C617.214 727.387 613.027 723.2 607.862 723.2C602.697 723.2 598.51 727.387 598.51 732.552C598.51 737.717 602.697 741.903 607.862 741.903Z"
          fill="#FFD02B"
        />
        <path
          d="M639.035 741.903C644.199 741.903 648.386 737.717 648.386 732.552C648.386 727.387 644.199 723.2 639.035 723.2C633.87 723.2 629.683 727.387 629.683 732.552C629.683 737.717 633.87 741.903 639.035 741.903Z"
          fill="#FFD02B"
        />
        <path
          d="M763.724 741.903C768.889 741.903 773.076 737.717 773.076 732.552C773.076 727.387 768.889 723.2 763.724 723.2C758.559 723.2 754.372 727.387 754.372 732.552C754.372 737.717 758.559 741.903 763.724 741.903Z"
          fill="#FFD02B"
        />
        <path
          d="M794.897 741.903C800.061 741.903 804.248 737.717 804.248 732.552C804.248 727.387 800.061 723.2 794.897 723.2C789.732 723.2 785.545 727.387 785.545 732.552C785.545 737.717 789.732 741.903 794.897 741.903Z"
          fill="#FFD02B"
        />
        <path
          d="M857.241 741.903C862.406 741.903 866.593 737.717 866.593 732.552C866.593 727.387 862.406 723.2 857.241 723.2C852.077 723.2 847.89 727.387 847.89 732.552C847.89 737.717 852.077 741.903 857.241 741.903Z"
          fill="#FFD02B"
        />
        <path
          d="M264.966 773.076C270.13 773.076 274.317 768.889 274.317 763.724C274.317 758.559 270.13 754.372 264.966 754.372C259.801 754.372 255.614 758.559 255.614 763.724C255.614 768.889 259.801 773.076 264.966 773.076Z"
          fill="#FFD02B"
        />
        <path
          d="M327.31 773.076C332.475 773.076 336.662 768.889 336.662 763.724C336.662 758.559 332.475 754.372 327.31 754.372C322.146 754.372 317.959 758.559 317.959 763.724C317.959 768.889 322.146 773.076 327.31 773.076Z"
          fill="#FFD02B"
        />
        <path
          d="M358.483 773.076C363.648 773.076 367.835 768.889 367.835 763.724C367.835 758.559 363.648 754.372 358.483 754.372C353.318 754.372 349.131 758.559 349.131 763.724C349.131 768.889 353.318 773.076 358.483 773.076Z"
          fill="#FFD02B"
        />
        <path
          d="M389.655 773.076C394.82 773.076 399.007 768.889 399.007 763.724C399.007 758.559 394.82 754.372 389.655 754.372C384.49 754.372 380.303 758.559 380.303 763.724C380.303 768.889 384.49 773.076 389.655 773.076Z"
          fill="#FFD02B"
        />
        <path
          d="M452 773.076C457.165 773.076 461.352 768.889 461.352 763.724C461.352 758.559 457.165 754.372 452 754.372C446.835 754.372 442.648 758.559 442.648 763.724C442.648 768.889 446.835 773.076 452 773.076Z"
          fill="#FFD02B"
        />
        <path
          d="M514.345 773.076C519.51 773.076 523.697 768.889 523.697 763.724C523.697 758.559 519.51 754.372 514.345 754.372C509.18 754.372 504.993 758.559 504.993 763.724C504.993 768.889 509.18 773.076 514.345 773.076Z"
          fill="#FFD02B"
        />
        <path
          d="M576.69 773.076C581.855 773.076 586.041 768.889 586.041 763.724C586.041 758.559 581.855 754.372 576.69 754.372C571.525 754.372 567.338 758.559 567.338 763.724C567.338 768.889 571.525 773.076 576.69 773.076Z"
          fill="#FFD02B"
        />
        <path
          d="M607.862 773.076C613.027 773.076 617.214 768.889 617.214 763.724C617.214 758.559 613.027 754.372 607.862 754.372C602.697 754.372 598.51 758.559 598.51 763.724C598.51 768.889 602.697 773.076 607.862 773.076Z"
          fill="#FFD02B"
        />
        <path
          d="M639.035 773.076C644.199 773.076 648.386 768.889 648.386 763.724C648.386 758.559 644.199 754.372 639.035 754.372C633.87 754.372 629.683 758.559 629.683 763.724C629.683 768.889 633.87 773.076 639.035 773.076Z"
          fill="#FFD02B"
        />
        <path
          d="M670.207 773.076C675.372 773.076 679.559 768.889 679.559 763.724C679.559 758.559 675.372 754.372 670.207 754.372C665.042 754.372 660.855 758.559 660.855 763.724C660.855 768.889 665.042 773.076 670.207 773.076Z"
          fill="#FFD02B"
        />
        <path
          d="M701.379 773.076C706.544 773.076 710.731 768.889 710.731 763.724C710.731 758.559 706.544 754.372 701.379 754.372C696.215 754.372 692.028 758.559 692.028 763.724C692.028 768.889 696.215 773.076 701.379 773.076Z"
          fill="#FFD02B"
        />
        <path
          d="M732.552 773.076C737.717 773.076 741.903 768.889 741.903 763.724C741.903 758.559 737.717 754.372 732.552 754.372C727.387 754.372 723.2 758.559 723.2 763.724C723.2 768.889 727.387 773.076 732.552 773.076Z"
          fill="#FFD02B"
        />
        <path
          d="M763.724 773.076C768.889 773.076 773.076 768.889 773.076 763.724C773.076 758.559 768.889 754.372 763.724 754.372C758.559 754.372 754.372 758.559 754.372 763.724C754.372 768.889 758.559 773.076 763.724 773.076Z"
          fill="#FFD02B"
        />
        <path
          d="M857.241 773.076C862.406 773.076 866.593 768.889 866.593 763.724C866.593 758.559 862.406 754.372 857.241 754.372C852.077 754.372 847.89 758.559 847.89 763.724C847.89 768.889 852.077 773.076 857.241 773.076Z"
          fill="#FFD02B"
        />
        <path
          d="M888.414 773.076C893.579 773.076 897.766 768.889 897.766 763.724C897.766 758.559 893.579 754.372 888.414 754.372C883.249 754.372 879.062 758.559 879.062 763.724C879.062 768.889 883.249 773.076 888.414 773.076Z"
          fill="#FFD02B"
        />
        <path
          d="M296.138 804.248C301.303 804.248 305.49 800.061 305.49 794.897C305.49 789.732 301.303 785.545 296.138 785.545C290.973 785.545 286.786 789.732 286.786 794.897C286.786 800.061 290.973 804.248 296.138 804.248Z"
          fill="#FFD02B"
        />
        <path
          d="M327.31 804.248C332.475 804.248 336.662 800.061 336.662 794.897C336.662 789.732 332.475 785.545 327.31 785.545C322.146 785.545 317.959 789.732 317.959 794.897C317.959 800.061 322.146 804.248 327.31 804.248Z"
          fill="#FFD02B"
        />
        <path
          d="M452 804.248C457.165 804.248 461.352 800.061 461.352 794.897C461.352 789.732 457.165 785.545 452 785.545C446.835 785.545 442.648 789.732 442.648 794.897C442.648 800.061 446.835 804.248 452 804.248Z"
          fill="#FFD02B"
        />
        <path
          d="M483.172 804.248C488.337 804.248 492.524 800.061 492.524 794.897C492.524 789.732 488.337 785.545 483.172 785.545C478.008 785.545 473.821 789.732 473.821 794.897C473.821 800.061 478.008 804.248 483.172 804.248Z"
          fill="#FFD02B"
        />
        <path
          d="M514.345 804.248C519.51 804.248 523.697 800.061 523.697 794.897C523.697 789.732 519.51 785.545 514.345 785.545C509.18 785.545 504.993 789.732 504.993 794.897C504.993 800.061 509.18 804.248 514.345 804.248Z"
          fill="#FFD02B"
        />
        <path
          d="M576.69 804.248C581.855 804.248 586.041 800.061 586.041 794.897C586.041 789.732 581.855 785.545 576.69 785.545C571.525 785.545 567.338 789.732 567.338 794.897C567.338 800.061 571.525 804.248 576.69 804.248Z"
          fill="#FFD02B"
        />
        <path
          d="M732.552 804.248C737.717 804.248 741.903 800.061 741.903 794.897C741.903 789.732 737.717 785.545 732.552 785.545C727.387 785.545 723.2 789.732 723.2 794.897C723.2 800.061 727.387 804.248 732.552 804.248Z"
          fill="#FFD02B"
        />
        <path
          d="M857.241 804.248C862.406 804.248 866.593 800.061 866.593 794.897C866.593 789.732 862.406 785.545 857.241 785.545C852.077 785.545 847.89 789.732 847.89 794.897C847.89 800.061 852.077 804.248 857.241 804.248Z"
          fill="#FFD02B"
        />
        <path
          d="M327.31 835.421C332.475 835.421 336.662 831.234 336.662 826.069C336.662 820.904 332.475 816.717 327.31 816.717C322.146 816.717 317.959 820.904 317.959 826.069C317.959 831.234 322.146 835.421 327.31 835.421Z"
          fill="#FFD02B"
        />
        <path
          d="M358.483 835.421C363.648 835.421 367.835 831.234 367.835 826.069C367.835 820.904 363.648 816.717 358.483 816.717C353.318 816.717 349.131 820.904 349.131 826.069C349.131 831.234 353.318 835.421 358.483 835.421Z"
          fill="#FFD02B"
        />
        <path
          d="M420.828 835.421C425.992 835.421 430.179 831.234 430.179 826.069C430.179 820.904 425.992 816.717 420.828 816.717C415.663 816.717 411.476 820.904 411.476 826.069C411.476 831.234 415.663 835.421 420.828 835.421Z"
          fill="#FFD02B"
        />
        <path
          d="M452 835.421C457.165 835.421 461.352 831.234 461.352 826.069C461.352 820.904 457.165 816.717 452 816.717C446.835 816.717 442.648 820.904 442.648 826.069C442.648 831.234 446.835 835.421 452 835.421Z"
          fill="#FFD02B"
        />
        <path
          d="M483.172 835.421C488.337 835.421 492.524 831.234 492.524 826.069C492.524 820.904 488.337 816.717 483.172 816.717C478.008 816.717 473.821 820.904 473.821 826.069C473.821 831.234 478.008 835.421 483.172 835.421Z"
          fill="#FFD02B"
        />
        <path
          d="M576.69 835.421C581.855 835.421 586.041 831.234 586.041 826.069C586.041 820.904 581.855 816.717 576.69 816.717C571.525 816.717 567.338 820.904 567.338 826.069C567.338 831.234 571.525 835.421 576.69 835.421Z"
          fill="#FFD02B"
        />
        <path
          d="M607.862 835.421C613.027 835.421 617.214 831.234 617.214 826.069C617.214 820.904 613.027 816.717 607.862 816.717C602.697 816.717 598.51 820.904 598.51 826.069C598.51 831.234 602.697 835.421 607.862 835.421Z"
          fill="#FFD02B"
        />
        <path
          d="M639.035 835.421C644.199 835.421 648.386 831.234 648.386 826.069C648.386 820.904 644.199 816.717 639.035 816.717C633.87 816.717 629.683 820.904 629.683 826.069C629.683 831.234 633.87 835.421 639.035 835.421Z"
          fill="#FFD02B"
        />
        <path
          d="M670.207 835.421C675.372 835.421 679.559 831.234 679.559 826.069C679.559 820.904 675.372 816.717 670.207 816.717C665.042 816.717 660.855 820.904 660.855 826.069C660.855 831.234 665.042 835.421 670.207 835.421Z"
          fill="#FFD02B"
        />
        <path
          d="M794.897 835.421C800.061 835.421 804.248 831.234 804.248 826.069C804.248 820.904 800.061 816.717 794.897 816.717C789.732 816.717 785.545 820.904 785.545 826.069C785.545 831.234 789.732 835.421 794.897 835.421Z"
          fill="#FFD02B"
        />
        <path
          d="M826.069 835.421C831.234 835.421 835.421 831.234 835.421 826.069C835.421 820.904 831.234 816.717 826.069 816.717C820.904 816.717 816.717 820.904 816.717 826.069C816.717 831.234 820.904 835.421 826.069 835.421Z"
          fill="#FFD02B"
        />
        <path
          d="M857.241 835.421C862.406 835.421 866.593 831.234 866.593 826.069C866.593 820.904 862.406 816.717 857.241 816.717C852.077 816.717 847.89 820.904 847.89 826.069C847.89 831.234 852.077 835.421 857.241 835.421Z"
          fill="#FFD02B"
        />
        <path
          d="M888.414 835.421C893.579 835.421 897.766 831.234 897.766 826.069C897.766 820.904 893.579 816.717 888.414 816.717C883.249 816.717 879.062 820.904 879.062 826.069C879.062 831.234 883.249 835.421 888.414 835.421Z"
          fill="#FFD02B"
        />
        <path
          d="M296.138 866.593C301.303 866.593 305.49 862.406 305.49 857.241C305.49 852.077 301.303 847.89 296.138 847.89C290.973 847.89 286.786 852.077 286.786 857.241C286.786 862.406 290.973 866.593 296.138 866.593Z"
          fill="#FFD02B"
        />
        <path
          d="M327.31 866.593C332.475 866.593 336.662 862.406 336.662 857.241C336.662 852.077 332.475 847.89 327.31 847.89C322.146 847.89 317.959 852.077 317.959 857.241C317.959 862.406 322.146 866.593 327.31 866.593Z"
          fill="#FFD02B"
        />
        <path
          d="M358.483 866.593C363.648 866.593 367.835 862.406 367.835 857.241C367.835 852.077 363.648 847.89 358.483 847.89C353.318 847.89 349.131 852.077 349.131 857.241C349.131 862.406 353.318 866.593 358.483 866.593Z"
          fill="#FFD02B"
        />
        <path
          d="M389.655 866.593C394.82 866.593 399.007 862.406 399.007 857.241C399.007 852.077 394.82 847.89 389.655 847.89C384.49 847.89 380.303 852.077 380.303 857.241C380.303 862.406 384.49 866.593 389.655 866.593Z"
          fill="#FFD02B"
        />
        <path
          d="M420.828 866.593C425.992 866.593 430.179 862.406 430.179 857.241C430.179 852.077 425.992 847.89 420.828 847.89C415.663 847.89 411.476 852.077 411.476 857.241C411.476 862.406 415.663 866.593 420.828 866.593Z"
          fill="#FFD02B"
        />
        <path
          d="M514.345 866.593C519.51 866.593 523.697 862.406 523.697 857.241C523.697 852.077 519.51 847.89 514.345 847.89C509.18 847.89 504.993 852.077 504.993 857.241C504.993 862.406 509.18 866.593 514.345 866.593Z"
          fill="#FFD02B"
        />
        <path
          d="M576.69 866.593C581.855 866.593 586.041 862.406 586.041 857.241C586.041 852.077 581.855 847.89 576.69 847.89C571.525 847.89 567.338 852.077 567.338 857.241C567.338 862.406 571.525 866.593 576.69 866.593Z"
          fill="#FFD02B"
        />
        <path
          d="M607.862 866.593C613.027 866.593 617.214 862.406 617.214 857.241C617.214 852.077 613.027 847.89 607.862 847.89C602.697 847.89 598.51 852.077 598.51 857.241C598.51 862.406 602.697 866.593 607.862 866.593Z"
          fill="#FFD02B"
        />
        <path
          d="M639.035 866.593C644.199 866.593 648.386 862.406 648.386 857.241C648.386 852.077 644.199 847.89 639.035 847.89C633.87 847.89 629.683 852.077 629.683 857.241C629.683 862.406 633.87 866.593 639.035 866.593Z"
          fill="#FFD02B"
        />
        <path
          d="M701.379 866.593C706.544 866.593 710.731 862.406 710.731 857.241C710.731 852.077 706.544 847.89 701.379 847.89C696.215 847.89 692.028 852.077 692.028 857.241C692.028 862.406 696.215 866.593 701.379 866.593Z"
          fill="#FFD02B"
        />
        <path
          d="M732.552 866.593C737.717 866.593 741.903 862.406 741.903 857.241C741.903 852.077 737.717 847.89 732.552 847.89C727.387 847.89 723.2 852.077 723.2 857.241C723.2 862.406 727.387 866.593 732.552 866.593Z"
          fill="#FFD02B"
        />
        <path
          d="M794.897 866.593C800.061 866.593 804.248 862.406 804.248 857.241C804.248 852.077 800.061 847.89 794.897 847.89C789.732 847.89 785.545 852.077 785.545 857.241C785.545 862.406 789.732 866.593 794.897 866.593Z"
          fill="#FFD02B"
        />
        <path
          d="M857.241 866.593C862.406 866.593 866.593 862.406 866.593 857.241C866.593 852.077 862.406 847.89 857.241 847.89C852.077 847.89 847.89 852.077 847.89 857.241C847.89 862.406 852.077 866.593 857.241 866.593Z"
          fill="#FFD02B"
        />
        <path
          d="M888.414 866.593C893.579 866.593 897.766 862.406 897.766 857.241C897.766 852.077 893.579 847.89 888.414 847.89C883.249 847.89 879.062 852.077 879.062 857.241C879.062 862.406 883.249 866.593 888.414 866.593Z"
          fill="#FFD02B"
        />
        <path
          d="M296.138 897.766C301.303 897.766 305.49 893.579 305.49 888.414C305.49 883.249 301.303 879.062 296.138 879.062C290.973 879.062 286.786 883.249 286.786 888.414C286.786 893.579 290.973 897.766 296.138 897.766Z"
          fill="#FFD02B"
        />
        <path
          d="M327.31 897.766C332.475 897.766 336.662 893.579 336.662 888.414C336.662 883.249 332.475 879.062 327.31 879.062C322.146 879.062 317.959 883.249 317.959 888.414C317.959 893.579 322.146 897.766 327.31 897.766Z"
          fill="#FFD02B"
        />
        <path
          d="M420.828 897.766C425.992 897.766 430.179 893.579 430.179 888.414C430.179 883.249 425.992 879.062 420.828 879.062C415.663 879.062 411.476 883.249 411.476 888.414C411.476 893.579 415.663 897.766 420.828 897.766Z"
          fill="#FFD02B"
        />
        <path
          d="M452 897.766C457.165 897.766 461.352 893.579 461.352 888.414C461.352 883.249 457.165 879.062 452 879.062C446.835 879.062 442.648 883.249 442.648 888.414C442.648 893.579 446.835 897.766 452 897.766Z"
          fill="#FFD02B"
        />
        <path
          d="M514.345 897.766C519.51 897.766 523.697 893.579 523.697 888.414C523.697 883.249 519.51 879.062 514.345 879.062C509.18 879.062 504.993 883.249 504.993 888.414C504.993 893.579 509.18 897.766 514.345 897.766Z"
          fill="#FFD02B"
        />
        <path
          d="M639.035 897.766C644.199 897.766 648.386 893.579 648.386 888.414C648.386 883.249 644.199 879.062 639.035 879.062C633.87 879.062 629.683 883.249 629.683 888.414C629.683 893.579 633.87 897.766 639.035 897.766Z"
          fill="#FFD02B"
        />
        <path
          d="M670.207 897.766C675.372 897.766 679.559 893.579 679.559 888.414C679.559 883.249 675.372 879.062 670.207 879.062C665.042 879.062 660.855 883.249 660.855 888.414C660.855 893.579 665.042 897.766 670.207 897.766Z"
          fill="#FFD02B"
        />
        <path
          d="M763.724 897.766C768.889 897.766 773.076 893.579 773.076 888.414C773.076 883.249 768.889 879.062 763.724 879.062C758.559 879.062 754.372 883.249 754.372 888.414C754.372 893.579 758.559 897.766 763.724 897.766Z"
          fill="#FFD02B"
        />
        <path
          d="M857.241 897.766C862.406 897.766 866.593 893.579 866.593 888.414C866.593 883.249 862.406 879.062 857.241 879.062C852.077 879.062 847.89 883.249 847.89 888.414C847.89 893.579 852.077 897.766 857.241 897.766Z"
          fill="#FFD02B"
        />
        <path
          d="M685.793 0V218.207H904V0H685.793ZM871.269 185.476H718.524V32.731H871.269V185.476Z"
          fill="#FFD02B"
        />
        <path
          d="M841.655 62.3448H748.138V155.862H841.655V62.3448Z"
          fill="#FFD02B"
        />
        <path
          d="M0 0V218.207H218.207V0H0ZM185.476 185.476H32.731V32.731H185.476V185.476Z"
          fill="#FFD02B"
        />
        <path
          d="M155.862 62.3448H62.3448V155.862H155.862V62.3448Z"
          fill="#FFD02B"
        />
        <path
          d="M0 685.793V904H218.207V685.793H0ZM185.476 871.269H32.731V718.524H185.476V871.269Z"
          fill="#FFD02B"
        />
        <path
          d="M155.862 748.138H62.3448V841.655H155.862V748.138Z"
          fill="#FFD02B"
        />
        <path
          d="M335.103 440.724H401.518L440.083 402.124V335.201C384.605 340.866 340.536 385.131 335.103 440.724ZM417.808 390.519L391.356 416.998H365.145C374.817 393.28 393.93 374.434 417.808 365.136V390.519Z"
          fill="#FF7AD1"
        />
        <path
          d="M569.226 440.724C563.757 384.792 519.199 340.34 463.258 335.103V402.115L501.831 440.724H569.226ZM485.524 365.136C509.402 374.443 528.533 393.28 538.196 416.998H511.976L485.524 390.519V365.136Z"
          fill="#FF7AD1"
        />
        <path
          d="M335.103 463.935C340.536 519.537 384.605 563.802 440.083 569.467V502.544L401.518 463.935H335.103ZM417.826 539.523C393.948 530.216 374.826 511.379 365.154 487.67H391.374L417.826 514.149V539.523Z"
          fill="#FF7AD1"
        />
        <path
          d="M463.258 502.535V569.547C519.199 564.31 563.757 519.858 569.226 463.926H501.831L463.258 502.535ZM538.214 487.661C528.542 511.379 509.419 530.225 485.541 539.514V514.14L511.993 487.661H538.214Z"
          fill="#FF7AD1"
        />
      </g>
      <defs>
        <clipPath id="clip0_11636_108656">
          <rect width="904" height="904" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
