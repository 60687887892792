import { BaseIconProps } from "../icon";

export function SwitchCurvedIcon(props: BaseIconProps) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.48943 0.75C3.83016 0.75 2.46828 2.09161 2.46828 3.72619V9.00893L1.5997 8.15327L0.75 8.99963L3.07251 11.2876L5.39502 8.99963L4.54532 8.16257L3.67674 9.00893V3.72619C3.67674 2.74149 4.48985 1.94048 5.48943 1.94048H8.81269V0.75H5.48943ZM10.9275 2.71243L8.59554 5.00967L9.45468 5.84673L10.3233 4.99107V10.2738C10.3233 11.2585 9.51015 12.0595 8.51057 12.0595H5.18731V13.25H8.51057C10.1698 13.25 11.5317 11.9084 11.5317 10.2738V4.99107L12.4003 5.84673L13.25 5.00037L10.9275 2.71243Z"
        fill="currentColor"
      />
    </svg>
  );
}
