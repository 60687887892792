import { useTypedSelector } from "@/store";
import { useBackgroundTransactions } from "./use-background-transactions";
import { isTransactionPending } from "@/app/background-transactions/utils";

export function useBackgroundTransactionStatus() {
  const orderedTransactions = useBackgroundTransactions();

  const hasUnreadTransactions = useTypedSelector(
    (state) => state.backgroundTransactions.hasUnreadTransactions,
  );

  const pendingTransactions = orderedTransactions.filter(isTransactionPending);

  const hasPending = pendingTransactions.length > 0;
  const hasFinished =
    orderedTransactions.filter(isTransactionPending).length > 0;

  return {
    hasPending,
    hasFinished,
    hasUnreadTransactions,
  };
}
