/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  backgroundTransactionApiActions,
  backgroundTransactionsActions,
  backgroundTransactionsName,
} from "@/app/background-transactions";
import { profileActions } from "@/routes/profile/-reducer";
import { rewardsActions } from "@/routes/rewards/-reducer";
import { tradeSwapActions } from "@/routes/trade/-reducer";
import { tradeChartActions } from "@/routes/trade/-token/reducer";
import { withdrawFlowActions } from "@/routes/withdraw/-reducer";
import { portfolioActions } from "@/routes/portfolio/-reducer";
import { REHYDRATE } from "@/store";
import { createListenerMiddleware, isAnyOf } from "@reduxjs/toolkit";
import ric from "ric-shim";
import * as cache from "./cache";
import { tokenCacheActions } from "@/app/token-cache/token-cache-actions";
import ms from "ms";

// Store actions that we want to persist on
const storeActions = Object.values({
  ...withdrawFlowActions,
  ...tradeSwapActions,
  ...tradeChartActions,
  ...profileActions,
  ...rewardsActions,
  ...backgroundTransactionsActions,
  ...portfolioActions,
  ...tokenCacheActions,
});

/**
 * Middleware that listens for actions and persists the data to IndexedDB.
 */
export const persistMiddleware = createListenerMiddleware();

await cache.init();
const version = cache.version;
const getDefaultExpiry = () => Date.now() + ms("12h");

persistMiddleware.startListening({
  matcher: isAnyOf(
    ...storeActions,
    ...Object.values(backgroundTransactionApiActions),
  ),
  effect: (action, store) => {
    // This stands for request idle callback
    // It will only cache data when the browser has
    // nothing else to do so it won't affect performance
    ric(() => {
      if (isAnyOf(...Object.values(backgroundTransactionApiActions))(action)) {
        const state = (store.getState() as any)[backgroundTransactionsName];
        const expiresAt = getDefaultExpiry();
        void cache.set(`${backgroundTransactionsName}/${REHYDRATE}`, {
          version,
          expiresAt,
          data: { ...state, orderContent: null },
        });
      } else if (isAnyOf(...Object.values(tokenCacheActions))(action)) {
        // Token cache is longer lived than other data
        const name = action.type.split("/")[0];
        const expiresAt = Date.now() + ms("14d");
        const state = (store.getState() as any)[name];
        void cache.set(`${name}/${REHYDRATE}`, {
          version,
          expiresAt,
          data: state,
        });
      } else {
        const name = action.type.split("/")[0];
        const state = (store.getState() as any)[name];
        const expiresAt = getDefaultExpiry();
        void cache.set(`${name}/${REHYDRATE}`, {
          version,
          expiresAt,
          data: state,
        });
      }
    });
  },
});
