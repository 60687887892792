import { Icon } from "@/components";
import { useLongPress } from "use-long-press";
import { Key } from "./key";

type Key =
  | "1"
  | "2"
  | "3"
  | "4"
  | "5"
  | "6"
  | "7"
  | "8"
  | "9"
  | "0"
  | "."
  | "backspace";

interface NumericKeyboardProps {
  onKeyClick: (key: Key) => void;
  onBackspaceLongPress: () => void;
}

export function NumericKeyboard({
  onKeyClick,
  onBackspaceLongPress,
}: NumericKeyboardProps) {
  const bind = useLongPress(
    () => {
      onBackspaceLongPress();
    },
    { threshold: 1000 },
  );

  return (
    <div
      className="grid grid-cols-3 bg-gray-700 pb-safe-bottom select-none"
      aria-hidden
    >
      <Key onClick={onKeyClick} name="1" />
      <Key onClick={onKeyClick} name="2" />
      <Key onClick={onKeyClick} name="3" />
      <Key onClick={onKeyClick} name="4" />
      <Key onClick={onKeyClick} name="5" />
      <Key onClick={onKeyClick} name="6" />
      <Key onClick={onKeyClick} name="7" />
      <Key onClick={onKeyClick} name="8" />
      <Key onClick={onKeyClick} name="9" />
      <Key onClick={onKeyClick} name="." />
      <Key onClick={onKeyClick} name="0" />
      <Key
        {...bind()}
        onClick={onKeyClick}
        name="backspace"
        aria-label="backspace"
      >
        <Icon name="backspace" className="mx-auto w-6" />
      </Key>
    </div>
  );
}
