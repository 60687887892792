import {
  useChangeWalletMutation,
  useUpsertUserMutation,
  userApi,
  walletApi,
} from "@/api";
import { useAppDispatch } from "@/store";
import { embeddedWallet } from "@/embedded-wallet";
import {
  DynamicContextProvider,
  useDynamicContext,
} from "@dynamic-labs/sdk-react-core";
import { SolanaWalletConnectors } from "@dynamic-labs/solana";
import { useEffect } from "react";
import { useDynamicWallet } from "@/hooks";

export function DynamicProvider({ children }: React.PropsWithChildren) {
  return (
    <DynamicContextProvider
      theme="dark"
      settings={{
        environmentId: import.meta.env.VITE_DYNAMIC_CLIENT_ID,
        walletConnectors: [SolanaWalletConnectors],
        eventsCallbacks: {
          onAuthFlowOpen: () => {
            console.log("onAuthFlowOpen was called");
          },
          onAuthFlowClose: () => {
            console.log("onAuthFlowClose was called");
          },
          onAuthFlowCancel: () => {
            console.log("onAuthFlowCancel was called");
          },
          onAuthSuccess: () => {
            console.log("onAuthSuccess was called");
            try {
              // pre-fetch user data after successful auth
              // this ensures user settings are up-to-date
              userApi.util.prefetch("user", undefined, { force: true });
            } catch (e) {
              console.error("onAuthError:", e);
            }
          },
        },
      }}
    >
      <UpdateWalletCache>{children}</UpdateWalletCache>
    </DynamicContextProvider>
  );
}

function UpdateWalletCache({ children }: React.PropsWithChildren) {
  const { sdkHasLoaded } = useDynamicContext();
  const primaryWallet = useDynamicWallet();
  const walletAddress = primaryWallet?.address;
  const dispatch = useAppDispatch();
  const [change] = useChangeWalletMutation();
  const [userUpsertMutate] = useUpsertUserMutation();

  useEffect(() => {
    if (!sdkHasLoaded) return;
    if (primaryWallet) {
      embeddedWallet.reset();
      embeddedWallet.init(primaryWallet);
      void userUpsertMutate();
      change().catch((e: unknown) => console.error(e));
    } else {
      embeddedWallet.wallet = null;
      if (!embeddedWallet.initialized) {
        embeddedWallet.init(null);
      }
      // when the wallet is disconnected, clear cached wallet info
      dispatch(
        walletApi.util.updateQueryData("walletInfo", undefined, (data) => {
          data.balance = null;
          data.walletAddress = null;
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletAddress, sdkHasLoaded, userUpsertMutate, change, dispatch]);

  return children;
}
