import { Drawer, Icon } from "@/components";

interface OrderLineItemProps {
  label: string;
  description?: string | React.ReactNode;
  value: React.ReactNode;
}

export function OrderLineItem({
  label,
  value,
  description,
}: OrderLineItemProps) {
  return (
    <div className="w-full flex justify-between items-center">
      <span className="text-sm text-primary font-bold uppercase flex items-center gap-1">
        {label}
        {description ? (
          <Drawer.Root>
            <Drawer.Trigger>
              <Icon
                name="info"
                className="w-4 h-4 text-gray-200"
                srText="More info"
              />
            </Drawer.Trigger>
            <Drawer.Content title={label}>
              {typeof description === "string" ? (
                <p className="text-gray-200 text-sm pb-4">{description}</p>
              ) : (
                description
              )}
            </Drawer.Content>
          </Drawer.Root>
        ) : null}
      </span>
      <span className="text-sm text-white font-bold max-w-[200px]">
        {value}
      </span>
    </div>
  );
}
