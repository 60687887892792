import { BaseIconProps } from "../icon";

export function BackspaceIcon(props: BaseIconProps) {
  return (
    <svg
      width="23"
      height="16"
      viewBox="0 0 23 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.16522 0.875L7.90486 1.10938L1.23977 7.77604L0.666992 8.375L1.23977 8.97396L7.90486 15.6406L8.16522 15.875H22.667V0.875H8.16522ZM8.86818 2.54167H21.0007V14.2083H8.86818L3.03622 8.375L8.86818 2.54167ZM11.1333 4.83333L9.96167 6.00521L12.3309 8.375L9.96167 10.7448L11.1333 11.9167L13.5025 9.54688L15.8717 11.9167L17.0433 10.7448L14.6741 8.375L17.0433 6.00521L15.8717 4.83333L13.5025 7.20312L11.1333 4.83333Z"
        fill="currentColor"
      />
    </svg>
  );
}
