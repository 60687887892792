import { useState } from "react";
import { Notification } from "./notifications";
import { allNotifications } from "./all-notifications";
import { isAfter, subWeeks } from "date-fns";

function dismissedNotificationKey(notification: Notification) {
  return `haze-dismissed-notifications-${notification.title}-${notification.createdAt}`;
}

export const useNotifications = () => {
  const [notifications, setNotifications] = useState<Notification[]>(() =>
    allNotifications.filter(
      (n) =>
        // Don't show notifications that have been dismissed or are older than 8 weeks
        isAfter(new Date(n.createdAt), subWeeks(new Date(), 8)) &&
        localStorage.getItem(dismissedNotificationKey(n)) !== "true",
    ),
  );
  const visibleNotifications = notifications
    .sort(
      (a, b) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
    )
    .slice(-3);
  const [showNotifications, setShowNotifications] = useState(
    visibleNotifications.length > 0,
  );

  return {
    notifications: visibleNotifications,
    dismissNotification: (notification: Notification) => {
      setNotifications((prev) => {
        const newNotifications = prev.filter(
          (n) => n.title !== notification.title,
        );

        localStorage.setItem(dismissedNotificationKey(notification), "true");

        if (newNotifications.length === 0) {
          setTimeout(() => {
            setShowNotifications(false);
          }, 2000);
        }

        return newNotifications;
      });
    },
    showNotifications,
    notificationsHeight: showNotifications ? 130 : 0,
  };
};
