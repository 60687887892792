import { cn } from "@/utils";
import * as ToggleGroupPrimitive from "@radix-ui/react-toggle-group";
import React from "react";

export interface ToggleGroupData {
  label: React.ReactNode;
  value: string;
}

interface ToggleGroupProps {
  options: ToggleGroupData[];
  activeValue: string;
  vertical?: boolean;
  setActiveValue: (value: string) => void;
}

export function ToggleGroup({
  options,
  activeValue,
  setActiveValue,
  vertical,
}: ToggleGroupProps) {
  return (
    <ToggleGroupPrimitive.Root
      className={cn("flex ", {
        "space-x-2.5": !vertical,
        "space-y-2.5 flex-col": vertical,
      })}
      type="single"
      value={activeValue}
      onValueChange={(value) => {
        if (value === "") return; // Prevent unselecting all options
        setActiveValue(value);
      }}
      aria-label="Toggle options"
    >
      {options.map((option, index) => (
        <ToggleGroupPrimitive.Item
          key={option.value}
          className={cn(
            "font-bold text-sm rounded-full p-3.5 py-2 h-[46px] border border-cloud transition-all",
            activeValue === option.value && "border-accent",
            !vertical && "flex-1",
          )}
          aria-label={
            typeof option.label === "string"
              ? option.label
              : `Option ${index + 1}`
          }
          value={option.value}
        >
          {option.label}
        </ToggleGroupPrimitive.Item>
      ))}
    </ToggleGroupPrimitive.Root>
  );
}
