import { Button, Icon, Input, Page } from "@/components";
import { getJwtPayload } from "@/lib/auth";
import { SearchParamsWithRedirect } from "@/utils";
import { useEmbeddedWallet } from "@dynamic-labs/sdk-react-core";
import { createFileRoute, redirect, useNavigate } from "@tanstack/react-router";
import { ClipboardEventHandler, useEffect, useRef, useState } from "react";

interface SearchParams extends SearchParamsWithRedirect {
  isManagingPasskeys: boolean;
}

export const Route = createFileRoute(
  "/profile/wallet-settings/create-session/",
)({
  component: CreateSession,
  validateSearch: (searchParams: Record<string, unknown>): SearchParams => ({
    redirect:
      typeof searchParams.redirect === "string"
        ? searchParams.redirect
        : undefined,
    isManagingPasskeys: searchParams.isManagingPasskeys === true,
  }),
  loader: async () => {
    const payload = await getJwtPayload();

    if (!payload) {
      throw redirect({
        to: "/login-signup",
        search: {
          redirect: location.href,
        },
      });
    }

    return { email: payload.email };
  },
});

function CreateSession() {
  const { email } = Route.useLoaderData();
  const calledRef = useRef(false);
  const [meta, setMeta] = useState({ isLoading: false, errorMessage: "" });
  const search = Route.useSearch();
  const wallet = useEmbeddedWallet();
  const navigate = useNavigate({ from: Route.fullPath });
  const [oneTimeCode, setOneTimeCode] = useState("");

  useEffect(() => {
    if (calledRef.current) {
      return;
    }
    calledRef.current = true;

    if (wallet.isSessionActive && !search.isManagingPasskeys) {
      void navigate({ to: "/profile/wallet-settings/create-passkey", search });
    } else {
      void sendOneTimeCode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function sendOneTimeCode() {
    try {
      setMeta((prev) => ({ ...prev, errorMessage: "" }));
      await wallet.sendOneTimeCode();
    } catch (e) {
      console.error(e);
      setMeta({
        isLoading: false,
        errorMessage:
          e instanceof Error ? e.message : "An unknown error occurred",
      });
    }
  }

  async function createOrRestoreSession(code?: string) {
    setMeta({ isLoading: true, errorMessage: "" });

    try {
      await wallet.createOrRestoreSession(
        code ? { oneTimeCode: code } : undefined,
      );
      setMeta({ isLoading: false, errorMessage: "" });
      if (search.isManagingPasskeys) {
        await wallet.createPasskey(undefined);
        void navigate({
          to: "/profile/wallet-settings/manage-passkeys",
          search: (prev) => prev,
        });
      } else {
        void navigate({
          to: "/profile/wallet-settings/create-passkey",
          search,
        });
      }
    } catch (error) {
      console.error(error);
      setMeta({
        isLoading: false,
        errorMessage:
          (error as Error | undefined)?.message ??
          "There was an error, please try again.",
      });
      setTimeout(() => {
        setMeta({ isLoading: false, errorMessage: "" });
        setOneTimeCode("");
      }, 2_000);
    }
  }

  const handlePaste: ClipboardEventHandler<HTMLInputElement> = (e) => {
    const data = e.clipboardData.getData("text").trim();
    setOneTimeCode(data);
    void createOrRestoreSession(data);
  };

  return (
    <Page
      flex
      fullScreenHeight
      hideTabs
      title="Enter one time code"
      backButton={
        search.isManagingPasskeys
          ? {
              to: "/profile/wallet-settings/manage-passkeys",
              search: { redirect: decodeURI(search.redirect ?? "") },
            }
          : { to: decodeURI(search.redirect ?? "/trade") }
      }
    >
      <div className="w-full flex flex-col p-5 gap-5 flex-1">
        <div className="my-5 flex justify-center items-center relative">
          <Icon
            name="circle-partial"
            className="w-[50px] h-[50px] text-primary absolute animate-spin"
          />
          <Icon name="email" className="w-6 h-6 text-primary" />
        </div>

        <p className="text-gray-200 text-sm text-center">
          We’ve sent a verification code to
          <br />
          <span className="font-bold text-white">{email}</span>
        </p>

        <Input
          placeholder="Enter your code here..."
          value={oneTimeCode}
          isError={!!meta.errorMessage}
          disabled={meta.isLoading}
          onChange={(e) => {
            setOneTimeCode(e.target.value);
          }}
          onPaste={handlePaste}
          onKeyDown={(e) => {
            if (e.key === "Enter" && oneTimeCode) {
              void createOrRestoreSession(oneTimeCode);
            }
          }}
        />

        {meta.errorMessage ? (
          <>
            <p className="text-sm text-negative text-center">
              {meta.errorMessage}
            </p>
            <p className="text-sm text-negative text-center px-6">
              Not seeing your code? Try logging out from your profile page and
              logging back in.
            </p>
          </>
        ) : null}

        <div>
          <p className="text-sm text-gray-200 text-center">
            Did not receive your code yet?
          </p>
          <Button
            variant="flat"
            color="dark"
            className="w-full"
            disabled={meta.isLoading}
            onClick={() => {
              void sendOneTimeCode();
            }}
          >
            Resend code
          </Button>
        </div>
      </div>
    </Page>
  );
}
