import { SolType, TokenBalanceEntry, TokenInfoWithPrice } from "@/api";
import {
  Drawer,
  Input,
  Token,
  VirtualItemProps,
  VirtualList,
} from "@/components";
import {
  useSearchTokensPortfolioQuery,
  useSearchTokensQuery,
} from "@/hooks/useSearchTokensQuery";
import { cn } from "@/utils";
import { type DialogProps } from "@radix-ui/react-dialog";
import * as ToggleGroup from "@radix-ui/react-toggle-group";
import { useId } from "react";

type Token<T = undefined> = [T] extends [true]
  ? TokenBalanceEntry
  : TokenInfoWithPrice;

interface RenderTokenContainerProps<T extends boolean>
  extends VirtualItemProps {
  token?: Token<T>;
  children: React.ReactNode;
  key: number | string;
}

export interface ChooseTokenModalProps<T extends boolean> extends DialogProps {
  selectedToken: TokenInfoWithPrice | null | undefined;
  excludedToken?: TokenInfoWithPrice | null;
  onValueChange?: (value: string) => void;
  onSelectToken: (token: Token<T>) => void;
  triggerClassName?: string;
  title: string;
  receiveToken?: string;
  onlyWalletTokens?: T;
  solType: SolType;
  showUnverified?: boolean;
  renderTokenContainer?: (
    props: RenderTokenContainerProps<T>,
  ) => React.ReactNode;
}

export function ChooseTokenModal<T extends boolean>({
  selectedToken,
  excludedToken,
  solType,
  onValueChange,
  onSelectToken,
  triggerClassName,
  title,
  children,
  onlyWalletTokens,
  showUnverified,
  renderTokenContainer,
  ...props
}: React.PropsWithChildren<ChooseTokenModalProps<T>>) {
  const id = useId();
  const allTokensQuery = useSearchTokensQuery({
    skip: !!onlyWalletTokens,
    solType,
  });
  const walletTokensQuery = useSearchTokensPortfolioQuery({
    skip: !onlyWalletTokens,
    solType,
  });
  const {
    isLoading,
    search,
    rows,
    hasNextPage,
    setSearch,
    debouncedParams,
    goNextPage,
  } = onlyWalletTokens ? walletTokensQuery : allTokensQuery;
  const filteredRows = rows.filter(
    (token) =>
      // If only showing wallet tokens, omit tokens with 0 quantity
      (!onlyWalletTokens || !("quantity" in token) || token.quantity !== "0") &&
      // Omit excluded token if one is specified
      token.address !== excludedToken?.address,
  ) as Token<T>[];
  const getTokenPrice = (token: TokenInfoWithPrice | TokenBalanceEntry) => {
    if ("usdTotal" in token) {
      return token.usdTotal;
    }
    return token.price;
  };

  return (
    <Drawer.Root
      {...props}
      onOpenChange={(open) => {
        props.onOpenChange?.(open);

        if (!open) {
          setSearch("");
        }
      }}
    >
      <Drawer.Trigger className={triggerClassName}>{children}</Drawer.Trigger>
      <Drawer.Content title={title} titleId={id} noPadding>
        {isLoading ? (
          "loading..."
        ) : (
          <>
            <div className="px-5">
              <Input
                icon="search"
                placeholder="Search token name, symbol or address"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                setValue={setSearch}
                withClearBtn
              />
              <p className="uppercase text-sm text-primary font-bold mt-5">
                {onlyWalletTokens ? "Your tokens" : "All tokens"}
              </p>
            </div>

            <ToggleGroup.Root
              type="single"
              value={selectedToken?.address}
              onValueChange={(value) => {
                if (value) {
                  onValueChange?.(value);
                }
              }}
              aria-labelledby={id}
            >
              <VirtualList
                id={id}
                searchKey={debouncedParams.filter}
                classname="mt-2 h-[calc(80dvh-165px)] px-2.5"
                itemHeight={64}
                totalItems={filteredRows.length}
                hasNextPage={hasNextPage}
                isFetching={isLoading}
                onNextPage={goNextPage}
              >
                {(virtualItem, props) => {
                  const token =
                    virtualItem.index < filteredRows.length
                      ? filteredRows[virtualItem.index]
                      : undefined;

                  const content = token ? (
                    <Drawer.Close asChild>
                      <ToggleGroup.Item
                        {...(!renderTokenContainer
                          ? { ...props, key: virtualItem.key }
                          : null)}
                        value={token.address}
                        className={cn(
                          "w-full border border-transparent hover:border-cloud hover:bg-box rounded-lg px-3",
                          !renderTokenContainer ? props.className : null,
                        )}
                        onClick={() => onSelectToken(token)}
                      >
                        <Token
                          {...token}
                          showUnverified={showUnverified}
                          price={getTokenPrice(token)}
                          isSelected={
                            token.address === selectedToken?.address &&
                            token.symbol === selectedToken.symbol
                          }
                          priceChange24h={
                            onlyWalletTokens ? undefined : token.priceChange24h
                          }
                          quantityDetails={
                            onlyWalletTokens &&
                            "quantity" in token &&
                            typeof token.quantity === "string"
                              ? {
                                  value: token.quantity,
                                  decimals: token.decimals,
                                }
                              : undefined
                          }
                        />
                      </ToggleGroup.Item>
                    </Drawer.Close>
                  ) : null;

                  return renderTokenContainer
                    ? renderTokenContainer({
                        ...props,
                        token,
                        key: virtualItem.key,
                        children: content,
                      })
                    : content;
                }}
              </VirtualList>
            </ToggleGroup.Root>
          </>
        )}
      </Drawer.Content>
    </Drawer.Root>
  );
}
