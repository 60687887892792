import { DrawerContent } from "./content";
import { Root } from "./root";
import { Trigger } from "./trigger";
import { Close } from "./close";
import { Description } from "./description";

export const Drawer = {
  Root,
  Trigger,
  Content: DrawerContent,
  Description,
  Close,
};
