import { TokenInfo } from "@/api";
import {
  formatTokenNumber,
  getSymbolWithDefault,
  normalizeScaled,
} from "@/utils";

export interface Execution {
  in: bigint;
  out: bigint;
  remainder: string;
}

// export function getExecution(
//   inAmount: string,
//   outAmount: string,
//   inDecimals: number,
//   outDecimals: number,
// ): Execution {
//   const decimalDiff = BigInt(inDecimals - outDecimals);
//   const [inScaled, outScaled] =
//     decimalDiff < 0
//       ? [BigInt(inAmount) * 10n ** -decimalDiff, BigInt(outAmount)]
//       : [BigInt(inAmount), BigInt(outAmount) * 10n ** decimalDiff];

//   const biggerDecimal = Math.max(inDecimals, outDecimals);

//   let execution: Execution;
//   if (inScaled > outScaled) {
//     const remainder = normalizeScaled(inScaled % outScaled, biggerDecimal)
//       .slice(1)
//       .replace(/.$/, "");
//     execution = {
//       in: inScaled / outScaled,
//       out: 1n,
//       remainder,
//     };
//   } else {
//     const remainder = normalizeScaled(outScaled % inScaled, biggerDecimal)
//       .slice(1)
//       .replace(/.$/, "");
//     execution = {
//       in: 1n,
//       out: outScaled / inScaled,
//       remainder,
//     };
//   }
//   return execution;
// }

// export function formatExecution(
//   execution: Execution | null,
//   inToken: TokenInfo | null,
//   outToken: TokenInfo | null,
// ) {
//   if (!execution) return "N/A";
//   if (!inToken || !outToken) return "N/A";

//   return execution.in === 1n
//     ? `${execution.in} ${getSymbolWithDefault(inToken, true)} / ${execution.out}${execution.remainder} ${getSymbolWithDefault(outToken, true)}`
//     : `${execution.out} ${getSymbolWithDefault(outToken, true)} / ${execution.in}${execution.remainder} ${getSymbolWithDefault(inToken, true)}`;
// }

export function getExecution(
  inAmount: string,
  outAmount: string,
  inDecimals: number,
  outDecimals: number,
) {
  if (inAmount === "0") return null;
  const inNormalized = normalizeScaled(inAmount, inDecimals);
  const outNormalized = normalizeScaled(outAmount, outDecimals);
  return Number(outNormalized) / Number(inNormalized);
}

export function formatExecution(
  execution: number | null,
  inToken: TokenInfo | null,
  outToken: TokenInfo | null,
) {
  if (execution === null) return "N/A";
  if (!inToken || !outToken) return "N/A";
  if (execution < 1) {
    return `1 ${getSymbolWithDefault(outToken, true)} / ${formatTokenNumber(1 / execution, inToken.decimals, { decimalsMode: "fixed" })} ${getSymbolWithDefault(inToken, true)}`;
  }
  return `1 ${getSymbolWithDefault(inToken, true)} / ${formatTokenNumber(execution, outToken.decimals, { decimalsMode: "fixed" })} ${getSymbolWithDefault(outToken, true)}`;
}
