import { Drawer, Icon } from "@/components";
import { cn } from "@/utils";
import { VariantProps, cva } from "class-variance-authority";

const variants = cva(
  "text-negative bg-negative-faint text-sm flex items-center gap-1.5 uppercase mx-auto",
  {
    variants: {
      variant: {
        banner: "justify-center px-5 py-1",
        rounded: "rounded-full px-3 py-1.5 w-fit font-bold text-sm",
      },
      color: {
        negative: "text-negative bg-negative/10",
        warning: "text-primary bg-primary/10",
      },
    },
    defaultVariants: {
      variant: "banner",
    },
  },
);

interface ErrorMessageProps extends VariantProps<typeof variants> {
  className?: string;
  tooltip?: React.ReactNode;
  children: string;
}

export function ErrorMessage({
  children,
  className,
  tooltip,
  ...props
}: ErrorMessageProps) {
  const content = (
    <div className={cn(variants(props), className)}>
      <p>{children}</p>
      <Icon name="info" className="w-4 min-w-4" />
    </div>
  );

  return tooltip ? (
    <Drawer.Root>
      <Drawer.Trigger>{content}</Drawer.Trigger>
      <Drawer.Content title={children}>
        <p className="pb-4 text-gray-200 text-sm">{tooltip}</p>
      </Drawer.Content>
    </Drawer.Root>
  ) : (
    content
  );
}
