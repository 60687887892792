interface CoinStacksIllustrationProps {
  className?: string;
}

export function CoinStacksIllustration({
  className,
}: CoinStacksIllustrationProps) {
  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M55.9999 9.94238L46.1999 7.51758L36.3999 9.94238L32.1999 15.6012V34.9996L22.3999 32.5748L12.5999 34.9996L8.3999 40.657V53.5902L12.5999 59.2476L22.3999 61.6738L32.1999 59.2476L36.3999 53.5902L46.1999 56.015L55.9999 53.5902L60.1999 47.9328V15.6012L55.9999 9.94238Z"
        stroke="#FFF6D5"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M32.1997 15.6016L36.3997 21.259L46.1997 23.6838L55.9997 21.259L60.1997 15.6016"
        stroke="#FFF6D5"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M32.1997 22.0664L36.3997 27.7252L46.1997 30.15L55.9997 27.7252L60.1997 22.0664"
        stroke="#FFF6D5"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M32.1997 28.5332L36.3997 34.192L46.1997 36.6168L55.9997 34.192L60.1997 28.5332"
        stroke="#FFF6D5"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M8.3999 40.6562L12.5999 46.315L22.3999 48.7398L32.1999 46.315L36.3999 40.6562"
        stroke="#FFF6D5"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M8.3999 47.123L12.5999 52.7818L22.3999 55.2066L32.1999 52.7818L36.3999 47.123"
        stroke="#FFF6D5"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M56 21.2588V53.5904"
        stroke="#FFF6D5"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M46.1997 23.6826V56.0142"
        stroke="#FFF6D5"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M32.1997 35L36.3997 40.6574L46.1997 43.0836L55.9997 40.6574L60.1997 35"
        stroke="#FFF6D5"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M36.3999 47.1242L46.1999 49.549L55.9999 47.1242L60.1999 41.4668"
        stroke="#FFF6D5"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M36.3999 21.2588V53.5904"
        stroke="#FFF6D5"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M12.6001 46.3164V59.2482"
        stroke="#FFF6D5"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M22.3999 48.7402V61.6734"
        stroke="#FFF6D5"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M32.1997 46.3164V59.2482"
        stroke="#FFF6D5"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M46.2 7.51719L56 9.942L60.2 15.5994V47.931L56 53.5884L46.2 56.0132L36.4 53.5884L32.2 59.2458L22.4 61.6706L12.6 59.2472L8.4 53.5898V40.6566L12.6 34.9992L22.4 32.5744L32.2 34.9992V15.6008L36.4 9.9434L46.2 7.51719ZM46.2 6.0752L45.864 6.1578L36.064 8.5826L35.5754 8.703L35.2758 9.1062L31.0758 14.7636L30.8 15.1374V15.6008V33.2114L22.736 31.2164L22.4 31.1324L22.064 31.215L12.264 33.6398L11.7754 33.7602L11.4758 34.1634L7.2758 39.8208L7 40.1946V40.658V53.5912V54.0546L7.2758 54.427L11.4758 60.0844L11.7754 60.4876L12.264 60.608L22.064 63.0328L22.4 63.1154L22.736 63.0328L32.536 60.608L33.0246 60.4876L33.3242 60.0844L36.9684 55.1746L45.864 57.3754L46.2 57.4566L46.536 57.374L56.336 54.9492L56.8246 54.8288L57.1242 54.4256L61.3242 48.7682L61.6 48.3944V47.931V15.6008V15.1374L61.3242 14.765L57.1242 9.1076L56.8246 8.7044L56.336 8.5826L46.536 6.1578L46.2 6.0752Z"
        fill="#FFF6D5"
      />
    </svg>
  );
}
