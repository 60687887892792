import {
  MINIMUM_SOL_BALANCE_LAMPORTS,
  NATIVE_SOL_ACCOUNT_RENT,
  SOL_TRANSACTION_FEE,
  TOKEN_ACCOUNT_RENT,
} from "@/utils";

// Validation for the amount of funds available in a users wallet

export type InvalidBalance = "zero_balance" | "insufficient_funds" | "none";

export function validateFunds(
  amount: bigint,
  balance: bigint,
  priorityFee: bigint,
  isNativeSol: boolean,
  { maintainSwapMinimum } = { maintainSwapMinimum: true },
) {
  if (balance === 0n) {
    return {
      valid: false,
      kind: isNativeSol ? "zero_sol_balance" : "zero_balance",
    } as const;
  }
  if (amount > balance) {
    return { valid: false, kind: "insufficient_funds" } as const;
  }
  if (isNativeSol) {
    return validateSolBalance(balance - amount, priorityFee, {
      maintainSwapMinimum,
    });
  }
  return { valid: true, kind: "none" } as const;
}

export type InvalidSolBalance =
  | "zero_sol_balance"
  | "insufficient_sol"
  | "none";

export function validateSolBalance(
  balance: bigint,
  priorityFee: bigint,
  { maintainSwapMinimum } = { maintainSwapMinimum: true },
) {
  if (balance === 0n) {
    return { valid: false, kind: "zero_sol_balance" } as const;
  }
  if (
    maintainSwapMinimum &&
    balance < priorityFee + SOL_TRANSACTION_FEE + MINIMUM_SOL_BALANCE_LAMPORTS
  ) {
    const requiredSol =
      priorityFee +
      SOL_TRANSACTION_FEE +
      MINIMUM_SOL_BALANCE_LAMPORTS -
      balance;
    return { valid: false, kind: "insufficient_sol", requiredSol } as const;
  }
  if (!maintainSwapMinimum && balance < priorityFee + SOL_TRANSACTION_FEE) {
    const requiredSol = priorityFee + SOL_TRANSACTION_FEE - balance;
    return { valid: false, kind: "insufficient_sol", requiredSol } as const;
  }
  return { valid: true, kind: "none" } as const;
}

export type InvalidRentState =
  | "insufficient_rent_for_source"
  | "insufficient_rent_for_destination"
  | "none";

interface NativeRentValidationParams {
  sendAmount: bigint;
  sendBalance: bigint;
  receiveBalance: bigint;
  solBalance: bigint;
  priorityFee: bigint;
}

export function validateNativeTransactionRent({
  sendAmount,
  receiveBalance,
  solBalance,
  priorityFee,
}: NativeRentValidationParams) {
  if (
    solBalance - sendAmount - priorityFee - SOL_TRANSACTION_FEE !== 0n &&
    solBalance - sendAmount <
      priorityFee + SOL_TRANSACTION_FEE + NATIVE_SOL_ACCOUNT_RENT
  ) {
    const requiredSol =
      priorityFee +
      SOL_TRANSACTION_FEE +
      NATIVE_SOL_ACCOUNT_RENT -
      solBalance +
      sendAmount;

    return {
      valid: false,
      kind: "insufficient_rent_for_source",
      requiredSol,
    } as const;
  }
  if (
    sendAmount <
    priorityFee + SOL_TRANSACTION_FEE + NATIVE_SOL_ACCOUNT_RENT - receiveBalance
  ) {
    const requiredSol =
      priorityFee +
      SOL_TRANSACTION_FEE +
      NATIVE_SOL_ACCOUNT_RENT -
      receiveBalance -
      sendAmount;

    return {
      valid: false,
      kind: "insufficient_rent_for_destination",
      requiredSol,
    } as const;
  }
  return { valid: true, kind: "none" } as const;
}

interface SplRentValidationParams {
  sendAmount: bigint;
  sendBalance: bigint;
  solBalance: bigint;
  recipientAccountExists: boolean;
  priorityFee: bigint;
  // accountSize: bigint;
}

export function validateSplTransactionRent({
  sendAmount,
  sendBalance,
  solBalance,
  recipientAccountExists,
  priorityFee,
  // accountSize: _a,
}: SplRentValidationParams) {
  // TODO: Calc rent for the recipient account (SPL 2022)
  if (
    !recipientAccountExists &&
    sendAmount === sendBalance &&
    solBalance !== priorityFee + SOL_TRANSACTION_FEE + TOKEN_ACCOUNT_RENT &&
    solBalance <
      priorityFee +
        SOL_TRANSACTION_FEE +
        TOKEN_ACCOUNT_RENT +
        NATIVE_SOL_ACCOUNT_RENT
  ) {
    const requiredSol =
      priorityFee + SOL_TRANSACTION_FEE + TOKEN_ACCOUNT_RENT - solBalance;

    return {
      valid: false,
      kind: "insufficient_rent_for_destination",
      requiredSol,
    } as const;
  }

  if (
    !recipientAccountExists &&
    sendAmount !== sendBalance &&
    solBalance <
      priorityFee +
        SOL_TRANSACTION_FEE +
        TOKEN_ACCOUNT_RENT +
        NATIVE_SOL_ACCOUNT_RENT
  ) {
    const requiredSol =
      priorityFee +
      SOL_TRANSACTION_FEE +
      TOKEN_ACCOUNT_RENT +
      NATIVE_SOL_ACCOUNT_RENT -
      solBalance;

    return {
      valid: false,
      kind: "insufficient_rent_for_source",
      requiredSol,
    } as const;
  }

  return { valid: true, kind: "none" } as const;
}
