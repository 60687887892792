import {
  TokenInfo,
  useTokenAccountByMintQuery,
  useWalletInfoQuery,
} from "@/api";
import { validateFunds, validateSolBalance } from "@/app/validations";
import { useTypedSelector } from "@/store";
import { SOL_ADDRESS } from "@/utils";
import { skipToken } from "@reduxjs/toolkit/query";

type InvalidState =
  | "no_buy_token"
  | "no_sell_token"
  | "no_amount"
  | "not_validated";

type ValidationReturn =
  | ReturnType<typeof validateSolBalance>
  | ReturnType<typeof validateFunds>
  | { kind: InvalidState; valid: boolean };

export function useValidate() {
  const { useWrappedSol, priorityFee } = useTypedSelector(
    (state) => state.profile,
  );
  const _solBalance = useWalletInfoQuery().currentData?.rawBalance;

  return ({
    sendToken,
    receiveToken,
    scaledValue,
    sendTokenBalance: _splTokenBalance,
  }: {
    sendToken?: TokenInfo | null;
    receiveToken?: TokenInfo | null;
    scaledValue?: string | null;
    sendTokenBalance?: string;
  }): ValidationReturn => {
    const solBalance = BigInt(_solBalance ?? "0");
    const sendTokenBalance = BigInt(
      !useWrappedSol && sendToken?.address === SOL_ADDRESS
        ? _solBalance ?? "0"
        : _splTokenBalance ?? "0",
    );
    const userPriorityFee = BigInt(priorityFee);

    if (!sendToken) {
      return {
        kind: "no_sell_token",
        valid: false,
      };
    }
    if (!receiveToken) {
      return {
        kind: "no_buy_token",
        valid: false,
      };
    }
    if (!scaledValue || scaledValue === "0") {
      return {
        kind: "no_amount",
        valid: false,
      };
    }
    const scaledAmount = BigInt(scaledValue);
    const isNativeSol = sendToken.address === SOL_ADDRESS && !useWrappedSol;

    const fundsValidation = validateFunds(
      scaledAmount,
      sendTokenBalance,
      userPriorityFee,
      isNativeSol,
    );
    if (fundsValidation.kind !== "none") {
      return fundsValidation;
    }
    const solValidation = validateSolBalance(solBalance, userPriorityFee);
    if (solValidation.kind !== "none") {
      return solValidation;
    }
    return {
      kind: "none",
      valid: true,
    };
  };
}

export function useValidation(): ValidationReturn {
  const state = useTypedSelector((state) => state.tradeSwap);
  const _splTokenBalance = useTokenAccountByMintQuery(
    state.sendToken?.address ?? skipToken,
    { refetchOnMountOrArgChange: true },
  ).currentData?.tokenRawBalance;

  const getValidation = useValidate();

  return getValidation({
    sendToken: state.sendToken,
    receiveToken: state.receiveToken,
    scaledValue: state.amount.scaledValue,
    sendTokenBalance: _splTokenBalance,
  });
}
