import { REHYDRATE, rehydrateReducer } from "@/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  showSmallBalance: true,
  showHidden: false,
};

const portfolioSlice = createSlice({
  name: "portfolio",
  initialState,
  reducers: {
    [REHYDRATE]: rehydrateReducer,
    setShowSmallBalance: (state, action: PayloadAction<boolean>) => {
      state.showSmallBalance = action.payload;
    },
    setShowHidden: (state, action: PayloadAction<boolean>) => {
      state.showHidden = action.payload;
    },
  },
});

export const { setShowSmallBalance, setShowHidden } = portfolioSlice.actions;

export const portfolioReducer = portfolioSlice.reducer;
export const portfolioActions = portfolioSlice.actions;
