import { BaseIconProps } from "../icon";

export function EmailIcon(props: BaseIconProps) {
  return (
    <svg
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.625 5V25H29.5139V5H0.625ZM5.41667 7.22222H24.7222L15.0694 13.6458L5.41667 7.22222ZM2.84722 8.19444L10.3472 13.1944L2.84722 18.4375V8.19444ZM27.2917 8.19444V18.4375L19.7917 13.1944L27.2917 8.19444ZM12.2917 14.5139L14.4444 15.9375L15.0694 16.3194L15.6944 15.9375L17.8472 14.5139L27.2917 21.1111V22.7778H2.84722V21.1111L12.2917 14.5139Z"
        fill="currentColor"
      />
    </svg>
  );
}
