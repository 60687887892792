import { PhoneData } from "@dynamic-labs/sdk-react-core";
import { OtpVerifier } from "@/components/form/otp-login-form/otp-login-form";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { z } from "zod";
import { TokenInfo } from "@/api";

const emailSchema = z.string().email();
const validTelSchema = z
  .string()
  .regex(/^\s*(\+1\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}\s*$/);

export type LoginSignupStep = "login-signup" | "verify" | "beta-access-denied";

type SelectedToken = Pick<TokenInfo, "address" | "symbol">;

const initialState = {
  step: "login-signup" as LoginSignupStep,
  email: "",
  sms: {
    phone: "",
  } as PhoneData,
  otpType: "email" as OtpVerifier,
  otp: "",
  validations: {
    email: {
      valid: false,
      message: "",
    },
    sms: {
      valid: false,
      message: "",
    },
  },
  isVerificationPending: false,
  web3authOtp: "",
  web3authSelectedToken: undefined as SelectedToken | undefined,
  isTransferDelayed: false,
};

const loginSignupSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    reset: () => initialState,
    updateEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;

      try {
        emailSchema.parse(action.payload);
        state.validations.email.valid = true;
        state.validations.email.message = "";
      } catch (error) {
        state.validations.email.valid = false;
        state.validations.email.message = "Invalid email";
      }
    },
    updateSms: (state, action: PayloadAction<string>) => {
      state.sms = {
        iso2: "US",
        dialCode: "+1",
        phone: action.payload,
      };
      try {
        validTelSchema.parse(action.payload);
        state.validations.sms.valid = true;
        state.validations.sms.message = "";
      } catch (error) {
        state.validations.sms.valid = false;
        state.validations.sms.message = "Invalid phone number";
      }
    },
    changeOtpType: (state, action: PayloadAction<OtpVerifier>) => {
      state.otpType = action.payload;
    },
    returnToLoginSignup: (state) => {
      state.step = "login-signup";
    },
    continueToVerify: (state) => {
      state.step = "verify";
    },
    continueToBetaAccessDenied: (state) => {
      state.step = "beta-access-denied";
    },
    setVerificationPending: (state, action: PayloadAction<boolean>) => {
      state.isVerificationPending = action.payload;
    },
    setIsTransferDelayed: (state, action: PayloadAction<boolean>) => {
      state.isTransferDelayed = action.payload;
    },
    updateWeb3authOtp: (state, action: PayloadAction<string>) => {
      state.web3authOtp = action.payload;
    },
    toggleToken: (state, action: PayloadAction<SelectedToken>) => {
      if (
        state.web3authSelectedToken?.address === action.payload.address &&
        state.web3authSelectedToken.symbol === action.payload.symbol
      ) {
        state.web3authSelectedToken = undefined;
      } else {
        state.web3authSelectedToken = action.payload;
      }
    },
  },
});

export const {
  updateEmail,
  updateSms,
  changeOtpType,
  returnToLoginSignup,
  continueToVerify,
  continueToBetaAccessDenied,
  updateWeb3authOtp,
  toggleToken,
  setIsTransferDelayed,
  reset,
} = loginSignupSlice.actions;

export const loginSignupReducer = loginSignupSlice.reducer;
