import { Icon, icons } from "@/components";
import { cn } from "@/utils";

interface InputBaseProps {
  icon?: keyof typeof icons;
  isError?: boolean;
  setValue?: (value: string) => void;
  withClearBtn?: boolean;
  isFetching?: boolean;
}

type InputProps = InputBaseProps & React.InputHTMLAttributes<HTMLInputElement>;

export function Input({
  icon,
  isError,
  setValue,
  className,
  value,
  withClearBtn,
  isFetching,
  ...props
}: InputProps) {
  const handleClearClick = () => {
    if (setValue) setValue("");
  };

  const showClearBtn = withClearBtn && value;

  return (
    <div className="relative w-full flex items-center">
      {icon && (
        <Icon name={icon} className="absolute w-4 h-4 left-3.5 text-gray-200" />
      )}
      <input
        className={cn(
          "px-3.5 py-3 outline-none text-sm placeholder-gray-200 bg-transparent w-full text-white border border-cloud rounded-md focus:border-primary",
          {
            "pl-10": icon,
            "border-negative focus:border-negative": isError,
            "pr-10": withClearBtn && value,
          },
          className,
        )}
        value={value}
        {...props}
        autoCorrect="off"
        spellCheck="false"
      />
      {isFetching ? (
        <Icon
          size="small"
          name="circle-partial"
          className={cn(
            "absolute right-3.5 w-4 h-4 text-gray-200 animate-spin",
            showClearBtn && "right-10",
          )}
        />
      ) : null}
      {showClearBtn && (
        <button
          onClick={handleClearClick}
          className="absolute flex items-center px-3.5 h-full right-0"
          aria-label="Clear search"
        >
          <Icon name="circle-close" className="w-4 h-4 text-gray-200" />
        </button>
      )}
    </div>
  );
}
