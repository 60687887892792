import { useUpdateUsernameMutation, useUserQuery } from "@/api";
import {
  ErrorMessage,
  Input,
  KeyboardAwareButton,
  Page,
  Shimmer,
} from "@/components";
import { useAppDispatch, useTypedSelector } from "@/store";
import { useIsLoggedIn } from "@dynamic-labs/sdk-react-core";
import { skipToken } from "@reduxjs/toolkit/query";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { useState } from "react";
import { updateUsername } from "../-reducer";

export const Route = createFileRoute("/profile/username/")({
  component: ProfileUserName,
});

export function ProfileUserName() {
  const navigate = useNavigate();
  const isLoggedIn = useIsLoggedIn();
  const userQuery = useUserQuery(isLoggedIn ? undefined : skipToken);
  const [usernameMutate, usernameMutation] = useUpdateUsernameMutation();
  const username = useTypedSelector((state) => state.profile.username);
  const dispatch = useAppDispatch();

  const [unsavedUsername, setUnsavedUsername] = useState(username);

  const hasUsernameDiff = username !== unsavedUsername;

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const cleanedUsername = event.target.value.replace(/[^a-zA-Z0-9_]/g, "");
    setUnsavedUsername(cleanedUsername);
  };

  const onSaveUserName = async () => {
    if (usernameMutation.isLoading) return;

    dispatch(updateUsername(unsavedUsername));
    if (isLoggedIn) {
      const result = await usernameMutate(unsavedUsername);

      if ("error" in result) {
        return;
      }
    }

    void navigate({ to: "/profile", search: (prev) => prev });
  };

  return (
    <Page
      title="UserName"
      backButton={{
        to: "/profile",
        search: (prev) => prev,
        "aria-label": "Back to profile",
      }}
      hideTabs
    >
      <div className="px-5 pb-button-row">
        {usernameMutation.error && (
          <ErrorMessage variant="banner" className="mb-6">
            Something happened. Please try again.
          </ErrorMessage>
        )}
        <p className="text-sm text-gray-200 mb-5">
          Your username can be changed at anytime.
        </p>
        <Shimmer.Text className="h-12 w-full" isLoading={userQuery.isLoading}>
          <Input
            value={unsavedUsername}
            onChange={handleInputChange}
            aria-label="Username"
            placeholder="Enter new username"
          />
        </Shimmer.Text>
      </div>

      <KeyboardAwareButton
        disabled={!hasUsernameDiff}
        onClick={onSaveUserName}
        placement="without-tabs"
        isLoading={usernameMutation.isLoading}
      >
        Save
      </KeyboardAwareButton>
    </Page>
  );
}
