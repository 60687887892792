import { useSearchTokensQuery } from "@/hooks";
import { TabListBaseProps } from "@/routes/trade/-search/categories/shared";
import { TokenList } from "../token-list";

type SearchTokenQueryResult = ReturnType<typeof useSearchTokensQuery>;

interface AllListProps extends TabListBaseProps {
  searchTokenQueryResult: SearchTokenQueryResult;
}

export function AllList({
  searchTokenQueryResult,
  onSelectToken,
  scrollRef,
  extraHeight,
  onQuickBuyLongPress,
}: AllListProps) {
  const {
    rows,
    arePricesLoading,
    debouncedParams,
    isFetching,
    isLoading,
    hasNextPage,
    goNextPage,
  } = searchTokenQueryResult;

  return (
    <TokenList
      ref={scrollRef}
      tokens={rows}
      arePricesLoading={arePricesLoading}
      id="all-token-list"
      searchKey={debouncedParams.filter ?? "none"}
      isFetching={isFetching}
      isLoading={isLoading}
      hasNextPage={hasNextPage}
      onSelectToken={onSelectToken}
      onNextPage={goNextPage}
      extraHeight={extraHeight}
      onQuickBuyLongPress={onQuickBuyLongPress}
    />
  );
}
