import { TransactionToast } from "@/components";
import { useAppDispatch, useTypedSelector } from "@/store";
import { hideQuickTradeValidationToast } from "./quick-trade-validation-toast-slice";

export function QuickTradeValidationToast() {
  const { toast } = useTypedSelector(
    (state) => state.quickTradeValidationToast,
  );
  const dispatch = useAppDispatch();

  return (
    <TransactionToast
      open={Boolean(toast)}
      duration={5000}
      status={toast?.status}
      animationStyle="slideDown"
      title={toast?.title}
      baseToken={toast?.baseToken}
      quoteToken={toast?.quoteToken}
      onOpenChange={(open) => {
        if (!open) {
          dispatch(hideQuickTradeValidationToast());
        }
      }}
    />
  );
}
