import { useEffect, useRef, useState } from "react";
import { useQuickTradeSettingsQuery } from "@/api";
import { formatNumberInput } from "@/utils";
import { ToggleGroupData } from "../-toggle-group";

const isValidSellInput = (value: string) => {
  const numValue = Number(value);
  return numValue >= 0 && numValue <= 100;
};

const quickSellOptions: ToggleGroupData[] = [
  { label: "5%", value: "500" },
  { label: "25%", value: "2500" },
  { label: "100%", value: "10000" },
];

const getSellBpsInitialValue = (value: number | null | undefined) => {
  const valueWithDefault = value?.toString() || quickSellOptions[1].value;
  const isPresetSelected = quickSellOptions.some(
    (o) => o.value === valueWithDefault,
  );
  return {
    input: (Number(valueWithDefault) / 100).toString(),
    value: valueWithDefault,
    isPresetSelected,
    showSuffix: !isPresetSelected,
  };
};

export const useSellBpsForm = () => {
  const quickTradeSettingsQuery = useQuickTradeSettingsQuery();

  const savedSellTokenSymbol =
    quickTradeSettingsQuery.data?.settings?.sellTokenSymbol;
  const savedSolSellBps = quickTradeSettingsQuery.data?.settings?.solSellBps;
  const savedUsdcSellBps = quickTradeSettingsQuery.data?.settings?.usdcSellBps;
  const savedUsdtSellBps = quickTradeSettingsQuery.data?.settings?.usdtSellBps;

  const [unsavedSellTokenSymbol, setUnsavedSellTokenSymbol] = useState(
    savedSellTokenSymbol || "SOL",
  );

  const [unsavedSellBps, setUnsavedSellBps] = useState(
    getSellBpsInitialValue(
      unsavedSellTokenSymbol === "SOL"
        ? savedSolSellBps
        : unsavedSellTokenSymbol === "USDC"
          ? savedUsdcSellBps
          : savedUsdtSellBps,
    ),
  );

  const prevIsLoading = useRef(quickTradeSettingsQuery.isLoading);

  useEffect(() => {
    if (prevIsLoading.current && !quickTradeSettingsQuery.isLoading) {
      if (
        savedSellTokenSymbol &&
        unsavedSellTokenSymbol !== savedSellTokenSymbol
      ) {
        setUnsavedSellTokenSymbol(savedSellTokenSymbol);
      }

      if (
        savedSellTokenSymbol === "SOL" &&
        savedSolSellBps &&
        unsavedSellBps.value !== savedSolSellBps.toString()
      ) {
        setUnsavedSellBps(getSellBpsInitialValue(savedSolSellBps));
      } else if (
        savedSellTokenSymbol === "USDC" &&
        savedUsdcSellBps &&
        unsavedSellBps.value !== savedUsdcSellBps.toString()
      ) {
        setUnsavedSellBps(getSellBpsInitialValue(savedUsdcSellBps));
      } else if (
        savedSellTokenSymbol === "USDT" &&
        savedUsdtSellBps &&
        unsavedSellBps.value !== savedUsdtSellBps.toString()
      ) {
        setUnsavedSellBps(getSellBpsInitialValue(savedUsdtSellBps));
      }
    }

    prevIsLoading.current = quickTradeSettingsQuery.isLoading;
  }, [
    quickTradeSettingsQuery.isLoading,
    savedSellTokenSymbol,
    savedSolSellBps,
    savedUsdcSellBps,
    savedUsdtSellBps,
    unsavedSellBps.value,
    unsavedSellTokenSymbol,
  ]);

  const saveDisabled =
    unsavedSellTokenSymbol === savedSellTokenSymbol &&
    ((unsavedSellTokenSymbol === "SOL" &&
      unsavedSellBps.value === savedSolSellBps?.toString()) ||
      (unsavedSellTokenSymbol === "USDC" &&
        unsavedSellBps.value === savedUsdcSellBps?.toString()) ||
      (unsavedSellTokenSymbol === "USDT" &&
        unsavedSellBps.value === savedUsdtSellBps?.toString()));

  const handleSellInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const formattedValue = formatNumberInput(event.target.value, {
      currency: "bps",
      tokenDecimals: 2,
    });
    if (isValidSellInput(formattedValue)) {
      setUnsavedSellBps((prev) => ({
        ...prev,
        input: formattedValue,
        value: Math.round(Number(formattedValue) * 100).toString(),
        isPresetSelected: false,
        showSuffix: true,
      }));
    }
  };

  const handleSellToggleGroup = (value: string) => {
    setUnsavedSellBps((prev) => ({
      ...prev,
      input: (Number(value) / 100).toString(),
      value: value,
      isPresetSelected: true,
      showSuffix: false,
    }));
  };

  const handleSellInputFocus = () => {
    setUnsavedSellBps((current) => ({
      ...current,
      showSuffix: true,
    }));
  };

  return {
    unsavedSellTokenSymbol,
    setUnsavedSellTokenSymbol,
    unsavedSellBps,
    saveDisabled,
    handleSellInputChange,
    handleSellToggleGroup,
    handleSellInputFocus,
    quickSellOptions,
    error: Number(unsavedSellBps.value) === 0,
  };
};
