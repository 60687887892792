interface KeyProps<K>
  extends Omit<React.HTMLAttributes<HTMLButtonElement>, "onClick"> {
  name: K;
  onClick: (key: K) => void;
  "aria-label"?: string;
}

export function Key<K extends string>({
  children,
  onClick,
  name,
  "aria-label": ariaLabel,
  ...rest
}: React.PropsWithChildren<KeyProps<K>>) {
  return (
    <button
      {...rest}
      type="button"
      className="text-center text-primary hover:text-primary-hover bg-transparent text-2xl font-medium py-2 active:scale-150 active:-translate-y-2 transition-transform"
      onClick={() => {
        onClick(name);
      }}
      aria-label={ariaLabel}
    >
      {children ?? name}
    </button>
  );
}
