import { BaseIconProps } from "@/components/icon/icon";

export function Key(props: BaseIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 26"
      fill="none"
      {...props}
    >
      <path
        d="M17.7341 9.60829C18.7562 9.60829 19.5849 8.77966 19.5849 7.7575C19.5849 6.73533 18.7562 5.90671 17.7341 5.90671C16.7119 5.90671 15.8833 6.73533 15.8833 7.7575C15.8833 8.77966 16.7119 9.60829 17.7341 9.60829Z"
        fill="currentColor"
      />
      <path
        d="M15.988 1.54163C11.5968 1.54163 8.02612 5.11226 8.02612 9.50353C8.02612 9.89639 8.0785 10.2543 8.13961 10.6035L8.15707 10.7258L1.31263 17.5353L1.04199 17.8408V24.4583H7.37136V21.8392H9.99041V19.2202H12.6095V16.6885C13.7095 17.2035 14.8706 17.4742 15.9968 17.4742C20.388 17.4742 23.9587 13.9035 23.9587 9.51226C23.9587 5.12099 20.388 1.54163 15.988 1.54163ZM15.988 15.5099C15.0015 15.5099 13.9364 15.2392 13.0634 14.7591L12.8452 14.6543H10.6452V17.2646H8.02612V19.8837H5.40707V22.5027H3.00628V18.6702L9.87691 11.7734L10.2523 11.4329L10.1563 10.9091C10.069 10.3678 9.99041 9.91385 9.99041 9.51226C9.99041 6.20353 12.6793 3.50591 15.9968 3.50591C19.3142 3.50591 22.0031 6.1948 22.0031 9.51226C22.0031 12.8297 19.3142 15.5186 15.9968 15.5186L15.988 15.5099Z"
        fill="currentColor"
      />
    </svg>
  );
}
