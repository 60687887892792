import { Page } from "@/components";
import { ProfileList } from "@/routes/profile/-profile-list";
import { createFileRoute } from "@tanstack/react-router";
import { useIsFastSigninEnabled } from "@/hooks/useIsFastSigninEnabled";

export const Route = createFileRoute("/profile/wallet-settings/")({
  component: WalletSettings,
});

function WalletSettings() {
  const isFastSigninEnabled = useIsFastSigninEnabled();
  const listItems = {
    ...(!isFastSigninEnabled && {
      security: [
        {
          label: "Passkeys",
          value: "/profile/wallet-settings/manage-passkeys",
        },
      ],
    }),
    export_and_backup: [
      {
        label: "Private Key",
        value: "/profile/wallet-settings/private-key",
      },
    ],
  };

  return (
    <Page
      title="Wallet Settings"
      backButton={{
        to: "/profile",
        "aria-label": "Back to Profile",
        search: (prev) => prev,
      }}
    >
      <div className="space-y-6 pb-6 mt-8">
        {listItems.security?.length ? (
          <ProfileList title="Security methods" items={listItems.security} />
        ) : null}
        {listItems.export_and_backup.length > 0 ? (
          <ProfileList
            title="Export and backup"
            items={listItems.export_and_backup}
          />
        ) : null}
      </div>
    </Page>
  );
}
