import { useUpdateSettingsMutation, useUserQuery } from "@/api";
import { ErrorMessage, KeyboardAwareButton, Page, Shimmer } from "@/components";
import {
  unvalidatedToken,
  useValidatedRpcUrl,
} from "@/routes/profile/rpc/-validate";
import { useAppDispatch, useTypedSelector } from "@/store";
import { useIsLoggedIn } from "@dynamic-labs/sdk-react-core";
import { skipToken } from "@reduxjs/toolkit/query";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { useState } from "react";
import { CustomInput } from "../-custom-input";
import { updateRPC } from "../-reducer";
import { ToggleGroup, ToggleGroupData } from "../-toggle-group";

export const Route = createFileRoute("/profile/rpc/")({
  component: ProfileRPC,
});

const DEFAULT_RPC_VALUE = "";

const CUSTOM_URL_LABEL = "CUSTOM URL";

function ProfileRPC() {
  const navigate = useNavigate();
  const isLoggedIn = useIsLoggedIn();
  const userQuery = useUserQuery(isLoggedIn ? undefined : skipToken);
  const [settingsMutate, settingsMutation] = useUpdateSettingsMutation();
  const rpcUrl =
    useTypedSelector((state) => state.profile.customRpcUrl) ??
    DEFAULT_RPC_VALUE;
  const [unsavedRpcUrl, setUnsavedRpcUrl] = useState(rpcUrl);
  const [rpcModeState, setRpcModeState] = useState(
    rpcUrl ? "Custom" : "Default",
  );
  const dispatch = useAppDispatch();

  const {
    validatedRpcUrl,
    isLoading: isValidationRunning,
    error: rpcError,
    setError: setRpcError,
  } = useValidatedRpcUrl(unsavedRpcUrl);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUnsavedRpcUrl(event.target.value);
  };

  const options: ToggleGroupData[] = [
    {
      label: "DFlow RPC Pool",
      value: "Default",
    },
    { label: CUSTOM_URL_LABEL, value: "Custom" },
  ];

  const isCustomSelected = rpcModeState === "Custom";

  const displayedOptions = isCustomSelected ? [options[0]] : options;

  const handleToggleGroup = (value: string) => {
    setRpcModeState(value);
    setUnsavedRpcUrl(isCustomSelected ? DEFAULT_RPC_VALUE : rpcUrl);
    setRpcError("");
  };

  const onSaveRPC = async () => {
    if (settingsMutation.isLoading) return;
    if (typeof validatedRpcUrl === "symbol") return;

    dispatch(updateRPC(validatedRpcUrl));
    if (isLoggedIn) {
      const result = await settingsMutate({ customRpcUrl: validatedRpcUrl });

      if ("error" in result) {
        return;
      }
    }

    void navigate({ to: "/profile", search: (prev) => prev });
  };

  return (
    <Page
      title="RPC"
      backButton={{
        to: "/profile",
        search: (prev) => prev,
        "aria-label": "Back to profile",
      }}
      hideTabs
    >
      <div className="px-5 pb-button-row">
        {settingsMutation.error && (
          <ErrorMessage variant="banner" className="mb-6">
            Something happened. Please try again.
          </ErrorMessage>
        )}
        <p className="text-sm text-gray-200 mb-5">
          Select or specify your preferred RPC provider.
        </p>
        <div className="flex flex-col gap-2.5">
          <Shimmer.Text className="h-12 w-full" isLoading={userQuery.isLoading}>
            <ToggleGroup
              options={displayedOptions}
              setActiveValue={handleToggleGroup}
              activeValue={rpcModeState}
              vertical
            />
          </Shimmer.Text>
          <Shimmer.Text className="h-12 w-full" isLoading={userQuery.isLoading}>
            {isCustomSelected && (
              <CustomInput
                customValue={unsavedRpcUrl}
                label={CUSTOM_URL_LABEL}
                inputMode="url"
                onChange={handleInputChange}
                focused
                autoFocus
                error={Boolean(rpcError)}
              />
            )}
          </Shimmer.Text>
          {isValidationRunning ? (
            <p className="ml-4 text-primary font-bold">Validating RPC URL...</p>
          ) : rpcError ? (
            <ErrorMessage variant="rounded" className="uppercase mb-6">
              {rpcError}
            </ErrorMessage>
          ) : null}
        </div>
      </div>
      <KeyboardAwareButton
        onClick={onSaveRPC}
        disabled={
          rpcUrl === unsavedRpcUrl ||
          Boolean(rpcError) ||
          validatedRpcUrl === unvalidatedToken ||
          (isCustomSelected && !unsavedRpcUrl)
        }
        placement="without-tabs"
        isLoading={settingsMutation.isLoading}
      >
        Save
      </KeyboardAwareButton>
    </Page>
  );
}
