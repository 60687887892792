import { BaseIconProps } from "../icon";

export function CheckIcon(props: BaseIconProps) {
  return (
    <svg
      viewBox="0 0 18 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.6425 0.875L6.25506 9.28564L2.34237 5.78632L0.75 7.21368L5.48162 11.465L6.25506 12.125L17.25 2.30235L15.6425 0.875Z"
        fill="currentColor"
      />
    </svg>
  );
}
