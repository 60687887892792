import { Icon, Shimmer, TokenIcon } from "@/components";
import { SOL_ADDRESS, cn, getSymbolWithDefault } from "@/utils";
import {
  ChooseTokenDrawer as ChooseTokenDrawerWrapper,
  ChooseTokenDrawerProps as ChooseTokenDrawerWrapperProps,
} from "../-choose-token-drawer";

interface ChooseTokenDrawerProps<T extends boolean>
  extends ChooseTokenDrawerWrapperProps<T> {
  className?: string;
  triggerLabel: string;
  isLoading?: boolean;
}

export function ChooseTokenDrawer<T extends boolean>({
  className,
  triggerLabel,
  selectedToken,
  solType,
  isLoading = false,
  ...props
}: React.PropsWithChildren<ChooseTokenDrawerProps<T>>) {
  let symbol = getSymbolWithDefault(selectedToken) || "Select token";
  if (selectedToken?.address === SOL_ADDRESS && solType === "sol") {
    symbol = "SOL";
  }
  if (selectedToken?.address === SOL_ADDRESS && solType === "wsol") {
    symbol = "WSOL";
  }
  return (
    <ChooseTokenDrawerWrapper
      {...props}
      solType={solType}
      selectedToken={selectedToken}
      triggerClassName={cn("flex items-center w-full group", className)}
    >
      <div className="w-full flex items-center justify-between h-info-row border-t border-cloud group-last:border-b">
        <p className="uppercase text-xs font-bold">{triggerLabel}</p>
        <Shimmer.Text isLoading={isLoading} className="h-[24px] py-2 w-[100px]">
          <div className="flex items-center justify-between">
            {selectedToken ? (
              <TokenIcon logoURI={selectedToken.logoURI} className="w-6 h-6" />
            ) : null}
            <span className="text-sm font-bold ml-1.5">{symbol}</span>
            <Icon name="chevron" className="w-3 h-3 -rotate-90 ml-1.5" />
          </div>
        </Shimmer.Text>
      </div>
    </ChooseTokenDrawerWrapper>
  );
}
