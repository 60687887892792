import {
  usePreviouslyUsedWithdrawalAddressesQuery,
  useWalletInfoQuery,
} from "@/api";
import {
  Button,
  ButtonRow,
  TransactionErrorMessage,
  TransactionErrorMessageProps,
  Icon,
  Input,
  Page,
  ScanQRCode,
} from "@/components";
import { useAppDispatch, useTypedSelector } from "@/store";
import { cn, formatAddressShort, formatSimpleDate } from "@/utils";
import { createFileRoute, useRouter } from "@tanstack/react-router";
import { useState } from "react";
import { setAddress } from "../-reducer";
import { useValidation } from "../-validation";
import { validateAddress } from "@/app/validations";

export const Route = createFileRoute("/withdraw/address/")({
  component: AddressView,
});

function AddressView() {
  const walletAddress = useWalletInfoQuery().data?.walletAddress ?? undefined;
  const [showScanQrCode, setShowScanQrCode] = useState(false);
  const addressesQuery = usePreviouslyUsedWithdrawalAddressesQuery();
  const router = useRouter();

  const dispatch = useAppDispatch();
  const { address } = useTypedSelector((state) => state.withdrawFlow);
  const { addressValidation } = useValidation();

  const selectAddress = (address: string) => {
    dispatch(setAddress(address));
    if (!validateAddress(address, walletAddress).valid) return;

    void router.navigate({ to: "/withdraw/amount" });
  };

  if (showScanQrCode) {
    return (
      <ScanQRCode
        onScan={(newAddress) => {
          dispatch(setAddress(newAddress));
          setShowScanQrCode(false);
        }}
        onClose={() => {
          setShowScanQrCode(false);
        }}
      />
    );
  }

  let error: TransactionErrorMessageProps | undefined;

  if (
    address &&
    addressesQuery.data?.every((next) => address !== next.address)
  ) {
    error = {
      title: "NEW ADDRESS DETECTED",
      message: "You have no historical transaction to this address.",
      color: "warning",
    };
  }

  switch (addressValidation.kind) {
    case "invalid_address":
      error = {
        title: "INVALID SOLANA ADDRESS",
        message: "You entered an invalid Solana wallet address.",
        color: "negative",
      };
      break;
    case "own_address":
      error = {
        title: "OWN ADDRESS DETECTED",
        message: "You cannot withdraw to your own address.",
        color: "negative",
      };
      break;
  }

  return (
    <Page
      hideTabs
      title="Withdraw"
      backButton={{
        to: "/withdraw/amount",
        "aria-label": "Back to portfolio",
      }}
      fullScreenHeight
    >
      <div
        className={cn("w-full h-full bg-gray-600", {
          "pb-button-row": address,
        })}
      >
        <div className="px-5 flex flex-col gap-7">
          <p className="text-sm text-gray-200 font-sans">
            Specify a Solana wallet address for this withdrawal
          </p>

          <div className="flex gap-1">
            <Input
              placeholder="Enter address"
              withClearBtn
              setValue={() => {
                dispatch(setAddress(""));
              }}
              isError={!addressValidation.valid && (address?.length ?? 0) > 0}
              value={address}
              onChange={(e) => {
                dispatch(setAddress(e.target.value));
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" && address) {
                  selectAddress(address);
                }
              }}
              autoCorrect="off"
              spellCheck="false"
            />

            {!address && (
              <button
                onClick={() => {
                  setShowScanQrCode(true);
                }}
                className="p-3.5 border rounded-lg border-cloud hover:border-primary"
                aria-label="Scan QR code"
              >
                <Icon name="qrcode" className="w-4 h-4 text-primary" />
              </button>
            )}
          </div>

          {!address
            ? (addressesQuery.data?.length ?? 0) > 0 && (
                <div className="flex flex-col gap-4">
                  <h2 className="uppercase text-primary text-sm font-bold">
                    USE PREVIOUS ADDRESSES
                  </h2>
                  <ul className="overflow-y-auto">
                    {addressesQuery.data?.map(({ address, lastUsed }) => {
                      return (
                        <li
                          key={address}
                          className="flex justify-between items-center border-t last:border-b border-cloud h-info-row"
                        >
                          <button
                            onClick={() => {
                              selectAddress(address);
                            }}
                            className="w-full h-full flex justify-between items-center"
                          >
                            <span className="text-xs text-white font-bold ml-1.5">
                              {formatAddressShort(address, 5)}
                            </span>
                            <div className="flex gap-2">
                              <span className="text-gray-200 text-xs">
                                LAST USED:{" "}
                                {lastUsed
                                  ? formatSimpleDate(lastUsed)
                                  : "NEVER"}
                              </span>
                              <Icon
                                name="chevron"
                                className="w-4 h-4 text-white -rotate-90"
                              />
                            </div>
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )
            : null}

          {error ? <TransactionErrorMessage {...error} /> : null}
        </div>

        {address && (
          <ButtonRow noTabs>
            <Button
              color={!addressValidation.valid ? "negative" : "primary"}
              disabled={!addressValidation.valid}
              className="w-full"
              onClick={() => {
                if (address) {
                  selectAddress(address);
                }
              }}
            >
              Confirm
            </Button>
          </ButtonRow>
        )}
      </div>
    </Page>
  );
}
