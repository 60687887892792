import { BaseIconProps } from "../icon";

export function PishingIcon(props: BaseIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 25"
      fill="none"
      {...props}
    >
      <path
        d="M2.08301 2.08333V2.95138V22.9167H22.9163V2.08333H2.08301ZM3.81912 3.81944H21.1802V7.29166H3.81912V3.81944ZM4.68717 4.68749V6.42361H6.42329V4.68749H4.68717ZM7.29134 4.68749V6.42361H9.02745V4.68749H7.29134ZM9.89551 4.68749V6.42361H11.6316V4.68749H9.89551ZM3.81912 9.02777H12.6404C12.5477 9.31017 12.4997 9.60375 12.4997 9.89583C12.4997 10.5228 12.704 11.1613 13.1524 11.6658C13.4271 11.9749 13.8059 12.2069 14.2358 12.3491V15.9722C14.2358 16.9297 13.4571 17.7083 12.4997 17.7083C11.5422 17.7083 10.7636 16.9297 10.7636 15.9722H12.4997L9.02745 12.5V15.9722C9.02745 17.8871 10.5847 19.4444 12.4997 19.4444C14.4146 19.4444 15.9719 17.8871 15.9719 15.9722V12.3491C16.4017 12.2069 16.7805 11.9749 17.0553 11.6658C17.5037 11.1614 17.708 10.5228 17.708 9.89583C17.708 9.60375 17.66 9.31017 17.5673 9.02777H21.1802V21.1805H3.81912V9.02777ZM15.1038 9.02777C15.4655 9.02777 15.6263 9.13215 15.7566 9.27869C15.8868 9.42524 15.9719 9.6547 15.9719 9.89583C15.9719 10.137 15.8868 10.3664 15.7566 10.513C15.6263 10.6595 15.4655 10.7639 15.1038 10.7639C14.7422 10.7639 14.5814 10.6595 14.4511 10.513C14.3208 10.3664 14.2358 10.137 14.2358 9.89583C14.2358 9.6547 14.3208 9.42524 14.4511 9.27869C14.5814 9.13215 14.7422 9.02777 15.1038 9.02777Z"
        fill="currentColor"
      />
    </svg>
  );
}
