import { Icon, IconProps } from "@/components";

export interface InfoCard {
  icon: IconProps["name"];
  title: string;
  content: string;
}

interface InfoCardsProps {
  cards: InfoCard[];
}

export function InfoCards({ cards }: InfoCardsProps) {
  return (
    <div className="w-full flex flex-col gap-2.5">
      {cards.map((card) => (
        <div
          key={card.title}
          className="p-2.5 flex w-full flex-col gap-2.5 border border-white/10 rounded-xl bg-box"
        >
          <div className="flex gap-2.5">
            <Icon name={card.icon} className="shrink-0 text-accent w-7 h-7" />
            <div className="flex flex-col gap-1">
              <h2 className="text-white font-bold text-sm">{card.title}</h2>
              <p className="text-gray-200 text-sm">{card.content}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
