import { BaseIconProps } from "../icon";

export function ActionIcon(props: BaseIconProps) {
  return (
    <svg
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_14145_1164)">
        <path
          d="M10.15 11.2813H10V13.3688H1.40625V2.78125H10V5.025L10.7 4.30625L11.4062 3.61875V1.375H0V14.775H11.4062V12.5375L10.7 11.85L10.15 11.2813Z"
          fill="white"
        />
        <path
          d="M14.1 7.56875L11.5437 5.0125L10.5313 6.025L11.8813 7.375H4.375V8.78125H11.8813L10.5875 10.0687L10.5313 10.125L11.5437 11.1375L14.1 8.58125L14.5812 8.075L14.1 7.56875Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_14145_1164">
          <rect
            width="15"
            height="15"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
