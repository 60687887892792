/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { cn } from "@/utils";
import {
  // eslint-disable-next-line no-restricted-imports
  Link as TanstackLink,
} from "@tanstack/react-router";
import React from "react";

export const Link: typeof TanstackLink = React.forwardRef(function Link(
  props: any,
  ref,
) {
  const { className, ...rest } = props;
  return (
    <TanstackLink
      className={cn("drag-none callout-none", className)}
      {...rest}
      ref={ref}
    />
  );
}) as any;
