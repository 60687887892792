import { Notification } from "./notifications";

// (title + createdAt) needs to be unique since it's used as the key for local storage
export const allNotifications: Notification[] = [
  {
    title: "Deposit funds now!",
    description: "Deposit directly to DFlow from Coinbase.",
    type: "Reminder",
    icon: "speakerphone",
    createdAt: "2024-07-25T00:00:00Z",
  },
  {
    title: "Swipe to Like!",
    description:
      "You can now swipe left on tokens to save them to your favorites list.",
    type: "Feature",
    icon: "hand-swipe",
    createdAt: "2024-07-26T00:00:00Z",
  },
  {
    title: "Quick trade",
    description: "Swipe tokens to one-click buy or sell  them!",
    type: "Feature",
    icon: "bolt",
    createdAt: "2024-08-02T00:00:00Z",
  },
];
