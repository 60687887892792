import { TokenInfoWithPrice, useTokenInfoQuery } from "@/api";
import {
  Button,
  ButtonProps,
  CurrencyInput,
  TransactionErrorMessage,
  TransactionErrorMessageProps,
  Icon,
  NumericKeyboard,
  OneTimeCodePrompt,
  Shimmer,
  TotalPercentagePresets,
  handleNumericKeyboardKey,
} from "@/components";
import {
  useAccountBalance,
  useSearchTokensPortfolioQuery,
  useTotalPercentagePresets,
} from "@/hooks";
import {
  setIsUsd,
  updateAmount,
  updateReceiveToken,
  updateScaledValue,
  updateSendToken,
} from "@/routes/trade/-reducer";
import { updateSearchParams } from "@/routes/trade/-swap/util";
import { useValidation } from "@/routes/trade/-swap/validation";
import { useAppDispatch, useTypedSelector } from "@/store";
import {
  MINIMUM_SOL_BALANCE_LAMPORTS,
  SOL_ADDRESS,
  SOL_DECIMALS,
  SOL_TRANSACTION_FEE,
  USDC_ADDRESS,
  formatTokenNumber,
  formatUsdTokenAmountValue,
  getSymbolWithDefault,
  normalizeScaled,
  transactionStatusMessage,
} from "@/utils";
import { getRedirectPath } from "@/utils/url";
import { useIsLoggedIn } from "@dynamic-labs/sdk-react-core";
import { skipToken } from "@reduxjs/toolkit/query";
import { useNavigate } from "@tanstack/react-router";
import { useEffect, useRef } from "react";
import { Route } from "..";
import { ChooseTokenDrawer } from "./choose-token-drawer";

interface MobileSwapProps {
  sendTokenIsLoading: boolean;
  receiveTokenIsLoading: boolean;
}

export function MobileSwap({
  sendTokenIsLoading,
  receiveTokenIsLoading,
}: MobileSwapProps) {
  const { receiveToken: receiveTokenAddress } = Route.useSearch();
  const navigate = useNavigate();
  const isLoggedIn = useIsLoggedIn();
  const state = useTypedSelector((state) => state.tradeSwap);
  const { useWrappedSol } = useTypedSelector((state) => state.profile);
  const priorityFee = BigInt(
    useTypedSelector((state) => BigInt(state.profile.priorityFee)),
  );
  const dispatch = useAppDispatch();
  const sendTokenBalance = useAccountBalance(state.sendToken?.address);
  const {
    reset: resetPresets,
    overdraft: presetOverdraft,
    ...totalPercentagePresetProps
  } = useTotalPercentagePresets(state.sendToken?.address);
  const fallbackTokenInfo = useTokenInfoQuery(
    !state.sendToken
      ? {
          address:
            state.receiveToken?.address === SOL_ADDRESS
              ? USDC_ADDRESS
              : SOL_ADDRESS,
          withPrice: true,
          solType: "sol",
        }
      : skipToken,
  );
  const isFiatUnavailable = !state.sendToken?.price;
  // Set isUsd to false if we don't have a price
  useEffect(() => {
    if (state.isUsd && state.sendToken && state.sendToken.price === null) {
      dispatch(setIsUsd(false));
    }
  });

  const fiatValueAvailable =
    (sendTokenBalance.data?.balance ?? 0) * (state.sendToken?.price ?? 0);
  const availableBalance = state.isUsd
    ? formatUsdTokenAmountValue(fiatValueAvailable, {
        moneyMaxDecimals: 4,
      })
    : formatTokenNumber(
        sendTokenBalance.data?.balance,
        state.sendToken?.decimals ?? 9,
        { decimalsMode: "fixed" },
      );
  const validation = useValidation();
  const inputRef = useRef<HTMLInputElement>(null);

  const ValidationErrorButton = () => {
    const ErrBtn = (props: ButtonProps) => (
      <Button variant="big" className="w-full" {...props} />
    );
    if (presetOverdraft.overdrafted) {
      const msg =
        presetOverdraft.amount === 0n
          ? "Deposit SOL to proceed"
          : `Deposit ${normalizeScaled(presetOverdraft.amount, SOL_DECIMALS)} SOL to proceed`;
      return <ErrBtn color="negative">{msg}</ErrBtn>;
    }
    switch (validation.kind) {
      case "no_sell_token":
        return <ErrBtn color="gray">Select sell token</ErrBtn>;
      case "no_buy_token":
        return <ErrBtn color="gray">Select buy token</ErrBtn>;
      case "no_amount":
        return <ErrBtn color="gray">Enter an amount</ErrBtn>;
      case "zero_balance":
        return (
          <ErrBtn color="negative">
            0.00 {getSymbolWithDefault(state.sendToken)} Available
          </ErrBtn>
        );
      case "insufficient_funds":
        return (
          <ErrBtn
            color="negative"
            to="/deposit"
            search={{ redirect: getRedirectPath() }}
            icon={<Icon name="back-arrow-boxed" className="rotate-180 w-4" />}
          >
            Deposit funds to proceed
          </ErrBtn>
        );
      case "zero_sol_balance":
        return (
          <ErrBtn
            color="negative"
            to="/deposit"
            search={{ redirect: getRedirectPath() }}
            icon={<Icon name="back-arrow-boxed" className="rotate-180 w-4" />}
          >
            Deposit SOL to proceed
          </ErrBtn>
        );
      case "insufficient_sol":
        return (
          <ErrBtn color="negative">
            Deposit {normalizeScaled(validation.requiredSol, SOL_DECIMALS)} SOL
            to proceed
          </ErrBtn>
        );
      case "not_validated":
      case "none":
        return null;
      default: {
        const _exhaustiveCheck: never = validation;
      }
    }
  };

  const onInputChange = (value: string) => {
    resetPresets();
    dispatch(
      updateAmount({
        amount: value,
        isUsd: state.isUsd,
        token: state.sendToken,
      }),
    );
  };

  const onSwapCurrency = () => {
    resetPresets();
    dispatch(setIsUsd(!state.isUsd));
  };

  const walletTokensQuery = useSearchTokensPortfolioQuery({
    solType: useWrappedSol ? "wsol" : "sol",
  });
  const defaultSendTokenAddress =
    receiveTokenAddress !== SOL_ADDRESS
      ? SOL_ADDRESS
      : walletTokensQuery.rows.length && !walletTokensQuery.isLoading
        ? walletTokensQuery.rows
            .filter((row) => row.address !== receiveTokenAddress)
            .sort((a, b) => (b.usdTotal || 0) - (a.usdTotal || 0))[0]?.address
        : undefined;
  const defaultSendTokenInfo = useTokenInfoQuery(
    defaultSendTokenAddress
      ? {
          address: defaultSendTokenAddress,
          withPrice: true,
          solType: "sol",
        }
      : skipToken,
  );

  useEffect(() => {
    if (
      !state.sendToken &&
      !defaultSendTokenInfo.isLoading &&
      defaultSendTokenInfo.currentData
    ) {
      updateSearchParams({
        sendToken: defaultSendTokenAddress,
      });
      dispatch(updateSendToken(defaultSendTokenInfo.currentData));
    }
  });

  const onSwitchToken = () => {
    resetPresets();

    const newReceiveToken =
      state.sendToken ?? fallbackTokenInfo.currentData ?? null;

    dispatch(updateSendToken(state.receiveToken ?? null));
    dispatch(updateReceiveToken(newReceiveToken));
    updateSearchParams({
      sendToken: state.receiveToken?.address,
      receiveToken: newReceiveToken?.address,
    });
  };

  const onSellTokenChange = (token: TokenInfoWithPrice) => {
    if (token.address === state.sendToken?.address) return;
    resetPresets();

    if (token.address === state.receiveToken?.address) {
      updateSearchParams({
        sendToken: token.address,
        receiveToken:
          state.sendToken?.address ?? fallbackTokenInfo.data?.address,
      });
      dispatch(updateReceiveToken(state.sendToken));
    } else {
      updateSearchParams({ sendToken: token.address });
    }

    dispatch(updateSendToken(token));
  };

  const onBuyTokenChange = (token: TokenInfoWithPrice) => {
    if (token.address === state.receiveToken?.address) return;
    if (token.address === state.sendToken?.address) return;
    updateSearchParams({ receiveToken: token.address });
    dispatch(updateReceiveToken(token));
  };

  let error: TransactionErrorMessageProps | undefined;
  switch (true) {
    case validation.kind === "zero_sol_balance" ||
      validation.kind === "insufficient_sol" ||
      presetOverdraft.overdrafted:
      error = {
        title: "Insufficient SOL",
        message:
          state.sendToken?.address === SOL_ADDRESS &&
          state.sendToken.symbol === "SOL"
            ? transactionStatusMessage.insufficientSolNative
            : transactionStatusMessage.insufficientSolSpl,
        baseFee: SOL_TRANSACTION_FEE,
        rent: MINIMUM_SOL_BALANCE_LAMPORTS,
        priorityFee,
        color: "negative",
      };
      break;
    case validation.kind === "insufficient_funds":
      error = {
        title: "Insufficient funds",
        message: transactionStatusMessage.insufficientFunds(
          getSymbolWithDefault(state.sendToken),
        ),
        color: "negative",
      };
      break;
  }

  return (
    <div className="w-full">
      <div className="flex flex-col h-[calc(var(--h-page-tabless)-276px-var(--safe-bottom))] justify-between px-5 pt-4 w-full overflow-y-auto">
        <div className="mb-6">
          <CurrencyInput
            ref={inputRef}
            value={state.amount.input}
            aria-label={
              state.isUsd
                ? "USD amount"
                : state.sendToken
                  ? `${getSymbolWithDefault(state.sendToken)} amount`
                  : "Token amount"
            }
            currency={state.isUsd ? "USD" : state.sendToken?.symbol}
            onChange={(e) => onInputChange(e.target.value)}
            preventFocusOnClick
            onChangeIsUsd={!isFiatUnavailable ? onSwapCurrency : undefined}
            swapValue={
              state.isUsd
                ? `${formatTokenNumber(normalizeScaled(state.amount.scaledValue ?? "0", state.sendToken?.decimals ?? 9), state.sendToken?.decimals ?? 9)} ${getSymbolWithDefault(state.sendToken)}`
                : formatUsdTokenAmountValue(state.amount.fiatValue, {
                    showFractionTruncatedMark: true,
                  })
            }
            token={state.sendToken}
          />
        </div>

        <div className="flex flex-col">
          {error ? <TransactionErrorMessage {...error} /> : null}
          <div className="flex w-full flex-col mt-4">
            <Shimmer.Text
              isLoading={sendTokenBalance.isLoading}
              className="w-12"
            >
              <div className="flex justify-between items-center mb-3.5">
                {sendTokenBalance.data && state.sendToken && (
                  <p className="text-sm font-bold text-center text-gray-200">
                    {availableBalance}{" "}
                    {!state.isUsd ? getSymbolWithDefault(state.sendToken) : ""}{" "}
                    <span className="uppercase">available</span>
                  </p>
                )}
                <button
                  type="button"
                  className="border border-cloud rounded-full w-7 h-7 bg-box hover:bg-box-hover flex items-center justify-center ml-auto"
                  onClick={onSwitchToken}
                  aria-label="Switch tokens"
                >
                  <Icon name="switch-curved" className="text-primary w-4" />
                </button>
              </div>
            </Shimmer.Text>
            <ChooseTokenDrawer
              triggerLabel="Sell"
              title="Select token to sell"
              onlyWalletTokens
              solType={useWrappedSol ? "wsol" : "sol"}
              selectedToken={state.sendToken}
              onSelectToken={onSellTokenChange}
              isLoading={
                !state.sendToken &&
                (sendTokenIsLoading || defaultSendTokenInfo.isLoading)
              }
            />
            <ChooseTokenDrawer
              className="border-b border-cloud"
              triggerLabel="Buy"
              title="Select token to buy"
              showUnverified
              solType={useWrappedSol ? "wsol" : "sol"}
              excludedToken={state.sendToken}
              selectedToken={state.receiveToken}
              onSelectToken={onBuyTokenChange}
              isLoading={
                !state.receiveToken &&
                (receiveTokenIsLoading || fallbackTokenInfo.isLoading)
              }
            />
            <TotalPercentagePresets
              {...totalPercentagePresetProps}
              minimumWalletSolBalance={MINIMUM_SOL_BALANCE_LAMPORTS}
              onSelect={(dividedAmount) => {
                dispatch(updateScaledValue(dividedAmount.toString()));
              }}
            />
          </div>
          <div className="fixed bottom-0 left-0 right-0">
            {!validation.valid ? (
              <ValidationErrorButton />
            ) : isLoggedIn ? (
              <OneTimeCodePrompt>
                {({ isCodeRequired }) => (
                  <Button
                    variant="big"
                    className="w-full"
                    onClick={() => {
                      if (isCodeRequired) return;
                      if (
                        !state.sendToken ||
                        !state.receiveToken ||
                        !state.amount.scaledValue
                      ) {
                        return;
                      }
                      void navigate({
                        to: "/trade/confirm",
                      });
                    }}
                  >
                    Review
                  </Button>
                )}
              </OneTimeCodePrompt>
            ) : (
              <Button
                variant="big"
                className="w-full"
                to="/login-signup"
                search={{ redirect: getRedirectPath() }}
              >
                Connect wallet
              </Button>
            )}
            <NumericKeyboard
              onKeyClick={(key) => {
                onInputChange(
                  handleNumericKeyboardKey(
                    key,
                    state.amount.input,
                    inputRef.current,
                  ),
                );
              }}
              onBackspaceLongPress={() => {
                onInputChange("");
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
