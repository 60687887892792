import { TokenBalanceEntry, useTokenPriceQuery } from "@/api";
import {
  Drawer,
  TransactionErrorMessage,
  TransactionErrorMessageProps,
  Icon,
  Shimmer,
  TokenIcon,
} from "@/components";
import { useClipboard } from "@/hooks/useClipboard";
import {
  SOL_ADDRESS,
  SOL_DECIMALS,
  cn,
  formatAddressShort,
  formatTokenNumber,
  formatUsdTokenAmountValue,
  formatUsdTransactionFee,
  getSymbolWithDefault,
  getTokenBalanceNominalValue,
  normalizeScaled,
} from "@/utils";

interface OverviewViewProps {
  className?: string;
  flowState:
    | "confirmWithdraw"
    | "successWithdraw"
    | "errorWithdraw"
    | "loadingWithdraw"
    | "confirmDeposit"
    | "successDeposit"
    | "errorDeposit";
  address: string;
  tokenQuantityScaled: bigint;
  token: TokenBalanceEntry;
  transactionFeeLamports: bigint;
  footer: React.ReactNode;
  error?: TransactionErrorMessageProps;
}

const configMap = {
  confirmWithdraw: {
    subtitle: "Withdrawing",
    action: "Withdraw",
    total: "Total to withdraw",
    backTo: undefined,
  },
  loadingWithdraw: {
    subtitle: "Withdrawing",
    action: "Withdraw",
    total: "Total to withdraw",
    backTo: undefined,
  },
  successWithdraw: {
    subtitle: "Withdrew",
    action: "Withdrew",
    total: "Total withdrawn",
    backTo: undefined,
  },
  errorWithdraw: {
    subtitle: "Withdrawing",
    action: "Withdraw",
    total: "Total to withdraw",
    backTo: {
      to: "/withdraw/confirm",
      "aria-label": "Back to confirmation",
    },
  },
  confirmDeposit: {
    subtitle: "Depositing",
    action: undefined,
    total: "Total deposit",
    backTo: undefined,
  },
  successDeposit: {
    subtitle: "Deposited",
    action: undefined,
    total: "Total deposited",
    backTo: undefined,
  },
  errorDeposit: {
    subtitle: "Depositing",
    action: undefined,
    total: "Total deposit",
    backTo: {
      to: "/withdraw/confirm",
      "aria-label": "Back to confirmation",
    },
  },
} as const;

export function OverviewView({
  className,
  flowState,
  tokenQuantityScaled,
  token,
  address,
  transactionFeeLamports,
  footer,
  error,
}: OverviewViewProps) {
  const solPriceQuery = useTokenPriceQuery(SOL_ADDRESS);
  const tokenPriceQuery = useTokenPriceQuery(token.address);
  const [copied, copy] = useClipboard();
  const solanaFee = solPriceQuery.data?.value
    ? formatUsdTransactionFee(
        getTokenBalanceNominalValue(
          transactionFeeLamports,
          SOL_DECIMALS,
          solPriceQuery.data.value,
        ),
        { moneyMaxDecimals: 6 },
      )
    : `${normalizeScaled(transactionFeeLamports, SOL_DECIMALS)} SOL`;
  const getTokenValueUsdEq = (tokenPrice: number) =>
    getTokenBalanceNominalValue(
      tokenQuantityScaled,
      token.decimals,
      tokenPrice,
    );
  const tokenDisplayQuantity = formatTokenNumber(
    normalizeScaled(tokenQuantityScaled, token.decimals),
    token.decimals,
    { decimalsMode: "fixed" },
  );

  const config = configMap[flowState];

  return (
    <div className={cn("flex flex-col gap-7 pt-5 px-5 flex-1", className)}>
      <div className="flex flex-col items-center w-full">
        {flowState === "loadingWithdraw" && (
          <Icon
            name="circle-partial"
            className="w-[50px] h-[50px] text-primary animate-spin"
          />
        )}
        {(flowState === "successWithdraw" ||
          flowState === "successDeposit") && (
          <div className="relative w-[50px] h-[50px] flex justify-center items-center border-2 border-positive rounded-full">
            <Icon
              name="check"
              className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10 w-5 h-5 text-positive"
            />
          </div>
        )}
        {(flowState === "errorWithdraw" || flowState === "errorDeposit") && (
          <div className="relative w-[50px] h-[50px] flex justify-center items-center border-2 border-negative rounded-full">
            <Icon
              name="warning"
              className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10 w-5 h-5 text-primary"
            />
          </div>
        )}

        {(flowState === "errorDeposit" || flowState === "errorWithdraw") &&
          error && <TransactionErrorMessage {...error} />}

        <ul className="mt-9 w-full space-y-3.5">
          <li className="flex justify-between items-center w-full">
            <div className="flex flex-col">
              <span
                className={cn("text-primary text-sm font-bold uppercase", {
                  "text-negative":
                    flowState === "errorWithdraw" ||
                    flowState === "errorDeposit",
                })}
              >
                {config.action}
              </span>
              <span className="flex items-center gap-1">
                <span className="text-white text-sm font-bold">
                  {tokenDisplayQuantity} {getSymbolWithDefault(token)}
                </span>
                {token.symbol ? (
                  <span className="text-gray-200 text-left text-xxs bg-gray-700 px-1 flex items-center h-4 rounded-sm">
                    {formatAddressShort(token.address, 5)}
                  </span>
                ) : null}
              </span>
            </div>
            <div className="flex items-center gap-1">
              <TokenIcon
                logoURI={token.logoURI}
                className="w-[30px] h-[30px]"
              />
            </div>
          </li>
          <li>
            <hr className="border-cloud" />
          </li>
          <li className="flex justify-between items-center w-full">
            <span className="text-primary text-sm font-bold uppercase">TO</span>
            <div className="flex items-center gap-1">
              <span className="text-white text-sm font-bold">
                {formatAddressShort(address, 5)}
              </span>
              <button
                aria-label="Copy address"
                onClick={() => {
                  void copy(address);
                }}
              >
                <Icon
                  name={copied ? "check" : "copy"}
                  className="w-4 h-4 text-white"
                />
              </button>
            </div>
          </li>
          <li className="flex justify-between items-center w-full">
            <span className="text-primary text-sm font-bold uppercase flex items-center gap-1">
              Solana Fee{" "}
              <Drawer.Root>
                <Drawer.Trigger>
                  <Icon name="info" className="w-4 h-4 text-gray-200" />
                </Drawer.Trigger>
                <Drawer.Content title="Solana Fee">
                  <p className="text-gray-200 text-sm">
                    The Solana fee is the required payment for transaction
                    processing on the Solana network, payable in SOL.
                  </p>
                </Drawer.Content>
              </Drawer.Root>
            </span>

            <Shimmer.Text
              isLoading={solPriceQuery.isLoading}
              className="h-[21px]"
            >
              <span className="text-white text-sm font-bold">{solanaFee}</span>
            </Shimmer.Text>
          </li>
          <li>
            <hr className="border-cloud" />
          </li>
        </ul>

        <div className="w-full flex flex-col gap-1">
          <span className="mt-3.5 text-primary text-sm font-bold uppercase">
            {config.total}
          </span>
          <Shimmer.Text
            isLoading={tokenPriceQuery.isLoading}
            className="h-[48px]"
          >
            <span className="text-white text-3xl font-bold">
              {tokenPriceQuery.data?.value ? (
                formatUsdTokenAmountValue(
                  getTokenValueUsdEq(tokenPriceQuery.data.value),
                  {
                    showFractionTruncatedMark: true,
                  },
                )
              ) : (
                <>
                  {tokenDisplayQuantity}{" "}
                  {formatSymbol(getSymbolWithDefault(token))}
                </>
              )}
            </span>
          </Shimmer.Text>
        </div>
      </div>

      {footer}
    </div>
  );
}

function formatSymbol(symbol: string) {
  return symbol.length > 6 ? (
    <span className="text-base">{symbol}</span>
  ) : (
    symbol
  );
}
