import { Drawer } from "vaul";
import type { DialogTriggerProps } from "@radix-ui/react-dialog";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { Dialog } from "@/components";

export function Trigger(props: DialogTriggerProps) {
  const isDesktopAndUp = useMediaQuery("(min-width: 1024px)");
  return isDesktopAndUp ? (
    <Dialog.Trigger {...props} />
  ) : (
    <Drawer.Trigger {...props} />
  );
}
