import {
  isWeb3authBuildAndSendTransactionError,
  usePhaseZeroUserQuery,
} from "@/api";
import { useWeb3authSendAndConfirmTransactionMutation } from "@/app/web3auth";
import {
  Button,
  TransactionErrorMessage,
  TransactionErrorMessageProps,
  Icon,
  Page,
  Shimmer,
  Token,
  TransferIndicator,
  WalletCard,
} from "@/components";
import { with404 } from "@/hocs/with404";
import { withDynamicProtected } from "@/hocs/withDynamicProtected";
import { withWeb3authProtected } from "@/hocs/withWeb3authProtected";
import { useDynamicWallet } from "@/hooks";
import { useMigrationStatus } from "@/hooks/useMigrationStatus";
import { useWeb3AuthClient } from "@/hooks/useWeb3AuthClient";
import { useWeb3authPortfolio } from "@/hooks/useWeb3authPortfolio";
import {
  toggleToken,
  setIsTransferDelayed,
} from "@/routes/login-signup/-reducer";
import { useAppDispatch, useTypedSelector } from "@/store";
import {
  SOL_ADDRESS,
  DISABLE_MIGRATION_FLOW,
  transactionStatusMessage,
  TOKEN_ACCOUNT_RENT,
  NATIVE_SOL_ACCOUNT_RENT,
  SOL_TRANSACTION_FEE,
  getIsSlippageLimitReached,
  normalizeScaled,
  SOL_DECIMALS,
  cn,
} from "@/utils";
import { compose } from "@reduxjs/toolkit";
import {
  RouteComponent,
  createFileRoute,
  useNavigate,
} from "@tanstack/react-router";
import { useEffect, useState } from "react";

export const Route = createFileRoute("/migrate/tokens/")({
  component: DISABLE_MIGRATION_FLOW
    ? with404(Tokens)
    : compose<RouteComponent>(
        withWeb3authProtected({ requireTkeyPassword: true }),
        withDynamicProtected,
      )(Tokens),
});

function useValidateWallet() {
  const navigate = useNavigate();
  const phaseZeroUserQuery = usePhaseZeroUserQuery();
  const [client] = useWeb3AuthClient();
  const userPhaseZero = phaseZeroUserQuery.data;

  useEffect(() => {
    if (
      client?.walletAddress &&
      userPhaseZero?.wallet &&
      userPhaseZero.wallet !== client.walletAddress
    ) {
      console.log(
        `Web3Auth wallet=${client.walletAddress} phaseZeroWallet=${userPhaseZero.wallet}`,
      );
      void navigate({
        to: "/migrate/invalid",
        replace: true,
        search: (prev) => prev,
      });
    }
  }, [client, navigate, userPhaseZero]);
}

function Tokens() {
  useValidateWallet();
  const [hideBlur, setHideBlur] = useState(false);
  const dispatch = useAppDispatch();
  const { priorityFee, web3authSelectedToken, isTransferDelayed } =
    useTypedSelector((state) => ({
      web3authSelectedToken: state.loginSignup.web3authSelectedToken,
      isTransferDelayed: state.loginSignup.isTransferDelayed,
      priorityFee: BigInt(state.profile.priorityFee),
    }));
  const { completeMigrationMutation, handleSkip, handleComplete } =
    useMigrationStatus();
  const [web3authClient] = useWeb3AuthClient();
  const dynamicWallet = useDynamicWallet()?.address;
  const web3AuthWallet = web3authClient?.walletAddress;
  const { tokens, tokensQuery, web3authTokensQuery } = useWeb3authPortfolio();

  const solToken = tokens?.find(
    (token) => token.address === SOL_ADDRESS && token.symbol === "SOL",
  );
  const hasSplTokens = solToken && (tokens?.length ?? 0) > 1;
  const solBalance = BigInt(solToken?.quantity ?? "0");

  const [sendAndConfirmTransaction, sendAndConfirmTransactionMutation] =
    useWeb3authSendAndConfirmTransactionMutation();
  let signature = sendAndConfirmTransactionMutation.data?.signature;

  const handleTransfer = async () => {
    dispatch(setIsTransferDelayed(false));
    const transfers = tokens
      ?.filter(
        (token) =>
          web3authSelectedToken?.address === token.address &&
          web3authSelectedToken.symbol === token.symbol,
      )
      .map((token) => ({
        tokenAddress: token.address,
        amount: token.quantity,
        decimals: token.decimals,
        symbol: token.symbol,
      }));
    if (!transfers || !web3AuthWallet || !dynamicWallet) return;

    const id = setTimeout(() => {
      dispatch(setIsTransferDelayed(true));
    }, 30_000);
    try {
      const result = await sendAndConfirmTransaction({
        from: web3AuthWallet,
        to: dynamicWallet,
        transfers,
        priorityFee,
      });

      if ("data" in result && web3authSelectedToken) {
        dispatch(toggleToken(web3authSelectedToken));
      }
    } finally {
      clearTimeout(id);
    }
  };

  let allowTryAgain = false;
  let error: TransactionErrorMessageProps | undefined;
  if (
    isWeb3authBuildAndSendTransactionError(
      sendAndConfirmTransactionMutation.error,
    )
  ) {
    switch (sendAndConfirmTransactionMutation.error.kind) {
      case "cancel_signature":
        sendAndConfirmTransactionMutation.reset();
        break;
      case "failed_to_sign":
        error = {
          title: "FAILED TO SIGN TRANSACTION",
          message: transactionStatusMessage.failedToSignTransaction,
          color: "negative",
          feeStatus: "not_charged",
        };
        break;
      case "failed_to_fetch_latest_blockhash":
        allowTryAgain = true;
        error = {
          title: "TRANSACTION NOT SENT",
          message: transactionStatusMessage.failedToFetchLatestBlockhash,
          color: "negative",
          feeStatus: "not_charged",
        };
        break;
      case "not_simulated":
        allowTryAgain = true;
        error = {
          title: "TRANSACTION NOT SENT",
          message: transactionStatusMessage.notSimulated,
          color: "negative",
          feeStatus: "not_charged",
        };
        break;
      case "simulation_failed":
        allowTryAgain = true;
        error = {
          title: "TRANSACTION NOT SENT",
          message: transactionStatusMessage.simulationFailed(
            sendAndConfirmTransactionMutation.error.data.value,
          ),
          color: "negative",
          feeStatus: "not_charged",
        };
        break;
      case "unhandled_error":
        error = {
          title: "AN ERROR OCCURRED",
          message: transactionStatusMessage.unhandledError(
            sendAndConfirmTransactionMutation.error.data,
          ),
          color: "negative",
          feeStatus: "unknown",
        };
        break;
      case "web3auth_not_initialized":
        error = {
          title: "TRANSACTION NOT SENT",
          message: transactionStatusMessage.web3authNotInitialized,
          color: "negative",
          feeStatus: "not_charged",
        };
        break;
      default: {
        const _exhaustiveCheck: never = sendAndConfirmTransactionMutation.error;
      }
    }
  }

  const titles = {
    tokens: "Transfer your assets",
    loading: "Transferring...",
    unknown: "Transfer status unknown",
    success: "Success!",
    error: "Transfer failed",
  } as const;
  let view: "error" | "success" | "loading" | "tokens";
  let title: (typeof titles)[keyof typeof titles];
  if (sendAndConfirmTransactionMutation.isLoading) {
    view = "loading";
    title = titles.loading;
  } else if (error) {
    view = "error";
    title = titles.error;
  } else {
    view = "tokens";
    title = titles.tokens;
  }

  if (sendAndConfirmTransactionMutation.data) {
    switch (sendAndConfirmTransactionMutation.data.status) {
      case "success":
        view = "success";
        title = titles.success;
        break;
      case "failed":
        view = "error";
        title = titles.error;
        if (
          getIsSlippageLimitReached(
            sendAndConfirmTransactionMutation.data.transactionError,
          )
        ) {
          error = {
            title: "SLIPPAGE LIMIT REACHED",
            message: transactionStatusMessage.slippageLimitReached,
            feeStatus: "charged",
            color: "negative",
          };
        } else {
          error = {
            title: "TRANSACTION FAILED",
            message: transactionStatusMessage.transactionFailed,
            feeStatus: "charged",
            color: "negative",
          };
        }
        break;
      case "expired":
        view = "error";
        title = titles.error;
        error = {
          title: "TRANSACTION EXPIRED",
          message: transactionStatusMessage.transactionExpired,
          feeStatus: "not_charged",
          color: "negative",
        };
        signature = undefined;
        allowTryAgain = true;
        break;
      case "unknown":
        view = "error";
        title = titles.unknown;
        error = {
          title: "TRANSACTION STATUS UNKNOWN",
          message: transactionStatusMessage.transactionStatusUnknown,
          feeStatus: "unknown",
          color: "negative",
        };

        break;
      default: {
        const _exhaustiveCheck: never = sendAndConfirmTransactionMutation.data;
      }
    }
  }

  const requiredSolBalance =
    (hasSplTokens ? TOKEN_ACCOUNT_RENT : NATIVE_SOL_ACCOUNT_RENT) +
    SOL_TRANSACTION_FEE +
    priorityFee;
  let additionalMessage: string | undefined;

  if (
    !error &&
    solBalance < requiredSolBalance &&
    !tokensQuery.isLoading &&
    !web3authTokensQuery.isLoading
  ) {
    error = {
      title: "Insufficient SOL",
      message:
        web3authSelectedToken?.address === SOL_ADDRESS &&
        web3authSelectedToken.symbol === "SOL"
          ? transactionStatusMessage.insufficientSolNative
          : transactionStatusMessage.insufficientSolSpl,
      baseFee: SOL_TRANSACTION_FEE,
      priorityFee,
      rent: hasSplTokens ? TOKEN_ACCOUNT_RENT : NATIVE_SOL_ACCOUNT_RENT,
      color: "negative",
    };
    additionalMessage = `Deposit ${normalizeScaled((hasSplTokens ? TOKEN_ACCOUNT_RENT : NATIVE_SOL_ACCOUNT_RENT) + priorityFee + SOL_TRANSACTION_FEE, SOL_DECIMALS)} SOL to Old Account to proceed.`;
  } else if (!error && hasSplTokens) {
    error = {
      color: "warning",
      title: "Remaining SPL tokens",
      message:
        "You have remaining SPL tokens. If you transfer all of your SOL, you will need to deposit SOL again to make further transfers.",
    };
  }

  const isFetching = tokensQuery.isFetching || web3authTokensQuery.isFetching;

  return (
    <Page flex hideTabs title={title}>
      {view === "tokens" && (
        <div className="flex-1 flex flex-col justify-between items-center px-5 py-3">
          {error ? (
            <>
              {additionalMessage ? (
                <span className="text-sm text-gray-200 mb-3">
                  {additionalMessage}
                </span>
              ) : null}
              <TransactionErrorMessage {...error} />
            </>
          ) : null}
          <div className="w-full flex items-center shrink-0 m-5">
            <h1 className="text-sm font-bold text-white uppercase">
              Select Assets
            </h1>
          </div>
          <ul
            className="mb-5 shrink h-full w-full overflow-y-scroll"
            onScroll={(e) => {
              const el = e.target as HTMLUListElement;
              const scrollY = el.scrollTop + el.offsetHeight;
              setHideBlur(scrollY >= el.scrollHeight - 10);
            }}
          >
            {tokens?.length === 0 ? (
              <li className="text-center text-sm text-gray-200">
                You don&apos;t have any tokens.
              </li>
            ) : null}
            {isFetching ? (
              <Shimmer.List rows={3} />
            ) : (
              tokens?.map((token) => (
                <li
                  key={token.address + (token.symbol ?? "")}
                  className="mb-2.5"
                >
                  <button
                    className="w-full flex justify-between items-center gap-5 rounded-xl border border-white/10 px-2.5 bg-box"
                    aria-label={`Toggle ${token.name}`}
                    onClick={() => {
                      dispatch(
                        toggleToken({
                          address: token.address,
                          symbol: token.symbol,
                        }),
                      );
                    }}
                  >
                    <Token
                      {...token}
                      compact
                      priceChange24h={undefined}
                      price={token.usdTotal}
                      quantityDetails={{
                        value: token.quantity,
                        decimals: token.decimals,
                      }}
                    />

                    <Icon
                      name={
                        web3authSelectedToken?.address === token.address &&
                        web3authSelectedToken.symbol === token.symbol
                          ? "checkbox-on"
                          : "checkbox-off"
                      }
                      className="w-5 h-5 text-primary"
                    />
                  </button>
                </li>
              ))
            )}
          </ul>

          {dynamicWallet && web3AuthWallet ? (
            <div className="relative w-full mb-10 flex items-center gap-1.5 shrink-0">
              <div
                className={cn(
                  "absolute -top-[90px] w-full h-[80px] bg-gradient-to-t from-gray-600 to-transparent z-10 pointer-events-none",
                  {
                    "hidden": hideBlur,
                  },
                )}
              />
              <WalletCard
                compact
                address={web3AuthWallet}
                label="Old Account"
              />
              <Icon
                name="arrow"
                className="w-3.5 h-3.5 text-accent rotate-90 flex-shrink-0"
              />
              <WalletCard compact address={dynamicWallet} label="New Account" />
            </div>
          ) : null}
          <Button
            variant="cta"
            className="w-full shrink-0"
            onClick={handleTransfer}
            disabled={
              completeMigrationMutation.isLoading ||
              web3authSelectedToken === undefined ||
              solBalance < requiredSolBalance
            }
          >
            Transfer
          </Button>
          <Button
            variant="flat"
            color="dark"
            className="w-full shrink-0"
            onClick={handleSkip}
            disabled={completeMigrationMutation.isLoading}
          >
            Proceed without transferring
          </Button>
        </div>
      )}
      {view === "loading" && (
        <div className="flex-1 flex flex-col items-center px-5 py-3">
          <Icon name="dflow-logo" className="w-12 h-12 text-accent" />
          {dynamicWallet && web3AuthWallet ? (
            <div className="mt-9 w-full flex flex-col gap-5">
              <WalletCard
                hideIcon
                address={web3AuthWallet}
                label="Old Account"
              />
              <TransferIndicator type="loading" />
              <WalletCard
                hideIcon
                address={dynamicWallet}
                label="New Account"
              />
            </div>
          ) : null}
          {isTransferDelayed && (
            <div className="mt-9 w-full flex flex-col items-center gap-1">
              <Icon name="warning" className="w-5 h-5 text-primary" />
              <p className="text-center text-xs text-accent font-bold">
                Your transfer is taking longer than expected....
              </p>
            </div>
          )}
        </div>
      )}
      {view === "error" && (
        <div className="flex-1 w-full flex flex-col justify-between items-center px-5 pb-4">
          <div className="mt-5 w-full flex flex-col items-center gap-1">
            <div className="w-12 h-12 rounded-full border-2 border-negative flex justify-center items-center">
              <Icon name="warning" className="w-5 h-5 text-primary" />
            </div>
            <div className="mt-9 w-full flex flex-col gap-5 items-center">
              {error ? <TransactionErrorMessage {...error} /> : null}
              {dynamicWallet && web3AuthWallet ? (
                <div className="w-full flex flex-col gap-5">
                  <WalletCard
                    hideIcon
                    address={web3AuthWallet}
                    label="Old Account"
                  />
                  <TransferIndicator type="error" />
                  <WalletCard
                    hideIcon
                    address={dynamicWallet}
                    label="New Account"
                  />
                </div>
              ) : null}
            </div>
          </div>

          <div className="w-full flex flex-col">
            {allowTryAgain ? (
              <Button
                variant="cta"
                className="w-full"
                onClick={handleTransfer}
                disabled={
                  completeMigrationMutation.isLoading ||
                  web3authSelectedToken === undefined ||
                  solBalance < requiredSolBalance
                }
              >
                Try again
              </Button>
            ) : (
              <Button
                variant="cta"
                className="w-full"
                onClick={() => sendAndConfirmTransactionMutation.reset()}
                disabled={
                  completeMigrationMutation.isLoading ||
                  web3authSelectedToken === undefined
                }
              >
                Go back
              </Button>
            )}
            {signature && (
              <Button
                variant="flat"
                color="dark"
                className="w-full"
                onClick={() => {
                  window.open(`https://solscan.io/tx/${signature}`, "_blank");
                }}
                icon={<Icon className="w-5" name="link" />}
              >
                View on Explorer
              </Button>
            )}
          </div>
        </div>
      )}
      {view === "success" && (
        <div className="flex-1 w-full flex flex-col justify-between items-center px-5">
          <div className="mt-5 w-full flex flex-col items-center gap-9">
            <div className="w-12 h-12 rounded-full border-2 border-positive flex justify-center items-center">
              <Icon name="check" className="w-5 h-5 text-positive" />
            </div>
            <p className="text-gray-200 text-sm text-center">
              Transferred assets successfully.
              <br />
              Proceed to the app to see your portfolio.
            </p>
            <div className="w-full flex flex-col gap-5 items-center">
              {dynamicWallet && web3AuthWallet ? (
                <div className="w-full flex flex-col gap-5">
                  <WalletCard
                    hideIcon
                    address={web3AuthWallet}
                    label="Old Account"
                  />
                  <WalletCard
                    hideIcon
                    address={dynamicWallet}
                    label="New Account"
                  />
                </div>
              ) : null}
            </div>
          </div>

          <div className="w-full flex flex-col">
            <Button variant="cta" className="w-full" onClick={handleComplete}>
              Proceed to the app
            </Button>
            <Button
              variant="flat"
              color="dark"
              className="w-full"
              onClick={() => {
                sendAndConfirmTransactionMutation.reset();
              }}
            >
              TRANSFER AGAIN
            </Button>
          </div>
        </div>
      )}
    </Page>
  );
}
