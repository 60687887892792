import { cn } from "@/utils";
import { PropsWithChildren } from "react";

interface ShimmerProps {
  className?: string;
  style?: React.CSSProperties;
}

const ShimmerInner = ({ className, style }: ShimmerProps) => {
  return (
    <div
      className={cn(
        "bg-shimmer-gradient bg-no-repeat animate-shimmer bg-[length:1000%_100%]",
        className,
      )}
      style={style}
    />
  );
};

interface ShimmerTextProps extends PropsWithChildren<ShimmerProps> {
  isLoading: boolean;
}

const Text = ({ children, isLoading, ...props }: ShimmerTextProps) => {
  if (isLoading)
    return (
      <>
        <Shimmer {...props} />
        <span className="sr-only">Loading...</span>
      </>
    );
  return children;
};

interface ShimmerTableProps extends ShimmerTextProps {
  height: number;
}

const Table = ({
  children,
  isLoading,
  className,
  height,
}: ShimmerTableProps) => {
  if (isLoading) {
    const rowsLen = Math.floor((height - (45 - 10)) / 73.75);
    return (
      <div className={cn("flex flex-col gap-1", className)}>
        <Shimmer className="h-[45px] mb-1 w-full" />
        {Array.from({ length: rowsLen }).map((_, i) => (
          <Shimmer key={`shimmer-table-${i}`} className="w-full h-[80px]" />
        ))}
        <span className="sr-only">Loading...</span>
      </div>
    );
  }
  return children;
};

interface ListShimmerProps extends ShimmerProps {
  rows?: number;
  variant?: "default" | "cards";
  rowClassName?: string;
}

const List = ({
  className,
  rows = 3,
  variant = "default",
  rowClassName,
}: ListShimmerProps) => {
  return (
    <div role="status" className={cn("flex flex-col gap-2 pt-2", className)}>
      {Array.from({ length: rows }).map((_, index) => (
        <div
          key={index}
          className={cn(
            "flex justify-between rounded-lg loading-skeleton",
            {
              "items-end pt-4 pb-6 px-2": variant === "default",
              "items-center px-5 py-4 bg-gray-500": variant === "cards",
            },
            rowClassName,
          )}
        >
          {variant === "cards" ? (
            <>
              <div className="rounded-full bg-gray-700 h-6 w-6 shrink-0" />
              <div className="flex flex-col w-full ml-4">
                <div className="w-2/3 h-2.5 rounded-full bg-gray-700 mb-2.5"></div>
                <div className="w-4/5 h-2 rounded-full bg-gray-700"></div>
              </div>
            </>
          ) : (
            <>
              <div>
                <div className="h-2.5 rounded-full bg-gray-600 w-24 mb-2"></div>
                <div className="w-32 h-2 rounded-full bg-gray-700"></div>
              </div>
              <div className="h-2.5 rounded-full bg-gray-700 w-12"></div>
            </>
          )}
        </div>
      ))}
      <span className="sr-only">Loading...</span>
    </div>
  );
};

export const Shimmer = Object.assign(ShimmerInner, {
  Text,
  Table,
  List,
});
