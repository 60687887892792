import { BaseIconProps } from "../icon";

export function WarningIcon(props: BaseIconProps) {
  return (
    <svg
      width="82"
      height="82"
      viewBox="0 0 82 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M36.8824 3.27979H46.7224L81.1624 63.6318V78.7198H2.44238V63.6318L36.8824 3.27979Z"
        fill="#1D1E20"
      />
      <path
        d="M46.7224 3.27979H36.8824L2.44238 63.6318V78.7198H81.1624V63.6318L46.7224 3.27979ZM46.724 68.6613H36.884V58.6026H46.724V68.6613ZM46.724 48.5439H36.884V23.3973H46.724V48.5439Z"
        fill="currentColor"
      />
    </svg>
  );
}
