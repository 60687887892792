import { cn } from "@/utils";
import { Icon } from "../icon";
import { useState } from "react";
import { Shimmer } from "../shimmer";

interface ProfileImageProps {
  profileImage?: string | null;
  size: "large" | "medium";
}

function twitterBiggerImageVariant(profileImage: string) {
  return profileImage.replace("_normal.", "_400x400.");
}

export function ProfileImage({ profileImage, size }: ProfileImageProps) {
  const [isImageLoading, setIsImageLoading] = useState(true);
  const url = profileImage
    ? twitterBiggerImageVariant(profileImage)
    : "/duck.png";

  return (
    <div className="relative w-fit h-fit shrink-0">
      {isImageLoading && (
        <Shimmer.Text
          className={cn("rounded-full", {
            "w-[42px] h-[42px]": size === "medium",
            "w-[70px] h-[70px]": size === "large",
          })}
          isLoading
        />
      )}
      <img
        onLoad={() => setIsImageLoading(false)}
        onError={() => setIsImageLoading(false)}
        src={url}
        alt="Profile Image"
        className={cn("rounded-full", {
          "hidden": isImageLoading,
          "w-[42px] h-[42px]": size === "medium",
          "w-[70px] h-[70px]": size === "large",
        })}
      />
      {profileImage ? (
        <div className="absolute bottom-0 right-0 p-[1px] bg-box rounded-full">
          <Icon
            name="verified"
            className={cn("text-positive", {
              "w-3.5 h-3.5": size === "medium",
              "w-5 h-5": size === "large",
            })}
          />
        </div>
      ) : null}
    </div>
  );
}
