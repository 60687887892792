import { BaseIconProps } from "../icon";

export function HandSwipeIcon(props: BaseIconProps) {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.1164 4.28144C11.4887 4.28144 10.1471 5.61724 10.1471 7.2379V18.8789L9.49754 18.2014L9.2501 17.9858C8.10954 16.8502 6.24598 16.8502 5.10542 17.9858C3.96486 19.1214 3.96486 20.9769 5.10542 22.1125V22.1433L13.2092 30.1196L13.2711 30.1504L13.302 30.212C14.6359 31.209 16.337 31.875 18.2509 31.875H19.952C24.4408 31.875 28.0558 28.2757 28.0558 23.8063V16.1073C28.0558 14.4866 26.7142 13.1508 25.0865 13.1508C24.665 13.1508 24.2746 13.2663 23.9111 13.428C23.5864 12.1461 22.4149 11.1798 21.0346 11.1798C20.2768 11.1798 19.5809 11.4762 19.055 11.9497C18.5292 11.4762 17.8333 11.1798 17.0755 11.1798C16.7275 11.1798 16.3989 11.253 16.0857 11.3646V7.2379C16.0857 5.61724 14.7441 4.28144 13.1164 4.28144ZM13.1164 6.25241C13.6654 6.25241 14.1062 6.69126 14.1062 7.2379V18.0782H16.0857V14.1363C16.0857 13.5896 16.5265 13.1508 17.0755 13.1508C17.6245 13.1508 18.0653 13.5896 18.0653 14.1363V18.0782H20.0448V14.1363C20.0448 13.5896 20.4856 13.1508 21.0346 13.1508C21.5836 13.1508 22.0244 13.5896 22.0244 14.1363V18.0782H24.0967V16.1073C24.0967 15.5606 24.5375 15.1218 25.0865 15.1218C25.6355 15.1218 26.0762 15.5606 26.0762 16.1073V23.8063C26.0762 27.2209 23.3814 29.904 19.952 29.904H18.2509C16.801 29.904 15.5483 29.3882 14.5083 28.6106L6.49729 20.7267C6.05653 20.2879 6.05653 19.8105 6.49729 19.3717C6.93805 18.9328 7.41747 18.9328 7.85823 19.3717L12.1266 23.6216V7.2379C12.1266 6.69126 12.5674 6.25241 13.1164 6.25241Z"
        fill="currentColor"
      />
      <path
        d="M22.2538 3.81814C29.9662 5.30533 29.7494 10.5335 29.7494 10.5335C29.7494 10.5335 28.6186 8.1196 21.6775 6.78112L21.3481 8.47426L17.8327 4.41835L22.5831 2.125L22.2538 3.81814Z"
        fill="currentColor"
      />
    </svg>
  );
}
