import { useState, useRef } from "react";

export function useClipboard(options: { timeout: number } = { timeout: 2000 }) {
  const [copied, setCopied] = useState(false);

  const timeout = useRef<NodeJS.Timeout>();

  const copy = async (content: string) => {
    clearTimeout(timeout.current);
    await navigator.clipboard.writeText(content);
    setCopied(true);
    timeout.current = setTimeout(() => {
      setCopied(false);
    }, options.timeout);
  };

  return [copied, copy] as const;
}
