import { useState } from "react";
import { useDispatch } from "react-redux";
import type { TokenInfoWithPrice } from "@/api";
import { Button, Drawer, Icon, TokenIcon, Link } from "@/components";
import { formatAddressShort } from "@/utils";
import { updateHideUnverifiedTokenModal } from "../profile/-reducer";

interface VerifyTokenDrawerProps {
  token?: TokenInfoWithPrice;
  onSelectToken: (token: TokenInfoWithPrice) => void;
  isOpen: boolean;
  onClose: () => void;
}

export function VerifyTokenDrawer({
  token,
  onSelectToken,
  isOpen,
  onClose,
}: VerifyTokenDrawerProps) {
  const dispatch = useDispatch();
  const [localHideUnverifiedTokenModal, setLocalHideUnverifiedTokenModal] =
    useState(false);

  return (
    <Drawer.Root
      open={isOpen}
      onOpenChange={(open) => {
        if (!open) {
          onClose();
        }
      }}
    >
      <Drawer.Content title="Confirm Token Selection">
        <div className="flex flex-col gap-4">
          <TokenIcon
            alt=""
            logoURI={token?.logoURI}
            showUnverified
            className="w-[60px] h-[60px] mx-auto"
          />
          <Link
            to={`https://solscan.io/token/${token?.address}`}
            target="_blank"
            className="mx-auto w-fit text-white text-sm bg-gray-700 p-1 flex items-center rounded-sm leading-4"
          >
            {formatAddressShort(token?.address ?? "", 5)}
            <Icon name="link" className="w-3 h-3 text-primary ml-1" />
          </Link>
          <p className="text-gray-200 text-sm text-center">
            This token is not verified.
            <br />
            Please confirm its address to proceed.
          </p>
          <Drawer.Close asChild>
            <Button
              className="w-full"
              variant="cta"
              onClick={() => {
                if (!token) {
                  return;
                }
                onClose();
                onSelectToken(token);
                dispatch(
                  updateHideUnverifiedTokenModal(localHideUnverifiedTokenModal),
                );
              }}
            >
              Confirm
            </Button>
          </Drawer.Close>

          <button
            className="flex items-center gap-2.5"
            aria-label="Toggle solana chain warning"
            onClick={() => {
              setLocalHideUnverifiedTokenModal((v) => !v);
            }}
          >
            <Icon
              name={localHideUnverifiedTokenModal ? "circle-check" : "circle"}
              className="w-5 h-5 text-primary"
            />
            <span className="text-sm text-white font-bold">
              Don’t show this warning again
            </span>
          </button>
        </div>
      </Drawer.Content>
    </Drawer.Root>
  );
}
