import { cn } from "@/utils";
import * as RadixTabs from "@radix-ui/react-tabs";
import type { TabsProps as RadixTabsProps } from "@radix-ui/react-tabs";

interface Tab {
  label: string;
  value?: string;
  content: React.ReactNode;
  contentRef?: React.RefObject<HTMLDivElement>;
  footer?: React.ReactNode;
}

interface TabsProps {
  "tabs": Tab[];
  "aria-label"?: string;
}

export const ContentTabs = ({
  tabs,
  "aria-label": ariaLabel,
  ...props
}: TabsProps & RadixTabsProps) => {
  return (
    <RadixTabs.Root
      defaultValue={
        props.defaultValue ? props.defaultValue : tabs.length ? "0" : undefined
      }
      className="pt-tab-header"
      {...props}
    >
      <RadixTabs.List
        aria-label={ariaLabel}
        className="px-5 fixed h-tab-header top-header left-0 right-0 border-b border-cloud bg-gray-600 z-20 whitespace-nowrap"
      >
        {tabs.map((tab, index) => (
          <RadixTabs.Trigger
            value={tab.value ? tab.value : index.toString()}
            key={index}
            className="uppercase font-bold text-sm py-2 mr-6 border-b border-transparent transition-colors text-gray-200 hover:text-white hover:border-white aria-selected:text-primary aria-selected:border-primary"
          >
            {tab.label}
          </RadixTabs.Trigger>
        ))}
      </RadixTabs.List>
      {tabs.map((tab, index) => (
        <RadixTabs.Content
          ref={tab.contentRef}
          value={tab.value ? tab.value : index.toString()}
          key={index}
          className={cn(
            "overflow-y-auto h-[calc(var(--h-page)-var(--h-tab-header))] overscroll-contain",
            tab.footer &&
              "h-[calc(var(--h-page)-var(--h-tab-header)-var(--h-button-row))] overscroll-contain",
          )}
        >
          {tab.content}
          {tab.footer}
        </RadixTabs.Content>
      ))}
    </RadixTabs.Root>
  );
};
