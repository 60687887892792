import * as RadixDialog from "@radix-ui/react-dialog";
import { DialogContent } from "./content";

export const Dialog = {
  Root: RadixDialog.Root,
  Trigger: RadixDialog.Trigger,
  Content: DialogContent,
  Description: RadixDialog.Description,
  Close: RadixDialog.Close,
};
