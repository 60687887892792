import { BaseIconProps } from "../icon";

export function ChevronIcon(props: BaseIconProps) {
  return (
    <svg
      viewBox="0 0 11 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.23599 0.300049L5.42075 3.57827L1.6055 0.300049L0.0234375 1.66094L5.42075 6.30005L10.8234 1.66094L9.23599 0.300049Z"
        fill="currentColor"
      />
    </svg>
  );
}
