import { TokenInfoWithPrice } from "@/api";
import { addTokenToCache } from "@/app/token-cache/token-cache-actions";
import { Icon, Shimmer, TokenIcon } from "@/components";
import { useAppDispatch } from "@/store";
import {
  formatAbsolutePercent,
  formatAddressShort,
  formatTokenNumber,
  normalizeScaled,
  formatUsdMarketPrice,
  cn,
} from "@/utils";
import clsx from "clsx";

interface PriceChangeProps {
  priceChange24h: number | null;
  orientation: "left" | "right";
}

function PriceChange({ priceChange24h, orientation }: PriceChangeProps) {
  const orientationClass = { "justify-end": orientation === "right" };
  if (typeof priceChange24h !== "number") {
    return (
      <p
        className={cn(
          "flex font-bold text-gray-200 items-center",
          orientationClass,
        )}
      >
        N/A
      </p>
    );
  }

  return (
    <p
      className={clsx("font-bold flex items-center", {
        "text-positive": priceChange24h >= 0,
        "text-negative": priceChange24h < 0,
        ...orientationClass,
      })}
    >
      <Icon
        name="arrow"
        className={clsx("w-2 h-2 mr-1", priceChange24h < 0 && "rotate-180")}
      />
      {formatAbsolutePercent(priceChange24h)}
    </p>
  );
}

interface TokenProps extends TokenInfoWithPrice {
  volume?: number;
  priceChange24hLeft?: boolean;
  quantityDetails?: { value: string; decimals: number };
  isSelected?: boolean;
  showUnverified?: boolean;
  compact?: boolean;
  descriptionId?: string;
  isPriceLoading?: boolean;
}

export const Token = ({
  priceChange24hLeft,
  quantityDetails,
  isSelected,
  showUnverified,
  compact,
  descriptionId,
  isPriceLoading = false,
  ...token
}: TokenProps) => {
  const dispatch = useAppDispatch();
  const { address, name, symbol, logoURI, price, priceChange24h, verified } =
    token;
  return (
    <div
      id={address}
      className={cn(
        "w-full text-sm sm:text-base flex gap-2.5 py-5 leading-none",
        {
          "py-3.5": compact,
        },
      )}
      onClick={() => {
        // When a user clicks on a token, we add it to the device storage
        // so that it can be accessed in the future without needing to fetch
        dispatch(addTokenToCache(token));
      }}
    >
      <div className="relative w-6 h-6">
        <TokenIcon
          // Pass a key to this component so that it doesn't display the previous
          // token icon while the new one loads in the virtual list
          key={address}
          logoURI={logoURI}
          alt=""
          className="min-w-6 w-6 h-6"
        />
        {showUnverified && !verified ? (
          <Icon
            name="warning"
            className="w-3 h-3 text-accent absolute -right-[1px] -bottom-[3px]"
          />
        ) : null}
      </div>
      <div className={"flex-1 flex flex-col gap-1"}>
        {/* If last child, add margin to center line with icon */}
        <div className="flex items-center gap-1.5 last:mt-1.5">
          {name ? (
            <p className="font-bold max-w-[150px] h-[15px] leading-none overflow-hidden text-ellipsis whitespace-nowrap text-sm">
              {name}
            </p>
          ) : (
            <p className="font-bold max-w-[150px] h-[15px] leading-none overflow-hidden whitespace-nowrap text-sm">
              {formatAddressShort(address, 5)}
            </p>
          )}
          {isSelected ? (
            <Icon name="circle-check" className="w-4 h-4 text-primary inline" />
          ) : (
            ""
          )}
        </div>

        {priceChange24hLeft && priceChange24h !== undefined ? (
          <Shimmer.Text
            className="h-[14px] max-w-[120px]"
            isLoading={isPriceLoading}
          >
            <PriceChange orientation="left" priceChange24h={priceChange24h} />
          </Shimmer.Text>
        ) : symbol ? (
          <div className="flex items-center gap-1.5" id={descriptionId}>
            <p className="text-gray-200 text-left text-sm">{symbol}</p>
            <p className="text-gray-200 text-left text-xxs bg-gray-700 px-1 flex items-center h-4 rounded-sm">
              {formatAddressShort(address, 5)}
            </p>
          </div>
        ) : null}
      </div>
      <div className="text-right flex flex-col gap-1">
        <Shimmer.Text className="h-[14px] w-[100px]" isLoading={isPriceLoading}>
          {typeof price === "number" ? (
            <p className="font-bold text-sm">{formatUsdMarketPrice(price)}</p>
          ) : (
            <p className="font-bold text-sm text-gray-200">N/A</p>
          )}
        </Shimmer.Text>
        {priceChange24h !== undefined && !priceChange24hLeft ? (
          <Shimmer.Text
            className="h-[14px] w-[100px]"
            isLoading={isPriceLoading}
          >
            <PriceChange orientation="right" priceChange24h={priceChange24h} />
          </Shimmer.Text>
        ) : null}
        {quantityDetails ? (
          <span className="text-xs text-gray-200 text-right">
            {formatTokenNumber(
              normalizeScaled(quantityDetails.value, quantityDetails.decimals),
              quantityDetails.decimals,
              { decimalsMode: "fixed" },
            )}{" "}
            {symbol ?? formatAddressShort(address, 2)}
          </span>
        ) : null}
      </div>
    </div>
  );
};
