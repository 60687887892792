import { OtpLoginForm } from "@/components/form";
import {
  changeOtpType,
  continueToVerify,
  continueToBetaAccessDenied,
  reset,
  updateEmail,
  updateSms,
} from "@/routes/login-signup/-reducer";
import { useAppDispatch, useTypedSelector } from "@/store";
import { useConnectWithOtp, PhoneData } from "@dynamic-labs/sdk-react-core";
import { FormEvent, useRef, useState } from "react";

export function DynamicConnectForm() {
  const [otp, setOtp] = useState("");
  const { email, sms, otpType, step, validations } = useTypedSelector(
    (state) => state.loginSignup,
  );
  const dispatch = useAppDispatch();
  const { connectWithEmail, connectWithSms, verifyOneTimePassword } =
    useConnectWithOtp();
  const [connectError, setConnectError] = useState("");
  const [verificationResult, setVerificationResult] = useState<
    "success" | "failure"
  >();

  const onSubmit = async (e?: FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    setConnectError("");
    setVerificationResult(undefined);
    switch (otpType) {
      case "email": {
        try {
          await connectWithEmail(email);
          dispatch(continueToVerify());
        } catch (err) {
          console.error(err);
          setConnectError("Something went wrong. Please try again.");
        }
        break;
      }
      case "sms": {
        try {
          const sanitized = sanitizeSms(sms);
          await connectWithSms(sanitized);
          dispatch(continueToVerify());
        } catch (err) {
          console.error(err);
          setConnectError("Something went wrong. Please try again.");
        }
        break;
      }
    }
  };

  const onOtpChange = (value: string) => {
    setOtp(value);
    setVerificationResult(undefined);
  };

  // Prevent multiple simultaneous verification calls
  const isVerifyingRef = useRef(false);

  const onOtpComplete = async (value: string) => {
    if (isVerifyingRef.current) return;
    try {
      isVerifyingRef.current = true;
      setVerificationResult(undefined);
      await verifyOneTimePassword(value);
      setVerificationResult("success");
      setTimeout(() => {
        // On timeout to avoid flashing the empty form
        dispatch(reset());
      }, 1000);
    } catch (err) {
      console.error(err);
      setVerificationResult("failure");
      if (err instanceof Error && err.message === "User does not have access") {
        setTimeout(() => {
          dispatch(continueToBetaAccessDenied());
        }, 2000);
      }
      setTimeout(() => {
        setOtp("");
        setVerificationResult(undefined);
      }, 1400);
    } finally {
      isVerifyingRef.current = false;
    }
  };

  return (
    <OtpLoginForm
      verifier={otpType}
      onChangeVerifier={(v) => dispatch(changeOtpType(v))}
      value={otpType === "sms" ? sms.phone : email}
      otp={otp}
      onChangeValue={(v) =>
        dispatch(otpType === "sms" ? updateSms(v) : updateEmail(v))
      }
      onSubmit={onSubmit}
      onOtpChange={onOtpChange}
      onOtpComplete={onOtpComplete}
      onResend={onSubmit}
      step={step}
      connectError={connectError}
      isSuccess={verificationResult === "success"}
      isFailure={verificationResult === "failure"}
      isContinueDisabled={
        otpType === "sms"
          ? !validations.sms.valid
          : otpType === "email"
            ? !validations.email.valid
            : false
      }
    />
  );
}

function sanitizeSms(sms: PhoneData) {
  const phone = sms.phone.replace(/\+1|\(|\)/g, "").trim();
  const sanitized = { ...sms, phone };
  return sanitized;
}
