import { Icon, IconProps } from "@/components";
import { cn } from "@/utils";
import { useRef, useState } from "react";
import { useSwipeable } from "react-swipeable";

export interface Notification {
  title: string;
  description: string;
  type: "Feature" | "Reminder";
  icon: IconProps["name"];
  createdAt: string;
}

interface NotificationProps extends Notification {
  onDismiss: () => void;
  index: number;
  total: number;
}

const colors = {
  Feature: {
    text: "text-accent",
    border: "border-accent",
    bg: "bg-accent-1000",
  },
  Reminder: {
    text: "text-lime",
    border: "border-lime",
    bg: "bg-coriander-1000",
  },
};

function Notification({
  title,
  description,
  type,
  icon,
  onDismiss,
  index,
  total,
}: NotificationProps) {
  const [isSwiping, setIsSwiping] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const animationFrameRef = useRef(0);

  const updateSwipeX = (x: string, transition?: boolean) => {
    const container = containerRef.current;
    if (!container) return;

    cancelAnimationFrame(animationFrameRef.current);
    animationFrameRef.current = requestAnimationFrame(() => {
      container.style.transform = `translateX(${x})`;

      if (transition) {
        container.style.transition =
          "transform 150ms cubic-bezier(0.4, 0, 0.2, 1)";
      } else {
        container.style.transition = "none";
      }
    });
  };

  const onButtonDismiss = () => {
    const container = containerRef.current;
    if (!container) return;

    cancelAnimationFrame(animationFrameRef.current);
    animationFrameRef.current = requestAnimationFrame(() => {
      container.style.opacity = "0";
      container.style.transition = "opacity 150ms cubic-bezier(0.4, 0, 0.2, 1)";
    });

    setTimeout(() => {
      onDismiss();
    }, 150);
  };

  const handlers = useSwipeable({
    onSwiping: ({ deltaX, event }) => {
      if (isSwiping) {
        event.preventDefault();
        updateSwipeX(`${deltaX}px`);
      }
    },
    onSwiped: ({ dir, absX }) => {
      if (isSwiping) {
        setIsSwiping(false);

        if ((dir === "Left" || dir === "Right") && absX >= 50) {
          updateSwipeX(dir === "Left" ? "-100%" : "100%", true);
          setTimeout(() => {
            onDismiss();
          }, 150);
        } else {
          updateSwipeX("0", true);
        }
      } else {
        updateSwipeX("0", true);
      }
    },
    onSwipeStart: ({ dir }) => {
      if (dir === "Left" || dir === "Right") {
        setIsSwiping(true);
      }
    },
    touchEventOptions: { passive: false },
  });

  const color = colors[type];

  const isTopNotification = index === total - 1;

  return (
    <div
      {...(isTopNotification ? handlers : {})}
      ref={(el) => {
        if (isTopNotification) {
          handlers.ref(el);
        }
        containerRef.current = el;
      }}
      className="absolute bottom-2 left-0 right-0"
      aria-hidden={!isTopNotification}
    >
      <div
        className={cn(
          "flex items-center gap-3 p-3 rounded-md border transition-transform min-h-20 w-full max-w-96 mx-auto",
          color.bg,
          color.border,
        )}
        style={{
          transform: `scale(${1 - (total - index - 1) * 0.03}) translateY(${
            (total - index - 1) * 0.4
          }rem)`,
        }}
      >
        <Icon name={icon} className={cn("w-8 h-8 shrink-0", color.text)} />
        <div>
          <p className={cn("uppercase text-xs font-bold pr-8", color.text)}>
            {type}: {title}
          </p>
          <p className="text-sm leading-tight">{description}</p>
        </div>
        <button
          type="button"
          aria-label="Dismiss"
          className="absolute p-2 top-1 right-1"
          onClick={onButtonDismiss}
          tabIndex={!isTopNotification ? -1 : undefined}
        >
          <Icon name="close" className={cn("w-4", color.text)} />
        </button>
      </div>
    </div>
  );
}

interface NotificationsProps {
  notifications: Notification[];
  dismissNotification: (notification: Notification) => void;
}

export function Notifications({
  notifications,
  dismissNotification,
}: NotificationsProps) {
  return (
    <div className="p-5 h-full">
      {notifications.length ? (
        <div className="relative h-full">
          {notifications.map((notification, index) => (
            <Notification
              key={notification.title}
              {...notification}
              index={index}
              total={notifications.length}
              onDismiss={() => {
                dismissNotification(notification);
              }}
            />
          ))}
        </div>
      ) : (
        <div className="h-full text-center flex flex-col items-center justify-center">
          <Icon name="binoculars-illustration" className="w-10 h-10 mx-auto" />
          <p className="text-gray-200 text-sm mt-1">Nothing to see here!</p>
        </div>
      )}
    </div>
  );
}
