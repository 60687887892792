import { BaseIconProps } from "../icon";

export function ExclamationMarkIcon(props: BaseIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17 18"
      fill="none"
      {...props}
    >
      <path
        d="M9.36191 4.79082H7.64088V10.5153H9.36191V4.79082Z"
        fill="currentColor"
      />
      <path
        d="M9.36191 11.5255H7.64088V13.2092H9.36191V11.5255Z"
        fill="currentColor"
      />
      <path
        d="M8.5014 0.75C3.85462 0.75 0.0683594 4.45408 0.0683594 9C0.0683594 13.5459 3.85462 17.25 8.5014 17.25C13.1482 17.25 16.9344 13.5459 16.9344 9C16.9344 4.45408 13.1482 0.75 8.5014 0.75ZM8.5014 15.5663C4.79775 15.5663 1.78939 12.6233 1.78939 9C1.78939 5.37673 4.79775 2.43367 8.5014 2.43367C12.2051 2.43367 15.2134 5.37673 15.2134 9C15.2134 12.6233 12.2051 15.5663 8.5014 15.5663Z"
        fill="currentColor"
      />
    </svg>
  );
}
