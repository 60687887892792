import {
  TokenInfoWithPrice,
  useQuickTradeSettingsQuery,
  useTokenAccountsQuery,
  useWalletInfoQuery,
} from "@/api";
import { Drawer, Button, Slider, Shimmer } from "@/components";
import { useQuickSell } from "@/hooks/useQuickTrade";
import {
  formatPercent,
  formatTokenNumber,
  getSymbolWithDefault,
  MINIMUM_SOL_BALANCE_LAMPORTS,
  normalizeScaled,
  SOL_ADDRESS,
  SOL_TRANSACTION_FEE,
  USDC_ADDRESS,
  USDT_ADDRESS,
} from "@/utils";
import { useState } from "react";

interface CustomQuickSellDrawerProps {
  token?: TokenInfoWithPrice;
  onClose: () => void;
}

export function CustomQuickSellDrawer({
  token,
  onClose,
}: CustomQuickSellDrawerProps) {
  const { data: quickTradeSettings, isLoading: isQuickTradeSettingsLoading } =
    useQuickTradeSettingsQuery();
  const walletQuery = useWalletInfoQuery();

  const tokenAccountsQuery = useTokenAccountsQuery();

  const receiveTokenAddress =
    quickTradeSettings?.settings?.sellTokenSymbol === "SOL"
      ? SOL_ADDRESS
      : quickTradeSettings?.settings?.sellTokenSymbol === "USDC"
        ? USDC_ADDRESS
        : quickTradeSettings?.settings?.sellTokenSymbol === "USDT"
          ? USDT_ADDRESS
          : undefined;

  let sendTokenBalance =
    token?.address === SOL_ADDRESS
      ? walletQuery.data?.rawBalance
      : tokenAccountsQuery.data?.find((t) => t.mintAddress === token?.address)
          ?.tokenRawBalance;

  if (
    token?.address === SOL_ADDRESS &&
    token.symbol === "SOL" &&
    sendTokenBalance
  ) {
    sendTokenBalance =
      typeof quickTradeSettings?.settings?.priorityFee === "number"
        ? (
            BigInt(sendTokenBalance) -
            BigInt(quickTradeSettings.settings.priorityFee) -
            SOL_TRANSACTION_FEE -
            MINIMUM_SOL_BALANCE_LAMPORTS
          ).toString()
        : undefined;
  }

  const { onQuickSell } = useQuickSell();

  const [percent, setPercent] = useState(0);
  if (!token && percent !== 0) {
    setPercent(0);
  }

  const sendTokenAmount = sendTokenBalance
    ? (BigInt(percent) * BigInt(sendTokenBalance)) / 100n
    : undefined;

  const isLoading =
    isQuickTradeSettingsLoading ||
    walletQuery.isLoading ||
    tokenAccountsQuery.isLoading;

  const isSameToken = Boolean(
    token?.address && token.address === receiveTokenAddress,
  );
  const hasNoBalance =
    !isLoading && (!sendTokenBalance || sendTokenBalance === "0");

  const isSliderDisabled = isLoading || isSameToken || hasNoBalance;

  return (
    <Drawer.Root
      open={Boolean(token)}
      onOpenChange={(open) => {
        if (!open) {
          onClose();
        }
      }}
    >
      <Drawer.Content
        title={
          quickTradeSettings?.settings?.sellTokenSymbol
            ? `Sell to ${quickTradeSettings.settings.sellTokenSymbol}`
            : "Sell"
        }
      >
        <Shimmer.Text isLoading={isLoading} className="w-40 h-8 mb-3">
          <p className="font-bold text-xl">
            {sendTokenAmount && token?.decimals
              ? formatTokenNumber(
                  normalizeScaled(sendTokenAmount, token.decimals),
                  token.decimals,
                  { decimalsMode: "fixed" },
                )
              : "0"}{" "}
            {getSymbolWithDefault(token)}
          </p>
        </Shimmer.Text>
        <Shimmer.Text isLoading={isLoading} className="w-16 h-5">
          <p className="text-lime">{formatPercent(percent)}</p>
        </Shimmer.Text>
        <Slider
          min={0}
          max={100}
          value={[percent]}
          onValueChange={(v) => {
            setPercent(v[0]);
          }}
          disabled={isSliderDisabled}
          className="mt-5"
        />
        <div className="mt-7">
          {isSameToken ? (
            <Button className="w-full" color="negative">
              Cannot sell to same token
            </Button>
          ) : hasNoBalance ? (
            <Button className="w-full" color="negative">
              Insufficient funds
            </Button>
          ) : (
            <Drawer.Close asChild>
              <Button
                className="w-full"
                disabled={isSliderDisabled || !sendTokenAmount}
                onClick={() => {
                  if (sendTokenAmount) {
                    void onQuickSell(token, sendTokenAmount.toString());
                  }
                }}
              >
                Sell
              </Button>
            </Drawer.Close>
          )}
        </div>
      </Drawer.Content>
    </Drawer.Root>
  );
}
