import { BackgroundTransactionToast } from "@/app/background-transactions/background-transaction-toast";
import { markTransactionsRead } from "@/app/background-transactions";
import { QuickTradeValidationToast } from "@/app/quick-trade-validation-toast";
import { useMediaQuery } from "@/hooks";
import {
  initialState,
  updateOrdersLink,
  updatePortfolioLink,
  updateRewardsLink,
  updateTradeLink,
} from "@/routes/-nav-reducer";
import { IndexRoute } from "@/routes/index";
import { PromptReload } from "@/sw/prompt-reload";
import * as Toast from "@radix-ui/react-toast";
import {
  Outlet,
  createRootRouteWithContext,
  useRouter,
} from "@tanstack/react-router";
import { useLayoutEffect } from "react";
import { useAppDispatch } from "../store/store";

interface RootRouteContext {
  isLoggedIn: boolean;
}

export const Route = createRootRouteWithContext<RootRouteContext>()({
  component: AppRoot,
});

function AppRoot() {
  const isDesktop = useMediaQuery("(min-width: 1024px)");
  const dispatch = useAppDispatch();
  const router = useRouter();

  useLayoutEffect(() => {
    const onBeforeLoad = (pathname: string, href: string) => {
      if (pathname.startsWith(initialState.currentTradeLink)) {
        dispatch(updateTradeLink(href));
      } else if (pathname.startsWith(initialState.currentPortfolioLink)) {
        dispatch(updatePortfolioLink(href));
      } else if (pathname.startsWith(initialState.currentRewardsLink)) {
        dispatch(updateRewardsLink(href));
      } else if (pathname.startsWith(initialState.currentOrdersLink)) {
        dispatch(updateOrdersLink(href));
        dispatch(markTransactionsRead());
      }
    };

    onBeforeLoad(location.pathname, location.href.replace(location.origin, ""));

    const unsubscribe = router.subscribe("onBeforeLoad", ({ toLocation }) => {
      onBeforeLoad(toLocation.pathname, toLocation.href);
    });

    return () => {
      unsubscribe();
    };
  }, [dispatch, router]);

  const Component = isDesktop ? IndexRoute : Outlet;
  return (
    <>
      <Toast.Viewport className="fixed w-screen z-50 top-0 left-0 list-none outline-none" />

      <BackgroundTransactionToast />
      <QuickTradeValidationToast />

      <Component />
      <PromptReload />
    </>
  );
}
