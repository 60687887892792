import type { TokenInfo } from "@/api";
import {
  addTokenToCache,
  removeExpiredTokens,
} from "@/app/token-cache/token-cache-actions";
import { NATIVE_SOL_UNIQUE_ADDRESS } from "@/app/token-cache/token-constants";
import { REHYDRATE, rehydrateReducer } from "@/store";
import { isNativeSol } from "@/utils";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import ms from "ms";

export interface CachedTokenInfo extends TokenInfo {
  expiresAt: number;
}

interface TokenCachePayload extends TokenInfo {
  fulfilledTimeStamp?: number;
}

type TokenCache = Record<string, CachedTokenInfo | undefined>;

const infoKeys = new Set<keyof TokenInfo>([
  "address",
  "decimals",
  "name",
  "symbol",
  "logoURI",
  "verified",
]);

function isTokenInfoKey(key: string): key is keyof TokenInfo {
  return infoKeys.has(key as keyof TokenInfo);
}

const tokenCacheSlice = createSlice({
  name: "tokenCache",
  initialState: {} as TokenCache,
  reducers: {
    [REHYDRATE]: rehydrateReducer,
  },
  extraReducers: (builder) => {
    builder.addCase(
      addTokenToCache,
      (state, action: PayloadAction<TokenCachePayload>) => {
        // Filter out keys that are not part of the TokenInfo interface e.g. price, rank, etc.
        let timeFulfilled = action.payload.fulfilledTimeStamp;
        if (!timeFulfilled) {
          if (
            "updateUnixTimestamp" in action.payload &&
            typeof action.payload.updateUnixTimestamp === "number"
          ) {
            timeFulfilled = action.payload.updateUnixTimestamp * 1000;
          } else {
            timeFulfilled = Date.now();
          }
        }
        const expiresAt = timeFulfilled + ms("14d");
        const token = Object.fromEntries(
          Object.entries(action.payload).filter(([key]) => isTokenInfoKey(key)),
        ) as TokenCachePayload;
        const key = isNativeSol(token)
          ? NATIVE_SOL_UNIQUE_ADDRESS
          : token.address;
        const entry = {
          ...token,
          expiresAt,
        };
        const existing = state[key];
        if (existing) {
          // Preserve existing token properties if not provided
          const name = entry.name || existing.name;
          const symbol = entry.symbol || existing.symbol;
          const logoURI = entry.logoURI || existing.logoURI;
          state[key] = {
            ...entry,
            name,
            symbol,
            logoURI,
            expiresAt: existing.expiresAt,
          };
        } else {
          state[key] = entry;
        }
      },
    );
    builder.addCase(removeExpiredTokens, (state) => {
      const currentTime = Date.now();
      for (const [address, token] of Object.entries(state)) {
        if (token && token.expiresAt < currentTime) {
          delete state[address];
        }
      }
    });
  },
});

export const tokenCacheReducer = tokenCacheSlice.reducer;
