import * as RadixAccordion from "@radix-ui/react-accordion";
import { Icon } from "../icon";

interface AccordionRowProps {
  value?: React.ReactNode;
}

export function Row({
  value,
  children,
}: React.PropsWithChildren<AccordionRowProps>) {
  return (
    <RadixAccordion.Header>
      <RadixAccordion.Trigger asChild>
        <button className="flex h-info-row gap-4 items-center justify-between px-5 py-3.5 w-full text-sm font-bold text-white border-t border-cloud">
          {children}
          <div className="flex items-center gap-1.5">
            {value ? <span className="text-primary">{value}</span> : null}
            <Icon
              name="chevron"
              className="w-3 group-[&[data-state='open']]:-rotate-180"
            />
          </div>
        </button>
      </RadixAccordion.Trigger>
    </RadixAccordion.Header>
  );
}
