import { TransactionErrorMessage, ErrorMessage, Shimmer } from "@/components";
import { ToggleGroup } from "../-toggle-group";
import { CustomInput } from "../-custom-input";
import { SlippageFormProps } from "./-use-slippage-hook";

export function SlippageForm({
  className,
  isLoading,
  mutationError,
  handleToggleGroup,
  handleInputFocus,
  handleInputChange,
  slippageState,
  slippageOptions,
  warning,
}: SlippageFormProps) {
  return (
    <div className={className}>
      {mutationError && (
        <ErrorMessage variant="banner" className="mb-6">
          Something happened. Please try again.
        </ErrorMessage>
      )}
      <p className="text-sm text-gray-200 mb-5">
        Your order will fail if price moves by more than this percentage before
        it’s filled.
      </p>
      <div className="flex flex-col gap-2.5">
        <ToggleGroup
          options={slippageOptions}
          setActiveValue={handleToggleGroup}
          activeValue={
            slippageState.isPresetSelected ? slippageState.value : ""
          }
        />
        <Shimmer.Text className="h-12 w-full" isLoading={isLoading}>
          <CustomInput
            customValue={
              slippageState.isPresetSelected ? "" : slippageState.value
            }
            label="Custom"
            suffix={slippageState.showSuffix ? "%" : ""}
            onChange={handleInputChange}
            focused={!slippageState.isPresetSelected}
            onFocus={handleInputFocus}
          />
        </Shimmer.Text>
      </div>
      {warning ? (
        <div className="mt-5 flex justify-center">
          <TransactionErrorMessage {...warning} />
        </div>
      ) : null}
    </div>
  );
}
