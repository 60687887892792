import { useContext } from "react";
import { Icon } from "@/components";
import { cn } from "@/utils";
import * as HoverCard from "@radix-ui/react-hover-card";
import * as Popover from "@radix-ui/react-popover";
import { type HoverCardTriggerProps } from "@radix-ui/react-hover-card";
import { GraphHoverCardContext } from "./root";

export function GraphHoverCardTrigger({
  className,
  style,
  ...props
}: Omit<HoverCardTriggerProps, "asChild">) {
  const { sendToken, hidePopover } = useContext(GraphHoverCardContext);
  return (
    <div className={cn("relative", className)} style={style}>
      <HoverCard.Trigger {...props} asChild>
        <div>{props.children}</div>
      </HoverCard.Trigger>
      {!hidePopover ? (
        <Popover.Trigger
          className="hidden lg:block sr-only focus:not-sr-only !absolute top-1/2 -translate-y-1/2 -right-2"
          aria-label={`Show more ${sendToken ?? "token"} info`}
        >
          <Icon name="info" className="w-4 text-primary" />
        </Popover.Trigger>
      ) : null}
    </div>
  );
}
