import { BaseIconProps } from "../icon";

export function UserIcon(props: BaseIconProps) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.94489 8.97688C12.1844 8.97688 14.0167 7.14456 14.0167 4.90506C14.0167 2.66557 12.1844 0.833252 9.94489 0.833252C7.70539 0.833252 5.87308 2.66557 5.87308 4.90506C5.87308 7.14456 7.70539 8.97688 9.94489 8.97688ZM9.94489 2.86916C11.085 2.86916 11.9808 3.76496 11.9808 4.90506C11.9808 6.04517 11.085 6.94097 9.94489 6.94097C8.80478 6.94097 7.90898 6.04517 7.90898 4.90506C7.90898 3.76496 8.80478 2.86916 9.94489 2.86916Z"
        fill="currentColor"
      />
      <path
        d="M17.0807 12.1223C16.6634 11.4607 15.9508 11.0739 15.1873 11.0739H4.82459C4.06112 11.0739 3.35873 11.4709 2.94137 12.1223L1.33301 14.6469V19.1666H18.6891V14.6469L17.0807 12.1223ZM3.36891 17.1307V15.2373L4.66171 13.2116C4.70243 13.1403 4.77369 13.0996 4.83477 13.0996H15.1975C15.2586 13.0996 15.3197 13.1403 15.3706 13.2116L16.6634 15.2373V17.1307H3.36891Z"
        fill="currentColor"
      />
    </svg>
  );
}
