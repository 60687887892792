import { BaseIconProps } from "../icon";

export function HeartFilledIcon(props: BaseIconProps) {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.0724 1.67059C11.5597 1.7742 10.1709 3.59945 10.1709 3.59945C10.1709 3.59945 8.78204 1.7742 6.26933 1.67059C4.58442 1.60121 3.03976 2.44769 2.02222 3.80483C-1.51812 8.52662 5.18487 14.0505 6.76161 15.5334C7.7049 16.4206 8.86912 17.4743 9.56399 18.0988C9.91142 18.4115 10.4294 18.4115 10.7768 18.0988C11.4717 17.4743 12.6359 16.4206 13.5792 15.5334C15.1559 14.0505 21.8598 8.52662 18.3186 3.80483C17.3019 2.44769 15.7573 1.60121 14.0724 1.67059Z"
        fill="currentColor"
      />
    </svg>
  );
}
