import { useLongPress } from "use-long-press";
import { Icon, IconProps, Link, Shimmer } from "@/components";
import { useSwipeable } from "react-swipeable";
import { type LinkProps } from "@tanstack/react-router";
import { useEffect, useId, useRef, useState } from "react";
import { cn } from "@/utils";

interface TokenAction extends Partial<LinkProps> {
  label: string;
  icon: IconProps["name"];
  onClick?: () => void;
  isLoading?: boolean;
  animateOnClick?: boolean;
  onLongPress?: () => void;
}

interface SwipeableActionProps extends Omit<TokenAction, "animateOnClick"> {
  label: string;
  icon: IconProps["name"];
  onClick?: () => void;
  isLoading?: boolean;
  isAnimating?: boolean;
  onAnimationEnd?: () => void;
}

function SwipeableAction({
  label,
  isLoading,
  icon,
  isAnimating,
  onAnimationEnd,
  onLongPress,
  ...rest
}: SwipeableActionProps) {
  const iconClassName = "w-5 h-5 mx-auto text-primary";
  const content = (
    <>
      <div className="overflow-hidden relative">
        <Icon
          name={icon}
          className={cn(
            iconClassName,
            "absolute translate-y-[-200%]",
            isAnimating ? "animate-swipeIconIn" : "hidden",
          )}
          onAnimationEnd={onAnimationEnd}
        />
        <div className={isAnimating ? "animate-swipeIconOut" : undefined}>
          <Shimmer.Text isLoading={isLoading || false} className="w-6 h-5">
            <Icon name={icon} className={iconClassName} />
          </Shimmer.Text>
        </div>
      </div>
      <span className="uppercase text-xs font-bold pt-0.5">{label}</span>
    </>
  );
  const className =
    "h-full w-[76px] flex flex-col items-center justify-center p-2 border-l border-cloud bg-box-dark select-none";

  const longPressBind = useLongPress(
    () => {
      if (onLongPress) {
        onLongPress();
      }
    },
    { threshold: 500 },
  );

  return "to" in rest ? (
    <Link key={label} {...rest} className={className}>
      {content}
    </Link>
  ) : (
    <button
      key={label}
      onClick={rest.onClick}
      className={className}
      disabled={rest.disabled || isLoading}
      {...longPressBind()}
    >
      {content}
    </button>
  );
}

export interface SwipeableActionsProps {
  actions: TokenAction[];
  name: string;
  tokenDescriptionId: string;
}

export const SwipeableActions = ({
  actions,
  name,
  tokenDescriptionId,
  children,
}: React.PropsWithChildren<SwipeableActionsProps>) => {
  const [isExtended, setIsExtended] = useState(false);
  const [isSwiping, setIsSwiping] = useState(false);
  const [indexTriggered, setIndexTriggered] = useState<number | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const childrenContainerRef = useRef<HTMLDivElement | null>(null);
  const animationFrameRef = useRef(0);

  const actionsWidth = actions.length * 76;

  const updateSwipeX = (deltaX: number) => {
    const container = childrenContainerRef.current;
    if (!container) return;

    cancelAnimationFrame(animationFrameRef.current);
    animationFrameRef.current = requestAnimationFrame(() => {
      const swipeX = Math.min(0, Math.max(-actionsWidth, deltaX));
      container.style.transform = `translateX(${swipeX}px)`;

      // Apply CSS transition only when the drawer opens/closes so that drag is
      // more responsive
      if (swipeX === 0 || swipeX === -actionsWidth) {
        container.style.transition =
          "transform 200ms cubic-bezier(0.4, 0, 0.2, 1)";
      } else {
        container.style.transition = "none";
      }
    });
  };

  const closeDrawer = () => {
    updateSwipeX(0);

    // Delay hiding the actions until transition to close drawer is done
    setTimeout(() => {
      setIsExtended(false);
    }, 200);
  };

  const id = useId();

  const handlers = useSwipeable({
    onSwiping: ({ deltaX, event }) => {
      if (isSwiping) {
        event.preventDefault();
        updateSwipeX(isExtended ? deltaX - actionsWidth : deltaX);
      }
    },
    onSwiped: ({ dir }) => {
      if (isSwiping) {
        setIsSwiping(false);

        if (dir === "Left") {
          setIsExtended(true);
          updateSwipeX(-actionsWidth);
        } else if (dir === "Right") {
          closeDrawer();
        } else {
          updateSwipeX(isExtended ? -actionsWidth : 0);
        }
      }
    },
    onSwipeStart: ({ dir }) => {
      if (dir === "Left" || dir === "Right") {
        setIsSwiping(true);
      }
    },
    touchEventOptions: { passive: false },
  });

  // Close drawer when user taps outside of the drawer or scrolls
  useEffect(() => {
    const onInteraction = (e: TouchEvent) => {
      if (!isExtended) return;

      if (
        e.target instanceof Element &&
        !containerRef.current?.contains(e.target)
      ) {
        closeDrawer();
      }
    };

    document.body.addEventListener("touchstart", onInteraction);

    return () => {
      document.body.removeEventListener("touchstart", onInteraction);
    };
  });

  const toggleId = `${id}-toggle`;

  return (
    <div className="w-full h-full relative" ref={containerRef}>
      <div
        {...handlers}
        className={cn(
          "w-full absolute top-0 left-0 z-10 bg-gray-600 touch-pan-y",
        )}
        ref={(el) => {
          handlers.ref(el);
          childrenContainerRef.current = el;
        }}
      >
        {children}
        <button
          type="button"
          className="sr-only focus:not-sr-only focus:absolute right-3 top-1/2 -translate-y-1/2 bg-gray-600 rounded-full border border-cloud focus:w-6 focus:h-6 flex items-center justify-center"
          onClick={() => {
            setIsExtended(!isExtended);
            setIsSwiping(false);
            updateSwipeX(isExtended ? 0 : -actionsWidth);
          }}
          aria-controls={id}
          aria-expanded={isExtended}
          aria-label={`${name} actions`}
          aria-describedby={tokenDescriptionId}
          id={toggleId}
        >
          <Icon
            name="chevron"
            className={cn(
              "w-3 text-primary",
              isExtended ? "-rotate-90" : "rotate-90",
            )}
          />
        </button>
      </div>
      <div
        className={cn(
          "flex items-center self-stretch absolute top-0 bottom-0 right-0",
          isExtended || isSwiping ? "flex" : "hidden",
        )}
        id={id}
        role="region"
        aria-labelledby={toggleId}
        aria-describedby={tokenDescriptionId}
      >
        {actions.map(({ animateOnClick, ...rest }, index) => (
          <SwipeableAction
            {...rest}
            key={rest.label}
            isAnimating={indexTriggered === index}
            onAnimationEnd={() => {
              setIndexTriggered(null);

              setTimeout(() => {
                closeDrawer();
              }, 300);
            }}
            onClick={() => {
              rest.onClick?.();

              if (animateOnClick) {
                setIndexTriggered(index);
              } else {
                setTimeout(() => {
                  closeDrawer();
                }, 300);
              }
            }}
            onLongPress={() => {
              rest.onLongPress?.();
              closeDrawer();
            }}
          />
        ))}
      </div>
    </div>
  );
};
