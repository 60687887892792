import { ChooseTokenModal, ChooseTokenModalProps } from "@/components";
import { GraphHoverCard } from "./-graph-hover-card";

export interface ChooseTokenDrawerProps<T extends boolean>
  extends ChooseTokenModalProps<T> {
  receiveToken?: string;
}

export function ChooseTokenDrawer<T extends boolean>({
  excludedToken,
  children,
  ...props
}: React.PropsWithChildren<ChooseTokenDrawerProps<T>>) {
  return (
    <ChooseTokenModal
      {...props}
      excludedToken={excludedToken}
      renderTokenContainer={({ token, children, key, ...props }) =>
        token ? (
          <GraphHoverCard.Root
            sendToken={token.address}
            receiveToken={excludedToken?.address}
            hidePopover
            key={key}
          >
            <GraphHoverCard.Trigger {...props}>
              {children}
            </GraphHoverCard.Trigger>
            <GraphHoverCard.Content sideOffset={20} />
          </GraphHoverCard.Root>
        ) : null
      }
    >
      {children}
    </ChooseTokenModal>
  );
}
