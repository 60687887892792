import { cn } from "@/utils";
import { ChangeEvent, FocusEvent, useId } from "react";

interface CustomInputProps {
  label: string;
  customValue: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  inputMode?: "url";
  suffix?: string;
  focused: boolean;
  error?: boolean;
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
  autoFocus?: boolean;
}

export function CustomInput({
  label,
  customValue,
  onChange,
  inputMode,
  suffix,
  focused,
  onFocus,
  autoFocus,
  error,
}: CustomInputProps) {
  const inputId = useId();

  return (
    <label
      className={cn(
        "flex relative items-center w-full rounded-full border border-cloud text-gray-200 bg-gray-600 px-3.5 py-2.5 h-[46px] leading-none text-sm font-bold uppercase focus-within:border-primary focus-within:text-white gap-2 focus-within:rounded-md group/custom-input justify-between",
        focused && "border-accent focus-within:border-accent",
        error && "border-negative focus-within:border-negative",
      )}
      htmlFor={inputId}
    >
      <span
        className={cn(
          !customValue &&
            "text-center flex-1 group-focus-within/custom-input:flex-initial group-focus-within/custom-input:text-initial",
        )}
      >
        {label}
      </span>
      <input
        className={cn(
          "bg-transparent focus-visible:outline-none overflow-hidden text-right",
          customValue ? "flex-1" : "w-0 group-focus-within/custom-input:flex-1",
        )}
        value={customValue}
        onChange={onChange}
        aria-label={label}
        id={inputId}
        onFocus={(e) => {
          if (onFocus) {
            onFocus(e);
          }

          e.currentTarget.select();
        }}
        inputMode={inputMode ?? "decimal"}
        pattern={inputMode !== undefined ? undefined : "[0-9.,]*"}
        autoCorrect="off"
        spellCheck="false"
        autoComplete="off"
        autoFocus={autoFocus}
      />
      {suffix && (
        <span
          className={cn(
            !customValue && "hidden group-focus-within/custom-input:block",
          )}
        >
          {suffix}
        </span>
      )}
    </label>
  );
}
