import { BaseIconProps } from "../icon";

export function MedalIcon(props: BaseIconProps) {
  return (
    <svg
      width="17"
      height="22"
      viewBox="0 0 17 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.19246 0L5.72857 1.05321L5.00984 2.67783L2.09509 2.94262L2.71029 5.80362L0.508301 7.73086L2.68839 9.68398L2.03934 12.539L3.57436 12.6963V22L8.6712 19.4516L13.768 22V12.7481L15.2473 12.6147L14.6321 9.75366L16.8341 7.82643L14.654 5.8733L15.3031 3.01828L12.3903 2.71964L11.2375 0.0298643L8.70703 1.49919L6.19246 0ZM7.12622 2.93068L8.6931 3.86443L10.2719 2.9486L10.9907 4.62498L12.8064 4.81213L12.4002 6.59004L13.7581 7.80851L12.3863 9.00905L12.7706 10.7929L10.9528 10.9582L10.2162 12.6266L8.64929 11.6929L7.07047 12.6087L6.35174 10.9323L4.53599 10.7452L4.94215 8.96724L3.58432 7.74878L4.95608 6.54824L4.57183 4.76434L6.38957 4.59909L7.12622 2.93068ZM8.63536 14.0581L11.1499 15.5573L11.7293 14.2452V18.701L8.6712 17.1719L5.6131 18.701V14.3806L6.10486 15.5274L8.63536 14.0581Z"
        fill="currentColor"
      />
    </svg>
  );
}
