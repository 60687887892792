export function Corner({ className }: { className?: string }) {
  return (
    <svg
      width="88"
      height="88"
      viewBox="0 0 88 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M8.46144 16.9229C8.46144 12.2606 12.2606 8.46144 16.9229 8.46144H87.2797V0H16.9229C7.57299 0 0 7.57299 0 16.9229V87.2797H8.46144V16.9229Z"
        fill="currentColor"
      />
    </svg>
  );
}
