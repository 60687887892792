import { persistMiddleware } from "@/app/store/persist-middleware";
import { loginSignupReducer } from "@/routes/login-signup/-reducer";
import { profileReducer } from "@/routes/profile/-reducer";
import { rewardsReducer } from "@/routes/rewards/-reducer";
import { tradeSwapReducer } from "@/routes/trade/-reducer";
import { tradeChartReducer } from "@/routes/trade/-token/reducer";
import { withdrawFlowReducer } from "@/routes/withdraw/-reducer";
import { portfolioReducer } from "@/routes/portfolio/-reducer";
import {
  UnknownAction,
  combineReducers,
  configureStore,
  createAction,
} from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { navReducer } from "../routes/-nav-reducer";
import { scrollReducer } from "../routes/-scroll-reducer";
import { api } from "../services/api";
import * as cache from "./cache";
import { backgroundTransactionsReducer } from "@/app/background-transactions";
import { tokenSearchReducer } from "@/routes/trade/-search/token-search-reducer";
import { tokenCacheReducer } from "@/app/token-cache/token-cache-slice";
import { quickTradeValidationToastReducer } from "@/app/quick-trade-validation-toast";

const offlineCachedData = await cache.getAll();

export const globalClearState = createAction("global/clearState");

const combinedReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  nav: navReducer,
  scroll: scrollReducer,
  withdrawFlow: withdrawFlowReducer,
  tradeSwap: tradeSwapReducer,
  tradeChart: tradeChartReducer,
  loginSignup: loginSignupReducer,
  profile: profileReducer,
  rewards: rewardsReducer,
  backgroundTransactions: backgroundTransactionsReducer,
  portfolio: portfolioReducer,
  tokenSearchList: tokenSearchReducer,
  tokenCache: tokenCacheReducer,
  quickTradeValidationToast: quickTradeValidationToastReducer,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const rootReducer = (state: any, action: UnknownAction) => {
  if (action.type === globalClearState.type) {
    state = undefined;
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      persistMiddleware.middleware,
      //? This middleware might be causing some issues, disabled for now
      // offlineApiCacheMiddleware.middleware,
      api.middleware,
    ),
});

setupListeners(store.dispatch);

Object.entries(offlineCachedData)
  .sort(([_ka, a], [_kb, b]) => a.timestamp - b.timestamp)
  .forEach(([key, value]) => {
    store.dispatch({ type: key, payload: value.data });
  });

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
