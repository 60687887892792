import { useVisualViewportOffset } from "@/hooks/useVisualViewportOffset";
import { Button, ButtonProps } from "../button";
import { cn } from "@/utils";
import { VariantProps, cva } from "class-variance-authority";

const variants = cva(
  "flex gap-5 fixed left-0 right-0 p-5 bg-gray-600 h-button-row",
  {
    variants: {
      placement: {
        "with-tabs":
          "bottom-[max(var(--h-tab-bar),var(--visual-viewport-offset))]",
        "without-tabs":
          "bottom-[max(var(--visual-viewport-offset),var(--safe-bottom))]",
      },
    },
    defaultVariants: {
      placement: "with-tabs",
    },
  },
);

export function KeyboardAwareButton({
  placement,
  ...props
}: VariantProps<typeof variants> & ButtonProps) {
  const visualViewportOffset = useVisualViewportOffset();

  return (
    <div
      className={variants({ placement })}
      style={
        {
          "--visual-viewport-offset": `${visualViewportOffset ?? 0}px`,
        } as React.CSSProperties
      }
    >
      <Button className={cn("w-full", props.className)} {...props} />
    </div>
  );
}
