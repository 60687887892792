import React, {
  useMemo,
  useCallback,
  useTransition,
  useState,
  useRef,
  useEffect,
} from "react";
import { fromUnixTime } from "date-fns";
import { Line, Bar, LinePath } from "@visx/shape";
import { curveMonotoneX } from "@visx/curve";
import { useParentSize } from "@visx/responsive";
import { scaleTime, scaleLinear } from "@visx/scale";
import { useTooltip, Tooltip } from "@visx/tooltip";
import { localPoint } from "@visx/event";
import { RadialGradient } from "@visx/gradient";
import { min, max, extent, bisector } from "@visx/vendor/d3-array";
import { timeFormat } from "@visx/vendor/d3-time-format";
import { type PricePoint } from "./token-price";

const positiveColor = "#4EFF31";
const negativeColor = "#FF3154";

const getDate = (d: PricePoint) => fromUnixTime(d.unixTime);
const getPrice = (d: PricePoint) => d.value;
const bisectDate = bisector<PricePoint, Date>((d) => getDate(d));

const getDistance = (x1: number, y1: number, x2: number, y2: number) => {
  const X = x2 - x1;
  const Y = y2 - y1;
  return Math.sqrt(X * X + Y * Y);
};

export interface AreaProps {
  data: PricePoint[];
  daysShown: number;
  setHoveredPricePoint: (pricePoint?: PricePoint) => void;
  xMargins?: number;
}

const marginTop = 40;
const marginBottom = 10;

export const AreaGraph = ({
  data,
  setHoveredPricePoint,
  daysShown,
  xMargins,
}: AreaProps) => {
  const { parentRef, width, height } = useParentSize({ debounceTime: 150 });
  const marginRight = xMargins ?? 0;
  const marginLeft = xMargins ?? 0;

  const {
    tooltipData,
    tooltipLeft = 0,
    tooltipTop = 0,
    showTooltip,
    hideTooltip,
  } = useTooltip<PricePoint>();

  const getIsLastDataPoint = (dataPoint?: PricePoint) =>
    dataPoint && data[data.length - 1].unixTime === dataPoint.unixTime;

  const formatDate = (dataPoint: PricePoint) => {
    const isLastPoint = getIsLastDataPoint(dataPoint);
    return timeFormat(
      daysShown > 30 && isLastPoint
        ? "%b %-d, %-I:%M %p"
        : daysShown > 30
          ? "%b %-d, %Y"
          : daysShown > 1
            ? "%b %-d, %-I:%M %p"
            : "%-I:%M %p",
    )(getDate(dataPoint));
  };

  const innerWidth = width - marginLeft - marginRight;
  const innerHeight = height - marginTop - marginBottom;

  const dateScale = useMemo(
    () =>
      scaleTime({
        range: [marginLeft, innerWidth + marginLeft],
        domain: extent(data, getDate) as [Date, Date],
      }),
    [data, innerWidth, marginLeft],
  );
  const priceScale = useMemo(() => {
    const minPrice = min(data, getPrice) ?? 0;
    const maxPrice = max(data, getPrice) ?? 0;
    const yMax = innerHeight + marginTop;
    const yMin = marginTop;
    return scaleLinear({
      range: [yMax, yMin],
      domain: [minPrice, maxPrice],
      nice: true,
    });
  }, [data, innerHeight]);

  const handleLeave = () => {
    hideTooltip();
    setHoveredPricePoint();
  };

  const [, startTransition] = useTransition();

  const handleTooltip = useCallback(
    (
      event:
        | React.TouchEvent<SVGRectElement | SVGSVGElement>
        | React.MouseEvent<SVGRectElement>,
    ) => {
      const { x } = localPoint(event) ?? { x: 0 };
      const x0 = dateScale.invert(x);
      const index = bisectDate.left(data, x0, 1);
      const d0 = data[index - 1];
      const d1 = data[index] as PricePoint | undefined;
      let d = d0;
      d =
        d1 &&
        x0.valueOf() - getDate(d0).valueOf() >
          getDate(d1).valueOf() - x0.valueOf()
          ? d1
          : d0;

      // We use deferred rendering so the mouse dragging doesn't lag
      startTransition(() => {
        setHoveredPricePoint(d);
      });

      showTooltip({
        tooltipData: d,
        tooltipLeft: Math.min(Math.max(marginLeft, x), width - marginRight),
        tooltipTop: priceScale(getPrice(d)),
      });
    },
    [
      data,
      showTooltip,
      priceScale,
      dateScale,
      setHoveredPricePoint,
      width,
      marginLeft,
      marginRight,
    ],
  );

  const lineDataProps = {
    data,
    x: (d: PricePoint) => dateScale(getDate(d)),
    y: (d: PricePoint) => priceScale(getPrice(d)),
  };

  const isPositive = data[0].value <= data[data.length - 1].value;
  const approxTooltipLength =
    daysShown <= 1
      ? 20
      : daysShown <= 30 || getIsLastDataPoint(tooltipData)
        ? 45
        : 35;
  const graphColor = isPositive ? positiveColor : negativeColor;

  const [isDragging, setIsDragging] = useState(false);
  const draggingTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const currentCursorPositionRef = useRef<{
    x: number | null;
    y: number | null;
  }>({
    x: null,
    y: null,
  });
  const graphRef = useRef<SVGSVGElement | null>(null);

  const handleDragTooltip = (event: React.TouchEvent<SVGRectElement>) => {
    if (isDragging) {
      handleTooltip(event);
    }
  };

  useEffect(() => {
    const onTouchMove = (e: TouchEvent) => {
      const touch = e.touches[0];
      currentCursorPositionRef.current = {
        x: touch.clientX,
        y: touch.clientY,
      };

      if (!e.cancelable && isDragging) {
        setIsDragging(false);
        handleLeave();
      } else if (isDragging) {
        e.preventDefault();
      }
    };

    const graph = graphRef.current;
    if (graph) {
      graph.addEventListener("touchmove", onTouchMove, { passive: false });
    }

    return () => {
      if (graph) {
        graph.removeEventListener("touchmove", onTouchMove);
      }
    };
  });

  return (
    <div ref={parentRef} className="h-full relative">
      {!width || !height ? null : (
        <svg
          ref={graphRef}
          width={width}
          height={height}
          className={isDragging ? "touch-pan-none" : "touch-manipulation"}
          onTouchStart={(e) => {
            const touch = e.touches[0];
            currentCursorPositionRef.current = {
              x: touch.clientX,
              y: touch.clientY,
            };

            draggingTimeoutRef.current = setTimeout(() => {
              if (
                currentCursorPositionRef.current.x !== null &&
                currentCursorPositionRef.current.y !== null &&
                getDistance(
                  currentCursorPositionRef.current.x,
                  currentCursorPositionRef.current.y,
                  touch.clientX,
                  touch.clientY,
                ) < 20
              ) {
                setIsDragging(true);
                handleTooltip(e);
              }
            }, 150);
          }}
          onTouchCancel={() => {
            setIsDragging(false);
            currentCursorPositionRef.current = { x: null, y: null };

            if (draggingTimeoutRef.current) {
              clearTimeout(draggingTimeoutRef.current);
            }
          }}
          onTouchEnd={() => {
            setIsDragging(false);
            currentCursorPositionRef.current = { x: null, y: null };

            if (draggingTimeoutRef.current) {
              clearTimeout(draggingTimeoutRef.current);
            }
          }}
        >
          <LinePath<PricePoint>
            {...lineDataProps}
            strokeWidth={1.5}
            stroke={graphColor}
            curve={curveMonotoneX}
          />
          <Bar
            x={0}
            y={marginTop}
            width={width}
            height={innerHeight}
            fill="transparent"
            rx={14}
            onTouchStart={handleDragTooltip}
            onTouchMove={handleDragTooltip}
            onMouseMove={handleTooltip}
            onMouseLeave={handleLeave}
            onTouchEnd={handleLeave}
            onMouseUp={handleLeave}
          />
          {tooltipData && (
            <g>
              <Line
                from={{ x: tooltipLeft, y: marginTop }}
                to={{ x: tooltipLeft, y: innerHeight + marginTop }}
                stroke="#FFFFFF"
                strokeOpacity={0.25}
                strokeWidth={1}
                pointerEvents="none"
                strokeDasharray="5,5"
              />
              <RadialGradient
                id="tooltip-dot-gradient"
                from={graphColor}
                fromOpacity={1}
                to={graphColor}
                toOpacity={0}
                pointerEvents="none"
              />
              <circle
                cx={tooltipLeft}
                cy={tooltipTop}
                r={7}
                fill="url(#tooltip-dot-gradient)"
                pointerEvents="none"
              />
              <circle
                cx={tooltipLeft}
                cy={tooltipTop}
                r={4}
                fill={graphColor}
                pointerEvents="none"
              />
            </g>
          )}
        </svg>
      )}
      {tooltipData && (
        <div>
          <Tooltip
            top={0}
            // clamp words to screen
            left={Math.max(
              marginLeft + approxTooltipLength,
              Math.min(tooltipLeft, width - marginLeft - approxTooltipLength),
            )}
            unstyled
            className="absolute pointer-events-none whitespace-nowrap -translate-x-[calc(50%+10px)] text-sm text-white"
          >
            {formatDate(tooltipData)}
          </Tooltip>
        </div>
      )}
    </div>
  );
};
