import { router } from "@/router";
import { Route, TradeSearchParams } from "@/routes/trade";

export function updateSearchParams(
  newSearch: Partial<TradeSearchParams>,
  clearExistingParams?: boolean,
) {
  void router.navigate({
    to: Route.fullPath,
    search: (prev) => ({
      ...(clearExistingParams ? {} : prev),
      ...newSearch,
    }),
    replace: true,
  });
}
