import { CustomConnection } from "@/rpc-connection/custom-connection";
import { Connection } from "@solana/web3.js";
import { MultiplexedConnection } from "./multiplexed-connection";

export class ActiveConnection {
  private _connection: CustomConnection | MultiplexedConnection | undefined;
  private _initPromise: Promise<void>;
  private _resolveInitPromise: () => void = () => {
    /* empty */
  };

  constructor() {
    this._initPromise = new Promise((resolve) => {
      this._resolveInitPromise = resolve;
      // Give the app 5 seconds to initialize the connection
      setTimeout(() => {
        resolve();
      }, 5000);
    });
  }

  init(connection: CustomConnection | MultiplexedConnection) {
    console.info("Initializing connection");
    this._connection = connection;
    this._resolveInitPromise();
  }

  async getReadConnection(): Promise<Connection> {
    await this._initPromise;

    if (!this._connection) {
      throw new Error(
        "Connection not initialized, did you forget to call init?",
      );
    }

    if (this._connection instanceof CustomConnection) {
      return this._connection.getConnection();
    }

    if (this._connection instanceof MultiplexedConnection) {
      return this._connection.readConnection;
    }

    throw new Error("Connection not correctly initialized");
  }

  getWriteConnection(): CustomConnection | MultiplexedConnection {
    if (!this._connection) {
      throw new Error(
        "Connection not initialized, did you forget to call init?",
      );
    }

    return this._connection;
  }
}

export const activeConnection = new ActiveConnection();

export async function resolveRpcConnections() {
  const read = await activeConnection.getReadConnection();
  const write = activeConnection.getWriteConnection();
  if (write instanceof CustomConnection) {
    return { read, write: [write.getConnection()] };
  } else {
    return { read, write: write.writeConnections };
  }
}
