import {
  isBuildAndSendTransactionError,
  useBuildAndSendTransferTransactionMutation,
  useUpsertWithdrawalAddressMutation,
  useWalletInfoQuery,
} from "@/api";
import {
  Button,
  ButtonRow,
  TransactionErrorMessageProps,
  Icon,
  OneTimeCodePrompt,
  Page,
} from "@/components";
import { useAppDispatch, useTypedSelector } from "@/store";
import {
  getIsSlippageLimitReached,
  getRedirectPath,
  parseAsScaled,
  transactionStatusMessage,
} from "@/utils";
import { useIsLoggedIn } from "@dynamic-labs/sdk-react-core";
import {
  Navigate,
  createFileRoute,
  useNavigate,
  useRouter,
} from "@tanstack/react-router";
import { resetWithdrawFlow } from "../-reducer";
import { useValidation } from "../-validation";
import { OverviewView } from "./-overview-view";

export const Route = createFileRoute("/withdraw/confirm/")({
  component: ConfirmView,
});

function ConfirmView() {
  const dispatch = useAppDispatch();
  const isLoggedIn = useIsLoggedIn();
  const router = useRouter();
  const { address, token, tokenQuantity } = useTypedSelector(
    (state) => state.withdrawFlow,
  );
  // Use half of the priority fee in settings for withdrawals
  const priorityFee = Math.floor(
    useTypedSelector((state) => state.profile.priorityFee) / 2,
  );
  // Safe because priority fee in lamports is << Number.MAX_SAFE_INTEGER
  const transactionFeeLamports = BigInt(priorityFee + 5000);
  const walletQuery = useWalletInfoQuery();
  const [withdrawMutate, withdrawMutation] =
    useBuildAndSendTransferTransactionMutation();
  const [lastUsedMutate] = useUpsertWithdrawalAddressMutation();
  const navigate = useNavigate();

  const { tokenValidation, addressValidation, amountValidation } =
    useValidation();

  if (
    withdrawMutation.isUninitialized &&
    (!tokenValidation.valid ||
      !addressValidation.valid ||
      !amountValidation.valid)
  ) {
    return <Navigate replace to="/withdraw/amount" />;
  }

  if (!token || !address || !tokenQuantity) {
    void navigate({ to: "/withdraw/amount" });
    return "Something went wrong";
  }

  let error: TransactionErrorMessageProps | undefined;
  let errorPageTitle = "Withdrawal failed";
  let signature = withdrawMutation.data?.signature;
  if (isBuildAndSendTransactionError(withdrawMutation.error)) {
    switch (withdrawMutation.error.kind) {
      case "cancel_signature":
        withdrawMutation.reset();
        break;
      case "failed_to_sign":
        error = {
          title: "FAILED TO SIGN TRANSACTION",
          message: transactionStatusMessage.failedToSignTransaction,
          color: "negative",
          feeStatus: "not_charged",
        };
        break;
      case "failed_to_fetch_latest_blockhash":
        error = {
          title: "TRANSACTION NOT SENT",
          message: transactionStatusMessage.failedToFetchLatestBlockhash,
          color: "negative",
          feeStatus: "not_charged",
        };
        break;
      case "not_simulated":
        error = {
          title: "TRANSACTION NOT SENT",
          message: transactionStatusMessage.notSimulated,
          color: "negative",
          feeStatus: "not_charged",
        };
        break;
      case "simulation_failed":
        error = {
          title: "TRANSACTION NOT SENT",
          message: transactionStatusMessage.simulationFailed(
            withdrawMutation.error.data.value,
          ),
          color: "negative",
          feeStatus: "not_charged",
        };

        break;
      case "unhandled_error":
        error = {
          title: "AN ERROR OCCURRED",
          message: transactionStatusMessage.unhandledError(
            withdrawMutation.error.data,
          ),
          color: "negative",
          feeStatus: "unknown",
        };
        break;
      default: {
        const _exhaustiveCheck: never = withdrawMutation.error;
      }
    }
  }
  if (withdrawMutation.data) {
    switch (withdrawMutation.data.status) {
      case "success":
        break;
      case "failed":
        if (getIsSlippageLimitReached(withdrawMutation.data.transactionError)) {
          error = {
            title: "SLIPPAGE LIMIT REACHED",
            message: transactionStatusMessage.slippageLimitReached,
            color: "negative",
            feeStatus: "charged",
          };
        } else {
          error = {
            title: "TRANSACTION FAILED",
            message: transactionStatusMessage.transactionFailed,
            color: "negative",
            feeStatus: "charged",
          };
        }
        break;
      case "expired":
        signature = undefined;
        error = {
          title: "TRANSACTION EXPIRED",
          message: transactionStatusMessage.transactionExpired,
          color: "negative",
          feeStatus: "not_charged",
        };
        break;
      case "unknown":
        errorPageTitle = "Withdrawal status unknown";
        error = {
          title: "TRANSACTION STATUS UNKNOWN",
          message: transactionStatusMessage.transactionStatusUnknown,
          color: "negative",
          feeStatus: "unknown",
        };
        break;
      default: {
        const _exhaustiveCheck: never = withdrawMutation.data;
      }
    }
  }

  const handleSubmit = () => {
    const amount = parseAsScaled(tokenQuantity, token.decimals);

    if (!walletQuery.data?.walletAddress || !amount) {
      return;
    }

    void lastUsedMutate(address);
    void withdrawMutate({
      from: walletQuery.data.walletAddress,
      to: address,
      priorityFee,
      transfer: {
        amount,
        tokenAddress: token.address,
        decimals: token.decimals,
        symbol: token.symbol,
        balance: BigInt(token.quantity),
      },
    });
  };

  if (withdrawMutation.isLoading) {
    return (
      <Page flex fullScreenHeight hideTabs title="Withdrawing...">
        <OverviewView
          flowState="loadingWithdraw"
          address={address}
          token={token}
          tokenQuantityScaled={
            parseAsScaled(tokenQuantity, token.decimals) ?? 0n
          }
          transactionFeeLamports={transactionFeeLamports}
          footer={null}
        />
      </Page>
    );
  }

  if (error) {
    return (
      <Page
        flex
        fullScreenHeight
        hideTabs
        title={errorPageTitle}
        backButton={{
          to: "/withdraw/amount",
          "aria-label": "Back to amount",
        }}
      >
        <OverviewView
          className="pb-button-row"
          flowState="errorWithdraw"
          address={address}
          token={token}
          tokenQuantityScaled={
            parseAsScaled(tokenQuantity, token.decimals) ?? 0n
          }
          transactionFeeLamports={transactionFeeLamports}
          error={error}
          footer={
            <ButtonRow noTabs>
              <Button
                className="w-full"
                onClick={() => {
                  withdrawMutation.reset();
                }}
              >
                Go back
              </Button>

              {signature && (
                <Button
                  variant="outline"
                  className="w-full"
                  onClick={() => {
                    window.open(`https://solscan.io/tx/${signature}`, "_blank");
                  }}
                  icon={<Icon className="w-5" name="link" />}
                >
                  Explorer
                </Button>
              )}
            </ButtonRow>
          }
        />
      </Page>
    );
  }

  if (withdrawMutation.data?.status === "success") {
    return (
      <Page flex fullScreenHeight hideTabs title="Withdrawal completed">
        <OverviewView
          className="pb-button-row"
          flowState="successWithdraw"
          address={address}
          token={token}
          tokenQuantityScaled={
            parseAsScaled(tokenQuantity, token.decimals) ?? 0n
          }
          transactionFeeLamports={transactionFeeLamports}
          footer={
            <ButtonRow noTabs>
              <Button
                className="w-full"
                onClick={() => {
                  withdrawMutation.reset();
                  dispatch(resetWithdrawFlow());
                  void router.navigate({ to: "/portfolio" });
                }}
              >
                Done
              </Button>

              {signature && (
                <Button
                  variant="outline"
                  className="w-full"
                  onClick={() => {
                    window.open(`https://solscan.io/tx/${signature}`, "_blank");
                  }}
                  icon={<Icon className="w-5" name="link" />}
                >
                  Explorer
                </Button>
              )}
            </ButtonRow>
          }
        />
      </Page>
    );
  }

  return (
    <Page
      flex
      fullScreenHeight
      hideTabs
      title="Withdraw"
      backButton={{
        to: "/withdraw/amount",
        "aria-label": "Back to amount",
      }}
    >
      <OverviewView
        className="pb-button-row"
        flowState="confirmWithdraw"
        address={address}
        token={token}
        tokenQuantityScaled={parseAsScaled(tokenQuantity, token.decimals) ?? 0n}
        transactionFeeLamports={transactionFeeLamports}
        footer={
          <ButtonRow noTabs>
            {isLoggedIn ? (
              <OneTimeCodePrompt>
                {({ isCodeRequired }) => (
                  <Button
                    onClick={() => {
                      if (isCodeRequired) return;
                      handleSubmit();
                    }}
                    className="w-full"
                  >
                    Withdraw
                  </Button>
                )}
              </OneTimeCodePrompt>
            ) : (
              <Button
                to="/login-signup"
                search={{ redirect: getRedirectPath() }}
                className="w-full"
              >
                Connect wallet
              </Button>
            )}
          </ButtonRow>
        }
      />
    </Page>
  );
}
