import { useRouterState } from "@tanstack/react-router";
import { useTypedSelector } from "@/store";
import { Link } from "@/components";

interface TabProps {
  to: string;
}

function Tab({ to, children }: React.PropsWithChildren<TabProps>) {
  const { location } = useRouterState();
  const toWithoutParams = to.split("?")[0];
  return (
    <Link
      to={to}
      className="relative self-stretch flex items-center font-bold font-heading text-xl uppercase transition-colors text-gray-300 hover:text-white [&[aria-current='page']]:text-primary group mx-3.5"
      aria-current={
        location.pathname.startsWith(toWithoutParams) ? "page" : undefined
      }
    >
      <span>{children}</span>
      <span className="group-hover:bg-white [.group[aria-current='page']_&]:bg-primary absolute left-0 right-0 bottom-0 h-px z-10 bg-transparent transition-colors" />
    </Link>
  );
}

export const DesktopNav = () => {
  const {
    currentTradeLink,
    currentPortfolioLink,
    currentRewardsLink,
    currentOrdersLink,
  } = useTypedSelector((state) => state.nav);
  return (
    <>
      <Tab to={currentTradeLink}>Swap</Tab>
      <Tab to={currentPortfolioLink}>Portfolio</Tab>
      <Tab to={currentRewardsLink}>Rewards</Tab>
      <Tab to={currentOrdersLink}>Orders</Tab>
    </>
  );
};
