import { TokenInfo } from "@/api";
import { TransactionToastProps } from "@/components";
import { REHYDRATE, rehydrateReducer } from "@/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface QuickTradeValidationToastState {
  title: TransactionToastProps["title"];
  status: TransactionToastProps["status"];
  baseToken?: TokenInfo;
  quoteToken?: TokenInfo;
}

const initialState = {
  toast: undefined as QuickTradeValidationToastState | undefined,
};

const quickTradeValidationToastSlice = createSlice({
  name: "quickTradeValidationToast",
  initialState,
  reducers: {
    [REHYDRATE]: rehydrateReducer,
    showQuickTradeValidationToast: (
      state,
      action: PayloadAction<QuickTradeValidationToastState>,
    ) => {
      state.toast = action.payload;
    },
    hideQuickTradeValidationToast: (state) => {
      state.toast = undefined;
    },
  },
});

export const { showQuickTradeValidationToast, hideQuickTradeValidationToast } =
  quickTradeValidationToastSlice.actions;
export const quickTradeValidationToastReducer =
  quickTradeValidationToastSlice.reducer;
export const quickTradeValidationToastActions =
  quickTradeValidationToastSlice.actions;
export const quickTradeValidationToastName =
  quickTradeValidationToastSlice.name;
