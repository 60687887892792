import { Shimmer } from "@/components";
import { setScrollPosition } from "@/routes/-scroll-reducer";
import { useTypedSelector } from "@/store";
import { cn } from "@/utils";
import { VirtualItem, useVirtualizer } from "@tanstack/react-virtual";
import {
  forwardRef,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
} from "react";
import { useDispatch } from "react-redux";

export interface VirtualItemProps {
  className: string;
  style: React.CSSProperties;
}

interface VirtualListProps {
  id: string;
  totalItems: number;
  itemHeight: number;
  searchKey?: string;
  classname?: string;
  isFetching?: boolean;
  isLoading?: boolean;
  hasNextPage?: boolean;
  overscan?: number;
  style?: React.CSSProperties;
  onNextPage?: () => void;
  children: (
    virtualItem: VirtualItem,
    props: VirtualItemProps,
  ) => React.ReactNode;
}

const TIMEOUT = 300;

function VirtualListInner(
  {
    id,
    totalItems,
    hasNextPage,
    searchKey,
    isFetching,
    isLoading,
    itemHeight,
    classname,
    overscan = 10,
    onNextPage,
    children,
    style,
  }: VirtualListProps,
  forwardedRef: React.ForwardedRef<HTMLDivElement>,
) {
  const scrollPositions = useTypedSelector(
    (state) => state.scroll.scrollPositions,
  );
  const initialScrollTopRef = useRef(scrollPositions[id]);
  const dispatch = useDispatch();
  const parentRef = useRef<HTMLDivElement | null>(null);
  const calledTimestampRef = useRef(0);
  const rowVirtualizer = useVirtualizer({
    count: totalItems,
    getScrollElement: () => parentRef.current,
    estimateSize: () => itemHeight,
    overscan,
  });
  const virtualItems = rowVirtualizer.getVirtualItems();
  const totalSize = rowVirtualizer.getTotalSize();
  const lastItemIndex =
    virtualItems.length > 0 ? virtualItems[virtualItems.length - 1].index : 0;

  useEffect(() => {
    if (!lastItemIndex || Date.now() - calledTimestampRef.current < TIMEOUT) {
      return;
    }

    if (lastItemIndex + 1 === totalItems && hasNextPage && !isFetching) {
      calledTimestampRef.current = Date.now();
      console.info("Fetching next page");
      onNextPage?.();
    }
  }, [hasNextPage, onNextPage, totalItems, isFetching, lastItemIndex]);

  useLayoutEffect(() => {
    parentRef.current?.scrollTo(0, 0);
  }, [searchKey]);

  useLayoutEffect(() => {
    if (totalSize !== itemHeight) {
      if (initialScrollTopRef.current) {
        parentRef.current?.scrollTo(0, initialScrollTopRef.current);
      }
    }
  }, [itemHeight, totalSize]);

  const onPreserveScroll = useCallback(
    (e: React.UIEvent<HTMLDivElement>) => {
      const scrollTop = (e.target as HTMLDivElement).scrollTop;
      dispatch(
        setScrollPosition({
          id,
          position: scrollTop,
        }),
      );
    },
    [dispatch, id],
  );

  return (
    <div
      className={cn("overflow-auto", classname)}
      ref={(el) => {
        parentRef.current = el;
        if (typeof forwardedRef === "function") {
          forwardedRef(el);
        } else if (forwardedRef) {
          forwardedRef.current = el;
        }
      }}
      onScroll={onPreserveScroll}
      style={style}
    >
      <div
        className={cn("relative w-full")}
        style={{
          height: `${totalSize}px`,
        }}
      >
        {virtualItems.length === 0 && !isFetching && !isLoading && (
          <div className="fixed top-1/2 w-full text-center text-sm text-gray-200">
            There are no results
          </div>
        )}
        {!isLoading
          ? virtualItems.map((item) =>
              children(item, {
                className: "absolute top-0 left-0 w-full",
                style: {
                  height: `${item.size}px`,
                  transform: `translateY(${item.start}px)`,
                },
              }),
            )
          : null}
        {isFetching || isLoading ? (
          <div
            className="absolute top-0 left-0 w-full px-5"
            style={
              !isLoading
                ? {
                    transform: `translateY(${totalSize}px)`,
                  }
                : undefined
            }
          >
            <Shimmer.List rows={6} />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export const VirtualList = forwardRef<HTMLDivElement, VirtualListProps>(
  VirtualListInner,
);
