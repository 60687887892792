import { useTwitterDisconnectMutation, useTwitterConnectMutation } from "@/api";
import { TransactionErrorMessageProps } from "@/components";
import { useRouter } from "@tanstack/react-router";
import { useCallback, useEffect, useRef, useState } from "react";

export function useTwitterIntegration(): {
  errorProps: TransactionErrorMessageProps | undefined;
  isLoading: boolean;
  dismissError: () => void;
  disconnect: () => void;
} {
  const [connectMutate, connectMutation] = useTwitterConnectMutation();
  const [disconnectMutate, disconnectMutation] = useTwitterDisconnectMutation();

  const { navigate, parseLocation } = useRouter();
  const params = parseLocation().search;
  const [isDismissed, setIsDismissed] = useState(false);
  const code = "code" in params ? (params.code as string) : undefined;
  const error = "error" in params ? (params.error as string) : undefined;
  const calledRef = useRef(false);

  useEffect(() => {
    setIsDismissed(false);

    if (!code || calledRef.current) {
      return;
    }

    calledRef.current = true;
    void connectMutate({ code }).then(() => {
      void navigate({ to: "/profile", replace: true });
      calledRef.current = false;
    });
  }, [code, connectMutate, navigate]);

  const errorProps: TransactionErrorMessageProps | undefined =
    (error || connectMutation.isError) && !isDismissed
      ? {
          title: "Unable to connect twitter account",
          message: "An error occurred while connecting your account.",
          color: "negative",
        }
      : disconnectMutation.isError && !isDismissed
        ? {
            title: "Unable to disconnect twitter account",
            message: "An error occurred while disconnecting your account.",
            color: "negative",
          }
        : undefined;

  const dismissError = useCallback(() => {
    setIsDismissed(true);
  }, []);

  const disconnect = useCallback(() => {
    void disconnectMutate();
  }, [disconnectMutate]);

  return {
    dismissError,
    disconnect,
    errorProps,
    isLoading: connectMutation.isLoading || disconnectMutation.isLoading,
  };
}
