import { BaseIconProps } from "../icon";

export function UnknownIcon(props: BaseIconProps) {
  return (
    <svg
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect y="0.5" width="25" height="25" rx="12.5" fill="currentColor" />
      <path
        d="M13.7756 17.5663H11.2246V20.1174H13.7756V17.5663Z"
        fill="#1D1E20"
      />
      <path
        d="M12.5002 6.62244C10.1124 6.62244 8.16345 8.57142 8.16345 10.9592V11.2143H10.7145V10.9592C10.7145 9.95917 11.5002 9.17346 12.5002 9.17346C13.5002 9.17346 14.2859 9.95917 14.2859 10.9592C14.2859 11.6326 13.8573 12.2245 13.2247 12.4388L12.8063 12.5714C11.8777 12.8775 11.2247 13.7755 11.2247 14.7551V16.3163H13.7757V14.9388L14.0206 14.8673C15.7043 14.3061 16.8369 12.7347 16.8369 10.9592C16.8369 8.57142 14.8879 6.62244 12.5002 6.62244Z"
        fill="#1D1E20"
      />
    </svg>
  );
}
