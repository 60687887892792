import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface ScrollState {
  scrollPositions: Record<string, number>;
}

const initialState: ScrollState = {
  scrollPositions: {},
};

const navSlice = createSlice({
  name: "scroll",
  initialState,
  reducers: {
    setScrollPosition(
      state,
      {
        payload: { id, position },
      }: PayloadAction<{ id: string; position: number }>,
    ) {
      state.scrollPositions[id] = position;
    },
  },
});

export const { setScrollPosition } = navSlice.actions;

export const scrollReducer = navSlice.reducer;
export const scrollActions = navSlice.actions;
