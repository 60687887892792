import { useWeb3authInputPasswordMutation } from "@/app/web3auth";
import { Button, Icon, Input, Page } from "@/components";
import { with404 } from "@/hocs/with404";
import { withDynamicProtected } from "@/hocs/withDynamicProtected";
import { withWeb3authProtected } from "@/hocs/withWeb3authProtected";
import { DISABLE_MIGRATION_FLOW, cn, getApiErrorMessage } from "@/utils";
import { compose } from "@reduxjs/toolkit";
import {
  RouteComponent,
  createFileRoute,
  useRouter,
} from "@tanstack/react-router";
import { useState } from "react";

export const Route = createFileRoute("/migrate/password/")({
  component: DISABLE_MIGRATION_FLOW
    ? with404(Password)
    : compose<RouteComponent>(
        withWeb3authProtected(),
        withDynamicProtected,
      )(Password),
});

function Password() {
  const router = useRouter();
  const [web3authPassword, setWeb3authPassword] = useState("");
  const [inputPassword, inputPasswordMutation] =
    useWeb3authInputPasswordMutation();
  const error = inputPasswordMutation.error
    ? getApiErrorMessage(inputPasswordMutation.error)
    : undefined;

  const submit = async () => {
    if (!web3authPassword || inputPasswordMutation.isLoading) return;

    const response = await inputPassword({ password: web3authPassword });

    if ("error" in response) {
      return;
    }

    void router.navigate({
      to: "/migrate/tokens",
      replace: true,
      search: (prev) => prev,
    });
  };

  return (
    <Page
      hideTabs
      fullScreenHeight
      backButton={{
        to: "/migrate/notice",
        "aria-label": "Back",
        search: (prev) => prev,
      }}
      title="Connect old account"
    >
      <div
        className={cn(
          "flex flex-col justify-center items-center px-5 py-3 relative",
          { "pt-10": error },
        )}
      >
        {error ? (
          <div className="w-full p-2 bg-negative/10 text-negative text-xs font-bold text-center absolute top-0">
            {error}
          </div>
        ) : null}
        <Icon name="dflow-logo" className="w-12 h-12 text-accent" />
        <Input
          type="password"
          autoComplete="current-password"
          autoCorrect="off"
          spellCheck="false"
          className="mt-7"
          placeholder="Enter your previous DFlow account password"
          value={web3authPassword}
          onChange={(e) => {
            setWeb3authPassword(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              void submit();
            }
          }}
        />
        <div className="mt-1.5 w-full flex flex-col gap-1">
          <Button
            variant="cta"
            disabled={
              !web3authPassword ||
              inputPasswordMutation.isLoading ||
              inputPasswordMutation.isSuccess
            }
            onClick={() => {
              void submit();
            }}
          >
            Continue
          </Button>
          <Button
            variant="flat"
            color="dark"
            onClick={() => {
              void router.navigate({
                to: "/migrate/forgot-password",
                search: (prev) => prev,
              });
            }}
          >
            Forgot password
          </Button>
        </div>
      </div>
    </Page>
  );
}
