import type ThresholdKey from "@tkey/core";
import type { SecurityQuestionsModule } from "@tkey/security-questions";
import { TORUS_NETWORK_TYPE, Web3Auth } from "@web3auth/single-factor-auth";
import type { SolanaWallet } from "@web3auth/solana-provider";
import { store } from "@/store";
import { getApiErrorMessage } from "@/utils";
import { web3authApi } from "./web3auth-api.ts";
import { Transaction } from "@solana/web3.js";

type UserInfo = Awaited<ReturnType<Web3Auth["getUserInfo"]>>;
const REFRESH_TOKEN_KEY = "x-refresh-token";
const EMAIL_KEY = "x-email";

export class Web3AuthClient {
  private static instance: Web3AuthClient | undefined;
  private static instancePromise:
    | Promise<Web3AuthClient | undefined>
    | undefined;
  private static callbacks = new Map<
    string,
    (error: unknown, instance: Web3AuthClient | undefined) => void
  >();
  private static lastCallbackParams:
    | {
        error: unknown;
        instance: Web3AuthClient | undefined;
      }
    | undefined;

  public solanaWallet: SolanaWallet;
  public tkey: ThresholdKey;
  public userInfo: UserInfo;
  public walletAddress: string;

  private static getConnectOptions(email: string, idToken: string) {
    return {
      verifier: import.meta.env.VITE_AUTH0_VERIFIER,
      verifierId: email.toLowerCase(),
      idToken,
    };
  }

  public static reset() {
    this.instancePromise = undefined;
    this.instance = undefined;
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    localStorage.removeItem(EMAIL_KEY);
    Web3AuthClient.emitCallbacks(undefined, undefined);
  }

  public static registerCallback(
    cb: (error: unknown, instance: Web3AuthClient | undefined) => void,
  ) {
    void Web3AuthClient.getInstanceAsync().catch(() => null);
    const newKey = Math.random().toString();
    Web3AuthClient.callbacks.set(newKey, cb);
    if (Web3AuthClient.lastCallbackParams) {
      cb(
        Web3AuthClient.lastCallbackParams.error,
        Web3AuthClient.lastCallbackParams.instance,
      );
    }

    return () => {
      Web3AuthClient.callbacks.delete(newKey);
    };
  }

  public static getInstance() {
    return (
      Web3AuthClient.lastCallbackParams ?? {
        error: undefined,
        instance: undefined,
      }
    );
  }

  public static async getInstanceAsync() {
    if (Web3AuthClient.instance) {
      return Web3AuthClient.instance;
    }

    if (Web3AuthClient.instancePromise) {
      return Web3AuthClient.instancePromise;
    }

    const promise = Web3AuthClient.reload();
    Web3AuthClient.instancePromise = promise;
    const result = await promise;

    if (!result) {
      Web3AuthClient.instancePromise = undefined;
    }

    return promise;
  }

  public static async init(
    idToken: string,
    refreshToken: string,
    email: string,
  ) {
    try {
      const {
        ThresholdKey,
        SfaServiceProvider,
        TorusStorageLayer,
        SolanaPrivateKeyProvider,
        SolanaWallet,
        SecurityQuestionsModule,
        WebStorageModule,
      } = await import("./web3auth-deps.ts");

      const web3AuthOptions = {
        clientId: import.meta.env.VITE_WEB3AUTH_CLIENT_ID,
        web3AuthNetwork: import.meta.env
          .VITE_WEB3_AUTH_NETWORK as TORUS_NETWORK_TYPE,
        chainConfig: {
          chainId: import.meta.env.VITE_RPC_CHAIN,
          rpcTarget: import.meta.env.VITE_SOLANA_READ_RPC,
          displayName: "Solana",
          blockExplorer: "https://explorer.solana.com/",
          ticker: "SOL",
          tickerName: "Solana",
        },
      };
      const instance = new Web3AuthClient();
      const serviceProvider = new SfaServiceProvider({ web3AuthOptions });
      const webStorage = new WebStorageModule();
      const securityQuestions = new SecurityQuestionsModule();
      const storageLayer = new TorusStorageLayer({
        hostUrl: "https://metadata.tor.us",
      });
      const solanaPrivateKeyProvider = new SolanaPrivateKeyProvider({
        config: { chainConfig: web3AuthOptions.chainConfig },
      });
      instance.solanaWallet = new SolanaWallet(solanaPrivateKeyProvider);
      instance.tkey = new ThresholdKey({
        serviceProvider,
        storageLayer,
        modules: {
          webStorage,
          securityQuestions,
        },
      });

      // @ts-expect-error unknown type error
      await serviceProvider.init(solanaPrivateKeyProvider);

      const web3auth = serviceProvider.web3AuthInstance;
      await web3auth.connect(Web3AuthClient.getConnectOptions(email, idToken));

      const tokenResponse = await store.dispatch(
        web3authApi.endpoints.web3authRefreshToken.initiate({ refreshToken }),
      );
      if (tokenResponse.error) {
        throw new Error(getApiErrorMessage(tokenResponse.error));
      }

      await serviceProvider.connect(
        Web3AuthClient.getConnectOptions(email, tokenResponse.data.id_token),
      );

      if (web3auth.connected) {
        const userInfo = await web3auth.getUserInfo();
        await instance.tkey.initialize();
        const accounts = await instance.solanaWallet.requestAccounts();
        instance.walletAddress = accounts[0];
        instance.userInfo = userInfo as unknown as UserInfo;
      }

      this.instance = instance;
      localStorage.setItem(REFRESH_TOKEN_KEY, tokenResponse.data.refresh_token);
      localStorage.setItem(EMAIL_KEY, email);

      Web3AuthClient.emitCallbacks(undefined, instance);
      return instance;
    } catch (error) {
      Web3AuthClient.emitCallbacks(error, undefined);
      throw error;
    }
  }

  private static emitCallbacks(
    error: unknown,
    instance: Web3AuthClient | undefined,
  ) {
    if (
      Web3AuthClient.lastCallbackParams?.error !== undefined &&
      Web3AuthClient.lastCallbackParams.error === error
    ) {
      return;
    }

    if (
      Web3AuthClient.lastCallbackParams?.instance !== undefined &&
      Web3AuthClient.lastCallbackParams.instance === instance
    ) {
      return;
    }
    Web3AuthClient.lastCallbackParams = { error, instance };
    Web3AuthClient.callbacks.forEach((cb) => cb(error, instance));
  }

  private static async reload(): Promise<Web3AuthClient | undefined> {
    let idToken: string;
    let refreshToken: string;
    let email: string;
    try {
      if (Web3AuthClient.instance ?? Web3AuthClient.instancePromise) {
        return Web3AuthClient.instance ?? Web3AuthClient.instancePromise;
      }

      refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY) ?? "";
      email = localStorage.getItem(EMAIL_KEY) ?? "";

      if (!refreshToken || !email) {
        throw new Error("No refresh token or email found");
      }

      const tokenResponse = await store.dispatch(
        web3authApi.endpoints.web3authRefreshToken.initiate({ refreshToken }),
      );
      if ("error" in tokenResponse) {
        throw new Error("Unable to refresh token");
      }

      idToken = tokenResponse.data.id_token;
      refreshToken = tokenResponse.data.refresh_token;
    } catch (error) {
      Web3AuthClient.emitCallbacks(error, undefined);
      throw error;
    }
    return await Web3AuthClient.init(idToken, refreshToken, email);
  }

  private constructor() {
    this.solanaWallet = undefined as unknown as SolanaWallet;
    this.tkey = undefined as unknown as ThresholdKey;
    this.userInfo = undefined as unknown as UserInfo;
    this.walletAddress = "";
  }

  async inputTKeyPassword(password: string) {
    const securityQuestions = this.tkey.modules
      .securityQuestions as SecurityQuestionsModule;

    await securityQuestions.inputShareFromSecurityQuestions(password);
    await this.tkey.reconstructKey();
  }

  async signTransaction(transaction: Transaction) {
    return await this.solanaWallet.signTransaction(transaction);
  }
}
