import { Button, Icon, IconProps, Page } from "@/components";
import { InfoCards } from "@/routes/profile/wallet-settings/-shared";
import { useEmbeddedWallet } from "@dynamic-labs/sdk-react-core";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { useEffect } from "react";

interface PasskeySearchParams {
  redirect?: string;
}

export const Route = createFileRoute(
  "/profile/wallet-settings/create-passkey/",
)({
  component: CreatePasskey,
  validateSearch: (
    searchParams?: Record<string, unknown>,
  ): PasskeySearchParams => ({
    redirect:
      typeof searchParams?.redirect === "string"
        ? searchParams.redirect
        : "/profile",
  }),
});

const CARDS = [
  {
    icon: "run" as IconProps["name"],
    title: "Convenient and fast",
    content: "Use Face ID or Touch ID to complete transactions quickly.",
  },
  {
    icon: "lock" as IconProps["name"],
    title: "Increased security",
    content:
      "Passkeys are encrypted end-to-end on your device or password manager to prevent phishing attempts.",
  },
];

function CreatePasskey() {
  const search: PasskeySearchParams = Route.useSearch();
  const wallet = useEmbeddedWallet();
  const navigate = useNavigate({ from: Route.fullPath });

  useEffect(() => {
    if (!wallet.isSessionActive) {
      void navigate({
        to: "/profile/wallet-settings/create-session",
        search: { ...search, isManagingPasskeys: false },
      });
    }
  }, [wallet.isSessionActive, navigate, search]);

  async function createPasskey() {
    await wallet.createPasskey(undefined);
    void navigate({ to: decodeURI(search.redirect ?? "") });
  }

  return (
    <Page
      flex
      fullScreenHeight
      hideTabs
      title="Secure your wallet"
      backButton={{ to: "/trade", search }}
    >
      <div className="w-full flex flex-col justify-between p-5 flex-1">
        <div className="flex flex-col items-center gap-6">
          <Icon name="shield" className="w-12 h-12 text-primary" />
          <p className="text-gray-200 text-sm text-center">
            Set up a passkey to avoid re-entering a one-time code to sign
            transactions.
          </p>
          <InfoCards cards={CARDS} />
        </div>
        <div className="flex flex-col gap-2">
          <Button variant="big" className="w-full" onClick={createPasskey}>
            Set up a passkey
          </Button>
          <Button
            variant="flat"
            color="dark"
            className="w-full"
            onClick={() => navigate({ to: decodeURI(search.redirect ?? "") })}
          >
            Continue without Passkey
          </Button>
        </div>
      </div>
    </Page>
  );
}
