import { useEffect, useState } from "react";
import { useNavigate, useRouter } from "@tanstack/react-router";
import { LogoSpinner } from "@/components";
import { useWeb3AuthClient } from "@/hooks/useWeb3AuthClient";

export const withWeb3authRedirectOnLogin = (options: { to: string }) => {
  return (Component: React.ComponentType) => {
    const WithWeb3authRedirectOnLoginWrapper = (props: {}) => {
      const [isLoading, setIsLoading] = useState(true);
      const [client, error] = useWeb3AuthClient();
      const navigate = useNavigate();
      const isLoggedIn = Boolean(client?.userInfo);
      const { pathname } = useRouter().parseLocation();

      useEffect(() => {
        if (isLoggedIn) {
          if (pathname === options.to) {
            setIsLoading(false);
          } else {
            void navigate({
              to: options.to,
              replace: true,
              search: (prev) => prev,
            });
          }
        } else if (error) {
          setIsLoading(false);
        }
      }, [error, isLoggedIn, navigate, pathname]);

      if (isLoading) {
        return (
          <div className="flex items-center justify-center h-full pb-tab-bar">
            <LogoSpinner size="large" />
          </div>
        );
      }

      return <Component {...props} />;
    };

    WithWeb3authRedirectOnLoginWrapper.displayName = `withWeb3authRedirectOnLogin(${Component.displayName})`;

    return WithWeb3authRedirectOnLoginWrapper;
  };
};
