import type { Wallet, WalletConnector } from "@dynamic-labs/sdk-react-core";
import {
  Transaction,
  VersionedTransaction,
  Connection,
  SendOptions,
} from "@solana/web3.js";

export type TransactionOrVersionedTransaction =
  | Transaction
  | VersionedTransaction;

export interface EmbeddedWalletConnector extends WalletConnector {
  signAndSendTransaction: <T extends TransactionOrVersionedTransaction>(
    args: {
      transaction: T;
    },
    opts?: SendOptions,
  ) => Promise<T>;
  signTransaction: <T extends TransactionOrVersionedTransaction>(args: {
    transaction: T;
  }) => Promise<Buffer>;
  sendTransaction: <T extends TransactionOrVersionedTransaction>(
    transaction: T,
    connection: Connection,
    opts?: SendOptions,
  ) => Promise<string>;
}

/**  Wrapper class for the dynamic embedded wallet */
export class EmbeddedWallet {
  initialized = false;
  private _wallet: Wallet | null = null;
  private _connectWalletPromise: Promise<void>;
  private _connectWalletPromiseResolve = null as (() => void) | null;

  constructor() {
    this._connectWalletPromise = new Promise((resolve) => {
      this._connectWalletPromiseResolve = resolve;
    });
  }

  init(wallet: Wallet | null) {
    console.log("Initializing wallet", wallet);
    this._wallet = wallet;
    this.initialized = true;
    this._connectWalletPromiseResolve?.();
  }

  reset() {
    this._wallet = null;
    if (this.initialized) {
      this._connectWalletPromise = new Promise((resolve) => {
        this._connectWalletPromiseResolve = resolve;
      });
    }
    this.initialized = false;
  }

  async getConnector(): Promise<EmbeddedWalletConnector | null> {
    await this._connectWalletPromise;
    if (!this._wallet) {
      return null;
    }
    return this._wallet.connector as EmbeddedWalletConnector;
  }

  set wallet(wallet: Wallet | null) {
    this._wallet = wallet;
  }

  get address(): string | null {
    if (!this._wallet) {
      return null;
    }

    return this._wallet.address;
  }
}

// Class instance of the embedded wallet, used in the rtkq store
export const embeddedWallet = new EmbeddedWallet();
