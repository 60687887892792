import { Icon, IconProps, Link } from "@/components";
import { cn } from "@/utils";
import React from "react";

export interface ProfileListItemProps {
  label: string;
  value: string;
  target?: React.AnchorHTMLAttributes<HTMLAnchorElement>["target"];
  icon?: IconProps["name"];
  onClick?: () => void;
}

interface ProfileListProps {
  items: ProfileListItemProps[];
  title?: string;
}

export function ProfileList({ items, title }: ProfileListProps) {
  return (
    <div>
      {title && (
        <h2 className="px-5 pb-3 text-sm leading-none uppercase text-accent font-bold">
          {title}
        </h2>
      )}
      <ul className="divide-y divide-cloud border-cloud mt-0 border-y">
        {items.map(({ onClick, label, value, target, icon }) => (
          <li key={label} className="text-sm leading-none font-bold">
            <Link
              to={value}
              onClick={onClick}
              search={(prev) => prev}
              className="flex gap-4 items-center justify-between px-5 py-3.5 h-info-row"
              target={target}
            >
              {label}
              <Icon
                name={
                  icon ??
                  (target && target === "_blank" ? "external" : "chevron")
                }
                className={cn("w-4", icon ? "" : !target && "-rotate-90")}
              />
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}
