import {
  Button,
  ErrorMessage,
  Icon,
  OneTimeCodePrompt,
  Page,
} from "@/components";
import { InfoCard, InfoCards } from "@/routes/profile/wallet-settings/-shared";
import { cn } from "@/utils";
import { useEmbeddedWallet } from "@dynamic-labs/sdk-react-core";
import { createFileRoute } from "@tanstack/react-router";
import { useState } from "react";

export const Route = createFileRoute("/profile/wallet-settings/private-key/")({
  component: PrivateKey,
});

type Views = "agree_to_continue" | "reveal_private_key";
const titles = {
  agree_to_continue: "Agree to Continue",
  reveal_private_key: "Private Key",
};

const CARDS: InfoCard[] = [
  {
    icon: "wallet",
    title: "Your wallet is non-custodial",
    content: "You are always in control of it.",
  },
  {
    icon: "key",
    title: "Do not share your private key",
    content: "Your private key controls your account and assets.",
  },
  {
    icon: "pishing",
    title: "Beware of phishing attempts",
    content:
      "The DFlow team will never ask you to share your private key with anybody.",
  },
];

const AlternateOneTimeCodePrompt = (
  props: Parameters<typeof OneTimeCodePrompt>[0],
) => (
  <OneTimeCodePrompt
    {...props}
    altDescription="A one-time code is required to reveal private keys."
    altButtonText="Authorize reveal"
  />
);

function PrivateKey() {
  const { revealWalletKey } = useEmbeddedWallet();
  const [view, setView] = useState<Views>("agree_to_continue");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [affirmationChecked, setAffirmationChecked] = useState(false);

  const revealPrivateKey = async () => {
    try {
      setView("reveal_private_key");
      setError("");
      setIsLoading(true);
      await revealWalletKey({
        htmlContainerId: "reveal-private-key-container",
        type: "privateKey",
      });
    } catch (e) {
      if (
        e instanceof Error &&
        e.message.includes("The operation either timed out or was not allowed")
      ) {
        console.log(e.message);
        setError("Reveal was cancelled by the user");
      } else {
        setError("Something happened. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Page
      title={titles[view]}
      backButton={{ to: "/profile/wallet-settings", search: (prev) => prev }}
      hideTabs
    >
      <div className="flex flex-1 flex-col items-center gap-10 px-5 pt-5">
        <Icon name="shield" className="w-12 h-12 text-primary" />
        {view === "agree_to_continue" ? (
          <InfoCards cards={CARDS} />
        ) : (
          <div className="flex flex-col items-center gap-2">
            <p
              className={cn("text-gray-200 text-sm", {
                "invisible": !isLoading,
              })}
            >
              You may be asked for your passkey
            </p>

            <div
              id="reveal-private-key-container"
              className="bg-black flex rounded p-2 min-h-[184px] min-w-[316px]"
            />
            {error && (
              <ErrorMessage variant="rounded" className="self-start">
                {error}
              </ErrorMessage>
            )}
          </div>
        )}
        <div className="w-full fixed bottom-0 flex flex-col items-center gap-5 px-5 py-5">
          {view === "agree_to_continue" && (
            <form className="flex items-start gap-2 text-sm font-bold">
              <button
                type="button"
                id="affirmation-checkbox"
                role="checkbox"
                aria-checked={affirmationChecked}
                onClick={() => {
                  setAffirmationChecked(true);
                }}
              >
                <Icon
                  name={affirmationChecked ? "checkbox-on" : "checkbox-off"}
                  className="w-5 h-5 text-primary"
                />
              </button>
              <label htmlFor="affirmation-checkbox">
                I have read and understand the above. I am responsible for
                safeguarding my private key.
              </label>
            </form>
          )}
          {view === "agree_to_continue" ? (
            <AlternateOneTimeCodePrompt>
              {({ isCodeRequired }) => (
                <Button
                  variant="cta"
                  className="flex w-full"
                  disabled={!affirmationChecked}
                  onClick={() => {
                    if (isCodeRequired) return;
                    void revealPrivateKey();
                  }}
                >
                  Agree & Continue
                </Button>
              )}
            </AlternateOneTimeCodePrompt>
          ) : error ? (
            <AlternateOneTimeCodePrompt>
              {({ isCodeRequired }) => (
                <Button
                  variant="cta"
                  className="flex w-full"
                  onClick={() => {
                    if (isCodeRequired) return;
                    void revealPrivateKey();
                  }}
                >
                  Retry
                </Button>
              )}
            </AlternateOneTimeCodePrompt>
          ) : (
            <Button
              variant="cta"
              to="/profile"
              search={(prev) => prev}
              className="flex w-full"
              disabled={isLoading}
            >
              Exit
            </Button>
          )}
        </div>
      </div>
    </Page>
  );
}
