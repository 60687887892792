import { BaseIconProps } from "../icon";

export function CircleCheckIcon(props: BaseIconProps) {
  return (
    <svg
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.6167 7.23341L16.1083 8.74175V8.80008C16.2083 9.22508 16.25 9.66675 16.25 10.1084C16.25 13.7251 13.3083 16.6751 9.68333 16.6751C6.05833 16.6751 3.11667 13.7334 3.11667 10.1084C3.11667 6.48342 6.05833 3.54175 9.68333 3.54175C10.975 3.54175 12.2333 3.92508 13.3083 4.64175L13.375 4.69175L14.7167 3.35008L14.6167 3.27508C13.1667 2.22508 11.4583 1.66675 9.68333 1.66675C5.03333 1.66675 1.25 5.45008 1.25 10.1001C1.25 14.7501 5.03333 18.5334 9.68333 18.5334C14.3333 18.5334 18.1167 14.7501 18.1167 10.1001C18.1167 9.18341 17.9667 8.27508 17.6583 7.38342L17.6083 7.22508L17.6167 7.23341Z"
        fill="currentColor"
      />
      <path
        d="M17.4333 2.70008L8.85 11.2751L6.10833 8.53341L4.78333 9.85842L8.78333 13.8584L8.85 13.9334L18.7583 4.02508L17.4333 2.70008Z"
        fill="currentColor"
      />
    </svg>
  );
}
