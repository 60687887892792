import { useTokenAccountByMintQuery, useWalletInfoQuery } from "@/api";
import {
  getMaxWrapAmount,
  getReducedPriorityFee,
  isValidNumberInput,
} from "@/routes/profile/-reducer";
import { useTypedSelector } from "@/store";
import {
  NATIVE_SOL_ACCOUNT_RENT,
  SOL_ADDRESS,
  SOL_TRANSACTION_FEE,
} from "@/utils";

export function useValidation() {
  const { wrapInput, wrapUnwrapToggle, priorityFee } = useTypedSelector(
    (state) => state.profile,
  );
  const solBalance = useWalletInfoQuery().data?.rawBalance ?? "0";
  const wsolBalance =
    useTokenAccountByMintQuery(SOL_ADDRESS).data?.tokenRawBalance ?? "0";
  const wrapMaxAmount = getMaxWrapAmount(
    BigInt(solBalance),
    BigInt(wsolBalance),
    priorityFee,
  );

  if (wrapUnwrapToggle === "wrap") {
    return validateWrapAmount(wrapInput.scaledAmount, wrapMaxAmount);
  } else {
    return validateUnwrapAmount(solBalance, priorityFee);
  }
}

interface WrapValidationState {
  valid: boolean;
  kind:
    | "none"
    | "invalid"
    | "exceeds_maximum"
    | "insufficient_sol"
    | "min_sol_required";
  message: string;
}

function validateWrapAmount(
  amountStr: string,
  maxAmount: bigint,
): WrapValidationState {
  if (!isValidNumberInput(amountStr)) {
    return {
      valid: false,
      kind: "invalid",
      message: "Invalid amount",
    };
  }
  let amount: bigint;
  try {
    amount = BigInt(amountStr);
  } catch {
    return {
      valid: false,
      kind: "invalid",
      message: "Invalid amount",
    };
  }
  if (amount === 0n) {
    return {
      valid: false,
      kind: "invalid",
      message: "Amount must be greater than 0",
    };
  }
  if (amount > maxAmount) {
    return {
      valid: false,
      kind: "exceeds_maximum",
      message: "Amount exceeds maximum",
    };
  }
  if (amount !== maxAmount && maxAmount - amount < NATIVE_SOL_ACCOUNT_RENT) {
    return {
      valid: false,
      kind: "min_sol_required",
      message:
        "Amount must be equal to maximum or leave more than account minimum",
    };
  }
  return { valid: true, kind: "none", message: "" };
}

interface UnwrapValidationState {
  valid: boolean;
  kind: "none" | "insufficient_sol";
  message: string;
}

const validateUnwrapAmount = (
  balanceStr: string,
  priorityFee: number,
): UnwrapValidationState => {
  const balance = BigInt(balanceStr);
  const fees = BigInt(getReducedPriorityFee(priorityFee)) + SOL_TRANSACTION_FEE;
  if (balance - fees < 0n) {
    return {
      valid: false,
      kind: "insufficient_sol",
      message: "Insufficient SOL for fees",
    };
  }
  return { valid: true, kind: "none", message: "" };
};
