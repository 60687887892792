import { useEffect, useRef } from "react";

export function useDebouncedFocus(onFocus: () => void, timeout: number) {
  const focusTimeoutRef = useRef(0);
  useEffect(() => {
    const handleFocus = () => {
      if (Date.now() - focusTimeoutRef.current < timeout) {
        return;
      }
      focusTimeoutRef.current = Date.now();

      onFocus();
    };
    window.addEventListener("focus", handleFocus, false);

    return () => {
      window.removeEventListener("focus", handleFocus, false);
    };
  }, [onFocus, timeout]);
}
