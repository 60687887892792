import { EmptyState } from "@/components";
import { cn } from "@/utils";
import { AreaGraph } from "./area-graph";
import { timeframes, type PricePoint, type Timeframe } from "./token-price";
import { UsePriceChartQueryResult } from "@/api";

interface PriceGraphProps {
  setHoveredPricePoint: (pricePoint?: PricePoint) => void;
  mode: Timeframe["label"];
  isLoading?: boolean;
  priceChartResults: UsePriceChartQueryResult;
  xMargins?: number;
}

export function PriceGraph({
  mode,
  setHoveredPricePoint,
  isLoading,
  priceChartResults,
  xMargins,
}: PriceGraphProps) {
  const timeframe = timeframes.find((t) => t.label === mode)?.value;

  const { data, currentData, isError, isFetching } = priceChartResults;

  return (
    <div className="h-full flex flex-col">
      <div className="flex-1 flex flex-col">
        {isError ? (
          "Something went wrong"
        ) : !data?.length && isLoading ? (
          <div className="h-full loading-skeleton" />
        ) : data?.length ? (
          <div
            className={cn(
              "flex-1 transition-opacity opacity-100",
              isFetching && !currentData && "opacity-40",
            )}
          >
            <AreaGraph
              data={data}
              daysShown={
                timeframe === "max"
                  ? Number.MAX_SAFE_INTEGER
                  : timeframe !== undefined
                    ? +timeframe
                    : 0
              }
              setHoveredPricePoint={setHoveredPricePoint}
              xMargins={xMargins}
            />
          </div>
        ) : (
          <div className="h-full flex items-center">
            <EmptyState illustration="Chart">No data available.</EmptyState>
          </div>
        )}
      </div>
    </div>
  );
}
