import { useDynamicContext } from "@dynamic-labs/sdk-react-core";

export function useIsFastSigninEnabled() {
  const { user, sdkHasLoaded } = useDynamicContext();
  return (
    sdkHasLoaded &&
    Boolean(
      user?.verifiedCredentials.some(
        (next) => next.walletProperties?.isSessionKeyCompatible,
      ),
    )
  );
}
