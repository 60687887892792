import { useEmbeddedWallet } from "@dynamic-labs/sdk-react-core";
import { useState, useEffect } from "react";

export function useSession() {
  const [hasPasskeys, setHasPasskeys] = useState<boolean | undefined>(
    undefined,
  );
  const { isSessionActive, getPasskeys } = useEmbeddedWallet();
  useEffect(() => {
    async function run() {
      const keys = await getPasskeys();
      setHasPasskeys(keys.length > 0);
    }

    void run();
  }, [getPasskeys, isSessionActive]);

  return { isSessionActive, hasPasskeys };
}
