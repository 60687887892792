import { fetchWithRateLimit } from "@/rpc-connection/fetch";
import { Connection } from "@solana/web3.js";
import { COMMITMENT } from "./constants";

export class CustomConnection {
  private _connection: Connection;

  constructor(rpcUrl: string | URL) {
    console.info(`Initializing connection with RPC: ${rpcUrl}`);
    const connection = new Connection(rpcUrl.toString(), {
      disableRetryOnRateLimit: true,
      fetch: fetchWithRateLimit,
      commitment: COMMITMENT,
    });

    this._connection = connection;
  }

  getConnection(): Connection {
    return this._connection;
  }
}
