import { BaseIconProps } from "../icon";

export function InfoIcon(props: BaseIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15 16"
      fill="none"
      {...props}
    >
      <path
        d="M8.19095 4.54523H6.80905V5.92714H8.19095V4.54523Z"
        fill="currentColor"
      />
      <path
        d="M8.19095 7.30905H6.80905V11.4548H8.19095V7.30905Z"
        fill="currentColor"
      />
      <path
        d="M6.80905 2.52073V1.125C3.54774 1.44975 0.949749 4.04774 0.625 7.30905H2.02073C2.33166 4.80088 4.30779 2.83166 6.80905 2.52073Z"
        fill="currentColor"
      />
      <path
        d="M12.9793 7.30905H14.375C14.0503 4.04774 11.4454 1.44975 8.19095 1.125V2.52073C10.6991 2.83166 12.6683 4.80779 12.9793 7.30905Z"
        fill="currentColor"
      />
      <path
        d="M8.19095 13.4793V14.875C11.4454 14.5503 14.0503 11.9523 14.375 8.69095H12.9793C12.6683 11.1991 10.6922 13.1683 8.19095 13.4793Z"
        fill="currentColor"
      />
      <path
        d="M2.02073 8.69095H0.625C0.949749 11.9454 3.54774 14.5503 6.80905 14.875V13.4793C4.30088 13.1683 2.33166 11.1922 2.02073 8.69095Z"
        fill="currentColor"
      />
    </svg>
  );
}
