import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { api } from "./api";
import { QUOTE_TIMEOUT } from "@/utils";

const AGGREGATOR_API_BASE_URL = "https://quote-api.dflow.net";

export const aggregatorApi = api.injectEndpoints({
  endpoints: (builder) => ({
    quote: builder.query<Quote, QuoteParams>({
      query: ({ slippageBps, ...params }) => {
        return {
          url: `${AGGREGATOR_API_BASE_URL}/quote`,
          params: {
            ...params,
            ...(slippageBps !== undefined && {
              slippageBps: slippageBps.toString(),
            }),
          },
        };
      },
      // Keep the data in cache until just before the quote expires
      keepUnusedDataFor: (QUOTE_TIMEOUT - 1) / 1000,
      providesTags: ["Quote"],
      extraOptions: { maxRetries: 1 },
      transformResponse: (response: Quote, meta, arg) => {
        return {
          ...response,
          requestUrl: meta?.request.url ?? "",
          requestParams: arg,
        };
      },
      transformErrorResponse(error, meta): QuoteError {
        const response = meta?.response;

        if (!response) {
          return {
            status: 500,
            kind: "unknown_error",
            data: "Failed to fetch quote",
          };
        }

        const errorMsg = extractErrorMsg(error);
        console.log("error", error, "errorMsg", errorMsg);

        if (errorMsg?.includes("Failed to compute route:")) {
          return {
            status: response.status,
            kind: "failed_to_compute_route",
            data: errorMsg,
          };
        }
        return {
          status: response.status,
          kind: "unknown_error",
          data: error,
        };
      },
    }),
    swap: builder.mutation<Swap, SwapParams>({
      query: (body) => {
        return {
          url: `${AGGREGATOR_API_BASE_URL}/swap`,
          method: "POST",
          body: JSON.stringify(body),
        };
      },
      transformErrorResponse(error, meta) {
        const response = meta?.response;

        if (!response) {
          return {
            status: 500,
            data: "Failed to create swap",
          };
        }

        return {
          status: response.status,
          kind: "unknown_error",
          data:
            typeof error === "object" && "msg" in error
              ? (error.msg as string)
              : response.statusText || "Failed to fetch quote",
        };
      },
      extraOptions: { maxRetries: 1 },
    }),
  }),
});

export const { useQuoteQuery, useLazyQuoteQuery, useSwapMutation } =
  aggregatorApi;

export interface MarketOrder {
  id: number;
  price: number;
}

export interface QuoteParams {
  inputMint: string;
  outputMint: string;
  amount: string;
  slippageBps?: number;
  platformFeeBps?: number;
}

export interface Quote {
  inputMint: string;
  inAmount: string;
  outputMint: string;
  outAmount: string;
  otherAmountThreshold: string;
  minOutAmount: string;
  slippageBps: number;
  platformFee: { amount: string; feeBps: number } | null;
  priceImpactPct: string; // 0.01 = 1%, 1 = 100%
  routePlan: {
    venue: string;
    marketKey: string;
    inputMint: string;
    outputMint: string;
    inAmount: string;
    outAmount: string;
    leg: {
      token_in: number[];
      token_out: number[];
      level_general: {
        Whirlpools: {
          venue_info: {
            pool_address: number[];
            vault_a: number[];
            vault_b: number[];
          };
          venue_side_info: {
            tick_addresses: number[];
          };
        };
      };
      price_info: {
        price: number;
        quantity_in: number;
        quantity_out: number;
      };
    };
    inputMintDecimals: number;
    outputMintDecimals: number;
  }[];
  requestUrl: string;
  requestParams: QuoteParams;
}

export interface Swap {
  swapTransaction: string;
  lastValidBlockHeight: number;
  prioritizationFeeLamports: number;
}

export interface SwapParams {
  userPublicKey: string;
  feeAccount?: string;
  createFeeAccount?: {
    referralAccount: string;
  };
  dynamicComputeUnitLimit: boolean;
  quoteResponse: Quote;
  wrapAndUnwrapSol?: boolean;
  prioritizationFeeLamports?: number;
}

export interface QuoteError {
  status: number;
  kind: "failed_to_compute_route" | "unknown_error";
  data: unknown;
}

export function isQuoteError(error: unknown): error is QuoteError {
  return typeof error === "object" && error !== null && "kind" in error;
}

function extractErrorMsg(error: FetchBaseQueryError): string | undefined {
  const errorData =
    typeof error === "object" && "data" in error
      ? (error.data as object)
      : undefined;

  return typeof errorData === "object" && "msg" in errorData
    ? (errorData.msg as string)
    : undefined;
}
