import { Icon, LogoSpinner, icons, Link } from "@/components";
import { cn } from "@/utils";
import { type LinkProps } from "@tanstack/react-router";
import { VariantProps, cva } from "class-variance-authority";
import { DesktopNav } from "./desktop-nav";
import { MobileNav } from "./mobile-nav";
import { getRedirectPath } from "@/utils/url";
import { useAppDispatch } from "@/store";
import {
  resetTradeLink,
  resetPortfolioLink,
  resetRewardsLink,
  resetOrdersLink,
} from "@/routes/-nav-reducer";

interface BackButton extends LinkProps {
  "aria-label"?: string;
  icon?: keyof typeof icons;
  onClick?: () => void;
  isRedirect?: boolean;
}

interface PageProps extends VariantProps<typeof variants> {
  backButton?: BackButton;
  title?: string;
  headerContent?: React.ReactNode;
  profileButton?: boolean;
  hideTabs?: boolean;
  isAuthenticating?: boolean;
  isLoading?: boolean;
  onCurrentTabClick?: () => void;
}

const variants = cva(
  "mt-header overscroll-auto select-none w-full max-w-[1200px] mx-auto lg:px-7 lg:py-20 overflow-y-auto",
  {
    variants: {
      flex: { true: "flex" },
      hideTabs: {
        true: "pb-safe-bottom h-page-tabless",
        false: "h-page",
      },
      fullScreenHeight: { true: "" },
    },
  },
);

export function Page({
  title,
  backButton,
  headerContent,
  profileButton,
  flex = false,
  hideTabs = false,
  fullScreenHeight = false,
  isAuthenticating = false,
  isLoading = false,
  onCurrentTabClick,
  children,
}: React.PropsWithChildren<PageProps>) {
  const dispatch = useAppDispatch();
  if (isAuthenticating || isLoading) {
    return (
      <div className="flex items-center justify-center h-full pb-tab-bar">
        <LogoSpinner size="large" />
      </div>
    );
  }

  const onBackClick = () => {
    if (backButton?.isRedirect) {
      switch (true) {
        case location.pathname.startsWith("/trade"):
          dispatch(resetTradeLink());
          break;
        case location.pathname.startsWith("/portfolio"):
          dispatch(resetPortfolioLink());
          break;
        case location.pathname.startsWith("/rewards"):
          dispatch(resetRewardsLink());
          break;
        case location.pathname.startsWith("/orders"):
          dispatch(resetOrdersLink());
          break;
      }
    }
    backButton?.onClick?.();
  };

  return (
    <>
      <header className="select-none h-header fixed top-0 left-0 w-screen bg-gray-600 z-20 hidden lg:block">
        <div
          className="h-full relative px-7 flex items-center pt-safe-top"
          aria-label="Home"
        >
          <Link to="/" className="absolute top-1/2 -translate-y-1/2 left-7">
            <Icon name="logo" className="w-8 text-accent" />
          </Link>
          <nav className="w-full pl-14 min-[1350px]:pl-3.5 pr-3.5 h-full min-[1350px]:max-w-[1200px] mx-auto flex items-center">
            <DesktopNav />
          </nav>
          <div className="absolute right-7 top-1/2 -translate-y-1/2">
            <button className="uppercase text-primary hover:text-primary-hover transition-colors text-sm font-bold flex items-center">
              Mobile App <Icon name="download" className="ml-1" />
            </button>
          </div>
          <div className="absolute left-0 right-0 bottom-0 h-px bg-cloud" />
        </div>
      </header>
      <header
        className={cn(
          "select-none h-header pt-safe-top px-3 flex items-center fixed top-0 left-0 w-screen bg-gray-600 justify-between gap-10 z-10 lg:hidden",
          !backButton && !headerContent && !profileButton && "justify-center",
        )}
      >
        {backButton ? (
          <Link
            to={backButton.to}
            aria-label={backButton["aria-label"] ?? "Back"}
            params={backButton.params}
            search={backButton.search}
            onClick={onBackClick}
            className="p-2"
          >
            <Icon
              name={backButton.icon ?? "back-arrow-boxed"}
              color="primary"
              className="w-5 h-5 text-primary"
            />
          </Link>
        ) : null}
        {title ? (
          <h1
            className={cn(
              "text-primary font-heading font-bold text-xl leading-none flex items-center justify-center px-2",
              backButton &&
                "absolute left-1/2 -translate-x-1/2 w-full text-center max-w-[66%] pointer-events-none",
              !backButton && !headerContent && !profileButton && "text-center",
            )}
          >
            {title}
          </h1>
        ) : null}
        <div className="flex items-center">
          {headerContent ? headerContent : null}
          {profileButton ? (
            <Link
              to="/profile"
              search={{ redirect: getRedirectPath() }}
              aria-label="Profile"
              className="shrink-0 p-2"
            >
              <Icon name="user" className="w-5" color="primary" />
            </Link>
          ) : null}
        </div>
      </header>
      <main
        className={cn(
          variants({
            flex,
            fullScreenHeight,
            hideTabs,
          }),
        )}
      >
        {children}
      </main>
      {!hideTabs ? <MobileNav onCurrentTabClick={onCurrentTabClick} /> : null}
    </>
  );
}
