import { SOL_DECIMALS, normalizeScaled } from "@/utils";
import { Drawer } from "../drawer";
import { ErrorMessage } from "../form";

export type TransactionFeeStatus = "charged" | "not_charged" | "unknown";

export interface TransactionErrorMessageProps {
  title: string;
  message: string | React.ReactNode;
  color: "negative" | "warning";
  feeStatus?: TransactionFeeStatus;
  baseFee?: bigint;
  priorityFee?: bigint;
  rent?: bigint;
  onDismiss?: () => void;
}

const statusLabels = {
  charged: "Charged",
  not_charged: "Not Charged",
  unknown: "Unknown if Charged",
};

export function TransactionErrorMessage({
  title,
  message,
  feeStatus,
  color,
  baseFee,
  priorityFee,
  rent,
  onDismiss,
}: TransactionErrorMessageProps) {
  return (
    <Drawer.Root
      onOpenChange={(open) => {
        if (!open && onDismiss) {
          onDismiss();
        }
      }}
    >
      <Drawer.Trigger>
        <ErrorMessage variant="rounded" color={color} className="mx-auto">
          {title}
        </ErrorMessage>
      </Drawer.Trigger>
      <Drawer.Content title={title}>
        <div className="flex pb-4 gap-5 flex-col">
          <p className="text-gray-200 text-sm">{message}</p>
          {feeStatus ? (
            <div className="flex gap-1.5">
              <span className="text-sm text-primary font-bold uppercase">
                Solana Fee
              </span>
              <span className="text-sm text-gray-200">
                {statusLabels[feeStatus]}
              </span>
            </div>
          ) : null}
          {baseFee && priorityFee ? (
            <div className="flex gap-1.5">
              <span className="text-sm text-primary font-bold uppercase">
                Base + Priority
              </span>
              <span className="text-sm text-gray-200">
                {normalizeScaled(baseFee + priorityFee, SOL_DECIMALS)} SOL
              </span>
            </div>
          ) : null}

          {rent ? (
            <div className="flex gap-1.5">
              <span className="text-sm text-primary font-bold uppercase">
                Rent
              </span>
              <span className="text-sm text-gray-200">
                {normalizeScaled(rent, SOL_DECIMALS)} SOL
              </span>
            </div>
          ) : null}
        </div>
      </Drawer.Content>
    </Drawer.Root>
  );
}
