import { BaseIconProps } from "../icon";

export function MoneyArrowsIcon(props: BaseIconProps) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.9 8.56167H14.0617V6.83833H11.8617V5.73833H10.1383V6.83833H9.9C8.81833 6.83833 7.93833 7.71833 7.93833 8.8V9.9C7.93833 10.9817 8.81833 11.8617 9.9 11.8617H12.1C12.2283 11.8617 12.3383 11.9717 12.3383 12.1V13.2C12.3383 13.3283 12.2283 13.4383 12.1 13.4383H7.93833V15.1617H10.1383V16.2617H11.8617V15.1617H12.1C13.1817 15.1617 14.0617 14.2817 14.0617 13.2V12.1C14.0617 11.0183 13.1817 10.1383 12.1 10.1383H9.9C9.77167 10.1383 9.66167 10.0283 9.66167 9.9V8.8C9.66167 8.67167 9.77167 8.56167 9.9 8.56167Z"
        fill="currentColor"
      />
      <path
        d="M11 0C7.1775 0 3.80417 1.96167 1.83333 4.9225V2.75H0V8.25H5.5V6.41667H3.08917C4.675 3.685 7.60833 1.83333 11 1.83333C16.0692 1.83333 20.1667 5.93083 20.1667 11H22C22 4.93167 17.0683 0 11 0Z"
        fill="currentColor"
      />
      <path
        d="M0 11C0 17.0683 4.93167 22 11 22C14.8225 22 18.1958 20.0383 20.1667 17.0775V19.25H22V13.75H16.5V15.5833H18.9108C17.325 18.315 14.3917 20.1667 11 20.1667C5.93083 20.1667 1.83333 16.0692 1.83333 11H0Z"
        fill="currentColor"
      />
    </svg>
  );
}
