import { TokenInfo } from "@/app/services/token-api";
import { addTokenToCache } from "@/app/token-cache/token-cache-actions";
import { CachedTokenInfo } from "@/app/token-cache/token-cache-slice";
import { RootState as AppRootState } from "@/store";
import { ThunkDispatch, UnknownAction } from "@reduxjs/toolkit";

export function addTokensToCache(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: ThunkDispatch<any, any, UnknownAction>,
  tokens: TokenInfo[],
  fulfilledTimeStamp: number = Date.now(),
) {
  for (const token of tokens) {
    dispatch(
      addTokenToCache({
        ...token,
        fulfilledTimeStamp,
      }),
    );
  }
}

export function getTokenInfoFromCache(
  getState: () => unknown,
  tokenId: string,
): CachedTokenInfo | undefined {
  return (getState() as AppRootState).tokenCache[tokenId];
}

export function isTokenInfoComplete(tokenInfo: CachedTokenInfo): boolean {
  return tokenInfo.name !== undefined && tokenInfo.symbol !== undefined;
}
