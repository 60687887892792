import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  category: "trending",
};

const tokenSearchSlice = createSlice({
  name: "tokenSearchList",
  initialState,
  reducers: {
    updateCategory(state, action: PayloadAction<string>) {
      state.category = action.payload;
    },
  },
});

export const tokenSearchReducer = tokenSearchSlice.reducer;
export const { updateCategory } = tokenSearchSlice.actions;
export const tokenSearchActions = tokenSearchSlice.actions;
