import { TokenInfo } from "@/api";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SimulatedTransactionResponse } from "@solana/web3.js";

const getNumber = (value?: string | number | null) => {
  if (!value && value !== 0) {
    return null;
  }

  const numberValue = Number(value);

  if (isNaN(numberValue)) {
    return null;
  }

  return numberValue;
};

const getNumberForFormatting = (value?: string | number | null) => {
  let numberValue = getNumber(value);
  if (numberValue === null) {
    numberValue = 0;
  }

  return numberValue;
};

const truncateTrailingZeros = (
  value: string,
  minDecimals: number | undefined = 0,
) => {
  const valueWithoutTrailingZeros = value.replace(/0+$/, "");
  return valueWithoutTrailingZeros.length < minDecimals
    ? "0".repeat(minDecimals)
    : valueWithoutTrailingZeros;
};

export const formatUsdMarketPrice = (value?: string | number | null) => {
  const numberValue = getNumberForFormatting(value);
  const fractionDigits = 4;

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumSignificantDigits: 4,
    maximumSignificantDigits: 4,
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
    // @ts-expect-error: this type is accidentally missing from typescript. It
    // was added in https://github.com/microsoft/TypeScript/pull/56902 but the
    // fix hasn't been released yet. This comment can be removed after we update
    // to a newer version of typescript that supports it.
    roundingPriority: "morePrecision",
  }).format(numberValue);
};

interface FormatUsdOptions {
  useGrouping?: boolean;
  moneyMinDecimals?: number;
  moneyMaxDecimals?: number;
  showFractionTruncatedMark?: boolean;
}

export const formatUsdToParts = (
  value?: string | number | null,
  options?: FormatUsdOptions,
) => {
  const moneyMinDecimals = options?.moneyMinDecimals ?? 2;
  const moneyMaxDecimals = options?.moneyMaxDecimals ?? 2;
  const parts = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumSignificantDigits: 4,
    maximumSignificantDigits: 4,
    minimumFractionDigits: moneyMinDecimals,
    maximumFractionDigits: 20,
    // @ts-expect-error: this type is accidentally missing from typescript. It
    // was added in https://github.com/microsoft/TypeScript/pull/56902 but the
    // fix hasn't been released yet. This comment can be removed after we update
    // to a newer version of typescript that supports it.
    roundingPriority: "morePrecision",
    useGrouping: options?.useGrouping,
  }).formatToParts(getNumberForFormatting(value));

  let isFractionTruncated = false;
  const newParts = parts.map((part) => {
    if (part.type === "fraction") {
      const leadingZeros = part.value.match(/^0*/)?.[0] ?? "";
      if (
        leadingZeros.length !== part.value.length &&
        leadingZeros.length >= moneyMaxDecimals
      ) {
        isFractionTruncated = true;
      }

      const newValue = part.value
        .substring(0, moneyMaxDecimals)
        .padEnd(moneyMinDecimals, "0");
      return {
        ...part,
        value: newValue,
      };
    }

    return part;
  });

  if (
    options?.showFractionTruncatedMark &&
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    isFractionTruncated &&
    newParts.some((part) => part.type === "integer" && part.value === "0")
  ) {
    newParts[0].value = "<" + newParts[0].value;
    const fractionPart = newParts.find((part) => part.type === "fraction");

    if (fractionPart) {
      fractionPart.value = fractionPart.value.replace(/0$/, "1");
    }
  }
  return newParts;
};

export const formatUsd = (
  value?: string | number | null,
  options?: FormatUsdOptions,
) =>
  formatUsdToParts(value, options)
    .map((part) => part.value)
    .join("");

export const formatUsdTransactionFee = (
  value?: string | number | null,
  options?: FormatUsdOptions,
) => formatUsd(value, options);

export const formatUsdBalance = (
  value?: string | number | null,
  options?: FormatUsdOptions,
) => formatUsd(value, options);

export const formatUsdTokenAmountValue = (
  value?: string | number | null,
  options?: FormatUsdOptions,
) => formatUsd(value, options);

export const formatUsdCompact = (value: number) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    notation: "compact",
  }).format(value);

export const formatNumber = (
  value?: string | number | null,
  options?: {
    minimumFractionDigits?: number;
    maximumFractionDigits?: number;
    notation?: Intl.NumberFormatOptions["notation"];
    useGrouping?: boolean;
  },
) => {
  const numberValue = getNumber(value);
  if (!numberValue) {
    return "0";
  }

  return new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: options?.minimumFractionDigits,
    maximumFractionDigits: options?.maximumFractionDigits,
    notation: options?.notation,
    useGrouping: options?.useGrouping,
  }).format(numberValue);
};

export const formatNumberCompact = (value: number) =>
  new Intl.NumberFormat("en-US", {
    style: "decimal",
    notation: "compact",
  }).format(value);

export const formatTokenNumberToParts = (
  value: string | number | null | undefined,
  decimals: number,
  options?: { decimalsMode?: "max" | "fixed"; noGroups?: boolean },
) => {
  const numberValue = getNumberForFormatting(value);

  let parts: Intl.NumberFormatPart[] = [];

  if (options?.decimalsMode === "fixed") {
    parts = new Intl.NumberFormat("en-US", {
      style: "decimal",
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
      useGrouping: !options.noGroups,
    }).formatToParts(numberValue);
  } else {
    parts = Intl.NumberFormat("en-US", {
      style: "decimal",
      minimumSignificantDigits: 5,
      maximumSignificantDigits: 5,
      minimumFractionDigits: 0,
      maximumFractionDigits: decimals,
      useGrouping: !options?.noGroups,
      // @ts-expect-error: this type is accidentally missing from typescript. It
      // was added in https://github.com/microsoft/TypeScript/pull/56902 but the
      // fix hasn't been released yet. This comment can be removed after we update
      // to a newer version of typescript that supports it.
      roundingPriority: "lessPrecision",
    }).formatToParts(numberValue);
  }

  const partsWithoutTrailingZeros = parts.map((part) => {
    if (part.type === "fraction") {
      return { type: part.type, value: truncateTrailingZeros(part.value) };
    }

    return part;
  });

  const isFractionEmpty =
    partsWithoutTrailingZeros.find((part) => part.type === "fraction")?.value
      .length === 0;

  // If fraction is empty after truncating trailing zeros, remove it and the
  // decimal symbol
  return isFractionEmpty
    ? partsWithoutTrailingZeros.filter(
        (part) => part.type !== "fraction" && part.type !== "decimal",
      )
    : partsWithoutTrailingZeros;
};

export const formatTokenNumber = (
  ...args: Parameters<typeof formatTokenNumberToParts>
) =>
  formatTokenNumberToParts(...args)
    .map((part) => part.value)
    .join("");

export function formatAddressShort(address: string, length: 2 | 5 | 6 = 6) {
  return address.slice(0, length) + "..." + address.slice(-length);
}

export const formatSimpleDate = (date: Date | string | number) => {
  const d = new Date(date);
  return d.toLocaleDateString("en-US");
};

const fiatSymbols = new Set(["USD"]);
type FiatSymbol = "USD";

export function isFiat(symbol: string): symbol is FiatSymbol {
  return fiatSymbols.has(symbol);
}

export function formatNumberInput(
  value: string | number,
  options:
    | {
        currency: "fiat";
        tokenDecimals?: never;
        showFractionTruncatedMark?: boolean;
      }
    | {
        currency: "token";
        tokenDecimals: number;
      }
    | {
        currency: "bps";
        tokenDecimals: number;
      },
) {
  const { currency, tokenDecimals } = options;

  if (!value) {
    return "0";
  }

  let replacedValue =
    typeof value === "string" ? value.replace(/[^\d.-]+/g, "").trim() : value;

  // format in case it is a decimal number to avoid bugs with scientific notation and string manipulation
  if (Number(replacedValue) !== 0 && Number(replacedValue) < 1) {
    replacedValue = new Intl.NumberFormat("en-US", {
      style: "decimal",
      maximumFractionDigits: 20,
    }).format(Number(replacedValue));
  }
  let formattedValue: string;

  if (typeof replacedValue === "string") {
    const parts = replacedValue.split(".");
    formattedValue = [parts[0], parts[1]]
      .filter((part) => Boolean(part))
      .join(".");

    if (formattedValue.startsWith("0") && !replacedValue.startsWith("0.")) {
      formattedValue = formattedValue.replace(/^0+/, "");
    }
  } else {
    formattedValue = value.toString();
  }

  if (!formattedValue) {
    return value === "." ? "0." : "0";
  }

  const integralDigitLimit = 20;
  const integralPart = formattedValue.split(".")[0];

  if (integralPart.length > integralDigitLimit) {
    formattedValue = formattedValue.replace(
      integralPart,
      integralPart.slice(0, integralDigitLimit),
    );
  }

  const numDecimals = formattedValue.split(".")[1]?.length;
  const maxDecimals = currency === "fiat" ? 2 : tokenDecimals;

  if (numDecimals > maxDecimals) {
    const decimalSeparatorIndex = formattedValue.indexOf(".");

    const originalFraction = formattedValue.substring(
      decimalSeparatorIndex + 1,
    );
    const leadingZeros = originalFraction.match(/^0*/)?.[0] ?? "";

    formattedValue = formattedValue.slice(
      0,
      decimalSeparatorIndex + maxDecimals + 1,
    );

    if (
      options.currency === "fiat" &&
      options.showFractionTruncatedMark &&
      leadingZeros.length !== originalFraction.length &&
      leadingZeros.length >= maxDecimals &&
      integralPart === "0"
    ) {
      formattedValue = `<${formattedValue.replace(/0$/, "1")}`;
    }
  }

  if (
    !formattedValue.includes(".") &&
    typeof replacedValue === "string" &&
    replacedValue.endsWith(".")
  ) {
    return `${formattedValue.replace(/\./g, "")}.`;
  }

  return formattedValue;
}

export function formatPercentile(value: number) {
  return new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
}

export function formatAbsolutePercent(value: number, fractionDigits = 2) {
  const absValue = Math.abs(value);
  const formatter = new Intl.NumberFormat("en-US", {
    style: "percent",
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });

  if (absValue === 0) {
    return formatter.format(0);
  }

  // If smaller than smallest fractional value, return <0.01%, <0.1%, <1%, etc.
  if (absValue * 10 ** fractionDigits < 1) {
    return `<${formatter.format(0.01 / 10 ** fractionDigits)}`;
  }

  return formatter.format(absValue / 100);
}

export function formatPlatformFeePercent(bps: number) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "percent",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  return formatter.format(bps / 10000);
}

export function formatBasisPointsPercent(bps: number) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "percent",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  return formatter.format(bps / 10000);
}

export function formatPercent(bps: number) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "percent",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  return formatter.format(bps / 100);
}

export const formatDate = (
  date: Date,
  format: "short" | "long" = "short",
  locale: Intl.LocalesArgument = navigator.language,
): string => {
  const parseDate = new Date(date);

  switch (format) {
    case "short":
      return new Intl.DateTimeFormat(locale, {
        month: "short",
        day: "numeric",
        year: "numeric",
      }).format(parseDate);
    case "long": {
      const datePart = new Intl.DateTimeFormat(locale, {
        month: "short",
        day: "numeric",
        year: "numeric",
      }).format(parseDate);
      const hourPart = new Intl.DateTimeFormat(locale, {
        hour: "numeric",
        minute: "2-digit",
      }).format(parseDate);

      // double space is intentional
      return `${datePart}  ${hourPart}`;
    }
  }
};

export function getApiErrorMessage(
  error: FetchBaseQueryError | SerializedError,
) {
  console.log({ error });
  if ("message" in error && error.message) {
    return error.message;
  }

  if ("error" in error && typeof error.error === "string") {
    return error.error;
  }

  if ("data" in error && typeof error.data === "string") {
    return error.data;
  }

  const errorData = error as
    | { error: { data: { message: string } | undefined } | undefined }
    | undefined;
  if (errorData?.error?.data?.message) {
    return errorData.error.data.message;
  }

  return "An error occurred";
}

export function formatErrorMessage(error: unknown) {
  if (typeof error === "string") {
    return error;
  }

  if (error instanceof Error) {
    return error.message;
  }

  return "An error occurred";
}

export function formatTxSimError(value: SimulatedTransactionResponse) {
  if (typeof value.err === "string") {
    return `Error: ${value.err}. Logs: ${value.logs?.join("\n")}`;
  }

  return value.logs?.join("\n");
}

export function getTokenBalanceNominalValue(
  balance: string | bigint,
  decimals: number,
  usdPrice: number,
): number {
  return (Number(balance) / 10 ** decimals) * usdPrice;
}

export function truncateZeroDecimals(value: string) {
  let truncated = value.trim().replace(/(\.\d*[1-9]+)0+$/g, "$1");
  truncated = truncated.trim().replace(/\.0*$/g, "");
  return truncated;
}

export function parseAsScaled(
  realStr: string,
  decimals: number,
): bigint | null {
  const realStrRegEx = new RegExp(`^[0-9]*[.]?[0-9]{0,${decimals}}$`, "g");

  if (realStr.match(realStrRegEx) === null) {
    const realStrRegEx = new RegExp(`^[0-9]+$`, "g");
    if (realStr.match(realStrRegEx)) {
      return BigInt(realStr.padEnd(realStr.length + decimals, "0"));
    }
    return null;
  }

  const [integerPartStr, decimalPartStr] = realStr.split(".");
  const integerPart = BigInt(
    integerPartStr
      ? integerPartStr.padEnd(integerPartStr.length + decimals, "0")
      : "0",
  );
  const decimalPart = BigInt(
    decimalPartStr ? decimalPartStr.padEnd(decimals, "0") : "0",
  );

  const fixed = integerPart + decimalPart;
  return fixed;
}

export function normalizeScaled(
  scaled: bigint | string,
  decimals: number,
): string {
  const scaledStr = scaled.toString();
  if (scaledStr.length <= decimals) {
    return `0.${scaledStr.padStart(decimals, "0").replace(/0+$/, "")}`.replace(
      /\.$/,
      "",
    );
  }
  const integerPart = scaledStr.slice(0, scaledStr.length - decimals);
  let decimalPart = scaledStr.slice(-decimals);
  // Remove trailing zeros
  decimalPart = decimalPart.replace(/0+$/, "");
  return `${integerPart || "0"}.${decimalPart}`.replace(/\.$/, "");
}

export const getSymbolWithDefault = (
  tokenInfo?: TokenInfo | null,
  shorter?: boolean,
) =>
  tokenInfo?.symbol ||
  (tokenInfo?.address
    ? formatAddressShort(tokenInfo.address, shorter ? 2 : 5)
    : "");

export function formatHoursDifference(secondsSinceUnixTimestamp: number) {
  const date = new Date(secondsSinceUnixTimestamp * 1000);
  const now = new Date();
  const diff = now.getTime() - date.getTime();
  return Math.floor(diff / 1000 / 60 / 60);
}
