import { BaseIconProps } from "../icon";

export function SearchIcon(props: BaseIconProps) {
  return (
    <svg
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.75474 9.28017L4.78182 9.25308H5.65516L5.86503 9.0431C5.13386 8.16933 4.73443 7.07882 4.73443 5.94766C4.73443 3.28571 6.90085 1.125 9.55471 1.125C12.2086 1.125 14.375 3.29249 14.375 5.94766C14.375 8.60283 12.2086 10.7703 9.55471 10.7703C8.43088 10.7703 7.33413 10.3707 6.4608 9.63916L6.25092 9.84914V10.7229L2.1618 14.814L2.10087 14.875L0.68593 13.4594L0.625 13.3984L4.74797 9.2734L4.75474 9.28017ZM9.55471 9.25308C11.3759 9.25308 12.8585 7.7697 12.8585 5.94766C12.8585 4.12562 11.3691 2.64901 9.55471 2.64901C7.74034 2.64901 6.25092 4.13239 6.25092 5.95443C6.25092 7.77648 7.73357 9.25985 9.55471 9.25985V9.25308Z"
        fill="currentColor"
      />
    </svg>
  );
}
