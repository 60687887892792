import { useClipboard } from "@/hooks/useClipboard";
import { Icon } from "../icon";
import { cn, formatAddressShort } from "@/utils";

interface WalletCardProps {
  compact?: boolean;
  hideIcon?: boolean;
  label: string;
  address: string;
}

export const WalletCard = ({
  label,
  address,
  compact = false,
  hideIcon = false,
}: WalletCardProps) => {
  const [copied, copy] = useClipboard();

  return (
    <div className="w-full flex justify-between items-center border border-white/10 bg-box p-2.5 rounded-xl">
      <div className="flex items-center gap-3">
        {compact || hideIcon ? null : (
          <Icon
            name="wallet"
            className="min-w-6 w-6 h-6 text-primary"
            srText="Wallet"
          />
        )}
        <div className="flex flex-col">
          <span
            className={cn("text-sm text-white font-bold", {
              "text-primary": compact || hideIcon,
            })}
          >
            {label}
          </span>
          <span className="text-xs text-gray-200">
            {formatAddressShort(address, 5)}
          </span>
        </div>
      </div>
      <button
        className="border rounded-full border-white/10 w-9 h-9 flex justify-center items-center"
        aria-label="Copy account address"
        onClick={() => copy(address)}
      >
        <Icon
          name={copied ? "check" : "copy"}
          className="w-4 h-4 text-gray-200"
        />
      </button>
    </div>
  );
};
