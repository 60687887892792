import { BaseIconProps } from "../icon";

export function CircleClose(props: BaseIconProps) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15 16"
      fill="none"
    >
      <g clipPath="url(#clip0_11240_51077)">
        <path
          d="M10.2512 6.43309L9.07212 5.254L7.5 6.82612L5.92788 5.254L4.74878 6.43309L6.32091 8.00521L4.74878 9.57734L5.92788 10.7564L7.5 9.18431L9.07212 10.7564L10.2512 9.57734L8.67909 8.00521L10.2512 6.43309Z"
          fill="currentColor"
        />
        <path
          d="M12.8033 2.69667C9.87915 -0.22748 5.12085 -0.22748 2.1967 2.69667C-0.727453 5.62082 -0.727453 10.3791 2.1967 13.3033C5.12085 16.2274 9.87915 16.2274 12.8033 13.3033C15.7275 10.3791 15.7327 5.62607 12.8033 2.69667ZM3.37055 12.1294C1.09621 9.85508 1.09097 6.15011 3.37055 3.87053C5.65013 1.59094 9.34987 1.59094 11.6294 3.87053C13.909 6.15011 13.909 9.84984 11.6294 12.1294C9.34987 14.409 5.65013 14.409 3.37055 12.1294Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_11240_51077">
          <rect
            width="15"
            height="15"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
