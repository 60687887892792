import { TokenInfoWithPrice } from "@/api";
import {
  formatNumberInput,
  formatTokenNumber,
  normalizeScaled,
  parseAsScaled,
} from "@/utils";
import { TokenAmount, TokenAmountUpdatePayload } from "@/utils/forms";
import { Draft, PayloadAction, createSlice } from "@reduxjs/toolkit";
import { setIsUsd as withdrawSetIsUsd } from "../withdraw/-reducer";
import { REHYDRATE, rehydrateReducer } from "@/store";

const initialState = {
  isUsd: true,
  sendToken: null as TokenInfoWithPrice | null,
  receiveToken: null as TokenInfoWithPrice | null,
  amount: {
    input: "0",
    scaledValue: null as string | null,
    fiatValue: null as number | null,
  } as TokenAmount,
};

const tradeSwapSlice = createSlice({
  name: "tradeSwap",
  initialState,
  reducers: {
    [REHYDRATE]: rehydrateReducer,
    reset(state) {
      state.sendToken = initialState.sendToken;
      state.receiveToken = initialState.receiveToken;
      state.amount = initialState.amount;
    },
    clearAmount(state) {
      state.receiveToken = initialState.receiveToken;
      state.amount = initialState.amount;
    },
    setIsUsd: innerSetIsUsd,
    updateSendToken(state, action: PayloadAction<TokenInfoWithPrice | null>) {
      state.sendToken = action.payload;
      if (action.payload?.price === null) {
        state.isUsd = false;
      }
      setAmounts(state, state.amount.input, action.payload);
    },
    updateReceiveToken(
      state,
      action: PayloadAction<TokenInfoWithPrice | null>,
    ) {
      state.receiveToken = action.payload;
    },
    updateAmount(state, action: PayloadAction<TokenAmountUpdatePayload>) {
      state.sendToken = action.payload.token ?? state.sendToken;
      setAmounts(
        state,
        action.payload.amount,
        action.payload.token,
        action.payload.isUsd,
      );
    },
    updateScaledValue(state, action: PayloadAction<string>) {
      if (!state.sendToken) return;
      state.amount.scaledValue = action.payload;
      const normalized = normalizeScaled(
        action.payload,
        state.sendToken.decimals,
      );
      state.amount.fiatValue = state.sendToken.price
        ? Number(normalized) * state.sendToken.price
        : null;

      if (state.isUsd) {
        state.amount.input = formatNumberInput(state.amount.fiatValue ?? 0, {
          currency: "fiat",
        });
      } else {
        state.amount.input = formatTokenNumber(
          normalized,
          state.sendToken.decimals,
          { decimalsMode: "fixed", noGroups: true },
        );
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(withdrawSetIsUsd, innerSetIsUsd);
  },
});

function innerSetIsUsd(
  state: Draft<typeof initialState>,
  action: PayloadAction<boolean>,
) {
  const next = action.payload;
  if (!state.sendToken) return;
  if (next) {
    state.amount.input = formatNumberInput(state.amount.fiatValue ?? 0, {
      currency: "fiat",
      showFractionTruncatedMark: true,
    });
  } else {
    state.amount.input = formatNumberInput(
      normalizeScaled(
        state.amount.scaledValue ?? "0",
        state.sendToken.decimals,
      ),
      { currency: "token", tokenDecimals: state.sendToken.decimals },
    );
  }
  state.isUsd = next;
}

export const tradeSwapReducer = tradeSwapSlice.reducer;
export const {
  reset: resetTradeFlow,
  clearAmount,
  setIsUsd,
  updateSendToken,
  updateReceiveToken,
  updateAmount,
  updateScaledValue,
} = tradeSwapSlice.actions;
export const tradeSwapActions = tradeSwapSlice.actions;

function setAmountsFromFiatValue(
  state: Draft<typeof initialState>,
  amount: string,
  token: TokenInfoWithPrice,
) {
  const formatted = formatNumberInput(amount, { currency: "fiat" });
  state.amount.input = formatted;
  const numAmt = Number(formatted || 0);
  state.amount.fiatValue = numAmt;
  // Token amount is an approximation here
  // as we are using the usd price
  const scaled = token.price
    ? parseAsScaled(
        (numAmt / token.price).toFixed(token.decimals),
        token.decimals,
      )
    : null;
  state.amount.scaledValue = scaled?.toString() ?? null;
  return state;
}

function setAmountsFromTokenValue(
  state: Draft<typeof initialState>,
  amount: string,
  token: TokenInfoWithPrice,
) {
  const formatted = formatNumberInput(amount, {
    currency: "token",
    tokenDecimals: token.decimals,
  });
  state.amount.input = formatted;
  const scaled = parseAsScaled(formatted, token.decimals);
  state.amount.scaledValue = scaled?.toString() ?? null;
  state.amount.fiatValue = token.price ? Number(formatted) * token.price : null;
  return state;
}

function setAmounts(
  state: Draft<typeof initialState>,
  amount: string,
  token: TokenInfoWithPrice | null | undefined,
  isUsd: boolean = state.isUsd,
) {
  state.amount.input = amount;
  if (!token) return;
  if (isUsd && token.price !== null) {
    state.isUsd = true;
    setAmountsFromFiatValue(state, amount, token);
  } else {
    state.isUsd = false;
    setAmountsFromTokenValue(state, amount, token);
  }
}
