import type { TokenInfo } from "@/api";
import { createAction } from "@reduxjs/toolkit";

// Actions are defined here to prevent a circular dependency between the store and api definitions

interface TokenCachePayload extends TokenInfo {
  fulfilledTimeStamp?: number;
}

export const addTokenToCache = createAction<TokenCachePayload>(
  "tokenCache/addTokenToCache",
);

export const removeExpiredTokens = createAction(
  "tokenCache/removeExpiredTokens",
);

export const tokenCacheActions = { addTokenToCache, removeExpiredTokens };
