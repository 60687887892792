import { api, getAuthHeaders } from "./api";

const ORDER_API_BASE_URL = import.meta.env.VITE_USER_API_BASE_URL;

export const orderHistoryApi = api.injectEndpoints({
  endpoints: (builder) => ({
    userOrderHistory: builder.query<Order[], void>({
      query: () => ({
        url: `${ORDER_API_BASE_URL}/api/order/user/history`,
        headers: getAuthHeaders(),
      }),
      providesTags: [{ type: "Order", id: "LIST" }],
    }),
    oneUserOrder: builder.query<Order, number | string>({
      query: (id) => ({
        url: `${ORDER_API_BASE_URL}/api/order/user/history/${id}`,
        headers: getAuthHeaders(),
      }),
      keepUnusedDataFor: 15 * 60,
    }),
    saveOrderQuoteDetails: builder.mutation<
      { data: PendingOrder },
      SaveOrderQuoteDetailsParams
    >({
      query: (body: SaveOrderQuoteDetailsParams) => ({
        url: `${ORDER_API_BASE_URL}/api/order/user/quote_details`,
        headers: getAuthHeaders(),
        method: "POST",
        body,
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        // Prime cache for the order that was just saved
        const {
          data: { data },
        } = await queryFulfilled;
        dispatch(
          orderHistoryApi.util.updateQueryData(
            "onePendingOrder",
            data.id,
            () => data,
          ),
        );
      },
    }),
    saveCompletedUserOrder: builder.mutation<
      { data: Order[] },
      SaveCompletedOrderParams
    >({
      query: (body) => ({
        url: `${ORDER_API_BASE_URL}/api/order/user`,
        headers: getAuthHeaders(),
        method: "POST",
        body,
      }),
      extraOptions: { maxRetries: 1 },
      invalidatesTags: [{ type: "Order", id: "LIST" }],
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        // Prime cache for the order that was just saved
        const {
          data: { data: list },
        } = await queryFulfilled;
        const data = list.at(0);
        if (data) {
          dispatch(
            orderHistoryApi.util.updateQueryData(
              "oneUserOrder",
              data.id,
              () => data,
            ),
          );
        }
      },
    }),
    onePendingOrder: builder.query<PendingOrder, number | string>({
      query: (id) => ({
        url: `${ORDER_API_BASE_URL}/api/order/user/pending/${id}`,
        headers: getAuthHeaders(),
      }),
      keepUnusedDataFor: 15 * 60,
    }),
  }),
});

export const {
  useUserOrderHistoryQuery,
  useOneUserOrderQuery,
  useSaveCompletedUserOrderMutation,
  useSaveOrderQuoteDetailsMutation,
  useOnePendingOrderQuery,
} = orderHistoryApi;

export type OrderStatus = "SUCCESS" | "FAILED";
type OrderType = "MARKET" | "LIMIT" | "DCA";

export interface Order {
  id: number;
  transactionIndex: number;
  type: OrderType;
  signature: string;
  status: OrderStatus;
  fee: number;
  blockTime: number;
  inMint: string;
  outMint: string;
  inAmount: string;
  outAmount: string;
  platformFeeBps?: number;
  createdAt: string;
  orderDetails: {
    id: number;
    userPublicKey: string;
    quoteRequestTimestamp: number;
    quoteRequestInputMint: string;
    quoteRequestOutputMint: string;
    quoteRequestAmount: string;
    quoteRequestSlippageBps: number;
    quoteRequestPlatformFeeBps?: number;
    quoteResponseRawBody: string;
    quoteResponseInputAmount: string;
    quoteResponseOutputAmount: string;
    quoteResponseMinOutputAmount: string;
    quoteResponsePlatformFeeBps?: number;
    quoteResponsePlatformFeeAmount?: string;
    quoteResponseTimestamp: number;
    swapRequestTimestamp: number;
    swapRequestRawBody: string;
    swapResponseRawBody: string;
    swapResponseTimestamp: number;
    transactionSignature: string;
    sendTransactionTimestamp: number;
  } | null;
}

export interface SaveOrderQuoteDetailsParams {
  userPublicKey: string;
  // GET /quote details
  quoteRequestTimestamp: number;
  quoteRequestRawUrl: string;
  quoteRequestParsedWellKnownParams: {
    inputMint: string;
    outputMint: string;
    quoteRequestAmount: string;
    slippageBps: number;
    quoteRequestPlatformFeeBps: number | null;
  };
  quoteResponseRawBody: string;
  quoteResponseParsedWellKnownFields: {
    quoteResponseInputAmount: string;
    quoteResponseOutputAmount: string;
    minOutAmount: string;
    quoteResponsePlatformFeeBps: number | null;
    quoteResponsePlatformFeeAmount: string | null;
  };
  quoteResponseTimestamp: number;
  // POST /swap details
  swapRequestTimestamp: number;
  swapRequestRawBody: string;
  swapRequestParsedWellKnownFields?: null;
  swapResponseRawBody: string;
  swapResponseParsedWellKnownFields?: null;
  swapResponseTimestamp: number;
  // Transaction details
  transactionSignature: string;
  sendTransactionTimestamp: number;
}

export interface RequestDetails {
  amount: string;
  inputMint: string;
  outputMint: string;
  slippageBps: number;
  platformFeeBps: number | null;
  timeOfRequest: number;
}

export interface SaveCompletedOrderParams {
  signature: string;
}

export interface PendingOrder {
  id: number;
  transaction_signature: string;
  in_mint: string;
  out_mint: string;
  in_amount: string;
  out_amount: string;
  time_started: number;
  status: "pending" | "succeeded" | "failed" | "expired" | "unknown";
}
