import { BaseIconProps } from "../icon";

export function HeartIcon(props: BaseIconProps) {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.4628 1.66663C11.8091 1.66663 10.3378 3.5649 10.3378 3.5649C10.3378 3.5649 8.86656 1.66663 6.21281 1.66663C3.42798 1.66663 1.17114 3.90278 1.17114 6.66209C1.17114 10.4505 5.67381 14.1217 6.92873 15.2815C8.37431 16.6167 10.3378 18.3333 10.3378 18.3333C10.3378 18.3333 12.3013 16.6167 13.7469 15.2815C15.0018 14.1217 19.5045 10.4505 19.5045 6.66209C19.5045 3.90278 17.2476 1.66663 14.4628 1.66663ZM12.9146 13.574C12.7523 13.7193 12.6111 13.8456 12.4966 13.9518C11.8081 14.5876 10.9941 15.3169 10.3378 15.9001C9.68148 15.3169 8.86656 14.5867 8.17906 13.9518C8.06356 13.8456 7.92239 13.7184 7.76106 13.574C6.45939 12.4087 3.00448 9.31331 3.00448 6.66209C3.00448 4.90913 4.44364 3.48316 6.21281 3.48316C7.88756 3.48316 8.85831 4.64119 8.88398 4.67117L10.3378 6.20795L11.7916 4.67117C11.8008 4.65936 12.7881 3.48316 14.4628 3.48316C16.232 3.48316 17.6711 4.90913 17.6711 6.66209C17.6711 9.31331 14.2162 12.4087 12.9146 13.574Z"
        fill="currentColor"
      />
    </svg>
  );
}
