import { userApi } from "@/api";
import { REHYDRATE, rehydrateReducer } from "@/store";
import {
  SOL_DECIMALS,
  SOL_TRANSACTION_FEE,
  TOKEN_ACCOUNT_RENT,
  normalizeScaled,
  parseAsScaled,
} from "@/utils";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export const DEFAULT_PRIORITY_FEE = 1_500_000; // 0.0015 SOL in lamports,
export type WrapUnwrapToggle = "wrap" | "unwrap";
const WRAP_PRIORITY_FEE_DIVISOR = 2;

const initialState = {
  slippage: 50, // 0.5%
  priorityFee: DEFAULT_PRIORITY_FEE,
  username: "",
  customRpcUrl: null as string | null,
  useWrappedSol: false,
  hideUnverifiedTokenModal: false,
  wrapUnwrapToggle: "wrap" as WrapUnwrapToggle,
  wrapInput: {
    value: "",
    scaledAmount: "0",
  },
  hasInsufficientSolForWrap: false,
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    [REHYDRATE]: rehydrateReducer,
    updateHideUnverifiedTokenModal: (state, action: PayloadAction<boolean>) => {
      state.hideUnverifiedTokenModal = action.payload;
    },
    updateSlippage: (state, action: PayloadAction<string>) => {
      const basisPoints = Math.round(Number(action.payload) * 100);
      state.slippage = basisPoints;
    },
    updatePriorityFee: (state, action: PayloadAction<number>) => {
      state.priorityFee = action.payload;
    },
    updateUsername: (state, action: PayloadAction<string>) => {
      state.username = action.payload;
    },
    updateRPC: (state, action: PayloadAction<string | null>) => {
      state.customRpcUrl = action.payload;
    },
    updateUseWrappedSol: (state, action: PayloadAction<boolean>) => {
      state.useWrappedSol = action.payload;
    },
    resetWrapUnwrap: (state) => {
      state.wrapInput.value = "";
      state.wrapInput.scaledAmount = "0";
      state.hasInsufficientSolForWrap = false;
    },
    setWrapUnwrapToggle: (state, action: PayloadAction<string>) => {
      const nextVal = action.payload as WrapUnwrapToggle;
      state.wrapUnwrapToggle = nextVal;
    },
    updateWrapAmount: (state, action: PayloadAction<string>) => {
      if (!isValidNumberInput(action.payload)) return;

      state.hasInsufficientSolForWrap = false;
      state.wrapInput.value = action.payload;
      const amount =
        parseAsScaled(action.payload.trim(), SOL_DECIMALS)?.toString() ?? "0";
      state.wrapInput.scaledAmount = amount;
    },
    setMaxWrapAmount: (
      state,
      action: PayloadAction<{ solBalance: string; wsolBalance: string }>,
    ) => {
      if (state.wrapUnwrapToggle === "unwrap") return;
      const solBalance = BigInt(action.payload.solBalance);
      const wsolBalance = BigInt(action.payload.wsolBalance);
      const max = getMaxWrapAmount(solBalance, wsolBalance, state.priorityFee);
      state.hasInsufficientSolForWrap = false;
      if (max <= 0n) {
        state.wrapInput.value = "0";
        state.wrapInput.scaledAmount = "0";
        state.hasInsufficientSolForWrap = true;
        return;
      }
      state.wrapInput.value = normalizeScaled(max, SOL_DECIMALS);
      state.wrapInput.scaledAmount = max.toString();
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      userApi.endpoints.user.matchFulfilled,
      (state, action) => {
        state.slippage = action.payload.slippageSetting;
        state.useWrappedSol = action.payload.useWrappedSolSetting;
        state.priorityFee =
          action.payload.priorityFeeSetting ?? DEFAULT_PRIORITY_FEE;
        state.customRpcUrl = action.payload.customRpcUrl;
        if (action.payload.username) {
          state.username = action.payload.username;
        }
      },
    );
  },
});

export const {
  updateHideUnverifiedTokenModal,
  updateSlippage,
  updatePriorityFee,
  updateUsername,
  updateRPC,
  updateUseWrappedSol,
  resetWrapUnwrap,
  setWrapUnwrapToggle,
  updateWrapAmount,
  setMaxWrapAmount,
} = profileSlice.actions;

export const profileReducer = profileSlice.reducer;
export const profileActions = profileSlice.actions;

export function isValidNumberInput(value: string) {
  if (!value) return true;
  const re = /^(\d*\.?\d{0,9})$/;
  return re.test(value);
}

export function getReducedPriorityFee(priorityFee: number) {
  return Math.floor(priorityFee / WRAP_PRIORITY_FEE_DIVISOR);
}

export function getMaxWrapAmount(
  solBalance: bigint,
  wsolBalance: bigint,
  globalPriorityFee: number,
): bigint {
  const rent = wsolBalance === 0n ? TOKEN_ACCOUNT_RENT : 0n;
  const priorityFee = BigInt(getReducedPriorityFee(globalPriorityFee));
  return solBalance - rent - SOL_TRANSACTION_FEE - priorityFee;
}
