import { TokenInfo } from "@/api";
import { TokenIcon } from "@/components";
import { cn, getSymbolWithDefault } from "@/utils";
import * as Toast from "@radix-ui/react-toast";
import { ToastProps } from "@radix-ui/react-toast";
import { VariantProps, cva } from "class-variance-authority";

const variants = cva(
  "h-toast group overflow-y-hidden min-h-toast bg-gray-600 rounded-lg border p-4 w-[92vw] mx-auto mt-[calc(1rem+var(--safe-top))] grid [grid-template-areas:_'title_image'_'description_image'] grid-cols-[auto_max-content] gap-x-[15px] text-sm data-[state=closed]:animate-hide data-[swipe=move]:translate-y-[var(--radix-toast-swipe-move-y)] data-[swipe=cancel]:translate-y-0 data-[swipe=cancel]:transition-[transform_200ms_ease-out] data-[swipe=end]:animate-swipeOut shadow-toast shadow-gray-600",
  {
    variants: {
      status: {
        neutral: "",
        pending: "border-primary",
        success: "border-positive",
        failed: "border-negative",
      },
      animationStyle: {
        slideDown: "data-[state=open]:animate-slideDown",
        none: "",
      },
    },
    defaultVariants: {
      animationStyle: "slideDown",
    },
  },
);

interface BaseTransactionToastProps extends ToastProps {
  title?: string;
  baseToken?: TokenInfo;
  quoteToken?: TokenInfo;
}

export type TransactionToastProps = BaseTransactionToastProps &
  VariantProps<typeof variants>;

export function TransactionToast({
  title,
  status,
  animationStyle,
  baseToken,
  quoteToken,
  ...props
}: TransactionToastProps) {
  return (
    <Toast.Root
      {...props}
      className={cn(
        variants({
          status,
          animationStyle,
        }),
        props.className,
      )}
    >
      {title ? (
        <Toast.Title
          className={cn(
            "[grid-area:_title] uppercase text-primary font-bold leading-none",
            {
              "text-positive": status === "success",
            },
          )}
        >
          {title}
        </Toast.Title>
      ) : null}
      {baseToken && quoteToken && (
        <>
          <Toast.Description className="[grid-area:_description] font-bold leading-none">
            {getSymbolWithDefault(baseToken)}/{getSymbolWithDefault(quoteToken)}
          </Toast.Description>
          <div className="[grid-area:_image] flex items-center justify-end h-[35px]">
            <TokenIcon
              logoURI={baseToken.logoURI}
              className="min-w-icon-lg w-icon-lg h-icon-lg"
            />
            <TokenIcon
              logoURI={quoteToken.logoURI}
              className="min-w-icon-lg w-icon-lg h-icon-lg -ml-1"
            />
          </div>
        </>
      )}
    </Toast.Root>
  );
}
