import { BaseIconProps } from "../icon";

export function DownloadIcon(props: BaseIconProps) {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.7809 5.61801L10.7163 4.52169L8.24966 7.06949V0.4375L6.75021 0.4375L6.75021 7.06949L4.28361 4.52169L3.21899 5.61801L7.49994 10.0188L11.7809 5.61801Z"
        fill="currentColor"
      />
      <path
        d="M12.8755 9.70218V12.0184L2.12445 12.0184V9.70218H0.625L0.625 13.5625L14.375 13.5625V9.70218H12.8755Z"
        fill="currentColor"
      />
    </svg>
  );
}
