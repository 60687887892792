import { BackgroundTransactionData } from "@/app/background-transactions";
import { useTypedSelector } from "@/store";
import { useMemo } from "react";

export function useBackgroundTransactions() {
  const storedTransactions = useTypedSelector(
    (state) => state.backgroundTransactions.transactions,
  );

  const orderedTransactions = useMemo(
    () =>
      Object.values(storedTransactions)
        .filter(Boolean)
        .sort((a, b) => {
          // sort by pending first, then by started time
          const aStart = a?.startedTimeStamp ?? 0;
          const bStart = b?.startedTimeStamp ?? 0;
          const aFinish = a?.finishedTimeStamp ?? 0;
          const bFinish = b?.finishedTimeStamp ?? 0;
          const aIsDone =
            a?.status !== "sending" &&
            a?.status !== "confirming" &&
            !a?.isSaving;
          const bIsDone =
            b?.status !== "sending" &&
            b?.status !== "confirming" &&
            !b?.isSaving;
          switch (true) {
            case !aIsDone && bIsDone:
              return -1;
            case aIsDone && !bIsDone:
              return 1;
            case !aIsDone && !bIsDone:
              return bStart - aStart;
            case aIsDone && bIsDone:
              return bFinish - aFinish;
            default:
              return bStart - aStart;
          }
        }),
    [storedTransactions],
  ) as BackgroundTransactionData[];

  return orderedTransactions;
}
