import { useEffect } from "react";

export function useFocus(onFocus: () => void) {
  useEffect(() => {
    const handleFocus = () => {
      onFocus();
    };
    window.addEventListener("focus", handleFocus, false);

    return () => {
      window.removeEventListener("focus", handleFocus, false);
    };
  }, [onFocus]);
}
