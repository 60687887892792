import { useEffect, useState, useCallback } from "react";

export const useVisualViewportOffset = () => {
  const [keyboardHeight, setKeyboardHeight] = useState<number | undefined>(
    undefined,
  );

  const updateKeyboardHeight = useCallback(() => {
    const innerHeight = window.innerHeight;
    const viewportHeight = window.visualViewport?.height ?? innerHeight;
    setKeyboardHeight(innerHeight - viewportHeight);
  }, []);

  useEffect(() => {
    if (window.visualViewport) {
      updateKeyboardHeight();
      window.visualViewport.addEventListener("resize", updateKeyboardHeight);
      return () => {
        window.visualViewport?.removeEventListener(
          "resize",
          updateKeyboardHeight,
        );
      };
    }
  }, [updateKeyboardHeight]);

  return keyboardHeight;
};
