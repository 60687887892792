import { getTwitterOAuthUrl, useUserQuery } from "@/api";
import {
  TransactionErrorMessage,
  Icon,
  Page,
  ProfileImage,
  Shimmer,
} from "@/components";
import { useLogout } from "@/hooks/useLogout";
import { DISABLE_MIGRATION_FLOW, formatAddressShort } from "@/utils";
import { useIsLoggedIn } from "@dynamic-labs/sdk-react-core";
import { skipToken } from "@reduxjs/toolkit/query";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { ProfileList, ProfileListItemProps } from "./-profile-list";
import { useMemo } from "react";
import { withDynamicProtected } from "@/hocs/withDynamicProtected";
import { useClipboard, useTwitterIntegration } from "@/hooks";

interface SearchParams {
  redirect?: string;
}

export const Route = createFileRoute("/profile/")({
  component: withDynamicProtected(Profile),
  validateSearch: (searchParams?: Record<string, unknown>): SearchParams => ({
    redirect:
      typeof searchParams?.redirect === "string"
        ? searchParams.redirect
        : undefined,
  }),
});

function Profile() {
  const search = Route.useSearch();
  const logout = useLogout();
  const navigate = useNavigate();

  const onLogout = async () => {
    await logout();
    void navigate({ to: "/" });
  };
  const isLoggedIn = useIsLoggedIn();
  const userQuery = useUserQuery(isLoggedIn ? undefined : skipToken);
  const walletAddress = userQuery.data?.wallet ?? "";
  const identifier =
    userQuery.data?.email ?? userQuery.data?.phone ?? "Anonymous";
  const username = userQuery.data?.username ?? "Anonymous";
  const migrationStatus = userQuery.data?.migrationStatus;

  const { dismissError, errorProps, isLoading, disconnect } =
    useTwitterIntegration();
  const [copied, copy] = useClipboard();
  const { accountNavItems, tradingNavItems, contactNavItems, socialNavItems } =
    useMemo(() => {
      const accountNavItems: ProfileListItemProps[] = [
        {
          label: "Wallet Settings",
          value: "/profile/wallet-settings",
        },
        userQuery.data?.profileImage
          ? {
              label: "Disconnect X Account",
              value: "",
              icon: "action" as const,
              onClick: disconnect,
            }
          : { label: "Connect X Account", value: getTwitterOAuthUrl() },
        {
          label: "Change Username",
          value: "/profile/username",
        },
      ];
      if (!DISABLE_MIGRATION_FLOW && migrationStatus !== "NOT_NEEDED") {
        accountNavItems.push({
          label: "Transfer Old Account",
          value: "/migrate/notice?redirect=/profile",
        });
      }

      const tradingNavItems: ProfileListItemProps[] = [
        {
          label: "Slippage",
          value: "/profile/slippage",
        },
        {
          label: "Priority Fee",
          value: "/profile/priority-fee",
        },
        {
          label: "WSOL",
          value: "/profile/wsol",
        },
        {
          label: "Custom RPC",
          value: "/profile/rpc",
        },
      ];

      const contactNavItems: ProfileListItemProps[] = [
        {
          label: "Support & Feedback",
          value: "https://help.dflow.net/",
          target: "_blank",
        },
      ];

      const socialNavItems: ProfileListItemProps[] = [
        {
          label: "Join Our Discord",
          value: "https://discord.gg/dflow",
          target: "_blank",
        },
        {
          label: "Follow Us on X",
          value: "https://x.com/DFlowProtocol",
          target: "_blank",
        },
      ];

      return {
        accountNavItems,
        tradingNavItems,
        contactNavItems,
        socialNavItems,
      };
    }, [migrationStatus, userQuery.data?.profileImage, disconnect]);

  return (
    <Page
      title="Profile"
      backButton={{
        to: search.redirect ?? "/trade",
        "aria-label": `Back to ${search.redirect ?? "/trade"}`,
      }}
    >
      <div className="flex flex-col items-center px-5 gap-3.5 pb-5 pt-3">
        <Shimmer.Text
          className="w-full h-[143px]"
          isLoading={userQuery.isLoading || isLoading}
        >
          <ProfileImage
            size="large"
            profileImage={userQuery.data?.profileImage}
          />
          <div className="flex flex-col items-center">
            <span className="text-sm font-bold text-primary">{username}</span>
            <span className="text-sm font-bold text-white break-words">
              {identifier}
            </span>
            <span className="flex items-center gap-1 text-sm text-gray-200">
              {formatAddressShort(walletAddress, 5)}
              <button
                className="w-4 h-4 flex justify-center items-center"
                aria-label="Copy account address"
                onClick={() => copy(walletAddress)}
              >
                <Icon
                  name={copied ? "check" : "copy"}
                  className="w-4 h-4 text-gray-200"
                />
              </button>
            </span>
          </div>
        </Shimmer.Text>
        {errorProps ? (
          <TransactionErrorMessage {...errorProps} onDismiss={dismissError} />
        ) : null}
      </div>

      <div className="space-y-6 pb-6">
        <ProfileList title="Account" items={accountNavItems} />
        <ProfileList title="Trading" items={tradingNavItems} />
        <ProfileList title="Support" items={contactNavItems} />
        <ProfileList title="Connect" items={socialNavItems} />
        <button
          onClick={onLogout}
          className="uppercase text-primary font-bold text-center text-sm w-full"
        >
          Logout
        </button>
      </div>
    </Page>
  );
}
