import type { TransactionErrorMessageProps } from "@/components";

const lowSlippage = 0.5;
const defaultHighSlippage = 3;
const maxSlippage = 100;

export const isValidSlippageRange = (value: string) => {
  const numValue = Number(value);
  return numValue >= 0 && numValue <= maxSlippage;
};

export const getSlippageWarningMessage = (
  value: string | number,
  highSlippage: number = defaultHighSlippage,
): TransactionErrorMessageProps | null => {
  const slippage = Number(value);

  if (slippage > highSlippage) {
    return {
      title: `SLIPPAGE ABOVE ${highSlippage}%`,
      message: `Slippage above ${highSlippage}% may result in suboptimal order execution due to frontrunning.`,
      color: "warning",
    };
  } else if (slippage < lowSlippage) {
    return {
      title: `SLIPPAGE BELOW ${lowSlippage}%`,
      message: `Slippage below ${lowSlippage}% may cause orders to fail to execute.`,
      color: "warning",
    };
  }

  return null;
};
