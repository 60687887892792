import { BaseIconProps } from "../icon";

export function LogoIcon(props: BaseIconProps) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.3483 9.14744V0.0130661C6.76599 0.78634 0.742666 6.82762 0 14.4166H9.07716L14.3483 9.14744ZM4.10546 11.1786C5.428 7.9418 8.03981 5.36897 11.304 4.10037V7.56407L7.68808 11.1786H4.10546Z"
        fill="currentColor"
      />
      <path
        d="M22.7886 14.4166H32C31.2526 6.78248 25.1627 0.715071 17.5162 0V9.14625L22.7886 14.4166ZM27.7591 11.1774H24.1753L20.5594 7.56288V4.09918C23.8235 5.36897 26.4377 7.94061 27.7591 11.1774Z"
        fill="currentColor"
      />
      <path
        d="M9.07716 17.5846H0C0.742666 25.1736 6.76599 31.216 14.3483 31.9893V22.8549L9.07716 17.5846ZM4.10665 20.8238H7.69046L11.3064 24.4383V27.902C8.04218 26.6322 5.42919 24.0606 4.10665 20.8238Z"
        fill="currentColor"
      />
      <path
        d="M17.5162 22.8537V32C25.1627 31.2849 31.2526 25.2175 32 17.5834H22.7886L17.5162 22.8537ZM27.7603 20.8226C26.4377 24.0594 23.8247 26.6322 20.5606 27.9008V24.4371L24.1765 20.8226H27.7603Z"
        fill="currentColor"
      />
    </svg>
  );
}
