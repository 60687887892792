import { ReactNode, forwardRef } from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "@/utils";
import { type LinkProps } from "@tanstack/react-router";
import { Icon, Link } from "@/components";

interface ButtonBaseProps extends VariantProps<typeof variants> {
  icon?: React.ReactNode;
  isLoading?: boolean;
  className?: string;
}

export type ButtonProps = ButtonBaseProps &
  (React.ButtonHTMLAttributes<HTMLButtonElement> | LinkProps);

const isLink = (props: ButtonProps): props is LinkProps => "to" in props;

const variants = cva(
  "inline-flex items-center justify-center h-12 uppercase px-5 font-bold text-sm transition-colors",
  {
    variants: {
      variant: {
        cta: "rounded-sm disabled:bg-white/10 disabled:text-gray-300 [&:not(:disabled)]:shadow-[0px_0px_10px_0px_rgba(0,0,0,0.1)]",
        outline:
          "rounded-sm bg-transparent border border-primary text-white disabled:border-cloud disabled:text-gray-300 [&:not(:disabled)]:shadow-[0px_0px_10px_0px_rgba(0,0,0,0.1)]",
        big: "disabled:bg-white/10 disabled:text-gray-300 h-14 px-8 text-sm",
        fluid: "w-full disabled:bg-gray-300 h-16",
        flat: "bg-transparent text-primary disabled:text-gray-300",
        small:
          "rounded-sm disabled:bg-white/10 disabled:text-gray-300 [&:not(:disabled)]:shadow-[0px_0px_10px_0px_rgba(0,0,0,0.1)] h-8",
      },
      color: {
        gray: "bg-box",
        primary: "",
        negative: "",
        dark: "",
        neutral: "",
      },
    },
    compoundVariants: [
      {
        variant: "cta",
        color: "primary",
        className:
          "bg-primary text-gray-600 [&:not(:disabled)]:shadow-primary/50 [&:not(:disabled)]:hover:bg-primary-hover",
      },
      {
        variant: "cta",
        color: "negative",
        className:
          "bg-negative text-white [&:not(:disabled)]:shadow-negative/50",
      },
      {
        variant: "outline",
        color: "primary",
        className:
          "border-primary text-white [&:not(:disabled)]:shadow-primary/25 [&:not(:disabled)]:hover:text-primary",
      },
      {
        variant: "outline",
        color: "negative",
        className:
          "border-negative text-white [&:not(:disabled)]:shadow-negative/25 [&:not(:disabled)]:hover:text-negative",
      },
      {
        variant: "big",
        color: "primary",
        className:
          "bg-primary text-gray-600 [&:not(:disabled)]:hover:bg-primary-hover",
      },
      {
        variant: "big",
        color: "negative",
        className: "bg-negative text-white",
      },
      {
        variant: "fluid",
        color: "primary",
        className: "bg-primary text-gray-600",
      },
      {
        variant: "flat",
        color: "primary",
        className: "text-primary ",
      },
      {
        variant: "small",
        color: "primary",
        className:
          "bg-primary text-gray-600 [&:not(:disabled)]:shadow-primary/50 [&:not(:disabled)]:hover:bg-primary-hover",
      },
    ],
    defaultVariants: {
      variant: "cta",
      color: "primary",
    },
  },
);

function ButtonComponent(
  { variant, color, icon, className, isLoading, ...props }: ButtonProps,
  forwardedRef:
    | React.ForwardedRef<HTMLButtonElement>
    | React.ForwardedRef<HTMLAnchorElement>,
): JSX.Element {
  const buttonClassName = cn(variants({ variant, color }), className);

  let iconWrapper: ReactNode = null;
  if (icon) {
    iconWrapper = <span className="ml-2 inline-block">{icon}</span>;
  }
  if (isLoading) {
    iconWrapper = (
      <span className="ml-2 inline-block z-10">
        <Icon
          name="circle-partial"
          size="small"
          className="w-5 h-5 text-current animate-spin"
        />
      </span>
    );
  }

  if (isLink(props)) {
    const { children } = props;
    const disabledClass =
      "pointer-events-none cursor-default bg-white/10 text-gray-300 [&:not(:disabled)]:shadow-none";
    return (
      <Link
        {...props}
        ref={forwardedRef as React.ForwardedRef<HTMLAnchorElement>}
        className={cn(buttonClassName, props.disabled && disabledClass)}
      >
        {typeof children === "function" ? (
          (state) => (
            <>
              {children(state)}
              {iconWrapper}
            </>
          )
        ) : (
          <>
            {props.children}
            {iconWrapper}
          </>
        )}
      </Link>
    );
  }

  return (
    <button
      type="button"
      {...props}
      ref={forwardedRef as React.ForwardedRef<HTMLButtonElement>}
      className={buttonClassName}
    >
      {props.children}
      {iconWrapper}
    </button>
  );
}

export const Button = forwardRef(ButtonComponent);
