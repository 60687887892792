import { useUserQuery } from "@/api";
import { Page } from "@/components";
import { DynamicConnectForm } from "@/components/headless-dynamic";
import { returnToLoginSignup } from "@/routes/login-signup/-reducer";
import { useAppDispatch, useTypedSelector } from "@/store";
import { DISABLE_MIGRATION_FLOW } from "@/utils";
import { useIsLoggedIn } from "@dynamic-labs/sdk-react-core";
import { skipToken } from "@reduxjs/toolkit/query";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { useEffect } from "react";

export const Route = createFileRoute("/login-signup/")({
  component: Login,
});

function Login() {
  const navigate = useNavigate();
  const search: Record<string, string | undefined> = Route.useSearch();
  const isLoggedIn = useIsLoggedIn();
  const userQuery = useUserQuery(isLoggedIn ? undefined : skipToken);

  function getMigrationStatus() {
    if (!isLoggedIn) {
      return "not_logged_in";
    }

    if (DISABLE_MIGRATION_FLOW) {
      return "should_skip";
    }

    // in case of error, we can't keep the user in this page therefore we allow them to proceed
    if (userQuery.isError) {
      return "should_skip";
    }

    if (userQuery.data === undefined) {
      return "loading";
    }

    if (userQuery.data.migrationStatus === "NOT_STARTED") {
      return "not_started";
    }

    return "should_skip";
  }

  const migrationStatus = getMigrationStatus();

  useEffect(() => {
    if (migrationStatus === "should_skip") {
      void navigate({ to: search.redirect ?? "/trade" });
    } else if (migrationStatus === "not_started") {
      void navigate({
        to: "/migrate/notice",
        search: { redirect: "/trade" },
      });
    }
  }, [migrationStatus, navigate, search.redirect]);

  const step = useTypedSelector((state) => state.loginSignup.step);
  const dispatch = useAppDispatch();
  const onBack = () => dispatch(returnToLoginSignup());
  let title = "Login / Signup";
  let backButton;
  switch (step) {
    case "login-signup":
      title = "Login / Signup";
      backButton = { to: "/" };
      break;
    case "verify":
      title = "Verify Email Address";
      backButton = { to: "/login-signup", onClick: onBack };
      break;
    case "beta-access-denied":
      title = "Access Coming Soon";
      backButton = { to: "/login-signup", onClick: onBack };
      break;
  }

  return (
    <Page
      hideTabs
      fullScreenHeight
      title={title}
      backButton={backButton}
      isLoading={userQuery.isFetching}
    >
      <div className="p-5">
        <DynamicConnectForm />
      </div>
    </Page>
  );
}
