import { useEffect, useRef, useState } from "react";
import { useQuickTradeSettingsQuery } from "@/api";
import {
  formatNumberInput,
  formatTokenNumber,
  normalizeScaled,
  parseAsScaled,
  SOL_DECIMALS,
} from "@/utils";
import { ToggleGroupData } from "../-toggle-group";

function isValidNumberInput(value: string, decimals: number) {
  if (!value) return true;
  const re = new RegExp(`^(\\d*\\.?\\d{0,${decimals}})$`);
  return re.test(value);
}

const solAmountOptions: ToggleGroupData[] = [
  { label: "0.01 SOL", value: "10000000" },
  { label: "0.1 SOL", value: "100000000" },
  { label: "1 SOL", value: "1000000000" },
];

const usdcAmountOptions: ToggleGroupData[] = [
  { label: "5 USDC", value: "5000000" },
  { label: "50 USDC", value: "50000000" },
  { label: "150 USDC", value: "150000000" },
];

const usdtAmountOptions: ToggleGroupData[] = [
  { label: "5 USDT", value: "5000000" },
  { label: "50 USDT", value: "50000000" },
  { label: "150 USDT", value: "150000000" },
];

const getBuyAmountInitialValue = (
  value: bigint | null | undefined,
  options: ToggleGroupData[],
  decimals: number,
) => {
  const valueWithDefault = value ?? BigInt(options[1].value);
  const uiValue = formatTokenNumber(
    normalizeScaled(valueWithDefault, decimals),
    decimals,
    {
      decimalsMode: "fixed",
      noGroups: true,
    },
  );
  const isPresetSelected = options.some(
    (o) => o.value === valueWithDefault.toString(),
  );

  return {
    value: uiValue,
    input: options.some((o) => o.value === valueWithDefault.toString())
      ? ""
      : uiValue,
    scaledValue: valueWithDefault.toString(),
    isPresetSelected,
    showSuffix: !isPresetSelected,
  };
};

export const useBuyAmountForm = () => {
  const quickTradeSettingsQuery = useQuickTradeSettingsQuery();

  const savedBuyTokenSymbol =
    quickTradeSettingsQuery.data?.settings?.buyTokenSymbol;
  const savedSolBuyAmount =
    quickTradeSettingsQuery.data?.settings?.solBuyAmount;
  const savedUsdcBuyAmount =
    quickTradeSettingsQuery.data?.settings?.usdcBuyAmount;
  const savedUsdtBuyAmount =
    quickTradeSettingsQuery.data?.settings?.usdtBuyAmount;

  const [unsavedBuyTokenSymbol, setUnsavedBuyTokenSymbol] = useState(
    savedBuyTokenSymbol || "SOL",
  );

  const [unsavedSolBuyAmount, setUnsavedSolBuyAmount] = useState(
    getBuyAmountInitialValue(
      savedSolBuyAmount ? BigInt(savedSolBuyAmount) : null,
      solAmountOptions,
      SOL_DECIMALS,
    ),
  );
  const [unsavedUsdcBuyAmount, setUnsavedUsdcBuyAmount] = useState(
    getBuyAmountInitialValue(
      savedUsdcBuyAmount ? BigInt(savedUsdcBuyAmount) : null,
      usdcAmountOptions,
      6,
    ),
  );
  const [unsavedUsdtBuyAmount, setUnsavedUsdtBuyAmount] = useState(
    getBuyAmountInitialValue(
      savedUsdtBuyAmount ? BigInt(savedUsdtBuyAmount) : null,
      usdtAmountOptions,
      6,
    ),
  );

  const prevIsLoading = useRef(quickTradeSettingsQuery.isLoading);

  useEffect(() => {
    if (prevIsLoading.current && !quickTradeSettingsQuery.isLoading) {
      if (
        savedBuyTokenSymbol &&
        unsavedBuyTokenSymbol !== savedBuyTokenSymbol
      ) {
        setUnsavedBuyTokenSymbol(savedBuyTokenSymbol);
      }

      if (
        savedSolBuyAmount &&
        unsavedSolBuyAmount.value !== savedSolBuyAmount
      ) {
        setUnsavedSolBuyAmount(
          getBuyAmountInitialValue(
            savedSolBuyAmount ? BigInt(savedSolBuyAmount) : null,
            solAmountOptions,
            SOL_DECIMALS,
          ),
        );
      }

      if (
        savedUsdcBuyAmount &&
        unsavedUsdcBuyAmount.value !== savedUsdcBuyAmount
      ) {
        setUnsavedUsdcBuyAmount(
          getBuyAmountInitialValue(
            savedUsdcBuyAmount ? BigInt(savedUsdcBuyAmount) : null,
            usdcAmountOptions,
            6,
          ),
        );
      }

      if (
        savedUsdtBuyAmount &&
        unsavedUsdtBuyAmount.value !== savedUsdtBuyAmount
      ) {
        setUnsavedUsdtBuyAmount(
          getBuyAmountInitialValue(
            savedUsdtBuyAmount ? BigInt(savedUsdtBuyAmount) : null,
            usdtAmountOptions,
            6,
          ),
        );
      }
    }

    prevIsLoading.current = quickTradeSettingsQuery.isLoading;
  }, [
    quickTradeSettingsQuery.isLoading,
    savedBuyTokenSymbol,
    savedSolBuyAmount,
    savedUsdcBuyAmount,
    savedUsdtBuyAmount,
    unsavedBuyTokenSymbol,
    unsavedSolBuyAmount.value,
    unsavedUsdcBuyAmount.value,
    unsavedUsdtBuyAmount.value,
  ]);

  const saveDisabled =
    unsavedBuyTokenSymbol === savedBuyTokenSymbol &&
    unsavedSolBuyAmount.scaledValue === savedSolBuyAmount &&
    unsavedUsdcBuyAmount.scaledValue === savedUsdcBuyAmount &&
    unsavedUsdtBuyAmount.scaledValue === savedUsdtBuyAmount;

  const setUnsavedCurrentBuyAmount =
    unsavedBuyTokenSymbol === "SOL"
      ? setUnsavedSolBuyAmount
      : unsavedBuyTokenSymbol === "USDC"
        ? setUnsavedUsdcBuyAmount
        : setUnsavedUsdtBuyAmount;

  const unsavedCurrentBuyAmount =
    unsavedBuyTokenSymbol === "SOL"
      ? unsavedSolBuyAmount
      : unsavedBuyTokenSymbol === "USDC"
        ? unsavedUsdcBuyAmount
        : unsavedUsdtBuyAmount;

  const handleBuyInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const decimals = unsavedBuyTokenSymbol === "SOL" ? SOL_DECIMALS : 6;

    if (isValidNumberInput(event.target.value, decimals)) {
      const scaledValue =
        parseAsScaled(event.target.value, decimals)?.toString() ?? "";
      setUnsavedCurrentBuyAmount((prev) => ({
        ...prev,
        input: formatNumberInput(event.target.value, {
          currency: "token",
          tokenDecimals: decimals,
        }),
        value: event.target.value,
        scaledValue,
        isPresetSelected: false,
      }));
    }
  };

  const handleBuyToggleGroup = (value: string) => {
    const formattedValue = normalizeScaled(value, SOL_DECIMALS);
    const scaledValue = value;

    setUnsavedCurrentBuyAmount((prev) => ({
      ...prev,
      value: formattedValue,
      scaledValue,
      isPresetSelected: true,
    }));
  };

  const handleBuyInputFocus = () => {
    setUnsavedCurrentBuyAmount((current) => ({
      ...current,
      showSuffix: true,
    }));
  };

  const currentBuyAmountOptions =
    unsavedBuyTokenSymbol === "SOL"
      ? solAmountOptions
      : unsavedBuyTokenSymbol === "USDC"
        ? usdcAmountOptions
        : usdtAmountOptions;

  return {
    unsavedBuyTokenSymbol,
    setUnsavedBuyTokenSymbol,
    unsavedCurrentBuyAmount,
    saveDisabled,
    handleBuyInputChange,
    handleBuyToggleGroup,
    handleBuyInputFocus,
    currentBuyAmountOptions,
    solBuyAmountScaledValue: unsavedSolBuyAmount.scaledValue,
    usdcBuyAmountScaledValue: unsavedUsdcBuyAmount.scaledValue,
    usdtBuyAmountScaledValue: unsavedUsdtBuyAmount.scaledValue,
    error: Number(unsavedCurrentBuyAmount.scaledValue) === 0,
  };
};
