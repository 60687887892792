import { BaseIconProps } from "@/components";
import { cn } from "@/utils";

export function DFlowLogo({ className, ...props }: BaseIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      className={cn("text-orchid", className)}
      {...props}
    >
      <path
        d="M21.537 13.6897V0.0195542C10.2291 1.17681 1.24625 10.218 0.138672 21.5754H13.6759L21.537 13.6897ZM6.26134 16.7295C8.23371 11.8854 12.1288 8.035 16.9968 6.13646V11.3201L11.6043 16.7295H6.26134Z"
        fill="currentColor"
      />
      <path
        d="M34.1244 21.5754H47.8618C46.7471 10.1504 37.665 1.07015 26.2615 0V13.6879L34.1244 21.5754ZM41.5371 16.7277H36.1924L30.7999 11.3183V6.13468C35.6679 8.035 39.5665 11.8836 41.5371 16.7277Z"
        fill="currentColor"
      />
      <path
        d="M13.6759 26.3164H0.138672C1.24625 37.6738 10.2291 46.7167 21.537 47.874V34.2038L13.6759 26.3164ZM6.26311 31.1641H11.6078L17.0004 36.5735V41.7571C12.1324 39.8568 8.23548 36.0082 6.26311 31.1641Z"
        fill="currentColor"
      />
      <path
        d="M26.2615 34.2021V47.89C37.665 46.8199 46.7471 37.7396 47.8618 26.3146H34.1244L26.2615 34.2021ZM41.5389 31.1623C39.5665 36.0064 35.6696 39.8568 30.8016 41.7553V36.5717L36.1942 31.1623H41.5389Z"
        fill="currentColor"
      />
    </svg>
  );
}
