import { differenceInDays, startOfYear } from "date-fns";

export const wait = (timeMs: number): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, timeMs));

export function calculateYTD() {
  // Get the current date and time
  const currentDate = new Date();

  // Convert to the start of the day
  const startOfCurrentDate = startOfYear(currentDate);

  // Calculate the difference in days between the current date and the start of the year
  const daysSinceStartOfYear = differenceInDays(
    currentDate,
    startOfCurrentDate,
  );

  return daysSinceStartOfYear.toString();
}
