import { Connection } from "@solana/web3.js";
import { COMMITMENT } from "./constants";
import { fetchWithRateLimit } from "@/rpc-connection/fetch";

export class MultiplexedConnection {
  private _readConnection: Connection;
  private _writeConnections: Connection[] = [];

  constructor({
    readUrl,
    writeRpcUrls,
  }: {
    readUrl: string;
    writeRpcUrls: string[];
  }) {
    this._readConnection = new Connection(readUrl, {
      commitment: COMMITMENT,
    });

    this._writeConnections = writeRpcUrls.map((url) => {
      return new Connection(url, {
        disableRetryOnRateLimit: true,
        commitment: COMMITMENT,
        fetch: fetchWithRateLimit,
      });
    });
  }

  get readConnection(): Connection {
    return this._readConnection;
  }

  get writeConnections(): Connection[] {
    if (!this._writeConnections.length) {
      throw new Error(
        "Write connections not initialized, did you provide an empty array?",
      );
    }

    return this._writeConnections;
  }
}
