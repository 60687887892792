import { ArrowIcon } from "./arrow";
import { ArrowLargeIcon } from "./arrow-large";
import { ArrowSlimIcon } from "./arrow-slim";
import { BackArrowBoxed } from "./back-arrow-boxed";
import { BackspaceIcon } from "./backspace";
import { CheckboxOffIcon } from "./checkbox-off";
import { CheckboxOnIcon } from "./checkbox-on";
import { CheckIcon } from "./check";
import { ChevronIcon } from "./chevron";
import { CircleCheckIcon } from "./circle-check";
import { CircleClose } from "./circle-close";
import { CircleIcon } from "./circle";
import { CirclePartialIcon } from "./circle-partial";
import { CloseIcon } from "./close";
import { CoinbaseIcon } from "./coinbase";
import { CoinIcon } from "./coin";
import { CopyIcon } from "./copy";
import { DFlowLogo } from "./dflow-logo";
import { DiscordIcon } from "./discord-icon";
import { DocumentIcon } from "./document";
import { DownloadIcon } from "./download";
import { EmailIcon } from "./email";
import { ErrorIcon } from "./error";
import { ExternalIcon } from "./external";
import { FailureIcon } from "./failure";
import { InfoIcon } from "./info";
import { Key } from "./key";
import { LinkIcon } from "./link";
import { LockIcon } from "./lock";
import { LogoIcon } from "./logo";
import { MedalIcon } from "./medal";
import { MoneyArrowsIcon } from "./money-arrows";
import { PieChartIcon } from "./pie-chart";
import { QRCodeAppIcon } from "./qrcode-app";
import { QRCodeIcon } from "./qrcode";
import { RunIcon } from "./run";
import { SearchIcon } from "./search";
import { ShareIcon } from "./share";
import { ShieldIcon } from "./shield";
import { SwitchCurvedIcon } from "./switch-curved";
import { SwitchIcon } from "./switch";
import { UnknownIcon } from "./unknown";
import { UserIcon } from "./user";
import { WalletIcon } from "./wallet";
import { PishingIcon } from "./pishing";
import { WarningIcon } from "./warning";
import { XIcon } from "./x-icon";
import { CogIcon } from "./cog";
import { ExclamationMarkIcon } from "./exclamation-mark";
import { FireIcon } from "./fire";
import { GraphIcon } from "./graph";
import { StarHalfIcon } from "./star-half";
import { LineChartIcon } from "./line-chart";
import { PercentBoxIcon } from "./percent-box";
import { DepositIcon } from "./deposit";
import { VerifiedIcon } from "./verified";
import { ActionIcon } from "./action";
import { TrendIllustrationIcon } from "@/components/icon/icons/trend-illustration";
import { HeartIcon } from "./heart";
import { HeartFilledIcon } from "./heart-filled";
import { BoltIcon } from "./bolt";
import { CoinIllustrationIcon } from "./coin-illustration";
import { HeartIllustrationIcon } from "./heart-illustration";
import { HandSwipeIcon } from "./hand-swipe";
import { BinocularsIllustrationIcon } from "./binoculars-illustration";
import { SpeakerphoneIcon } from "./speakerphone";

export const icons = {
  "arrow": ArrowIcon,
  "check": CheckIcon,
  "chevron": ChevronIcon,
  "exclamation-mark": ExclamationMarkIcon,
  "switch": SwitchIcon,
  "switch-curved": SwitchCurvedIcon,
  "unknown": UnknownIcon,
  "user": UserIcon,
  "back-arrow-boxed": BackArrowBoxed,
  "warning": WarningIcon,
  "money-arrows": MoneyArrowsIcon,
  "pie-chart": PieChartIcon,
  "medal": MedalIcon,
  "document": DocumentIcon,
  "qrcode": QRCodeIcon,
  "qrcode-app": QRCodeAppIcon,
  "close": CloseIcon,
  "search": SearchIcon,
  "info": InfoIcon,
  "key": Key,
  "copy": CopyIcon,
  "coin": CoinIcon,
  "share": ShareIcon,
  "wallet": WalletIcon,
  "pishing": PishingIcon,
  "circle": CircleIcon,
  "circle-check": CircleCheckIcon,
  "logo": LogoIcon,
  "deposit": DepositIcon,
  "download": DownloadIcon,
  "link": LinkIcon,
  "dflow-logo": DFlowLogo,
  "backspace": BackspaceIcon,
  "coinbase": CoinbaseIcon,
  "external": ExternalIcon,
  "checkbox-off": CheckboxOffIcon,
  "checkbox-on": CheckboxOnIcon,
  "email": EmailIcon,
  "failure": FailureIcon,
  "error": ErrorIcon,
  "arrow-slim": ArrowSlimIcon,
  "arrow-large": ArrowLargeIcon,
  "shield": ShieldIcon,
  "run": RunIcon,
  "lock": LockIcon,
  "circle-partial": CirclePartialIcon,
  "circle-close": CircleClose,
  "discord": DiscordIcon,
  "x": XIcon,
  "cog": CogIcon,
  "fire": FireIcon,
  "graph": GraphIcon,
  "star-half": StarHalfIcon,
  "line-chart": LineChartIcon,
  "percent-box": PercentBoxIcon,
  "verified": VerifiedIcon,
  "action": ActionIcon,
  "coin-illustration": CoinIllustrationIcon,
  "trend-illustration": TrendIllustrationIcon,
  "heart": HeartIcon,
  "heart-filled": HeartFilledIcon,
  "bolt": BoltIcon,
  "heart-illustration": HeartIllustrationIcon,
  "hand-swipe": HandSwipeIcon,
  "binoculars-illustration": BinocularsIllustrationIcon,
  "speakerphone": SpeakerphoneIcon,
};
