import { BaseIconProps } from "../icon";

export function ErrorIcon(props: BaseIconProps) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7 0.125C3.21111 0.125 0.125 3.21111 0.125 7C0.125 10.7889 3.21111 13.875 7 13.875C10.7889 13.875 13.875 10.7889 13.875 7C13.875 3.21111 10.7889 0.125 7 0.125ZM7 1.65278C8.14074 1.65278 9.26111 2.01944 10.1676 2.68148L2.75278 10.244C2.04491 9.31713 1.65278 8.1662 1.65278 7C1.65278 4.05139 4.05139 1.65278 7 1.65278ZM7 12.3472C5.85926 12.3472 4.73889 11.9806 3.83241 11.3185L11.2472 3.75602C11.9551 4.68287 12.3472 5.8338 12.3472 7C12.3472 9.94861 9.94861 12.3472 7 12.3472Z"
        fill="currentColor"
      />
    </svg>
  );
}
