import { useEffect, useState } from "react";

const MAINNET_GENESIS_HASH = "5eykt4UsFv8P8NJdTREpY1vzqKqZKvdpKuc147dw2N9d";

export async function validateRpcUrl(urlStr: string) {
  if (!urlStr) {
    throw new Error("RPC URL is required");
  }
  let url: URL;
  try {
    url = new URL(urlStr);
  } catch {
    throw new Error("Invalid URL");
  }

  const rpcResponse = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({
      "jsonrpc": "2.0",
      "id": 1,
      "method": "getGenesisHash",
    }),
  });

  if (!rpcResponse.ok) {
    const resp = (await rpcResponse.json()) as { error: { message: string } };
    throw new Error(`RPC Error: ${resp.error.message}`);
  }

  const json = (await rpcResponse.json()) as { result: string };

  if (json.result !== MAINNET_GENESIS_HASH) {
    throw new Error("RPC must be connected to Solana Mainnet");
  }
}

export const unvalidatedToken = Symbol("unvalidated");

export function useValidatedRpcUrl(urlStr: string) {
  const [debounced, setDebounced] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [validatedRpcUrl, setValidatedRpcUrl] = useState<
    string | typeof unvalidatedToken
  >(unvalidatedToken);
  const [error, setError] = useState("");
  const [prev, setPrev] = useState(urlStr);
  if (prev !== urlStr) {
    setPrev(urlStr);
    setError("");
    if (urlStr === "") {
      setValidatedRpcUrl("");
    } else {
      setValidatedRpcUrl(unvalidatedToken);
    }
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebounced(urlStr);
    }, 1500);

    return () => clearTimeout(timeout);
  }, [urlStr]);

  useEffect(() => {
    const run = async () => {
      try {
        setError("");
        if (!debounced) return;

        setIsLoading(true);
        await validateRpcUrl(debounced);
        setValidatedRpcUrl(debounced);
      } catch (err) {
        const errMsg =
          err instanceof Error ? err.message : "Something happened";
        setError(errMsg);
        setValidatedRpcUrl(unvalidatedToken);
      } finally {
        setIsLoading(false);
      }
    };
    if (debounced) {
      void run();
    }
  }, [debounced]);

  return { validatedRpcUrl, isLoading, error, setError };
}
