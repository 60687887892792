import { TokenInfo } from "@/api";

export function getFallbackTokenInfo(
  mintAddress: string | undefined,
): TokenInfo | null {
  if (!mintAddress) return null;
  return {
    address: mintAddress,
    decimals: 6,
    name: undefined,
    symbol: undefined,
    verified: false,
  };
}
