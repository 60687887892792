import {
  Button,
  ButtonRow,
  EmptyState,
  ErrorMessage,
  Icon,
  Page,
  Shimmer,
} from "@/components";
import { SearchParamsWithRedirect } from "@/utils";
import { useEmbeddedWallet } from "@dynamic-labs/sdk-react-core";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
} from "date-fns";
import { useEffect, useState } from "react";

export const Route = createFileRoute(
  "/profile/wallet-settings/manage-passkeys/",
)({
  component: ManagePasskeys,
  validateSearch: (
    searchParams?: Record<string, unknown>,
  ): SearchParamsWithRedirect => ({
    redirect:
      typeof searchParams?.redirect === "string"
        ? searchParams.redirect
        : "/profile",
  }),
});

function ManagePasskeys() {
  const search = Route.useSearch();
  const { getPasskeys, isLoadingEmbeddedWallet } = useEmbeddedWallet();
  type UserPasskeys = Awaited<ReturnType<typeof getPasskeys>>;

  const [passkeys, setPasskeys] = useState<UserPasskeys>([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    let stale = false;
    if (isLoadingEmbeddedWallet) return;
    const run = async () => {
      try {
        setIsLoading(true);
        setError("");
        const _passkeys = await getPasskeys();
        if (stale) return;
        setPasskeys(_passkeys);
      } catch (e) {
        if (stale) return;
        console.error(e);
        setError(e instanceof Error ? e.message : "An unknown error occurred");
      } finally {
        // eslint-disable-next-line no-unsafe-finally
        if (stale) return;
        setIsLoading(false);
      }
    };
    void run();

    return () => {
      stale = true;
    };
  }, [getPasskeys, isLoadingEmbeddedWallet]);

  const submit = () => {
    void navigate({
      to: "/profile/wallet-settings/create-session",
      search: {
        redirect: decodeURI(search.redirect ?? ""),
        isManagingPasskeys: true,
      },
      replace: true,
    });
  };
  return (
    <Page
      flex
      fullScreenHeight
      title="Manage Passkeys"
      backButton={{ to: "/profile/wallet-settings", search: (prev) => prev }}
      hideTabs
    >
      <div className="w-full flex flex-col p-5 pb-button-row flex-grow">
        {isLoadingEmbeddedWallet || isLoading ? (
          <Shimmer.List rows={2} variant="cards" />
        ) : (
          <div className="flex flex-1 flex-col items-center gap-2.5 max-h-[calc(100dvh-250px)] overflow-auto">
            {passkeys.length === 0 && (
              <div className="flex flex-1 items-center">
                <EmptyState illustration="Key">
                  Set up a passkey to avoid re-entering a one-time code to sign
                  transactions.
                </EmptyState>
              </div>
            )}

            {error ? (
              <ErrorMessage variant="rounded">{error}</ErrorMessage>
            ) : (
              passkeys.map((passkey) => (
                <div
                  key={passkey.id}
                  className="p-2.5 w-full border border-white/10 rounded-xl bg-box"
                >
                  <div className="flex gap-2.5">
                    {passkey.storage?.icon ? (
                      <img
                        src={passkey.storage.icon}
                        className="shrink-0 w-6 h-6"
                      />
                    ) : (
                      <Icon name="unknown" className="shrink-0 w-6 h-6" />
                    )}

                    <div className="flex flex-col">
                      <h2 className="text-white font-bold text-sm leading-snug">
                        {passkey.storage?.name ?? "N/A"}
                      </h2>
                      <p className="text-gray-200 text-sm leading-snug">
                        Created {getTimeAgo(passkey.createdAt)}
                      </p>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        )}
        <ButtonRow noTabs>
          <Button variant="cta" className="w-full" onClick={submit}>
            Create new passkey
          </Button>
        </ButtonRow>
      </div>
    </Page>
  );
}

function getTimeAgo(date: Date) {
  const now = new Date();
  const differenceDays = differenceInDays(now, date);
  const differenceHours = differenceInHours(now, date);
  const differenceMinutes = differenceInMinutes(now, date);

  return differenceDays > 0
    ? `${differenceDays} days ago`
    : differenceHours > 0
      ? `${differenceHours} hours ago`
      : differenceMinutes > 0
        ? `${differenceMinutes} minutes ago`
        : "Less than a minute ago";
}
