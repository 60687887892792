export const DFLOW_INVITE_BASEURL = "https://www.app.dflow.net/invites";
export const MAX_TRANSFER_BATCH_SIZE = 7;
export const SOL_ADDRESS = "So11111111111111111111111111111111111111112";
export const USDC_ADDRESS =
  import.meta.env.VITE_USE_MOCKS === "true"
    ? "USDCvC7pCCvnhDVDvSAwzkBbgpyV23H1A2Sg4dLBXXY"
    : "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v";
export const USDT_ADDRESS =
  import.meta.env.VITE_USE_MOCKS === "true"
    ? "USDTuicF1H1M316gcrQY2L1ofPpUK2uziMeYba5ahCt"
    : "Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB";
export const FOLLOW_TWITTER_URL = "https://twitter.com/DFlowProtocol";
export const JOIN_DISCORD_URL = "https://discord.gg/dflow";
export const WEB3AUTH_FORGOT_PASSWORD_GUIDE_URL =
  "https://docs.dflow.net/docs/migration-guide";
// eslint-disable-next-line @typescript-eslint/no-inferrable-types
export const DISABLE_MIGRATION_FLOW: boolean = false;
export const TOAST_COLLAPSE_DURATION = 350;
export const QUOTE_TIMEOUT = 20000;
