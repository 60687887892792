import { BaseIconProps } from "../icon";

export function LineChartIcon(props: BaseIconProps) {
  return (
    <svg
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.77344 0.666656V2.51851H5.73349V0.666656H3.77344ZM13.5737 0.666656V2.51851H15.5337V0.666656H13.5737ZM17.7809 1.86385L14.5537 4.9129L11.6136 2.13512L7.69354 5.83882L4.75347 3.06104L1.12049 6.49348L2.5063 7.8028L4.75347 5.67968L7.69354 8.45745L11.6136 4.75375L14.5537 7.53153L19.1667 3.17317L17.7809 1.86385ZM3.77344 8.07406V9.92592H5.73349V8.07406H3.77344ZM13.5737 8.07406V9.92592H15.5337V8.07406H13.5737ZM3.77344 11.7778V13.6296H5.73349V11.7778H3.77344ZM13.5737 11.7778V13.6296H15.5337V11.7778H13.5737ZM0.833374 15.4815V17.3333H18.4738V15.4815H0.833374Z"
        fill="currentColor"
      />
    </svg>
  );
}
