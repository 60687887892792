import { Quote } from "@/api";
import { PublicKey } from "@solana/web3.js";

const PLATFORM_FEE_BPS = {
  stablecoin: 0,
  blueChip: 25,
  default: 25,
} as const;

const STABLECOIN_MINTS = [
  "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v", // USDC
  "Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB", // USDT
  "2b1kV6DkPAnxd5ixfnxCpjxmKwqjjaYmCZfHsFu24GXo", // PYUSD
];
// Ensure that mints are valid
STABLECOIN_MINTS.map((mint) => new PublicKey(mint));
const STABLECOIN_MINTS_SET = new Set(STABLECOIN_MINTS);

const BLUE_CHIP_MINTS = [
  "So11111111111111111111111111111111111111112", // SOL
  "mSoLzYCxHdYgdzU16g5QSh3i5K3z3KZK7ytfqcJm7So", // mSOL
  "bSo13r4TkiE4KumL71LsHTPpL2euBYLFx6h9HP3piy1", // bSOL
  "Dso1bDeDjCQxTrWHqUUi63oBvV7Mdm6WaobLbQ7gnPQ", // dSOL
  "J1toso1uCk3RLmjorhTtrVwY9HJ7X8V9yYac6Y7kGCPn", // JitoSOL
  "he1iusmfkpAdwvxLNGV8Y1iSbj4rUy6yMhEA3fotn9A", // hSOL
  "jupSoLaHXQiZZTSfEWMTRRgpnyFm8f6sZdosWBjx93v", // jupSOL
  "7dHbWXmci3dT8UFYWYZweBLXgycu7Y3iL6trKn1Y7ARj", // stSOL

  "EKpQGSJtjMFqKZ9KQanSqYXRcF8fBopzLHYxdM65zcjm", // WIF
  "DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263", // Bonk

  "5oVNBeEEQvYi1cX3ir8Dx5n1P7pdxydbGF2X4TxVusJm", // INF
  "7i5KKsX2weiTkry7jA4ZwSuXGhs5eJBEjY8vVxR4pfRx", // GMT
  "z3dn17yLaGMKffVogeFHQ9zWVcXgqgf3PQnDsNs2g6M", // OXY
  "JUPyiwrYJFskUPiHa7hkeR8VUtAeFoSYbKedZNsDvCN", // JUP
  "BZLbGTNCSFfoth2GYDtwr7e4imWzpR5jqcUuGEwr646K", // IO
  "4vMsoUT2BWatFweudnQM1xedRLfJgJ7hswhcpz4xgBTy", // HONEY
  "4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R", // RAY
  "rndrizKT3MK1iimdxRdWabcF7Zg7AR5T4nud4EkHBof", // RENDER
  "HZ1JovNiVvGrGNiiYvEozEVgZ58xaU3RKwX8eACQBCt3", // PYTH
  "ZEUS1aR7aX8DFFJf5QjWj2ftDDdNTroMNGo8YoQm3Gq", // ZEUS
  "KMNo3nJsBXfcpJTVhZcXLW7RmTwTt4GVFE7suUBo9sS", // KMNO
  "hntyVP6YFm1Hg25TN9WGLqM12b8TQmcknKrdu1oxWux", // HNT
  "nosXBVoaCTtYdLvKY6Csb4AC8JCdQKKAaWYtx2ZMoo7", // NOS
  "METAewgxyPbgwsseH8T16a39CQ5VyVxZi9zXiDPY18m", // MPLX
  "EchesyfXePKdLtoiZSL8pBe8Myagyy8ZRqsACNCFGnvp", // FIDA
  "DriFtupJYLTosbwoN8koMbEYSx54aFAVLddWsbksjwg7", // DRIFT
  "4LLbsb5ReP3yEtYzmXewyGjcir5uXtKFURtaEUVC2AHs", // PRCL
  "27G8MtK7VtTcCHkpASjSDdkWWYfoqT6ggEuKidVJidD4", // JLP
  "TNSRxcUxoT9xBG3de7PiJyTDYu7kskLqcpddxnEJAS6", // TNSR
  "SRMuApVNdxXokk5GT7XD5cUUgXMBCoAz2LHeuAoKWRt", // SRM
  "MNDEFzGvMt87ueuHvVU9VcTqsAP5b3fTGPsHuuPA5ey", // MDNE
  "5MAYDfq5yxtudAhtfyuMBuHZjgAbaS9tbEyEQYAhDS5y", // ACS
  "LFNTYraetVioAPnGJht4yNg2aUZFXR776cMeN9VMjXp", // LFNTY
  "85VBFQZC9TZkfaptBWjvUw7YbZjy52A6mjtPGjstQAmQ", // W
  "orcaEKTdK7LKz57vaAYr9QeNsVEPfiu6QeMU1kektZE", // ORCA
  "mb1eu7TzEc71KxDpsmsKoucSSuuoGLv1drys1oP2jh6", // MOBILE
];
// Ensure that mints are valid
BLUE_CHIP_MINTS.map((mint) => new PublicKey(mint));
const BLUE_CHIP_MINTS_SET = new Set(BLUE_CHIP_MINTS);

export function getPlatformFeeBps(
  sendMint: string,
  receiveMint: string,
): number {
  try {
    // Ensure that mints are valid
    new PublicKey(sendMint);
    new PublicKey(receiveMint);
  } catch {
    return PLATFORM_FEE_BPS.default;
  }

  const isSendStablecoin = STABLECOIN_MINTS_SET.has(sendMint);
  const isReceiveStablecoin = STABLECOIN_MINTS_SET.has(receiveMint);
  if (isSendStablecoin && isReceiveStablecoin) {
    return PLATFORM_FEE_BPS.stablecoin;
  }

  const isSendBlueChip = BLUE_CHIP_MINTS_SET.has(sendMint);
  const isReceiveBlueChip = BLUE_CHIP_MINTS_SET.has(receiveMint);
  if (
    (isSendStablecoin || isSendBlueChip) &&
    (isReceiveStablecoin || isReceiveBlueChip)
  ) {
    return PLATFORM_FEE_BPS.blueChip;
  }

  return PLATFORM_FEE_BPS.default;
}

export const HAZE_REFERRAL_ACCOUNT = new PublicKey(
  "Fs1Rk8He8HRaJ2Dg5mCWkyaQUfqfuip7LcBLmkgLTLMa",
);

const REFERRAL_PROGRAM_ID = new PublicKey(
  "REFER4ZgmyYx9c6He5XfaTMiGfdLwRnkV4RPp9t9iF3",
);

export function getPlatformFeeAccount(quote: Quote): PublicKey | undefined {
  const effectivePlatformFeeBps = quote.platformFee?.feeBps ?? 0;
  if (effectivePlatformFeeBps === 0) {
    return undefined;
  }
  const feeMint = new PublicKey(quote.outputMint);
  return PublicKey.findProgramAddressSync(
    [
      Buffer.from("referral_ata"),
      HAZE_REFERRAL_ACCOUNT.toBuffer(),
      feeMint.toBuffer(),
    ],
    REFERRAL_PROGRAM_ID,
  )[0];
}
