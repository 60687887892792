import { Button, Drawer, Icon, Link } from "@/components";
import { cn, FOLLOW_TWITTER_URL, JOIN_DISCORD_URL } from "@/utils";
import { Navigate, createFileRoute } from "@tanstack/react-router";
import { useEffect, useState } from "react";
import * as ToggleGroup from "@radix-ui/react-toggle-group";
import { useIsLoggedIn } from "@dynamic-labs/sdk-react-core";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { useMobileDetect } from "./-useMobileDetect";

export const Route = createFileRoute("/")({
  component: Index,
});

interface ToggleOption {
  label: string;
  value: string;
}

const options: ToggleOption[] = [
  { label: "iOS", value: "ios" },
  { label: "Android", value: "android" },
];

interface StepProps {
  label: number;
  value: string;
  image: string;
  imageWidth: number;
  isIOS?: boolean;
}

const Step = ({ label, value, image, imageWidth, isIOS }: StepProps) => (
  <div className="flex flex-col items-center justify-center gap-3.5">
    <p
      className={cn(
        "flex flex-col items-center justify-center p-2.5 w-[30px] h-[30px] rounded-full font-bold border border-accent",
        {
          "w-[50px] h-[50px]": isIOS,
        },
      )}
    >
      {label}
    </p>
    <p className="font-bold text-center text-md">{value}</p>
    <img
      src={image}
      alt="DFlow logo"
      className="w-full h-auto shadow-[0px_30px_60px_0px_rgba(0,0,0,0.25)]"
      style={{ maxWidth: imageWidth }}
    />
  </div>
);

const stepsIOS = [
  {
    label: 1,
    value: "Tap the “Share” button in your browser",
    image: "/assets/splash/ios-1.png",
    imageWidth: 320,
  },
  {
    label: 2,
    value: "Then tap the “Add to Home Screen” button",
    image: "/assets/splash/ios-2.png",
    imageWidth: 320,
  },
];

const stepsAndroid = [
  {
    label: 1,
    value: "Tap the “Menu” button in your browser",
    image: "/assets/splash/android-1.png",
    imageWidth: 284,
  },
  {
    label: 2,
    value: "Tap “Install App” in the panel",
    image: "/assets/splash/android-2.png",
    imageWidth: 258,
  },
  {
    label: 3,
    value: "Tap “Install” in the pop up",
    image: "/assets/splash/android-3.png",
    imageWidth: 254,
  },
];

export { Index as IndexRoute };

function Index() {
  const isDesktopAndUp = useMediaQuery("(min-width: 1024px)");
  const [showContent, setShowContent] = useState(true);
  const deviceOS = useMobileDetect();
  const [selectedOS, setSelectedOS] = useState(deviceOS);
  const isIOS = selectedOS === "ios";
  const isPWA = window.matchMedia("(display-mode: standalone)").matches;
  const isLoggedIn = useIsLoggedIn();

  useEffect(() => {
    setSelectedOS(deviceOS);
  }, [deviceOS]);

  useEffect(() => {
    const handleResize = () => {
      if (isDesktopAndUp) {
        setShowContent(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isDesktopAndUp]);

  if (isLoggedIn && !isDesktopAndUp) {
    return <Navigate to="/trade" />;
  }

  return (
    <div className="select-none fixed top-0 bottom-0 left-0 right-0 bg-city-backdrop-mobile lg:bg-city-backdrop-desktop bg-center bg-cover overflow-y-auto flex flex-col">
      <div
        className={cn(
          "flex flex-col flex-1 justify-between px-5 max-w-[640px] mx-auto animate-fade-in duration-150 transition-opacity gap-10 pt-[calc(var(--safe-top)+154px)] pb-[calc(var(--safe-bottom)+1.25rem)] lg:py-14",
          showContent ? "opacity-100" : "opacity-0",
        )}
      >
        <div className="flex flex-col items-center gap-6 lg:gap-5 lg:flex-1 lg:justify-center">
          <img
            src="/assets/logo/dflow-logo-with-name.png"
            alt="DFlow logo"
            className="w-[185px] h-[40px]"
          />

          <h1 className="text-6xl leading-none lg:leading-[1.3] text-center font-semibold lg:text-5xl">
            {isDesktopAndUp
              ? "Download to start trading today"
              : "Trade any token, anywhere"}
          </h1>
          {!isDesktopAndUp && !isPWA ? (
            <p className="text-xl text-center">
              Download the app below for the best experience on mobile.
            </p>
          ) : null}
          {isDesktopAndUp && (
            <Icon name="qrcode-app" className="w-[186px] mx-auto" />
          )}
        </div>
        {isDesktopAndUp ? (
          <div className="flex justify-center gap-4 items-center">
            <a
              href={FOLLOW_TWITTER_URL as string}
              target="_blank"
              className="rounded-md flex items-center justify-center border border-cloud hover:border-cloudHover active:border-white p-2 w-11 h-10"
              rel="noreferrer"
            >
              <Icon name="x" className="w-4" />
            </a>
            <a
              href={JOIN_DISCORD_URL as string}
              target="_blank"
              className="rounded-md flex items-center justify-center border border-cloud hover:border-cloudHover active:border-white p-2 w-11 h-10"
              rel="noreferrer"
            >
              <Icon name="discord" className="w-5" />
            </a>
          </div>
        ) : (
          <div className="flex flex-col gap-5">
            {isPWA ? (
              <Button to="/login-signup" className="shrink-0">
                LOGIN / SIGN UP
              </Button>
            ) : (
              <>
                <Drawer.Root onOpenChange={(e) => setShowContent(!e)}>
                  <Drawer.Trigger asChild>
                    <Button
                      className={cn(
                        "shrink-0 animate-fade-in duration-150 transition-opacity",
                        showContent ? "opacity-100" : "opacity-0",
                      )}
                    >
                      DOWNLOAD
                    </Button>
                  </Drawer.Trigger>

                  <Drawer.Content variant="layer">
                    <ToggleGroup.Root
                      className="flex gap-2 items-center justify-center w-full mb-10"
                      type="single"
                      value={selectedOS}
                      onValueChange={setSelectedOS}
                      aria-label="Toggle operating system"
                    >
                      {options.map((option) => (
                        <ToggleGroup.Item
                          key={option.value}
                          className={cn(
                            "text-md text-white font-bold",
                            selectedOS === option.value && "text-primary",
                          )}
                          aria-label={option.value}
                          value={option.value}
                        >
                          {option.label}
                        </ToggleGroup.Item>
                      ))}
                    </ToggleGroup.Root>

                    <div className={"flex flex-col gap-10 pb-10"}>
                      {isIOS ? (
                        <>
                          {stepsIOS.map((step) => (
                            <Step
                              key={step.label}
                              label={step.label}
                              value={step.value}
                              image={step.image}
                              imageWidth={step.imageWidth}
                              isIOS
                            />
                          ))}
                        </>
                      ) : (
                        <>
                          {stepsAndroid.map((step) => (
                            <Step
                              key={step.label}
                              label={step.label}
                              value={step.value}
                              image={step.image}
                              imageWidth={step.imageWidth}
                            />
                          ))}
                        </>
                      )}
                    </div>
                  </Drawer.Content>
                </Drawer.Root>
                <Link
                  to="/login-signup"
                  className={cn(
                    "text-primary leading-none font-bold uppercase text-sm text-center px-5 animate-fade-in duration-150 transition-opacity",
                    showContent ? "opacity-100" : "opacity-0",
                  )}
                >
                  LOGIN / SIGN UP
                </Link>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
