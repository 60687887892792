import { api, getAuthHeaders } from "./api";
import type { TokenInfoWithPrice } from "./token-api";
import { getRedirectUri } from "./twitter";

const USER_API_BASE_URL = import.meta.env.VITE_USER_API_BASE_URL;

export const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    twitterConnect: builder.mutation<void, { code: string }>({
      query: ({ code }) => ({
        url: `${USER_API_BASE_URL}/api/user/twitter/connect`,
        method: "POST",
        headers: getAuthHeaders(),
        body: JSON.stringify({
          code,
          clientId: import.meta.env.VITE_TWITTER_CLIENT_ID,
          redirectUri: getRedirectUri(),
        }),
      }),
      invalidatesTags: ["User", "UserXp", "Leaderboard"],
    }),
    twitterDisconnect: builder.mutation<void, void>({
      query: () => ({
        url: `${USER_API_BASE_URL}/api/user/twitter/disconnect`,
        method: "POST",
        headers: getAuthHeaders(),
      }),
      invalidatesTags: ["User", "UserXp", "Leaderboard"],
    }),
    user: builder.query<User, void>({
      query: () => ({
        url: `${USER_API_BASE_URL}/api/user`,
        headers: getAuthHeaders(),
      }),
      providesTags: ["User"],
    }),
    upsertUser: builder.mutation<UpsertUserResponse, void>({
      query: () => ({
        url: `${USER_API_BASE_URL}/api/user`,
        headers: getAuthHeaders(),
        method: "POST",
      }),
      invalidatesTags: ["User"],
    }),
    updateUserWallet: builder.mutation<MutationResponse<User>, void>({
      query: () => ({
        url: `${USER_API_BASE_URL}/api/user/wallet`,
        headers: getAuthHeaders(),
        method: "PATCH",
      }),
      invalidatesTags: ["User"],
    }),
    phaseZeroUser: builder.query<PhaseZeroUser, void>({
      query: () => ({
        url: `${USER_API_BASE_URL}/api/user/phase_zero`,
        headers: getAuthHeaders(),
      }),
    }),
    setMigrationStatus: builder.mutation<
      MutationResponse<User>,
      MigrationStatus
    >({
      query: (migrationStatus) => ({
        url: `${USER_API_BASE_URL}/api/user/migration-status`,
        headers: getAuthHeaders(),
        method: "PATCH",
        body: JSON.stringify({ migrationStatus }),
      }),
      invalidatesTags: ["User"],
    }),
    previouslyUsedWithdrawalAddresses: builder.query<PreviousAddress[], void>({
      query: () => ({
        url: `${USER_API_BASE_URL}/api/user/previous_withdraw_addresses`,
        headers: getAuthHeaders(),
      }),
      providesTags: ["PreviousWithdrawalAddresses"],
    }),
    upsertWithdrawalAddress: builder.mutation<PreviousAddress, string>({
      query: (address) => ({
        url: `${USER_API_BASE_URL}/api/user/previous_withdraw_address`,
        headers: getAuthHeaders(),
        method: "POST",
        body: JSON.stringify({ address }),
      }),
      invalidatesTags: ["PreviousWithdrawalAddresses"],
    }),
    updateUsername: builder.mutation<User, string>({
      query: (username) => ({
        url: `${USER_API_BASE_URL}/api/user/username`,
        headers: getAuthHeaders(),
        method: "PATCH",
        body: JSON.stringify({ username }),
      }),
      invalidatesTags: ["User", "UserXp", "Leaderboard"],
      async onQueryStarted(arg, ctx) {
        ctx.dispatch(
          userApi.util.updateQueryData("user", undefined, (draft) => {
            draft.username = arg;
          }),
        );
        try {
          await ctx.queryFulfilled;
        } catch {
          userApi.util.invalidateTags(["User"]);
        }
      },
    }),
    updateSettings: builder.mutation<User, SettingsUpdate>({
      query: (update) => ({
        url: `${USER_API_BASE_URL}/api/user/settings`,
        headers: getAuthHeaders(),
        method: "PATCH",
        body: JSON.stringify(update),
      }),
      invalidatesTags: ["User"],
      async onQueryStarted(arg, ctx) {
        ctx.dispatch(
          userApi.util.updateQueryData("user", undefined, (draft) => {
            Object.assign(draft, arg);
          }),
        );
        try {
          await ctx.queryFulfilled;
        } catch {
          userApi.util.invalidateTags(["User"]);
        }
      },
    }),
    quickTradeSettings: builder.query<QuickTradeResponse, void>({
      query: () => ({
        url: `${USER_API_BASE_URL}/api/user/quick_trade`,
        headers: getAuthHeaders(),
      }),
      providesTags: ["QuickTradeSettings"],
    }),
    updateQuickTradeSettings: builder.mutation<
      QuickTradeSettings,
      QuickTradeSettings
    >({
      query: (settings) => ({
        url: `${USER_API_BASE_URL}/api/user/quick_trade`,
        headers: getAuthHeaders(),
        method: "POST",
        body: JSON.stringify(settings),
      }),
      invalidatesTags: ["QuickTradeSettings"],
      async onQueryStarted(arg, ctx) {
        ctx.dispatch(
          userApi.util.updateQueryData(
            "quickTradeSettings",
            undefined,
            (draft) => {
              if (draft.settings) {
                Object.assign(draft.settings, arg);
              } else {
                draft.settings = arg;
              }
            },
          ),
        );
        try {
          await ctx.queryFulfilled;
        } catch {
          userApi.util.invalidateTags(["QuickTradeSettings"]);
        }
      },
    }),
    updateQuickTradeEnabled: builder.mutation<boolean, boolean>({
      query: (enabled) => ({
        url: `${USER_API_BASE_URL}/api/user/quick_trade/enable`,
        headers: getAuthHeaders(),
        method: "POST",
        body: JSON.stringify({ enabled }),
      }),
      invalidatesTags: ["QuickTradeSettings"],
      async onQueryStarted(enabled, ctx) {
        ctx.dispatch(
          userApi.util.updateQueryData(
            "quickTradeSettings",
            undefined,
            (draft) => {
              draft.enabled = enabled;
            },
          ),
        );
        try {
          await ctx.queryFulfilled;
        } catch {
          userApi.util.invalidateTags(["QuickTradeSettings"]);
        }
      },
    }),
    favoriteTokens: builder.query<FavoriteToken[] | null, void>({
      query: () => ({
        url: `${USER_API_BASE_URL}/api/user/favorite_tokens`,
        headers: getAuthHeaders(),
      }),
      providesTags: ["FavoriteTokens"],
    }),
    addFavoriteToken: builder.mutation<FavoriteTokenResponse, string>({
      query: (address) => ({
        url: `${USER_API_BASE_URL}/api/user/favorite_token`,
        headers: getAuthHeaders(),
        method: "POST",
        body: JSON.stringify({ address }),
      }),
      invalidatesTags: ["FavoriteTokens"],
      async onQueryStarted(arg, ctx) {
        ctx.dispatch(
          userApi.util.updateQueryData("favoriteTokens", undefined, (draft) => {
            const likedAt = new Date().toISOString();
            draft?.push({ address: arg, likedAt });
          }),
        );
        try {
          const { data } = await ctx.queryFulfilled;
          ctx.dispatch(
            userApi.util.updateQueryData(
              "favoriteTokens",
              undefined,
              (draft) => {
                if (!draft) return;
                const idx = draft.findIndex((t) => t.address === arg);
                const userToken = {
                  address: data.data.token.address,
                  likedAt: data.data.createdAt,
                };
                if (idx === -1) {
                  draft.push(userToken);
                  return;
                }
                draft.splice(idx, 1, userToken);
              },
            ),
          );
        } catch {
          userApi.util.invalidateTags(["FavoriteTokens"]);
        }
      },
    }),
    removeFavoriteToken: builder.mutation<User, string>({
      query: (address) => ({
        url: `${USER_API_BASE_URL}/api/user/favorite_token`,
        headers: getAuthHeaders(),
        method: "DELETE",
        body: JSON.stringify({ tokenAddress: address }),
      }),
      invalidatesTags: ["FavoriteTokens"],
      async onQueryStarted(arg, ctx) {
        ctx.dispatch(
          userApi.util.updateQueryData("favoriteTokens", undefined, (draft) => {
            const index = draft?.findIndex((t) => t.address === arg);
            if (index !== undefined && index !== -1) {
              draft?.splice(index, 1);
            }
          }),
        );
        try {
          await ctx.queryFulfilled;
        } catch {
          userApi.util.invalidateTags(["FavoriteTokens"]);
        }
      },
    }),
  }),
});

export const {
  useSetMigrationStatusMutation,
  usePhaseZeroUserQuery,
  useUserQuery,
  usePreviouslyUsedWithdrawalAddressesQuery,
  useUpsertUserMutation,
  useUpsertWithdrawalAddressMutation,
  useUpdateUsernameMutation,
  useUpdateSettingsMutation,
  useUpdateUserWalletMutation,
  useTwitterConnectMutation,
  useTwitterDisconnectMutation,
  useFavoriteTokensQuery,
  useAddFavoriteTokenMutation,
  useRemoveFavoriteTokenMutation,
  useQuickTradeSettingsQuery,
  useUpdateQuickTradeSettingsMutation,
  useUpdateQuickTradeEnabledMutation,
} = userApi;

export type UserSetting =
  | "priorityFeeSetting"
  | "slippageSetting"
  | "customRpcUrl"
  | "useWrappedSolSetting";

export type SettingsUpdate = Partial<Pick<User, UserSetting>>;

export interface PreviousAddress {
  address: string;
  lastUsed?: Date;
  userId: number;
  createdAt: Date;
}

export interface TokenBalanceEntry extends TokenInfoWithPrice {
  quantity: string;
  usdTotal: number | null;
}

type MigrationStatus = "NOT_NEEDED" | "NOT_STARTED" | "SKIPPED" | "COMPLETED";

interface MutationResponse<T> {
  message: string;
  data: T;
}

export interface User {
  id: number;
  email: string | null;
  phone: string | null;
  username: string | null;
  profileImage: string | null;
  wallet: string;
  migrationStatus: MigrationStatus;
  slippageSetting: number;
  priorityFeeSetting: number | null;
  customRpcUrl: string | null;
  useWrappedSolSetting: boolean;
  createdAt: string;
  previouslyUsedWithdrawAddresses: PreviousAddress[];
  orders: unknown;
  logs: unknown;
}

export interface PhaseZeroUser {
  id: number;
  email: string;
  username: string;
  wallet: string;
  admin: boolean;
  airdropsClaimed: boolean;
  flaggedForSuspiciousActivity: boolean;
  airdrops: unknown;
}

interface UpsertUserResponse {
  newUser: boolean;
  data: User;
  message: string;
}

export interface FavoriteToken {
  address: string;
  likedAt: string;
}

interface FavoriteTokenResponse {
  message: string;
  data: {
    id: number;
    user: null;
    userId: number;
    token: {
      id: number;
      address: string;
      createdAt: string;
      UserToken: null;
    };
    tokenId: number;
    createdAt: string;
  };
}

interface QuickTradeResponse {
  enabled: boolean;
  settings: QuickTradeResponseSettings | null;
}

interface QuickTradeResponseSettings extends QuickTradeSettings {
  id?: number | null;
  user?: null;
  userId?: number | null;
  createdAt?: string | null;
}

export interface QuickTradeSettings {
  slippageBps?: number | null;
  priorityFee?: number | null;
  solBuyAmount?: string | null;
  solSellBps?: number | null;
  usdcBuyAmount?: string | null;
  usdcSellBps?: number | null;
  usdtBuyAmount?: string | null;
  usdtSellBps?: number | null;
  buyTokenSymbol?: string | null;
  sellTokenSymbol?: string | null;
}
