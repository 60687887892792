import { Button, Icon, Page } from "@/components";
import { with404 } from "@/hocs/with404";
import { useMigrationStatus } from "@/hooks/useMigrationStatus";
import {
  DISABLE_MIGRATION_FLOW,
  WEB3AUTH_FORGOT_PASSWORD_GUIDE_URL,
  cn,
  getApiErrorMessage,
} from "@/utils";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/migrate/forgot-password/")({
  component: DISABLE_MIGRATION_FLOW ? with404(ForgotPassword) : ForgotPassword,
});

function ForgotPassword() {
  const { completeMigrationMutation, handleSkip } = useMigrationStatus();
  const error = completeMigrationMutation.error
    ? getApiErrorMessage(completeMigrationMutation.error)
    : undefined;

  return (
    <Page
      flex
      hideTabs
      fullScreenHeight
      title="Recover old account"
      backButton={{
        to: "/migrate/password",
        "aria-label": "Back",
        search: (prev) => prev,
        replace: true,
      }}
    >
      <div className="flex-1 flex flex-col justify-between px-5">
        <div
          className={cn(
            "flex flex-col justify-center items-center pt-3 gap-7 relative",
            { "pt-10": error },
          )}
        >
          {error ? (
            <div className="w-full p-2 bg-negative/10 text-negative text-xs font-bold text-center absolute top-0">
              {error}
            </div>
          ) : null}
          <Icon name="dflow-logo" className="w-12 h-12 text-accent" />

          <p className="text-gray-200 text-sm">
            If you have your private key, you can transfer to your new DFlow
            account from an external wallet provider.
          </p>

          <p className="text-gray-200 text-sm">
            If you don’t have access to your private key you will be unable to
            transfer funds and can proceed to the app.
          </p>
        </div>
        <div className="w-full flex flex-col gap-1">
          <Button
            variant="cta"
            disabled={completeMigrationMutation.isLoading}
            to={WEB3AUTH_FORGOT_PASSWORD_GUIDE_URL}
            target="_blank"
          >
            Go to guide
          </Button>
          <Button
            variant="flat"
            color="dark"
            onClick={handleSkip}
            disabled={completeMigrationMutation.isLoading}
          >
            Proceed without transferring
          </Button>
        </div>
      </div>
    </Page>
  );
}
