import { BaseIconProps } from "../icon";

export function CloseIcon(props: BaseIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M16.8959 0V2.23795H21.8106V7.26176H24V0H16.8959Z"
        fill="currentColor"
      />
      <path
        d="M2.18937 2.23795H7.10414V0H0V7.26176H2.18937V2.23795Z"
        fill="currentColor"
      />
      <path
        d="M21.8106 21.7621H16.8959V24H24V16.7382H21.8106V21.7621Z"
        fill="currentColor"
      />
      <path
        d="M2.18937 16.7382H0V24H7.10414V21.7621H2.18937V16.7382Z"
        fill="currentColor"
      />
      <path
        d="M16.1903 18.1674L12.1605 14.0482L8.1307 18.1674L6.54797 16.5495L10.5778 12.4303L6.54797 8.3111L8.1307 6.69324L12.1605 10.8125L16.1903 6.69324L17.773 8.3111L13.7432 12.4303L17.773 16.5495L16.1903 18.1674Z"
        fill="currentColor"
      />
    </svg>
  );
}
