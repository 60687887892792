import { BaseIconProps } from "../icon";

export function ArrowIcon(props: BaseIconProps) {
  return (
    <svg
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 3.84235L6.85794 4.93787L4.8078 2.97127L4.8078 8L3.1922 8L3.1922 2.97128L1.14206 4.93788L2.95101e-07 3.84235L4 -7.78829e-07L8 3.84235Z"
        fill="currentColor"
      />
    </svg>
  );
}
