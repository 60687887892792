import { useTokenAccountByMintQuery, useWalletInfoQuery } from "@/api";
import { useDebouncedFocus } from "@/hooks/useDebouncedFocus";
import { useTypedSelector } from "@/store";
import { SOL_ADDRESS } from "@/utils";
import { skipToken } from "@reduxjs/toolkit/query";
import { useCallback } from "react";

/**
 * This hook will determine whether the requested mint address
 * is a native SOL account or an SPL token account
 * and return the appropriate balance data.
 */
export function useAccountBalance(
  mintAddress: string | undefined,
  // Use this to override the usual native sol detection
  solTypeOverride?: "wsol" | "sol",
) {
  const useWrappedSol = useTypedSelector(
    (state) => state.profile.useWrappedSol,
  );
  const isNativeSol = !solTypeOverride
    ? mintAddress === SOL_ADDRESS && !useWrappedSol
    : solTypeOverride === "sol";
  const splTokenAccountQuery = useTokenAccountByMintQuery(
    mintAddress && !isNativeSol ? mintAddress : skipToken,
    { refetchOnMountOrArgChange: 5, pollingInterval: 30_000 },
  );
  const walletInfoQuery = useWalletInfoQuery();

  useDebouncedFocus(
    useCallback(() => {
      if (isNativeSol) {
        void walletInfoQuery.refetch();
      } else {
        void splTokenAccountQuery.refetch();
      }
    }, [isNativeSol, splTokenAccountQuery, walletInfoQuery]),
    30_000,
  );

  return isNativeSol
    ? {
        data: walletInfoQuery.data && {
          balance: walletInfoQuery.data.balance,
          rawBalance: walletInfoQuery.data.rawBalance,
        },
        isLoading: walletInfoQuery.isLoading,
      }
    : {
        data: splTokenAccountQuery.data && {
          balance: splTokenAccountQuery.data.tokenBalance,
          rawBalance: splTokenAccountQuery.data.tokenRawBalance,
        },
        isLoading: splTokenAccountQuery.isLoading,
      };
}
