import { Web3AuthClient } from "@/app/web3auth";
import { embeddedWallet } from "@/embedded-wallet";
import { globalClearState } from "@/store";
import { useDynamicContext } from "@dynamic-labs/sdk-react-core";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

export function useLogout() {
  const { handleLogOut } = useDynamicContext();
  const dispatch = useDispatch();
  const logout = useCallback(async () => {
    await handleLogOut();
    embeddedWallet.reset();
    Web3AuthClient.reset();
    dispatch(globalClearState());
  }, [handleLogOut, dispatch]);

  return logout;
}
