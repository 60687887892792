import {
  FavoriteToken,
  TokenInfoWithPrice,
  useAddFavoriteTokenMutation,
  useFavoriteTokensQuery,
  useRemoveFavoriteTokenMutation,
} from "@/api";
import {
  SwipeableActions,
  SwipeableActionsProps,
  Token,
  VirtualList,
} from "@/components";
import { useVisualViewportOffset } from "@/hooks";
import { useQuickBuy } from "@/hooks/useQuickTrade";
import { cn, formatAddressShort } from "@/utils";
import { forwardRef, useId, useImperativeHandle, useRef } from "react";

interface TokenListProps {
  onSelectToken: (token: TokenInfoWithPrice) => void;
  tokens: TokenInfoWithPrice[];
  arePricesLoading?: boolean;
  id?: string;
  searchKey: string;
  isFetching: boolean;
  isLoading?: boolean;
  hasNextPage: boolean;
  onNextPage?: () => void;
  extraHeight?: number;
  onQuickBuyLongPress?: (token: TokenInfoWithPrice) => void;
}

export const TokenList = forwardRef(function TokenList(
  {
    onSelectToken,
    tokens,
    arePricesLoading,
    id,
    searchKey,
    isFetching,
    isLoading,
    hasNextPage,
    onNextPage,
    extraHeight,
    onQuickBuyLongPress,
  }: TokenListProps,
  ref,
) {
  const favoriteTokensQuery = useFavoriteTokensQuery();
  const favoritesMap = favoriteTokensQuery.data?.reduce<
    Record<string, FavoriteToken>
  >((acc, token) => {
    acc[token.address] = token;
    return acc;
  }, {});
  const visualViewportOffset = useVisualViewportOffset();
  const [addFavoriteTokenMutate] = useAddFavoriteTokenMutation();
  const [removeFavoriteTokenMutate] = useRemoveFavoriteTokenMutation();
  const _id = useId();
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  useImperativeHandle(
    ref,
    () => {
      return {
        resetScroll() {
          scrollContainerRef.current?.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        },
      };
    },
    [],
  );
  const quickBuyProps = useQuickBuy();

  return (
    <VirtualList
      id={id || _id}
      ref={scrollContainerRef}
      classname="z-0 absolute overscroll-contain left-0 right-0 top-[calc(var(--header-height)+var(--h-search-bar)+var(--extra-height))] bottom-[max(calc(var(--h-tab-bar)-var(--safe-top)),calc(var(--visual-viewport-offset)-var(--safe-top)))] transition-all"
      itemHeight={76}
      searchKey={searchKey}
      totalItems={tokens.length}
      hasNextPage={hasNextPage}
      isFetching={isFetching}
      isLoading={isLoading}
      onNextPage={onNextPage}
      style={
        {
          "--visual-viewport-offset": `${visualViewportOffset ?? 0}px`,
          "--extra-height": `${extraHeight ?? 0}px`,
        } as React.CSSProperties
      }
    >
      {(virtualItem, virtualProps) => {
        const token =
          virtualItem.index < tokens.length
            ? tokens[virtualItem.index]
            : undefined;

        if (!token) {
          return null;
        }

        const isLiked = favoritesMap?.[token.address] !== undefined;

        const actions: SwipeableActionsProps["actions"] = [
          {
            label: isLiked ? "Liked" : "Like",
            icon: isLiked ? "heart-filled" : "heart",
            onClick: () => {
              if (isLiked) {
                void removeFavoriteTokenMutate(token.address);
              } else {
                void addFavoriteTokenMutate(token.address);
              }
            },
          },
          {
            label: quickBuyProps.submittingTokens.includes(token.address)
              ? "Buying"
              : "Buy",
            icon: "bolt",
            isLoading: quickBuyProps.isLoading,
            animateOnClick: true,
            onLongPress: onQuickBuyLongPress
              ? () => {
                  onQuickBuyLongPress(token);
                }
              : undefined,
            ...quickBuyProps.getActionProps(token),
          },
        ];

        const descriptionId = `description-${id}-${token.address}-${token.symbol}`;

        return (
          <div
            key={virtualItem.key}
            {...virtualProps}
            className={cn("border-b border-cloud", virtualProps.className)}
          >
            <SwipeableActions
              key={`${token.address}-${token.symbol}`}
              actions={actions}
              name={
                token.name ||
                token.symbol ||
                formatAddressShort(token.address, 5)
              }
              tokenDescriptionId={descriptionId}
            >
              <button
                type="button"
                className="w-full px-5 h-full"
                onClick={() => {
                  onSelectToken(token);
                }}
              >
                <Token
                  {...token}
                  isPriceLoading={arePricesLoading && !token.price}
                  showUnverified
                  descriptionId={descriptionId}
                />
              </button>
            </SwipeableActions>
          </div>
        );
      }}
    </VirtualList>
  );
});
