import { TokenInfo } from "@/api";
import { TokenIcon } from "@/components";
import { formatDate, getSymbolWithDefault } from "@/utils";

interface TokenPairProps {
  base: TokenInfo | undefined;
  quote: TokenInfo | undefined;
  date: Date;
}

export const TokenPair = ({ base, quote, date }: TokenPairProps) => {
  const baseSymbol = getSymbolWithDefault(base);
  const quoteSymbol = getSymbolWithDefault(quote);
  return (
    <div className="flex gap-2.5 w-full">
      <div className="flex items-center">
        <TokenIcon
          logoURI={base?.logoURI}
          className="min-w-6 w-6 h-6  lg:w-8 lg:h-8 "
        />
        <TokenIcon
          logoURI={quote?.logoURI}
          className="min-w-6 w-6 h-6 -ml-1 lg:w-8 lg:h-8 lg:-ml-2"
        />
      </div>
      <ul className="flex flex-col items-start text-sm gap-1 leading-none flex-1">
        <li className="text-white font-bold">
          {baseSymbol}/{quoteSymbol}
        </li>
        <li className="text-gray-200">{formatDate(date)}</li>
      </ul>
    </div>
  );
};
