import { useEffect, useState } from "react";

interface CustomWindow extends Window {
  opera?: string;
  MSStream?: string;
}
declare const window: CustomWindow;

export function useMobileDetect(): string {
  const [os, setOs] = useState<"android" | "ios">("android");

  useEffect(() => {
    const userAgent: string =
      (navigator.userAgent || navigator.vendor || window.opera) ?? "";
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setOs("ios");
    }
  }, []);

  return os;
}
