import { TokenInfoWithPrice } from "@/api";
import { Icon, IconProps, Input } from "@/components";
import { useSearchTokensQuery } from "@/hooks";
import { updateReceiveToken } from "@/routes/trade/-reducer";
import {
  AllList,
  FavoriteList,
  ResetScrollHandle,
  TrendingList,
} from "@/routes/trade/-search/categories";
import { updateCategory } from "./token-search-reducer";
import { VerifyTokenDrawer } from "@/routes/trade/-verify-token-drawer";
import { useAppDispatch, useTypedSelector } from "@/store";
import * as ToggleGroup from "@radix-ui/react-toggle-group";
import { useNavigate } from "@tanstack/react-router";
import { useImperativeHandle, useRef, useState } from "react";
import { Notifications } from "./notifications";
import { useNotifications } from "./use-notifications";
import { CustomQuickBuyDrawer } from "./custom-quick-buy-drawer";

interface ToggleGroupItemProps {
  value: string;
  icon: IconProps["name"];
}

function ToggleGroupItem({
  value,
  icon,
  children,
}: React.PropsWithChildren<ToggleGroupItemProps>) {
  return (
    <ToggleGroup.Item
      value={value}
      className="uppercase text-xs font-bold flex items-center border border-cloud aria-checked:border-lime aria-checked:bg-coriander-1000 aria-checked:shadow-[0px_0px_2px_0px_rgba(233,255,184,0.70)] rounded p-2 text-gray-200 aria-checked:text-lime group transition-all"
    >
      <Icon
        name={icon}
        className="w-6 h-6 text-current mr-1.5 text-gray-300 group-aria-checked:text-current"
      />
      {children}
    </ToggleGroup.Item>
  );
}

interface TokenSearchProps {
  tokenListScrollContainerRef: React.RefObject<ResetScrollHandle>;
}

export function TokenSearch({ tokenListScrollContainerRef }: TokenSearchProps) {
  const { useWrappedSol, hideUnverifiedTokenModal } = useTypedSelector(
    (state) => state.profile,
  );

  const searchTokensQuery = useSearchTokensQuery({
    solType: useWrappedSol ? "wsol" : "sol",
    persistSearchInUrl: true,
  });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { category } = useTypedSelector((state) => state.tokenSearchList);

  const [selectedUnverifiedToken, setSelectedUnverifiedToken] = useState<
    TokenInfoWithPrice | undefined
  >();

  const selectToken = (token: TokenInfoWithPrice) => {
    dispatch(updateReceiveToken(token));
    void navigate({
      to: "/trade",
      search: {
        receiveToken: token.address,
      },
    });
  };

  const onSelectTokenInList = (token: TokenInfoWithPrice) => {
    if (token.verified || hideUnverifiedTokenModal) {
      selectToken(token);
    } else {
      setSelectedUnverifiedToken(token);
    }
  };

  const trendingScrollRef = useRef<ResetScrollHandle>(null);
  const allScrollRef = useRef<ResetScrollHandle>(null);
  const favoriteScrollRef = useRef<ResetScrollHandle>(null);

  useImperativeHandle(tokenListScrollContainerRef, () => {
    return {
      resetScroll: () => {
        if (category === "trending") {
          trendingScrollRef.current?.resetScroll();
        } else if (category === "all") {
          allScrollRef.current?.resetScroll();
        } else if (category === "favorites") {
          favoriteScrollRef.current?.resetScroll();
        }
      },
    };
  });

  const {
    showNotifications,
    notifications,
    dismissNotification,
    notificationsHeight,
  } = useNotifications();

  const [quickBuyDrawerToken, setQuickBuyDrawerToken] = useState<
    TokenInfoWithPrice | undefined
  >(undefined);

  return (
    <>
      <div className="fixed top-header left-0 right-0 bg-gray-600 z-10 overscroll-none">
        <div className="px-5 pb-5 border-b border-cloud h-search-bar">
          <Input
            icon="search"
            placeholder="Search token name, symbol or address"
            value={searchTokensQuery.search}
            onChange={(e) => {
              searchTokensQuery.setSearch(e.target.value);
              if (e.target.value) {
                dispatch(updateCategory("all"));
              }
            }}
            setValue={searchTokensQuery.setSearch}
            withClearBtn
            isFetching={searchTokensQuery.isSearching}
          />

          <ToggleGroup.Root
            type="single"
            value={category}
            onValueChange={(value) => {
              if (value) {
                dispatch(updateCategory(value));
                searchTokensQuery.setSearch("");
              }
            }}
            className="flex items-center gap-2.5 mt-5"
          >
            <ToggleGroupItem value="trending" icon="trend-illustration">
              Trending
            </ToggleGroupItem>
            <ToggleGroupItem value="all" icon="coin-illustration">
              All
            </ToggleGroupItem>
            <ToggleGroupItem value="favorites" icon="heart-illustration">
              Favorites
            </ToggleGroupItem>
          </ToggleGroup.Root>
        </div>

        {showNotifications ? (
          <div
            className="border-b border-cloud transition-all"
            style={{
              height: notificationsHeight,
            }}
          >
            <Notifications
              notifications={notifications}
              dismissNotification={dismissNotification}
            />
          </div>
        ) : null}
      </div>

      <VerifyTokenDrawer
        isOpen={selectedUnverifiedToken !== undefined}
        token={selectedUnverifiedToken}
        onSelectToken={selectToken}
        onClose={() => {
          setSelectedUnverifiedToken(undefined);
        }}
      />
      <CustomQuickBuyDrawer
        token={quickBuyDrawerToken}
        onClose={() => {
          setQuickBuyDrawerToken(undefined);
        }}
      />
      {category === "trending" ? (
        <TrendingList
          search={searchTokensQuery.search}
          onSelectToken={onSelectTokenInList}
          scrollRef={trendingScrollRef}
          extraHeight={notificationsHeight}
          onQuickBuyLongPress={(token) => {
            setQuickBuyDrawerToken(token);
          }}
        />
      ) : category === "all" ? (
        <AllList
          searchTokenQueryResult={searchTokensQuery}
          onSelectToken={onSelectTokenInList}
          scrollRef={allScrollRef}
          extraHeight={notificationsHeight}
          onQuickBuyLongPress={(token) => {
            setQuickBuyDrawerToken(token);
          }}
        />
      ) : category === "favorites" ? (
        <FavoriteList
          search={searchTokensQuery.search}
          onSelectToken={onSelectTokenInList}
          scrollRef={favoriteScrollRef}
          extraHeight={notificationsHeight}
          onQuickBuyLongPress={(token) => {
            setQuickBuyDrawerToken(token);
          }}
        />
      ) : null}
    </>
  );
}
