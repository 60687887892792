import { cn } from "@/utils";

interface ButtonRowProps extends React.PropsWithChildren {
  children: React.ReactNode;
  hideOnDesktop?: boolean;
  border?: boolean;
  noFixedHeight?: boolean;
  vertical?: boolean;
  noGap?: boolean;
  noTabs?: boolean;
}

export function ButtonRow({
  children,
  hideOnDesktop,
  noFixedHeight,
  vertical,
  noGap,
  noTabs,
  border,
}: ButtonRowProps) {
  return (
    <div
      className={cn(
        "p-5 bg-gray-600 left-0 right-0 fixed bottom-[var(--h-tab-bar)] flex gap-5",
        {
          "lg:hidden": hideOnDesktop,
          "h-button-row": !noFixedHeight && !vertical,
          "flex-col": vertical,
          "gap-0": noGap,
          "bottom-[env(safe-area-inset-bottom)]": noTabs,
          "border-t border-cloud": border,
        },
      )}
    >
      {children}
    </div>
  );
}
