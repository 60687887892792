import { BaseIconProps } from "../icon";

export function CircleIcon(props: BaseIconProps) {
  return (
    <svg
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.68333 1.66675C5.025 1.66675 1.25 5.44175 1.25 10.1001C1.25 14.7584 5.025 18.5334 9.68333 18.5334C14.3417 18.5334 18.1167 14.7584 18.1167 10.1001C18.1167 5.44175 14.35 1.66675 9.68333 1.66675ZM9.68333 16.6584C6.06667 16.6584 3.13333 13.7251 3.13333 10.1084C3.13333 6.49175 6.06667 3.55841 9.68333 3.55841C13.3 3.55841 16.2333 6.49175 16.2333 10.1084C16.2333 13.7251 13.3 16.6584 9.68333 16.6584Z"
        fill="currentColor"
      />
    </svg>
  );
}
