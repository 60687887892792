import * as Tabs from "@radix-ui/react-tabs";
import { useTokenOverviewQuery } from "@/api";
import { useTypedSelector } from "@/store";
import {
  cn,
  formatAbsolutePercent,
  formatNumber,
  formatUsdCompact,
} from "@/utils";
import { skipToken } from "@reduxjs/toolkit/query";
import { Icon } from "@/components";

interface TimeStatProps {
  label: string;
  value?: string;
  startLabel?: string;
  startValue?: number;
  endLabel?: string;
  endValue?: number;
  formatValue: (value: number) => string;
  isLoading?: boolean;
  isFetching?: boolean;
}

function TimeStat({
  label,
  value,
  startLabel,
  startValue,
  endLabel,
  endValue,
  formatValue,
  isLoading,
  isFetching,
}: TimeStatProps) {
  const total =
    startValue !== undefined && endValue !== undefined
      ? startValue + endValue
      : undefined;
  const startWidth =
    startValue !== undefined && total !== undefined
      ? Math.round((startValue / total) * 100)
      : undefined;
  return (
    <div className="grid grid-cols-5 py-2.5 first:pt-3.5 last:pb-3.5">
      <div className="col-span-2">
        <p className="text-gray-200 mb-px text-sm leading-tight">{label}</p>
        {isLoading ? (
          <div className="h-5 w-1/3 loading-skeleton" />
        ) : (
          <div className={cn("transition-opacity", isFetching && "opacity-40")}>
            <p className="text-sm font-bold">{value ?? "–"}</p>
          </div>
        )}
      </div>
      <div className="col-span-3">
        <div className="flex">
          <div className="flex-1">
            <p className="text-gray-200 mb-px text-sm leading-tight">
              {startLabel}
            </p>
            {isLoading ? (
              <div className="h-5 w-1/2 mb-px loading-skeleton" />
            ) : (
              <div
                className={cn("transition-opacity", isFetching && "opacity-40")}
              >
                <p className="text-sm">{formatValue(startValue ?? 0)}</p>
              </div>
            )}
          </div>
          <div className="flex-1 text-right">
            <p className="text-gray-200 mb-px text-sm leading-tight">
              {endLabel}
            </p>
            {isLoading ? (
              <div className="h-5 w-1/2 mb-px loading-skeleton ml-auto" />
            ) : (
              <div
                className={cn("transition-opacity", isFetching && "opacity-40")}
              >
                <p className="text-sm">{formatValue(endValue ?? 0)}</p>
              </div>
            )}
          </div>
        </div>
        <div
          className={cn(
            "w-full h-[3px] flex mt-1 transition-opacity",
            startWidth && "gap-0.5",
            isFetching && "opacity-40",
          )}
        >
          <div
            className="bg-positive h-full w-[var(--start-width)]"
            style={
              startWidth
                ? ({
                    "--start-width": `${startWidth}%`,
                  } as React.CSSProperties)
                : undefined
            }
          />
          <div
            className={cn(
              "h-full flex-1",
              !startValue && !endValue ? "bg-cloud" : "bg-negative",
            )}
          />
        </div>
      </div>
    </div>
  );
}

const formatUsdWithPrecision = (value: number | null) => {
  if (!value) {
    return formatUsdCompact(0);
  }

  return Math.round(value) < 1
    ? `<${formatUsdCompact(1)}`
    : formatUsdCompact(value);
};

const timeOptions = [
  { label: "30m", value: "30m", title: "30 minutes" },
  { label: "2h", value: "2h", title: "2 hours" },
  { label: "4h", value: "4h", title: "4 hours" },
  { label: "24h", value: "24h", title: "24 hours" },
] as const;

interface TokenTimeStatsProps {
  tokenAddress: string | undefined | null;
}

export const TokenTimeStats = ({ tokenAddress }: TokenTimeStatsProps) => {
  const { useWrappedSol } = useTypedSelector((state) => state.profile);
  const overviewQuery = useTokenOverviewQuery(
    tokenAddress
      ? { address: tokenAddress, solType: useWrappedSol ? "wsol" : "sol" }
      : skipToken,
  );

  const timeBasedOverviewData = {
    "30m": {
      priceChangePercent: overviewQuery.data?.priceChange30mPercent,
      vUSD: overviewQuery.data?.v30mUSD,
      trade: overviewQuery.data?.trade30m,
      vBuyUSD: overviewQuery.data?.vBuy30mUSD,
      vSellUSD: overviewQuery.data?.vSell30mUSD,
      buy: overviewQuery.data?.buy30m,
      sell: overviewQuery.data?.sell30m,
    },
    "2h": {
      priceChangePercent: overviewQuery.data?.priceChange2hPercent,
      vUSD: overviewQuery.data?.v2hUSD,
      trade: overviewQuery.data?.trade2h,
      vBuyUSD: overviewQuery.data?.vBuy2hUSD,
      vSellUSD: overviewQuery.data?.vSell2hUSD,
      buy: overviewQuery.data?.buy2h,
      sell: overviewQuery.data?.sell2h,
    },
    "4h": {
      priceChangePercent: overviewQuery.data?.priceChange4hPercent,
      vUSD: overviewQuery.data?.v4hUSD,
      trade: overviewQuery.data?.trade4h,
      vBuyUSD: overviewQuery.data?.vBuy4hUSD,
      vSellUSD: overviewQuery.data?.vSell4hUSD,
      buy: overviewQuery.data?.buy4h,
      sell: overviewQuery.data?.sell4h,
    },
    "24h": {
      priceChangePercent: overviewQuery.data?.priceChange24hPercent,
      vUSD: overviewQuery.data?.v24hUSD,
      trade: overviewQuery.data?.trade24h,
      vBuyUSD: overviewQuery.data?.vBuy24hUSD,
      vSellUSD: overviewQuery.data?.vSell24hUSD,
      buy: overviewQuery.data?.buy24h,
      sell: overviewQuery.data?.sell24h,
    },
  };

  return (
    <div className="p-5">
      <Tabs.Root defaultValue={timeOptions[0].value}>
        <Tabs.List className="flex gap-1">
          {timeOptions.map((option) => {
            const priceChangePercent =
              timeBasedOverviewData[option.value].priceChangePercent;
            return (
              <Tabs.Trigger
                value={option.value}
                aria-label={option.title}
                key={option.value}
                className="flex-1 aria-selected:bg-dark text-left pb-1 group"
              >
                <div className="px-3.5 py-2.5 bg-box group-aria-selected:bg-transparent transition-colors">
                  <p className="text-xs text-gray-200">{option.label}</p>
                  <p
                    className={cn(
                      "font-bold text-xs leading-light flex items-center relative",
                      {
                        "text-positive":
                          !priceChangePercent || priceChangePercent >= 0,
                        "text-negative":
                          priceChangePercent && priceChangePercent < 0,
                      },
                    )}
                  >
                    {typeof priceChangePercent === "number" &&
                    !isNaN(priceChangePercent) ? (
                      <Icon
                        name="arrow"
                        className={cn(
                          "w-2 h-2 mr-0.5",
                          priceChangePercent < 0 && "rotate-180",
                        )}
                        srText={
                          priceChangePercent < 0 ? "Decrease of" : "Increase of"
                        }
                      />
                    ) : null}
                    {typeof priceChangePercent === "number" &&
                    !isNaN(priceChangePercent)
                      ? formatAbsolutePercent(priceChangePercent)
                      : "–"}
                  </p>
                </div>
              </Tabs.Trigger>
            );
          })}
        </Tabs.List>

        {timeOptions.map((option) => {
          const tabData = timeBasedOverviewData[option.value];
          return (
            <Tabs.Content
              key={option.value}
              value={option.value}
              className="bg-dark px-3.5"
            >
              <TimeStat
                label="Volume"
                value={
                  typeof tabData.vUSD === "number" && !isNaN(tabData.vUSD)
                    ? formatUsdWithPrecision(tabData.vUSD)
                    : undefined
                }
                startLabel="Buy Volume"
                startValue={
                  typeof tabData.vBuyUSD === "number" && !isNaN(tabData.vBuyUSD)
                    ? tabData.vBuyUSD
                    : undefined
                }
                endLabel="Sell Volume"
                endValue={
                  typeof tabData.vSellUSD === "number" &&
                  !isNaN(tabData.vSellUSD)
                    ? tabData.vSellUSD
                    : undefined
                }
                formatValue={formatUsdWithPrecision}
                isLoading={overviewQuery.isLoading}
                isFetching={overviewQuery.isFetching}
              />
              <TimeStat
                label="# of Trades"
                value={
                  typeof tabData.trade === "number" && !isNaN(tabData.trade)
                    ? formatNumber(tabData.trade)
                    : undefined
                }
                startLabel="Buys"
                startValue={
                  typeof tabData.buy === "number" && !isNaN(tabData.buy)
                    ? tabData.buy
                    : undefined
                }
                endLabel="Sells"
                endValue={
                  typeof tabData.sell === "number" && !isNaN(tabData.sell)
                    ? tabData.sell
                    : undefined
                }
                formatValue={formatNumber}
                isLoading={overviewQuery.isLoading}
                isFetching={overviewQuery.isFetching}
              />
            </Tabs.Content>
          );
        })}
      </Tabs.Root>
    </div>
  );
};
