import {
  NATIVE_SOL_ACCOUNT_RENT,
  SOL_DECIMALS,
  SOL_TRANSACTION_FEE,
  formatTokenNumber,
  normalizeScaled,
  parseAsScaled,
} from "@/utils";
import { PublicKey } from "@solana/web3.js";
import { TokenBalanceEntry } from "../services/user-api";
import {
  validateFunds,
  validateNativeTransactionRent,
  validateSolBalance,
  validateSplTransactionRent,
} from "./balance";

export function validateAmount(
  amountStr: string | undefined,
  tokenQuantity: string | undefined,
  priorityFee: bigint,
  token: TokenBalanceEntry | null,
  solBalance: bigint,
  isNativeSolWithdrawal: boolean,
  isUsd: boolean,
  recipientAccountExists: boolean,
  receiveBalance: bigint,
) {
  if (!amountStr) {
    return {
      kind: "no_amount",
      valid: false,
      message: "Enter amount to proceed",
    } as const;
  }

  const regex = isUsd
    ? /^\d*\.?\d{0,2}$/g
    : new RegExp(`^\\d*\\.?\\d{0,${token?.decimals ?? 2}}$`, "g");
  if (!amountStr.match(regex)) {
    return {
      valid: false,
      kind: "invalid_amount",
      message: "Invalid amount",
    } as const;
  }

  if (!token) {
    return {
      valid: false,
      kind: "no_token",
      message: "Select a token",
    } as const;
  }

  const tokenQuantityScaled =
    parseAsScaled(tokenQuantity ?? "0", token.decimals) ?? 0n;
  const totalBalance = BigInt(token.quantity);

  if (tokenQuantityScaled === 0n) {
    return {
      valid: false,
      kind: "zero_amount",
      message: "Enter an amount",
    } as const;
  }

  const fundsValidation = validateFunds(
    tokenQuantityScaled,
    totalBalance,
    priorityFee,
    isNativeSolWithdrawal,
    { maintainSwapMinimum: false },
  );
  if (!fundsValidation.valid && !isNativeSolWithdrawal) {
    if (fundsValidation.kind === "insufficient_funds") {
      const maxAmount = normalizeScaled(totalBalance, token.decimals);
      return {
        valid: false,
        kind: "max_amount",
        message: `Max amount ${formatTokenNumber(maxAmount, token.decimals, { decimalsMode: "fixed" })}`,
      } as const;
    }
  }

  const solValidation = validateSolBalance(solBalance, priorityFee, {
    maintainSwapMinimum: false,
  });
  if (!solValidation.valid) {
    switch (solValidation.kind) {
      case "zero_sol_balance": {
        const requiredSol = normalizeScaled(
          priorityFee + SOL_TRANSACTION_FEE,
          SOL_DECIMALS,
        );
        return {
          valid: false,
          kind: "insufficient_sol",
          message: `Deposit ${requiredSol} SOL to proceed`,
        } as const;
      }
      case "insufficient_sol": {
        const requiredSol = normalizeScaled(
          solValidation.requiredSol,
          SOL_DECIMALS,
        );
        return {
          valid: false,
          kind: "insufficient_sol",
          message: `Deposit ${requiredSol} SOL to proceed`,
        } as const;
      }
    }
  }

  if (isNativeSolWithdrawal) {
    const rentValidation = validateNativeTransactionRent({
      sendAmount: tokenQuantityScaled,
      sendBalance: totalBalance,
      priorityFee: BigInt(priorityFee),
      solBalance: BigInt(solBalance),
      receiveBalance,
    });

    if (!rentValidation.valid) {
      switch (rentValidation.kind) {
        case "insufficient_rent_for_destination":
          return {
            valid: false,
            kind: "insufficient_rent_for_destination",
            message: `Must send at least ${normalizeScaled(NATIVE_SOL_ACCOUNT_RENT, SOL_DECIMALS)} SOL to recipient`,
          } as const;
        case "insufficient_rent_for_source": {
          const maxAmountPartial = normalizeScaled(
            totalBalance -
              priorityFee -
              SOL_TRANSACTION_FEE -
              NATIVE_SOL_ACCOUNT_RENT,
            SOL_DECIMALS,
          );

          const maxAmountFull = normalizeScaled(
            totalBalance - priorityFee - SOL_TRANSACTION_FEE,
            SOL_DECIMALS,
          );

          return {
            valid: false,
            kind: "insufficient_rent_for_source",
            message: `Max amount ${formatTokenNumber(maxAmountPartial, token.decimals, { decimalsMode: "fixed" })} SOL or ${formatTokenNumber(maxAmountFull, token.decimals, { decimalsMode: "fixed" })} SOL`,
          } as const;
        }
      }
    }
  } else {
    const rentValidation = validateSplTransactionRent({
      sendAmount: tokenQuantityScaled,
      sendBalance: totalBalance,
      priorityFee: BigInt(priorityFee),
      recipientAccountExists,
      solBalance: BigInt(solBalance),
    });
    if (!rentValidation.valid) {
      const requiredSol = normalizeScaled(
        rentValidation.requiredSol,
        SOL_DECIMALS,
      );
      const message = `Deposit ${formatTokenNumber(requiredSol, SOL_DECIMALS, { decimalsMode: "fixed" })} SOL to proceed`;
      return {
        ...rentValidation,
        message,
      } as const;
    }
  }

  // If all validations pass, return valid
  return { valid: true, kind: "none", message: "" } as const;
}

export function validateAddress(
  address: string | undefined,
  ownAddress: string | undefined,
) {
  if (!address) {
    return {
      valid: false,
      kind: "no_address",
      message: "Enter address to proceed",
    } as const;
  }

  if (address === ownAddress) {
    return {
      valid: false,
      kind: "own_address",
      message: "You cannot withdraw to your own address",
    } as const;
  }

  try {
    new PublicKey(address);
    return { valid: true, kind: "none", message: "" } as const;
  } catch {
    return {
      valid: false,
      kind: "invalid_address",
      message: "You entered an invalid Solana wallet address",
    } as const;
  }
}

export function validateToken(token: TokenBalanceEntry | undefined) {
  return token
    ? ({ valid: true, kind: "none", message: "" } as const)
    : ({
        valid: false,
        kind: "no_token",
        message: "Select token to proceed",
      } as const);
}
