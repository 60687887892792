import { BaseIconProps } from "../icon";

export function WalletIcon(props: BaseIconProps) {
  return (
    <svg
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.775 1.5C0.62375 1.5 0.5 1.62656 0.5 1.78125V10.2188C0.5 10.3734 0.62375 10.5 0.775 10.5H11.225C11.3763 10.5 11.5 10.3734 11.5 10.2188V1.78125C11.5 1.62656 11.3763 1.5 11.225 1.5H0.775ZM10.675 3.1875H1.325V2.34375H10.675V3.1875ZM1.325 4.03125H10.675V4.875H7.6995C7.2705 4.875 6.91025 5.17313 6.781 5.56687C6.671 5.89875 6.36575 6.14062 5.99725 6.14062C5.62875 6.14062 5.3235 5.89875 5.21625 5.56687C5.087 5.17594 4.72675 4.875 4.29775 4.875H1.325M4.3005 5.71875C4.35825 5.71875 4.4105 5.75531 4.43525 5.83406C4.65525 6.50344 5.27675 6.98438 6 6.98438C6.72325 6.98438 7.34475 6.50344 7.56475 5.83406C7.59225 5.7525 7.64175 5.71875 7.6995 5.71875H10.675V9.65625H1.325V5.71875H4.3005Z"
        fill="currentColor"
      />
    </svg>
  );
}
