import { Web3AuthClient } from "@/app/web3auth";
import { useEffect, useState } from "react";

export function useWeb3AuthClient() {
  const [state, setState] = useState<{
    error: unknown;
    instance: Web3AuthClient | undefined;
  }>(Web3AuthClient.getInstance());
  useEffect(() => {
    const unsub = Web3AuthClient.registerCallback((error, instance) => {
      setState({ error, instance });
    });

    return () => {
      unsub();
    };
  }, []);

  return [state.instance, state.error] as const;
}
