import { BaseIconProps } from "../icon";

export function PercentBoxIcon(props: BaseIconProps) {
  return (
    <svg
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.9925 8.90639C10.7041 8.90639 9.65539 9.95508 9.65539 11.2435V11.9925C9.65539 13.2809 10.7041 14.3296 11.9925 14.3296C13.2809 14.3296 14.3295 13.2809 14.3295 11.9925V11.2435C14.3295 9.95508 13.2809 8.90639 11.9925 8.90639ZM11.3408 11.2435C11.3408 10.8689 11.6254 10.5843 12 10.5843C12.3745 10.5843 12.6591 10.8689 12.6591 11.2435V11.9925C12.6591 12.3671 12.3745 12.6517 12 12.6517C11.6254 12.6517 11.3408 12.3671 11.3408 11.9925V11.2435Z"
        fill="currentColor"
      />
      <path
        d="M5.99996 9.09365C7.28835 9.09365 8.33704 8.04496 8.33704 6.75658V6.00751C8.33704 4.71912 7.28835 3.67043 5.99996 3.67043C4.71157 3.67043 3.66288 4.71912 3.66288 6.00751V6.75658C3.66288 8.04496 4.71157 9.09365 5.99996 9.09365ZM6.65914 6.74908C6.65914 7.12362 6.37449 7.40826 5.99996 7.40826C5.62543 7.40826 5.34078 7.12362 5.34078 6.74908V6.00002C5.34078 5.62549 5.62543 5.34084 5.99996 5.34084C6.37449 5.34084 6.65914 5.62549 6.65914 6.00002V6.74908Z"
        fill="currentColor"
      />
      <path
        d="M0.666626 0.666687V17.3334H17.3333V0.666687H0.666626ZM2.35202 15.648V2.35208H15.6479V15.648H2.35202Z"
        fill="currentColor"
      />
      <path
        d="M13.6704 3.66294H11.6404L4.42693 14.1873L4.32955 14.3371H6.35951L13.573 3.81275L13.6704 3.66294Z"
        fill="currentColor"
      />
    </svg>
  );
}
