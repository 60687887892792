import { BaseIconProps } from "../icon";

export function DepositIcon(props: BaseIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <rect x="0.5" y="0.5" width="19" height="19" rx="1.5" stroke="white" />
      <g clipPath="url(#clip0_7989_37817)">
        <path
          d="M3.5835 3.58325V8.24992H4.75016V9.41659H5.91683V8.24992H8.25016V3.58325H3.5835ZM8.25016 8.24992V9.41659H9.41683V10.5833H7.0835V11.7499H3.5835V16.4166H8.25016V11.7499H11.7502V10.5833H10.5835V9.41659H12.9168V8.24992H14.0835V9.41659H15.2502V8.24992H16.4168V3.58325H11.7502V8.24992H8.25016ZM15.2502 9.41659V10.5833H16.4168V9.41659H15.2502ZM15.2502 10.5833H14.0835V11.7499H15.2502V10.5833ZM15.2502 11.7499V12.9166H16.4168V11.7499H15.2502ZM15.2502 12.9166H14.0835V11.7499H12.9168V12.9166H10.0002V16.4166H11.1668V14.0833H13.5002V15.2499H14.6668V14.0833H15.2502V12.9166ZM13.5002 15.2499H12.3335V16.4166H13.5002V15.2499ZM14.0835 10.5833V9.41659H12.9168V10.5833H14.0835ZM7.0835 10.5833V9.41659H5.91683V10.5833H7.0835ZM4.75016 9.41659H3.5835V10.5833H4.75016V9.41659ZM9.41683 3.58325V5.91659H8.8335V7.08325H9.41683V7.66659H10.5835V5.91659H11.1668V4.74992H10.5835V3.58325H9.41683ZM4.75016 4.74992H7.0835V7.08325H4.75016V4.74992ZM12.9168 4.74992H15.2502V7.08325H12.9168V4.74992ZM5.3335 5.33325V6.49992H6.50016V5.33325H5.3335ZM13.5002 5.33325V6.49992H14.6668V5.33325H13.5002ZM4.75016 12.9166H7.0835V15.2499H4.75016V12.9166ZM5.3335 13.4999V14.6666H6.50016V13.4999H5.3335ZM15.2502 15.2499V16.4166H16.4168V15.2499H15.2502Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_7989_37817">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(3 3)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
