import { useAccountBalance } from "@/hooks/useAccountBalance";
import { useTypedSelector } from "@/store";
import { SOL_ADDRESS } from "@/utils";
import { useCallback, useState } from "react";

const initialOverdraft = {
  amount: 0n,
  overdrafted: false,
};

export function useTotalPercentagePresets(
  tokenMintAddress: string | undefined,
  solTypeOverride?: "sol" | "wsol",
) {
  const { useWrappedSol, priorityFee } = useTypedSelector(
    (state) => state.profile,
  );
  const balance = useAccountBalance(tokenMintAddress, solTypeOverride).data
    ?.rawBalance;
  const [selected, setSelected] = useState<string | null>(null);
  const [overdraft, setOverdraft] = useState(() => initialOverdraft);
  const maxAmount = BigInt(balance ?? "0");
  const isNativeSol: boolean = !solTypeOverride
    ? tokenMintAddress === SOL_ADDRESS && !useWrappedSol
    : solTypeOverride === "sol";
  const disabled = !tokenMintAddress;
  const reset = useCallback(() => {
    setSelected(null);
    setOverdraft(initialOverdraft);
  }, []);

  return {
    selected,
    setSelected,
    maxAmount,
    isNativeSol,
    priorityFee: BigInt(priorityFee),
    disabled,
    overdraft,
    setOverdraft,
    reset,
  };
}
