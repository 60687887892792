import { Drawer } from "vaul";
import type { DialogDescriptionProps } from "@radix-ui/react-dialog";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { Dialog } from "@/components";

export function Description(props: DialogDescriptionProps) {
  const isDesktopAndUp = useMediaQuery("(min-width: 1024px)");
  return isDesktopAndUp ? (
    <Dialog.Description {...props} />
  ) : (
    <Drawer.Description {...props} />
  );
}
