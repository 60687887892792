import {
  api,
  isQuoteError,
  isSignTransactionError,
  useQuoteQuery,
} from "@/api";
import {
  Button,
  ButtonRow,
  TransactionErrorMessage,
  TransactionErrorMessageProps,
  Icon,
  Page,
} from "@/components";
import { useDynamicWallet, useFocus } from "@/hooks";
import { useSubmitQuote } from "@/hooks/useSubmitQuote";
import {
  initialState as navInitialState,
  updateTradeLink,
} from "@/routes/-nav-reducer.ts";
import { resetTradeFlow } from "@/routes/trade/-reducer";
import { useValidation } from "@/routes/trade/-swap/validation";
import { TradeDetails } from "@/routes/trade/confirm/-trade-details.tsx";
import { store, useAppDispatch, useTypedSelector } from "@/store";
import { QUOTE_TIMEOUT, getPlatformFeeBps } from "@/utils";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import {
  Navigate,
  createFileRoute,
  redirect,
  useNavigate,
} from "@tanstack/react-router";
import { useCallback, useEffect, useState } from "react";

export interface SearchParams {
  sendToken?: string;
  receiveToken?: string;
  tokenValue?: string;
  redirect?: string;
}

export const Route = createFileRoute("/trade/confirm/")({
  component: TradeConfirm,
  loaderDeps: () => store,
  loader: ({ deps: store }) => {
    const sendToken = store.getState().tradeSwap.sendToken;
    const receiveToken = store.getState().tradeSwap.receiveToken;
    const tokenValue = store.getState().tradeSwap.amount.scaledValue;
    if (!sendToken || !receiveToken || !tokenValue) {
      throw redirect({ to: "/trade" });
    }

    return {
      sendToken,
      receiveToken,
      tokenValue,
    };
  },
});

function shouldRefetchQuote(
  error: FetchBaseQueryError | SerializedError | undefined,
) {
  return !(
    isQuoteError(error) &&
    (error.kind === "failed_to_compute_route" || error.status === 429)
  );
}

function TradeConfirm() {
  const { sendToken, receiveToken, tokenValue } = Route.useLoaderData();
  const profileSettings = useTypedSelector((state) => state.profile);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const walletAddress = useDynamicWallet()?.address;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { submitQuote, swapMutation, signTxMutation, sendTransactionMutation } =
    useSubmitQuote();

  const isCancelTransaction =
    isSignTransactionError(signTxMutation.error) &&
    signTxMutation.error.kind === "cancel_signature";

  const isError =
    swapMutation.isError ||
    (!isCancelTransaction && signTxMutation.isError) ||
    sendTransactionMutation.isError;

  const { slippage } = profileSettings;

  const quoteQuery = useQuoteQuery(
    {
      amount: tokenValue,
      inputMint: sendToken.address,
      outputMint: receiveToken.address,
      slippageBps: slippage,
      platformFeeBps: getPlatformFeeBps(
        sendToken.address,
        receiveToken.address,
      ),
    },
    {
      skip: isSubmitting || sendTransactionMutation.isSuccess || isError,
      pollingInterval: QUOTE_TIMEOUT,
      skipPollingIfUnfocused: true,
    },
  );

  // Schedule on mount to ensure we always fetch a quote
  useEffect(() => {
    const msSinceLastQuote = Date.now() - (quoteQuery.fulfilledTimeStamp ?? 0);

    // NOTE always schedule it because we cannot refetch if component just mounted
    const id = setTimeout(
      () => {
        if (shouldRefetchQuote(quoteQuery.error)) {
          void quoteQuery.refetch();
        }
      },
      Math.max(0, QUOTE_TIMEOUT - msSinceLastQuote),
    );

    return () => clearTimeout(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useFocus(
    useCallback(() => {
      const msSinceLastQuote =
        Date.now() - (quoteQuery.fulfilledTimeStamp ?? 0);

      if (
        msSinceLastQuote >= QUOTE_TIMEOUT &&
        shouldRefetchQuote(quoteQuery.error)
      ) {
        void quoteQuery.refetch();
      }
    }, [quoteQuery]),
  );

  const viewsConfig = {
    loading: {
      title: "Confirming order...",
      backButton: undefined,
    },
    error: {
      title: "Order failed",
      backButton: undefined,
    },
    success: {
      title: "Order completed!",
      backButton: undefined,
    },
    unknown: {
      title: "Order status unknown",
      backButton: undefined,
    },
    default: {
      title: "Confirm order",
      backButton: {
        to: "/trade",
        search: {
          sendToken: sendToken.address,
          receiveToken: receiveToken.address,
          step: "trade",
        },
      },
    },
  };
  const config = viewsConfig.default;

  const validation = useValidation();

  const submit = async () => {
    if (!validation.valid) {
      void navigate({
        to: "/trade",
        search: {
          sendToken: sendToken.address,
          receiveToken: receiveToken.address,
          step: "trade",
        },
      });
      return;
    }
    if (isSubmitting) return;

    try {
      setIsSubmitting(true);

      await submitQuote({
        sendToken,
        receiveToken,
        quote: quoteQuery.data,
        quoteStartedTimeStamp: quoteQuery.startedTimeStamp,
        quoteFulfilledTimeStamp: quoteQuery.fulfilledTimeStamp,
        priorityFee: profileSettings.priorityFee,
        onBeforeSendTransaction: () => {
          dispatch(resetTradeFlow());
          dispatch(updateTradeLink(navInitialState.currentTradeLink));
        },
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  let warningProps: TransactionErrorMessageProps | undefined;
  const showPriceImpactWarning =
    quoteQuery.data?.priceImpactPct &&
    Number(quoteQuery.data.priceImpactPct) > 0.03;

  if (showPriceImpactWarning) {
    warningProps = {
      title: "PRICE IMPACT ABOVE 3%",
      message:
        "Your current trade is expected to result in a high price impact. A high price impact is generally a result of placing a large trade relative to existing available liquidity.",
      color: "warning",
    };
  }

  if (sendTransactionMutation.isLoading) {
    return <Navigate to="/trade" />;
  }

  return (
    <Page
      flex
      fullScreenHeight
      hideTabs
      backButton={config.backButton}
      title={config.title}
    >
      <div className="w-full flex flex-col p-5 pb-button-row">
        <div>
          {quoteQuery.isLoading ? (
            <TradeDetails.LoadingSkeleton />
          ) : quoteQuery.isError ? (
            <div className="flex flex-col items-center mt-5 gap-8">
              <div className="relative flex h-[50px] justify-center items-center">
                <div className="w-[50px] h-[50px] rounded-full border-2 border-negative absolute" />
                <Icon name="warning" className="w-6 h-6 text-primary" />
              </div>

              {isQuoteError(quoteQuery.error) &&
              quoteQuery.error.kind === "failed_to_compute_route" ? (
                <TransactionErrorMessage
                  title="No route found"
                  message={`
                        Failed to find a route for the specified 
                        token pair and input amount. 
                        Try again with a different token pair or amount.
                      `}
                  color="negative"
                />
              ) : (
                <TransactionErrorMessage
                  title="Failed to fetch quote"
                  message="Something went wrong, please try again"
                  color="negative"
                />
              )}
            </div>
          ) : quoteQuery.data ? (
            <>
              <TradeDetails
                type="quote"
                isFetching={quoteQuery.isFetching}
                isError={quoteQuery.isError}
                inAmount={quoteQuery.data.inAmount}
                outAmount={quoteQuery.data.outAmount}
                platformFeeBps={quoteQuery.data.platformFee?.feeBps ?? 0}
                inTokenMint={quoteQuery.data.inputMint}
                outTokenMint={quoteQuery.data.outputMint}
                priceImpactPct={Number(quoteQuery.data.priceImpactPct)}
              />
              {warningProps && (
                <div className="flex justify-center mt-5">
                  <TransactionErrorMessage {...warningProps} />
                </div>
              )}
            </>
          ) : null}
        </div>
        {swapMutation.isError && (
          <div className="mx-auto">
            <TransactionErrorMessage
              title="Failed to place order"
              message="Try again with a new quote."
              color="negative"
            />
          </div>
        )}

        <ButtonRow noTabs>
          {swapMutation.isError ? (
            <Button
              to="/trade"
              search={{
                sendToken: sendToken.address,
                receiveToken: receiveToken.address,
                step: "trade",
              }}
              className="w-full"
              variant="cta"
              onClick={() => {
                // This waits a bit to ensure query is not
                // subscribed to on this page anymore
                setTimeout(() => {
                  dispatch(api.util.invalidateTags([{ type: "Quote" }]));
                }, 100);
              }}
            >
              Retry with new quote
            </Button>
          ) : (
            <Button
              className="w-full"
              variant="cta"
              isLoading={isSubmitting}
              disabled={
                isSubmitting ||
                quoteQuery.isFetching ||
                !walletAddress ||
                quoteQuery.isError
              }
              onClick={() => {
                void submit();
              }}
            >
              {isSubmitting ? null : "Place order"}
            </Button>
          )}
        </ButtonRow>
      </div>
    </Page>
  );
}
