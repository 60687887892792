import { cn } from "@/utils";
import * as React from "react";

type TableProps = Omit<React.HTMLAttributes<HTMLTableElement>, "className">;

const Table: React.FC<TableProps> = (props) => (
  <div className="relative w-full overflow-auto">
    <table className="w-full caption-bottom text-sm" {...props} />
  </div>
);

type TableSectionProps = Omit<
  React.HTMLAttributes<HTMLTableSectionElement>,
  "className"
>;

const TableHeader: React.FC<TableSectionProps> = (props) => (
  <thead className="[&_tr]:border-b [&_tr]:border-cloud" {...props} />
);

const TableBody: React.FC<TableSectionProps> = (props) => (
  <tbody
    className="[&_tr:last-child]:border-0 [&_tr]:border-b [&_tr]:border-cloud"
    {...props}
  />
);

const TableFooter: React.FC<TableSectionProps> = (props) => (
  <tfoot
    className="border-t bg-slate-100/50 font-medium [&>tr]:last:border-b-0 dark:bg-slate-800/50"
    {...props}
  />
);

type TableRowProps = Omit<
  React.HTMLAttributes<HTMLTableRowElement>,
  "className"
> & {
  highlighted?: boolean;
};

const TableRow: React.FC<TableRowProps> = ({ highlighted, ...props }) => (
  <tr
    className={cn(
      "transition-colors hover:bg-slate-100/50 data-[state=selected]:bg-slate-100 dark:hover:bg-slate-800/50 dark:data-[state=selected]:bg-slate-800 border-b border-cloud",
      {
        "bg-[linear-gradient(180deg,_rgba(255,_122,_209,_0.13)_0%,_rgba(255,_122,_209,_0.03)_34%,_rgba(255,_122,_209,_0.03)_55%,_rgba(255,_122,_209,_0.13)_100%)] outline outline-accent border-x border-accent":
          highlighted,
      },
    )}
    {...props}
  />
);

type TableCellProps = Omit<
  React.TdHTMLAttributes<HTMLTableCellElement>,
  "className"
>;

const TableHead: React.FC<TableCellProps> = (props) => (
  <th
    className="h-12 px-5 text-left font-bold text-white lg:text-primary [&:has([role=checkbox])]:pr-0 "
    {...props}
  />
);

const TableCell: React.FC<TableCellProps> = (props) => (
  <td
    className="px-5 py-2 [&:has([role=checkbox])]:pr-0 max-lg:h-info-row text-left lg:p-3.5"
    {...props}
  />
);

type TableCaptionProps = Omit<
  React.HTMLAttributes<HTMLTableCaptionElement>,
  "className"
>;

const TableCaption: React.FC<TableCaptionProps> = (props) => (
  <caption className="mt-4 text-sm text-white" {...props} />
);

export {
  Table,
  TableHeader,
  TableBody,
  TableFooter,
  TableHead,
  TableRow,
  TableCell,
  TableCaption,
};
