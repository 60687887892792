import {
  Web3AuthClient,
  useWeb3authRequestOtpMutation,
  useWeb3authSubmitOtpMutation,
} from "@/app/web3auth";
import { Button, DigitInput, Icon, Page } from "@/components";
import { with404 } from "@/hocs/with404";
import { withDynamicProtected } from "@/hocs/withDynamicProtected";
import { withWeb3authRedirectOnLogin } from "@/hocs/withWeb3authRedirectOnLogin";
import { updateWeb3authOtp } from "@/routes/login-signup/-reducer";
import { useAppDispatch, useTypedSelector } from "@/store";
import { DISABLE_MIGRATION_FLOW, cn, getApiErrorMessage } from "@/utils";
import { useDynamicContext } from "@dynamic-labs/sdk-react-core";
import { compose } from "@reduxjs/toolkit";
import { RouteComponent, createFileRoute } from "@tanstack/react-router";
import { useEffect, useRef } from "react";

export const Route = createFileRoute("/migrate/login/")({
  component: DISABLE_MIGRATION_FLOW
    ? with404(Login)
    : compose<RouteComponent>(
        withWeb3authRedirectOnLogin({ to: "/migrate/password" }),
        withDynamicProtected,
      )(Login),
});

function Login() {
  const dispatch = useAppDispatch();
  const { web3authOtp } = useTypedSelector((state) => state.loginSignup);
  const [requestOtp, requestOtpMutation] = useWeb3authRequestOtpMutation();
  const [submitOtp, submitOtpMutation] = useWeb3authSubmitOtpMutation();
  const email = useDynamicContext().user?.email;

  const apiError = requestOtpMutation.error ?? submitOtpMutation.error;
  const language = "en"; // TODO add multi language support

  const ref = useRef(false);
  useEffect(() => {
    if (ref.current) return;
    ref.current = true;
    dispatch(updateWeb3authOtp(""));

    if (email) {
      void requestOtp({ email, language });
    }
  }, [requestOtp, email, language, dispatch]);

  return (
    <Page
      hideTabs
      fullScreenHeight
      backButton={{
        to: "/migrate/notice",
        "aria-label": "Back",
        search: (prev) => prev,
      }}
      title="Connect old account"
    >
      <div
        className={cn(
          "flex flex-col justify-center items-center gap-5 px-5 pb-3 relative",
          { "pt-10": apiError },
        )}
      >
        {apiError ? (
          <div className="w-full p-2 bg-negative/10 text-negative text-xs font-bold text-center absolute top-0">
            {getApiErrorMessage(apiError)}
          </div>
        ) : null}
        <div className="relative h-[89px] flex justify-center items-center">
          <div className="my-5 flex justify-center items-center relative">
            {!submitOtpMutation.isSuccess && !submitOtpMutation.isError ? (
              <>
                <Icon
                  name="circle-partial"
                  className="w-[50px] h-[50px] text-primary absolute animate-spin"
                />
                <Icon name="email" className="w-6 h-6 text-primary" />
              </>
            ) : null}
            {submitOtpMutation.isSuccess ? (
              <>
                <div className="w-[50px] h-[50px] rounded-full border-2 border-positive absolute" />
                <Icon name="check" className="w-6 h-6 text-positive" />
              </>
            ) : null}
            {submitOtpMutation.isError ? (
              <>
                <div className="w-[50px] h-[50px] rounded-full border-2 border-negative absolute" />
                <Icon name="warning" className="w-6 h-6 text-primary" />
              </>
            ) : null}
          </div>
        </div>
        <p className="text-gray-200 text-sm text-center">
          We’ve sent a verification code to
          <br />
          <span className="font-bold">{email}</span>
        </p>
        <DigitInput
          inputMode="numeric"
          isError={Boolean(apiError)}
          isSuccess={submitOtpMutation.isSuccess}
          disabled={!email || submitOtpMutation.isLoading}
          value={web3authOtp}
          onChange={async (value) => {
            submitOtpMutation.reset();
            dispatch(updateWeb3authOtp(value));

            if (value.length === 6 && email) {
              const response = await submitOtp({ email, otp: value });

              if (response.data) {
                void Web3AuthClient.init(
                  response.data.id_token,
                  response.data.refresh_token,
                  email,
                );
              }
            }
          }}
        />
        {!submitOtpMutation.isSuccess && (
          <div className="flex flex-col gap-1">
            <p className="text-gray-200 text-sm text-center">
              Did not receive your code yet?
            </p>
            <Button
              variant="flat"
              color="dark"
              className="h-6"
              disabled={
                !email ||
                requestOtpMutation.isLoading ||
                submitOtpMutation.isLoading
              }
              onClick={() => {
                if (email) {
                  void requestOtp({ email, language });
                }
              }}
            >
              Resend Code
            </Button>
          </div>
        )}
      </div>
    </Page>
  );
}
