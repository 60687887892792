import { useBackgroundTransactionStatus } from "@/app/background-transactions/use-background-transaction-status";
import type { IconProps } from "@/components";
import { Icon, Link, NotificationDot } from "@/components";
import { initialState } from "@/routes/-nav-reducer";
import { useTypedSelector } from "@/store";
import { useRouterState } from "@tanstack/react-router";

interface TabProps {
  to: string;
  baseTo: string;
  replace?: boolean;
  icon?: IconProps["name"];
  showNotification?: boolean;
  flashNotification?: boolean;
  onCurrentTabClick?: () => void;
}

const Tab = ({
  children,
  replace,
  to,
  baseTo,
  icon,
  showNotification,
  flashNotification,
  onCurrentTabClick,
}: React.PropsWithChildren<TabProps>) => {
  const { location } = useRouterState();
  const toWithoutParams = to.split("?")[0];
  const isCurrent = location.pathname.startsWith(toWithoutParams);
  return (
    <Link
      // Take users to root page of tab when clicking tab they are currently on
      to={isCurrent ? baseTo : to}
      replace={replace}
      className="text-gray-300 [&[aria-current='page']]:text-primary flex-1 flex flex-col items-center justify-center gap-y-1.5 uppercase font-bold text-xs"
      aria-current={isCurrent ? "page" : undefined}
      onClick={() => {
        if (isCurrent && onCurrentTabClick) {
          onCurrentTabClick();
        }
      }}
    >
      {icon ? (
        <Icon name={icon} className="relative">
          {showNotification && (
            <NotificationDot
              flashing={flashNotification}
              className="-top-1 -right-1"
            />
          )}
        </Icon>
      ) : (
        <span className="h-[22px]">?</span>
      )}
      {children}
    </Link>
  );
};

interface MobileNavProps {
  onCurrentTabClick?: () => void;
}

export const MobileNav = ({ onCurrentTabClick }: MobileNavProps) => {
  const {
    currentTradeLink,
    currentPortfolioLink,
    currentRewardsLink,
    currentOrdersLink,
  } = useTypedSelector((state) => state.nav);
  const backgroundTxStatus = useBackgroundTransactionStatus();
  return (
    <nav className="select-none fixed bottom-0 left-0 right-0 flex bg-gray-600 border-t border-cloud h-[var(--h-tab-bar)] lg:hidden pb-[env(safe-area-inset-bottom)] z-10">
      <Tab
        to={currentTradeLink}
        baseTo={initialState.currentTradeLink}
        replace
        icon="money-arrows"
        onCurrentTabClick={onCurrentTabClick}
      >
        Trade
      </Tab>
      <Tab
        to={currentPortfolioLink}
        baseTo={initialState.currentPortfolioLink}
        replace
        icon="pie-chart"
        onCurrentTabClick={onCurrentTabClick}
      >
        Portfolio
      </Tab>
      <Tab
        to={currentRewardsLink}
        baseTo={initialState.currentRewardsLink}
        replace
        icon="medal"
        onCurrentTabClick={onCurrentTabClick}
      >
        Rewards
      </Tab>
      <Tab
        to={currentOrdersLink}
        baseTo={initialState.currentOrdersLink}
        replace
        icon="document"
        showNotification={
          backgroundTxStatus.hasUnreadTransactions ||
          backgroundTxStatus.hasPending
        }
        flashNotification={backgroundTxStatus.hasPending}
        onCurrentTabClick={onCurrentTabClick}
      >
        Orders
      </Tab>
    </nav>
  );
};
