import { BaseIconProps } from "../icon";

export function GraphIcon(props: BaseIconProps) {
  return (
    <svg
      viewBox="0 0 20 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.833374 0.5V1.20833V14.6667H19.25V0.5H0.833374ZM2.25004 1.91667H17.8334V13.25H2.25004V1.91667ZM15 3.33333V11.8333H16.4167V3.33333H15ZM12.1667 4.75V11.8333H13.5834V4.75H12.1667ZM9.33337 6.16667V11.8333H10.75V6.16667H9.33337ZM6.50004 7.58333V11.8333H7.91671V7.58333H6.50004ZM3.66671 9V11.8333H5.08337V9H3.66671Z"
        fill="currentColor"
      />
    </svg>
  );
}
