import { BaseIconProps } from "../icon";

export function CoinIcon(props: BaseIconProps) {
  return (
    <svg
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.99998 1.33337C4.94304 1.33337 0.833313 5.4431 0.833313 10.5C0.833313 15.557 4.94304 19.6667 9.99998 19.6667C15.0569 19.6667 19.1666 15.557 19.1666 10.5C19.1666 5.4431 15.0569 1.33337 9.99998 1.33337ZM10.7639 2.90698C13.0173 3.12851 14.9653 4.32018 16.2028 6.05421L14.866 6.84101C13.8958 5.5424 12.4368 4.64101 10.7562 4.42712V2.89935L10.7639 2.90698ZM9.21317 2.90698H9.23609V4.43476C7.56317 4.64865 6.09651 5.53476 5.12637 6.8181L3.78956 6.05421C5.02706 4.32018 6.96734 3.12851 9.20553 2.90698H9.21317ZM3.03331 13.6243C2.60554 12.6695 2.36109 11.6153 2.36109 10.5C2.36109 9.38476 2.5979 8.3306 3.03331 7.37573L4.37012 8.13962C4.05692 8.87296 3.89651 9.65976 3.89651 10.5C3.89651 11.3403 4.06456 12.1348 4.37012 12.8605L3.03331 13.6243ZM9.23609 18.0855C6.98262 17.8639 5.0347 16.6723 3.7972 14.9382L5.13401 14.1514C6.10415 15.45 7.56317 16.3514 9.24373 16.5653V18.0931L9.23609 18.0855ZM5.41665 10.4924C5.41665 7.95629 7.46387 5.90907 9.99998 5.90907C12.5361 5.90907 14.5833 7.95629 14.5833 10.4924C14.5833 13.0285 12.5361 15.0757 9.99998 15.0757C7.46387 15.0757 5.41665 13.0285 5.41665 10.4924ZM10.7639 18.0855V16.5577C12.4368 16.3438 13.9035 15.4577 14.8736 14.1743L16.2104 14.9382C14.9653 16.6723 13.0173 17.8716 10.7715 18.0855H10.7639ZM15.6375 12.8605C15.9507 12.1271 16.1111 11.3403 16.1111 10.5C16.1111 9.65976 15.943 8.86532 15.6375 8.13962L16.9743 7.37573C17.4021 8.3306 17.6465 9.38476 17.6465 10.5C17.6465 11.6153 17.4021 12.6695 16.9743 13.6243L15.6375 12.8605Z"
        fill="currentColor"
      />
    </svg>
  );
}
