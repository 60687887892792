/* eslint-disable @typescript-eslint/no-unnecessary-condition */

import { Button, Drawer } from "@/components";
import ms from "ms";
import { useEffect, useState } from "react";
import { useRegisterSW } from "virtual:pwa-register/react";

const SW_CHECK_INTERVAL = ms(import.meta.env.VITE_SW_CHECK_INTERVAL);

console.log(`sw check interval: ${SW_CHECK_INTERVAL}`);

export function PromptReload() {
  // replaced dynamically
  const buildDate = "__DATE__";

  const [isReloading, setIsReloading] = useState(false);
  const [ignore, setIgnore] = useState(false);

  const {
    offlineReady: [_offlineReady],
    needRefresh: [needRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW(swUrl, r) {
      console.log(`Service Worker at: ${swUrl}`);
      r &&
        setInterval(() => {
          console.log("Checking for sw update");
          void r.update();
        }, SW_CHECK_INTERVAL);
    },
    onRegisterError(error) {
      console.log("SW registration error", error);
    },
  });

  useEffect(() => {
    const handleFocus = () => {
      setIgnore(false);
    };

    // Listen to visibilitychange and focus
    if (typeof window !== "undefined" && window.addEventListener) {
      window.addEventListener("visibilitychange", handleFocus, false);
      window.addEventListener("focus", handleFocus, false);
    }

    return () => {
      window.removeEventListener("visibilitychange", handleFocus);
      window.removeEventListener("focus", handleFocus);
    };
  }, []);

  const close = () => {
    setIgnore(true);
  };

  return (
    <Drawer.Root
      open={needRefresh && !ignore}
      onOpenChange={(open) => {
        if (!open) {
          close();
        }
      }}
    >
      <Drawer.Content title="Update Available">
        <p className="text-sm text-gray-200">
          New features are available. Update to optimize your trading
          experience. This will cause the app to reload.
        </p>
        <div className="flex flex-col gap-2 mt-8">
          <Button
            variant="cta"
            color="primary"
            onClick={() => {
              setIsReloading(true);
              void updateServiceWorker(true);
              // fallback in case the update doesn't trigger reload
              setTimeout(() => {
                window.location.reload();
              }, 3000);
            }}
          >
            {isReloading ? "Reloading..." : "Reload"}
          </Button>
          <Button variant="flat" color="dark" onClick={close}>
            Dismiss for now
          </Button>
        </div>
        <div className="hidden">{buildDate}</div>
      </Drawer.Content>
    </Drawer.Root>
  );
}
