import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

interface NavState {
  currentTradeLink: string;
  currentPortfolioLink: string;
  currentRewardsLink: string;
  currentOrdersLink: string;
}

export const initialState: NavState = {
  currentTradeLink: "/trade",
  currentPortfolioLink: "/portfolio",
  currentRewardsLink: "/rewards",
  currentOrdersLink: "/orders",
};

const navSlice = createSlice({
  name: "nav",
  initialState,
  reducers: {
    updateTradeLink: (state, action: PayloadAction<string>) => {
      state.currentTradeLink = action.payload;
    },
    updatePortfolioLink: (state, action: PayloadAction<string>) => {
      state.currentPortfolioLink = action.payload;
    },
    updateRewardsLink: (state, action: PayloadAction<string>) => {
      state.currentRewardsLink = action.payload;
    },
    updateOrdersLink: (state, action: PayloadAction<string>) => {
      state.currentOrdersLink = action.payload;
    },
    resetTradeLink: (state) => {
      state.currentTradeLink = initialState.currentTradeLink;
    },
    resetPortfolioLink: (state) => {
      state.currentPortfolioLink = initialState.currentPortfolioLink;
    },
    resetRewardsLink: (state) => {
      state.currentRewardsLink = initialState.currentRewardsLink;
    },
    resetOrdersLink: (state) => {
      state.currentOrdersLink = initialState.currentOrdersLink;
    },
  },
});

export const {
  updateTradeLink,
  updatePortfolioLink,
  updateRewardsLink,
  updateOrdersLink,
  resetTradeLink,
  resetPortfolioLink,
  resetRewardsLink,
  resetOrdersLink,
} = navSlice.actions;

export const navReducer = navSlice.reducer;
export const navActions = navSlice.actions;
