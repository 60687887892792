import { PropsWithChildren } from "react";
import * as illustrations from "./illustrations";

interface PresetEmptyStateProps {
  illustration: keyof typeof illustrations;
  customIllustration?: never;
}

interface CustomEmptyStateProps {
  illustration?: never;
  customIllustration: React.ReactNode;
}

export function EmptyState({
  children,
  illustration,
  customIllustration,
}: PropsWithChildren<PresetEmptyStateProps | CustomEmptyStateProps>) {
  const Illustration = illustration ? illustrations[illustration] : undefined;
  return (
    <div className="mx-auto w-fit self-center px-5 py-10">
      {Illustration ? (
        <div className="p-7 border border-cloud rounded-lg bg-box w-fit mx-auto">
          <Illustration className="w-18 h-18" />
        </div>
      ) : (
        customIllustration
      )}
      <p className="text-sm text-gray-200 text-center mt-8 max-w-64 mx-auto">
        {children}
      </p>
    </div>
  );
}
