import { TokenBalanceEntry } from "@/api";
import {
  SOL_TRANSACTION_FEE,
  CU_LIMIT_WITHDRAW_NATIVE_SOL,
  SOL_ADDRESS,
  calculateComputeUnitPriceMicroLamports,
} from "@/utils";

export function getIsNativeSol(token: TokenBalanceEntry): boolean {
  return token.address === SOL_ADDRESS && token.symbol?.toUpperCase() === "SOL";
}

export function calculatePriorityFee(priorityFee: number) {
  return (
    calculateComputeUnitPriceMicroLamports(
      priorityFee.toString(),
      CU_LIMIT_WITHDRAW_NATIVE_SOL,
    ) /
    2n /
    1000n
  );
}

export function getFullMinBalance(priorityFee: number): bigint {
  return SOL_TRANSACTION_FEE + calculatePriorityFee(priorityFee);
}

export function getPresetAmount(
  token: TokenBalanceEntry,
  dividedBy: number,
  priorityFee: number,
): bigint {
  let newQuantity = BigInt(token.quantity);

  if (getIsNativeSol(token)) {
    newQuantity -= getFullMinBalance(priorityFee);
  }

  newQuantity /= BigInt(dividedBy);

  return newQuantity;
}
