import { TabListBaseProps } from "./shared";
import { TokenList } from "../token-list";
import {
  TokenInfo,
  TokenInfoWithPrice,
  useFavoriteTokensQuery,
  useTokenPricesByAddressQuery,
  useTokensQuery,
} from "@/api";
import { useUserSolType } from "@/hooks";
import { useMemo } from "react";
import { EmptyState, Icon } from "@/components";
import { skipToken } from "@reduxjs/toolkit/query";

const EMPTY_LIST: TokenInfo[] = [];

interface FavoriteListProps extends TabListBaseProps {
  search: string;
}

export function FavoriteList({
  search,
  onSelectToken,
  scrollRef,
  extraHeight,
  onQuickBuyLongPress,
}: FavoriteListProps) {
  const solType = useUserSolType();
  const favoriteTokenQuery = useFavoriteTokensQuery();
  const favorites = favoriteTokenQuery.data?.map((x) => x.address) ?? [];
  const tokenInfoMap = useTokensQuery({
    solType,
    list_address: favorites,
  }).data?.data.reduce<Record<string, TokenInfo>>((acc, token) => {
    acc[token.address] = token;
    return acc;
  }, {});
  const infoMapKeys = Object.keys(tokenInfoMap ?? {});
  const favoriteTokens = tokenInfoMap
    ? favoriteTokenQuery.data
        ?.map((x) => ({ ...x, ...tokenInfoMap[x.address] }))
        .filter(Boolean)
        .sort(
          (a, b) =>
            new Date(b.likedAt).getTime() - new Date(a.likedAt).getTime(),
        ) ?? EMPTY_LIST
    : EMPTY_LIST;

  const pricesQuery = useTokenPricesByAddressQuery(
    favoriteTokens.length ? favoriteTokens.map((x) => x.address) : skipToken,
  );
  const tokens = useMemo(() => {
    if (!tokenInfoMap) return [];
    if (!favoriteTokenQuery.data) return [];
    return favoriteTokenQuery.data.reduce<TokenInfoWithPrice[]>(
      (tokens, token) => {
        const tokenData = tokenInfoMap[token.address] as TokenInfo | undefined;
        const priceData = pricesQuery.data?.[token.address];
        if (!tokenData) return tokens;
        tokens.push({
          ...tokenData,
          price: priceData?.value ?? null,
          priceChange24h: priceData?.priceChange24h ?? null,
          updateUnixTime: priceData?.updateUnixTime ?? null,
        });
        return tokens;
      },
      [],
    );
  }, [favoriteTokenQuery.data, pricesQuery.data, tokenInfoMap]);

  const hasInfoMismatch = infoMapKeys.length < favorites.length;

  if (
    !hasInfoMismatch &&
    !favoriteTokenQuery.isLoading &&
    tokens.length === 0
  ) {
    return (
      <div className="fixed flex left-0 right-0 top-[calc(var(--header-height)+var(--h-search-bar))] bottom-[calc(var(--h-tab-bar))]">
        <EmptyState
          customIllustration={
            <div className="flex flex-col justify-center items-center">
              <Icon name="hand-swipe" className="text-lime w-8" />
              <img src="/favorite-empty-state.svg" className="mt-8" />
            </div>
          }
        >
          {search
            ? "There are no results"
            : "Swipe left on tokens to like them, or add them from their page."}
        </EmptyState>
      </div>
    );
  }

  return (
    <TokenList
      ref={scrollRef}
      tokens={tokens}
      arePricesLoading={pricesQuery.isFetching}
      id="favorite-token-list"
      searchKey={search}
      isFetching={favoriteTokenQuery.isLoading || hasInfoMismatch}
      hasNextPage={false}
      onSelectToken={onSelectToken}
      extraHeight={extraHeight}
      onQuickBuyLongPress={onQuickBuyLongPress}
    />
  );
}
