import { BaseIconProps } from "../icon";

export function LockIcon(props: BaseIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 25"
      fill="none"
      {...props}
    >
      <path
        d="M18.7501 8.85417V7.8125C18.7501 4.35417 15.9584 1.5625 12.5001 1.5625C9.04175 1.5625 6.25008 4.35417 6.25008 7.8125V8.85417C5.11466 8.85417 4.16675 9.80208 4.16675 10.9375V21.3542C4.16675 22.4896 5.11466 23.4375 6.25008 23.4375H18.7501C19.8855 23.4375 20.8334 22.4896 20.8334 21.3542V10.9375C20.8334 9.80208 19.8855 8.85417 18.7501 8.85417ZM8.33342 7.8125C8.33342 5.4375 10.1251 3.64583 12.5001 3.64583C14.8751 3.64583 16.6667 5.4375 16.6667 7.8125V8.85417H8.33342V7.8125ZM18.7501 21.3542H6.25008V10.9375H18.7501V21.3542Z"
        fill="currentColor"
      />
      <path
        d="M11.5938 16.4062V18.8646H13.4063V16.4062C13.8647 16.1042 14.1355 15.6042 14.1355 15.0625C14.1355 14.1667 13.4063 13.4375 12.5105 13.4375C11.6147 13.4375 10.8855 14.1667 10.8855 15.0625C10.8855 15.6042 11.1563 16.1042 11.6147 16.4062H11.5938Z"
        fill="currentColor"
      />
    </svg>
  );
}
