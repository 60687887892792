import { TokenInfo, useTokensByAddressQuery } from "@/api";
import { useBackgroundTransactions } from "@/app/background-transactions";
import { useUserOrderHistoryQuery } from "@/app/services/order-history-api";
import {
  Button,
  ContentTabs,
  Drawer,
  EmptyState,
  Icon,
  Link,
  Page,
  Shimmer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  TokenPair,
} from "@/components";
import { withDynamicProtected } from "@/hocs/withDynamicProtected";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { getFallbackTokenInfo } from "@/routes/orders/-utils";
import {
  formatTokenNumber,
  getSymbolWithDefault,
  normalizeScaled,
} from "@/utils";
import { useIsLoggedIn } from "@dynamic-labs/sdk-react-core";
import { skipToken } from "@reduxjs/toolkit/query";
import { createFileRoute } from "@tanstack/react-router";
import { useRef, useState } from "react";

export const Route = createFileRoute("/orders/")({
  component: withDynamicProtected(Orders),
});

function Orders() {
  const isLoggedIn = useIsLoggedIn();
  const isDesktopAndUp = useMediaQuery("(min-width: 1024px)");
  const orderHistoryQuery = useUserOrderHistoryQuery(
    isLoggedIn ? undefined : skipToken,
  );
  const backgroundTransactions = useBackgroundTransactions();
  const pendingTransactions = backgroundTransactions.filter(
    (tx) => tx.status === "sending" || tx.status === "confirming",
  );
  const incompleteTransactions = backgroundTransactions.filter(
    (tx) => tx.status === "expired" || tx.status === "unknown",
  );
  const listAddresses = orderHistoryQuery.data
    ?.map((o) => [
      o.inMint || o.orderDetails?.quoteRequestInputMint || "",
      o.outMint || o.orderDetails?.quoteRequestOutputMint || "",
    ])
    .flat();
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const tokenInfoQuery = useTokensByAddressQuery(
    listAddresses ? { listAddresses, solType: "sol" } : skipToken,
  );
  const backgroundTxTokenInfo = backgroundTransactions.reduce<
    Record<string, TokenInfo>
  >((acc, tx) => {
    if (!tx.swapPair) return acc;
    return {
      ...acc,
      [tx.swapPair.base.address]: tx.swapPair.base,
      [tx.swapPair.quote.address]: tx.swapPair.quote,
    };
  }, {});
  const [selectedTab, setSelectedTab] = useState(
    pendingTransactions.length > 0 ? "in_progress" : "completed",
  );

  interface OrderLineItem {
    inMint: string;
    outMint: string;
    inAmount: string;
    outAmount: string;
    date: Date;
    orderId?: number;
    status: "pending" | "incomplete" | "succeeded" | "failed";
  }

  const getOrderLineItem = (
    item: OrderLineItem,
    infoMap: Record<string, TokenInfo | null | undefined>,
  ) => {
    const { inMint, outMint, inAmount, outAmount, date, orderId, status } =
      item;

    const inToken = (inMint && infoMap[inMint]) || getFallbackTokenInfo(inMint);
    const outToken =
      (outMint && infoMap[outMint]) || getFallbackTokenInfo(outMint);
    const pair = {
      base: inToken ?? undefined,
      quote: outToken ?? undefined,
      date,
    };
    const inUiAmount = normalizeScaled(inAmount, inToken?.decimals ?? 0);
    const outUiAmount = normalizeScaled(outAmount, outToken?.decimals ?? 0);
    const props =
      (status === "pending" || status === "incomplete") && orderId
        ? {
            to: "/orders/pending/$pendingOrderId",
            params: { pendingOrderId: orderId.toString() },
          }
        : orderId
          ? {
              to: "/orders/$orderId",
              params: { orderId: orderId.toString() },
            }
          : {
              to: "/orders",
            };
    return (
      <li key={orderId} className="px-5 py-3.5">
        <Link {...props} className="grid grid-cols-2 gap-3.5">
          <TokenPair {...pair} />
          {status === "failed" ? (
            <span className="text-sm font-bold text-negative text-right uppercase">
              Failed
            </span>
          ) : (
            <span className="flex flex-col gap-1 text-sm leading-none items-end text-right">
              <span className="text-positive font-bold">
                +
                {formatTokenNumber(outUiAmount, outToken?.decimals ?? 0, {
                  decimalsMode: "fixed",
                })}{" "}
                {getSymbolWithDefault(outToken)}
              </span>
              <span className="text-gray-200">
                -
                {formatTokenNumber(inUiAmount, inToken?.decimals ?? 0, {
                  decimalsMode: "fixed",
                })}{" "}
                {getSymbolWithDefault(inToken)}
              </span>
            </span>
          )}
        </Link>
      </li>
    );
  };

  return (
    <Page
      title="Orders"
      profileButton
      onCurrentTabClick={() => {
        if (scrollContainerRef.current) {
          scrollContainerRef.current.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }
      }}
    >
      {isDesktopAndUp ? (
        <div className="max-w-[922px] mx-auto border border-cloud rounded-md py-3.5 divide-y divide-cloud">
          <h2 className="text-page-heading font-heading px-3.5 mb-3.5 leading-none">
            Orders
          </h2>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>
                  <span className="text-primary uppercase leading-none text-sm font-bold">
                    order
                  </span>
                </TableHead>
                <TableHead>
                  <span className="text-primary uppercase leading-none text-sm font-bold">
                    value
                  </span>
                </TableHead>
                <TableHead></TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {orderHistoryQuery.currentData?.map((order) => {
                const inMint =
                  order.inMint || order.orderDetails?.quoteRequestInputMint;
                const outMint =
                  order.outMint || order.orderDetails?.quoteRequestOutputMint;
                const inToken =
                  (inMint && tokenInfoQuery.data?.[inMint]) ||
                  getFallbackTokenInfo(inMint);
                const outToken =
                  (outMint && tokenInfoQuery.data?.[outMint]) ||
                  getFallbackTokenInfo(outMint);
                const pair = {
                  base: inToken ?? undefined,
                  quote: outToken ?? undefined,
                  date: new Date(order.blockTime * 1000),
                };
                return (
                  <Drawer.Root key={order.id}>
                    <TableRow>
                      <TableCell>
                        <Drawer.Trigger className="w-full">
                          <TokenPair {...pair} />
                        </Drawer.Trigger>
                      </TableCell>
                      <TableCell>
                        <Drawer.Trigger className="w-full">
                          <span className="flex flex-col gap-1 text-sm leading-none text-left">
                            <span className="text-positive font-bold">
                              +
                              {formatTokenNumber(
                                order.inAmount,
                                inToken?.decimals ?? 6,
                                { decimalsMode: "fixed" },
                              )}{" "}
                              {getSymbolWithDefault(inToken)}
                            </span>
                            <span className="text-gray-200">
                              -
                              {formatTokenNumber(
                                order.outAmount,
                                outToken?.decimals ?? 6,
                                { decimalsMode: "fixed" },
                              )}{" "}
                              {getSymbolWithDefault(outToken)}
                            </span>
                          </span>
                        </Drawer.Trigger>
                      </TableCell>
                      <TableCell>
                        <Button
                          icon={<Icon className="w-5" name="link" />}
                          variant="small"
                          className="float-right"
                        >
                          Explorer
                        </Button>
                      </TableCell>
                    </TableRow>

                    <Drawer.Content title="View Order">
                      {/* TODO Wrap this with some component and call custom hook with orderId page logic */}
                      {/* <OrderDetails order={order} /> */}
                    </Drawer.Content>
                  </Drawer.Root>
                );
              })}
            </TableBody>
          </Table>
        </div>
      ) : (
        <ContentTabs
          value={selectedTab}
          onValueChange={setSelectedTab}
          tabs={[
            {
              label: "Completed",
              value: "completed",
              contentRef:
                selectedTab === "completed" ? scrollContainerRef : undefined,
              content: (
                <>
                  {orderHistoryQuery.isError ? (
                    <div className="h-full flex items-center justify-center">
                      <div className="flex gap-2 h-[66vh] items-center justify-center">
                        <Icon name="error" />
                        <p>Something went wrong.</p>
                      </div>
                    </div>
                  ) : !orderHistoryQuery.isLoading &&
                    (orderHistoryQuery.data?.length ?? 0) === 0 ? (
                    <div className="h-full flex items-center">
                      <EmptyState illustration="List">
                        Your orders will appear here.
                      </EmptyState>
                    </div>
                  ) : orderHistoryQuery.isLoading ||
                    tokenInfoQuery.isLoading ? (
                    <div className="px-5 pt-1">
                      <Shimmer.List />
                    </div>
                  ) : (
                    <ul className="divide-y divide-cloud">
                      {orderHistoryQuery.data?.map((order) => {
                        if (!tokenInfoQuery.data) return null;
                        const inMint =
                          order.inMint ||
                          order.orderDetails?.quoteRequestInputMint ||
                          "";
                        const outMint =
                          order.outMint ||
                          order.orderDetails?.quoteRequestOutputMint ||
                          "";
                        return getOrderLineItem(
                          {
                            inMint,
                            outMint,
                            inAmount: order.inAmount,
                            outAmount: order.outAmount,
                            date: new Date(order.blockTime * 1000),
                            orderId: order.id,
                            status:
                              order.status === "SUCCESS"
                                ? "succeeded"
                                : "failed",
                          },
                          tokenInfoQuery.data,
                        );
                      })}
                    </ul>
                  )}
                </>
              ),
            },
            {
              label: `In Progress ${pendingTransactions.length > 0 ? `(${pendingTransactions.length})` : ""}`,
              value: "in_progress",
              contentRef:
                selectedTab === "in_progress" ? scrollContainerRef : undefined,
              content: (
                <>
                  {pendingTransactions.length === 0 ? (
                    <div className="h-full flex items-center">
                      <EmptyState illustration="List">
                        You do not have any orders in progress.
                      </EmptyState>
                    </div>
                  ) : (
                    <ul className="divide-y divide-cloud">
                      {pendingTransactions.map((tx) => {
                        const quote = tx.quote;
                        if (!quote) return null;
                        return getOrderLineItem(
                          {
                            inMint: quote.inputMint,
                            outMint: quote.outputMint,
                            inAmount: quote.inAmount,
                            outAmount: quote.outAmount,
                            date: new Date(tx.startedTimeStamp),
                            orderId: tx.pendingOrderId,
                            status: "pending",
                          },
                          backgroundTxTokenInfo,
                        );
                      })}
                    </ul>
                  )}
                </>
              ),
            },
            {
              label: `Incomplete ${incompleteTransactions.length > 0 ? `(${incompleteTransactions.length})` : ""}`,
              value: "incomplete",
              contentRef:
                selectedTab === "incomplete" ? scrollContainerRef : undefined,
              content: (
                <>
                  {incompleteTransactions.length === 0 ? (
                    <div className="h-full flex items-center">
                      <EmptyState illustration="List">
                        You do not have any incomplete orders.
                      </EmptyState>
                    </div>
                  ) : (
                    <ul className="divide-y divide-cloud">
                      {incompleteTransactions.map((tx) => {
                        const quote = tx.quote;
                        if (!quote) return null;
                        return getOrderLineItem(
                          {
                            inMint: quote.inputMint,
                            outMint: quote.outputMint,
                            inAmount: quote.inAmount,
                            outAmount: quote.outAmount,
                            date: new Date(tx.startedTimeStamp),
                            orderId: tx.pendingOrderId,
                            status: "incomplete",
                          },
                          backgroundTxTokenInfo,
                        );
                      })}
                    </ul>
                  )}
                </>
              ),
            },
          ]}
        />
      )}
    </Page>
  );
}
