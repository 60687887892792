import { useUserQuery, useWalletInfoQuery } from "@/api";
import { removeExpiredTokens } from "@/app/token-cache/token-cache-actions";
import { useDebouncedFocus, useIntervalWhenFocused } from "@/hooks";
import { validateRpcUrl } from "@/routes/profile/rpc/-validate";
import {
  CustomConnection,
  MultiplexedConnection,
  activeConnection,
} from "@/rpc-connection";
import { useAppDispatch, useTypedSelector } from "@/store";
import { useIsLoggedIn } from "@dynamic-labs/sdk-react-core";
import { skipToken } from "@reduxjs/toolkit/query";
import ms from "ms";
import { PropsWithChildren, useEffect } from "react";

export function App({ children }: PropsWithChildren) {
  // This is to initialize a few things on startup
  const customRpcUrl = useTypedSelector((state) => state.profile.customRpcUrl);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!customRpcUrl) {
      connectToDefaultPool();
      return;
    }
    validateRpcUrl(customRpcUrl)
      .then(() => {
        console.info("Using custom connection");
        const conn = new CustomConnection(customRpcUrl);
        activeConnection.init(conn);
      })
      .catch(() => {
        connectToDefaultPool();
      });
  }, [customRpcUrl]);

  const isLoggedIn = useIsLoggedIn();
  // Fetch user data on startup
  useUserQuery(isLoggedIn ? undefined : skipToken);
  // Fetch wallet info on startup, refetch on focus
  const walletInfoQuery = useWalletInfoQuery();
  useDebouncedFocus(walletInfoQuery.refetch, 20_000);
  // Clean up token cache every 12 hours
  useIntervalWhenFocused(() => {
    dispatch(removeExpiredTokens());
  }, ms("12h"));

  return children;
}

function connectToDefaultPool() {
  console.info("Using default connection");
  const readUrl = import.meta.env.VITE_SOLANA_READ_RPC;
  const writeRpcUrls = import.meta.env.VITE_SOLANA_WRITE_RPCS;

  const conn = new MultiplexedConnection({
    readUrl,
    writeRpcUrls: JSON.parse(writeRpcUrls) as string[],
  });
  activeConnection.init(conn);
}
