import { cn } from "@/utils";
import {
  ChangeEventHandler,
  ClipboardEventHandler,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

interface DigitInputProps {
  isError?: boolean;
  isSuccess?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  value: string;
  inputMode: "text" | "numeric";
  onChange: (value: string) => void;
}

const INPUTS = [1, 9, 5, 2, 8, 0];

export const DigitInput = forwardRef<HTMLInputElement, DigitInputProps>(
  function DigitInput(
    { disabled, isError, isSuccess, isLoading, value, inputMode, onChange },
    outerRef,
  ) {
    const ref = useRef<HTMLInputElement>(null);
    const [hasFocus, setHasFocus] = useState(false);
    const [, rerender] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    useImperativeHandle(outerRef, () => ref.current!, []);

    const handlePaste: ClipboardEventHandler<HTMLInputElement> = (e) => {
      const data = e.clipboardData
        .getData("text")
        .replace(/[^0-9A-Za-z]/g, "")
        .toUpperCase()
        .substring(0, 6);

      onChange(data);
      setTimeout(() => {
        ref.current?.setSelectionRange(6, 6);
        rerender((v) => !v);
      }, 0);
    };

    const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
      onChange(e.target.value.replace(/[^0-9A-Za-z]/g, "").toUpperCase());
    };

    return (
      <div className="flex gap-x-[8px]">
        {INPUTS.map((_, i) => {
          const status = isSuccess
            ? "success"
            : isError
              ? "error"
              : isLoading
                ? "loading"
                : value.length > i
                  ? "filled"
                  : hasFocus && value.length === i
                    ? "focused"
                    : hasFocus
                      ? "active"
                      : "inactive";

          return (
            <div key={i} className="relative cursor-pointer">
              <div
                className={cn(
                  "flex justify-center items-center text-center border rounded-xl border-white/10 w-[46.7px] h-[45px] text-[22px] text-white bg-box",
                  {
                    "border-white/50": !disabled && status === "focused",
                    "bg-negative/10 text-negative border-negative":
                      status === "error",
                    "bg-positive/10 border-positive": status === "success",
                  },
                )}
                onClick={() => {
                  ref.current?.focus();
                }}
              >
                {value[i] ?? ""}
              </div>
            </div>
          );
        })}
        <input
          ref={ref}
          autoFocus
          inputMode={inputMode}
          className="fixed opacity-0 z-10 text-black h-[60px] w-[280px] !bg-transparent !text-transparent outline-none !caret-transparent"
          maxLength={6}
          value={value}
          disabled={disabled}
          onChange={handleChange}
          onPaste={handlePaste}
          onKeyDown={(e) => {
            if (
              e.key === "ArrowLeft" ||
              e.key === "ArrowRight" ||
              e.key === "ArrowUp" ||
              e.key === "ArrowDown"
            ) {
              e.preventDefault();
            }
          }}
          type="text"
          onFocus={() => setHasFocus(true)}
          onBlur={() => setHasFocus(false)}
          autoCorrect="off"
          spellCheck="false"
        />
      </div>
    );
  },
);
