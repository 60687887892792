import { Button, Icon, Page } from "@/components";
import { with404 } from "@/hocs/with404";
import { useMigrationStatus } from "@/hooks/useMigrationStatus";
import { DISABLE_MIGRATION_FLOW } from "@/utils";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/migrate/invalid/")({
  component: DISABLE_MIGRATION_FLOW ? with404(Invalid) : Invalid,
});

function Invalid() {
  const { handleSkip, completeMigrationMutation } = useMigrationStatus();

  return (
    <Page flex hideTabs fullScreenHeight title="Transfer your assets">
      <div className="flex-1 flex flex-col items-center px-5 pt-5 py-3">
        <div className="flex flex-col items-center gap-5">
          <Icon name="warning" className="w-12 h-12 text-primary" />
          <p className="text-sm text-gray-200">
            We have detected suspicious activity in your account. Your assets
            are not available for transfer at this time.
          </p>
        </div>
        <Button
          variant="flat"
          color="dark"
          className="w-full"
          onClick={handleSkip}
          disabled={completeMigrationMutation.isLoading}
        >
          Proceed without transferring
        </Button>
      </div>
    </Page>
  );
}
