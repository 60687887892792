import { BaseIconProps } from "../icon";

export function CheckboxOffIcon(props: BaseIconProps) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.68333 1.66699C5.025 1.66699 1.25 5.44199 1.25 10.1003C1.25 14.7587 5.025 18.5337 9.68333 18.5337C14.3417 18.5337 18.1167 14.7587 18.1167 10.1003C18.1167 5.44199 14.35 1.66699 9.68333 1.66699ZM9.68333 16.6587C6.06667 16.6587 3.13333 13.7253 3.13333 10.1087C3.13333 6.49199 6.06667 3.55866 9.68333 3.55866C13.3 3.55866 16.2333 6.49199 16.2333 10.1087C16.2333 13.7253 13.3 16.6587 9.68333 16.6587Z"
        fill="currentColor"
      />
    </svg>
  );
}
