import {
  SearchTokenParams,
  SolType,
  TokenBalanceEntry,
  useTokenSearchPricesQuery,
  useTokensQuery,
} from "@/api";
import { useRouter } from "@tanstack/react-router";
import { useCallback, useMemo, useRef, useState } from "react";
import { usePortfolio } from "./usePortfolio";

export function useSearchTokensQuery({
  skip = false,
  solType,
  persistSearchInUrl,
  addresses,
}: {
  skip?: boolean;
  solType: SolType;
  persistSearchInUrl?: boolean;
  addresses?: string[];
}) {
  const { navigate, parseLocation } = useRouter();
  const searchParams = parseLocation().search;
  const [search, setSearch] = useState(
    persistSearchInUrl &&
      "search" in searchParams &&
      typeof searchParams.search === "string"
      ? searchParams.search ?? ""
      : "",
  );
  const [debouncedParams, setDebouncedParams] = useState<SearchTokenParams>({
    filter: search,
    page: 1,
    solType,
    list_address: addresses,
  });

  const timeoutIdRef = useRef<ReturnType<typeof setTimeout>>();
  /* These two queries will merge multiple pages automatically */
  const tokensQuery = useTokensQuery(debouncedParams, { skip });
  const pricesQuery = useTokenSearchPricesQuery(debouncedParams, { skip });
  /* */
  const tokensWithPrices = useMemo(() => {
    return (
      tokensQuery.data?.data.map((token) => {
        const priceData = pricesQuery.data?.data[token.address];
        return {
          ...token,
          price: priceData?.value ?? null,
          priceChange24h: priceData?.priceChange24h ?? null,
          updateUnixTime: priceData?.updateUnixTime ?? null,
        };
      }) ?? []
    );
  }, [tokensQuery.data, pricesQuery.data]);
  const rows = tokensWithPrices;

  const updateSearchParams = useCallback(
    (newSearch: Record<string, string | number | boolean>) => {
      void navigate({
        search: {
          ...searchParams,
          ...newSearch,
        },
        replace: true,
      });
    },
    [navigate, searchParams],
  );

  const setPage = useCallback((mix: number | ((prev: number) => number)) => {
    setDebouncedParams((prev) => ({
      ...prev,
      page: typeof mix === "function" ? mix(prev.page ?? 1) : mix,
    }));
  }, []);

  const updateSearch = useCallback(
    (value: string) => {
      setSearch(value);
      if (persistSearchInUrl) {
        updateSearchParams({ search: value });
      }

      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }

      timeoutIdRef.current = setTimeout(() => {
        setDebouncedParams((prev) => ({
          ...prev,
          filter: value,
          page: 1,
        }));
      }, 500);
    },
    [updateSearchParams, persistSearchInUrl],
  );

  const hasNextPage = typeof tokensQuery.data?.nextPage === "number";
  const nextPage = tokensQuery.data?.nextPage;

  const goNextPage = useCallback(() => {
    if (nextPage) {
      setPage(nextPage);
    }
  }, [nextPage, setPage]);

  const [prevFilter, setPrevFilter] = useState(debouncedParams.filter);
  if (prevFilter !== debouncedParams.filter && !tokensQuery.isFetching) {
    setPrevFilter(debouncedParams.filter);
  }

  return {
    isLoading: (Boolean(search) && !prevFilter) || tokensQuery.isLoading,
    isFetching: tokensQuery.isFetching,
    isSearching:
      Boolean(search) &&
      (search !== tokensQuery.originalArgs?.filter || tokensQuery.isFetching),
    arePricesLoading: pricesQuery.isFetching,
    search,
    debouncedParams,
    rows,
    hasNextPage,
    setSearch: updateSearch,
    setPage,
    goNextPage,
  };
}

function filterPortfolioTokens(token: TokenBalanceEntry, search: string) {
  const searchLowercase = search.toLowerCase();
  const name = token.name?.toLowerCase();
  const symbol = token.symbol?.toLowerCase();
  return (
    (Boolean(token.address) &&
      token.address.toLowerCase().startsWith(searchLowercase)) ||
    (name &&
      (name.includes(searchLowercase) || searchLowercase.includes(name))) ||
    (symbol &&
      (symbol.includes(searchLowercase) || searchLowercase.includes(symbol)))
  );
}

export function useSearchTokensPortfolioQuery({
  skip = false,
  solType,
  persistSearchInUrl,
}: {
  skip?: boolean;
  solType: SolType;
  persistSearchInUrl?: boolean;
}) {
  const { navigate, parseLocation } = useRouter();
  const searchParams = parseLocation().search;
  const [search, setSearch] = useState(
    persistSearchInUrl &&
      "search" in searchParams &&
      typeof searchParams.search === "string"
      ? searchParams.search ?? ""
      : "",
  );
  const [debouncedParams, setDebouncedParams] = useState<SearchTokenParams>({
    filter: search,
    page: 1,
    solType,
  });
  const timeoutIdRef = useRef<ReturnType<typeof setTimeout>>();
  const portfolio = usePortfolio({ skip, solType });

  const rows =
    portfolio.tokens?.filter((token) => filterPortfolioTokens(token, search)) ??
    [];

  const updateSearchParams = useCallback(
    (newSearch: Record<string, string | number | boolean>) => {
      void navigate({
        search: {
          ...searchParams,
          ...newSearch,
        },
        replace: true,
      });
    },
    [navigate, searchParams],
  );

  const setPage = useCallback((mix: number | ((prev: number) => number)) => {
    setDebouncedParams((prev) => ({
      ...prev,
      page: typeof mix === "function" ? mix(prev.page ?? 1) : mix,
    }));
  }, []);

  const updateSearch = useCallback(
    (value: string) => {
      setSearch(value);
      if (persistSearchInUrl) {
        updateSearchParams({ search: value });
      }

      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }

      timeoutIdRef.current = setTimeout(() => {
        setDebouncedParams({ filter: value, page: 1, solType });
      }, 500);
    },
    [updateSearchParams, solType, persistSearchInUrl],
  );

  const goNextPage = useCallback(() => {
    // noop
  }, []);

  return {
    isFetching: portfolio.isFetching,
    isLoading: portfolio.isLoading,
    isEmpty: portfolio.tokens?.length === 0,
    search,
    debouncedParams,
    rows,
    hasNextPage: false,
    setSearch: updateSearch,
    setPage,
    goNextPage,
  };
}
