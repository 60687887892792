import { BaseIconProps } from "../icon";

export function ShieldIcon(props: BaseIconProps) {
  return (
    <svg
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M42.9829 9.19357C38.9197 9.19357 36.6858 7.97987 34.5046 6.80135L34.1704 6.62546C31.8486 5.35899 29.2101 3.91663 25.3931 3.91663C21.5761 3.91663 18.9377 5.35899 16.6158 6.62546L16.2816 6.80135C14.0125 8.03264 11.8841 9.19357 7.80331 9.19357H5.83325V11.1636C5.83325 25.3938 10.8815 34.1887 15.1207 39.0611C19.7468 44.3908 24.6016 46.4136 24.6719 46.4312L25.3931 46.6775L26.1319 46.4312C26.1319 46.4312 31.0395 44.426 35.6656 39.1138C39.9047 34.2415 44.953 25.4466 44.953 11.1636V9.19357H42.9829ZM18.6562 10.0203C20.8374 8.82418 22.5788 7.87433 25.3931 7.87433C28.2075 7.87433 29.9489 8.82418 32.13 10.0203L32.5874 10.2665C35.1379 11.6561 37.9347 12.5356 40.8722 12.8523C40.4852 25.1123 36.2109 32.4825 32.6929 36.5281C31.057 38.4102 28.4713 40.9256 25.3755 42.3152C22.9481 41.207 20.4328 39.1842 18.0582 36.4754C14.5402 32.4121 10.2659 25.042 9.87891 12.8523C12.8692 12.518 15.578 11.6737 18.1637 10.2665L18.621 10.0203H18.6562Z"
        fill="currentColor"
      />
    </svg>
  );
}
