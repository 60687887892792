import { cva, VariantProps } from "class-variance-authority";
import { icons } from "./icons";
import { cn } from "@/utils";
import { Fragment, PropsWithChildren } from "react";

export type BaseIconProps = Omit<
  React.SVGProps<SVGSVGElement>,
  "name" | "color"
> & { size?: "small" | "default" };

export interface IconProps
  extends BaseIconProps,
    VariantProps<typeof variants> {
  name: keyof typeof icons;
  srText?: string;
}

const variants = cva("icon", {
  variants: {
    color: {
      primary: "text-primary",
      secondary: "text-secondary",
      white: "text-white",
      black: "text-black",
      gray: "text-gray-300",
      positive: "text-positive",
      orchid: "text-orchid",
    },
  },
});

const RelativeBox = ({ children }: PropsWithChildren) => (
  <div className="relative">{children}</div>
);

export const Icon = ({
  name,
  color,
  srText,
  children,
  ...props
}: IconProps) => {
  const IconComponent = icons[name];
  const Comp = children ? RelativeBox : Fragment;
  return (
    <Comp>
      <IconComponent
        aria-hidden
        {...props}
        className={cn(variants({ color }), props.className)}
      />
      {children}
      {srText ? <span className="sr-only">{srText}</span> : null}
    </Comp>
  );
};
