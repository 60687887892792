import {
  SolType,
  TokenBalanceEntry,
  useTokenAccountsQuery,
  useTokensByAddressQuery,
  useWalletInfoQuery,
} from "@/api";
import { SOL_ADDRESS, getTokenBalanceNominalValue } from "@/utils";
import { useDynamicContext, useIsLoggedIn } from "@dynamic-labs/sdk-react-core";
import { useCallback, useMemo } from "react";
import { useDebouncedFocus } from "./useDebouncedFocus";

/** Returns a negative number if `a` should come before `b` */
function compareForSort(
  solFirst: boolean,
  a: TokenBalanceEntry,
  b: TokenBalanceEntry,
): number {
  if (solFirst && a.address === SOL_ADDRESS && a.symbol === "SOL") {
    return -1;
  }
  if (solFirst && b.address === SOL_ADDRESS && b.symbol === "SOL") {
    return 1;
  }

  const compareUsdBalance = (b.usdTotal ?? 0) - (a.usdTotal ?? 0);
  const compareQty = Number(b.quantity) - Number(a.quantity);
  const compareVerified = Number(b.verified) - Number(a.verified);
  const compareHasMetadata =
    Number(!!b.name) - Number(!!a.name) ||
    Number(!!b.symbol) - Number(!!a.symbol) ||
    Number(!!b.logoURI) - Number(!!a.logoURI);
  const compareAddress = a.address.localeCompare(b.address);
  return compareWithPrecedence([
    compareUsdBalance,
    compareQty,
    compareVerified,
    compareHasMetadata,
    compareAddress,
  ]);
}

function compareWithPrecedence(
  comparisonResultsByPrecedence: number[],
): number {
  for (const result of comparisonResultsByPrecedence) {
    if (result !== 0) {
      return result;
    }
  }
  return 0;
}

export interface UsePortfolioResult {
  /** All tokens that the user holds. Includes unknown and small balance tokens. */
  tokens?: TokenBalanceEntry[];
  knownTokens?: TokenBalanceEntry[];
  unknownTokens?: TokenBalanceEntry[];
  smallBalanceTokens?: TokenBalanceEntry[];
  totalBalance?: number;
  walletAddress?: string | null;
  isLoading: boolean;
  isFetching: boolean;
}

export function usePortfolio({
  solType,
  skip = false,
  solFirst = false,
}: {
  skip?: boolean;
  solType: SolType;
  solFirst?: boolean;
}): UsePortfolioResult {
  const { sdkHasLoaded } = useDynamicContext();
  const isLoggedIn = useIsLoggedIn();
  const queryOptions = {
    refetchOnMountOrArgChange: 5,
    skip: !isLoggedIn || !sdkHasLoaded,
  };
  const walletQuery = useWalletInfoQuery(undefined, queryOptions);
  const tokenAccountsQuery = useTokenAccountsQuery(undefined, queryOptions);
  useDebouncedFocus(
    useCallback(() => {
      void walletQuery.refetch();
      void tokenAccountsQuery.refetch();
    }, [walletQuery, tokenAccountsQuery]),
    5_000,
  );

  const tokenAddresses =
    tokenAccountsQuery.data?.map((x) => x.mintAddress) ?? [];
  const tokensQuery = useTokensByAddressQuery(
    { listAddresses: [SOL_ADDRESS, ...tokenAddresses], withPrices: true },
    { skip: skip || tokenAccountsQuery.isLoading },
  );

  const tokens = useMemo(() => {
    if (!tokenAccountsQuery.data && !walletQuery.data) {
      return undefined;
    }

    let newTokens: TokenBalanceEntry[] = [];

    const details = tokensQuery.data?.[SOL_ADDRESS];
    if (walletQuery.data?.balance && details) {
      newTokens.push({
        ...details,
        name: "Solana",
        symbol: "SOL",
        quantity: walletQuery.data.rawBalance ?? "0",
        usdTotal: getTokenBalanceNominalValue(
          walletQuery.data.rawBalance ?? "0",
          details.decimals,
          details.price ?? 0,
        ),
      });
    }

    newTokens = newTokens.concat(
      tokenAccountsQuery.data?.map((token) => {
        const details = tokensQuery.data?.[token.mintAddress];

        if (!details) {
          return {
            address: token.mintAddress,
            symbol: null,
            name: null,
            logoURI: undefined,
            quantity: token.tokenRawBalance,
            decimals: token.decimals ?? 0,
            price: null,
            priceChange24h: null,
            updateUnixTime: null,
            usdTotal: null,
            verified: false,
          };
        }

        return {
          ...details,
          ...(details.address === SOL_ADDRESS && {
            name: "Wrapped Solana",
            symbol: "WSOL",
          }),
          quantity: token.tokenRawBalance,
          usdTotal:
            token.decimals && details.price
              ? getTokenBalanceNominalValue(
                  token.tokenRawBalance,
                  token.decimals,
                  details.price,
                )
              : null,
        };
      }) ?? [],
    );

    newTokens.sort((a, b) => compareForSort(solFirst, a, b));

    if (solType === "any") {
      return newTokens;
    }

    return newTokens.filter((token) => {
      if (token.address !== SOL_ADDRESS) {
        return true;
      }
      return token.symbol?.toLowerCase() === solType;
    });
  }, [
    tokenAccountsQuery.data,
    walletQuery.data,
    tokensQuery.data,
    solType,
    solFirst,
  ]);

  const tokenKey = (token: TokenBalanceEntry) =>
    `${token.address}-${token.symbol}`;
  const unknownTokens = tokens?.filter(
    (token) => !tokensQuery.data?.[token.address],
  );
  const unknownTokenKeys = new Set(unknownTokens?.map(tokenKey) ?? []);
  const smallBalanceTokens = tokens?.filter(
    (token) =>
      !unknownTokenKeys.has(tokenKey(token)) && // Exclude unknown tokens
      (!token.usdTotal || token.usdTotal < 1),
  );
  const smallBalanceTokenKeys = new Set(
    smallBalanceTokens?.map(tokenKey) ?? [],
  );
  const knownTokens = tokens?.filter(
    (token) =>
      !unknownTokenKeys.has(tokenKey(token)) && // Exclude unknown tokens
      !smallBalanceTokenKeys.has(tokenKey(token)), // Exclude small balance tokens
  );

  for (const tk of smallBalanceTokens?.filter(
    (x) => x.address === "So11111111111111111111111111111111111111112",
  ) ?? []) {
    console.log(tk);
  }

  const totalBalance = tokens?.reduce((acc, token) => {
    return (
      acc +
      getTokenBalanceNominalValue(
        token.quantity,
        token.decimals,
        token.price ?? 0,
      )
    );
  }, 0);
  const walletAddress = walletQuery.data?.walletAddress;

  const isLoading =
    tokenAccountsQuery.isLoading ||
    tokensQuery.isLoading ||
    walletQuery.isLoading;
  const isFetching =
    tokenAccountsQuery.isFetching ||
    tokensQuery.isFetching ||
    walletQuery.isFetching;

  return {
    tokens,
    knownTokens,
    unknownTokens,
    smallBalanceTokens,
    totalBalance,
    walletAddress,
    isLoading,
    isFetching,
  };
}
