import * as ToggleGroup from "@radix-ui/react-toggle-group";
import * as HoverCard from "@radix-ui/react-hover-card";
import { type HoverCardContentProps } from "@radix-ui/react-hover-card";
import * as Popover from "@radix-ui/react-popover";
import { PriceGraph } from "../-token/price-graph";
import { useContext, useState } from "react";
import {
  type Timeframe,
  timeframes,
  useTokenPriceGraphData,
} from "../-token/token-price";
import {
  isBasePricingMode,
  isComparePricingMode,
  type BasePricingMode,
  type ComparePricingMode,
} from "../-token/reducer";
import { useTokenInfoQuery } from "@/api";
import { skipToken } from "@reduxjs/toolkit/query";
import { Icon, Link, TokenIcon } from "@/components";
import {
  cn,
  formatAddressShort,
  getSymbolWithDefault,
  formatTokenNumber,
  formatUsdMarketPrice,
} from "@/utils";
import { TokenStats } from "../-token/token-stats";
import { GraphHoverCardContext } from "./root";
import { useTypedSelector } from "@/store";

export function GraphHoverCardContentInner() {
  const { sendToken, receiveToken } = useContext(GraphHoverCardContext);

  const [mode, setMode] = useState<Timeframe["label"]>(timeframes[0].label);
  const [basePricingMode, setBasePricingMode] =
    useState<BasePricingMode>("sendToken");
  const [comparePricingMode, setComparePricingMode] =
    useState<ComparePricingMode>("USD");

  const {
    baseCompareToken,
    secondaryCompareToken,
    price,
    priceChange,
    displayPricingMode,
    setHoveredPricePoint,
    tokenPriceResults,
    hasPriceChartData,
    togglePricingModeItems,
    displayPercent,
    priceChartResults,
  } = useTokenPriceGraphData({
    basePricingMode,
    comparePricingMode,
    mode,
    sendToken,
    receiveToken,
  });

  const {
    data: tokenPriceData,
    currentData: tokenPriceCurrentData,
    isLoading: isTokenPriceLoading,
    isError: isTokenPriceError,
    isFetching: isTokenPriceFetching,
  } = tokenPriceResults;

  const { useWrappedSol } = useTypedSelector((state) => state.profile);
  const baseCompareTokenInfo = useTokenInfoQuery(
    baseCompareToken
      ? { address: baseCompareToken, solType: useWrappedSol ? "wsol" : "sol" }
      : skipToken,
  ).currentData;

  const timeframe = timeframes.find((t) => t.label === mode)?.value;

  const currentPricingMode = togglePricingModeItems.find(
    (item) => item.value === displayPricingMode,
  );
  const togglePricingMode = togglePricingModeItems.find(
    (item) => item.value !== displayPricingMode,
  );

  const formattedPrice =
    secondaryCompareToken === "USD"
      ? formatUsdMarketPrice(price)
      : baseCompareTokenInfo
        ? formatTokenNumber(price, baseCompareTokenInfo.decimals)
        : "";

  return (
    <>
      <div className="flex px-3.5 relative">
        <TokenIcon
          logoURI={baseCompareTokenInfo?.logoURI}
          className="w-6 h-6 mr-2"
        />
        <div>
          <h1 className="text-sm leading-tight pr-24">
            {baseCompareTokenInfo?.name ? (
              <span className="font-bold">{baseCompareTokenInfo.name}</span>
            ) : null}{" "}
            <span
              className={cn("text-gray-200", {
                "text-white font-bold": !baseCompareTokenInfo?.name,
              })}
            >
              {getSymbolWithDefault(baseCompareTokenInfo)}
            </span>
          </h1>
          {isTokenPriceError ? (
            "Something went wrong"
          ) : !tokenPriceData?.value && isTokenPriceLoading ? (
            <div className="pb-px">
              <div className="w-32 h-7 loading-skeleton mt-2.5" />
            </div>
          ) : (
            <div
              className={cn(
                "flex items-center transition-opacity opacity-100",
                isTokenPriceFetching && !tokenPriceCurrentData && "opacity-40",
              )}
            >
              {baseCompareTokenInfo ? (
                <p
                  className={cn(
                    "font-semibold sm:text-2xl",
                    formattedPrice.length && {
                      "text-2xl leading-normal": formattedPrice.length <= 10,
                      "text-xl leading-normal":
                        formattedPrice.length > 10 &&
                        formattedPrice.length <= 15,
                      "text-lg leading-normal": formattedPrice.length > 15,
                    },
                  )}
                >
                  {price ? formattedPrice : "–"}
                </p>
              ) : null}
              {priceChange !== undefined ? (
                <p
                  className={cn("font-bold text-sm flex items-center ml-1.5", {
                    "text-positive": priceChange >= 0,
                    "text-negative": priceChange < 0,
                  })}
                >
                  <Icon
                    name="arrow"
                    className={cn(
                      "w-2 h-2 mr-1",
                      priceChange < 0 && "rotate-180",
                    )}
                  />
                  {displayPercent}
                </p>
              ) : null}
            </div>
          )}
        </div>
        {baseCompareToken ? (
          <Link
            to={`https://solscan.io/token/${baseCompareToken}`}
            target="_blank"
            className="text-[0.625rem] absolute top-0 right-3.5 text-gray-200 flex items-center hover:underline"
          >
            {formatAddressShort(baseCompareToken, 5)}
            <Icon name="external" className="text-primary w-3 h-3 ml-1" />
          </Link>
        ) : null}
      </div>
      <div className="h-24 mt-1">
        <PriceGraph
          setHoveredPricePoint={setHoveredPricePoint}
          mode={mode}
          priceChartResults={priceChartResults}
          xMargins={14}
        />
      </div>
      <div className="mt-1">
        {priceChartResults.isLoading ? (
          <div className="loading-skeleton h-5 mt-2 mx-3.5 w-2/3" />
        ) : hasPriceChartData ? (
          <ToggleGroup.Root
            type="single"
            aria-label="Time range"
            value={timeframe}
            onValueChange={(value) => {
              const timeframe = timeframes.find((item) => item.value === value);
              if (timeframe) {
                setMode(timeframe.label);
              }
            }}
            className="flex gap-1 px-1.5 z-10"
          >
            {timeframes.map((item) => (
              <ToggleGroup.Item
                key={item.label}
                value={item.value}
                aria-label={item.title}
                className="font-bold text-xs px-2 text-gray-200 hover:text-white aria-checked:text-primary transition-colors group"
              >
                <div className="py-1 border-b border-transparent group-hover:border-white group-aria-checked:border-primary">
                  {item.label}
                </div>
              </ToggleGroup.Item>
            ))}
          </ToggleGroup.Root>
        ) : null}
        <div className="px-3.5">
          <div className="w-full h-px bg-cloud -mt-px" />
        </div>
        <div className="px-3.5 mt-1">
          <TokenStats tokenAddress={baseCompareToken} />
        </div>
        {receiveToken && currentPricingMode && togglePricingMode ? (
          <button
            type="button"
            className="w-full justify-center py-4 border-t border-cloud flex items-center text-primary hover:text-primary-hover transition-colors text-sm font-bold"
            onClick={() => {
              const parts = togglePricingMode.value.split("/");
              const [base, compare] = parts;
              if (isBasePricingMode(base) && isComparePricingMode(compare)) {
                setBasePricingMode(base);
                setComparePricingMode(compare);
              }
            }}
          >
            {currentPricingMode.label}{" "}
            <Icon name="switch" className="w-4 ml-1.5 rotate-90" />
          </button>
        ) : null}
      </div>
    </>
  );
}

export function GraphHoverCardContent(props: HoverCardContentProps) {
  const { hidePopover } = useContext(GraphHoverCardContext);

  // This ref is used to only render the inner content when this is rendered in
  // the DOM/visible to users. Otherwise, we make all of the network requests
  // for the data in this hover card even if the user never hovers it. It's
  // especially problematic when rendered in the list of tokens since it would
  // start multiple network requests for each token in the list.
  const [hoverContentRef, setHoverContentRef] = useState<HTMLDivElement | null>(
    null,
  );
  const [popoverContentRef, setPopoverContentRef] =
    useState<HTMLDivElement | null>(null);

  const className = cn(
    "border border-cloud rounded-xl bg-gray-600 pt-3.5 w-72 z-50 data-[side=bottom]:animate-slideUpAndFade data-[side=right]:animate-slideLeftAndFade data-[side=left]:animate-slideRightAndFade data-[side=top]:animate-slideDownAndFade",
    props.className,
  );
  const defaultProps: HoverCardContentProps = {
    side: "right",
    sideOffset: 4,
    align: "start",
    collisionPadding: 20,
  };

  return (
    <>
      <HoverCard.Portal>
        <HoverCard.Content
          ref={(ref) => {
            setHoverContentRef(ref);
          }}
          {...defaultProps}
          {...props}
          className={className}
        >
          {hoverContentRef ? <GraphHoverCardContentInner /> : null}
        </HoverCard.Content>
      </HoverCard.Portal>
      {!hidePopover ? (
        <Popover.Portal>
          <Popover.Content
            ref={(ref) => {
              setPopoverContentRef(ref);
            }}
            {...defaultProps}
            {...props}
            className={className}
          >
            {popoverContentRef ? <GraphHoverCardContentInner /> : null}
            <Popover.Close
              aria-label="Close"
              className="absolute -top-10 right-0 bg-gray-600 rounded-full border border-cloud p-2"
            >
              <Icon name="close" className="w-4 h-4 text-primary" />
            </Popover.Close>
          </Popover.Content>
        </Popover.Portal>
      ) : null}
    </>
  );
}
