import { useEffect } from "react";
import { useIsLoggedIn } from "@dynamic-labs/sdk-react-core";
import { useNavigate } from "@tanstack/react-router";
import { LogoSpinner } from "@/components";
import { getRedirectPath } from "@/utils";

export const withDynamicProtected = (Component: React.ComponentType) => {
  const WithDynamicProtectedWrapper = (props: {}) => {
    const isLoggedIn = useIsLoggedIn();
    const navigate = useNavigate();

    useEffect(() => {
      if (!isLoggedIn) {
        void navigate({
          to: "/login-signup",
          replace: true,
          search: (prev) => ({ ...prev, redirect: getRedirectPath() }),
        });
      }
    }, [navigate, isLoggedIn]);

    if (!isLoggedIn) {
      return (
        <div className="flex items-center justify-center h-full pb-tab-bar">
          <LogoSpinner size="large" />
        </div>
      );
    }

    return <Component {...props} />;
  };

  WithDynamicProtectedWrapper.displayName = `withDynamicProtected(${Component.displayName})`;

  return WithDynamicProtectedWrapper;
};
