import { cn } from "@/utils";

interface TransferIndicatorProps {
  type: "error" | "loading";
}

export function TransferIndicator({ type }: TransferIndicatorProps) {
  return (
    <div className="flex flex-col items-center gap-2">
      <div
        className={cn("w-1.5 h-1.5 bg-gray-300 animate-loading", {
          "bg-negative animate-none": type === "error",
        })}
      />
      <div
        className={cn(
          "w-1.5 h-1.5 bg-gray-300 animate-loading animation-delay-[500ms]",
          { "bg-negative animate-none": type === "error" },
        )}
      />
      <div
        className={cn(
          "w-1.5 h-1.5 bg-gray-300 animate-loading animation-delay-[1000ms]",
          { "bg-negative animate-none": type === "error" },
        )}
      />
      <div
        className={cn(
          "w-1.5 h-1.5 bg-gray-300 animate-loading animation-delay-[1500ms]",
          { "bg-negative animate-none": type === "error" },
        )}
      />
    </div>
  );
}
