import { QrReader } from "react-qr-reader";
import { Icon } from "@/components";
import { Corner } from "./corner";

interface ScanQRCodeProps {
  onScan: (newAddress: string) => void;
  onClose: () => void;
}

export function ScanQRCode({ onScan, onClose }: ScanQRCodeProps) {
  return (
    <div className="fixed bg-gray-600 w-full h-screen top-0">
      <div className="fixed w-full h-screen bg-black/20 top-0 left-0 z-10 flex flex-col justify-between items-center">
        <header className="w-full flex justify-between items-center pl-3 pr-5 h-header pt-[var(--safe-top)] shrink-0">
          <button
            onClick={onClose}
            aria-label="Back to address step"
            className="p-2"
          >
            <Icon name="back-arrow-boxed" className="w-5 h-5 text-primary" />
          </button>
          <h1 className="text-primary font-heading font-bold text-xl leading-none">
            Scan QR Code
          </h1>
          <div />
        </header>

        <div className="mt-16 flex flex-col items-center h-full">
          <div className="border rounded-xl border-accent w-[300px] h-[300px] relative">
            <Corner className="text-primary absolute top-0 left-0 -translate-y-1 -translate-x-1" />
            <Corner className="text-primary absolute top-0 right-0 -scale-x-100 -translate-y-1 translate-x-1" />
            <Corner className="text-primary absolute bottom-0 left-0 -scale-y-100 translate-y-1 -translate-x-1" />
            <Corner className="text-primary absolute bottom-0 right-0 -scale-y-100 -scale-x-100 translate-y-1 translate-x-1" />
          </div>
        </div>
      </div>
      {/* NOTE: camera not turning off in development but in works in production https://github.com/JodusNodus/react-qr-reader/issues/287 */}
      <QrReader
        constraints={{ facingMode: "environment" }}
        scanDelay={1000}
        onResult={(result) => {
          if (result) {
            onScan(result.getText());
          }
        }}
        className="w-full h-screen"
        videoStyle={{ objectFit: "cover" }}
        videoContainerStyle={{ height: "100%", position: "static" }}
      />
    </div>
  );
}
