import { Drawer, Icon } from "@/components";
import { ReactNode } from "react";

interface TooltipProps {
  children: ReactNode;
  title: string;
}

export const Tooltip = ({ children, title }: TooltipProps) => (
  <Drawer.Root>
    <Drawer.Trigger>
      <Icon name="info" className="w-4" />
    </Drawer.Trigger>
    <Drawer.Content title={title}>{children}</Drawer.Content>
  </Drawer.Root>
);
