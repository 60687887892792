import { BaseIconProps } from "../icon";

export function SwitchIcon(props: BaseIconProps) {
  return (
    <svg
      viewBox="0 0 13 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.98945 4.39349L6.84286 3.54008L3.76738 0.4646L0.691895 3.54008L1.5453 4.39349L3.16355 2.77524L3.16355 10.5686H4.3712L4.3712 2.77524L5.98945 4.39349Z"
        fill="currentColor"
      />
      <path
        d="M7.181 6.69607L6.3276 7.54948L9.40308 10.625L12.4786 7.54948L11.6252 6.69607L10.0069 8.31433V0.529008L8.79925 0.529008V8.30627L7.181 6.69607Z"
        fill="currentColor"
      />
    </svg>
  );
}
