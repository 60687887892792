import { OrderStatus } from "@/app/services/order-history-api";
import { Icon } from "@/components";
import { cn } from "@/utils";
import React, { useMemo } from "react";

interface ProgressProps {
  percentage: string;
  status?: OrderStatus;
}

export const Progress: React.FC<ProgressProps> = ({ percentage, status }) => {
  const radius = 24;
  const circumference = 2 * Math.PI * radius;

  const formattedPercentage = useMemo(
    () => parseFloat(percentage),
    [percentage],
  );
  const dashoffset =
    circumference - (formattedPercentage / 100) * circumference;

  const isCompleted = formattedPercentage === 100;

  return (
    <div className="h-[50px] w-[50px] relative -z-10">
      <svg className="h-full w-full" viewBox="0 0 50 50" aria-hidden>
        <circle
          className={cn(
            "stroke-current text-positive transform -rotate-90 origin-center",
            {
              "text-negative": status === "FAILED",
            },
          )}
          strokeWidth="2"
          strokeLinecap="round"
          cx="25"
          cy="25"
          r={radius}
          fill="transparent"
          strokeDasharray={circumference}
          strokeDashoffset={dashoffset}
        />
      </svg>

      {isCompleted && (
        <Icon
          name={status === "FAILED" ? "error" : "check"}
          className={cn(
            "w-6 h-6 absolute top-1/2 left-1/2 text-positive -translate-x-1/2 -translate-y-1/2",
            {
              "text-negative": status === "FAILED",
            },
          )}
        />
      )}

      {status === "FAILED" ? (
        <span className="sr-only">Status: failed</span>
      ) : null}
    </div>
  );
};
