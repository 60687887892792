import * as Tabs from "@radix-ui/react-tabs";
import {
  Page,
  Accordion,
  KeyboardAwareButton,
  Switch,
  ErrorMessage,
  Icon,
  Shimmer,
} from "@/components";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { useQuickTradePriorityFeeForm } from "../priority-fee/-use-priority-fee-hook";
import { PriorityFeeForm } from "../priority-fee/-priority-fee-form";
import { SlippageForm } from "../slippage/-slippage-form";
import { useQuickTradeSlippageForm } from "../slippage/-use-slippage-hook";
import { useEffect, useId, useRef, useState } from "react";
import { ToggleGroup } from "../-toggle-group";
import { CustomInput } from "../-custom-input";
import {
  useQuickTradeSettingsQuery,
  useUpdateQuickTradeEnabledMutation,
  useUpdateQuickTradeSettingsMutation,
} from "@/api";
import { TokenToggleGroup } from "./-token-toggle-group";
import { useBuyAmountForm } from "./-use-buy-amount-form";
import { useSellBpsForm } from "./-use-sell-bps-form";

interface SearchParams {
  redirect: string;
  tab?: "quick-buy" | "quick-sell";
}

export const Route = createFileRoute("/profile/quick-trade-settings/")({
  component: QuickTradeSettings,
  validateSearch: (searchParams?: Record<string, unknown>): SearchParams => {
    return {
      redirect:
        typeof searchParams?.redirect === "string"
          ? searchParams.redirect
          : "/profile",
      tab:
        searchParams?.tab === "quick-buy" || searchParams?.tab === "quick-sell"
          ? searchParams.tab
          : undefined,
    };
  },
});

function QuickTradeSettings() {
  const search = Route.useSearch();
  const navigate = useNavigate();
  const quickTradeSettingsQuery = useQuickTradeSettingsQuery();
  const [quickTradeSettingsEnabledMutate, quickTradeSettingsEnabledMutation] =
    useUpdateQuickTradeEnabledMutation();
  const [quickTradeSettingsMutate, quickTradeSettingsMutation] =
    useUpdateQuickTradeSettingsMutation();

  const priorityFeeFormProps = useQuickTradePriorityFeeForm();
  const slippageFormProps = useQuickTradeSlippageForm();
  const buyAmountFormProps = useBuyAmountForm();
  const sellBpsFormProps = useSellBpsForm();

  const quickTradeEnabled = quickTradeSettingsQuery.data?.enabled ?? false;

  const [unsavedEnabled, setUnsavedEnabled] = useState(quickTradeEnabled);

  const prevIsLoading = useRef(quickTradeSettingsQuery.isLoading);

  useEffect(() => {
    if (prevIsLoading.current && !quickTradeSettingsQuery.isLoading) {
      if (unsavedEnabled !== quickTradeEnabled) {
        setUnsavedEnabled(quickTradeEnabled);
      }
    }

    prevIsLoading.current = quickTradeSettingsQuery.isLoading;
  }, [quickTradeSettingsQuery.isLoading, quickTradeEnabled, unsavedEnabled]);

  const saveDisabled =
    (quickTradeEnabled === unsavedEnabled &&
      slippageFormProps.saveDisabled &&
      priorityFeeFormProps.saveDisabled &&
      buyAmountFormProps.saveDisabled &&
      sellBpsFormProps.saveDisabled) ||
    Boolean(priorityFeeFormProps.error) ||
    Boolean(buyAmountFormProps.error) ||
    Boolean(sellBpsFormProps.error);

  const switchId = useId();

  const onSave = async () => {
    if (
      quickTradeSettingsMutation.isLoading ||
      quickTradeSettingsEnabledMutation.isLoading ||
      saveDisabled
    ) {
      return;
    }

    if (unsavedEnabled !== quickTradeEnabled) {
      await quickTradeSettingsEnabledMutate(unsavedEnabled).unwrap();
    }
    if (unsavedEnabled) {
      const priorityFeeLamports = Math.round(
        Number(priorityFeeFormProps.unsavedPriorityFee.scaledValue),
      );

      await quickTradeSettingsMutate({
        priorityFee: priorityFeeLamports,
        slippageBps: Number(slippageFormProps.slippageState.value) * 100,
        buyTokenSymbol: buyAmountFormProps.unsavedBuyTokenSymbol,
        solBuyAmount: buyAmountFormProps.solBuyAmountScaledValue,
        usdcBuyAmount: buyAmountFormProps.usdcBuyAmountScaledValue,
        usdtBuyAmount: buyAmountFormProps.usdtBuyAmountScaledValue,
        sellTokenSymbol: sellBpsFormProps.unsavedSellTokenSymbol,
        solSellBps: Number(sellBpsFormProps.unsavedSellBps.value),
        usdcSellBps: Number(sellBpsFormProps.unsavedSellBps.value),
        usdtSellBps: Number(sellBpsFormProps.unsavedSellBps.value),
      }).unwrap();
    }

    void navigate({ to: decodeURI(search.redirect) });
  };

  return (
    <Page
      title="Quick trade"
      hideTabs
      backButton={{
        to: decodeURI(search.redirect),
      }}
    >
      <div className="p-5 border-b border-cloud">
        {(quickTradeSettingsEnabledMutation.error ||
          quickTradeSettingsMutation.error) && (
          <ErrorMessage variant="banner" className="mb-6">
            Something happened. Please try again.
          </ErrorMessage>
        )}
        <p className="text-sm text-gray-200">
          Enable one-click buying or selling.
        </p>
        <div className="flex items-center justify-between mt-4">
          <label
            htmlFor={switchId}
            className="text-sm font-bold uppercase text-primary"
          >
            Enable quick trade
          </label>
          <Shimmer.Text
            isLoading={quickTradeSettingsQuery.isLoading}
            className="w-10 h-5 rounded-full"
          >
            <Switch
              checked={unsavedEnabled}
              id={switchId}
              onCheckedChange={(value) => {
                setUnsavedEnabled(value);
              }}
            />
          </Shimmer.Text>
        </div>
      </div>
      {unsavedEnabled ? (
        <div>
          <p className="text-sm font-bold text-accent p-5 leading-snug">
            Changing settings here will only affect quick buying and selling.
          </p>
          <Tabs.Root defaultValue={search.tab || "quick-buy"}>
            <Tabs.List
              aria-label="Quick trade"
              className="px-5 border-b border-cloud"
            >
              <Tabs.Trigger
                value="quick-buy"
                className="uppercase font-bold text-sm py-2 mr-6 border-b border-transparent transition-colors text-gray-200 hover:text-white hover:border-white aria-selected:text-primary aria-selected:border-primary"
              >
                Quick buy
              </Tabs.Trigger>
              <Tabs.Trigger
                value="quick-sell"
                className="uppercase font-bold text-sm py-2 mr-6 border-b border-transparent transition-colors text-gray-200 hover:text-white hover:border-white aria-selected:text-primary aria-selected:border-primary"
              >
                Quick sell
              </Tabs.Trigger>
            </Tabs.List>
            <Tabs.Content value="quick-buy">
              <div className="px-5">
                <p className="text-gray-200 text-sm py-5">
                  Preconfigure the quick buy amount.
                </p>
                <div className="flex flex-col gap-2.5 mb-5">
                  <ToggleGroup
                    key={buyAmountFormProps.unsavedBuyTokenSymbol}
                    options={buyAmountFormProps.currentBuyAmountOptions}
                    setActiveValue={buyAmountFormProps.handleBuyToggleGroup}
                    activeValue={
                      buyAmountFormProps.unsavedCurrentBuyAmount
                        .isPresetSelected
                        ? buyAmountFormProps.unsavedCurrentBuyAmount.scaledValue
                        : ""
                    }
                  />
                  <CustomInput
                    customValue={
                      buyAmountFormProps.unsavedCurrentBuyAmount
                        .isPresetSelected
                        ? ""
                        : buyAmountFormProps.unsavedCurrentBuyAmount.input
                    }
                    label="Custom"
                    suffix={
                      buyAmountFormProps.unsavedCurrentBuyAmount.showSuffix
                        ? buyAmountFormProps.unsavedBuyTokenSymbol
                        : ""
                    }
                    onChange={buyAmountFormProps.handleBuyInputChange}
                    focused={
                      !buyAmountFormProps.unsavedCurrentBuyAmount
                        .isPresetSelected
                    }
                    onFocus={buyAmountFormProps.handleBuyInputFocus}
                  />
                </div>
                <TokenToggleGroup
                  label="Buy with"
                  value={buyAmountFormProps.unsavedBuyTokenSymbol}
                  onValueChange={buyAmountFormProps.setUnsavedBuyTokenSymbol}
                />
              </div>
            </Tabs.Content>
            <Tabs.Content value="quick-sell">
              <div className="px-5">
                <p className="text-gray-200 text-sm py-5">
                  Preconfigure the quick sell amount.
                </p>
                <div className="flex flex-col gap-2.5 mb-5">
                  <ToggleGroup
                    options={sellBpsFormProps.quickSellOptions}
                    setActiveValue={sellBpsFormProps.handleSellToggleGroup}
                    activeValue={
                      sellBpsFormProps.unsavedSellBps.isPresetSelected
                        ? sellBpsFormProps.unsavedSellBps.value
                        : ""
                    }
                  />
                  <CustomInput
                    customValue={
                      sellBpsFormProps.unsavedSellBps.isPresetSelected
                        ? ""
                        : sellBpsFormProps.unsavedSellBps.input
                    }
                    label="Custom"
                    suffix={
                      sellBpsFormProps.unsavedSellBps.showSuffix ? "%" : ""
                    }
                    onChange={sellBpsFormProps.handleSellInputChange}
                    focused={!sellBpsFormProps.unsavedSellBps.isPresetSelected}
                    onFocus={sellBpsFormProps.handleSellInputFocus}
                  />
                </div>
                <TokenToggleGroup
                  label="Sell to"
                  value={sellBpsFormProps.unsavedSellTokenSymbol}
                  onValueChange={sellBpsFormProps.setUnsavedSellTokenSymbol}
                />
              </div>
            </Tabs.Content>
          </Tabs.Root>
          <Accordion.Root type="multiple" className="border-b border-cloud">
            <Accordion.Item value="priority-fee" className="group">
              <Accordion.Row
                value={priorityFeeFormProps.unsavedPriorityFee.formattedValue}
              >
                <div className="flex items-center">
                  Priority Fee
                  <Icon name="bolt" className="w-4 h-4 text-white ml-1" />
                </div>
              </Accordion.Row>
              <Accordion.Content>
                <PriorityFeeForm {...priorityFeeFormProps} />
              </Accordion.Content>
            </Accordion.Item>
            <Accordion.Item value="slippage" className="group">
              <Accordion.Row
                value={`${slippageFormProps.slippageState.value}%`}
              >
                <div className="flex items-center">
                  Slippage
                  <Icon name="bolt" className="w-4 h-4 text-white ml-1" />
                </div>
              </Accordion.Row>
              <Accordion.Content>
                <SlippageForm {...slippageFormProps} />
              </Accordion.Content>
            </Accordion.Item>
          </Accordion.Root>
        </div>
      ) : null}
      <div className="pb-button-row">
        <KeyboardAwareButton
          placement="without-tabs"
          disabled={saveDisabled}
          onClick={onSave}
          className="shrink-0 w-full"
          isLoading={
            quickTradeSettingsMutation.isLoading ||
            quickTradeSettingsEnabledMutation.isLoading ||
            priorityFeeFormProps.isSaving ||
            slippageFormProps.isSaving
          }
        >
          Save Changes
        </KeyboardAwareButton>
      </div>
    </Page>
  );
}
