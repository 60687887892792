import {
  TokenBalanceEntry,
  useAddFavoriteTokenMutation,
  useDestinationSolBalanceQuery,
  useFavoriteTokensQuery,
  usePhaseZeroUserQuery,
  useQuickTradeSettingsQuery,
  useRemoveFavoriteTokenMutation,
  useTokenAccountsByOwnerQuery,
} from "@/api";
import {
  Button,
  ButtonRow,
  ContentTabs,
  EmptyState,
  Icon,
  Link,
  Page,
  Shimmer,
  SwipeableActions,
  SwipeableActionsProps,
  Token,
  icons,
} from "@/components";
import { withDynamicProtected } from "@/hocs/withDynamicProtected";
import { usePortfolio } from "@/hooks/usePortfolio";
import { updateReceiveToken } from "@/routes/trade/-reducer";
import { useAppDispatch, useTypedSelector } from "@/store";
import {
  cn,
  formatAddressShort,
  formatBasisPointsPercent,
  formatUsdBalance,
  getRedirectPath,
} from "@/utils";
import * as Collapsible from "@radix-ui/react-collapsible";
import { skipToken } from "@reduxjs/toolkit/query";
import { createFileRoute } from "@tanstack/react-router";
import { useId, useRef, useState } from "react";
import { setShowHidden, setShowSmallBalance } from "./-reducer";
import { useQuickSell } from "@/hooks/useQuickTrade";
import { CustomQuickSellDrawer } from "./-custom-quick-sell-drawer";

export const Route = createFileRoute("/portfolio/")({
  component: withDynamicProtected(Portfolio),
});

interface PortfolioTokenListProps {
  tokens: TokenBalanceEntry[] | undefined;
  onQuickSellLongPress?: (token: TokenBalanceEntry) => void;
}

function PortfolioTokenList({
  tokens,
  onQuickSellLongPress,
}: PortfolioTokenListProps) {
  const favoriteTokensQuery = useFavoriteTokensQuery();
  const [addFavoriteTokenMutate] = useAddFavoriteTokenMutation();
  const [removeFavoriteTokenMutate] = useRemoveFavoriteTokenMutation();
  const dispatch = useAppDispatch();

  const id = useId();

  const quickSellProps = useQuickSell();

  return (
    <ul>
      {tokens?.map((token) => {
        const isLiked = favoriteTokensQuery.data?.some(
          (t) => t.address === token.address,
        );

        const actions: SwipeableActionsProps["actions"] = [
          {
            label: isLiked ? "Liked" : "Like",
            icon: isLiked ? "heart-filled" : "heart",
            onClick: () => {
              if (isLiked) {
                void removeFavoriteTokenMutate(token.address);
              } else {
                void addFavoriteTokenMutate(token.address);
              }
            },
          },
          {
            label: quickSellProps.submittingTokens.includes(token.address)
              ? "Selling"
              : "Sell",
            icon: "bolt",
            isLoading: quickSellProps.isLoading,
            animateOnClick: true,
            onLongPress: onQuickSellLongPress
              ? () => {
                  onQuickSellLongPress(token);
                }
              : undefined,
            ...quickSellProps.getActionProps(token),
          },
        ];

        const descriptionId = `description-${id}-${token.address}-${token.symbol}`;

        return (
          <li
            key={token.address + (token.symbol ?? "")}
            className="border-b border-cloud h-[75px]"
          >
            <SwipeableActions
              key={`${token.address}-${token.symbol}`}
              actions={actions}
              name={
                token.name ||
                token.symbol ||
                formatAddressShort(token.address, 5)
              }
              tokenDescriptionId={descriptionId}
            >
              <Link
                to="/trade"
                search={{
                  receiveToken: token.address,
                  redirect: getRedirectPath(),
                }}
                className="block px-5"
                onClick={() => {
                  dispatch(updateReceiveToken(token));
                }}
              >
                <Token
                  {...token}
                  priceChange24hLeft
                  price={token.price ? token.usdTotal : undefined}
                  quantityDetails={{
                    value: token.quantity,
                    decimals: token.decimals,
                  }}
                  descriptionId={descriptionId}
                />
              </Link>
            </SwipeableActions>
          </li>
        );
      })}
    </ul>
  );
}

function Portfolio() {
  const dispatch = useAppDispatch();
  const { showSmallBalance, showHidden } = useTypedSelector(
    (state) => state.portfolio,
  );

  const { data: legacyAccount } = usePhaseZeroUserQuery();
  const legacyAccountSolBalanceQuery = useDestinationSolBalanceQuery(
    legacyAccount?.wallet ?? skipToken,
    {
      refetchOnMountOrArgChange: 5,
    },
  );
  const legacySolBalance = legacyAccountSolBalanceQuery.data?.rawBalance ?? "0";
  const legacyAccountTokenBalanceQuery = useTokenAccountsByOwnerQuery(
    legacyAccount?.wallet ?? skipToken,
    {
      refetchOnMountOrArgChange: 5,
    },
  );
  const legacyTokenAccounts = legacyAccountTokenBalanceQuery.data ?? [];
  const hasFundsInLegacyAccount =
    legacySolBalance !== "0" ||
    legacyTokenAccounts.some((a) => a.balance !== "0");

  const {
    knownTokens,
    unknownTokens,
    smallBalanceTokens,
    tokens,
    totalBalance,
    isLoading,
  } = usePortfolio({
    solFirst: true,
    solType: "any",
  });

  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const quickTradeSettingsQuery = useQuickTradeSettingsQuery();

  const [quickSellDrawerToken, setQuickSellDrawerToken] = useState<
    TokenBalanceEntry | undefined
  >(undefined);

  const quickTradeSellBps =
    quickTradeSettingsQuery.data?.settings?.sellTokenSymbol === "SOL"
      ? quickTradeSettingsQuery.data.settings.solSellBps
      : quickTradeSettingsQuery.data?.settings?.sellTokenSymbol === "USDC"
        ? quickTradeSettingsQuery.data.settings.usdcSellBps
        : quickTradeSettingsQuery.data?.settings?.sellTokenSymbol === "USDT"
          ? quickTradeSettingsQuery.data.settings.usdtSellBps
          : null;

  return (
    <Page
      title="Portfolio"
      profileButton
      onCurrentTabClick={() => {
        if (scrollContainerRef.current) {
          scrollContainerRef.current.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }
      }}
      headerContent={
        <Shimmer.Text
          isLoading={quickTradeSettingsQuery.isLoading}
          className="w-8 h-5 mr-2"
        >
          <Link
            to="/profile/quick-trade-settings"
            search={{ redirect: getRedirectPath(), tab: "quick-sell" }}
            aria-label="Quick trade settings"
            className="shrink-0 p-2 flex items-center gap-x-1"
          >
            <Icon name="bolt" className="text-primary w-5 h-5" />
            {quickTradeSettingsQuery.data?.enabled && quickTradeSellBps ? (
              <p className="text-sm text-primary font-bold">
                {formatBasisPointsPercent(quickTradeSellBps)}
              </p>
            ) : null}
          </Link>
        </Shimmer.Text>
      }
    >
      <ContentTabs
        tabs={[
          {
            label: "Overview",
            contentRef: scrollContainerRef,
            content: (
              <div className="h-full flex flex-col pt-7 relative z-[0]">
                <div className="px-5">
                  <h2 className="uppercase text-primary text-sm font-bold leading-[1.3]">
                    Total Balance
                  </h2>
                  {isLoading ? (
                    <Shimmer.List rows={1} rowClassName="h-[65px]" />
                  ) : (
                    <p className="text-5xl font-semibold leading-[1.3]">
                      {formatUsdBalance(totalBalance)}
                    </p>
                  )}

                  <p
                    aria-hidden={!hasFundsInLegacyAccount}
                    className={cn(
                      "text-gray-200 text-sm mt-2.5 mb-5 transition-all overflow-hidden opacity-100 h-[42px]",
                      {
                        "opacity-0": !hasFundsInLegacyAccount,
                        "h-0": !hasFundsInLegacyAccount,
                        "m-0": !hasFundsInLegacyAccount,
                      },
                    )}
                  >
                    Funds have been detected in your Phase 0 account.
                    <br />
                    <Link
                      to="/migrate/notice"
                      search={{ redirect: getRedirectPath() }}
                      className="text-primary font-bold"
                    >
                      Transfer funds to your upgraded account.
                    </Link>
                  </p>
                </div>
                <div className="flex flex-col flex-1">
                  <h2 className="uppercase text-primary text-sm font-bold leading-[1.3] px-5 py-3.5 border-b border-cloud">
                    Assets
                  </h2>
                  {isLoading ? (
                    <Shimmer.List className="px-5 pt-3" rows={3} />
                  ) : tokens?.length === 0 ? (
                    <div className="h-full flex items-center">
                      <EmptyState illustration="CoinStacks">
                        Your assets will appear here.
                        <br />
                        Deposit tokens to get started.
                      </EmptyState>
                    </div>
                  ) : (
                    <>
                      <PortfolioTokenList
                        tokens={knownTokens}
                        onQuickSellLongPress={(token) => {
                          setQuickSellDrawerToken(token);
                        }}
                      />
                      {smallBalanceTokens?.length ? (
                        <Collapsible.Root
                          open={showSmallBalance}
                          onOpenChange={(open) => {
                            dispatch(setShowSmallBalance(open));
                          }}
                        >
                          <Collapsible.Trigger className="w-full flex justify-between items-center group px-5 py-3.5 border-b border-cloud">
                            <p className="uppercase text-primary text-sm font-bold">
                              Small balance ({smallBalanceTokens.length})
                            </p>
                            <p className="text-primary text-sm font-bold flex items-center gap-1">
                              {showSmallBalance ? "HIDE" : "SHOW"}
                              <icons.chevron className="w-3.5 h-3.5 group-data-[state=open]:rotate-180" />
                            </p>
                          </Collapsible.Trigger>
                          <Collapsible.Content>
                            <PortfolioTokenList
                              tokens={smallBalanceTokens}
                              onQuickSellLongPress={(token) => {
                                setQuickSellDrawerToken(token);
                              }}
                            />
                          </Collapsible.Content>
                        </Collapsible.Root>
                      ) : null}
                      {unknownTokens?.length ? (
                        <Collapsible.Root
                          open={showHidden}
                          onOpenChange={(open) => {
                            dispatch(setShowHidden(open));
                          }}
                        >
                          <Collapsible.Trigger className="w-full flex justify-between items-center group px-5 py-3.5 border-b border-cloud">
                            <p className="uppercase text-primary text-sm font-bold">
                              Hidden ({unknownTokens.length})
                            </p>
                            <p className="text-primary text-sm font-bold flex items-center gap-1">
                              {showHidden ? "HIDE" : "SHOW"}
                              <icons.chevron className="w-3.5 h-3.5 group-data-[state=open]:rotate-180" />
                            </p>
                          </Collapsible.Trigger>
                          <Collapsible.Content>
                            <PortfolioTokenList
                              tokens={unknownTokens}
                              onQuickSellLongPress={(token) => {
                                setQuickSellDrawerToken(token);
                              }}
                            />
                          </Collapsible.Content>
                        </Collapsible.Root>
                      ) : null}
                    </>
                  )}
                </div>
              </div>
            ),
            footer: (
              <ButtonRow border>
                <Button variant="cta" className="w-full" to="/deposit">
                  Deposit
                </Button>
                {tokens && tokens.length > 0 ? (
                  <Button
                    variant="outline"
                    className="w-full"
                    to="/withdraw/amount"
                  >
                    Withdraw
                  </Button>
                ) : null}
              </ButtonRow>
            ),
          },
        ]}
      />
      <CustomQuickSellDrawer
        token={quickSellDrawerToken}
        onClose={() => {
          setQuickSellDrawerToken(undefined);
        }}
      />
    </Page>
  );
}
