import { TokenInfo, useTokenInfoQuery } from "@/api";
import { Order } from "@/app/services/order-history-api";
import { Button, ButtonRow, Icon, Progress, TokenIcon } from "@/components";
import { getFallbackTokenInfo } from "@/routes/orders/-utils";
import {
  formatDate,
  formatExecution,
  formatTokenNumber,
  getExecution,
  getSymbolWithDefault,
  normalizeScaled,
} from "@/utils";
import { skipToken } from "@reduxjs/toolkit/query";

interface OrderDetailsProps {
  order: Order;
}

const OrderDetailsInner = ({ order }: OrderDetailsProps) => {
  const inTokenAddress =
    order.inMint || order.orderDetails?.quoteRequestInputMint;
  const inTokenQuery = useTokenInfoQuery(
    inTokenAddress ? { address: inTokenAddress, solType: "sol" } : skipToken,
  );

  const outTokenAddress =
    order.outMint || order.orderDetails?.quoteRequestOutputMint;
  const outTokenQuery = useTokenInfoQuery(
    outTokenAddress ? { address: outTokenAddress, solType: "sol" } : skipToken,
  );
  const inToken =
    inTokenQuery.data ??
    getFallbackTokenInfo(
      order.inMint || order.orderDetails?.quoteRequestInputMint,
    );
  const outToken =
    outTokenQuery.data ??
    getFallbackTokenInfo(
      order.outMint || order.orderDetails?.quoteRequestOutputMint,
    );

  if (inTokenQuery.isLoading || outTokenQuery.isLoading) {
    return <LoadingSkeleton />;
  }
  const orderWithExtraDetails = buildOrderDetails(order, inToken, outToken);
  if (!orderWithExtraDetails)
    return (
      <div className="flex flex-col items-center pt-28">
        <div className="flex flex-col items-center max-w-72 gap-8">
          <Icon name="search" className="w-12 h-12" />
          <p>
            We are unable to get the details of this order. Please check the
            solscan link for more information.
          </p>
          <Button
            to={`https://solscan.io/tx/${order.signature}`}
            className="w-full"
            icon={<Icon className="w-5" name="link" />}
          >
            View on explorer
          </Button>
        </div>
      </div>
    );

  const { status, orderDate, inUiAmount, outUiAmount, execution, signature } =
    orderWithExtraDetails;

  return (
    <div className="flex flex-col pb-button-row max-lg:px-5">
      <div className="flex flex-col items-center justify-center mb-6 lg:hidden">
        <Progress percentage="100" status={status} />
      </div>

      <p className="text-xs text-gray-200 mb-6 uppercase leading-none lg:hidden whitespace-pre">
        {orderDate ? formatDate(orderDate, "long") : "N/A"}
      </p>

      <div className="divide-y divide-cloud gap-5">
        <ul className="flex flex-col">
          <li className="flex gap-2 justify-between items-center mb-3.5 text-md leading-none">
            <span className="font-bold inline-flex flex-wrap flex-col gap-1">
              <span className="text-primary uppercase">
                {status === "FAILED" ? "Sell" : "Sold"}
              </span>
              {inToken ? (
                <span className="text-white">
                  {formatTokenNumber(inUiAmount, inToken.decimals, {
                    decimalsMode: "fixed",
                  })}{" "}
                  {getSymbolWithDefault(inToken)}
                </span>
              ) : null}
            </span>
            <span className="flex items-center gap-1 text-right">
              <TokenIcon logoURI={inToken?.logoURI} className="w-8 h-8" />
            </span>
          </li>
          <li className="flex justify-end">
            <Icon
              name="arrow-large"
              className="w-4 -translate-x-[7px] -translate-y-[5px]"
            />
          </li>
          <li className="flex gap-2 justify-between items-center mb-3.5 text-md leading-none">
            <span className="font-bold inline-flex flex-wrap flex-col gap-1">
              <span className="text-primary uppercase">
                {status === "FAILED" ? "Buy" : "Bought"}
              </span>
              {outToken ? (
                <span className="text-white">
                  {formatTokenNumber(outUiAmount, outToken.decimals, {
                    decimalsMode: "fixed",
                  })}{" "}
                  {getSymbolWithDefault(outToken)}
                </span>
              ) : null}
            </span>
            <span className="flex items-center gap-1 text-right">
              <TokenIcon logoURI={outToken?.logoURI} className="w-8 h-8" />
            </span>
          </li>
        </ul>
        {status === "SUCCESS" ? (
          <ul className="flex flex-col pt-5">
            <li className="flex gap-2 justify-between items-center mb-3.5 text-sm leading-none">
              <span className="font-bold inline-flex flex-wrap gap-1 items-center">
                <span className="text-primary leading-none uppercase">
                  Execution Price
                </span>
              </span>
              <span className="flex items-center gap-1 text-right font-bold">
                {formatExecution(execution, inToken, outToken)}
              </span>
            </li>
          </ul>
        ) : null}
      </div>

      <ButtonRow>
        <Button
          to={`https://solscan.io/tx/${signature}`}
          className="w-full"
          icon={<Icon className="w-5" name="link" />}
        >
          View on explorer
        </Button>
      </ButtonRow>
    </div>
  );
};

const LoadingSkeleton = () => (
  <div role="status" className="flex flex-col animate-pulse">
    <div className="flex justify-center pb-3.5">
      <div className="rounded-full bg-gray-500 w-[48px] h-[48px]" />
    </div>
    <div className="py-3 px-5">
      <div className="h-2 rounded-full bg-gray-500 max-w-[120px] mb-2.5"></div>
    </div>
    <div className="py-3 px-5 border-b border-cloud">
      <div className="h-2 rounded-full bg-gray-500 max-w-[60px] mb-2.5"></div>
      <div className="h-2 rounded-full bg-gray-500 max-w-[120px] mb-2.5"></div>
    </div>
    <div className="py-4 px-5 border-b border-cloud">
      <div className="h-2 rounded-full bg-gray-500 max-w-[330px] mb-4"></div>
      <div className="h-2 rounded-full bg-gray-500 max-w-[300px] mb-4"></div>
    </div>
    <div className="py-4 px-5">
      <div className="h-2 rounded-full bg-gray-500 max-w-[360px] mb-4"></div>
    </div>
    <span className="sr-only">Loading...</span>
  </div>
);

export const OrderDetails = Object.assign(OrderDetailsInner, {
  LoadingSkeleton,
});

interface OrderWithExtraDetails extends Order {
  inToken: TokenInfo | null;
  outToken: TokenInfo | null;
  inUiAmount: string | null;
  outUiAmount: string | null;
  execution: number | null;
  orderDate: Date | null;
}

function buildOrderDetails(
  order: Order,
  inToken: TokenInfo | null,
  outToken: TokenInfo | null,
): OrderWithExtraDetails | null {
  if (inToken === null || outToken === null) return null;
  let inUiAmount = "0";
  let outUiAmount = "0";
  let execution: number | null = null;
  if (order.status === "FAILED") {
    if (order.orderDetails === null) return null;

    inUiAmount = normalizeScaled(
      order.orderDetails.quoteResponseInputAmount,
      inToken.decimals,
    );
    outUiAmount = normalizeScaled(
      order.orderDetails.quoteResponseOutputAmount,
      outToken.decimals,
    );
    execution = getExecution(
      order.orderDetails.quoteResponseInputAmount,
      order.orderDetails.quoteResponseOutputAmount,
      inToken.decimals,
      outToken.decimals,
    );
  } else {
    inUiAmount = normalizeScaled(order.inAmount, inToken.decimals);
    outUiAmount = normalizeScaled(order.outAmount, outToken.decimals);
    execution = getExecution(
      order.inAmount,
      order.outAmount,
      inToken.decimals,
      outToken.decimals,
    );
  }
  const orderDate = new Date(order.blockTime * 1000);

  return {
    ...order,
    inToken,
    outToken,
    inUiAmount,
    outUiAmount,
    execution,
    orderDate,
  };
}
