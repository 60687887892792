import { useId } from "react";
import * as RadixSelect from "@radix-ui/react-select";
import * as ToggleGroup from "@radix-ui/react-toggle-group";
import { Drawer, Icon } from "@/components";
import { useMediaQuery } from "@/hooks/useMediaQuery";

interface SelectItemProps {
  value: string;
  label: string;
}

interface SelectProps {
  value: string;
  onValueChange?: (value: string) => void;
  title: string;
  items: SelectItemProps[];
  triggerIcon?: React.ReactNode;
}

export function Select({
  onValueChange,
  value,
  title,
  items,
  triggerIcon,
}: SelectProps) {
  const isDesktopAndUp = useMediaQuery("(min-width: 1024px)");
  const mode = isDesktopAndUp ? "select" : "drawer";

  const drawerTitleId = useId();

  const triggerClassName =
    "flex items-center text-primary hover:text-primary-hover transition-colors text-sm font-bold";

  const displayValueLabel = items.find((item) => item.value === value)?.label;

  return mode === "select" ? (
    <RadixSelect.Root value={value} onValueChange={onValueChange}>
      <RadixSelect.Trigger aria-label={title} className={triggerClassName}>
        <RadixSelect.Value />
        <RadixSelect.Icon>
          <Icon name="chevron" className="w-3 ml-1.5" />
        </RadixSelect.Icon>
      </RadixSelect.Trigger>
      <RadixSelect.Portal>
        <RadixSelect.Content className="relative bg-box border border-cloud rounded py-1 z-20">
          <RadixSelect.Viewport>
            {items.map((item) => (
              <RadixSelect.Item
                value={item.value}
                key={item.value}
                className="text-primary font-bold text-sm px-3 h-10 pr-12 flex items-center data-[highlighted]:text-primary-hover transition-colors relative select-none"
              >
                <RadixSelect.ItemText>{item.label}</RadixSelect.ItemText>
                <RadixSelect.ItemIndicator className="absolute right-3 top-1/2 -translate-y-1/2">
                  <Icon name="circle-check" className="w-4 h-4" />
                </RadixSelect.ItemIndicator>
              </RadixSelect.Item>
            ))}
          </RadixSelect.Viewport>
        </RadixSelect.Content>
      </RadixSelect.Portal>
    </RadixSelect.Root>
  ) : (
    <Drawer.Root>
      <Drawer.Trigger className={triggerClassName}>
        {displayValueLabel} {triggerIcon}
      </Drawer.Trigger>
      <Drawer.Content title={title} titleId={drawerTitleId}>
        <ToggleGroup.Root
          type="single"
          value={value}
          onValueChange={(value) => {
            if (value && onValueChange) {
              onValueChange(value);
            }
          }}
          aria-labelledby={drawerTitleId}
        >
          {items.map((item) => (
            <Drawer.Close asChild key={item.value}>
              <ToggleGroup.Item
                value={item.value}
                className="w-full text-left text-sm font-bold group flex items-center justify-between gap-8 border-b border-cloud py-3.5 h-info-row"
              >
                {item.label}
                <Icon
                  name="check"
                  className="w-5 hidden group-aria-checked:inline"
                />
                <Icon
                  name="chevron"
                  className="w-4 -rotate-90 group-aria-checked:hidden"
                />
              </ToggleGroup.Item>
            </Drawer.Close>
          ))}
        </ToggleGroup.Root>
      </Drawer.Content>
    </Drawer.Root>
  );
}
