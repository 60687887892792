import { TokenInfo as TTokenInfo, useTokenInfoQuery } from "@/api";
import { Icon, Link, Select } from "@/components";
import { useAccountBalance, useClipboard } from "@/hooks";
import { useTokenInfoParams } from "@/hooks/useTokenInfoParams";
import { TokenStats } from "@/routes/trade/-token/token-stats";
import { useAppDispatch, useTypedSelector } from "@/store";
import {
  cn,
  formatAddressShort,
  formatTokenNumber,
  formatUsdMarketPrice,
  getSymbolWithDefault,
} from "@/utils";
import * as ToggleGroup from "@radix-ui/react-toggle-group";
import { skipToken } from "@reduxjs/toolkit/query";
import clsx from "clsx";
import { useEffect } from "react";
import { ChooseTokenDrawer } from "../-choose-token-drawer";
import { PriceGraph } from "./price-graph";
import { reset, updateChartMode, updatePricingMode } from "./reducer";
import { timeframes, useTokenPriceGraphData } from "./token-price";
import { TokenTimeStats } from "./token-time-stats";
// import { TooltipStats } from "./tooltip-stats";

interface TokenInfoProps {
  sendToken: Partial<Pick<TTokenInfo, "address">>;
  receiveToken: Partial<Pick<TTokenInfo, "address">>;
  onCompareTokenChange: (token: string) => void;
  isLoading?: boolean;
}

export function TokenInfo({
  sendToken: _sendToken,
  receiveToken: _receiveToken,
  onCompareTokenChange,
  isLoading,
}: TokenInfoProps) {
  const sendTokenAddress = _sendToken.address;
  const receiveTokenAddress = _receiveToken.address;
  const sendToken = useTokenInfoQuery(
    useTokenInfoParams(sendTokenAddress),
  ).currentData;
  const receiveToken = useTokenInfoQuery(
    useTokenInfoParams(receiveTokenAddress),
  ).currentData;
  const sendTokenBalanceQuery = useAccountBalance(sendToken?.address);
  const receiveTokenBalanceQuery = useAccountBalance(receiveToken?.address);

  const { basePricingMode, comparePricingMode, mode, token } = useTypedSelector(
    (state) => state.tradeChart,
  );
  const dispatch = useAppDispatch();

  const [copied, copy] = useClipboard();

  useEffect(() => {
    if (receiveToken && receiveToken.address !== token) {
      dispatch(reset(receiveToken.address));
    }
  });

  useEffect(() => {
    if (
      !sendToken &&
      (basePricingMode !== "receiveToken" || comparePricingMode !== "USD")
    ) {
      dispatch(updatePricingMode(["receiveToken", "USD"]));
    }
  });

  const timeframe = timeframes.find((t) => t.label === mode);
  const timeframeMode = timeframe?.value;
  const timeframeTitle = timeframe?.title;

  const {
    baseCompareToken,
    secondaryCompareToken,
    price,
    priceChange,
    displayPricingMode,
    setHoveredPricePoint,
    tokenPriceResults,
    hasPriceChartData,
    pricingModeItems,
    displayPercent,
    priceChartResults,
  } = useTokenPriceGraphData({
    basePricingMode,
    comparePricingMode,
    mode,
    sendToken: sendTokenAddress,
    receiveToken: receiveTokenAddress,
    refetchOnMountOrArgChange: true,
  });

  const { useWrappedSol } = useTypedSelector((state) => state.profile);
  const baseCompareTokenInfoQuery = useTokenInfoQuery(
    baseCompareToken && baseCompareToken !== "USD"
      ? { address: baseCompareToken, solType: useWrappedSol ? "wsol" : "sol" }
      : skipToken,
  );
  const baseCompareTokenInfo = baseCompareTokenInfoQuery.currentData;

  const secondaryCompareTokenInfoQuery = useTokenInfoQuery(
    secondaryCompareToken && secondaryCompareToken !== "USD"
      ? {
          address: secondaryCompareToken,
          solType: useWrappedSol ? "wsol" : "sol",
        }
      : skipToken,
  );
  const secondaryCompareTokenInfo = secondaryCompareTokenInfoQuery.currentData;

  const {
    data: tokenPriceData,
    currentData: tokenPriceCurrentData,
    isLoading: isTokenPriceLoading,
    isFetching: isTokenPriceFetching,
  } = tokenPriceResults;

  const compareTokenBalance =
    baseCompareToken === sendToken?.address
      ? sendToken &&
        sendTokenBalanceQuery.data &&
        formatTokenNumber(
          sendTokenBalanceQuery.data.balance,
          sendToken.decimals,
        )
      : receiveToken &&
        receiveTokenBalanceQuery.data &&
        formatTokenNumber(
          receiveTokenBalanceQuery.data.balance,
          receiveToken.decimals,
        );
  const isCompareTokenBalanceLoading =
    (baseCompareToken === sendToken?.address &&
      sendTokenBalanceQuery.isLoading) ||
    (baseCompareToken === receiveToken?.address &&
      receiveTokenBalanceQuery.isLoading);

  const formattedPrice =
    secondaryCompareToken === "USD"
      ? formatUsdMarketPrice(price)
      : baseCompareTokenInfo
        ? formatTokenNumber(price, baseCompareTokenInfo.decimals)
        : "";

  return (
    <div>
      <div className="relative px-5">
        {baseCompareTokenInfoQuery.isLoading ? (
          <div className="w-28 h-[18px] loading-skeleton" />
        ) : (
          <h2 className="text-sm leading-light">
            {baseCompareTokenInfo?.name ? (
              <span className="font-bold">{baseCompareTokenInfo.name}</span>
            ) : null}{" "}
            <span
              className={cn("text-gray-200", {
                "text-white font-bold": !baseCompareTokenInfo?.name,
              })}
            >
              {getSymbolWithDefault(baseCompareTokenInfo)}
            </span>
          </h2>
        )}
        {!tokenPriceData?.value && isTokenPriceLoading ? (
          <>
            <div className="w-40 h-[24.79px] sm:h-[48.2px] loading-skeleton mt-2.5" />
            <div className="w-32 h-[18px] loading-skeleton mt-2.5" />
          </>
        ) : (
          <div
            className={cn(
              "transition-opacity opacity-100",
              isTokenPriceFetching && !tokenPriceCurrentData && "opacity-40",
            )}
          >
            {baseCompareTokenInfo ? (
              <p className="font-semibold leading-light text-3xl sm:text-5xl">
                {price ? formattedPrice : "–"}
              </p>
            ) : null}
            <div className="flex items-center flex-wrap gap-x-1.5">
              {secondaryCompareToken !== "USD" &&
              price &&
              secondaryCompareTokenInfo &&
              baseCompareTokenInfo ? (
                <p className="text-sm leading-light">
                  {getSymbolWithDefault(secondaryCompareTokenInfo)} per{" "}
                  {getSymbolWithDefault(baseCompareTokenInfo)}
                </p>
              ) : null}
              {priceChange !== undefined ? (
                <p
                  className={clsx(
                    "font-bold text-sm leading-light flex items-center",
                    {
                      "text-positive": priceChange >= 0,
                      "text-negative": priceChange < 0,
                    },
                  )}
                >
                  <Icon
                    name="arrow"
                    className={clsx(
                      "w-2 h-2 mr-1",
                      priceChange < 0 && "rotate-180",
                    )}
                    srText={priceChange < 0 ? "Decrease of" : "Increase of"}
                  />
                  {secondaryCompareToken === "USD" ? (
                    formatUsdMarketPrice(Math.abs(priceChange))
                  ) : secondaryCompareTokenInfo ? (
                    <>
                      {formatTokenNumber(
                        Math.abs(priceChange),
                        secondaryCompareTokenInfo.decimals,
                      )}{" "}
                      <span className="pl-1">
                        {secondaryCompareTokenInfo.symbol}
                      </span>
                    </>
                  ) : null}{" "}
                  <span className="pl-1">({displayPercent})</span>
                </p>
              ) : (
                <p className="font-bold text-sm leading-light">–</p>
              )}
              <p className="text-sm">{timeframeTitle}</p>
            </div>
          </div>
        )}
        {sendToken ? (
          <div className="absolute top-0 right-4">
            <Select
              onValueChange={(value) => {
                const parts = value.split("/");
                dispatch(updatePricingMode([parts[0], parts[1]]));
              }}
              value={displayPricingMode}
              title="Display price as"
              items={pricingModeItems}
              triggerIcon={
                <Icon name="switch" className="w-3 ml-1.5 rotate-90" />
              }
            />
          </div>
        ) : null}
      </div>
      <div className="h-80 mt-4">
        <PriceGraph
          setHoveredPricePoint={setHoveredPricePoint}
          mode={mode}
          isLoading={
            isLoading || isTokenPriceLoading || priceChartResults.isLoading
          }
          priceChartResults={priceChartResults}
          xMargins={16}
        />
      </div>
      <div className="flex justify-between items-center mt-4">
        {hasPriceChartData ? (
          <ToggleGroup.Root
            type="single"
            aria-label="Time range"
            value={timeframeMode}
            onValueChange={(value) => {
              const timeframe = timeframes.find((item) => item.value === value);
              if (timeframe) {
                dispatch(updateChartMode(timeframe));
              }
            }}
            className="flex gap-0.5 px-3"
          >
            {timeframes.map((item) => (
              <ToggleGroup.Item
                key={item.label}
                value={item.value}
                aria-label={item.title}
                className="font-bold text-sm px-2 text-gray-200 hover:text-white aria-checked:text-primary transition-colors group"
              >
                <div className="py-2 border-b border-transparent group-hover:border-white group-aria-checked:border-primary">
                  {item.label}
                </div>
              </ToggleGroup.Item>
            ))}
          </ToggleGroup.Root>
        ) : null}
        <div className="ml-auto">
          <ChooseTokenDrawer
            excludedToken={receiveToken}
            selectedToken={sendToken}
            solType={useWrappedSol ? "wsol" : "sol"}
            onSelectToken={(token) => {
              onCompareTokenChange(token.address);
              dispatch(updatePricingMode(["receiveToken", "sendToken"]));
            }}
            triggerClassName="text-primary uppercase font-bold text-sm mr-3 px-2 py-2"
            title="Select token to compare"
          >
            Compare
          </ChooseTokenDrawer>
        </div>
      </div>
      <div className="w-full h-px bg-cloud -mt-px" />
      {isCompareTokenBalanceLoading ? (
        <div className="w-full px-5 h-[54px] flex items-center justify-between bg-transparent border-b border-cloud">
          <div className="w-28 h-2.5 loading-skeleton rounded-full" />
          <div className="w-24 h-2.5 loading-skeleton rounded-full" />
        </div>
      ) : compareTokenBalance ? (
        <div className="px-5 py-4 flex justify-between text-sm border-b border-cloud">
          <h3 className="text-gray-200">Available Balance</h3>
          <p className="font-bold">
            {compareTokenBalance} {baseCompareTokenInfo?.symbol ?? ""}
          </p>
        </div>
      ) : null}
      {baseCompareToken ? (
        <div className="px-5 py-4 flex items-center justify-between border-b border-cloud">
          <h3 className="text-gray-200 text-sm">Token Address</h3>
          <div className="flex items-center gap-2">
            <Link
              to={`https://solscan.io/token/${baseCompareToken}`}
              target="_blank"
              className="text-sm font-bold text-primary hover:underline"
            >
              {formatAddressShort(baseCompareToken)}
            </Link>
            <button
              type="button"
              onClick={() => {
                void copy(baseCompareToken);
              }}
              aria-label="Copy address"
            >
              <Icon name={copied ? "check" : "copy"} className="w-4 h-4" />
            </button>
          </div>
        </div>
      ) : null}
      <div>
        <TokenStats tokenAddress={baseCompareTokenInfo?.address} />
      </div>
      <TokenTimeStats tokenAddress={baseCompareTokenInfo?.address} />
      {/* Temporarily removing security stats until provider is changed */}
      {/* <TooltipStats tokenAddress={baseCompareTokenInfo?.address} /> */}
    </div>
  );
}
