import { Drawer, Icon, Page, Shimmer } from "@/components";
import { useClipboard } from "@/hooks/useClipboard";
import { cn, formatAddressShort } from "@/utils";
import { createFileRoute } from "@tanstack/react-router";
import { useEffect, useRef, useState } from "react";
import { QRCode } from "react-qrcode-logo";
import { initOnRamp, CBPayInstanceType } from "@coinbase/cbpay-js";
import { withDynamicProtected } from "@/hocs/withDynamicProtected";
import { useDynamicWallet } from "@/hooks";

export const Route = createFileRoute("/deposit/")({
  component: withDynamicProtected(DepositPage),
});

type InitOnRampOptions = Parameters<typeof initOnRamp>[0];

function useCoinbasePay(destinationWalletAddress: string | null | undefined) {
  const [isReady, setIsReady] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const onrampInstance = useRef<CBPayInstanceType>();

  useEffect(() => {
    if (!destinationWalletAddress) {
      return;
    }

    const options: InitOnRampOptions = {
      appId: import.meta.env.VITE_COINBASE_PAY_APP_ID,
      target: "#cbpay-container",
      widgetParameters: {
        partnerUserId: destinationWalletAddress,
        destinationWallets: [
          {
            address: destinationWalletAddress,
            blockchains: ["solana"],
          },
        ],
      },
      onSuccess: () => {
        console.log("onSuccess");
      },
      onExit: (error) => {
        console.log("onExit", error);
      },
      onEvent: (event) => {
        console.log("onEvent", event);
      },
      experienceLoggedIn: "embedded",
      experienceLoggedOut: "popup",
    };

    // instance.destroy() should be called before initOnramp if there is already an instance.
    if (onrampInstance.current) {
      onrampInstance.current.destroy();
    }

    console.log("initOnRamp");
    initOnRamp(options, (error, instance) => {
      if (error) {
        setError(error.message);
        console.error(error);
      } else if (instance) {
        onrampInstance.current = instance;
        setIsReady(true);
      }
    });
  }, [destinationWalletAddress]);

  const handleOnPress = () => {
    console.log("handleOnPress", onrampInstance);
    onrampInstance.current?.open();
  };

  return {
    handleOnPress,
    isReady,
    error,
  };
}

function DepositPage() {
  const [checked, setChecked] = useState(false);
  const [copied, copy] = useClipboard();
  const walletAddress = useDynamicWallet()?.address;
  const {
    handleOnPress,
    isReady,
    error: coinbaseError,
  } = useCoinbasePay(walletAddress);

  return (
    <Page
      hideTabs
      fullScreenHeight
      title="Deposit options"
      backButton={{
        to: "/portfolio",
        "aria-label": "Back to portfolio",
      }}
    >
      <div className="p-5">
        <p className="text-sm text-gray-200">
          Choose your preferred method to deposit funds.
        </p>

        {/* preload the coinbase pay sdk and image asset to avoid a loading indicator */}
        {!isReady && (
          <button id="cbpay-container" className="hidden" disabled>
            <img src="/assets/coinbase/button.png" />
          </button>
        )}

        <div className="mt-7 w-full h-full bg-gray-600">
          <Drawer.Root>
            <Drawer.Trigger asChild>
              <button className="w-full px-1.5 py-3.5 h-info-row flex justify-between items-center gap-7 border-y border-cloud">
                <div className="flex items-center gap-2">
                  <Icon name="deposit" className="w-5 h-5" />
                  <p className="text-sm text-white font-sans font-bold">
                    Deposit to address
                  </p>
                </div>
                <Icon
                  name="chevron"
                  className="w-4 h-4 text-white -rotate-90"
                />
              </button>
            </Drawer.Trigger>
            <Drawer.Content title="Deposit">
              <p className="text-sm text-gray-200">
                Send assets to your address below. Confirm that you&apos;re
                sending:
              </p>

              <button
                className="mt-3.5 flex items-center gap-2.5"
                aria-label="Toggle solana chain warning"
                onClick={() => {
                  setChecked((v) => !v);
                }}
              >
                <Icon
                  name={checked ? "circle-check" : "circle"}
                  className="w-5 h-5 text-primary"
                />
                <span className="text-sm text-white font-bold">
                  On Solana, not any other chain
                </span>
              </button>

              {walletAddress ? (
                <div className="mt-5 flex flex-col items-center gap-5">
                  <div
                    className={cn(
                      "text-primary flex justify-center items-center",
                      {
                        "blur-sm": !checked,
                      },
                    )}
                  >
                    <QRCode
                      value={walletAddress}
                      size={202}
                      ecLevel="H"
                      logoPadding={0}
                      logoHeight={60}
                      logoWidth={60}
                      removeQrCodeBehindLogo
                      logoPaddingStyle="square"
                      logoImage="/qr-logo.png"
                      bgColor="#1d1e20"
                      fgColor="#FFD02B"
                      qrStyle="dots"
                    />
                  </div>

                  <button
                    type="button"
                    disabled={!checked}
                    aria-label="Copy address"
                    onClick={() => {
                      if (walletAddress) {
                        void copy(walletAddress);
                      }
                    }}
                    className="rounded-full border border-cloud px-5 py-4 flex items-center gap-3 mb-6 text-sm font-bold disabled:text-gray-300"
                  >
                    <p className="ellipsis overflow-hidden">
                      {formatAddressShort(walletAddress)}
                    </p>
                    <Icon
                      name={copied ? "check" : "copy"}
                      className="text-current w-5 h-5 shrink-0"
                    />
                  </button>
                </div>
              ) : null}
            </Drawer.Content>
          </Drawer.Root>
          <Drawer.Root>
            <Drawer.Trigger asChild>
              <button className="w-full px-1.5 py-3.5 h-info-row flex justify-between items-center gap-7 border-y border-t-0 border-cloud">
                <div className="flex items-center gap-2">
                  <Icon name="coinbase" className="w-5 h-5" />
                  <p className="text-sm text-white font-sans font-bold">
                    Deposit from Coinbase
                  </p>
                </div>
                <Icon
                  name="chevron"
                  className="w-4 h-4 text-white -rotate-90"
                />
              </button>
            </Drawer.Trigger>
            <Drawer.Content title="Deposit from coinbase">
              <div className="pb-5 w-full flex flex-col gap-5">
                <p className="text-sm text-gray-200">
                  Complete in the browser and funds will arrive in a few
                  minutes.
                </p>
                <Shimmer.Text className="w-full h-16" isLoading={!isReady}>
                  <button id="cbpay-container" onClick={handleOnPress}>
                    <img
                      className="w-full h-[65px]"
                      src="/assets/coinbase/button.png"
                    />
                  </button>
                </Shimmer.Text>

                {coinbaseError ? (
                  <p className="bg-negative/10 text-negative p-1 text-sm font-bold">
                    {coinbaseError}
                  </p>
                ) : null}
              </div>
            </Drawer.Content>
          </Drawer.Root>
        </div>
      </div>
    </Page>
  );
}
