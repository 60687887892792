import {
  SimulatedTransactionResponse,
  TransactionError,
} from "@solana/web3.js";
import {
  formatErrorMessage,
  formatTxSimError,
  normalizeScaled,
} from "./format";
import { MINIMUM_SOL_BALANCE_LAMPORTS, SOL_DECIMALS } from "./transaction";

export const transactionStatusMessage = {
  failedToFetchLatestBlockhash: "Failed to fetch latest blockhash.",
  failedToSignTransaction: "Failed to sign transaction.",
  notSimulated: "Could not simulate transaction.",
  simulationFailed: (simulationResult: SimulatedTransactionResponse) =>
    `Transaction simulation failed: ${formatTxSimError(simulationResult)}.`,
  unhandledError: (error: unknown) => formatErrorMessage(error),
  transactionFailed:
    "Your transaction failed when executed. The transaction log may contain additional details about the failure.",
  slippageLimitReached:
    "Transaction failed due to slippage exceeding the limit in your settings. To increase chance of transaction success, try again with a higher slippage.",
  transactionExpired: "The transaction expired and was not included on chain.",
  transactionStatusUnknown: "View on explorer to determine transaction status.",
  web3authNotInitialized: "Web3auth not initialized.",
  insufficientSolNative: (
    <>
      You need enough SOL in your account to cover the base fee, priority fee,
      token rent and your SOL input amount. The base fee, priority fee and token
      rent are paid in SOL to the Solana network. You can reduce your priority
      fee to lower the required SOL amount.
      <br />
      <br />
      {normalizeScaled(MINIMUM_SOL_BALANCE_LAMPORTS, SOL_DECIMALS)} SOL is
      reserved for rent if applicable and redeemable when you close out your
      token account.
    </>
  ) as React.ReactNode,
  insufficientSolSpl: (
    <>
      You need enough SOL in your account to cover the base fee, priority fee
      and token rent. The base fee, priority fee and token rent are paid in SOL
      to the Solana network. You can reduce your priority fee to lower the
      required SOL amount.
      <br />
      <br />
      {normalizeScaled(MINIMUM_SOL_BALANCE_LAMPORTS, SOL_DECIMALS)} SOL is
      reserved for rent if applicable and redeemable when you close out your
      token account.
    </>
  ) as React.ReactNode,
  insufficientFunds: (symbol: string) =>
    `You do not have enough ${symbol} in your account.`,
  lowSolBalance:
    "This transaction will leave you with insufficient SOL to place trades.",
};

interface InstructionError {
  InstructionError: [number, { Custom: number }];
}

export function getIsSlippageLimitReached(
  transactionError: TransactionError,
): boolean {
  try {
    const errorCode = (transactionError as InstructionError).InstructionError[1]
      .Custom;
    return errorCode === 15001;
  } catch (error) {
    return false;
  }
}
