import { cn } from "@/utils";
import * as RadixDialog from "@radix-ui/react-dialog";
import type { DialogContentProps as RadixDialogContentProps } from "@radix-ui/react-dialog";
import { cva, type VariantProps } from "class-variance-authority";
import { Icon } from "@/components";

const dialogVariants = cva(
  "select-none fixed w-full flex flex-col max-w-2xl bottom-0 md:bottom-auto md:top-1/2 left-1/2 -translate-x-1/2 md:-translate-y-1/2 bg-gray-600 py-6 overflow-auto motion-safe:animate-slide-up md:animate-pop z-50",
  {
    variants: {
      variant: {
        default:
          "max-h-[85vh] rounded-t-lg md:rounded-lg border-t border-cloud lg:max-w-[380px]",
        // safe-area-inset-bottom + p-6 applied above
        full: "h-viewport pb-[calc(env(safe-area-inset-bottom,0)+1.5rem)]",
      },
      size: {
        default: "",
        med: "max-w-[28rem]",
      },
    },
  },
);

export const overlayClassName =
  "fixed inset-0 bg-black/75 motion-safe:animate-fade-in backdrop-blur-sm z-50";

interface DialogContentProps
  extends RadixDialogContentProps,
    VariantProps<typeof dialogVariants> {
  title: string;
  titleId?: string;
  noPadding?: boolean;
}

export const DialogContent = ({
  children,
  title,
  titleId,
  variant = "default",
  noPadding,
  size = "default",
}: DialogContentProps) => {
  const showCloseBtn = variant === "default";
  const showBackBtn = variant === "full";
  return (
    <RadixDialog.Portal>
      <RadixDialog.Overlay className={overlayClassName} />
      <RadixDialog.Content className={cn(dialogVariants({ variant, size }))}>
        <div className="flex justify-between items-center pb-4 px-5">
          {showBackBtn && (
            <RadixDialog.Close aria-label="Close">
              <Icon name="back-arrow-boxed" className="w-5 text-primary" />
            </RadixDialog.Close>
          )}
          <RadixDialog.Title
            className={cn("text-primary font-heading font-bold text-xl", {
              "flex-1 text-center -ml-5 px-7 -z-10": showBackBtn,
            })}
            id={titleId}
          >
            {title}
          </RadixDialog.Title>
        </div>
        <div className={noPadding ? undefined : "px-5"}>{children}</div>
        {showCloseBtn && (
          <RadixDialog.Close
            aria-label="Close"
            className="absolute top-6 right-6"
          >
            <Icon name="close" className="w-5 text-primary" />
          </RadixDialog.Close>
        )}
      </RadixDialog.Content>
    </RadixDialog.Portal>
  );
};
