import { BaseIconProps } from "../icon";

export function StarHalfIcon(props: BaseIconProps) {
  return (
    <svg
      viewBox="0 0 10 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 0.416687L7.49115 7.71827H0L5.94126 12.3406L3.81989 19.5834L10 15.3122V13.1L7.00106 15.1714L8.03079 11.6561L5.14685 9.54367H8.78273L10 5.8697V0.416687Z"
        fill="currentColor"
      />
    </svg>
  );
}
