/* eslint-disable @typescript-eslint/no-explicit-any */

interface QueryWithMeta {
  meta: {
    arg: {
      queryCacheKey: string;
      endpointName: string;
      originalArgs: any;
    };
    fulfilledTimeStamp: number;
  };
}

export const isQueryWithMeta = (action: unknown): action is QueryWithMeta =>
  typeof action === "object" &&
  Object.prototype.hasOwnProperty.call(action, "meta");
