import { cn } from "@/utils";
import * as RadixSlider from "@radix-ui/react-slider";
import type { SliderProps } from "@radix-ui/react-slider";

export function Slider(props: SliderProps) {
  return (
    <RadixSlider.Root
      {...props}
      className={cn(
        "w-full h-5 relative flex items-center touch-none select-none",
        props.className,
      )}
    >
      <RadixSlider.Track className="relative h-px bg-cloud w-full">
        <RadixSlider.Range className="absolute bg-lime h-full" />
      </RadixSlider.Track>
      {Array.from({ length: 8 }).map((_, index) => (
        <div
          key={index}
          className="absolute w-px h-2.5 top-1/2 -translate-y-1/2 bg-cloud"
          style={{
            left: `${((index + 1) * 100) / 9}%`,
          }}
        />
      ))}
      {props.value?.map((_, index) => (
        <RadixSlider.Thumb
          key={index}
          className="block w-5 h-5 bg-coriander-1000 border border-lime rounded-full shadow-[0px_3px_20px_rgba(0,0,0,0.40)]"
          data-vaul-no-drag
        />
      ))}
    </RadixSlider.Root>
  );
}
