import { Order, SendTransactionArgs, updateTransactionStatus } from "@/api";
import { backgroundTransactionApiActions } from "./background-transaction-api-actions";
import { TransactionErrorMessageProps } from "@/components";
import { REHYDRATE, rehydrateReducer } from "@/store";
import { getIsSlippageLimitReached, transactionStatusMessage } from "@/utils";
import { PayloadAction, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { Transaction, VersionedTransaction } from "@solana/web3.js";

export type SolanaTransactionType = "versioned" | "legacy";

export type TransactionStatus =
  | "sending"
  | "confirming"
  | "succeeded"
  | "failed"
  | "expired"
  | "unknown";

export interface BackgroundTransactionData
  extends Omit<SendTransactionArgs, "transaction"> {
  transactionType: SolanaTransactionType;
  serializedTransaction: string;
  status: TransactionStatus;
  startedTimeStamp: number;
  finishedTimeStamp?: number;
  orderId?: number;
  pendingOrderId?: number;
  error: TransactionErrorMessageProps | null;
  isSaving?: boolean;
}

export type TransactionsRecord = Record<
  string,
  BackgroundTransactionData | undefined
>;

const initialState = {
  transactions: {} as TransactionsRecord,
  hasUnreadTransactions: false,
  transactionUpdates: [] as BackgroundTransactionData[],
};

const backgroundTransactionsSlice = createSlice({
  name: "backgroundTransactions",
  initialState,
  reducers: {
    [REHYDRATE]: rehydrateReducer,
    removePendingTransaction(state, action: PayloadAction<string>) {
      if (state.transactions[action.payload]) {
        delete state.transactions[action.payload];
      }
    },
    clearFinishedTransactions(state) {
      const pendingOnly = Object.entries(state.transactions).filter(
        ([, tx]) => tx?.status === "sending" || tx?.status === "confirming",
      );
      state.transactions = Object.fromEntries(pendingOnly);
    },
    markTransactionsRead(state) {
      state.hasUnreadTransactions = false;
    },
    pushTransactionUpdate(
      state,
      action: PayloadAction<BackgroundTransactionData>,
    ) {
      addTransactionUpdate(state, action.payload);
    },
    popTransactionUpdate(state) {
      state.transactionUpdates.pop();
    },
    // Used when already viewing a transaction in pending order page
    removeUpdatesForSignature(state, action: PayloadAction<string>) {
      state.transactionUpdates = state.transactionUpdates.filter(
        (tx) => tx.signature !== action.payload,
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateTransactionStatus, (state, action) => {
      const { signature, status } = action.payload;
      const tx = state.transactions[signature];
      if (tx) {
        if (status === "confirming" && tx.status !== "sending") {
          // We dispatch this action after a short delay when the transaction
          // transitions from "sending" to "confirming" so that "sending" stays
          // visible for long enough to be noticeable. Due to the delay, the
          // transaction may enter a state after "confirming" before the
          // "confirming" action is dispatched. This check ensures that if this
          // happens, we don't incorrectly overwrite the later state with
          // "confirming".
          return;
        }
        tx.status = status;
        const updatedTx = { ...tx, status };
        addTransactionUpdate(state, updatedTx);
      }
    });
    builder.addMatcher(
      backgroundTransactionApiActions.transactionPending,
      (state, action) => {
        const transactionArgs = action.meta.arg.originalArgs;
        const { signature, transaction } = transactionArgs;
        const { type, serializedTransaction } =
          serializeTransaction(transaction);
        const existingTx = state.transactions[signature];
        state.hasUnreadTransactions = true;

        const tx = {
          ...transactionArgs,
          status: "sending" as const,
          transactionType: type,
          serializedTransaction,
          startedTimeStamp: action.meta.startedTimeStamp,
          pendingOrderId: existingTx?.pendingOrderId,
          error: null,
        };
        state.transactions[signature] = tx;
        addTransactionUpdate(state, tx);
      },
    );
    builder.addMatcher(
      backgroundTransactionApiActions.pendingOrderSaved,
      (state, action) => {
        const signature = action.meta.arg.originalArgs.transactionSignature;
        const tx = state.transactions[signature];
        if (!tx) return;

        tx.pendingOrderId = action.payload.data.id;
        const idx = state.transactionUpdates.findIndex(
          (tx) => tx.signature === signature,
        );
        if (idx > -1) {
          state.transactionUpdates[idx].pendingOrderId = action.payload.data.id;
        }
      },
    );
    builder.addMatcher(
      backgroundTransactionApiActions.transactionFulfilled,
      (state, action) => {
        const { signature } = action.meta.arg.originalArgs;
        const result = action.payload;
        const tx = state.transactions[signature];
        if (!tx) return;
        tx.isSaving = true;
        if (result.status === "success") {
          tx.status = "succeeded";
        } else {
          tx.status = result.status;
          switch (result.status) {
            case "failed":
              if (getIsSlippageLimitReached(result.transactionError)) {
                tx.error = {
                  title: "SLIPPAGE LIMIT REACHED",
                  message: transactionStatusMessage.slippageLimitReached,
                  color: "negative",
                  feeStatus: "charged",
                };
              } else {
                tx.error = {
                  title: "TRANSACTION FAILED",
                  message: transactionStatusMessage.transactionFailed,
                  color: "negative",
                  feeStatus: "charged",
                };
              }
              break;
            case "expired":
              tx.isSaving = false;
              tx.error = {
                title: "TRANSACTION EXPIRED",
                message: transactionStatusMessage.transactionExpired,
                color: "negative",
                feeStatus: "not_charged",
              };
              break;
            case "unknown":
              tx.error = {
                title: "TRANSACTION STATUS UNKNOWN",
                message: transactionStatusMessage.transactionStatusUnknown,
                color: "negative",
                feeStatus: "unknown",
              };
              break;
            default: {
              const _exhaustiveCheck: never = result;
            }
          }
        }
        addTransactionUpdate(state, tx);
      },
    );
    builder.addMatcher(
      backgroundTransactionApiActions.transactionRejected,
      (state, action) => {
        const { signature } = action.meta.arg.originalArgs;
        const tx = state.transactions[signature];
        if (tx) {
          tx.status = "failed";
          tx.error = {
            title: "TRANSACTION STATUS UNKNOWN",
            message: transactionStatusMessage.transactionStatusUnknown,
            color: "negative",
            feeStatus: "unknown",
          };
          addTransactionUpdate(state, tx);
        }
      },
    );
    builder.addMatcher(
      isAnyOf(
        backgroundTransactionApiActions.completedOrderSaved,
        backgroundTransactionApiActions.completedOrderRejected,
      ),
      (state, action) => {
        const { signature } = action.meta.arg.originalArgs;
        const tx = state.transactions[signature];
        if (!tx) return;
        tx.isSaving = false;
        tx.finishedTimeStamp = Date.now();
        const data = Array.isArray(action.payload?.data)
          ? (action.payload.data as Order[])
          : [];
        const order = data.at(0);
        if (order) {
          tx.orderId = order.id;
        }
      },
    );
  },
});

export const {
  removePendingTransaction,
  clearFinishedTransactions,
  markTransactionsRead,
  pushTransactionUpdate,
  popTransactionUpdate,
  removeUpdatesForSignature,
} = backgroundTransactionsSlice.actions;
export const backgroundTransactionsReducer =
  backgroundTransactionsSlice.reducer;
export const backgroundTransactionsActions =
  backgroundTransactionsSlice.actions;
export const backgroundTransactionsName = backgroundTransactionsSlice.name;

function serializeTransaction(
  transaction: VersionedTransaction | Transaction,
): { type: SolanaTransactionType; serializedTransaction: string } {
  const serializedTransaction = Buffer.from(transaction.serialize()).toString(
    "base64",
  );
  return transaction instanceof VersionedTransaction
    ? {
        type: "versioned",
        serializedTransaction,
      }
    : {
        type: "legacy",
        serializedTransaction,
      };
}

function addTransactionUpdate(
  state: typeof initialState,
  tx: BackgroundTransactionData,
) {
  const idx = state.transactionUpdates.findIndex(
    (t) => t.signature === tx.signature,
  );
  if (idx > -1) {
    state.transactionUpdates.splice(idx, 1);
  }
  state.transactionUpdates.push(tx);
}
