import { TokenInfoWithPrice, useTokenInfoQuery } from "@/api";
import {
  TransactionErrorMessageProps,
  Icon,
  Shimmer,
  TokenIcon,
} from "@/components";
import { useUserSolType } from "@/hooks";
import { OrderLineItem } from "@/routes/trade/confirm/-order-line-item";
import {
  cn,
  formatAbsolutePercent,
  formatExecution,
  formatPlatformFeePercent,
  formatTokenNumber,
  formatUsd,
  getExecution,
  getSymbolWithDefault,
  normalizeScaled,
} from "@/utils";

const unknownToken: TokenInfoWithPrice = {
  address: "",
  symbol: undefined,
  name: "Unknown Token",
  logoURI: undefined,
  decimals: 0,
  price: null,
  priceChange24h: null,
  updateUnixTime: 0,
  verified: false,
};

interface BaseProps {
  isFetching: boolean;
  isError: boolean;
  inAmount: string;
  outAmount: string;
  platformFeeBps: number;
  inTokenMint: string;
  outTokenMint: string;
  TransactionErrorMessage?: TransactionErrorMessageProps | undefined;
}
type PropsType = "quote" | "completed_order" | "error_order";

interface TradeDetailsQuoteProps extends BaseProps {
  type: "quote";
  priceImpactPct: number;
}

interface TradeDetailsCompletedOrderProps extends BaseProps {
  type: "completed_order" | "error_order";
  priceImpactPct?: never;
}

type TradeDetailsProps<T extends PropsType> = T extends "quote"
  ? TradeDetailsQuoteProps
  : TradeDetailsCompletedOrderProps;

function TradeDetailsInner<T extends PropsType>({
  type,
  isFetching,
  isError,
  inAmount,
  outAmount,
  platformFeeBps,
  inTokenMint,
  outTokenMint,
  priceImpactPct,
}: TradeDetailsProps<T>) {
  const solType = useUserSolType();
  const inTokenQuery = useTokenInfoQuery({
    address: inTokenMint,
    withPrice: true,
    solType,
  });
  const outTokenQuery = useTokenInfoQuery({
    address: outTokenMint,
    withPrice: true,
    solType,
  });
  const inToken = inTokenQuery.data ?? unknownToken;
  const outToken = outTokenQuery.data ?? unknownToken;
  const execution = getExecution(
    inAmount,
    outAmount,
    inToken.decimals,
    outToken.decimals,
  );

  const priceImpactUsd =
    type === "quote" && outToken.price
      ? priceImpactPct *
        Number(normalizeScaled(outAmount, outToken.decimals)) *
        outToken.price
      : null;

  if (inTokenQuery.isLoading || outTokenQuery.isLoading) {
    return <LoadingSkeleton />;
  }

  const orderLineItems = {
    ...(!isError && {
      executionPrice: (
        <li>
          <OrderLineItem
            label={type === "completed_order" ? "Execution Price" : "MKT Price"}
            value={
              <Shimmer.Text
                className="w-[100px] h-[20px]"
                isLoading={isFetching}
              >
                {formatExecution(execution, inToken, outToken)}
              </Shimmer.Text>
            }
            description={
              type === "completed_order" ? undefined : (
                <div className="text-gray-200 text-sm flex flex-col gap-3.5 pb-4">
                  <p>
                    Market price represents the optimal current price for the
                    token you intend to buy or sell. DFlow sources liquidity
                    from across the Solana network and routes through unique
                    on-chain sources, ensuring you benefit from the best prices.
                  </p>
                  <p className="font-bold flex justify-between py-3 border-y border-cloud items-center">
                    <span>
                      {getSymbolWithDefault(outToken, true)} BEST PRICE
                    </span>
                    <span>{formatUsd(outToken.price)}</span>
                  </p>
                </div>
              )
            }
          />
        </li>
      ),
    }),
    ...(type === "quote" && {
      priceImpact: (
        <li>
          <OrderLineItem
            label="PRICE IMPACT"
            value={
              <Shimmer.Text
                className="w-[100px] h-[20px]"
                isLoading={isFetching}
              >
                {formatUsd(priceImpactUsd, {
                  showFractionTruncatedMark: true,
                })}{" "}
                ({formatAbsolutePercent(priceImpactPct * 100)})
              </Shimmer.Text>
            }
            description="Price impact measures the expected price deviation from the current best market price and is determined by the size of your trade in relation to the market's available liquidity."
          />
        </li>
      ),
    }),
  };

  const formattedBuyAmount = `${formatTokenNumber(
    normalizeScaled(outAmount, outToken.decimals),
    outToken.decimals,
    { decimalsMode: "fixed" },
  )} ${getSymbolWithDefault(outToken)}`;

  return (
    <>
      <div className="flex flex-col gap-5 pb-4">
        <div className="flex justify-between items-center relative">
          <div className="flex flex-col">
            <span
              className={cn("text-sm font-bold text-primary uppercase", {
                "text-negative": isError,
              })}
            >
              {type === "completed_order" ? "Sold" : "Sell"}
            </span>
            <span className="text-md text-white font-bold">
              {normalizeScaled(inAmount, inToken.decimals)}{" "}
              {getSymbolWithDefault(inToken)}
            </span>
          </div>

          <TokenIcon
            logoURI={inToken.logoURI}
            className="rounded-full w-6 h-6"
          />
          <Icon
            name="arrow-slim"
            className={cn(
              "w-5 h-7 text-gray-200 absolute right-0.5 -bottom-6",
              {
                "text-negative": isError,
              },
            )}
          />
        </div>

        <div className="flex justify-between items-center">
          <div className="flex flex-col">
            <span
              className={cn("text-sm font-bold text-primary uppercase", {
                "text-negative": isError,
              })}
            >
              {type === "completed_order" ? "Bought" : "Buy"}
            </span>
            <Shimmer.Text className="w-[100px] h-[24px]" isLoading={isFetching}>
              <span className="text-md text-white font-bold">
                {formattedBuyAmount}
              </span>
            </Shimmer.Text>
          </div>

          <TokenIcon
            logoURI={outToken.logoURI}
            className="rounded-full w-6 h-6"
          />
        </div>
      </div>

      {Object.keys(orderLineItems).length > 0 && (
        <div className="border-t border-cloud py-4">
          <ul className="flex flex-col gap-2.5">
            {orderLineItems.executionPrice}
            {orderLineItems.priceImpact}
          </ul>
        </div>
      )}

      <div className="flex flex-col py-4 border-t border-cloud">
        {type === "completed_order" ? (
          <span className="text-sm font-bold text-primary uppercase">
            Total received
          </span>
        ) : (
          <OrderLineItem
            label="ESTIMATED TO RECEIVE"
            value={undefined}
            description={
              <div className="text-gray-200 text-sm flex flex-col gap-3.5 pb-4">
                <p>
                  The estimated amount you will receive after fees. DFlow
                  applies a platform fee per trade.
                </p>
                <p className="flex gap-2 py-3 items-center">
                  <span className="font-bold text-primary uppercase">
                    PLATFORM FEE
                  </span>
                  <span>{formatPlatformFeePercent(platformFeeBps)}</span>
                </p>
              </div>
            }
          />
        )}
        <span className="text-2xl text-white font-semibold">
          <Shimmer.Text isLoading={isFetching} className="w-[100px] h-[48px]">
            {formattedBuyAmount}
          </Shimmer.Text>
        </span>
      </div>
    </>
  );
}

const LoadingSkeleton = () => (
  <div role="status" className="flex flex-col animate-pulse">
    <div className="py-3">
      <div className="h-2 rounded-full bg-gray-500 max-w-[60px] mb-2.5"></div>
      <div className="h-2 rounded-full bg-gray-500 max-w-[120px] mb-2.5"></div>
    </div>
    <div className="py-3 border-b border-cloud">
      <div className="h-2 rounded-full bg-gray-500 max-w-[60px] mb-2.5"></div>
      <div className="h-2 rounded-full bg-gray-500 max-w-[120px] mb-2.5"></div>
    </div>
    <div className="py-4 border-b border-cloud">
      <div className="h-2 rounded-full bg-gray-500 max-w-[330px] mb-4"></div>
      <div className="h-2 rounded-full bg-gray-500 max-w-[300px] mb-4"></div>
      <div className="h-2 rounded-full bg-gray-500 max-w-[360px] mb-4"></div>
      <div className="h-2 rounded-full bg-gray-500 max-w-[320px] mb-4"></div>
      <div className="h-2 rounded-full bg-gray-500 max-w-[260px]"></div>
    </div>
    <div className="py-4 border-b border-cloud">
      <div className="h-2 rounded-full bg-gray-500 max-w-[80px] mb-4"></div>
      <div className="h-6 rounded-md bg-gray-500 max-w-[120px]"></div>
    </div>
    <span className="sr-only">Loading...</span>
  </div>
);

export const TradeDetails = Object.assign(TradeDetailsInner, {
  LoadingSkeleton,
});
