import {
  useQuickTradeSettingsQuery,
  useUpdateQuickTradeSettingsMutation,
  useUpdateSettingsMutation,
  useUserQuery,
} from "@/api";
import { useAppDispatch, useTypedSelector } from "@/store";
import { formatNumberInput, truncateZeroDecimals } from "@/utils";
import { useIsLoggedIn } from "@dynamic-labs/sdk-react-core";
import { skipToken } from "@reduxjs/toolkit/query";
import { useEffect, useRef, useState } from "react";
import { updateSlippage } from "../-reducer";
import { ToggleGroupData } from "../-toggle-group";
import { compareEquality } from "../-util/compare";
import { getSlippageWarningMessage, isValidSlippageRange } from "./-utils";

const slippageOptions: ToggleGroupData[] = [
  { label: "0.5%", value: "0.5" },
  { label: "1%", value: "1" },
  { label: "3%", value: "3" },
];

const quickTradeSlippageOptions: ToggleGroupData[] = [
  { label: "5%", value: "5" },
  { label: "10%", value: "10" },
  { label: "15%", value: "15" },
];

export type SlippageFormProps = Omit<
  ReturnType<typeof useSlippageForm>,
  "onSaveSlippage"
> & {
  className?: string;
};

const getSplippageStateInitialValue = (
  slippage: string,
  options: ToggleGroupData[],
) => {
  const isPresetSelected = options.some((o) => o.value === slippage);
  return {
    value: slippage,
    isPresetSelected,
    showSuffix: !isPresetSelected,
  };
};

export function useBaseSlippageForm(
  slippage: number,
  isLoading: boolean,
  options: ToggleGroupData[],
) {
  const uiSlippage = truncateZeroDecimals((slippage / 100).toFixed(2));
  const [slippageState, setSlippageState] = useState(
    getSplippageStateInitialValue(uiSlippage, options),
  );

  const prevIsLoading = useRef(isLoading);
  useEffect(() => {
    if (prevIsLoading.current && !isLoading) {
      if (slippageState.value !== uiSlippage) {
        setSlippageState(getSplippageStateInitialValue(uiSlippage, options));
      }
    }

    prevIsLoading.current = isLoading;
  }, [isLoading, slippageState.value, uiSlippage, options]);

  const saveDisabled =
    compareEquality(uiSlippage, slippageState.value, 2) ||
    !isValidSlippageRange(slippageState.value);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formattedValue = formatNumberInput(event.target.value, {
      currency: "bps",
      tokenDecimals: 2,
    });
    if (isValidSlippageRange(formattedValue)) {
      setSlippageState((prevState) => ({
        ...prevState,
        value: formattedValue,
        isPresetSelected: false,
        showSuffix: true,
      }));
    }
  };

  const handleToggleGroup = (value: string) => {
    setSlippageState((prevState) => ({
      ...prevState,
      value,
      isPresetSelected: true,
      showSuffix: false,
    }));
  };

  const handleInputFocus = () => {
    setSlippageState((current) => ({
      ...current,
      showSuffix: true,
    }));
  };

  return {
    slippageState,
    uiSlippage,
    saveDisabled,
    handleToggleGroup,
    handleInputFocus,
    handleInputChange,
  };
}

export function useSlippageForm() {
  const isLoggedIn = useIsLoggedIn();
  const userQuery = useUserQuery(isLoggedIn ? undefined : skipToken);
  const [settingsMutate, settingsMutation] = useUpdateSettingsMutation();
  const slippage = useTypedSelector((state) => state.profile.slippage);

  const formProps = useBaseSlippageForm(
    slippage,
    userQuery.isLoading,
    slippageOptions,
  );

  const dispatch = useAppDispatch();

  const onSaveSlippage = () => {
    if (settingsMutation.isLoading || formProps.saveDisabled) return;

    dispatch(updateSlippage(formProps.slippageState.value));
    if (isLoggedIn) {
      const basisPoints = Number(formProps.slippageState.value) * 100;
      return settingsMutate({ slippageSetting: basisPoints });
    }
  };

  return {
    ...formProps,
    isLoading: userQuery.isLoading,
    mutationError: userQuery.error,
    onSaveSlippage,
    isSaving: settingsMutation.isLoading,
    slippageOptions,
    warning: getSlippageWarningMessage(formProps.slippageState.value),
  };
}

export function useQuickTradeSlippageForm() {
  const isLoggedIn = useIsLoggedIn();
  const quickTradeSettingsQuery = useQuickTradeSettingsQuery(
    isLoggedIn ? undefined : skipToken,
  );
  const [, quickTradeSettingsMutation] = useUpdateQuickTradeSettingsMutation();
  const slippage = quickTradeSettingsQuery.data?.settings?.slippageBps ?? 50;
  const formProps = useBaseSlippageForm(
    slippage,
    quickTradeSettingsQuery.isLoading,
    quickTradeSlippageOptions,
  );

  return {
    ...formProps,
    isLoading: quickTradeSettingsQuery.isLoading,
    mutationError: quickTradeSettingsQuery.error,
    isSaving: quickTradeSettingsMutation.isLoading,
    slippageOptions: quickTradeSlippageOptions,
    warning: getSlippageWarningMessage(formProps.slippageState.value, 15),
  };
}
