import {
  addTokensToCache,
  getTokenInfoFromCache,
  isTokenInfoComplete,
} from "@/app/services/util";
import { CachedTokenInfo } from "@/app/token-cache/token-cache-slice";
import { NATIVE_SOL_UNIQUE_ADDRESS } from "@/app/token-cache/token-constants";
import { Timeframe } from "@/routes/trade/-token/token-price";
import { SOL_ADDRESS } from "@/utils";
import {
  BaseQueryFn,
  SkipToken,
  TypedUseQueryHookResult,
} from "@reduxjs/toolkit/query/react";
import { api } from "./api";

export interface TokenInfoParams<P extends boolean> {
  address: string;
  solType: SolType;
  withPrice?: P;
}

interface TokenInfoByAddressParams<P extends boolean> {
  listAddresses: string[];
  withPrices?: P;
  solType?: SolType;
}

interface TokenSecurityHolder {
  address: string;
  amount: string;
}

export interface TokenSecurity {
  address: string;
  creation_time: number | null;
  holders: TokenSecurityHolder[];
  holders_total_amounts: number;
  mintable: boolean;
  freezable: boolean;
  total_burnt: number | null;
  total_supply_lp: number | null;
}

export interface TokenInfo {
  address: string;
  decimals: number;
  logoURI?: string;
  name?: string | null;
  symbol?: string | null;
  verified: boolean;
}

export interface TokenInfoWithPrice extends TokenInfo {
  price?: number | null;
  priceChange24h?: number | null;
  updateUnixTime?: number | null;
}

interface TokenPrice {
  value: number;
  updateUnixTime: number;
}

interface TokenPriceQueryData extends TokenPrice {
  priceChange24h: number;
  updateHumanTime: string;
}

export interface TokenOverview {
  decimals: number;
  symbol: string;
  name: string;
  price: number;
  mc: number | null;
  supply: number | null;
  holder: number | null;
  liquidity: number | null;
  uniqueWallet24h: number | null;
  priceChange30mPercent: number | null;
  v30mUSD: number | null;
  trade30m: number | null;
  vBuy30mUSD: number | null;
  vSell30mUSD: number | null;
  buy30m: number | null;
  sell30m: number | null;
  priceChange2hPercent: number | null;
  v2hUSD: number | null;
  trade2h: number | null;
  vBuy2hUSD: number | null;
  vSell2hUSD: number | null;
  buy2h: number | null;
  sell2h: number | null;
  priceChange4hPercent: number | null;
  v4hUSD: number | null;
  trade4h: number | null;
  vBuy4hUSD: number | null;
  vSell4hUSD: number | null;
  buy4h: number | null;
  sell4h: number | null;
  priceChange24hPercent: number | null;
  v24hUSD: number | null;
  trade24h: number | null;
  vBuy24hUSD: number | null;
  vSell24hUSD: number | null;
  buy24h: number | null;
  sell24h: number | null;
}

export interface Ohlcv {
  c: number;
  h: number;
  l: number;
  o: number;
  unixTime: number;
  v: number;
}

interface BaseQuoteItem {
  c: number;
  h: number;
  l: number;
  o: number;
  unixTime: number;
  vBase: number;
  vQuote: number;
}

const TOKEN_API_BASE_URL = import.meta.env.VITE_TOKEN_API_BASE_URL;

export interface HistorialPriceItem {
  unixTime: number;
  value: number;
}

export interface SearchTokenParams {
  filter?: string;
  page?: number;
  solType: SolType;
  list_address?: string[];
}

export type SolType = "sol" | "wsol" | "any";

const SEARCH_DATA_TTL = 10 * 60; // 10 minutes in seconds

export const tokenApi = api.injectEndpoints({
  endpoints: (builder) => ({
    tokens: builder.query<TokensResponse, SearchTokenParams>({
      query: ({ filter, ...params }) => {
        return {
          url: `${TOKEN_API_BASE_URL}v1/tokens`,
          params: {
            ...params,
            ...(filter && { filter }),
          },
        };
      },

      serializeQueryArgs(params) {
        const { page: _, filter, list_address, solType } = params.queryArgs;
        const obj = { filter, list_address, solType };
        return `${params.endpointName}(${JSON.stringify(obj, Object.keys(obj).sort())})`;
      },

      merge: (existing, incoming) => {
        existing.data.push(...incoming.data);
        existing.currentPage = incoming.currentPage;
        existing.count += incoming.count;
        existing.nextPage = incoming.nextPage;
      },

      // This keeps the data for the specified time when offscreen
      keepUnusedDataFor: SEARCH_DATA_TTL,

      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.page !== previousArg?.page;
      },

      transformResponse: (data: TokensResponse, _, params) => {
        const { currentPage, count, nextPage } = data;
        return {
          currentPage,
          nextPage,
          count,
          data: data.data.map((token) => {
            if (token.address !== SOL_ADDRESS || params.solType === "any") {
              return token;
            }

            return {
              ...token,
              symbol: params.solType === "wsol" ? "WSOL" : "SOL",
              name: params.solType === "wsol" ? "Wrapped Solana" : "Solana",
            };
          }),
        };
      },
    }),

    tokensByAddress: builder.query<
      Record<string, TokenInfoWithPrice>,
      TokenInfoByAddressParams<boolean>
    >({
      async queryFn({ listAddresses, withPrices = false, solType = "wsol" }) {
        if (listAddresses.length === 0) {
          return { data: {} };
        }
        const promises = [];
        const aggregatedMap = {} as Record<string, TokenInfoWithPrice>;
        const chunkSize = 100;
        for (let i = 0; i < listAddresses.length; i += chunkSize) {
          const chunk = listAddresses.slice(i, i + chunkSize);
          const query = new URLSearchParams();
          query.set("list_address", chunk.join(","));
          query.set("with_prices", `${withPrices}`);
          promises.push(
            (async () => {
              try {
                const response = await fetch(
                  `${TOKEN_API_BASE_URL}tokens_by_address?${query}`,
                );

                const data = (await response.json()) as {
                  data?: Record<string, TokenInfoWithPrice>;
                  error?: unknown;
                };
                if (data.error) {
                  return { error: data };
                }

                // Patch SOL address entry as the requested solType
                if (data.data?.[SOL_ADDRESS]) {
                  if (solType === "sol") {
                    data.data[SOL_ADDRESS].symbol = "SOL";
                    data.data[SOL_ADDRESS].name = "Solana";
                  } else {
                    data.data[SOL_ADDRESS].symbol = "WSOL";
                  }
                }

                return { data: data.data };
              } catch (error) {
                return { error };
              }
            })(),
          );
        }
        const results = await Promise.all(promises);
        for (const result of results) {
          if (result.data) {
            for (const [address, token] of Object.entries(result.data)) {
              aggregatedMap[address] = token;
            }
          }
        }
        if (Object.keys(aggregatedMap).length === 0) {
          return {
            error: {
              status: 400,
              data: "Request failed with no data returned",
            },
          };
        }
        return { data: aggregatedMap };
      },
    }),

    tokenSearchPrices: builder.query<
      TokenSearchPricesResponse,
      Omit<SearchTokenParams, "solType">
    >({
      query: (params) => ({
        url: `${TOKEN_API_BASE_URL}v1/token_prices`,
        params,
      }),

      serializeQueryArgs(params) {
        const { page: _, filter, list_address } = params.queryArgs;
        const obj = { filter, list_address };
        return `${params.endpointName}(${JSON.stringify(obj, Object.keys(obj).sort())})`;
      },

      merge: (existing, incoming) => {
        existing.currentPage = incoming.currentPage;
        existing.count += incoming.count;
        existing.nextPage = incoming.nextPage;
        Object.assign(existing.data, incoming.data);
      },

      // This must match the token query keepUnusedDataFor
      // duration to avoid stale or unavailable price data
      keepUnusedDataFor: SEARCH_DATA_TTL,

      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.page !== previousArg?.page;
      },
    }),

    tokenPricesByAddress: builder.query<TokenPriceMap, string[]>({
      query: (addresses) => ({
        url: `${TOKEN_API_BASE_URL}v1/token_prices`,
        params: { list_address: addresses, no_pagination: true },
      }),
    }),

    priceChart: builder.query<
      HistorialPriceItem[] | null,
      { address: string; compare?: string; mode: Timeframe["label"] }
    >({
      query: ({ address, compare, mode }) => {
        const formattedMode = mode === "24H" ? "24HR" : mode;
        if (compare) {
          return {
            url: `${TOKEN_API_BASE_URL}price_chart/compare`,
            params: {
              baseAddress: address,
              quoteAddress: compare,
              mode: formattedMode,
            },
          };
        } else {
          return {
            url: `${TOKEN_API_BASE_URL}price_chart`,
            params: { address, mode: formattedMode },
          };
        }
      },
      transformResponse: (rawResult: { items: HistorialPriceItem[] }) => {
        return rawResult.items;
      },
    }),

    tokenInfo: builder.query<TokenInfo | null, TokenInfoParams<boolean>>({
      async queryFn(
        { address, withPrice, solType },
        { getState },
        _e,
        baseQuery,
      ) {
        try {
          let cachedValue: CachedTokenInfo | undefined;
          if (!withPrice) {
            const key =
              solType === "sol" && address === SOL_ADDRESS
                ? NATIVE_SOL_UNIQUE_ADDRESS
                : address;
            cachedValue = getTokenInfoFromCache(getState, key);
            // If we have a complete cached value, return it
            if (cachedValue && isTokenInfoComplete(cachedValue)) {
              return {
                data: {
                  address: cachedValue.address,
                  decimals: cachedValue.decimals,
                  logoURI: cachedValue.logoURI,
                  name: cachedValue.name,
                  symbol: cachedValue.symbol,
                  verified: cachedValue.verified,
                },
              };
            }
          }
          const response = await baseQuery({
            url: `${TOKEN_API_BASE_URL}token_info`,
            params: { address, with_price: withPrice },
          });
          // If we have a 404 error and an incomplete cached value, return the incomplete value instead of error
          // This is primarily for the case where a user owns a token that is not yet in the token data service
          if (
            "error" in response &&
            response.error?.status === 404 &&
            cachedValue
          ) {
            return {
              data: {
                address: cachedValue.address,
                decimals: cachedValue.decimals,
                logoURI: cachedValue.logoURI,
                name: cachedValue.name,
                symbol: cachedValue.symbol,
                verified: cachedValue.verified,
              },
            };
          }

          if ("error" in response) {
            return {
              error: response.error ?? {
                status: 500,
                data: "Unknown error",
              },
            };
          }
          const data = (response.data ?? null) as TokenInfo | null;
          // Patch SOL address entry as the requested solType
          if (data?.address === SOL_ADDRESS) {
            if (solType === "wsol") {
              data.symbol = "WSOL";
              data.name = "Wrapped Solana";
            } else if (solType === "sol") {
              data.symbol = "SOL";
              data.name = "Solana";
            }
          }

          return { data: (response.data ?? null) as TokenInfo | null };
        } catch (error) {
          return {
            error: {
              status: 500,
              data: (error as Error).message,
            },
          };
        }
      },

      async onCacheEntryAdded(_args, { dispatch, cacheDataLoaded }) {
        const { data } = await cacheDataLoaded;
        if (data) {
          addTokensToCache(dispatch, [data]);
        }
      },
    }),

    tokenOverview: builder.query<
      TokenOverview | null,
      { address: string; solType: SolType }
    >({
      query: ({ address }) => ({
        url: `${TOKEN_API_BASE_URL}token_overview`,
        params: { address },
      }),
      transformResponse: (data: TokenOverview | null, _, params) => {
        if (data && params.address === SOL_ADDRESS) {
          if (params.solType === "wsol") {
            data.symbol = "WSOL";
            data.name = "Wrapped Solana";
          } else if (params.solType === "sol") {
            data.symbol = "SOL";
            data.name = "Solana";
          }
        }

        return data;
      },
    }),

    tokenPrice: builder.query<TokenPriceQueryData | null, string>({
      query: (address) => ({
        url: `${TOKEN_API_BASE_URL}price`,
        params: { address },
      }),
    }),

    ohlcv: builder.query<Ohlcv | null, string>({
      query: (address) => ({
        url: `${TOKEN_API_BASE_URL}ohlcv`,
        params: { address, type: "1D" },
      }),
      transformResponse: (rawResult: { items: Ohlcv[] }) => {
        return rawResult.items[0];
      },
    }),

    baseQuotePrice: builder.query<
      TokenPrice | null,
      { baseAddress: string; quoteAddress: string }
    >({
      query: ({ baseAddress, quoteAddress }) => ({
        url: `${TOKEN_API_BASE_URL}ohlcv/base_quote`,
        params: {
          base_address: baseAddress,
          quote_address: quoteAddress,
          type: "1D",
        },
      }),
      transformResponse: (rawResult: { items: BaseQuoteItem[] }) => {
        const data = rawResult.items[0];
        const value = data.c;
        const unixTime = data.unixTime;
        return { value, updateUnixTime: unixTime };
      },
    }),

    tokenSecurity: builder.query<TokenSecurity | null, string>({
      query: (address) => ({
        url: `${TOKEN_API_BASE_URL}token_security`,
        params: { address },
      }),
    }),

    trendingTokens: builder.query<TokenInfoWithPriceAndRank[], void>({
      query: () => `${TOKEN_API_BASE_URL}trending_tokens`,
    }),
  }),
});

interface PaginationData {
  currentPage: number;
  nextPage: number | null;
  count: number;
}

interface TokensResponse extends PaginationData {
  data: TokenInfo[];
}

export type TokenPriceMap = Record<
  string,
  | {
      value: number | null;
      priceChange24h: number | null;
      updateUnixTime: number | null;
    }
  | undefined
>;

interface TokenSearchPricesResponse extends PaginationData {
  data: TokenPriceMap;
}

export interface TokenInfoWithPriceAndRank extends TokenInfoWithPrice {
  rank: number;
}

export type UseTokenOverviewQueryResult = TypedUseQueryHookResult<
  (typeof tokenApi.endpoints.tokenOverview.Types)["ResultType"],
  (typeof tokenApi.endpoints.tokenOverview.Types)["QueryArg"],
  (typeof tokenApi.endpoints.tokenOverview.Types)["BaseQuery"]
>;
export type UsePriceChartQueryResult = TypedUseQueryHookResult<
  (typeof tokenApi.endpoints.priceChart.Types)["ResultType"],
  (typeof tokenApi.endpoints.priceChart.Types)["QueryArg"],
  (typeof tokenApi.endpoints.priceChart.Types)["BaseQuery"]
>;

/** These hooks have generics for parameter-based server responses */
export const useTokenInfoQuery = tokenApi.useTokenInfoQuery as <
  P extends boolean,
>(
  params: TokenInfoParams<P> | SkipToken,
  options?: Parameters<typeof tokenApi.useTokenInfoQuery>[1],
) => TypedUseQueryHookResult<
  P extends true ? TokenInfoWithPrice : TokenInfo,
  TokenInfoParams<P>,
  BaseQueryFn
>;

export const useTokensByAddressQuery = tokenApi.useTokensByAddressQuery as <
  P extends boolean,
>(
  params: TokenInfoByAddressParams<P> | SkipToken,
  options?: Parameters<typeof tokenApi.useTokensByAddressQuery>[1],
) => TypedUseQueryHookResult<
  Record<
    string,
    P extends true ? TokenInfoWithPrice | undefined : TokenInfo | undefined
  >,
  string[] | TokenInfoByAddressParams<P>,
  BaseQueryFn
>;

/** */

export const {
  useTokensQuery,
  useTokenSearchPricesQuery,
  useTokenPricesByAddressQuery,
  usePriceChartQuery,
  useTokenOverviewQuery,
  useTokenPriceQuery,
  useBaseQuotePriceQuery,
  useOhlcvQuery,
  useTokenSecurityQuery,
  useTrendingTokensQuery,
} = tokenApi;
