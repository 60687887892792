export const CU_LIMIT_WRAP_SOL = 35000;
export const CU_LIMIT_UNWRAP_SOL = 5000;
export const CU_LIMIT_WITHDRAW_SPL_TOKEN = 40000;
export const CU_LIMIT_WITHDRAW_NATIVE_SOL = 1000;

export function calculateComputeUnitPriceMicroLamports(
  prioritizationFeeLamports: string,
  computeUnitLimit: number,
): bigint {
  return (
    (BigInt(prioritizationFeeLamports) * 10n ** 6n) / BigInt(computeUnitLimit)
  );
}
