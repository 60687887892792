import { Button, ButtonRow, Icon, Page, WalletCard } from "@/components";
import { with404 } from "@/hocs/with404";
import { withDynamicProtected } from "@/hocs/withDynamicProtected";
import { useDynamicWallet } from "@/hooks";
import { useMigrationStatus } from "@/hooks/useMigrationStatus";
import { DISABLE_MIGRATION_FLOW } from "@/utils";
import { createFileRoute } from "@tanstack/react-router";

interface MigrationSearchParams {
  redirect: string;
}

export const Route = createFileRoute("/migrate/notice/")({
  component: DISABLE_MIGRATION_FLOW
    ? with404(Notice)
    : withDynamicProtected(Notice),
  validateSearch: (
    searchParams?: Record<string, unknown>,
  ): MigrationSearchParams => {
    return {
      redirect:
        typeof searchParams?.redirect === "string"
          ? searchParams.redirect
          : "/trade",
    };
  },
});

function Notice() {
  const { completeMigrationMutation, handleSkip } = useMigrationStatus();
  const walletAddress = useDynamicWallet()?.address;
  const { redirect } = Route.useSearch();
  const fromProfile = redirect.startsWith("/profile");

  return (
    <Page
      flex
      hideTabs
      fullScreenHeight
      backButton={
        fromProfile
          ? { to: redirect, isRedirect: true, "aria-label": "Back" }
          : undefined
      }
      title={fromProfile ? "Transfer account" : "Upgrade account"}
    >
      <div className="flex-1 flex flex-col items-center px-5 pt-3 pb-[calc(123px+env(safe-area-inset-bottom))]">
        <div className="flex flex-col items-center gap-5">
          <Icon name="dflow-logo" className="w-12 h-12" />
          <p className="w-full text-left text-sm leading-4 text-gray-200">
            We noticed your email matched an account created before 5/28.
          </p>
          <p className="w-full text-left text-sm leading-4 text-gray-200">
            DFlow has upgraded all user accounts with enhanced stability,
            security, and features. Your old account will not be usable. To
            carry over your XP from Phase 0, please transfer your assets from
            your old account.
          </p>
          <p className="w-full text-left text-sm leading-4 text-gray-200">
            You can complete this transfer now or later by visiting the Profile
            section.
          </p>
          <p className="w-full text-left text-sm leading-4 text-gray-200">
            Your new account remains non-custodial; DFlow cannot access your
            funds.
          </p>
          {walletAddress ? (
            <WalletCard address={walletAddress} label="New DFlow Account" />
          ) : null}
        </div>

        <ButtonRow vertical noGap noTabs noFixedHeight>
          <Button
            variant="cta"
            className="w-full shrink-0"
            to="/migrate/login"
            search={(prev) => prev}
            disabled={completeMigrationMutation.isLoading}
          >
            Authenticate and transfer
          </Button>
          {!fromProfile && (
            <Button
              variant="flat"
              color="dark"
              className="w-full shrink-0 pb-0 h-auto pt-3.5"
              onClick={handleSkip}
              disabled={completeMigrationMutation.isLoading}
            >
              Proceed without transferring
            </Button>
          )}
        </ButtonRow>
      </div>
    </Page>
  );
}
