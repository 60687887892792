import { BaseIconProps } from "../icon";

export function ArrowLargeIcon(props: BaseIconProps) {
  return (
    <svg
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.99899 13.75L3.37299 9.96424L4.37916 8.91374L6.28708 10.9057L6.28708 1.3125L7.71091 1.3125L7.71091 10.9057L9.61883 8.91374L10.625 9.96424L6.99899 13.75Z"
        fill="currentColor"
      />
    </svg>
  );
}
