import Lottie from "lottie-react";
import animationData from "./animation-data.json";
import { cn } from "@/utils";
import { VariantProps, cva } from "class-variance-authority";

const variants = cva("", {
  variants: {
    size: {
      small: "w-7 h-7",
      medium: "w-12 h-12",
      large: "w-16 h-16",
    },
  },
});

interface LogoSpinnerProps
  extends Required<Pick<VariantProps<typeof variants>, "size">>,
    Omit<VariantProps<typeof variants>, "size"> {
  className?: string;
}

export function LogoSpinner({ className, ...props }: LogoSpinnerProps) {
  return (
    <Lottie
      animationData={animationData}
      className={cn(variants(props), className)}
    />
  );
}
