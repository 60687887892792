import { BaseIconProps } from "../icon";

export function DocumentIcon(props: BaseIconProps) {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.375 0V22H19.625V0H0.375ZM2.3 1.83333H17.7V20.1667H2.3V1.83333ZM4.225 5.5V7.33333H15.775V5.5H4.225ZM4.225 11V12.8333H10.9625V11H4.225ZM12.8875 11V12.8333H15.775V11H12.8875ZM4.225 14.6667V16.5H10.9625V14.6667H4.225ZM12.8875 14.6667V16.5H15.775V14.6667H12.8875Z"
        fill="currentColor"
      />
    </svg>
  );
}
