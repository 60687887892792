import { BaseIconProps } from "../icon";

export function BoltIcon(props: BaseIconProps) {
  return (
    <svg
      width="11"
      height="20"
      viewBox="0 0 11 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.83333 0.833328L0 11.6667H5V19.1667L10.8333 8.33333H5.83333V0.833328Z"
        fill="currentColor"
      />
    </svg>
  );
}
