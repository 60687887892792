import {
  SendTransactionArgs,
  useLatestBlockHeightQuery,
  useSaveCompletedUserOrderMutation,
  useSendTransactionMutation,
} from "@/api";
import {
  BackgroundTransactionData,
  clearFinishedTransactions,
  popTransactionUpdate,
  removePendingTransaction,
} from "@/app/background-transactions/background-transactions-slice";
import { activeConnection } from "@/rpc-connection";
import { useAppDispatch, useTypedSelector } from "@/store";
import { Transaction, VersionedTransaction } from "@solana/web3.js";
import { useRouter } from "@tanstack/react-router";
import { useEffect, useRef, useState } from "react";
import { useBackgroundTransactions } from "./use-background-transactions";

export function useResumePendingTransactions() {
  const { transactions: storedTransactions, transactionUpdates } =
    useTypedSelector((state) => state.backgroundTransactions);
  const [sendTransactionMutate] = useSendTransactionMutation();
  const [saveCompletedOrderMutate] = useSaveCompletedUserOrderMutation();
  const latestBlockHeight = useLatestBlockHeightQuery().data;
  const [showToast, setShowToast] = useState(false);
  const dispatch = useAppDispatch();
  const hasResumedRef = useRef(false);
  const resumeTransaction = async (args: SendTransactionArgs) => {
    try {
      const sendResult = await sendTransactionMutate(args);
      if ("error" in sendResult) return;

      await saveCompletedOrderMutate({
        signature: sendResult.data.signature,
      });
    } catch (e) {
      console.error("Failed to resume transaction", e);
    }
  };
  const resumeSaving = async (signature: string) => {
    try {
      await saveCompletedOrderMutate({ signature });
    } catch (e) {
      console.error("Failed to resume saving transaction", e);
    }
  };

  // Check if any expired transactions have been included
  // If they have, save them to the order history
  const checkExpiredTransactions = async (
    txns: BackgroundTransactionData[],
  ) => {
    try {
      const conn = await activeConnection.getReadConnection();
      const signatures = txns.map((tx) => tx.signature);
      console.info("Checking expired transactions", signatures);
      const result = await conn.getSignatureStatuses(signatures);
      const includedSignatures = result.value;
      for (let i = 0; i < signatures.length; i += 1) {
        if (includedSignatures[i] !== null) {
          void resumeSaving(signatures[i]);
        }
      }
    } catch (e) {
      console.error("Failed to check expired transactions", e);
    }
  };

  useEffect(() => {
    if (!latestBlockHeight) return;
    if (hasResumedRef.current) return;
    hasResumedRef.current = true;
    dispatch(clearFinishedTransactions());
    const txnsToCheck: BackgroundTransactionData[] = [];
    // Resume pending transactions
    for (const tx of Object.values(storedTransactions)) {
      if (!tx) continue;
      const isPending = tx.status === "sending" || tx.status === "confirming";
      const isExpired = tx.lastValidBlockHeight < latestBlockHeight;
      if (isExpired) {
        txnsToCheck.push(tx);
        dispatch(removePendingTransaction(tx.signature));
      } else if (isPending) {
        console.info("Resuming transaction", tx);
        const buffer = Buffer.from(tx.serializedTransaction, "base64");
        void resumeTransaction({
          ...tx,
          transaction:
            tx.transactionType === "versioned"
              ? VersionedTransaction.deserialize(buffer)
              : Transaction.from(buffer),
        });
      }
      if (tx.isSaving) {
        void resumeSaving(tx.signature);
      }
      if (txnsToCheck.length > 0) {
        void checkExpiredTransactions(txnsToCheck);
      }
    }
  });

  const orderedTransactions = useBackgroundTransactions();
  const locationPath = useRouter().latestLocation.pathname;
  const isOrderIdRoute = Boolean(
    locationPath.match(/\/orders\/pending\/\d+/) ||
      locationPath.match(/\/orders\/\d+/),
  );
  const latestUpdate = transactionUpdates.at(-1);

  useEffect(() => {
    const transactionStatus = latestUpdate?.status;
    if (!transactionStatus) return;
    if (!hasResumedRef.current) return;

    // Prevents showing toast on first resume of stored completed transactions
    const timer = setTimeout(() => {
      setShowToast(true);
    }, 10);

    return () => {
      clearTimeout(timer);
    };
  }, [latestUpdate]);

  if (isOrderIdRoute && showToast) {
    setShowToast(false);
  }

  return {
    showToast: showToast,
    setShowToast,
    orderedTransactions,
    transactionUpdates,
    latestUpdate,
    popTransactionUpdate: () => dispatch(popTransactionUpdate()),
  };
}
