/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */

// Types are not perfect for these middlewares, so we need to disable some rules

import { Endpoints, api } from "@/api";
import { createListenerMiddleware, isAnyOf } from "@reduxjs/toolkit";
import * as cache from "./cache";
import { AppDispatch, RootState, isQueryWithMeta } from "@/store";

const apiActions = [
  ...Object.values<Endpoints[keyof Endpoints]>(api.endpoints),
].map((x) => x.matchPending);

/**
 * Middleware that listens for API rejects and uses offline data
 * stored in IndexedDB if the network request fails.
 */
export const offlineApiCacheMiddleware = createListenerMiddleware();

offlineApiCacheMiddleware.startListening.withTypes<RootState, AppDispatch>()({
  matcher: isAnyOf(...apiActions),
  effect: async (action, store) => {
    if (isQueryWithMeta(action)) {
      const cacheKey = action.meta.arg.queryCacheKey;
      const cachedData = await cache.get(cacheKey);
      if (cachedData) {
        await store.dispatch(
          (api.util.upsertQueryData as any)(
            action.meta.arg.endpointName,
            action.meta.arg.originalArgs,
            cachedData.data,
          ),
        );
      }
    }
  },
});
