import { useUserXpQuery } from "@/api";
import { Icon, IconProps, Shimmer } from "@/components";
import {
  approximatelyEqual,
  cn,
  formatAbsolutePercent,
  formatHoursDifference,
  formatNumber,
  formatNumberCompact,
  formatPercentile,
  formatUsd,
} from "@/utils";

interface CardProps {
  icon: IconProps["name"];
  title: string;
  value?: string;
  change?: {
    value: string;
    isPositive: boolean;
  };
}

function Card({ icon, title, value, change }: CardProps) {
  return (
    <div className="w-full h-[100px] flex flex-col justify-between p-2.5 rounded-xl border border-white/10 bg-box">
      <div className="flex justify-between">
        <Icon name={icon} className="w-5 h-5 text-primary" />
        {change ? (
          <div className="flex items-center gap-0.5">
            <Icon
              name="arrow"
              className={cn(
                "w-2 h-2",
                change.isPositive
                  ? "text-positive"
                  : "text-negative rotate-180",
              )}
            />
            <span
              className={cn(
                "text-sm font-bold",
                change.isPositive ? "text-positive" : "text-negative",
              )}
            >
              {change.value}
            </span>
          </div>
        ) : null}
      </div>

      <div className="flex flex-col">
        <span className="text-sm text-gray-200 leading-[18px]">{title}</span>
        <Shimmer.Text className="w-32 h-[26px]" isLoading={value === undefined}>
          <span className="text-[20px] font-bold text-white leading-[26px]">
            {value}
          </span>
        </Shimmer.Text>
      </div>
    </div>
  );
}

export function OverviewTab() {
  const query = useUserXpQuery();

  return (
    <div className="flex-1 flex flex-col px-5">
      <div className="pt-7 flex justify-between items-center">
        <div className=" flex flex-col gap-3">
          <span className="uppercase text-primary font-bold text-sm">
            DFLOW XP
          </span>
          <Shimmer.Text isLoading={!query.data} className="w-20 h-[45px]">
            <span className="h-[45px] leading-[45px] text-white text-5xl font-semibold">
              {query.data?.totalXp && query.data.totalXp >= 1_000_000
                ? formatNumberCompact(query.data.totalXp)
                : formatNumber(query.data?.totalXp)}
            </span>
          </Shimmer.Text>
        </div>
        {query.data ? (
          <div className="h-[37px] flex items-center justify-between gap-1 rounded-full border border-white/10 px-2.5 py-2">
            <Icon
              name="fire"
              className={cn(
                "w-4 h-4",
                query.data.currentStreak > 0 ? "text-accent" : "text-gray-200",
              )}
            />
            <span
              className={cn(
                "text-md font-bold",
                query.data.currentStreak > 0 ? "text-white" : "text-gray-200",
              )}
            >
              {formatNumber(query.data.currentStreak)}
            </span>
          </div>
        ) : (
          <Shimmer.Text isLoading className="w-20 h-8" />
        )}
      </div>

      <p className="text-sm text-gray-200 py-5">
        Trade to earn XP and rank up.
      </p>

      <div className="gap-2.5 flex flex-col">
        <div className="flex justify-between gap-2.5">
          <Card
            icon="star-half"
            title="Rank"
            value={
              query.data
                ? !query.data.rank
                  ? "N/A"
                  : formatNumber(query.data.rank)
                : undefined
            }
            change={
              query.data?.changeRank
                ? {
                    isPositive: query.data.changeRank > 0,
                    value: formatNumber(Math.abs(query.data.changeRank)),
                  }
                : undefined
            }
          />

          <Card
            icon="percent-box"
            title="Percentile"
            value={
              query.data
                ? !query.data.percentileRank
                  ? "N/A"
                  : formatPercentile(query.data.percentileRank)
                : undefined
            }
          />
        </div>

        <Card
          icon="graph"
          title="Total Volume"
          value={
            query.data ? formatUsd(query.data.totalTradingVolume) : undefined
          }
        />
        <Card
          icon="line-chart"
          title="Trailing 7 Day Volume"
          value={
            query.data
              ? formatUsd(query.data.trailing7DayTradingVolume)
              : undefined
          }
          change={
            query.data &&
            // Don't show change % if change is <= $0.01
            !approximatelyEqual(
              query.data.changeTrailing7DayTradingVolume,
              0,
              0.01,
            ) &&
            // Don't show change % if previous trailing 7 day trading volume is approximately 0
            !approximatelyEqual(
              query.data.trailing7DayTradingVolume,
              query.data.changeTrailing7DayTradingVolume,
              0.01,
            )
              ? {
                  isPositive: query.data.changeTrailing7DayTradingVolume > 0,
                  value: formatAbsolutePercent(
                    (query.data.changeTrailing7DayTradingVolume /
                      (query.data.trailing7DayTradingVolume -
                        query.data.changeTrailing7DayTradingVolume)) *
                      100,
                    1,
                  ),
                }
              : undefined
          }
        />
        <Card
          icon="fire"
          title="Max Streak"
          value={
            query.data
              ? `${formatNumber(query.data.maxStreak)} ${query.data.maxStreak !== 1 ? "days" : "day"}`
              : undefined
          }
        />
      </div>

      <div className="my-5 flex justify-between">
        <span className=" uppercase text-sm font-bold text-gray-200">
          Updates daily
        </span>
        {query.data?.timestamp ? (
          <span className=" uppercase text-sm font-bold text-gray-200">
            Last updated {formatHoursDifference(query.data.timestamp)}H ago
          </span>
        ) : null}
      </div>
    </div>
  );
}
