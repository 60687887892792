import { ContentTabs, Page } from "@/components";
import { createFileRoute } from "@tanstack/react-router";
import { OverviewTab } from "./-overview-tab";
import { LeaderboardTab } from "./-leaderboard-tab";
import { useRef } from "react";

export const Route = createFileRoute("/rewards/")({
  component: Rewards,
});

function Rewards() {
  const overviewScrollContainerRef = useRef<HTMLDivElement | null>(null);
  const leaderboardScrollContainerRef = useRef<HTMLDivElement | null>(null);

  const tabs = [
    {
      label: "Overview",
      content: <OverviewTab />,
      contentRef: overviewScrollContainerRef,
    },
    {
      label: "Leaderboard",
      content: <LeaderboardTab />,
      contentRef: leaderboardScrollContainerRef,
    },
  ];

  return (
    <Page
      title="Rewards"
      profileButton
      fullScreenHeight
      flex
      onCurrentTabClick={() => {
        if (overviewScrollContainerRef.current) {
          overviewScrollContainerRef.current.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }

        if (leaderboardScrollContainerRef.current) {
          leaderboardScrollContainerRef.current.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }
      }}
    >
      <div className="flex-1 flex flex-col">
        <ContentTabs tabs={tabs} />
      </div>
    </Page>
  );
}
