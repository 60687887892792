import { BaseIconProps } from "../icon";

export function QRCodeIcon(props: BaseIconProps) {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.125 1.125V6.125H2.375V7.375H3.625V6.125H6.125V1.125H1.125ZM6.125 6.125V7.375H7.375V8.625H4.875V9.875H1.125V14.875H6.125V9.875H9.875V8.625H8.625V7.375H11.125V6.125H12.375V7.375H13.625V6.125H14.875V1.125H9.875V6.125H6.125ZM13.625 7.375V8.625H14.875V7.375H13.625ZM13.625 8.625H12.375V9.875H13.625V8.625ZM13.625 9.875V11.125H14.875V9.875H13.625ZM13.625 11.125H12.375V9.875H11.125V11.125H8V14.875H9.25V12.375H11.75V13.625H13V12.375H13.625V11.125ZM11.75 13.625H10.5V14.875H11.75V13.625ZM12.375 8.625V7.375H11.125V8.625H12.375ZM4.875 8.625V7.375H3.625V8.625H4.875ZM2.375 7.375H1.125V8.625H2.375V7.375ZM7.375 1.125V3.625H6.75V4.875H7.375V5.5H8.625V3.625H9.25V2.375H8.625V1.125H7.375ZM2.375 2.375H4.875V4.875H2.375V2.375ZM11.125 2.375H13.625V4.875H11.125V2.375ZM3 3V4.25H4.25V3H3ZM11.75 3V4.25H13V3H11.75ZM2.375 11.125H4.875V13.625H2.375V11.125ZM3 11.75V13H4.25V11.75H3ZM13.625 13.625V14.875H14.875V13.625H13.625Z"
        fill="currentColor"
      />
    </svg>
  );
}
