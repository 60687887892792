import { LeaderboardEntry, useLeaderboardQuery, useUserXpQuery } from "@/api";
import { Icon, ProfileImage, Shimmer, VirtualList } from "@/components";
import { cn, formatNumber } from "@/utils";

export function LeaderboardTab() {
  const userQuery = useUserXpQuery();
  const leaderboardQuery = useLeaderboardQuery();
  const rows = leaderboardQuery.data ?? [];
  const userEntry: LeaderboardEntry | undefined = userQuery.data
    ? {
        username: userQuery.data.username,
        rank: userQuery.data.rank,
        currentStreak: userQuery.data.currentStreak,
        changeRank: userQuery.data.changeRank,
        profileImage: userQuery.data.profileImage,
      }
    : undefined;

  return (
    <div className={cn("flex-1 flex flex-col")}>
      {!userEntry ? (
        <Shimmer.Text className="w-full h-[76px]" isLoading />
      ) : (
        <EntryRow
          userEntry={userEntry}
          classname={cn(
            "z-50 sticky top-0 bg-box",
            "bg-[linear-gradient(180deg,_rgba(255,_122,_209,_0.13)_0%,_rgba(255,_122,_209,_0.03)_34%,_rgba(255,_122,_209,_0.03)_55%,_rgba(255,_122,_209,_0.13)_100%)] border-y border-accent",
          )}
        />
      )}
      <VirtualList
        id="leaderboard-list"
        classname="overflow-hidden"
        itemHeight={74}
        totalItems={rows.length}
        isFetching={leaderboardQuery.isFetching}
      >
        {(virtualItem, props) => {
          const userEntry =
            virtualItem.index < rows.length
              ? rows[virtualItem.index]
              : undefined;

          if (!userEntry) {
            return null;
          }

          return (
            <div key={virtualItem.key} {...props}>
              <EntryRow userEntry={userEntry} />
            </div>
          );
        }}
      </VirtualList>
    </div>
  );
}

function EntryRow({
  userEntry,
  classname,
}: {
  userEntry: LeaderboardEntry;
  classname?: string;
}) {
  return (
    <div
      className={cn(
        "w-full flex justify-between items-center px-5 py-4 border-b border-white/10",
        classname,
      )}
    >
      <div className="flex items-center gap-3.5">
        <ProfileImage size="medium" profileImage={userEntry.profileImage} />
        <div className="flex flex-col">
          <span className="text-sm text-white font-bold">
            {userEntry.username}
          </span>
          <div className="flex gap-2.5">
            {userEntry.changeRank ? (
              <div className="flex items-center gap-0.5">
                <Icon
                  name="arrow"
                  className={cn(
                    "w-2 h-2",
                    userEntry.changeRank > 0
                      ? "text-positive"
                      : "text-negative rotate-180",
                  )}
                />
                <span
                  className={cn(
                    "text-sm font-bold",
                    userEntry.changeRank > 0
                      ? "text-positive"
                      : "text-negative",
                  )}
                >
                  {Math.abs(userEntry.changeRank)}
                </span>
              </div>
            ) : null}
            {userEntry.currentStreak ? (
              <div className="flex items-center gap-0.5">
                <Icon name="fire" className="text-accent w-3.5 h-3.5" />
                <span className="text-accent text-sm font-bold">
                  {formatNumber(userEntry.currentStreak)}
                </span>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <span className="text-sm text-primary font-bold">
        {userEntry.rank === null ? "N/A" : `#${formatNumber(userEntry.rank)}`}
      </span>
    </div>
  );
}
